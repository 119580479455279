import { KintaiBunrui, NullKintai, RegularBunruiComb } from "@webkintai/bunrui";
import { HourBasedDays, Minutes } from "@webkintai/core";
import { getParentOfType, types } from "mobx-state-tree";
import moment from "moment";

import { typesHourBasedDays } from "../../primitives/HourBasedDays";
import { typesMinutes } from "../../primitives/Minutes";
import { KintaiApplicationDayType } from "../apps/KintaiApplicationDay";
import { typesKintaiBunrui } from "../kintaibunrui/typesKintaiBunrui";
import { KintaiDailyError } from "../KintaiDailyError";
import { MonthlyKintai } from "../MonthlyKintai";
import { makeArrivalLeaveOrdered } from "../utils/KintaiValueSettings";

const model = types
  .model("RegularDailyValues", {
    date: types.Date,
    errors: types.array(KintaiDailyError),

    type1: types.optional(typesKintaiBunrui, NullKintai),
    type2: types.optional(typesKintaiBunrui, NullKintai),
    type3: types.optional(typesKintaiBunrui, NullKintai),
    type4: types.optional(typesKintaiBunrui, NullKintai),

    transDay: types.maybe(types.Date),
    hourlyLeave: types.maybe(typesHourBasedDays),
    hourlyNursingcare: types.maybe(typesHourBasedDays),
    hourlyFamilycare: types.maybe(typesHourBasedDays),

    arrival: types.maybe(types.Date),
    leave: types.maybe(types.Date),

    doorEnter: types.maybe(types.Date),
    doorLeave: types.maybe(types.Date),

    ckrpEnter: types.maybe(types.Date),
    ckrpLeave: types.maybe(types.Date),

    rest: types.maybe(typesMinutes),
    actual: types.maybe(typesMinutes),
    flex: types.maybe(typesMinutes),

    holiday: types.maybe(typesMinutes),
    midnight: types.maybe(typesMinutes),
    allNightOff: types.maybe(typesMinutes),

    spWeekday: types.maybe(typesMinutes),
    spWeekdayMidnight: types.maybe(typesMinutes),
    spHoliday: types.maybe(typesMinutes),
    spHolidayMidnight: types.maybe(typesMinutes),

    diff: types.maybe(typesMinutes),

    off: types.maybe(typesMinutes),
    offCount: types.maybe(types.number),

    over: types.maybe(typesMinutes),
    overHoliday: types.maybe(typesMinutes),
    overHolidayMidnight: types.maybe(typesMinutes),
    growingOver: types.maybe(typesMinutes),
    overWeekday: types.maybe(typesMinutes),
    overWeekdayMidnight: types.maybe(typesMinutes),

    remarks: types.optional(types.string, ""),

    unusedRestSunset: types.optional(types.boolean, false),
    unusedRestNight: types.optional(types.boolean, false),

    workplaceZaitakuLe4H: types.optional(types.boolean, false),
    workplaceZaitakuGt4H: types.optional(types.boolean, false),
    workplaceSyussya: types.optional(types.boolean, false),
    workplaceSonota: types.optional(types.boolean, false),
  })
  .views(self => {
    return {
      get root() {
        return getParentOfType(self, MonthlyKintai);
      },
      get types() {
        return [self.type1, self.type2, self.type3, self.type4];
      },
      get isManager() {
        return this.root.isManager;
      },
    };
  })
  .views(self => {
    return {
      get privilegedApproveIsRequired(): boolean {
        return self.types.some(it => it.requirePrivilegedAuthorization);
      },
      get approveIsRequired(): boolean {
        return self.types.some(it => it.checkIfApproveIsRequired(self.isManager));
      },
      get arrivalAsMinutes() {
        return Minutes.fromDate(self.date, self.arrival);
      },
      get leaveAsMinutes() {
        return Minutes.fromDate(self.date, self.leave);
      },
      get allKintaiBunruiList() {
        return self.root.availableRegularKintaiBunruiList;
      },
      get dailyApp(): KintaiApplicationDayType {
        return self.root.apps!.days[self.date.getDate() - 1];
      },
      get disableWorkplaceInput() {
        return self.arrival === undefined || self.leave === undefined;
      },
    };
  })
  .actions(self => {
    const cleanTypesCombination = () => {
      const comb = new RegularBunruiComb(self.allKintaiBunruiList, [self.type1, self.type2, self.type3, self.type4]);
      self.type2 = comb.getAt(2);
      self.type3 = comb.getAt(3);
      self.type4 = comb.getAt(4);
    };

    return {
      setType1(value: KintaiBunrui) {
        return;
      },
      setType2(value: KintaiBunrui) {
        self.type2 = value;
        cleanTypesCombination();
      },
      setType3(value: KintaiBunrui) {
        self.type3 = value;
        cleanTypesCombination();
      },
      setTransDay(value?: Date) {
        self.transDay = value;
      },
      setHourlyLeave(value?: HourBasedDays) {
        self.hourlyLeave = value;
      },
      setHourlyNursingcare(value?: HourBasedDays) {
        self.hourlyNursingcare = value;
      },
      setHourlyFamilycare(value?: HourBasedDays) {
        self.hourlyFamilycare = value;
      },
      setType4(value: KintaiBunrui) {
        self.type4 = value;
        cleanTypesCombination();
      },
      setType(kintaiBunruiIdx: number, value: KintaiBunrui) {
        [this.setType1, this.setType2, this.setType3, this.setType4][kintaiBunruiIdx - 1](value);
      },

      setDefaultArrivalLeave(arrival: Minutes | undefined, leave: Minutes | undefined) {
        if (arrival && self.arrival === undefined) {
          this.setArrivalFromMinutes(arrival);
        }
        if (leave && self.leave === undefined) {
          this.setLeaveFromMinutes(leave);
        }
      },
      setArrival(value?: Date) {
        self.arrival = value;
        makeArrivalLeaveOrdered(self);
      },
      setArrivalFromMinutes(value?: Minutes) {
        this.setArrival(value !== undefined ? value.intoDate(self.date) : value);
      },
      setLeave(value?: Date) {
        self.leave = value;
        makeArrivalLeaveOrdered(self);
      },
      setLeaveFromMinutes(value?: Minutes) {
        const addOffset = value && self.arrivalAsMinutes && self.arrivalAsMinutes.minutes >= value.minutes ? 1 : 0;
        this.setLeave(
          value !== undefined
            ? moment(value.intoDate(self.date))
                .add(addOffset, "days")
                .toDate()
            : value,
        );
      },
      setDoorEnter(value?: Date) {
        self.doorEnter = value;
      },
      setDoorLeave(value?: Date) {
        self.doorLeave = value;
      },
      setCkrpEnter(value?: Date) {
        self.ckrpEnter = value;
      },
      setCkrpLeave(value?: Date) {
        self.ckrpLeave = value;
      },
      setRest(value?: Minutes) {
        self.rest = value;
      },
      setActual(value?: Minutes) {
        self.actual = value;
      },
      setFlex(value?: Minutes) {
        self.flex = value;
      },
      setHoliday(value?: Minutes) {
        self.holiday = value;
      },
      setMidnight(value?: Minutes) {
        self.midnight = value;
      },
      setAllNightOff(value?: Minutes) {
        self.allNightOff = value;
      },
      setSpWeekday(value?: Minutes) {
        self.spWeekday = value;
      },
      setSpWeekdayMidnight(value?: Minutes) {
        self.spWeekdayMidnight = value;
      },
      setSpHoliday(value?: Minutes) {
        self.spHoliday = value;
      },
      setSpHolidayMidnight(value?: Minutes) {
        self.spHolidayMidnight = value;
      },
      setDiff(value?: Minutes) {
        self.diff = value;
      },
      setOff(value?: Minutes) {
        self.off = value;
      },
      setOffCount(value?: number) {
        self.offCount = value;
      },
      setOver(value?: Minutes) {
        self.over = value;
      },
      setOverHoliday(value?: Minutes) {
        self.overHoliday = value;
      },
      setOverHolidayMidnight(value?: Minutes) {
        self.overHolidayMidnight = value;
      },
      setGrowingOver(value?: Minutes) {
        self.growingOver = value;
      },
      setOverWeekday(value?: Minutes) {
        self.overWeekday = value;
      },
      setOverWeekdayMidnight(value?: Minutes) {
        self.overWeekdayMidnight = value;
      },
      setRemarks(value?: string) {
        self.remarks = (value || "").substr(0, 800);
      },
      setUnusedRestSunset(value: boolean) {
        self.unusedRestSunset = value;
      },
      setUnusedRestNight(value: boolean) {
        self.unusedRestNight = value;
      },
      setWorkplaceZaitakuLe4H(value: boolean) {
        self.workplaceZaitakuLe4H = value;
        if (value) {
          self.workplaceZaitakuGt4H = false;
        }
      },
      setWorkplaceZaitakuGt4H(value: boolean) {
        self.workplaceZaitakuGt4H = value;
        if (value) {
          self.workplaceZaitakuLe4H = false;
        }
      },
      setWorkplaceSyussya(value: boolean) {
        self.workplaceSyussya = value;
      },
      setWorkplaceSonota(value: boolean) {
        self.workplaceSonota = value;
      },
    };
  });
export const RegularDailyValues: RegularDailyValuesModelType = model;
type RegularDailyValuesInferredType = typeof model;
export interface RegularDailyValuesModelType extends RegularDailyValuesInferredType {}
