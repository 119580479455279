import { getParentOfType, types } from "mobx-state-tree";
import moment from "moment";

import { getNendoMonthOf } from "../../../utils/calendar";
import { hasNoChangeReduce } from "../../../utils/model";
import { prefixedIdType } from "../../utils";
import { NendoDayCalendar } from "../NendoDayCalendar";
import { NendoMonthCalendarInput } from "./NendoMonthCalendarInput";

export const idPrefix = "NendoDayCalendar_";

const model = types
  .model("NendoDayCalendarInput", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    month: types.number,
    day: types.number,
    isHoliday: types.optional(types.boolean, false),
    isFinalDay: types.optional(types.boolean, false),
    origin: types.reference(NendoDayCalendar),
  })
  .views(self => ({
    get hasNoChange() {
      return hasNoChangeReduce([self.origin.isHoliday === self.isHoliday, self.origin.isFinalDay === self.isFinalDay]);
    },
    get date() {
      return moment(getNendoMonthOf(self.nendo, self.month))
        .date(self.day)
        .toDate();
    },
    get monthInput() {
      return getParentOfType(self, NendoMonthCalendarInput);
    },
  }))
  .actions(self => ({
    setIsHoliday(value: boolean) {
      self.isHoliday = value;
    },
    setIsFinalDay(value: boolean) {
      self.isFinalDay = value;
    },
  }));

export type NendoDayCalendarInputType = typeof NendoDayCalendarInput.Type;
export const NendoDayCalendarInput: NendoDayCalendarInputModelType = model;
type NendoDayCalendarInputInferredType = typeof model;
export interface NendoDayCalendarInputModelType extends NendoDayCalendarInputInferredType {}
