import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";
import { render } from "react-dom";

import { TableAttachedOptions } from "../TableAttachedOptions";
import { htmlToCsvBytes } from "./HtmlToCsv";

export type RenderedMedia = "screen" | "html" | "csv";

export interface ExportableTableProps {
  title: string;
  children: (renderedOn: RenderedMedia) => React.ReactNode;
}

const ExportFrame = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100%;
`;

const ExplainPreview = styled.span`
  padding-left: 1em;
  padding-right: 1em;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const ExportableTable: React.FunctionComponent<ExportableTableProps> = props => {
  const [displayedMedia, changeMedia] = React.useState("screen" as RenderedMedia);
  const useScreen = React.useCallback(() => {
    changeMedia("screen");
  }, [changeMedia]);
  const useCsv = React.useCallback(() => {
    changeMedia("csv");
  }, [changeMedia]);

  const downloadAs = (media: RenderedMedia) =>
    React.useCallback(() => {
      const divElem = document.createElement("div");
      render(<>{props.children(media)}</>, divElem, () => {
        if (media === "html") {
          saveAs(
            new Blob([divElem.innerHTML], {
              type: "text/html; charset=utf-8",
            }),
            `Web勤怠印刷_${props.title}.html`,
          );
        }

        if (media === "csv") {
          saveAs(
            new Blob([htmlToCsvBytes(divElem.children as any)], {
              type: "text/csv; charset=Shift_JIS",
            }),
            `Web勤怠CSV出力_${props.title}.csv`,
          );
        }
      });
    }, [props.children, media]);

  return (
    <>
      <TableAttachedOptions>
        <Button small={true} onClick={downloadAs("html")} icon="download">
          HTML形式
        </Button>
        <Button small={true} onClick={downloadAs("csv")} onMouseOver={useCsv} onMouseOut={useScreen} icon="th-list">
          CSV形式
        </Button>
        {displayedMedia === "csv" && <ExplainPreview>※CSVプレビュー表示</ExplainPreview>}
      </TableAttachedOptions>
      {props.children(displayedMedia)}
    </>
  );
};
