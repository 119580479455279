import { getParentOfType, IModelType, types } from "mobx-state-tree";

import { NendoDepts } from "./NendoDepts";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("NendoDept", {
    id: types.identifier,
    depCode: types.string,
    nendo: types.number,
    parentDepCode: types.string,
    name: types.string,
    childrenDeptCodes: types.array(types.string),
  })
  .views(self => {
    const parentNode = () => getParentOfType(self, NendoDepts);

    return {
      get isLeaf() {
        return self.childrenDeptCodes.length === 0;
      },
      get isRoot() {
        return self.depCode === self.parentDepCode;
      },
      get parentDept(): NendoDeptType | undefined {
        if (!self.parentDepCode || self.depCode === self.parentDepCode) {
          return undefined;
        }

        return parentNode().getDept(self.parentDepCode);
      },
      get childrenDepts(): NendoDeptType[] {
        return Array.from(self.childrenDeptCodes, depCode => parentNode().getDept(depCode)!);
      },
    };
  })
  .views(self => {
    return {
      get allAncestorDepts(): NendoDeptType[] {
        const depts: NendoDeptType[] = [];

        const walk = (cDept?: NendoDeptType) => {
          if (!cDept) {
            return;
          }

          depts.push(cDept);
          walk(cDept.parentDept);
        };

        walk(self.parentDept);

        return depts;
      },
      get allDescendantDepts(): NendoDeptType[] {
        const depts: NendoDeptType[] = [];

        const walk = (cDept: NendoDeptType) => {
          depts.push(cDept);

          cDept.childrenDepts.forEach(walk);
        };
        self.childrenDepts.forEach(cDept => {
          walk(cDept);
        });

        return depts;
      },
    };
  });

export const idNendoDept = (nendo: number, depCode: string) => `NendoDept-${nendo}${depCode}`;

export type NendoDeptType = typeof NendoDept.Type;
export const NendoDept: NendoDeptModelType = model;
type NendoDeptInferredType = typeof model;
export interface NendoDeptModelType extends NendoDeptInferredType {}
