import {
  JobNoListResult,
  JobNosControllerApi,
  KintaiExportsResult,
  KintaiReportEditEntity,
  KintaiReportsControllerApi,
  KintaiTimestampsControllerApi,
  KintaiUsersReportControllerApi,
  MonthlyReportApproversEditEntity,
  MonthlyReportAttributesEditEntity,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { KintaiApi, KintaiApiTimestampTypes } from "./KintaiApi";

export class KintaiApiImpl extends KintaiApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getKintai(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiUsersReportControllerApi).getKintaiReport(userId, year, month),
    );
  }

  public getKintaiReportsByUserIds(year: number, month: number, userIds: string[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .getKintaiReportsByUserIds(year, month, userIds),
    );
  }

  public validateKintai(userId: string, year: number, month: number, kintaiReportEntity: KintaiReportEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiUsersReportControllerApi)
        .validateKintaiReport(userId, year, month, kintaiReportEntity),
    );
  }

  public saveKintai(userId: string, year: number, month: number, kintaiReportEntity: KintaiReportEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiUsersReportControllerApi)
        .saveKintaiReport(userId, year, month, kintaiReportEntity),
    );
  }

  public removeKintai(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiUsersReportControllerApi).removeKintaiReport(userId, year, month),
    );
  }

  public searchNoEntryUsers(yearMonth: Date, depCode: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .searchUsers(yearMonth.getFullYear(), yearMonth.getMonth() + 1, depCode, undefined, undefined, true),
    );
  }

  public searchReviewerSpecified(yearMonth: Date, userId: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .searchUsers(yearMonth.getFullYear(), yearMonth.getMonth() + 1, undefined, userId, undefined, undefined),
    );
  }

  public searchPrivilegedKintaiBunruiSpecified(yearMonth: Date) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .searchUsers(yearMonth.getFullYear(), yearMonth.getMonth() + 1, undefined, undefined, true, undefined),
    );
  }

  public getApprovers(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiTimestampsControllerApi).getApprovers(userId, year, month),
    );
  }

  public updateKintaiAttributes(
    userId: string,
    year: number,
    month: number,
    payload: MonthlyReportAttributesEditEntity,
  ) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiUsersReportControllerApi)
        .updateKintaiAttributes(userId, year, month, payload),
    );
  }

  public updateApprovers(userId: string, month: Date, payload: MonthlyReportApproversEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiTimestampsControllerApi)
        .updateApprovers(userId, month.getFullYear(), month.getMonth() + 1, payload),
    );
  }

  public recalcKintai(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiUsersReportControllerApi).refreshReport(userId, year, month),
    );
  }

  public refreshKintaiAttributes(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiUsersReportControllerApi)
        .refreshKintaiAttributes(userId, year, month),
    );
  }

  public setTimestamp(userId: string, year: number, month: number, type: KintaiApiTimestampTypes) {
    const api = this.apiFactory.generateLoginAssumingApi(KintaiTimestampsControllerApi);

    return this.apiFactory.guardFailures(() => {
      switch (type) {
        case "self":
          return api.setSelfTimestamp(userId, year, month);
        case "clerk":
          return api.setClerkTimestamp(userId, year, month);
        case "review":
          return api.setReviewTimestamp(userId, year, month);
        case "department":
          return api.setDepartmentTimestamp(userId, year, month);
        case "hrm":
          return api.setHRMTimestamp(userId, year, month);
        default:
          throw Error("Coding coverage error: failed at KintaiApi#setTimestamp");
      }
    });
  }

  public clearTimestamp(userId: string, year: number, month: number, type: KintaiApiTimestampTypes) {
    const api = this.apiFactory.generateLoginAssumingApi(KintaiTimestampsControllerApi);

    return this.apiFactory.guardFailures(() => {
      switch (type) {
        case "self":
          return api.clearSelfTimestamp(userId, year, month);
        case "clerk":
          return api.clearClerkTimestamp(userId, year, month);
        case "review":
          return api.clearReviewTimestamp(userId, year, month);
        case "department":
          return api.clearDepartmentTimestamp(userId, year, month);
        case "hrm":
          return api.clearHRMTimestamp(userId, year, month);
        default:
          throw Error("Coding coverage error: failed at KintaiApi#clearTimestamp");
      }
    });
  }

  public exportKintai(yearMonth: Date): Promise<KintaiExportsResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .exportReports(yearMonth.getFullYear(), yearMonth.getMonth() + 1),
    );
  }

  public getJobNos(userId: string, year: number, month: number): Promise<JobNoListResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(JobNosControllerApi).getAvailableJobNoList(year, month, userId),
    );
  }
}
