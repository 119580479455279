import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverKFProps = {
  model: MTotalOverKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverKFVM {
  mTotalOver: KintaiInputItemState<Minutes>;
}

export const MTotalOverKF = observer((props: MTotalOverKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOver;

  return <HHMMField {...props} label="mTotalOverKF" {...itemState} onChange={undefined} />;
});
