import { Container } from "inversify";

import { LoginTokenStore } from "./LoginTokenStore";
import { LoginTokenStoreImpl } from "./LoginTokenStoreImpl";

export function register(container: Container) {
  container
    .bind(LoginTokenStore)
    .to(LoginTokenStoreImpl)
    .inSingletonScope();
}
