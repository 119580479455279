import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type OverRestNormalKFProps = {
  model: OverRestNormalKFVM;
} & CellFormPrintAdaptable;

export interface OverRestNormalKFVM {
  overRestNormal: KintaiInputItemState<Minutes | undefined>;
}

export const OverRestNormalKF = observer((props: OverRestNormalKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.overRestNormal);

  return <HHMMField {...props} label="残業休憩 普通" {...itemState} />;
});
