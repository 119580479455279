import { 介護時休, 時単休, 看護時休 } from "@webkintai/bunrui";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { MainKintaiType } from "../../../../../models/kintai/MainKintaiType";
import { GridSize } from "../../../table/GridSize";
import { GridTable } from "../../../table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../table/TableColumnDef";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import { MonthlyHeadingKF, MonthlyHeadingKFVM } from "../../monthly/fields/MonthlyHeadingKF";
import { HeaderLabelHourlyLeave } from "../common/KintaiInputHeaderLabels";
import { ActualKF } from "../fields/ActualKF";
import { ArrivalKF } from "../fields/ArrivalKF";
import { DateKF } from "../fields/DateKF";
import { DiffKF } from "../fields/DiffKF";
import { GrowingOverKF } from "../fields/GrowingOverKF";
import { HourlyFamilycareKF } from "../fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../fields/hourlyleave/HourlyNursingcare";
import { ImmunityKF } from "../fields/ImmunityKF";
import { LeaveKF } from "../fields/LeaveKF";
import { MidnightKF } from "../fields/MidnightKF";
import { MissedKF } from "../fields/MissedKF";
import { OverDiffKF } from "../fields/OverDiffKF";
import { OverHolidayKF } from "../fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../fields/OverHolidayMidnightKF";
import { OverRestMidnightKF } from "../fields/OverRestMidnightKF";
import { OverRestNormalKF } from "../fields/OverRestNormalKF";
import { OverWeekdayKF } from "../fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../fields/RemarksKF";
import { RestKF } from "../fields/RestKF";
import { TimeSumKF } from "../fields/TimeSumKF";
import { TransDayKF } from "../fields/TransDayKF";
import { TypesKF } from "../fields/TypesKF";
import { KintaiGridMovingVM } from "../KintaiGridMovingVM";
import { HeaderLabelZangyo, HeaderLabelZangyoKyukei } from "./SPortDailyKintaiGridHeaderLabels";
import { SPortDailyVM } from "./SPortDailyVM";

export type SPortDailyKintaiGridVM = {
  kintaiType: typeof MainKintaiType.Type;
  sPortRows: SPortDailyVM[];
  sPortErrors: string[];
  sPortWarnings: string[];
  sPortInfos: string[];
} & KintaiGridMovingVM &
  MonthlyHeadingKFVM;

@observer
export class SPortDailyKintaiGrid extends React.Component<{ model: SPortDailyKintaiGridVM }> {
  public render() {
    const { model } = this.props;
    const { sPortRows: rows } = model;

    return (
      <GridTable
        hideCount={true}
        rowIdGenerator={this.rowIdGenerator}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        onMoveCell={model.onMoveCell}
        activeCell={model.pos}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { sPortRows: rows } = this.props.model;

    return rows[rowIndex - 1].date.getTime().toString();
  };

  @computed
  private get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { sPortRows: rows } = model;

    return [
      {
        id: "date",
        width: GridSize.dateAndDayNameField,
        renderHeader: () => {
          return <MonthlyHeadingKF model={model} />;
        },
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <DateKF
              mode="cell"
              errors={row.errors}
              warnings={row.warnings}
              infos={row.infos}
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
    ];
  }

  @computed
  private get bodyColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { sPortRows: rows, kintaiType } = model;

    return [
      {
        id: "arrival",
        width: GridSize.hhmmField,
        renderHeader: () => "出勤",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <ArrivalKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "leave",
        width: GridSize.hhmmField,
        renderHeader: () => "退勤",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <LeaveKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },

      {
        id: "appStatus",
        width: GridSize.appStatus,
        renderHeader: () => "勤怠申請状況",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <AppStageKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row.dailyApp}
            />
          );
        },
      },
      {
        id: `types_1`,
        width: GridSize.typeField,
        renderHeader: () => `勤務分類`,
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TypesKF
              mode="cell"
              bunruiIdx={1}
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: `types_2`,
        width: GridSize.typeField,
        renderHeader: () => `休暇分類`,
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TypesKF
              mode="cell"
              bunruiIdx={2}
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: `types_3`,
        width: GridSize.typeField,
        renderHeader: () => `ほか分類`,
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TypesKF
              mode="cell"
              bunruiIdx={3}
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "transDay",
        width: GridSize.dateAndDayNameField,
        renderHeader: () => "振替日など",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TransDayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "hourlyLeave",
        width: GridSize.hourlyLeaveField,
        renderHeader: () => (
          <div>
            <HeaderLabelHourlyLeave />
            {時単休.abbrName}
          </div>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <HourlyLeaveKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "hourlyNursingcare",
        width: GridSize.hourlyLeaveField,
        renderHeader: () => (
          <div>
            <HeaderLabelHourlyLeave />
            {看護時休.abbrName}
          </div>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <HourlyNursingcareKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "hourlyFamilycare",
        width: GridSize.hourlyLeaveField,
        renderHeader: () => (
          <div>
            <HeaderLabelHourlyLeave />
            {介護時休.abbrName}
          </div>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <HourlyFamilycareKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "diff",
        width: GridSize.hhmmField,
        renderHeader: () => "差引",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <DiffKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "rest",
        width: GridSize.hhmmField,
        renderHeader: () => "休憩",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <RestKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "actual",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            普通
            <br />
            実働
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <ActualKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "missed",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            欠勤
            <br />
            時間
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <MissedKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overDiff",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            差引
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverDiffKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "midnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            深夜
            <br />
            実働
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <MidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "immunity",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            勤務
            <br />
            免除
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <ImmunityKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "timeSum",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            勤務
            <br />
            累計
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TimeSumKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },

      {
        id: "overRestNormal",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyoKyukei />
            普通
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverRestNormalKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overRestMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyoKyukei />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverRestMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overWeekday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyo />
            平日
            <br />
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverWeekdayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overWeekdayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyo />
            平日
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverWeekdayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overHoliday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyo />
            休日
            <br />
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverHolidayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "overHolidayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            <HeaderLabelZangyo />
            休日
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverHolidayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },

      {
        id: "growingOver",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            累計
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <GrowingOverKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "remarks",
        width: GridSize.remarksField,
        renderHeader: () => "備考",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <RemarksKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
    ];
  }
}
