import { IModelType, types } from "mobx-state-tree";

import { typesMinutes } from "../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "KintaiSumMonthProjectEntry_";

const model = types
  .model("KintaiSumMonthProjectEntry", {
    id: types.string,

    nendo: types.number,
    userId: types.string,
    month: types.Date,
    columnNo: types.number,

    pjCode: types.string,
    odCode: types.string,

    name: types.string,
    worktimeSum: typesMinutes,
  })
  .views(self => {
    return {};
  });

export const idKintaiSumMonthProjectEntry = (nendo: number, userId: string, month: Date, columnNo: number) =>
  `${idPrefix}${nendo}_${userId}_${month.toISOString()}_${columnNo}`;

export type KintaiSumMonthProjectEntryType = typeof KintaiSumMonthProjectEntry.Type;
export const KintaiSumMonthProjectEntry: KintaiSumMonthProjectEntryModelType = model;
type KintaiSumMonthProjectEntryInferredType = typeof model;
export interface KintaiSumMonthProjectEntryModelType extends KintaiSumMonthProjectEntryInferredType {}
