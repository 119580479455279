import { InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Minutes, minutesEq } from "@webkintai/core";
import React from "react";
import { useRef } from "react";
import { useCallback } from "react";

import { minAsHourText, recognizeAsHour } from "../../../../utils/hours";
import { FormAdaptable } from "../CellFormAdaptable";
import { componentCommonClasses, hasNoChangeStyle } from "../FormCommon";
import { FormLabel } from "../FormLabel";
import { HHMMFieldCoreProps } from "../HHMMField";

export const FormHHMMField: React.FunctionComponent<HHMMFieldCoreProps & FormAdaptable> = props => {
  const errorOverlayDisabled = props.disabledButShowsErrors !== true && props.disabled;

  const considerFormMode = (minutes: number | undefined) =>
    (!props.allowTimeInput || (minutes && minutes > 24 * 60) ? "text" : "time") as "text" | "time";

  const formRef = useRef<HTMLDivElement>(null);

  const inputElem = () => {
    if (formRef.current) {
      return formRef.current.querySelector("input");
    }
    return undefined;
  };
  const setDefaultValue = () => {
    const $i = inputElem();

    if ($i) {
      setTimeout(() => {
        $i.defaultValue = "";
      });
    }
  };

  const [inputText, setInputText] = React.useState(hhmmHourAsDisplayText(props.value));
  const [inInput, setInInput] = React.useState(false);
  const [formMode, setFormMode] = React.useState(considerFormMode(props.value && props.value.minutes));

  if (!inInput && inputText !== hhmmHourAsDisplayText(props.value)) {
    setInputText(hhmmHourAsDisplayText(props.value));
    setFormMode(considerFormMode(props.value && props.value.minutes));
  }

  const onFocus: React.FormEventHandler<HTMLInputElement> = ev => {
    setInputText(hhmmHourAsDisplayText(props.value));
    setInInput(true);
    setFormMode(considerFormMode(props.value && props.value.minutes));
  };

  const onChangeComponentText: React.FormEventHandler<HTMLInputElement> = ev => {
    const value = ev.currentTarget.value;

    if (formMode === "time") {
      const hour = recognizeAsHour(value);
      const asMinutes = hour !== undefined ? Minutes.of(hour) : undefined;
      setInputText(hhmmHourAsDisplayText(asMinutes));
      setFormMode(considerFormMode(hour));

      if (props.onChange) {
        if (!minutesEq(props.value, asMinutes)) {
          props.onChange(asMinutes);
        }
      }
      return;
    }
    setInputText(value);
  };

  const onBlur: React.FormEventHandler<HTMLInputElement> = ev => {
    setInInput(false);
    const hour = recognizeAsHour(inputElem()!!.value);
    const asMinutes = hour !== undefined ? Minutes.of(hour) : undefined;
    setInputText(hhmmHourAsDisplayText(asMinutes));
    setFormMode(considerFormMode(hour));

    if (props.onChange) {
      if (!minutesEq(props.value, asMinutes)) {
        props.onChange(asMinutes);
      }
    }
  };

  return (
    <FormLabel
      label={props.label}
      disabled={errorOverlayDisabled}
      errors={props.errors}
      warnings={props.warnings}
      infos={props.infos}>
      <div ref={formRef}>
        <Form
          type={formMode}
          className={componentCommonClasses(props)}
          placeholder="00:00"
          disabled={props.disabled}
          small={true}
          onChange={onChangeComponentText}
          onFocus={onFocus}
          onInput={setDefaultValue}
          onBlur={onBlur}
          value={inputText}
        />
      </div>
    </FormLabel>
  );
};

function hhmmHourAsDisplayText(d: Minutes | undefined, hh = true, decimal = false) {
  return minAsHourText(d ? d.minutes : undefined, hh, decimal);
}

const Form = styled(InputGroup)`
  ${hasNoChangeStyle};

  input {
    font-family: monospace !important;
    padding: 0 !important;
  }
`;
