import { Alignment, Button, IconName, Navbar, NavbarDivider, NavbarGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { Badge } from "../../common/Badge";
import {
  mediaQueryNotSmall,
  mediaQuerySmall,
  ResponsiveSpecification,
  useResponsive,
} from "../../common/responsive/Responsive";

export interface GlobalNavbbarVM {
  loginName: string;
  activeScreenMode: string;
  unreadNotificationCount: number;

  showKintai: boolean;
  navigateToKintai: () => void;

  showDashboard: boolean;
  navigateToDashboard: () => void;

  showAdmin: boolean;
  navigateToAdmin: () => void;

  showHelp: boolean;
  navigateToHelp: () => void;

  showProfile: boolean;
  navigateToProfile: () => void;
}

const height = 40;
const smallHeight = 60;

const OurNavbar = styled(Navbar)`
  flex: 0 auto;

  ${mediaQueryNotSmall} {
    &,
    .bp3-navbar-group {
      height: ${height}px;
    }

    transition-property: background-color;
    transition-duration: 0.8s;
    background-image: linear-gradient(to left, white 60%, rgba(255, 255, 255, 0) 100%);

    &.kintai {
      background-color: rgba(216, 255, 107, 0.25);
    }
    &.dashboard {
      background-color: rgba(255, 107, 107, 0.25);
    }
    &.admin {
      background-color: rgba(179, 107, 255, 0.25);
    }
    &.help {
      background-color: rgba(255, 218, 107, 0.25);
    }
    &.profile {
      background-color: rgba(107, 181, 255, 0.25);
    }
  }

  ${mediaQuerySmall} {
    flex: 0 auto;

    display: flex;
    flex-direction: row;

    background-color: #f9f9f9;
    padding: 0;

    &,
    .bp3-navbar-group {
      height: ${smallHeight}px;
    }

    .Menu {
      flex: 1 auto;

      & > * {
        padding: 0;
        height: ${smallHeight}px;
        flex-direction: column;
        width: 100%;

        & > * {
          padding: 0;
          height: ${smallHeight}px;
          flex-direction: column;
          width: 100%;
        }

        .bp3-icon {
          padding-top: 8px;
          padding-bottom: 5px;
        }

        .bp3-button-text {
          display: block;
          height: ${smallHeight - 32}px;
          overflow: hidden;
          padding-top: 3px;

          .Active {
            color: #999;
          }

          .NavBarOmissibleText {
            font-size: 8pt;
            line-height: 8pt;
            display: block;
            text-align: center;
            transition-property: width;
            transition-timing-function: ease-out;
            transition-duration: 0.2s;
          }
        }
      }
    }

    .NavbarButton {
    }
  }
`;

export interface GlobalNavbarProps {
  state: "init" | "started";
  model: GlobalNavbbarVM;
}

@observer
class OurGlobalNavbar extends React.Component<GlobalNavbarProps & ResponsiveSpecification> {
  public render() {
    const { state, small } = this.props;

    if (state === "init") {
      return <OurNavbar />;
    }

    const { model } = this.props;

    const profileMenu = this.renderMenu(
      model.activeScreenMode === "profile",
      model.loginName,
      "user",
      model.navigateToProfile,
      model.unreadNotificationCount,
    );

    return (
      <OurNavbar className={classNames(model.activeScreenMode)}>
        <NavbarGroup className={classNames("Menu")} align={Alignment.LEFT}>
          {model.showKintai &&
            this.renderMenu(model.activeScreenMode === "kintai", "Web勤怠", "timeline-events", model.navigateToKintai)}
          {model.showDashboard &&
            this.renderMenu(model.activeScreenMode === "dashboard", "一覧", "dashboard", model.navigateToDashboard)}
          {model.showAdmin &&
            this.renderMenu(model.activeScreenMode === "admin", "システム", "cog", model.navigateToAdmin)}
          {model.showHelp && this.renderMenu(model.activeScreenMode === "help", "ヘルプ", "help", model.navigateToHelp)}
          {small && profileMenu}
        </NavbarGroup>

        {!small && (
          <NavbarGroup align={Alignment.RIGHT}>
            <NavbarDivider />

            {profileMenu}
          </NavbarGroup>
        )}
      </OurNavbar>
    );
  }

  private renderMenu(
    active: boolean,
    menuName: string | React.ReactNode,
    iconName: IconName,
    handler: () => void,
    badgeCount = 0,
  ) {
    const { small } = this.props;

    const button = (
      <Button
        active={active}
        minimal={true}
        className={classNames("NavbarButton", active ? "Active" : "Inactive")}
        onClick={handler}
        icon={iconName}>
        <span className={classNames("NavBarOmissibleText")}>{menuName}</span>
      </Button>
    );

    return (
      <Badge count={badgeCount} hide={badgeCount === 0}>
        {button}
      </Badge>
    );
  }
}

export const GlobalNavbar: React.FunctionComponent<GlobalNavbarProps> = props => (
  <OurGlobalNavbar {...props} {...useResponsive()} />
);
