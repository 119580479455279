import { Button, IPopoverProps, Popover, Position } from "@blueprintjs/core";
import React from "react";

import { isManagerRankPosition } from "../../../models/common/RankPosition";
import { KinmuTypeSelector, KinmuTypeSelectorProps } from "./KinmuTypeSelector";
import { kinmuTypeUiDefs } from "./kinmuTypeUiDefs";

export type KinmuTypeButtonProps = KinmuTypeSelectorProps & {
  buttonProps?: JSX.LibraryManagedAttributes<typeof Button, Button["props"]>;
  popoverProps?: IPopoverProps;
};

export const KinmuTypeButton = (props: KinmuTypeButtonProps) => {
  const { mainKinmuType: kinmuType } = props;
  const { title, icon } = kinmuTypeUiDefs[kinmuType]!;
  return (
    <Popover
      content={<KinmuTypeSelector {...props} />}
      boundary="viewport"
      {...{
        minimal: true,
        position: Position.BOTTOM,
        ...props.popoverProps,
      }}>
      <Button icon={icon} rightIcon="caret-down" {...{ ...props.buttonProps }}>
        {title}
        {isManagerRankPosition(props.rankPosition) ? "(管理職)" : ""}
      </Button>
    </Popover>
  );
};
