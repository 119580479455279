import { ApiDays, Days } from "@webkintai/core";
import { types } from "mobx-state-tree";

export const typesDays = types.custom<number | ApiDays, Days>({
  name: "Days",

  fromSnapshot(value: number | ApiDays) {
    return new Days(typeof value === "number" ? value : value && value.days);
  },
  toSnapshot(value: Days) {
    if (value === undefined) {
      return undefined as any;
    }

    return value.days;
  },
  isTargetType(value: number | Days): boolean {
    if (value === undefined) {
      return true;
    }

    return value instanceof Days;
  },
  getValidationMessage(value: number): string {
    return "";
  },
});
