import styled from "@emotion/styled";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { weekdayName } from "../../../../utils/calendar";
import { CellComponentProps, InteractiveCellComponentProps } from "../../../common/form/CellFormAdaptable";
import { LabelField } from "../../../common/form/LabelField";
import { CalendarPageGridMonthVM } from "./CalendarPageGrid";

export const CalendarWeekdayHeader = observer((props: CellComponentProps & { month: CalendarPageGridMonthVM }) => (
  <LabelField cellPosition={props.cellPosition} mode="cell" hasNoChange={props.month.hasNoChange}>
    {moment(props.month.month).format("MM")}月
  </LabelField>
));

const HolidayColored = styled.span<{ isHoliday: boolean }>`
  ${({ isHoliday }) => (isHoliday ? "color: red ;" : "")}
`;

export const CalendarWeekdayBody = observer(
  (props: InteractiveCellComponentProps & { month: CalendarPageGridMonthVM }) => {
    const day = props.month.dayAt(props.cellPosition.rowIndex);
    if (!day) {
      return <></>;
    }

    return (
      <LabelField
        cellPosition={props.cellPosition}
        onCellFocused={props.onCellFocused}
        isActive={props.isActive}
        mode="cell">
        <HolidayColored isHoliday={day.isHoliday}>{weekdayName(day.date)}</HolidayColored>
      </LabelField>
    );
  },
);
