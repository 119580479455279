import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../form/HourBasedDaysCountField";

export type HourlyLeaveKFProps = {
  model: HourlyLeaveKFVM;
} & CellFormPrintAdaptable;

export interface HourlyLeaveKFVM {
  hourlyLeave: KintaiInputItemState<HourBasedDays | undefined>;
}

export const HourlyLeaveKF = observer((props: HourlyLeaveKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.hourlyLeave);

  return <HourBasedDaysCountField showAsDateCount={false} {...props} label="時間単位有休" {...itemState} />;
});
