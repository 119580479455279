import Help from "@webkintai/help/content/manuals/admin/calendar.md";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { DayCountField } from "../../common/form/DayCountField";
import { KintaiCalendarSelectorButton } from "../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonSection } from "../../common/page/PageRibbonSection";
import { ExcelIORibbon } from "../../common/ribbon/ExcelIORibbon";
import { GuideRibbon } from "../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../common/ribbon/JumpRibbon";
import { SaveRibbon } from "../../common/ribbon/SaveRibbon";

export interface CalendarPageRibbonVM {
  nendo: number;
  onChangeNendo: (nendo: number) => void;
  allowedToEdit: boolean;
  nendoList: number[];
  loadingStatus: LoadingStatusType;
  positiveHolidays: number;
  holidayCount: number;
  workdayCount: number;
  onChangePositiveHoliday: (days: number) => void;
  summerHolidays: number;
  onChangeSummerHoliday: (days: number) => void;
  saveDisabled: boolean;
  onExcelExport: () => void;
  onExcelImport: (file: File) => void;
  /** 保存ボタン */
  onSave: () => void;
  /** リセットボタン */
  onReset: () => void;
}

@observer
export class CalendarPageRibbon extends React.Component<{ model: CalendarPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    const onChangeDate = (d: Date) => {
      this.props.model.onChangeNendo(d.getFullYear());
    };
    const currentNendo = new Date(model.nendo, 4, 1);
    const inputPositiveHoliday = model.onChangePositiveHoliday;
    const inputSummerHoliday = model.onChangeSummerHoliday;
    const disabled = !model.allowedToEdit;

    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <KintaiCalendarSelectorButton
            nendoList={model.nendoList}
            buttonProps={{ small: true }}
            unit="year"
            currentDate={currentNendo}
            onChangeDate={onChangeDate}
          />
        </JumpRibbon>
        {loadingStatus === "loaded" && (
          <>
            <PageRibbonSection title="日数調整">
              <DayCountField
                mode="form"
                label="ポ休日数"
                disabled={disabled}
                value={model.positiveHolidays}
                onChange={inputPositiveHoliday}
              />
              <DayCountField
                mode="form"
                label="夏休日数"
                disabled={disabled}
                value={model.summerHolidays}
                onChange={inputSummerHoliday}
              />
              <DayCountField mode="form" max={999} disabled={true} label="休暇日数" value={model.holidayCount} />
              <DayCountField mode="form" max={999} disabled={true} label="出勤日数" value={model.workdayCount} />
            </PageRibbonSection>
            <ExcelIORibbon
              onExcelExport={model.onExcelExport}
              onExcelImport={disabled ? undefined : model.onExcelImport}
            />
            {model.allowedToEdit && (
              <SaveRibbon
                disabled={model.saveDisabled}
                onSave={model.onSave}
                onReset={model.onReset}
                useTimeoutConfirmReset={true}
              />
            )}
          </>
        )}
      </>
    );
  }
}
