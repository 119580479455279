import { ApiMinutes, Minutes } from "@webkintai/core";
import { types } from "mobx-state-tree";

export const typesMinutes = types.custom<number | ApiMinutes, Minutes>({
  name: "Minutes",

  fromSnapshot(value: number | ApiMinutes) {
    return new Minutes(typeof value === "number" ? value : value && value.minutes);
  },
  toSnapshot(value: Minutes) {
    if (value === undefined) {
      return undefined as any;
    }
    return value.minutes;
  },
  isTargetType(value: number | Minutes): boolean {
    if (value === undefined) {
      return true;
    }
    return value instanceof Minutes;
  },
  getValidationMessage(value: number): string {
    return "";
  },
});
