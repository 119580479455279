import { action, computed } from "mobx";
import { lazyInject } from "../../../didecorators";

import { RankPageModel, RankPageModelSymbol } from "../../../models/ranks/RankPageModel";
import { getNendo, getNendoMonthOf } from "../../../utils/calendar";
import { RankPageGridVM, RankRowVM } from "./grid/RankPageGrid";
import { RankRowVMImpl } from "./grid/RankRowVMImpl";
import { RankPageVM } from "./RankPage";
import { RankPageRibbonVM } from "./ribbon/RankPageRibbon";

export class RankPageVMImpl implements RankPageVM, RankPageRibbonVM, RankPageGridVM {
  public get pageRibbonModel() {
    return this;
  }

  public get rankPageGridModel() {
    return this;
  }
  @computed
  public get nendo() {
    return getNendoMonthOf(this.model.nendo);
  }

  @computed
  public get nendoList() {
    return this.model.nendoList;
  }

  @computed
  public get loadingStatus() {
    return this.model.loadingStatus;
  }

  @lazyInject(RankPageModelSymbol)
  private model: typeof RankPageModel.Type;

  @action.bound
  public onChangeNendo(nendo: Date) {
    this.model.navigateToNendo(getNendo(nendo));
  }

  @action.bound
  public onInitializeWithPrevNendo() {
    this.model.initWithPrevNendo();
  }

  @computed
  get initializeWithPrevYearDisabled() {
    return this.model.initializeWithPrevYearDisabled;
  }

  @computed
  get allowedToEdit() {
    return this.model.allowedToEdit;
  }

  @computed
  public get newRankCodeTextDisabled() {
    return !this.model.allowedToEdit;
  }

  @computed
  public get newRankCodeText() {
    return this.model.newRankCodeText;
  }

  @action.bound
  public onChangeNewRankCodeText(value: string) {
    this.model.setNewRankCodeText(value);
  }

  @action.bound
  public onAddNewRank() {
    this.model.addNewRank();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @computed
  get reloadDisabled() {
    return this.model.reloadDisabled;
  }

  @action.bound
  public onReset() {
    this.model.reset();
  }

  @action.bound
  public onSave() {
    this.model.save();
  }

  @computed
  get saveDisabled() {
    return this.model.saveDisabled;
  }

  @computed
  public get rankRows(): RankRowVM[] {
    return this.model.nendoRanksInput!.ranksArray.map(rank => new RankRowVMImpl(rank));
  }
}
