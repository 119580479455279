import { types } from "mobx-state-tree";

const pjHeaderLabelMaxLength = 50;

const model = types
  .model("PJReportPillarHeaderValues", {
    pjName: types.string,
    pjCode: types.string,
    odCode: types.string,
    labels: types.array(types.string),
    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string),
  })
  .actions(self => {
    return {
      setPjName(value: string) {
        self.pjName = value;
      },
      setPjCode(value: string) {
        self.pjCode = value;
      },
      setOdCode(value: string) {
        self.odCode = value;
      },
      setLabel(idx: number, label: string) {
        self.labels[idx] = label.substring(0, pjHeaderLabelMaxLength);
      },
    };
  });
export const PJReportPillarHeaderValues: PJReportPillarHeaderValuesModelType = model;
type PJReportPillarHeaderValuesInferredType = typeof model;
export interface PJReportPillarHeaderValuesModelType extends PJReportPillarHeaderValuesInferredType {}
