import { HourBasedDays } from "@webkintai/core";
import { injectable } from "inversify";
import { Instance } from "mobx-state-tree";

import { PHUserEntry } from "../../../models/ph/PHUserEntry";

@injectable()
export abstract class PHInputExcel {
  public abstract importExcel(file: File): Promise<PHImportResult>;
  public abstract exportExcel(value: PHExcelEntries, targetNendos: number[]): Promise<Buffer>;
}

export type PHImportResult =
  | {
      status: "success";
      rows: PHImportResultRow[];
    }
  | {
      status: "failure";
      failureReason: string;
    };

export interface PHImportResultRow {
  userId: string;
  vacHoursPerNendo: PHImportResultRowNendo[];
}

export interface PHImportResultRowNendo {
  nendo: number;
  hours: HourBasedDays | undefined;
}

export type PHExcelEntries = Array<Instance<typeof PHUserEntry>>;
