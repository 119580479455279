import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { ApprovalStamp } from "./ApprovalStamp";

export type MonthlyClerkStampProps = {
  model: MonthlyClerkStampVM;
} & CellFormAdaptable;

export interface MonthlyClerkStampVM {
  clerkStamped: boolean;
}

export const MonthlyClerkStamp = observer((props: MonthlyClerkStampProps) => {
  return <ApprovalStamp {...props} stamped={props.model.clerkStamped} />;
});
