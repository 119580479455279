import { NonIdealState } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";

export interface AdminIndexPageVM {}

const Container = styled.div`
  flex: 1 0;
  overflow: auto;

  display: flex;

  & > * {
    height: auto;
  }
`;

@observer
export class AdminIndexPage extends React.Component<{ model: AdminIndexPageVM }> {
  public render() {
    return (
      <>
        <Helmet>
          <title>{decorateTitle("管理者用メニュー")}</title>
        </Helmet>

        <Container>
          <NonIdealState icon="warning-sign" title="管理者用メニュー" description="操作に十分注意してください。" />
        </Container>
      </>
    );
  }
}
