import { observer } from "mobx-react";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface UserNameCellVM {
  userName: string;
}

@observer
export class KLUserNameCell extends React.Component<{ model: UserNameCellVM }> {
  public render() {
    const { model } = this.props;
    return (
      <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
        {model.userName}
      </LabelField>
    );
  }
}
