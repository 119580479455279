import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DateField } from "../../../form/DateField";

export type TransDayKFProps = {
  model: TransDayKFVM;
} & CellFormPrintAdaptable;

export interface TransDayKFVM {
  transDay: KintaiInputItemState<Date | undefined>;
}

export const TransDayKF = observer((props: TransDayKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.transDay);

  return <DateField {...props} label="振替日など" {...itemState} />;
});
