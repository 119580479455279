import { HTMLTable } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { LoadingWidgetContent } from "../fault/LoadingWidgetContent";
import { NoDataWidgetContent } from "../fault/NoDataWidgetContent";

export const PrvUserTable: React.FC = ({ children }) => {
  return (
    <PrvUserTableContainer>
      <PrvUserTableElem>{children}</PrvUserTableElem>
    </PrvUserTableContainer>
  );
};

const PrvUserTableContainer = styled.div`
  width: 35rem;
  min-height: 20rem;
  max-height: 20rem;
  overflow-y: auto;
`;

const PrvUserTableElem = styled(HTMLTable)`
  line-height: 1;
`;

export const PrvUserTableLoading = styled(LoadingWidgetContent)`
  width: 35rem;
  height: 20rem;
`;

export const PrvUserTableNoData = styled(NoDataWidgetContent)`
  width: 35rem;
  height: 20rem;
`;
