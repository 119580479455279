import { IModelType, types } from "mobx-state-tree";

import { typesDays } from "../primitives/Days";
import { typesHourBasedDays } from "../primitives/HourBasedDays";
import { typesMinutes } from "../primitives/Minutes";
import { KintaiSumMonthProjectEntry } from "./KintaiSumMonthProjectEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "KintaiSumMonthEntry_";

const model = types
  .model("KintaiSumMonthEntry", {
    id: types.string,

    nendo: types.number,
    userId: types.string,
    month: types.Date,

    actual: typesMinutes,
    designatedHours: types.maybe(typesMinutes),
    holidayDays: typesDays,
    lawOver: typesMinutes,
    lawOverCalc: typesMinutes,
    lawOverNendoSum: typesMinutes,
    lawOverNine: typesMinutes,
    over: typesMinutes,
    overNendoSum: typesMinutes,
    transOnDays: typesDays,

    hourlyLeave: typesHourBasedDays,
    paidAllDays: typesDays,
    paidHalfDays: typesHourBasedDays,
    plainPaidAllDays: typesDays,
    plainPaidHalfDays: typesHourBasedDays,

    projects: types.array(KintaiSumMonthProjectEntry),
  })
  .views(self => {
    return {
      /** 有休の集計値 */
      get paidDays() {
        return self.paidHalfDays.addDays(self.paidAllDays);
      },
      get plainPaidDays() {
        return self.plainPaidHalfDays.addDays(self.plainPaidAllDays);
      },
    };
  });

export const idKintaiSumMonthEntry = (nendo: number, userId: string, month: Date) =>
  `${idPrefix}${nendo}_${userId}_${month.toISOString()}`;

export type KintaiSumMonthEntryType = typeof KintaiSumMonthEntry.Type;
export const KintaiSumMonthEntry: KintaiSumMonthEntryModelType = model;
type KintaiSumMonthEntryInferredType = typeof model;
export interface KintaiSumMonthEntryModelType extends KintaiSumMonthEntryInferredType {}
