import { IModelType, types } from "mobx-state-tree";

import { typesAbilities } from "./typesAbilities";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.model("Role", {
  roleCode: types.identifier,
  roleName: types.string,
  abilities: types.array(typesAbilities),
});

export type RoleType = typeof Role.Type;

export const RoleSymbol = "Role_Symbol";
export const Role: RoleModelType = model;
type RoleInferredType = typeof model;
export interface RoleModelType extends RoleInferredType {}
