import { KintaiBunrui, NullKintai } from "@webkintai/bunrui";
import { observer, useComputed } from "mobx-react-lite";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { SelectField, SelectFieldOptionItem } from "../../../form/SelectField";

export type TypesKFProps = {
  model: TypesKFVM;
  bunruiIdx: BunruiIdx;
  label?: string;
} & CellFormPrintAdaptable;

export type BunruiIdx = 1 | 2 | 3 | 4;

export interface TypesKFVM {
  types: RegularTypesDef[];
}

export interface RegularTypesDef {
  hasNoChange?: boolean;
  onChange: (value: KintaiBunrui) => void;
  kintaiBunrui: KintaiBunrui;
  disabled: boolean;
  availableKintaiBunrui: KintaiBunrui[];
  errors?: string[];
  warnings?: string[];
  infos?: string[];
}

export const TypesKF = observer((props: TypesKFProps) => {
  const type = useComputed(() => props.model.types[props.bunruiIdx - 1] || null, [props.model.types, props.bunruiIdx]);

  const onChangeHandler = useComputed(() => {
    return (value: string | undefined) => {
      if (type) {
        const found = type.availableKintaiBunrui.find(b => b.code === value);
        type.onChange(found || NullKintai);
      }
    };
  }, [type]);

  const options: SelectFieldOptionItem[] = useComputed(
    () =>
      (type ? type.availableKintaiBunrui : []).map((b: KintaiBunrui) => ({
        label: b.abbrName,
        value: b.code,
      })),
    [type],
  );

  return (
    <SelectField
      {...props}
      errors={type.errors}
      warnings={type.warnings}
      infos={type.infos}
      hasNoChange={type.hasNoChange}
      disabled={type ? type.disabled : true}
      label={props.label || `分類${props.bunruiIdx}`}
      appendUndefinedItem={false}
      value={type ? type.kintaiBunrui.code : ""}
      options={options}
      onChange={onChangeHandler}
    />
  );
});
