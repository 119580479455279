import { action, computed } from "mobx";
import React from "react";

import { lazyInject } from "../../../didecorators";
import { SPortAdminPageModel, SPortAdminPageModelSymbol } from "../../../models/sport/SPortAdminPageModel";
import { SPortAdminPageGridVM } from "./grid/SPortAdminPageGrid";
import { SPortAdminPageGridVMImpl } from "./grid/SPortAdminPageGridVMImpl";
import { SPortAdminPageVM } from "./SPortAdminPage";
import { SPortAdminPageRibbonVM } from "./SPortAdminPageRibbon";

export class SPortAdminPageVMImpl implements SPortAdminPageVM, SPortAdminPageRibbonVM, SPortAdminPageGridVM {
  @computed
  private get nendoTarget() {
    return this.pageModel.currentTarget!;
  }

  @computed
  public get ribbonModel() {
    return this;
  }
  @computed
  public get gridModel() {
    return this;
  }

  @computed
  public get nendo() {
    return this.pageModel.nendo;
  }

  @computed
  public get nendoList() {
    return this.pageModel.nendoList;
  }

  @computed
  public get loadingStatus() {
    return this.pageModel.loadingStatus;
  }

  @computed
  public get months() {
    return this.nendoTarget.months.map(it => new SPortAdminPageGridVMImpl(this, it));
  }

  @lazyInject(SPortAdminPageModelSymbol)
  private pageModel: typeof SPortAdminPageModel.Type;

  @action.bound
  public onChangeNendo(nendo: number) {
    this.pageModel.navigateToNendo(nendo);
  }

  @computed
  public get allowedToEdit() {
    return this.nendoTarget.allowedToEdit;
  }

  @computed
  public get saveDisabled() {
    return this.nendoTarget.hasNoChange;
  }

  @action.bound
  public onSave() {
    this.nendoTarget.save();
  }

  @action.bound
  public onReset() {
    this.nendoTarget.reset();
  }
}
