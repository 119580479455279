import { computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { ByOrderUserRow } from "../../../../models/kintai_sum/ByOrderView";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { KintaiSumTableByOrderUserRowVM } from "./KintaiSumTableByOrderUser";

export class KintaiSumTableByOrderUserRowVMImpl implements KintaiSumTableByOrderUserRowVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  constructor(private readonly model: ByOrderUserRow) {}

  @computed
  get id() {
    return `${this.userId}_${this.pjCode}_${this.odCode}`;
  }

  @computed
  get odCode() {
    return this.model.odCode;
  }

  @computed
  get pjCode() {
    return this.model.pjCode;
  }

  @computed
  get pjName() {
    return this.model.pjName;
  }

  @computed
  get userId() {
    return this.model.userId;
  }

  @computed
  get userName() {
    return this.model.userName;
  }

  @computed
  get total() {
    return this.model.total;
  }

  @computed
  get months() {
    return this.model.months.map(month => ({
      ...month,
      onNavigateToKintai: () => {
        this.appRouter.navigate(
          paths.kintai
            .ofUser(this.userId)
            .ofDate(month.month)
            .index(),
        );
      },
    }));
  }
}
