import { types } from "mobx-state-tree";

export const AdminPageScreenModes = types.enumeration("AdminPageScreenModes", [
  "warnings",
  "accounts",
  "ph",
  "depts",
  "calendar",
  "ranks",
  "pjclose",
  "sport",
]);
