import { Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../models/statistics/usePrivateDDModel";
import { DashboardWidget } from "../../DashboardWidget";
import { DashboardWidgetLastUpdate } from "../../lastupdate/DashboardWidgetLastUpdate";
import {
  PrvKintaiWarningsTableContainer,
  PrvKintaiWarningsTableCounterContainer,
} from "./tables/PrvKintaiWarningsTableContainer";
import {
  PrvLawoverCalcTableContainer,
  PrvLawoverCalcTableCounterContainer,
} from "./tables/PrvLawoverCalcTableContainer";
import { PrvLawoverTableContainer, PrvLawoverTableCounterContainer } from "./tables/PrvLawoverTableContainer";

export const PrvMonthlyWidgetContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return (
    <DashboardWidget
      mood={model.monthlyDataWarned ? "bad" : "good"}
      title={"[当月] あなたの部下について"}
      lastUpdate={<DashboardWidgetLastUpdate lastUpdate={model.monthlyContentLastUpdate} />}>
      <Tabs
        id="TabsExample"
        onChange={model.setActiveMonthlyContent}
        selectedTabId={model.activeMonthlyContent}
        vertical={false}>
        <Tab id="lawover" title={<PrvLawoverTableCounterContainer />} panel={<PrvLawoverTableContainer />} />
        <Tab
          id="lawoverCalc"
          title={<PrvLawoverCalcTableCounterContainer />}
          panel={<PrvLawoverCalcTableContainer />}
        />
        <Tab
          id="kintaiAlerts"
          title={<PrvKintaiWarningsTableCounterContainer />}
          panel={<PrvKintaiWarningsTableContainer />}
        />
      </Tabs>
    </DashboardWidget>
  );
});
