import { computed } from "mobx";

import { MonthlyFatigueCheckList } from "../../../models/profile/MonthlyFatigueCheckList";
import { MonthlyInterviewPlan } from "../../../models/profile/MonthlyInterviewPlan";
import { ProfileInterviewRowVM } from "./ProfileInterviewPage";
import { ProfileInterviewPageVMImpl } from "./ProfileInterviewPageVMImpl";

export class ProfileInterviewPageRowVMImpl implements ProfileInterviewRowVM {
  constructor(
    private readonly parent: ProfileInterviewPageVMImpl,
    private readonly ivModel: typeof MonthlyInterviewPlan.Type | undefined,
    private readonly ftgModel: typeof MonthlyFatigueCheckList.Type | undefined,
  ) {}

  @computed
  get month() {
    if (this.ivModel) {
      return this.ivModel.month;
    }

    if (this.ftgModel) {
      return this.ftgModel.month;
    }

    throw new Error("both null");
  }

  @computed
  get lawOver() {
    if (this.ivModel) {
      return this.ivModel.lawOver;
    }

    if (this.ftgModel) {
      return this.ftgModel.lawOver;
    }

    throw new Error("both null");
  }

  @computed
  get sundayOnCount() {
    if (this.ivModel) {
      return this.ivModel.sundayOnCount;
    }

    if (this.ftgModel) {
      return this.ftgModel.sundayOnCount;
    }

    throw new Error("both null");
  }

  @computed
  get fatigue() {
    if (!this.ftgModel) {
      return undefined;
    }

    return {
      hasNoChange: this.ftgModel.hasNoChange,
      submitDate: this.ftgModel.submitDate,
      onChangeSubmitDate: this.ftgModel.setSubmitDate,
    };
  }

  @computed
  get interview() {
    if (!this.ivModel) {
      return undefined;
    }

    return {
      hasNoChange: this.ivModel.hasNoChange,
      receptionStatus: this.ivModel.receptionStatus,
      reservedDate: this.ivModel.reservedDate,
      onChangeReservedDate: this.ivModel.setReservedDate,
    };
  }
}
