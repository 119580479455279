import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { mediaQueryNotLarge, useResponsive } from "../responsive/Responsive";

export interface PageRibbonProps {
  ribbon: React.ReactNode;
  shrinkRibbon?: React.ReactNode;
  hideExpandButton?: boolean;
}

const Component = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

const ShrinkRibbonHeader = styled.div`
  flex: 0 0 auto;

  padding-top: 1px;
  padding-bottom: 1px;
  overflow: hidden;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
`;

const RibbonHeader = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #ccc;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #eee;
  padding: 0.4ex 0 0.4ex 0.6ex;

  * {
    white-space: nowrap;
  }

  ${mediaQueryNotLarge} {
    font-size: 10pt;
    padding: 0;
    .bp3-button {
      font-size: 10pt;
      padding-top: 0ex;
      padding-bottom: 0ex;
      margin: 0;
    }
  }
`;

const RibbonHeaderDiv = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: row;
`;

const RibbonBody = styled.div`
  flex: 1 auto;

  display: flex;
  overflow: hidden;
`;

export const PageRibbon: React.FunctionComponent<PageRibbonProps> = props => {
  const shrinkDisplayed = !!props.shrinkRibbon;
  const [shrinked, setShrinked] = React.useState(true);
  const onShrinkButtonClicked = React.useCallback(() => {
    setShrinked(!shrinked);
  }, [setShrinked, shrinked]);
  const { small } = useResponsive();

  const shrinkToggleButton =
    props.hideExpandButton === true ? (
      <></>
    ) : (
      <Button
        small={true}
        minimal={true}
        icon={shrinked ? "small-plus" : "small-minus"}
        onClick={onShrinkButtonClicked}
      />
    );

  return (
    <Component>
      {small && shrinkDisplayed ? (
        shrinked ? (
          <ShrinkRibbonHeader>
            {shrinkToggleButton}
            {props.shrinkRibbon}
          </ShrinkRibbonHeader>
        ) : (
          <RibbonHeader>
            {shrinkToggleButton}
            <RibbonHeaderDiv>{props.ribbon}</RibbonHeaderDiv>
          </RibbonHeader>
        )
      ) : (
        <RibbonHeader>
          <RibbonHeaderDiv>{props.ribbon}</RibbonHeaderDiv>
        </RibbonHeader>
      )}
      <RibbonBody>{props.children}</RibbonBody>
    </Component>
  );
};
