import { observer } from "mobx-react-lite";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";
import { PageRibbon } from "../../common/page/PageRibbon";
import { StatisticsPageRibbon } from "./StatisticsPageRibbon";
import { DashboardTemplate } from "./template/DashboardTemplate";
import { PrvMonthlyWidgetContainer } from "./widget/container/prv/PrvMonthlyWidgetContainer";
import { PrvNendoWidgetContainer } from "./widget/container/prv/PrvNendoWidgetContainer";
import { PubAvgLawoverAscWidgetContainer } from "./widget/container/pub/PubAvgLawoverAscWidgetContainer";
import { PubAvgLawoverDescWidgetContainer } from "./widget/container/pub/PubAvgLawoverDescWidgetContainer";
import { PubAvgPaidLeavesRatioAscWidgetContainer } from "./widget/container/pub/PubAvgPaidLeavesRatioAscWidgetContainer";
import { PubAvgPaidLeavesRatioDescWidgetContainer } from "./widget/container/pub/PubAvgPaidLeavesRatioDescWidgetContainer";
import { PubAvgPaidLeavesTakenAscWidgetContainer } from "./widget/container/pub/PubAvgPaidLeavesTakenAscWidgetContainer";
import { PubAvgPaidLeavesTakenDescWidgetContainer } from "./widget/container/pub/PubAvgPaidLeavesTakenDescWidgetContainer";

export const StatisticsPage: React.FC = observer(() => {
  return (
    <PageRibbon ribbon={<StatisticsPageRibbon />}>
      <Helmet>
        <title>{decorateTitle("ダッシュボード")}</title>
      </Helmet>
      <DashboardTemplate
        publicGood={
          <>
            <PubAvgPaidLeavesRatioDescWidgetContainer />
            <PubAvgPaidLeavesTakenDescWidgetContainer />
            <PubAvgLawoverAscWidgetContainer />
          </>
        }
        publicBad={
          <>
            <PubAvgPaidLeavesRatioAscWidgetContainer />
            <PubAvgPaidLeavesTakenAscWidgetContainer />
            <PubAvgLawoverDescWidgetContainer />
          </>
        }
        privateData={
          <>
            <PrvMonthlyWidgetContainer />
            <PrvNendoWidgetContainer />
          </>
        }
      />
    </PageRibbon>
  );
});
