import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { MTotalPaidDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/composite/MTotalPaidDaysKF";
import { MTotalPaidAllDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/old/MTotalPaidAllDaysKF";
import { MTotalPaidHalfDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/old/MTotalPaidHalfDaysKF";
import { MTotalOtherDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/plain/MTotalOtherDays";
import { MTotalAllNightCountKF } from "../../../common/kintai-table/monthly/fields/MTotalAllNightCountKF";
import { MTotalAllNightOffDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalAllNightOffDaysKF";
import { MTotalCompDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalCompDaysKF";
import { MTotalGivenWorkKF } from "../../../common/kintai-table/monthly/fields/MTotalGivenWorkKF";
import { MTotalHolidayDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalHolidayDaysKF";
import { MTotalLeaveDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalLeaveDaysKF";
import { MTotalMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalMidnightKF";
import { MTotalMissedKF } from "../../../common/kintai-table/monthly/fields/MTotalMissedKF";
import { MTotalNonAttendanceDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalNonAttendanceDaysKF";
import { MTotalNormalDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalNormalDaysKF";
import { MTotalOffCountKF } from "../../../common/kintai-table/monthly/fields/MTotalOffCountKF";
import { MTotalOtherHoursKF } from "../../../common/kintai-table/monthly/fields/MTotalOtherHours";
import { MTotalOverHolidayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../../common/kintai-table/monthly/fields/MTotalOverKF";
import { MTotalOverWeekdayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalPositiveDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalPositiveDaysKF";
import { MTotalSickoutDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSickoutDaysKF";
import { MTotalSpDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSpDaysKF";
import { MTotalSummerDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSummerDaysKF";
import { MTotalTransOffDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalTransOffDaysKF";
import { MTotalTransOnDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalTransOnDaysKF";
import { VacantDayItemsVisibility } from "../../../common/kintai-table/visibility/VacantDayItemsVisibility";
import { GridSize } from "../../../common/table/GridSize";
import { GridTable } from "../../../common/table/GridTable";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { kintaiListPageGridItemsBody, kintaiListPageGridItemsHeader } from "../common/CommonKintaiListPageGridItems";
import { HeaderLabelJikangai, HeaderLabelYasumi } from "../common/KintaiListHeaderLabels";
import { SPortKintaiListPageGridRowVM } from "./SPortKintaiListPageGridRowVM";

export interface SPortKintaiListPageGridVM {
  sPortRows: SPortKintaiListPageGridRowVM[];
  month: Date;
  showsBulkAuth: boolean;
}

const standardWidth = GridSize.hhmmTotalField;

@observer
export class SPortKintaiListPageGrid extends React.Component<{ model: SPortKintaiListPageGridVM }> {
  public render() {
    const { sPortRows: rows } = this.props.model;
    const firstRow = rows[0]!;

    return (
      <GridTable
        rowIdGenerator={this.rowIdGenerator}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { sPortRows: rows } = this.props.model;

    return rows[rowIndex - 1].userId;
  };

  @computed
  private get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { sPortRows: rows, showsBulkAuth } = model;

    return kintaiListPageGridItemsHeader(rows, showsBulkAuth);
  }

  @computed
  private get bodyColumns(): TableColumnDef[] {
    const { sPortRows: rows, month } = this.props.model;
    const firstRow = rows[0]!;
    const vacantVisibility = new VacantDayItemsVisibility(month);

    const result = kintaiListPageGridItemsBody(rows);

    result.push(
      {
        id: "振替出勤",
        width: standardWidth,
        renderHeader: () => (
          <>
            振替
            <br />
            出勤
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalTransOnDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "振替休日",
        width: standardWidth,
        renderHeader: () => (
          <>
            振替
            <br />
            休日
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalTransOffDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "平常出勤",
        width: standardWidth,
        renderHeader: () => (
          <>
            平常
            <br />
            出勤
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalNormalDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "休日出勤",
        width: standardWidth,
        renderHeader: () => (
          <>
            休日
            <br />
            出勤
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalHolidayDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "徹夜回数",
        width: standardWidth,
        renderHeader: () => (
          <>
            徹夜
            <br />
            回数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalAllNightCountKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
    );

    if (vacantVisibility.sickoutDaysOrNonattendanceDays === "sickoutDays") {
      result.push({
        id: "欠勤回数(休業含)",
        width: standardWidth,
        renderHeader: () => (
          <>
            欠勤
            <br />
            回数
            <br />
            (休業等)
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalSickoutDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    } else {
      result.push({
        id: "欠勤回数",
        width: standardWidth,
        renderHeader: () => (
          <>
            欠勤
            <br />
            回数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalNonAttendanceDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    if (vacantVisibility.leaveDays) {
      result.push({
        id: "休業日数",
        width: standardWidth,
        renderHeader: () => (
          <>
            休業
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLeaveDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    result.push(
      {
        id: "代休日数",
        width: standardWidth,
        renderHeader: () => (
          <>
            代休
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalCompDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "徹休日数",
        width: standardWidth,
        renderHeader: () => (
          <>
            徹休
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalAllNightOffDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
    );

    if (vacantVisibility.showAggregatedPaidDays) {
      result.push({
        id: "有休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            有休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "その他",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            その他
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOtherDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      if (vacantVisibility.showAggregatedPaidHours) {
        result.push({
          id: "その他(時間)",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelYasumi />
              その他
              <br />
              時間休
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOtherHoursKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        });
      }
    } else {
      result.push({
        id: "ポ休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            <br />
            ポ休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPositiveDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "有休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            有休
            <br />
            (終日)
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidAllDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "半休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            半休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidHalfDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      if (vacantVisibility.summerDays) {
        result.push({
          id: "夏休",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelYasumi />
              夏休
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSummerDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        });
      }
      result.push({
        id: "特休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            特休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalSpDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }
    result.push(
      {
        id: "遅刻早退外出",
        width: standardWidth,
        renderHeader: () => (
          <>
            遅刻
            <br />
            早退
            <br />
            外出
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOffCountKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "所定勤務時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            所定
            <br />
            勤務
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalGivenWorkKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "欠勤時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            欠勤
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalMissedKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "深夜時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            深夜
            <br />
            勤務
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "時間外勤務時間平日",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelJikangai />
            平日
            <br />
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOverWeekdayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "時間外勤務時間平日深夜",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelJikangai />
            平日
            <br />
            深夜
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOverWeekdayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "時間外勤務時間休日",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelJikangai />
            休日
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOverHolidayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "時間外勤務時間休日深夜",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelJikangai />
            休日
            <br />
            深夜
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOverHolidayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "時間外時間数",
        width: standardWidth,
        renderHeader: () => (
          <>
            時間外
            <br />
            時間数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOverKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
    );

    return result;
  }
}
