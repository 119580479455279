import { flatMap } from "lodash-es";
import { types } from "mobx-state-tree";

import { dateEq } from "../../../utils/date";
import { kintaiErrorDateFormat } from "../utils/KintaiErrorMessage";
import { reduceMinutes } from "./PJReportDailyCell";
import { PJReportTotalPillarDay } from "./PJReportTotalPillarDay";

const model = types
  .model("PJReportTotalPillar", {
    days: types.array(PJReportTotalPillarDay),
    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string)
  })
  .views(self => {
    return {
      get totalErrors() {
        return [
          ...self.errors.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.errors.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },
      get totalWarnings() {
        return [
          ...self.warnings.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.warnings.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },
      get totalInfos() {
        return [
          ...self.infos.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.infos.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },

      getAt(date: Date) {
        return self.days.find(d => dateEq(d.date, date));
      },

      get actual() {
        return reduceMinutes(self.days.map(it => it.actual));
      },
      get populated() {
        return reduceMinutes(self.days.map(it => it.populated));
      },
      get notPopulated() {
        return reduceMinutes(self.days.map(it => it.notPopulated));
      },
    };
  });
export const PJReportTotalPillar: PJReportTotalPillarModelType = model;
type PJReportTotalPillarInferredType = typeof model;
export interface PJReportTotalPillarModelType extends PJReportTotalPillarInferredType {}
