import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { ErrorOverlay } from "../../../form/ErrorOverlay";

export interface MonthlyHeadingKFProps {
  model: MonthlyHeadingKFVM;
}

export interface MonthlyHeadingKFVM {
  month: Date;
  warnings: string[];
  errors: string[];
  infos: string[];
}

export const MonthlyHeadingKF = observer((props: MonthlyHeadingKFProps) => {
  const { model } = props;
  const d = moment(model.month);

  return (
    <ErrorOverlay mode="cell" errors={model.errors} warnings={model.warnings} infos={model.infos}>
      <div>
        {d.format("YYYY年")}
        <br />
        {d.format("MM月")}
      </div>
    </ErrorOverlay>
  );
});
