// import pathToRegexp from "path-to-regexp";

export const paths = {
  /** トップページ */
  index: () => "/",

  kintai: {
    /** 勤怠ページ */
    index: () => "/kintai",

    ofUser: (userId: string) => ({
      /** 勤怠: ユーザID */
      index: () => `${paths.kintai.index()}/${userId}`,

      ofDate: (d: Date) =>
        paths.kintai
          .ofUser(userId)
          .ofYearMonth(d.getFullYear(), d.getMonth() + 1)
          .ofDay(d.getDate()),

      ofYearMonth: (year: number, month: number) => ({
        /** 勤怠: 年月 */
        index: () => `${paths.kintai.ofUser(userId).index()}/${year}/${month}`,

        ofDay: (day: number) => ({
          index: () =>
            `${paths.kintai
              .ofUser(userId)
              .ofYearMonth(year, month)
              .index()}/${day}`,
        }),
      }),
    }),
  },

  dashboard: {
    /** ダッシュボードページ */
    index: () => "/dashboard",

    statistics: {
      /** 統計 */
      index: () => `${paths.dashboard.index()}/statistics`,

      ofDate: (date: Date) => paths.dashboard.statistics.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

      ofYearMonth: (year: number, month: number) => ({
        index: () => `${paths.dashboard.statistics.index()}/${year}/${month}`,
      }),
    },

    kintai_sum: {
      /** 勤怠集計 */
      index: () => `${paths.dashboard.index()}/kintai_sum`,

      /** 部門検索 */
      ofDate: (date: Date) => paths.dashboard.kintai_sum.ofYear(date.getFullYear()),

      ofYear: (year: number) => ({
        index: () => `${paths.dashboard.kintai_sum.index()}/${year}`,

        search: (depCode: string) => `${paths.dashboard.kintai_sum.ofYear(year).index()}/${depCode}`,
      }),
    },

    kintai_list: {
      /** 勤怠一覧 */
      index: () => `${paths.dashboard.index()}/kintai_list`,

      /** 部門検索 */
      dept: {
        index: () => `${paths.dashboard.kintai_list.index()}/dept`,

        ofDate: (date: Date) => paths.dashboard.kintai_list.dept.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

        ofYearMonth: (year: number, month: number) => ({
          index: () => `${paths.dashboard.kintai_list.dept.index()}/${year}/${month}`,

          search: (depCode: string) =>
            `${paths.dashboard.kintai_list.dept.ofYearMonth(year, month).index()}/${depCode}`,
        }),
      },

      /** 査閲・承認検索 */
      reviewee: {
        index: () => `${paths.dashboard.kintai_list.index()}/reviewee`,

        ofDate: (date: Date) =>
          paths.dashboard.kintai_list.reviewee.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

        ofYearMonth: (year: number, month: number) => ({
          index: () => `${paths.dashboard.kintai_list.reviewee.index()}/${year}/${month}`,
        }),
      },

      /** 総務人事承認検索 */
      privileged: {
        index: () => `${paths.dashboard.kintai_list.index()}/privileged`,

        ofDate: (date: Date) =>
          paths.dashboard.kintai_list.privileged.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

        ofYearMonth: (year: number, month: number) => ({
          index: () => `${paths.dashboard.kintai_list.privileged.index()}/${year}/${month}`,
        }),
      },
    },

    trans_day: {
      /** 振替日チェック */
      index: () => `${paths.dashboard.index()}/trans_day`,
    },

    export: {
      /** エクスポート */
      index: () => `${paths.dashboard.index()}/export`,

      ofDate: (date: Date) => paths.dashboard.export.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

      ofYearMonth: (year: number, month: number) => ({
        index: () => `${paths.dashboard.export.index()}/${year}/${month}`,
      }),
    },

    interview_list: {
      /** 産業医面接一覧 */
      index: () => `${paths.dashboard.index()}/interview_list`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.dashboard.interview_list.index()}/${nendo}`,
      }),
    },

    saburoku: {
      /** 三六一覧 */
      index: () => `${paths.dashboard.index()}/saburoku`,

      ofDate: (date: Date) => paths.dashboard.saburoku.ofYearMonth(date.getFullYear(), date.getMonth() + 1),

      ofYearMonth: (year: number, month: number) => ({
        index: () => `${paths.dashboard.saburoku.index()}/${year}/${month}`,
      }),
    },

    applications: {
      /** 申請一覧 */
      index: () => `${paths.dashboard.index()}/applications`,
    },
  },

  admin: {
    index: () => "/admin",

    warnings: {
      /** システム管理（注意） */
      index: () => `${paths.admin.index()}/warnings`,
    },

    accounts: {
      /** アカウント管理 */
      index: () => `${paths.admin.index()}/accounts`,
    },

    ph: {
      /** 有給休暇管理 */
      index: () => `${paths.admin.index()}/ph`,
    },

    depts: {
      /** 部門設定 */
      index: () => `${paths.admin.index()}/depts`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.admin.depts.index()}/${nendo}`,
      }),
    },

    calendar: {
      /** カレンダー管理 */
      index: () => `${paths.admin.index()}/calendar`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.admin.calendar.index()}/${nendo}`,
      }),
    },

    ranks: {
      /** ランク管理 */
      index: () => `${paths.admin.index()}/ranks`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.admin.ranks.index()}/${nendo}`,
      }),
    },

    pjClose: {
      /** ランク管理 */
      index: () => `${paths.admin.index()}/pjclose`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.admin.pjClose.index()}/${nendo}`,
      }),
    },

    sport: {
      /** ランク管理 */
      index: () => `${paths.admin.index()}/sport`,

      ofNendo: (nendo: number) => ({
        index: () => `${paths.admin.sport.index()}/${nendo}`,
      }),
    },
  },

  help: {
    index: () => "/about",
  },
  profile: {
    /** プロファイルページ */
    index: () => "/profile",
    inbox: {
      /** 通知ページ */
      index: () => `${paths.profile.index()}/inbox`,
    },
    settings: {
      /** 設定ページ */
      index: () => `${paths.profile.index()}/settings`,
    },
    interview: {
      /** 産業医面接 */
      index: () => `${paths.profile.index()}/interview`,
    },
  },
};
