import "./index.css";
import "moment/locale/ja";
import "reflect-metadata";

import { configure } from "mobx";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./components/app/App";

require("portable-fetch");
configure({ enforceActions: "observed" });

moment.locale("ja");

const rootElem = document.getElementById("root") as HTMLElement;
ReactDOM.render(<App />, rootElem);

if (module.hot) {
  module.hot.accept(["./components/app/App"], () => {
    console.log("[HMR] Rerender React component from the root.");
    ReactDOM.render(<App />, rootElem);
  });
}
