import { reverse, sortBy } from "lodash-es";

import { DashboardSimpleChartRow } from "./DashboardSimpleChartData";

export const sortAndRoundDashboardSimpleChartRows = (data: DashboardSimpleChartRow[], orderBy: "asc" | "desc") => {
  const result = sortBy(
    data.map(it => ({ ...it })),
    it => it.value,
    it => it.code,
  );
  return orderBy === "asc" ? result : reverse(result);
};
