import { getEnv, types } from "mobx-state-tree";

import { fromPaths, KintaiOfDateReturnType } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { TimeProvider } from "../../services/TimeProvider";
import { dateOf } from "../../utils/date";
import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { ProfileSymbol, ProfileType } from "../profile/Profile";
import { KintaiReportPageModel } from "./KintaiReportPageModel";
import { KintaiUsersSymbol, KintaiUsersType } from "./KintaiUsers";

const model = types.optional(
  types
    .model("KintaiPageModel", {
      kintaiReport: KintaiReportPageModel,

      filterText: types.optional(types.string, ""),
    })
    .views(self => {
      return {
        get searchWords() {
          return filterTextToSearchWords(self.filterText);
        },
      };
    })
    .views(self => {
      const profile = (): ProfileType => getEnv(self).get(ProfileSymbol);
      const kintaiUsers = (): KintaiUsersType => getEnv(self).get(KintaiUsersSymbol);
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        get url(): string {
          const { kintaiReport } = self;
          return paths.kintai
            .ofUser(kintaiReport.userId!)
            .ofYearMonth(kintaiReport.date!.getFullYear(), kintaiReport.date!.getMonth() + 1)
            .ofDay(kintaiReport.date!.getDate())
            .index();
        },
        get currentKintai() {
          const { kintaiReport } = self;
          return kintaiReport.userId ? kintaiUsers().ofUserId(kintaiReport.userId) : undefined;
        },
        get date() {
          return self.kintaiReport.date;
        },
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get openedKintaiUserId() {
          return self.kintaiReport && self.kintaiReport.userId;
        },
        get myUserId() {
          return profile().userId;
        },
        get myKintaiHasBeenOpened() {
          return this.openedKintaiUserId === this.myUserId;
        },
        get openedKintaiList() {
          return Array.from(kintaiUsers().users.values(), it => ({
            userId: it.userId,
            userName: it.userName || "",
          })).filter(it => checkIfFilterTextMatches([it.userId, it.userName], self.searchWords));
        },
        get openTargetUserId() {
          const mat = self.filterText.match(/^\s*(\d{4,})/);
          return mat ? mat[1] : mat;
        },
        get openByFilterTextDisabled() {
          return !this.openTargetUserId;
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);

      function setDateComponents(date: KintaiOfDateReturnType) {
        const { kintaiReport } = self;
        const { targetYear, targetMonth, targetDay } = date;

        if (targetYear === null) {
          kintaiReport.setDate(kintaiReport.date || dateOf());
          return;
        }

        if (targetDay === null) {
          kintaiReport.setDate(dateOf(targetYear, targetMonth));
          return;
        }

        kintaiReport.setDate(dateOf(targetYear, targetMonth, targetDay));
      }

      return {
        navigateToKintai(userId = self.myUserId) {
          appRouter().navigate(paths.kintai.ofUser(userId).index());
        },
        navigateToDate(d: Date) {
          self.kintaiReport.setDate(d);
          self.kintaiReport.loadIfNeeded();
          appRouter().replaceWithoutEffects(self.url);
        },
        route(pathFragment: string) {
          const { kintaiReport } = self;
          const { targetUserId, others } = fromPaths.kintai.ofUser.index(pathFragment);

          if (targetUserId) {
            kintaiReport.setUserId(targetUserId === "me" ? self.myUserId : targetUserId);
          } else {
            if (!self.kintaiReport.userId) {
              kintaiReport.setUserId(self.myUserId);
            }
          }

          setDateComponents(fromPaths.kintai.ofUser.ofDate.index(others));

          self.kintaiReport.loadIfNeeded();
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
      };
    }),
  {},
);

export const KintaiPageModelSymbol = "KintaiPageModelSymbol";
export const KintaiPageModel: KintaiPageModelModelType = model;
type KintaiPageModelInferredType = typeof model;
export interface KintaiPageModelModelType extends KintaiPageModelInferredType {}
