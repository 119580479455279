import { injectable } from "inversify";
import { Instance } from "mobx-state-tree";

import { NendoCalendarInput } from "../../../models/calendar/input/NendoCalendarInput";

@injectable()
export abstract class NendoCalendarInputExcel {
  public abstract importExcel(
    file: File,
    target: Instance<typeof NendoCalendarInput>,
  ): Promise<NendoCalendarInputExcelImportResult>;
  public abstract exportExcel(value: Instance<typeof NendoCalendarInput>): Promise<Buffer>;
}

export interface NendoCalendarInputExcelImportResult {
  status: "success" | "failure";
  failureReason?: string;
}
