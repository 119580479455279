import styled from "@emotion/styled";
import React from "react";
import { AutoSizer, GridCellRangeRenderer, GridCellRenderer, MultiGrid } from "react-virtualized";

import { getCellMove } from "../form/CellFormAdaptable";
import { CellPosition } from "./CellPosition";
import { BodyCell } from "./cells/BodyCell";
import { HeaderCell } from "./cells/HeaderCell";
import { BLStyle, BRStyle, ComponentStyle, TLStyle, TRStyle } from "./GridTable.module.css";
import { MoveDelta } from "./MoveDelta";
import { RowIdGenerator, TableColumnDef } from "./TableColumnDef";

export interface GridTableVM {
  headerColumns: TableColumnDef[];
  bodyColumns: TableColumnDef[];
  rowCount: number;
  hideCount?: boolean;
  rowIdGenerator?: RowIdGenerator;
  headerRowHeight?: number;
  rowHeight?: number;
  renderFooter?: boolean;
  onMoveCell?: (delta: MoveDelta) => void;
  activeCell?: CellPosition;
}

const totalCountHeight = 14;

const TotalCount = styled.div<{ headerRowHeight: number }>`
  position: absolute;
  top: ${props => props.headerRowHeight - totalCountHeight}px;
  left: 0px;
  height: ${totalCountHeight}px;
  color: #fff;
  opacity: 0.8;
  background-color: #333;
  font-family: monospace;
  font-size: ${totalCountHeight - 3}px;
  border-radius: 5px;
  padding-left: 1ex;
  padding-right: 1ex;
  pointer-events: none;

  &:hover {
  }
`;

export class GridTable extends React.Component<GridTableVM> {
  public render() {
    // console.log("GridTable#render()");
    const { props, allCols } = this;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <>
            {/* enableFixedRowScroll が反映されないので、強制反映させるためにキーを変更 */}
            <MultiGrid
              key={props.rowCount === 0 ? "noRowMode" : "rowMode"}
              width={width}
              height={height}
              // CSS classes
              className={ComponentStyle}
              classNameTopLeftGrid={TLStyle}
              classNameTopRightGrid={TRStyle}
              classNameBottomLeftGrid={BLStyle}
              classNameBottomRightGrid={BRStyle}
              // ヘッダカラムの固定定義
              fixedColumnCount={this.fixedColumnCount}
              fixedRowCount={1}
              enableFixedRowScroll={props.rowCount === 0}
              // 幅・個数定義
              columnWidth={this.columnWidthAt}
              columnCount={this.allColsCount}
              rowCount={this.totalRowCount}
              rowHeight={this.rowHeightAt}
              // レンダリング
              cellRenderer={this.ourCellRenderer}
              cellRangeRenderer={this.cellRangeRenderer}
              // アクティブセル
              {...(props.activeCell
                ? {
                    scrollToRow: props.activeCell.rowIndex,
                    scrollToColumn: props.activeCell.columnIndex,
                  }
                : {})}
            />
            {props.hideCount !== true && (
              <TotalCount key="TotalCount" headerRowHeight={this.headerRowHeight}>
                {this.totalRowCount - 1} 件
              </TotalCount>
            )}
          </>
        )}
      </AutoSizer>
    );
  }

  private get fixedColumnCount() {
    return this.props.headerColumns.length;
  }

  private defaultRowGenerator = (rowIndex: number) => `${rowIndex}`;

  private get rowIdGenerator(): RowIdGenerator {
    return this.props.rowIdGenerator || this.defaultRowGenerator;
  }
  private onMoveCellKeyPressed = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const { props } = this;
    if (!props.onMoveCell) {
      return;
    }

    const keyMove = getCellMove(ev);
    if (!keyMove || (keyMove.x === 0 && keyMove.y === 0)) {
      return;
    }
    ev.preventDefault();

    // keyMove加算して外にはみ出そうならイベントを通知しない
    if (props.activeCell) {
      const afterRowPos = props.activeCell.rowIndex + keyMove.y;
      const afterColPos = props.activeCell.columnIndex + keyMove.x;
      if (afterRowPos < 0 || afterRowPos >= this.totalRowCount || afterColPos < 0 || afterColPos >= this.allColsCount) {
        return;
      }
    }

    props.onMoveCell(keyMove);
  };

  private onMoveOnCell = (cellPosition: CellPosition) => {
    const { props } = this;
    if (!props.onMoveCell || !props.activeCell) {
      return;
    }

    props.onMoveCell({
      x: cellPosition.columnIndex - props.activeCell.columnIndex,
      y: cellPosition.rowIndex - props.activeCell.rowIndex,
    });
  };

  private get totalRowCount() {
    const { props } = this;
    return 1 + (props.renderFooter ? 1 : 0) + props.rowCount;
  }

  private get allCols() {
    const { props } = this;
    return [...props.headerColumns, ...props.bodyColumns];
  }

  private get allColsCount() {
    const { props } = this;
    return props.headerColumns.length + props.bodyColumns.length;
  }

  private ourCellRenderer: GridCellRenderer = prm => {
    const { props } = this;

    const renderHeader: GridCellRenderer = params => {
      const col = this.colDefAt(params.columnIndex);
      const position = { rowIndex: params.rowIndex, columnIndex: params.columnIndex };
      const isActive =
        props.activeCell &&
        props.activeCell.rowIndex === params.rowIndex &&
        props.activeCell.columnIndex === params.columnIndex;

      return (
        <HeaderCell
          key={"header_" + col.id}
          isActive={!!isActive}
          style={params.style}
          cellPosition={position}
          colDef={col}
          fixedColumnCount={this.fixedColumnCount}
        />
      );
    };

    const renderBody: GridCellRenderer = params => {
      const col = this.colDefAt(params.columnIndex);
      const { rowIdGenerator } = this;
      const position = { rowIndex: params.rowIndex, columnIndex: params.columnIndex };
      const isActive =
        props.activeCell &&
        props.activeCell.rowIndex === params.rowIndex &&
        props.activeCell.columnIndex === params.columnIndex;

      return (
        <BodyCell
          key={rowIdGenerator(params.rowIndex) + "," + col.id}
          onMoveCellKeyPressed={this.onMoveCellKeyPressed}
          onMoveOnCell={this.onMoveOnCell}
          cellPosition={position}
          rowIdGenerator={rowIdGenerator}
          colDef={col}
          style={params.style}
          fixedColumnCount={this.fixedColumnCount}
          isActive={!!isActive}
        />
      );
    };

    return prm.rowIndex === 0 ? renderHeader(prm) : renderBody(prm);
  };

  private cellRangeRenderer: GridCellRangeRenderer = cRangeProps => {
    const { props } = this;

    const {
      cellCache,
      cellRenderer,
      columnSizeAndPositionManager,
      columnStartIndex,
      columnStopIndex,
      deferredMeasurementCache,
      horizontalOffsetAdjustment,
      isScrolling,
      rowSizeAndPositionManager,
      rowStartIndex,
      rowStopIndex,
      styleCache,
      verticalOffsetAdjustment,
      visibleColumnIndices,
      visibleRowIndices,
    } = cRangeProps;
    const { isScrollingOptOut, parent } = cRangeProps as any;

    const renderedCells = [];
    const inLeft = parent.props.className === TLStyle || parent.props.className === BLStyle;
    const inLeftTop = parent.props.className === TLStyle;

    // Browsers have native size limits for elements (eg Chrome 33M pixels, IE 1.5M pixes).
    // User cannot scroll beyond these size limitations.
    // In order to work around this, ScalingCellSizeAndPositionManager compresses offsets.
    // We should never cache styles for compressed offsets though as this can lead to bugs.
    // See issue #576 for more.
    const areOffsetsAdjusted =
      columnSizeAndPositionManager.areOffsetsAdjusted() || rowSizeAndPositionManager.areOffsetsAdjusted();

    const canCacheStyle = !isScrolling && !areOffsetsAdjusted;

    const headerIdxOffset = inLeft ? 0 : props.headerColumns.length;

    for (let rowIndex = rowStartIndex; rowIndex <= rowStopIndex; rowIndex++) {
      const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(rowIndex);

      for (let columnIndex = columnStartIndex; columnIndex <= columnStopIndex; columnIndex++) {
        const colDef = this.colDefAt(columnIndex + headerIdxOffset);
        const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(columnIndex);
        const isVisible =
          columnIndex >= visibleColumnIndices.start &&
          columnIndex <= visibleColumnIndices.stop &&
          rowIndex >= visibleRowIndices.start &&
          rowIndex <= visibleRowIndices.stop;
        const key = `${rowIndex}-${columnIndex}`;
        let style;

        // Cache style objects so shallow-compare doesn't re-render unnecessarily.
        if (canCacheStyle && styleCache[key]) {
          style = styleCache[key];
        } else {
          // In deferred mode, cells will be initially rendered before we know their size.
          // Don't interfere with CellMeasurer's measurements by setting an invalid size.
          if (deferredMeasurementCache && !deferredMeasurementCache.has(rowIndex, columnIndex)) {
            // Position not-yet-measured cells at top/left 0,0,
            // And give them width/height of 'auto' so they can grow larger than the parent Grid if necessary.
            // Positioning them further to the right/bottom influences their measured size.
            style = {
              height: "auto",
              left: 0,
              position: "absolute",
              top: 0,
              width: "auto",
            };
          } else {
            let cellWidth = columnDatum.size;
            let spanning = false;
            if (rowIndex === 0 && rowDatum.size === this.rowHeightAt({ index: 0 }) && colDef && colDef.headerColSpan) {
              for (let i = 1; i < colDef.headerColSpan; i++) {
                spanning = true;
                cellWidth += this.colDefAt(columnIndex + headerIdxOffset + i).width;
              }
            }

            style = {
              height: rowDatum.size,
              left: columnDatum.offset + horizontalOffsetAdjustment,
              position: "absolute",
              top: rowDatum.offset + verticalOffsetAdjustment,
              width: cellWidth,
              zIndex: spanning ? 10 : 0,
            };

            styleCache[key] = style as any;
          }
        }

        const cellRendererParams = {
          columnIndex,
          isScrolling,
          isVisible,
          key,
          parent,
          rowIndex,
          style,
        };

        let renderedCell;

        // Avoid re-creating cells while scrolling.
        // This can lead to the same cell being created many times and can cause performance issues for "heavy" cells.
        // If a scroll is in progress- cache and reuse cells.
        // This cache will be thrown away once scrolling completes.
        // However if we are scaling scroll positions and sizes, we should also avoid caching.
        // This is because the offset changes slightly as scroll position changes and caching leads to stale values.
        // For more info refer to issue #395
        //
        // If isScrollingOptOut is specified, we always cache cells.
        // For more info refer to issue #1028
        if ((isScrollingOptOut || isScrolling) && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
          if (!cellCache[key]) {
            cellCache[key] = cellRenderer(cellRendererParams as any);
          }

          renderedCell = cellCache[key];

          // If the user is no longer scrolling, don't cache cells.
          // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
        } else {
          renderedCell = cellRenderer(cellRendererParams as any);
        }

        if (renderedCell == null || renderedCell === false) {
          continue;
        }

        renderedCells.push(renderedCell);
      }
    }

    return renderedCells;
  };

  private rowHeightAt = (param: { index: number }) => {
    const { props } = this;
    return param.index === 0 ? this.headerRowHeight : this.rowHeight;
  };

  private get headerRowHeight() {
    return this.props.headerRowHeight || 40;
  }

  private get rowHeight() {
    return this.props.rowHeight || 20;
  }

  private columnWidthAt = (param: { index: number }) => this.colDefAt(param.index).width;

  private colDefAt(idx: number) {
    const { props } = this;
    const { headerColumns, bodyColumns } = props;
    const headerLength = headerColumns.length;
    if (idx < headerLength) {
      return headerColumns[idx];
    }

    return bodyColumns[idx - headerLength];
  }
}

export const x = (str: string) => ex(str.length);
export const ex = (length: number) => Math.floor(length * 8.3);

export const FocusMarkerClassName = "FocusMe";

// export const cellWidth = {
//   hhmmField:
// }
