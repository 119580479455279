import { computed } from "mobx";

import { Notification } from "../../../models/notify/Notification";
import { OpenedMessageVM } from "./OpenedMessage";

export class OpenedMessageVMImpl implements OpenedMessageVM {
  constructor(private readonly msg: typeof Notification.Type) {}

  @computed
  public get messageLoadingStatus() {
    return this.msg.bodyLoadingState;
  }

  @computed
  public get messageRead() {
    return this.msg.read;
  }
  @computed
  public get subject() {
    return this.msg.subject;
  }

  @computed
  public get message() {
    return this.msg.body || "";
  }

  @computed
  public get timestamp() {
    return this.msg.publishDate;
  }
}
