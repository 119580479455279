import { Icon, InputGroup, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";
import { useCallback } from "react";

import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormPrintAdaptable, getCellMove } from "./CellFormAdaptable";
import { ErrorOverlay } from "./ErrorOverlay";
import { cellFormStyle, cellStyle, componentCommonClasses, formStyle, hasNoChangeStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export const TextField: React.FC<TextFieldProps> = props => {
  if (props.mode === "print") {
    return <>{props.value}</>;
  }

  const ref = React.useRef<HTMLInputElement>(null);
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const formTextRef = React.useRef<HTMLTextAreaElement>(null);

  const onFocus: React.FormEventHandler<unknown> = React.useCallback(
    ev => {
      if (props.mode === "cell" && props.onCellFocused && !props.isActive) {
        props.onCellFocused(props.cellPosition);
      }
    },
    [props.value, props.mode === "cell" && props.onCellFocused, props.mode === "cell" && props.isActive],
  );

  const onChange: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> = React.useCallback(
    ev => {
      if (props.onChange) {
        props.onChange(ev.currentTarget.value);
      }
    },
    [props.onChange],
  );

  const formInput = () => (
    <>
      {props.multiline && (
        <Textarea
          ref={formTextRef}
          className={componentCommonClasses(props)}
          readOnly={props.disabled}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          onKeyDown={disablePropagation}
          onChange={onChange}
        />
      )}
      {!props.multiline && (
        <InputGroup
          className={componentCommonClasses(props)}
          readOnly={props.disabled}
          small={true}
          placeholder={props.placeholder}
          value={props.value}
          maxLength={props.maxLength}
          onChange={onChange}
        />
      )}
    </>
  );

  const form = () => (
    <FormLabel
      label={props.label}
      fullWidth={true}
      disabled={props.disabled}
      errors={props.errors}
      warnings={props.warnings}
      infos={props.infos}>
      {formInput()}
    </FormLabel>
  );

  if (props.mode === "cell") {
    const onOpenDetails = useCallback(() => {
      setTimeout(() => {
        if (formTextRef.current) {
          formTextRef.current.focus();
        }
      }, 0);
    }, [formTextRef]);

    const onCloseDetails = useCallback(() => {
      setTimeout(() => {
        if (btnRef.current) {
          btnRef.current.focus();
        }
      }, 0);
    }, [btnRef]);

    return (
      <ErrorOverlay
        mode={props.mode}
        disabled={props.disabled}
        errors={props.errors}
        warnings={props.warnings}
        infos={props.infos}>
        {props.multiline && (
          <MultilineCellContainer onFocus={onFocus}>
            <Popover
              boundary="viewport"
              content={
                <PopoverContainer>
                  {formInput()}
                  <PopoverDesc>(Escキーで閉じる)</PopoverDesc>
                </PopoverContainer>
              }
              autoFocus={true}
              canEscapeKeyClose={true}
              onClose={onCloseDetails}>
              <button ref={btnRef} onClick={onOpenDetails} className={FocusMarkerClassName}>
                <Icon icon="edit" iconSize={10} />
              </button>
            </Popover>
            <Cell
              className={componentCommonClasses(props)}
              ref={ref}
              value={props.value}
              placeholder={props.placeholder}
              readOnly={true}
              onChange={voidHandler}
            />
          </MultilineCellContainer>
        )}
        {!props.multiline && (
          <Cell
            className={classNames(FocusMarkerClassName, componentCommonClasses(props))}
            readOnly={props.disabled}
            ref={ref}
            value={props.value}
            placeholder={props.placeholder}
            maxLength={props.maxLength}
            onChange={onChange}
          />
        )}
      </ErrorOverlay>
    );
  }

  return form();
};

const voidHandler = () => {};

const disablePropagation = (ev: React.KeyboardEvent<unknown>) => {
  const v = getCellMove(ev);
  if (v && (v.x || v.y)) {
    ev.stopPropagation();
  }
};

export type TextFieldProps = {
  className?: string;
  label?: string;
  multiline?: boolean;
  hasNoChange?: boolean;
  disabled?: boolean;
  maxLength?: number;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
} & CellFormPrintAdaptable;

const Cell = styled.input`
  ${cellStyle} ${cellFormStyle}
  ${hasNoChangeStyle}
  cursor: pointer;
`;

const Textarea = styled.textarea`
  ${hasNoChangeStyle} ${formStyle}
  width: 100%;
  height: 5em;
  border: 1px solid #ccc;
`;

const MultilineCellContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PopoverContainer = styled.div`
  width: 20em;
  padding: 1ex;
  display: flex;
  flex-direction: column;
`;

const PopoverDesc = styled.p`
  padding: 0;
  margin: 0;
  margin-top: 0.5ex;
  text-align: right;
  color: #ccc;
`;
