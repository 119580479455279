import React from "react";

import { InteractiveCellComponentProps } from "../../../common/form/CellFormAdaptable";
import { LabelField } from "../../../common/form/LabelField";

export const CalendarDateRowNumber = (props: InteractiveCellComponentProps) => (
  <LabelField alignment="center" mode="cell" cellPosition={props.cellPosition} onCellFocused={props.onCellFocused}>
    {props.cellPosition.rowIndex}
  </LabelField>
);
