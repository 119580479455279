import Help from "@webkintai/help/content/manuals/dashboard/saburoku.md";
import { observer } from "mobx-react";
import React from "react";

import { FilterTextbox } from "../../../common/FilterTextbox";
import { IncludeManagerCheckbox } from "../../../common/IncludeManagerCheckbox";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";

export interface SaburokuPageRibbonVM {
  nendoList: number[];
  targetMonth: Date;
  onChangeTargetMonth: (value: Date) => void;
  filterText: string;
  onChangeFilterText: (value: string) => void;
  includeManager: boolean;
  onChangeIncludeManager: (value: boolean) => void;
  onReload: () => void;
}

@observer
export class SaburokuPageRibbon extends React.Component<{ model: SaburokuPageRibbonVM }> {
  public render() {
    const { model } = this.props;

    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <PageRibbonLine>
            <KintaiCalendarSelectorButton
              buttonProps={{ small: true }}
              unit="month"
              nendoList={model.nendoList}
              currentDate={model.targetMonth}
              onChangeDate={model.onChangeTargetMonth}
            />
          </PageRibbonLine>
        </JumpRibbon>
        <ReloadRibbon onReload={model.onReload} />
        <FilterRibbon>
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
          <PageRibbonLine>
            <IncludeManagerCheckbox
              includeManager={model.includeManager}
              onChangeIncludeManager={model.onChangeIncludeManager}
            />
          </PageRibbonLine>
        </FilterRibbon>
      </>
    );
  }
}
