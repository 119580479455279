import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../../models/statistics/usePrivateDDModel";
import { PrvUserDatesTable } from "../../../table/PrvUserDatesTable";
import { PrvUserTableWarningCounter } from "../../../table/PrvUserTableCounter";

export const PrvKintaiWarningsTableContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserDatesTable data={model.monthly_kintaiWarnings} atMonth={model.date} />;
});

export const PrvKintaiWarningsTableCounterContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserTableWarningCounter title="勤怠表の警告数" data={model.monthly_kintaiWarnings} />;
});
