import { DailyApplicationEntity, DailyApplicationResult } from "@webkintai/api";
import { 出張, 育介在宅 } from "@webkintai/bunrui";
import { applySnapshot, flow, getEnv, getParentOfType, getSnapshot, IModelType, types } from "mobx-state-tree";

import { KintaiAppApi } from "../../../services/api/KintaiAppApi";
import { AppConfirm } from "../../../services/msg/AppConfirm";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { fromApiDate } from "../../../utils/api";
import { applicationReleaseDate } from "../../../utils/date";
import { getDI } from "../../common/getDI";
import { Profile, ProfileSymbol } from "../../profile/Profile";
import { prefixedIdType } from "../../utils";
import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { MonthlyKintai } from "../MonthlyKintai";
import { ApprovalStatus } from "./ApprovalStatus";
import { DailyApplicationTemplates } from "./DailyApplicationTemplates";
import { KintaiApplicationStage } from "./KintaiApplicationStage";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "KintaiApplicationDay_";

const model = types
  .model("KintaiApplicationDay", {
    id: prefixedIdType(idPrefix),

    userId: types.string,
    date: types.Date,

    // 申請操作中か
    inSaving: types.optional(types.boolean, false),

    // 申請
    applicantUserId: types.maybe(types.string),
    applicantUserName: types.maybe(types.string),
    applicantComment: types.maybe(types.string),
    applicantTimestamp: types.maybe(types.Date),

    // 承認
    approverUserId: types.maybe(types.string),
    approverUserName: types.maybe(types.string),
    approverComment: types.maybe(types.string),
    approvalTimestamp: types.maybe(types.Date),

    // 最終結果
    approvalStatus: types.maybe(ApprovalStatus),
  })
  .views(self => {
    return {
      get monthlyKintai() {
        return getParentOfType(self, MonthlyKintai);
      },
      get regularDailyKintai() {
        return this.monthlyKintai.regularKintai!.days[self.date.getDate() - 1]!;
      },
      get sportDailyKintai() {
        return this.monthlyKintai.sPortKintai!.days[self.date.getDate() - 1]!;
      },
      get mainDailyKintai() {
        return this.monthlyKintai.attrs!.origin.mainKintaiType === "S-Port"
          ? this.sportDailyKintai
          : this.regularDailyKintai;
      },
      get dailyKintaiTypesHasNoChange() {
        return this.mainDailyKintai.typesHasEssentiallyNoChange;
      },
      get approveIsRequireInInput() {
        return this.mainDailyKintai.input.approveIsRequired;
      },
      get approveIsRequired() {
        return this.mainDailyKintai.origin.approveIsRequired;
      },
      get privilegedApproveIsRequired() {
        return this.mainDailyKintai.origin.privilegedApproveIsRequired;
      },
      get types() {
        return [
          this.mainDailyKintai.type1,
          this.mainDailyKintai.type2,
          this.mainDailyKintai.type3,
          this.mainDailyKintai.type4,
        ].map(it => it.kintaiBunrui);
      },
      get templates() {
        return new DailyApplicationTemplates(this.types);
      },
    };
  })
  .views(self => {
    const root = () => getParentOfType(self, MonthlyKintai);
    const profile = (): typeof Profile.Type => getEnv(self).get(ProfileSymbol);

    return {
      get isLocked() {
        return root().applicationIsLocked || self.inSaving;
      },
      get isProxiedApp() {
        return !!(self.applicantUserId && self.applicantUserId !== root().userId);
      },
      get appStage(): KintaiApplicationStage {
        // アプリリリース前の日は申請不要
        if (self.date.getTime() < applicationReleaseDate().getTime()) {
          return "no_need_to_app";
        }

        if (self.approveIsRequired && !self.approveIsRequireInInput) {
          return "no_need_to_app";
        }

        if (!self.approveIsRequired && self.approveIsRequireInInput) {
          return "app_is_required_before_saving";
        }

        if (!self.approveIsRequired) {
          return "no_need_to_app";
        }

        if (!self.applicantTimestamp) {
          if (!self.dailyKintaiTypesHasNoChange) {
            return "app_is_required_before_saving";
          }
          return "app_is_required";
        }

        if (!self.approvalTimestamp) {
          if (self.privilegedApproveIsRequired) {
            return "privileged_approve_is_required";
          }

          return "approve_is_required";
        }
        return self.approvalStatus!;
      },
      // [申請・キャンセル条件]
      // 根本がロックされていれば非活性
      // or 自勤怠 or 他者勤怠を申請可能でなければ非活性
      get cancelDisabled() {
        return this.isLocked || !this.postOrCancellable;
      },
      get postDisabled() {
        return this.isLocked || this.appStage !== "app_is_required" || !this.postOrCancellable;
      },
      get postOrCancellable() {
        return root().isMine || profile().hasAbility("DISP_OTHERSKINTAI_APP");
      },
      get satisfiesApplicantCommentCheck() {
        return !this.applicantCommentIsRequired || self.applicantComment !== "";
      },
      get approveDisabled() {
        // 根本がロックされていれば非活性
        if (this.isLocked) {
          return true;
        }

        // 査閲・承認者による承認が必要な場合は、該当権限がなければ非活性
        if (this.appStage === "approve_is_required") {
          return !root().approveAppsAllowed;
        }

        // 総務人事による承認が必要な場合は、該当権限がなければ非活性
        if (this.appStage === "privileged_approve_is_required") {
          return !root().privilegedApproveAppsAllowed;
        }

        return true;
      },
      get templatesList() {
        return self.templates.templates;
      },
      get applicantCommentIsRequired() {
        return self.templates.applicantCommentIsRequired;
      },
    };
  })
  .actions(self => {
    function root() {
      return getParentOfType(self, MonthlyKintai);
    }
    const appNotifier = () => getDI(self, AppNotifier);
    const appConfirm = () => getDI(self, AppConfirm);
    const kintaiAppApi = () => getDI(self, KintaiAppApi);

    const refreshApp = flow(function*() {
      try {
        // 警告のステータスを変更するために検証をトリガ
        self.monthlyKintai.triggerValidate();

        // なければ(=要申請) catch に行く
        const result: DailyApplicationResult = yield kintaiAppApi().getDailyAppStatus(self.userId, self.date);
        const { application } = result;

        applySnapshot(self, {
          ...getSnapshot(self),
          ...apiValueToKintaiApplicationDayValues(application),
        });
      } catch (exception) {
        applySnapshot(self, {
          ...getSnapshot(self),
          ...apiValueToKintaiApplicationDayValues(undefined),
        });
      }
    });

    const postApp = flow(function*() {
      if (
        !root().isMine &&
        !(yield appConfirm().confirm({
          title: "代理申請をしようとしています",
          message: "本人に代わり、代理で勤怠を申請しようとしています。続行しますか？",
        }))
      ) {
        return;
      }

      try {
        self.inSaving = true;
        yield kintaiAppApi().requestApp(self.userId, self.date, { applicantComment: self.applicantComment || "" });
        appNotifier().info({ message: "勤怠の申請が完了しました。" });
        yield refreshApp();
      } catch (exception) {
        appNotifier().error({ message: "勤怠の申請に失敗しました。", exception });
      } finally {
        self.inSaving = false;
      }
    });

    const cancelApp = flow(function*() {
      if (
        !root().isMine &&
        !(yield appConfirm().confirm({
          title: "代理で申請のキャンセルをしようとしています",
          message: "本人に代わり、代理で勤怠の申請をキャンセルしようとしています。続行しますか？",
        }))
      ) {
        return;
      }

      if (
        !(yield appConfirm().confirm({
          title: "キャンセルの確認",
          message: "申請を本当にキャンセルしますか？",
          yesButtonLabel: "申請をキャンセルする",
          noButtonLabel: "キャンセルしない",
        }))
      ) {
        return;
      }

      try {
        self.inSaving = true;
        yield kintaiAppApi().cancelApp(self.userId, self.date);
        appNotifier().info({ message: "勤怠申請をキャンセルしました。" });
        yield refreshApp();
      } catch (exception) {
        appNotifier().error({ message: "勤怠申請のキャンセルに失敗しました。", exception });
      } finally {
        self.inSaving = false;
      }
    });

    const approveApp = flow(function*() {
      if (self.types.some(it => it === 育介在宅) && self.types.some(it => it === 出張)) {
        if (
          !(yield appConfirm().confirm({
            title: "出張時の勤怠記録 ～正しく管理していますか？～",
            message:
              "出張時は原則、所定勤務時間勤務とみなします(みなし勤務)。\nただし、上長と同行していた、電話やチャット等による逐次報告などの場合や出張前後に出社勤務または在宅勤務を命じた場合は、その実勤務時間も考慮して勤務時間を記録しなければなりません。\n\n勤務の実態を確認した後、承認してください。",
            yesButtonLabel: "理解・確認した上で申請を許可する",
            noButtonLabel: "キャンセル",
          }))
        ) {
          return;
        }
      }

      try {
        self.inSaving = true;
        yield kintaiAppApi().approveApp(self.userId, self.date, { approverComment: self.approverComment || "" });
        appNotifier().info({ message: "勤怠申請の承認が完了しました。" });
        yield refreshApp();
      } catch (exception) {
        appNotifier().error({ message: "勤怠申請の承認に失敗しました。", exception });
      } finally {
        self.inSaving = false;
      }
    });

    const rejectApp = flow(function*() {
      try {
        self.inSaving = true;
        yield kintaiAppApi().rejectApp(self.userId, self.date, { approverComment: self.approverComment || "" });
        appNotifier().info({ message: "勤怠申請を却下しました。" });
        yield refreshApp();
      } catch (exception) {
        appNotifier().error({ message: "勤怠申請の却下に失敗しました。", exception });
      } finally {
        self.inSaving = false;
      }
    });

    return {
      postApp,
      cancelApp,
      approveApp,
      rejectApp,
      setApplicantComment(value: string) {
        self.applicantComment = value;
      },
      setApproverComment(value: string) {
        self.approverComment = value;
      },
    };
  })
  .views(self => {
    return {
      get kintaiTypeShouldBeLocked() {
        return (
          self.appStage !== "app_is_required_before_saving" &&
          self.appStage !== "app_is_required" &&
          self.appStage !== "no_need_to_app"
        );
      },

      get appPostText(): KintaiInputItemState<string> {
        const disabled = self.isLocked || self.postDisabled;
        return {
          get value() {
            return self.applicantComment || "";
          },
          onChange: self.setApplicantComment,
          disabled,
          errors: [],
          warnings: [],
          infos: [],
          get hasNoChange() {
            return true;
          },
        };
      },
      get appApproveText(): KintaiInputItemState<string> {
        const disabled = self.isLocked || self.approveDisabled;
        return {
          get value() {
            return self.approverComment || "";
          },
          onChange: self.setApproverComment,
          disabled,
          errors: [],
          warnings: [],
          infos: [],
          get hasNoChange() {
            return true;
          },
        };
      },
    };
  });

export type KintaiApplicationDayType = typeof KintaiApplicationDay.Type;

export const idKintaiApplicationDay = (userId: string, date: Date) =>
  `${idPrefix}${userId}_${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`;

export const apiValueToKintaiApplicationDayValues = (corr?: DailyApplicationEntity) =>
  corr
    ? {
        applicantUserId: corr.applicantUser ? corr.applicantUser.userId : undefined,
        applicantUserName: corr.applicantUser ? corr.applicantUser.userName : undefined,
        applicantComment: corr.applicantComment || "",
        applicantTimestamp: fromApiDate(corr.applicantTimestamp),

        approverUserId: corr.approverUser ? corr.approverUser.userId : undefined,
        approverUserName: corr.approverUser ? corr.approverUser.userName : undefined,
        approverComment: corr.approverComment || "",
        approvalTimestamp: fromApiDate(corr.approvalTimestamp),

        approvalStatus: corr.approvalStatus ? corr.approvalStatus.value : undefined,
      }
    : {
        applicantUserId: undefined,
        applicantUserName: undefined,
        applicantComment: "",
        applicantTimestamp: undefined,

        approverUserId: undefined,
        approverUserName: undefined,
        approverComment: "",
        approvalTimestamp: undefined,

        approvalStatus: corr,
      };
export const KintaiApplicationDay: KintaiApplicationDayModelType = model;
type KintaiApplicationDayInferredType = typeof model;
export interface KintaiApplicationDayModelType extends KintaiApplicationDayInferredType {}
