export var minutesEq = function minutesEq(l, r) {
  if (l === r) {
    return true;
  }

  if (l === undefined || r === undefined) {
    return false;
  }

  return l.minutes === r.minutes;
};