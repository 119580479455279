import { computed } from "mobx";

import { NendoDeptType } from "../../../models/depts/NendoDept";
import { NendoDeptsType } from "../../../models/depts/NendoDepts";
import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../../utils/searchwords";
import { DeptSelectorVM } from "./DeptSelector";
import { DeptSelectorEntry } from "./DeptSelectorEntry";
import { DeptSelectorEntryImpl } from "./DeptSelectorEntryImpl";

export class DeptSelectorVMImpl implements DeptSelectorVM {
  constructor(
    private readonly selectedDept: NendoDeptType | null | undefined,
    private readonly nendoDepts: NendoDeptsType,
    public readonly filterText: string,
    public readonly onSelectDept: (selected: DeptSelectorEntry) => void,
    public readonly onFilterTextChange: (filterText: string) => void,
    public readonly leafOnly = true,
  ) {}

  @computed
  public get status() {
    return this.nendoDepts.loadingStatus;
  }

  @computed
  public get roots(): DeptSelectorEntry[] {
    const filterWords = filterTextToSearchWords(this.filterText);
    const filterSpecified = this.filterText.length > 0;
    return (
      [...this.nendoDepts.depts.values()]
        // leafOnly であれば leaf のみ
        // leafOnly でないかつ、 フィルターワード がなければ ルートのみ
        .filter(d =>
          this.leafOnly
            ? d.isLeaf && checkIfFilterTextMatches([d.name, d.depCode], filterWords)
            : filterSpecified
            ? checkIfFilterTextMatches([d.name, d.depCode], filterWords)
            : d.isRoot,
        )
        .map(ent => new DeptSelectorEntryImpl(ent, !filterSpecified))
    );
  }

  public get activeDept() {
    if (!this.selectedDept) {
      return null;
    }

    return new DeptSelectorEntryImpl(this.selectedDept, false);
  }
}
