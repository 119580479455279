import styled from "@emotion/styled";
import { range } from "lodash-es";
import React from "react";

import { GridTable, x } from "../table/GridTable";
import { TableColumnDef } from "../table/TableColumnDef";
import { LoadingProps } from "./LaodingProps";
import { LoadingStateSpread } from "./LoadingStateSpread";

export interface GridTableLoadingProps extends LoadingProps {}

const dummyDef = (colPos: number) =>
  ({
    id: `${colPos}`,
    width: x("XXXXXXXX"),
    renderHeader: () => "...",
    renderBody: () => "...",
  } as TableColumnDef);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Progress = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  z-index: 10;
`;

const InsideProgress = styled.div`
  padding-top: 3em;
  margin-left: auto;
  margin-right: auto;
  height: 10em;
  width: 80%;
`;

const DummyTable = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  filter: blur(2px);
  pointer-events: none;
`;

export const GridTableLoading = (props: GridTableLoadingProps) => {
  return (
    <Container>
      <Progress>
        <InsideProgress>
          <LoadingStateSpread {...props} />
        </InsideProgress>
      </Progress>
      <DummyTable>
        <GridTable hideCount={true} headerColumns={[]} bodyColumns={range(1, 20).map(dummyDef)} rowCount={50} />
      </DummyTable>
    </Container>
  );
};
