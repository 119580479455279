import { injectable } from "inversify";

/**
 * アプリグローバルな通知サービス（Toast）
 */
@injectable()
export abstract class AppNotifier {
  public abstract info(content: { message: string }): void;
  public abstract warn(content: { message: string }): void;

  public abstract error(content: { message: string; exception?: any }): void;
}
