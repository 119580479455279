import { flow, getEnv, IModelType, types } from "mobx-state-tree";

import { PasswordApi } from "../../services/api/PasswordApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { sleep } from "../../utils/async";
import { getDI } from "../common/getDI";
import { Login, LoginSymbol } from "./Login";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("PasswordChangeFormModel", {
      cancelable: types.optional(types.boolean, false),
      showConsole: types.optional(types.boolean, false),
      userId: types.optional(types.string, ""),
      currentPassword: types.optional(types.string, ""),
      newPassword: types.optional(types.string, ""),
      newPasswordForConfirm: types.optional(types.string, ""),
    })
    .views(self => ({
      get errors(): string[] {
        const errors: string[] = [];

        if (self.currentPassword === "") {
          errors.push("今のパスワードが未入力です。");
        }

        if (self.newPassword === "") {
          errors.push("新しいパスワードが未入力です。");
        }

        if (self.newPassword !== self.newPasswordForConfirm) {
          errors.push("パスワードが確認用と一致しません。");
        }

        return errors;
      },
    }))
    .actions(self => {
      const passwordApi = (): PasswordApi => getDI(self, PasswordApi);
      const appNotifier = (): AppNotifier => getDI(self, AppNotifier);
      const login = (): typeof Login.Type => getEnv(self).get(LoginSymbol);

      return {
        initialize(cancelable: boolean, userId: string, password: string = "") {
          self.cancelable = cancelable;
          self.showConsole = true;
          this.setUserId(userId);
          this.setCurrentPassword(password);
          this.setNewPassword("");
          this.setNewPasswordForConfirm("");
        },
        setUserId(value: string) {
          self.userId = value;
        },
        setCurrentPassword(value: string) {
          self.currentPassword = value;
        },
        setNewPassword(value: string) {
          self.newPassword = value;
        },
        setNewPasswordForConfirm(value: string) {
          self.newPasswordForConfirm = value;
        },
        savePassword: flow(function*() {
          try {
            yield passwordApi().changePassword(self.userId, self.currentPassword, self.newPassword);
            appNotifier().info({ message: `パスワードを変更しました。` });
            yield sleep(1000);
            self.showConsole = false;
            login().login(self.userId, self.newPassword);
          } catch (exception) {
            appNotifier().error({ message: `パスワード変更時にエラーが発生しました。`, exception });
          }
        }),
        notifyClose() {
          if (self.cancelable) {
            self.showConsole = false;
          }
        },
      };
    }),
  {},
);

export type PasswordChangeFormModelType = typeof PasswordChangeFormModel.Type;

export const PasswordChangeFormModelSymbol = "PasswordChangeFormModel_Symbol";
export const PasswordChangeFormModel: PasswordChangeFormModelModelType = model;
type PasswordChangeFormModelInferredType = typeof model;
export interface PasswordChangeFormModelModelType extends PasswordChangeFormModelInferredType {}
