import { PasswordControllerApi } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { PasswordApi } from "./PasswordApi";

export class PasswordApiImpl extends PasswordApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public changePassword(userId: string, currentPassword: string, newPassword: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginFreeApi(PasswordControllerApi).updatePassword({
        userId,
        oldPassword: currentPassword,
        newPassword,
      }),
    );
  }

  public requestReset(userId: string, mailAddress: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginFreeApi(PasswordControllerApi).requestReset({
        userId,
        mailAddress,
      }),
    );
  }

  public resetPasswordByToken(token: string, newPassword: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginFreeApi(PasswordControllerApi).resetPassword({
        token,
        newPassword,
      }),
    );
  }
}
