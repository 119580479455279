import { KintaiBunrui, 時単休 } from "@webkintai/bunrui";

export interface AppTemplate {
  title: string;
  content: string;
}

/**
 * 日々の申請についてテンプレートや必須を計算する
 */
export class DailyApplicationTemplates {
  constructor(private readonly types: KintaiBunrui[]) {}

  private has(burui: KintaiBunrui) {
    return !!this.types.find(it => it === burui);
  }

  get templates(): AppTemplate[] {
    const result = [];

    if (this.has(時単休)) {
      result.push({
        title: `${時単休.abbrName}予定`,
        content: `[${時単休.abbrName}予定]\nXX:XX〜YY:YY の間で時間有休を取得予定。`,
      });
    }

    result.push(
      ...[
        { title: "私用のため", content: "私用のため" },
        { title: "休暇奨励日のため", content: "休暇奨励日のため" },
        { title: "通院のため", content: "通院のため" },
        { title: "業務都合のため", content: "業務都合のため" },
      ],
    );

    return result;
  }

  get applicantCommentIsRequired(): boolean {
    return this.has(時単休);
  }
}
