import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { SaburokuPageModel, SaburokuPageModelSymbol } from "../../../models/saburoku/SaburokuPageModel";
import { SaburokuPageRibbonVM } from "./ribbon/SaburokuPageRibbon";
import { SaburokuPageVM } from "./SaburokuPage";
import { SaburokuPageTableVM } from "./table/SaburokuPageTable";
import { SaburokuPageTableRowVMImpl } from "./table/SaburokuPageTableRowVMImpl";

export class SaburokuPageVMImpl implements SaburokuPageVM, SaburokuPageRibbonVM, SaburokuPageTableVM {
  @lazyInject(SaburokuPageModelSymbol)
  private model: typeof SaburokuPageModel.Type;

  public get ribbonModel() {
    return this;
  }
  public get tableModel() {
    return this;
  }

  @computed
  get rows() {
    return this.model.filteredRows.map(it => new SaburokuPageTableRowVMImpl(it));
  }

  @computed
  get loadingStatus() {
    return this.model.searchResultLoadingState;
  }

  @computed
  get targetMonth() {
    return this.model.targetMonth;
  }

  @computed
  get nendoList() {
    return this.model.nendoList;
  }

  @action.bound
  public onChangeTargetMonth(value: Date) {
    this.model.navigateToMonth(value);
  }

  @computed
  get filterText() {
    return this.model.filterText;
  }
  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  get includeManager() {
    return this.model.includeManager;
  }
  @action.bound
  public onChangeIncludeManager(value: boolean) {
    this.model.setIncludeManager(value);
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }
}
