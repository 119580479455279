import { observer } from "mobx-react";
import React from "react";

import { KintaiApplicationStage } from "../../../../../models/kintai/apps/KintaiApplicationStage";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { LabelField } from "../../../form/LabelField";

export type AppStageKFProps = {
  model: AppStageKFVM;
} & CellFormPrintAdaptable;

export const getAppStageMessage = (appStage: KintaiApplicationStage) => {
  switch (appStage) {
    case "app_is_required_before_saving":
      return "申請予定";
    case "no_need_to_app":
      return "申請不要";
    case "app_is_required":
      return "要申請";
    case "privileged_approve_is_required":
      return "要承(管)";
    case "approve_is_required":
      return "要承認";
    case "rejected":
      return "却下";
    case "approved":
      return "承認済み";
    default:
      return "";
  }
};

export const getAppStageMessageColour = (appStage: KintaiApplicationStage) => {
  switch (appStage) {
    case "no_need_to_app":
      return "#ccc";
    case "app_is_required_before_saving":
      return "#69f";
    case "app_is_required":
      return "#00f";
    case "privileged_approve_is_required":
      return "#c0c";
    case "approve_is_required":
      return "#f93";
    case "rejected":
      return "#f00";
    case "approved":
      return "#090";
    default:
      return "#000";
  }
};

export interface AppStageKFVM {
  appStage: KintaiApplicationStage;
  onNavigateToAppDailyForm: () => void;
}

export const AppStageKF = observer((props: AppStageKFProps) => {
  const { model } = props;

  return (
    <LabelField {...props} onClick={props.mode === "cell" ? model.onNavigateToAppDailyForm : undefined}>
      <span style={{ color: getAppStageMessageColour(model.appStage) }}>{getAppStageMessage(model.appStage)}</span>
    </LabelField>
  );
});
