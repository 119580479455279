import { injectable } from "inversify";

@injectable()
export abstract class AppConfirm {
  public abstract confirm(params: {
    title: string;
    message: string;
    yesButtonLabel?: string;
    noButtonLabel?: string;
  }): Promise<boolean>;
}
