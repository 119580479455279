import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalNonAttendanceDaysKFProps = {
  model: MTotalNonAttendanceDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalNonAttendanceDaysKFVM {
  mTotalNonAttendanceDays: KintaiInputItemState<Days>;
}

export const MTotalNonAttendanceDaysKF = observer((props: MTotalNonAttendanceDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalNonAttendanceDays;

  return <DayCountField {...props} label="" {...itemState} value={itemState.value.days} onChange={undefined} />;
});
