import { 時単休 } from "@webkintai/bunrui";
import { range } from "lodash-es";
import { observer } from "mobx-react";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import { ActualKF } from "../../daily/fields/ActualKF";
import { ArrivalKF } from "../../daily/fields/ArrivalKF";
import { DateKF } from "../../daily/fields/DateKF";
import { DiffKF } from "../../daily/fields/DiffKF";
import { GrowingOverKF } from "../../daily/fields/GrowingOverKF";
import { HourlyFamilycareKF } from "../../daily/fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../../daily/fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../../daily/fields/hourlyleave/HourlyNursingcare";
import { ImmunityKF } from "../../daily/fields/ImmunityKF";
import { LeaveKF } from "../../daily/fields/LeaveKF";
import { MidnightKF } from "../../daily/fields/MidnightKF";
import { MissedKF } from "../../daily/fields/MissedKF";
import { OverDiffKF } from "../../daily/fields/OverDiffKF";
import { OverHolidayKF } from "../../daily/fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../../daily/fields/OverHolidayMidnightKF";
import { OverRestMidnightKF } from "../../daily/fields/OverRestMidnightKF";
import { OverRestNormalKF } from "../../daily/fields/OverRestNormalKF";
import { OverWeekdayKF } from "../../daily/fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../../daily/fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../../daily/fields/RemarksKF";
import { RestKF } from "../../daily/fields/RestKF";
import { TimeSumKF } from "../../daily/fields/TimeSumKF";
import { TransDayKF } from "../../daily/fields/TransDayKF";
import { BunruiIdx, TypesKF } from "../../daily/fields/TypesKF";
import { MTotalHourlyFamilycareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyFamilycareKF";
import { MTotalHourlyLeaveKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyLeaveKF";
import { MTotalHourlyNursingcareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyNursingcareKF";
import { MTotalActualKF } from "../../monthly/fields/MTotalActualKF";
import { MTotalDiffKF } from "../../monthly/fields/MTotalDiffKF";
import { MTotalImmunityKF } from "../../monthly/fields/MTotalImmunityKF";
import { MTotalMidnightKF } from "../../monthly/fields/MTotalMidnightKF";
import { MTotalMissedKF } from "../../monthly/fields/MTotalMissedKF";
import { MTotalOffCountKF } from "../../monthly/fields/MTotalOffCountKF";
import { MTotalOverDiffKF } from "../../monthly/fields/MTotalOverDiffKF";
import { MTotalOverHolidayKF } from "../../monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../monthly/fields/MTotalOverKF";
import { MTotalOverRestDayKF } from "../../monthly/fields/MTotalOverRestDayKF";
import { MTotalOverRestMidnightKF } from "../../monthly/fields/MTotalOverRestMidnightKF";
import { MTotalOverWeekdayKF } from "../../monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalRestKF } from "../../monthly/fields/MTotalRestKF";
import { MTotalTimeSumKF } from "../../monthly/fields/MTotalTimeSumKF";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";
import { ArrivalLeaveTilda } from "../common/ArrivalLeaveTilda";
import { RemakrsWrapperTd } from "../common/RemarksWrapper";
import { SPortPrintedKintaiVM } from "./SPortPrintedKintai";

@observer
export class SPortPrintedMainTable extends React.Component<{ model: SPortPrintedKintaiVM }> {
  public render() {
    const { model } = this.props;
    const { sPortRows, sPortMonthlyTotal: total, printDetailMode } = model;

    const date = sPortRows[0].date;
    const vacantVisibility = new VacantDayItemsVisibility(date);

    return (
      <div>
        <table className="FullWidth">
          <thead>
            {/* ヘッダ: 1行目 */}
            <tr>
              <th>{date.getFullYear()}年</th>

              <th colSpan={2}>指示時間</th>
              <th>
                <HHMMField mode="print" value={model.designatedMinutes} />
              </th>

              <th colSpan={2}>所属</th>
              <th colSpan={6 + (vacantVisibility.newDisplaySinceHourlyNF ? 2 : 0)}>{model.depName}</th>

              <th colSpan={2}>氏名</th>
              <th colSpan={4}>{model.name}</th>

              <th colSpan={2}>ランク</th>
              <th colSpan={3}>{model.rankName}</th>

              <th>社員番号</th>
              <th colSpan={2}>{model.userId}</th>

              <th rowSpan={3}>備考</th>
            </tr>

            {/* ヘッダ: 2行目 */}
            <tr>
              <th rowSpan={2}>
                {date.getMonth() + 1}
                <br />
                月分
              </th>

              <th rowSpan={1} colSpan={vacantVisibility.newDisplaySinceHourlyNF ? 4 : 6}>
                分類
              </th>

              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th colSpan={3}>時間休(H)</th>
                  <th rowSpan={2}>
                    振替等
                    <br />
                    日付
                  </th>
                </>
              )}

              <th rowSpan={2} colSpan={3}>
                勤務時間
              </th>
              <th rowSpan={2}>差引</th>
              <th rowSpan={2}>休憩</th>
              <th rowSpan={2}>
                普通
                <br />
                実働
              </th>
              <th rowSpan={2}>
                深夜
                <br />
                実働
              </th>

              <th rowSpan={2}>
                勤務
                <br />
                免除
              </th>
              <th rowSpan={2}>
                勤務
                <br />
                累計
              </th>
              <th rowSpan={2}>
                欠勤
                <br />
                時間
              </th>
              <th rowSpan={2}>
                残業
                <br />
                差引
              </th>
              <th colSpan={2}>残業休憩</th>
              <th colSpan={4}>残業時間</th>
              <th rowSpan={2}>
                残業
                <br />
                累計
              </th>
              <th rowSpan={2}>
                遅早外
                <br />
                回数
              </th>
            </tr>

            {/* ヘッダ: 3行目 */}
            <tr>
              {/* 分類 */}
              <th>
                承認
                <br />
                状態
              </th>
              <th>勤務</th>
              <th>休暇</th>
              <th>他</th>

              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th>
                    有<br />休
                  </th>
                  <th>
                    看<br />護
                  </th>
                  <th>
                    介<br />護
                  </th>
                </>
              )}
              {!vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th>
                    {時単休.abbrName}
                    <br />
                    (H)
                  </th>
                  <th>
                    振替等
                    <br />
                    日付
                  </th>
                </>
              )}

              <th>普通</th>
              <th>深夜</th>

              <th>普通</th>
              <th>
                平日
                <br />
                深夜
              </th>
              <th>休日</th>
              <th>
                休日
                <br />
                深夜
              </th>
            </tr>
          </thead>
          {printDetailMode !== "brief" && (
            <tbody>
              {sPortRows.map((row, rowNumber) => (
                <tr key={row.date.getTime()} className={rowNumber % 2 === 0 ? "even" : "odd"}>
                  <td className="center">
                    <DateKF mode="print" model={row} />
                  </td>

                  <td className="center col_group_start">
                    <AppStageKF mode="print" model={row.dailyApp} />
                  </td>

                  {/* 分類 */}
                  {range(1, 4).map(i => (
                    <td key={i} className="left">
                      <TypesKF mode="print" model={row} bunruiIdx={i as BunruiIdx} />
                    </td>
                  ))}
                  <td>
                    <HourlyLeaveKF mode="print" model={row} />
                  </td>

                  {vacantVisibility.newDisplaySinceHourlyNF && (
                    <>
                      <td>
                        <HourlyNursingcareKF mode="print" model={row} />
                      </td>
                      <td>
                        <HourlyFamilycareKF mode="print" model={row} />
                      </td>
                    </>
                  )}

                  <td className="col_group_end">
                    <TransDayKF mode="print" model={row} />
                  </td>

                  {/* 勤務時間 */}
                  <td className="col_group_start">
                    <ArrivalKF mode="print" model={row} />
                  </td>
                  <td className="center">
                    <ArrivalLeaveTilda mode="print" model={row} />
                  </td>
                  <td className="col_group_end">
                    <LeaveKF mode="print" model={row} />
                  </td>

                  <td>
                    <DiffKF mode="print" model={row} />
                  </td>
                  <td>
                    <RestKF mode="print" model={row} />
                  </td>
                  <td>
                    <ActualKF mode="print" model={row} />
                  </td>
                  <td>
                    <MidnightKF mode="print" model={row} />
                  </td>

                  {/* 勤務免除 */}
                  <td>
                    <ImmunityKF mode="print" model={row} />
                  </td>
                  <td>
                    <TimeSumKF mode="print" model={row} />
                  </td>
                  <td>
                    <MissedKF mode="print" model={row} />
                  </td>
                  <td>
                    <OverDiffKF mode="print" model={row} />
                  </td>

                  {/* 残業休憩 */}
                  <td className="col_group_start">
                    <OverRestNormalKF mode="print" model={row} />
                  </td>
                  <td className="col_group_end">
                    <OverRestMidnightKF mode="print" model={row} />
                  </td>

                  {/* 残業時間 */}
                  <td className="col_group_start">
                    <OverWeekdayKF mode="print" model={row} />
                  </td>
                  <td>
                    <OverWeekdayMidnightKF mode="print" model={row} />
                  </td>
                  <td>
                    <OverHolidayKF mode="print" model={row} />
                  </td>
                  <td className="col_group_end">
                    <OverHolidayMidnightKF mode="print" model={row} />
                  </td>

                  <td>
                    <GrowingOverKF mode="print" model={row} />
                  </td>
                  <td>{/* 遅遅外回数 */}</td>
                  <RemakrsWrapperTd>
                    <RemarksKF mode="print" model={row} />
                  </RemakrsWrapperTd>
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <td colSpan={5} />
                  <td>
                    <MTotalHourlyLeaveKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalHourlyNursingcareKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalHourlyFamilycareKF mode="print" model={total} />
                  </td>
                  <td colSpan={4} />
                </>
              )}
              {!vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <td colSpan={10} />
                </>
              )}
              <td>
                <MTotalDiffKF mode="print" model={total} />
              </td>
              <td>
                <MTotalRestKF mode="print" model={total} />
              </td>
              <td>
                <MTotalActualKF mode="print" model={total} />
              </td>
              <td>
                <MTotalMidnightKF mode="print" model={total} />
              </td>

              {/* 勤務免除 */}
              <td>
                <MTotalImmunityKF mode="print" model={total} />
              </td>
              <td>
                <MTotalTimeSumKF mode="print" model={total} />
              </td>
              <td>
                <MTotalMissedKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOverDiffKF mode="print" model={total} />
              </td>

              {/* 残業休憩 */}
              <td>
                <MTotalOverRestDayKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOverRestMidnightKF mode="print" model={total} />
              </td>

              {/* 残業時間 */}
              <td>
                <MTotalOverWeekdayKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOverWeekdayMidnightKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOverHolidayKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOverHolidayMidnightKF mode="print" model={total} />
              </td>

              <td>
                <MTotalOverKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOffCountKF mode="print" model={total} />
              </td>
              <td>{/* 備考 */}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
