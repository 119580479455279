import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalOtherDaysExcludingNuFaKFProps = {
  model: MTotalOtherDaysExcludingNuFaKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOtherDaysExcludingNuFaKFVM {
  mTotalOtherDaysExcludingNuFa: KintaiInputItemState<HourBasedDays>;
}

/** 介護・看護除くその他休暇日数 */
export const MTotalOtherDaysExcludingNuFaKF = observer((props: MTotalOtherDaysExcludingNuFaKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOtherDaysExcludingNuFa;

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label=""
      {...itemState}
      value={itemState.value}
      onChange={undefined}
    />
  );
});
