import { CompanyNendoDashboardResult } from "@webkintai/api";
import { CompanyNendoDashboardData } from "@webkintai/schema";
import { reverse } from "lodash-es";
import { flow, Instance, toGenerator, types } from "mobx-state-tree";

import { DashboardApi } from "../../../services/api/DashboardApi";
import { fromApiDate } from "../../../utils/api";
import { getNendo } from "../../../utils/calendar";
import { getDI } from "../../common/getDI";
import { LoadingStatus } from "../../common/LoadingStatus";
import { WithLoading } from "../WithLoading";
import { calcAvgLawoverAscRows } from "./calcAvgLawoverAscRows";
import { calcAvgPaidLeavesRatioAscRows } from "./calcAvgPaidLeavesRatioAscRows";
import { calcAvgPaidLeavesTakenAscRows } from "./calcAvgPaidLeavesTakenAscRows";
import { DashboardSimpleChartData } from "./DashboardSimpleChartData";
import { sortAndRoundDashboardSimpleChartRows } from "./sortAscDashboardSimpleChartRows";

export const model = types.optional(
  types
    .model("PublicDDModel", {
      loadingStatus: types.optional(LoadingStatus, "loading"),

      date: types.optional(types.Date, new Date()),

      content: types.maybe(types.frozen<CompanyNendoDashboardData>()),
      contentLastUpdate: types.maybe(types.Date),
    })
    .views(self => {
      const withContent = <T>(calc: (data: CompanyNendoDashboardData) => T): WithLoading<T> => {
        if (self.loadingStatus !== "loaded") {
          return {
            state: "loading",
          };
        }
        if (self.content === undefined) {
          return {
            state: "no_data",
          };
        }

        return calc(self.content);
      };

      return {
        get avg_paidLeavesRatio_desc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "有休取得率 速報値 (上位)",
            unit: "%",
            chartRows: sortAndRoundDashboardSimpleChartRows(calcAvgPaidLeavesRatioAscRows(content, self.date), "desc"),
          }));
        },
        get avg_paidLeavesRatio_asc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "有休取得率 速報値 (下位)",
            unit: "%",
            chartRows: sortAndRoundDashboardSimpleChartRows(calcAvgPaidLeavesRatioAscRows(content, self.date), "asc"),
          }));
        },
        get avg_paidLeavesTaken_desc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "有休取得日数 (上位)",
            unit: "日",
            chartRows: sortAndRoundDashboardSimpleChartRows(
              reverse(calcAvgPaidLeavesTakenAscRows(content, self.date)),
              "desc",
            ),
          }));
        },
        get avg_paidLeavesTaken_asc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "有休取得日数 (下位)",
            unit: "日",
            chartRows: sortAndRoundDashboardSimpleChartRows(calcAvgPaidLeavesTakenAscRows(content, self.date), "asc"),
          }));
        },
        get avg_lawover_desc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "健康管理時間平均 (上位)",
            unit: "時間",
            chartRows: sortAndRoundDashboardSimpleChartRows(reverse(calcAvgLawoverAscRows(content, self.date)), "desc"),
          }));
        },
        get avg_lawover_asc(): WithLoading<DashboardSimpleChartData> {
          return withContent(content => ({
            title: "健康管理時間平均 (下位)",
            unit: "時間",
            chartRows: sortAndRoundDashboardSimpleChartRows(calcAvgLawoverAscRows(content, self.date), "asc"),
          }));
        },
      };
    })
    .actions(self => {
      const dashboardApi = () => getDI(self, DashboardApi);

      const route = (value?: Date) => {
        if (value === undefined) {
          loadIfNeeded();
          return;
        }

        self.date = value;
        load();
      };

      const loadIfNeeded = flow(function*() {
        if (self.loadingStatus === "loaded") {
          return;
        }

        yield load();
      });

      const load = flow(function*() {
        self.loadingStatus = "loading";

        try {
          const result: CompanyNendoDashboardResult = yield* toGenerator(
            dashboardApi().getCompanyDashboardByNendo(getNendo(self.date)),
          );
          self.content = JSON.parse(result.contentJson);
          self.contentLastUpdate = fromApiDate(result.lastUpdate);
        } catch (e) {
          console.error(`Error (or not found) while loading public dashboard`, e);
          self.content = undefined;
        }

        self.loadingStatus = "loaded";
      });

      return {
        loadIfNeeded,
        load,
        route,
        setDate(value: Date) {
          self.date = value;
        },
      };
    }),
  {},
);

export const PublicDDModel: PublicDDModelModelType = model;
type PublicDDModel_InferredType = typeof model;
export interface PublicDDModelModelType extends PublicDDModel_InferredType {}
type PublicDDModelIIf = Instance<typeof PublicDDModel>;
export interface PublicDDModelInstance extends PublicDDModelIIf {}

export type PublicDDModelId = {};
