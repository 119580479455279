import { observer } from "mobx-react";
import React from "react";

import { DateKF } from "../../daily/fields/DateKF";
import { PJRBodyActualTimeKF } from "../../pjreport/fields/PJRBodyActualTimeKF";
import { PJRBodyCellTimeKF } from "../../pjreport/fields/PJRBodyCellTimeKF";
import { PJRBodyNotPopulatedTimeKF } from "../../pjreport/fields/PJRBodyNotPopulatedTimeKF";
import { PJRFooterActualTimeKF } from "../../pjreport/fields/PJRFooterActualTimeKF";
import { PJRFooterCellTimeKF } from "../../pjreport/fields/PJRFooterCellTimeKF";
import { PJRFooterCellTimeTotalKF } from "../../pjreport/fields/PJRFooterCellTimeTotalKF";
import { PJRFooterNotPopulatedTimeKF } from "../../pjreport/fields/PJRFooterNotPopulatedTimeKF";
import { PJRHeaderJobNoKF } from "../../pjreport/fields/PJRHeaderJobNoKF";
import { PJRHeaderNumberKF } from "../../pjreport/fields/PJRHeaderNumberKF";
import { PJRHeaderOdCodeKF } from "../../pjreport/fields/PJRHeaderOdCodeKF";
import { PJRHeaderPjCodeKF } from "../../pjreport/fields/PJRHeaderPjCodeKF";
import { PJRHeaderPjNameKF } from "../../pjreport/fields/PJRHeaderPjNameKF";
import { RegularPrintedKintaiVM } from "./RegularPrintedKintai";

export interface RegularPrintedPJBodyProps {
  model: RegularPrintedKintaiVM;
  targetPillarIds: string[];
}

@observer
export class RegularPrintedPJBody extends React.Component<RegularPrintedPJBodyProps> {
  public render() {
    const { targetPillarIds, model } = this.props;
    const { regularRows, regularMonthlyTotal: total, printDetailMode } = model;
    const isTargetPillar = (id: string) => !!targetPillarIds.find(it => it === id);
    const firstRow = regularRows[0];
    const headers = firstRow.dailyPillars.filter(it => isTargetPillar(it.id));

    return (
      <div>
        <table className="PJReport">
          <thead>
            <tr>
              <th colSpan={3} rowSpan={2}>
                月報
              </th>

              {headers.map(p => (
                <th key={p.id} colSpan={3}>
                  <PJRHeaderNumberKF model={p} />
                </th>
              ))}
            </tr>
            <tr>
              {headers.map(p => (
                <th key={p.id} colSpan={3}>
                  <PJRHeaderPjNameKF mode="print" model={p} />
                </th>
              ))}
            </tr>
            <tr>
              <th rowSpan={3}>日</th>
              <th rowSpan={3}>
                就業
                <br />
                時間
              </th>
              <th rowSpan={3}>
                その他
                <br />
                間接
              </th>

              {headers.map(p => (
                <React.Fragment key={p.id}>
                  {model.projectInputStyle === "pjshushi" && (
                    <>
                      <th>PJ</th>
                      <th colSpan={2}>
                        <PJRHeaderPjCodeKF mode="print" model={p} />
                      </th>
                    </>
                  )}
                  {model.projectInputStyle === "zac" && (
                    <>
                      <th rowSpan={2}>ジョブNo.</th>
                      <th colSpan={2} rowSpan={2}>
                        <PJRHeaderJobNoKF mode="print" model={p} />
                      </th>
                    </>
                  )}
                </React.Fragment>
              ))}
            </tr>
            <tr>
              {headers.map(p => (
                <React.Fragment key={p.id}>
                  {model.projectInputStyle === "pjshushi" && (
                    <>
                      <th>OD</th>
                      <th colSpan={2}>
                        <PJRHeaderOdCodeKF mode="print" model={p} />
                      </th>
                    </>
                  )}
                  {model.projectInputStyle === "zac" && <></>}
                </React.Fragment>
              ))}
            </tr>

            <tr>
              {headers.map((p, idx) => {
                const labelOf = (cellIdx: number) => p.cells[cellIdx].PJRBodyCellLabel.value;

                return (
                  <React.Fragment key={p.id}>
                    <th>{labelOf(0)}</th>
                    <th>{labelOf(1)}</th>
                    <th>{labelOf(2)}</th>
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          {printDetailMode !== "brief" && (
            <tbody>
              {regularRows.map((row, rowNumber) => (
                <tr key={row.date.getTime()} className={rowNumber % 2 === 0 ? "even" : "odd"}>
                  <td className="center">
                    <DateKF mode="print" model={row} />
                  </td>
                  <td>
                    <PJRBodyActualTimeKF mode="print" model={row} />
                  </td>
                  <td>
                    <PJRBodyNotPopulatedTimeKF mode="print" model={row} />
                  </td>

                  {row.dailyPillars
                    .filter(dPillar => isTargetPillar(dPillar.id))
                    .map(dPillar => (
                      <React.Fragment key={dPillar.id}>
                        {dPillar.cells.map((cell, idx) => (
                          <td
                            key={idx}
                            className={
                              idx === 0
                                ? "col_group_start" //
                                : idx === dPillar.cells.length - 1
                                ? "col_group_end" //
                                : ""
                            }>
                            <PJRBodyCellTimeKF mode="print" model={cell} />
                          </td>
                        ))}
                      </React.Fragment>
                    ))}
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              <td />
              <td>
                <PJRFooterActualTimeKF model={total} />
              </td>
              <td>
                <PJRFooterNotPopulatedTimeKF model={total} />
              </td>
              {headers.map(h =>
                h.totalCells.map((p, idx) => (
                  <td key={h.id + "_" + idx}>
                    <PJRFooterCellTimeKF model={p} />
                  </td>
                )),
              )}
            </tr>
            <tr>
              <td colSpan={3} />
              {headers.map(h => (
                <td key={h.id} colSpan={3}>
                  <PJRFooterCellTimeTotalKF model={h} />
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
