import { computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { AppState, AppStateSymbol } from "../../../models/AppState";
import { Notifications, NotificationsSymbol } from "../../../models/notify/Notifications";
import { Profile, ProfileSymbol } from "../../../models/profile/Profile";
import { paths } from "../../../routing/paths";
import { AppRouter } from "../../../services/AppRouter";
import { MyPrivileges } from "../../../services/prv/MyPrivileges";
import { GlobalNavbbarVM } from "./GlobalNavbar";

export class GlobalNavbbarVMImpl implements GlobalNavbbarVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  @lazyInject(AppStateSymbol)
  private appState: typeof AppState.Type;

  @lazyInject(ProfileSymbol)
  private profile: typeof Profile.Type;

  @lazyInject(NotificationsSymbol)
  private notifications: typeof Notifications.Type;

  @lazyInject(MyPrivileges)
  private myPrivileges: MyPrivileges;

  @computed
  get unreadNotificationCount() {
    return this.notifications.unreadCount;
  }

  @computed
  public get showKintai() {
    return true;
  }
  public navigateToKintai = () => {
    this.appRouter.navigate(paths.kintai.index());
  };

  @computed
  public get showDashboard() {
    return this.myPrivileges.has("DISP_ROOTMENU_LIST");
  }
  public navigateToDashboard = () => {
    this.appRouter.navigate(paths.dashboard.index());
  };

  @computed
  public get showAdmin() {
    return this.myPrivileges.has("DISP_ROOTMENU_ADMIN");
  }
  public navigateToAdmin = () => {
    this.appRouter.navigate(paths.admin.index());
  };

  @computed
  public get showHelp() {
    return true;
  }
  public navigateToHelp = () => {
    this.appRouter.navigate(paths.help.index());
  };

  @computed
  public get showProfile() {
    return true;
  }
  public navigateToProfile = () => {
    this.appRouter.navigate(paths.profile.index());
  };

  @computed
  public get activeScreenMode() {
    return this.appState.screenMode;
  }

  @computed
  public get loginName() {
    return this.profile ? this.profile.userName : "未ログイン";
  }
}
