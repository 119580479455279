import { WkAbilities } from "@webkintai/privileges";
import { inject } from "inversify";

import { Profile, ProfileSymbol } from "../../models/profile/Profile";
import { MyPrivileges } from "./MyPrivileges";

export class MyPrivilegesImpl extends MyPrivileges {
  constructor(@inject(ProfileSymbol) private readonly profile: typeof Profile.Type) {
    super();
  }

  public has(ability: WkAbilities) {
    if (!this.profile.me) {
      return false;
    }
    return this.profile.hasAbility(ability);
  }
}
