import { getEnv, IModelType, types } from "mobx-state-tree";

import { KintaiUsersSymbol, KintaiUsersType } from "../kintai/KintaiUsers";
import { MonthlyKintai } from "../kintai/MonthlyKintai";
import { ProfileInterviewPageModelSymbol, ProfileInterviewPageModelType } from "../profile/ProfileInterviewPageModel";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("GlobalWarnings", {
      dismissed: types.optional(types.boolean, false),
      thisMonthKintaiOfMine: types.maybe(types.reference(MonthlyKintai)),
    })
    .views(self => {
      const profileInterviewPageModel = () =>
        getEnv(self).get(ProfileInterviewPageModelSymbol) as ProfileInterviewPageModelType;

      return {
        get pastLatestReservedDate() {
          return profileInterviewPageModel().pastLatestReservedDate;
        },
        get nearestFutureReservedDate() {
          return profileInterviewPageModel().nearestFutureReservedDate;
        },
        get showsHealthCareOver45() {
          return !!(
            self.thisMonthKintaiOfMine &&
            self.thisMonthKintaiOfMine.globalWarnings.indexOf("lawOverCalcWillExceed45") >= 0
          );
        },
        get showsHealthCareOver75() {
          return !!(
            self.thisMonthKintaiOfMine &&
            self.thisMonthKintaiOfMine.globalWarnings.indexOf("lawOverCalcWillExceed75") >= 0
          );
        },
        get showsFatigueCheckWarning() {
          return profileInterviewPageModel().needsFatigueCheckAnswer;
        },
        get showsInterviewWarning() {
          return profileInterviewPageModel().needsInterviewAnswer;
        },
      };
    })
    .actions(self => {
      const kintaiUsers = () => getEnv(self).get(KintaiUsersSymbol) as KintaiUsersType;
      const profileInterviewPageModel = () =>
        getEnv(self).get(ProfileInterviewPageModelSymbol) as ProfileInterviewPageModelType;

      return {
        setDismissed(value: boolean) {
          self.dismissed = value;
        },
        init() {
          self.thisMonthKintaiOfMine = kintaiUsers().loadThisMonthKintaiOfMine();
          profileInterviewPageModel().loadIfNeeded();
        },
      };
    }),
  {},
);

export type GlobalWarningsType = typeof GlobalWarnings.Type;

export const GlobalWarningsSymbol = "GlobalWarnings_Symbol";
export const GlobalWarnings: GlobalWarningsModelType = model;
type GlobalWarningsInferredType = typeof model;
export interface GlobalWarningsModelType extends GlobalWarningsInferredType {}
