import { getEnv, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { getDI } from "../common/getDI";
import { ExportPageModelSymbol, ExportPageModelType } from "../export/ExportPageModel";
import { InterviewListPageModelSymbol, InterviewListPageModelType } from "../interview_list/InterviewListPageModel";
import { KintaiListPageModelSymbol, KintaiListPageModelType } from "../kintai_list/KintaiListPageModel";
import { KintaiSumPageModelSymbol, KintaiSumPageModelType } from "../kintai_sum/KintaiSumPageModel";
import { SaburokuPageModelSymbol, SaburokuPageModelType } from "../saburoku/SaburokuPageModel";
import { getStasticsPageModel } from "../statistics/getStasticsPageModel";
import { TransDayPageModelSymbol, TransDayPageModelType } from "../trans_day/TransDayPageModel";
import { DashboardPageScreenModes } from "./DashboardPageScreenModes";

const model = types.optional(
  types
    .model("DashboardPageModel", {
      pageMode: types.optional(DashboardPageScreenModes, "statistics"),
    })
    .views(self => ({
      get url(): string {
        return paths.dashboard.index() + "/" + self.pageMode;
      },
    }))
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const kintaiListPageModel = (): KintaiListPageModelType => getEnv(self).get(KintaiListPageModelSymbol);
      const kintaiSumPageModel = (): KintaiSumPageModelType => getEnv(self).get(KintaiSumPageModelSymbol);
      const transDayPageModel = (): TransDayPageModelType => getEnv(self).get(TransDayPageModelSymbol);
      const interviewListPageModel = (): InterviewListPageModelType => getEnv(self).get(InterviewListPageModelSymbol);
      const saburokuPageModel = (): SaburokuPageModelType => getEnv(self).get(SaburokuPageModelSymbol);
      const exportPageModel = (): ExportPageModelType => getEnv(self).get(ExportPageModelSymbol);

      return {
        route(pathFragment: string) {
          const { menuItem, others } = fromPaths.dashboard.index(pathFragment);
          const normalizedMenuItem = menuItem || self.pageMode;
          if (DashboardPageScreenModes.is(normalizedMenuItem)) {
            self.pageMode = normalizedMenuItem;

            switch (normalizedMenuItem) {
              case "statistics":
                getStasticsPageModel().route(others);
                break;
              case "kintai_list":
                kintaiListPageModel().route(others);
                break;
              case "kintai_sum":
                kintaiSumPageModel().route(others);
                break;
              case "trans_day":
                transDayPageModel().route(others);
                break;
              case "interview_list":
                interviewListPageModel().route(others);
                break;
              case "saburoku":
                saburokuPageModel().route(others);
                break;
              case "export":
                exportPageModel().route(others);
                break;
            }
          } else {
            appRouter().replaceWithoutEffects(self.url);
          }
        },
      };
    }),
  {},
);
export const DashboardPageModelSymbol = "Model_DashboardPage";
export const DashboardPageModel: DashboardPageModelModelType = model;
type DashboardPageModelInferredType = typeof model;
export interface DashboardPageModelModelType extends DashboardPageModelInferredType {}
