import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type PJRHeaderOdCodeKFProps = {
  model: PJRHeaderOdCodeKFVM;
} & CellFormPrintAdaptable;

export interface PJRHeaderOdCodeKFVM {
  odCode: KintaiInputItemState<string>;
}

export const PJRHeaderOdCodeKF = observer((props: PJRHeaderOdCodeKFProps) => {
  const { model } = props;
  const itemState = model.odCode;

  return <TextField {...props} label="ODコード" {...itemState} />;
});
