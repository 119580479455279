import styled from "@emotion/styled";
import React from "react";

import { mediaQueryLarge } from "../../../common/responsive/Responsive";

export const DashboardWidget: React.FC<{
  mood?: "good" | "bad";
  title: React.ReactNode;
  lastUpdate?: React.ReactNode;
}> = ({ mood, title, children, lastUpdate }) => {
  return (
    <Container className={mood}>
      <Title>{title}</Title>
      <Body>{children}</Body>
      {lastUpdate && <LastUpdate>{lastUpdate}</LastUpdate>}
    </Container>
  );
};

const Container = styled.section`
  flex: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;

  &.good h1 {
    background-color: #36913b;
    color: #fff;
  }
  &.bad h1 {
    background-color: #cc3f3f;
    color: #fff;
  }
`;

const Title = styled.h1`
  flex: none;

  transition: background-color 0.2s;
  text-align: center;
  font-size: 12pt;
  margin: 0;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
`;

const Body = styled.div`
  flex: auto;

  margin-top: 0.5rem;
  padding: 0.25rem;
  padding-bottom: 1rem;

  overflow: auto;

  ${mediaQueryLarge} {
    display: flex;
    place-items: center;
    justify-content: center;
  }
`;

const LastUpdate = styled.div`
  flex: none;
  padding: 0.25rem;
`;
