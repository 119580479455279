import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormAdaptable } from "./CellFormAdaptable";
import { cellStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export const ButtonField: React.FC<ButtonFieldProps> = ({ className, mode, onClick, disabled, label, children }) => {
  if (mode === "cell") {
    return (
      <Cell className={classNames(FocusMarkerClassName, className)} disabled={disabled} onClick={onClick}>
        {children}
      </Cell>
    );
  }

  return (
    <FormLabel label={label}>
      <Form className={className} disabled={disabled} small={true} onClick={onClick}>
        {children}
      </Form>
    </FormLabel>
  );
};

export type ButtonFieldProps = {
  className?: string;
  label?: string;
  disabled?: boolean;
  onClick: () => void;
} & CellFormAdaptable;

const Cell = styled.button`
  ${cellStyle}
  text-align: center;
  line-height: 100%;
`;

const Form = styled(Button)``;
