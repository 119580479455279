import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../form/CellFormAdaptable";

export type AppRejectButtonKFProps = {
  model: AppRejectButtonKFVM;
} & CellFormAdaptable;

export interface AppRejectButtonKFVM {
  onAppReject: () => void;
  rejectDisabled: boolean;
}

export const AppRejectButtonKF = observer((props: AppRejectButtonKFProps) => {
  const { model } = props;

  return <Button disabled={model.rejectDisabled} intent={Intent.DANGER} onClick={model.onAppReject} text="却下" />;
});
