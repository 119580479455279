import { CalendarControllerApi, CalendarEntity, CalendarResult } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { CalendarApi } from "./CalendarApi";

export class CalendarApiImpl extends CalendarApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }
  public getNendoCalendar(nendo: number): Promise<CalendarResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(CalendarControllerApi).getCalendar(nendo),
    );
  }
  public saveNendoCalendar(nendo: number, calendar: CalendarEntity): Promise<Response> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(CalendarControllerApi).updateCalendar(nendo, calendar),
    );
  }
}
