import { DoorHistoriesControllerApi } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { DoorHistoriesApi } from "./DoorHistoriesApi";

export class DoorHistoriesApiImpl extends DoorHistoriesApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getDoorHistories(userId: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(DoorHistoriesControllerApi).getUserDoorHistories(userId, year, month),
    );
  }
}
