import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

export interface WarningBannerVM {
  dismissed: boolean;
  onDismiss: () => void;
  warnPhysicianInterview: boolean;
  warnFatigueCheck: boolean;
  onNavigateToPhysicianInterview: () => void;
  physicianInterviewNearestFutureReservedDate: Date | undefined;
  physicianInterviewPastLatestReservedDate: Date | undefined;
  warn45OverHealthCareTime: boolean;
  warn75OverHealthCareTime: boolean;
}

const WarningBannerContainer = styled.div`
  width: 100%;
  background-color: #333;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 24px;
  line-height: 24px;

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: dotted;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const WarningBannerItems = styled.ul`
  display: inline;
  padding: 0;
  margin: 0;
`;

const WarningBannerItem = styled.li`
  display: inline;
  list-item-style: none;
  height: 1em;
  padding: 0;
  margin: 0;
  margin-right: 1em;
`;

const DismissButton = styled.a`
  padding-right: 1em;
  padding-left: 0.25ex;
`;

@observer
export class WarningBanner extends React.Component<{ model: WarningBannerVM }> {
  public render() {
    const { model } = this.props;

    const anySpecified =
      model.warnFatigueCheck ||
      model.warnPhysicianInterview ||
      model.physicianInterviewNearestFutureReservedDate ||
      model.physicianInterviewPastLatestReservedDate ||
      model.warn45OverHealthCareTime ||
      model.warn75OverHealthCareTime;

    if (model.dismissed || !anySpecified) {
      return <></>;
    }

    const piMessage = (msg: string) => (
      <a href="javascript: undefined" onClick={model.onNavigateToPhysicianInterview}>
        {msg}
      </a>
    );

    const dateFormat = (date: Date) => moment(date).format("MM月DD日");

    return (
      <WarningBannerContainer>
        <DismissButton>
          <Icon icon="cross" onClick={model.onDismiss} />
        </DismissButton>
        <WarningBannerItems>
          {model.warnFatigueCheck && (
            <WarningBannerItem>
              {piMessage("疲労蓄積度チェックシートに回答してください。回答後、回答日を登録してください。")}
            </WarningBannerItem>
          )}
          {model.warnPhysicianInterview && (
            <WarningBannerItem>
              {piMessage("産業医面接を受診してください。面接の予約後に面接予定日を本システムにて登録してください。")}
            </WarningBannerItem>
          )}
          {model.physicianInterviewNearestFutureReservedDate && (
            <WarningBannerItem>
              {piMessage(
                `産業医面接は${dateFormat(
                  model.physicianInterviewNearestFutureReservedDate,
                )}の予定です。忘れずに受診してください。`,
              )}
            </WarningBannerItem>
          )}
          {model.physicianInterviewPastLatestReservedDate && (
            <WarningBannerItem>
              {piMessage(
                `${dateFormat(
                  model.physicianInterviewPastLatestReservedDate,
                )}の産業医面接の結果について産業医に確認中です。`,
              )}
            </WarningBannerItem>
          )}
          {model.warn45OverHealthCareTime && (
            <WarningBannerItem>今月の健康管理時間が45時間を超過する可能性があります。</WarningBannerItem>
          )}
          {model.warn75OverHealthCareTime && (
            <WarningBannerItem style={{ color: "#ff9999" }}>
              今月の健康管理時間が75時間を超過する可能性があります。
            </WarningBannerItem>
          )}
        </WarningBannerItems>
      </WarningBannerContainer>
    );
  }
}
