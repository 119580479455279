import moment from "moment";

export function toApiLocalDate(d: Date | undefined): string | undefined;
export function toApiLocalDate(d: Date): string;
export function toApiLocalDate(d: Date | undefined): string | undefined {
  return d ? moment(d).format("YYYY-MM-DD") : d;
}

export function fromApiDate(d: Date | string): Date;
export function fromApiDate(d: Date | string | undefined | null): Date | undefined;
export function fromApiDate(d: Date | string | undefined | null) {
  if (!d) {
    return undefined;
  }
  if (`${d}`.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return new Date(`${d}`.replace(/-/g, "/") + ` 00:00`);
  }
  return d ? new Date(d) : undefined;
}

export const undefinedIfNull = <T>(val: T | null) => (val === null ? undefined : val);
