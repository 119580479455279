import React from "react";
import { useState } from "react";
import { useCallback } from "react";

import { GuideButton } from "../guide/GuideButton";
import { PageRibbonSection } from "../page/PageRibbonSection";

export const GuideRibbon: React.FunctionComponent<{ markdown?: string }> = props => {
  return (
    <PageRibbonSection title="ガイド">
      <GuideButton markdown={props.markdown} children={props.children} />
    </PageRibbonSection>
  );
};
