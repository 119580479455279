import {
  DefaultResetPasswordSuggestionControllerApi,
  PaidVacationEditEntity,
  UserAbsenceEntity,
  UserEditEntity,
  UsersControllerApi,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { UsersApi } from "./UsersApi";

export class UsersApiImpl extends UsersApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getUsers() {
    return this.apiFactory.guardFailures(() => this.apiFactory.generateLoginAssumingApi(UsersControllerApi).getUsers());
  }

  public addUser(entry: UserEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).registerUser(entry),
    );
  }

  public updateUsers(entries: UserEditEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).updateUsers(entries),
    );
  }

  public updateUserAbsences(userId: string, absences: UserAbsenceEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).updateUserAbsences(userId, { absences }),
    );
  }

  public getPaidVacations(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).getPaidVacations(nendo),
    );
  }

  public savePaidVacations(nendo: number, paidVacations: PaidVacationEditEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).updatePaidVacations(nendo, paidVacations),
    );
  }

  public setInitPassword(userId: string, tempPassword: string | undefined) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(UsersControllerApi).resetUser({ userId, tempPassword }),
    );
  }

  public getDefaultResetPassword() {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(DefaultResetPasswordSuggestionControllerApi)
        .suggestDefaultResetPassword(),
    );
  }
}
