import { MonthlyReportTimestampsEntity, ReportApprovalTimestampEntity } from "@webkintai/api";
import { getParentOfType, IModelType, types } from "mobx-state-tree";

import { UsersType } from "../../users/Users";
import { MonthlyKintai } from "../MonthlyKintai";
import { KintaiTimestamp } from "./KintaiTimestamp";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("KintaiTimestamps", {
      self: types.maybe(KintaiTimestamp),
      clerk: types.maybe(KintaiTimestamp),
      department: types.maybe(KintaiTimestamp),
      hrm: types.maybe(KintaiTimestamp),
      review: types.maybe(KintaiTimestamp),
    })
    .views(self => {
      const monthlyKintai = () => {
        return getParentOfType(self, MonthlyKintai);
      };

      return {
        get isLocked() {
          if (self.clerk) {
            return true;
          }

          const kintai = monthlyKintai();
          if (self.department) {
            return !kintai.clerkStampIsAllowed;
          }
          if (self.review) {
            return !(kintai.clerkStampIsAllowed || kintai.approverStampIsAllowed);
          }
          if (self.self) {
            return !(kintai.clerkStampIsAllowed || kintai.approverStampIsAllowed || kintai.reviewerStampIsAllowed);
          }

          return false;
        },
      };
    }),
  {},
);

export function apiValueToTimestamps(ourUsers: UsersType, timestamps: MonthlyReportTimestampsEntity) {
  const extractTimestampEntry = (ent?: ReportApprovalTimestampEntity) => {
    if (!ent || !ent.user) {
      return undefined;
    }

    const user = ourUsers.mergeFromUserEntity(ent.user);
    return {
      user: user.userId,
      timestamp: new Date(ent.timestamp!),
    };
  };

  return {
    self: extractTimestampEntry(timestamps.self),
    clerk: extractTimestampEntry(timestamps.clerk),
    department: extractTimestampEntry(timestamps.department),
    hrm: extractTimestampEntry(timestamps.hrm),
    review: extractTimestampEntry(timestamps.review),
  };
}

export type KintaiTimestampsType = typeof KintaiTimestamps.Type;
export const KintaiTimestamps: KintaiTimestampsModelType = model;
type KintaiTimestampsInferredType = typeof model;
export interface KintaiTimestampsModelType extends KintaiTimestampsInferredType {}
