import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { RequiredApprovalErrorsKF } from "./RequiredApprovalErrorsKF";

export type MonthlyRejectedErrorsKFProps = {
  model: MonthlyRejectedErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyRejectedErrorsKFVM {
  monthlyRejectedErrors: string[];
}

export const MonthlyRejectedErrorsKF = observer((props: MonthlyRejectedErrorsKFProps) => {
  const { model, mode } = props;
  return <RequiredApprovalErrorsKF label="却下" errors={model.monthlyRejectedErrors} mode={mode} />;
});
