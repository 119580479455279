import moment from "moment";
import React from "react";
import { useCallback } from "react";

import { CheckboxField } from "../../form/CheckboxField";

export const ExcludeHasNoChangeCheckbox: React.FunctionComponent<{
  checked: boolean;
  onChange: (value: boolean) => void;
}> = props => {
  return <CheckboxField mode="form" value={props.checked} onChange={props.onChange} label="変更がある社員のみ抽出" />;
};

export const isOldEnoughToBeExcludedByRetirement = (date: Date | undefined) =>
  date !== undefined && moment(new Date()).diff(date, "days") >= 365;
