import { Button, ButtonGroup, Classes, Intent, Menu, MenuItem, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { Minutes } from "@webkintai/core";
import Help from "@webkintai/help/content/manuals/kintai/kintai_overview.md";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../../models/common/LoadingStatusType";
import { rankPositionName, RankPositionType } from "../../../../models/common/RankPosition";
import { MainKintaiTypeType } from "../../../../models/kintai/MainKintaiType";
import { PrintDetailMode } from "../../../../models/kintai/misc/printdetailmode/PrintDetailMode";
import { printDetailModeDef } from "../../../../models/kintai/misc/printdetailmode/printDetailModeDef";
import { printDetailModeDefs } from "../../../../models/kintai/misc/printdetailmode/printDetailModeDefs";
import { HHMMField } from "../../form/HHMMField";
import { KinmuTypeButton } from "../../kinmu-type/KinmuTypeButton";
import { LoadingRibbon } from "../../loading/LoadingRibbon";
import { PageRibbonLine } from "../../page/PageRibbonLine";
import { PageRibbonSection } from "../../page/PageRibbonSection";
import { GuideRibbon } from "../../ribbon/GuideRibbon";
import { ReloadRibbon } from "../../ribbon/ReloadRibbon";
import { SaveRibbon } from "../../ribbon/SaveRibbon";
import { ScreenModeRibbon } from "../../ribbon/ScreenModeRibbon";
import { ApproverSelectWindow } from "../approvers/ApproverSelectWindow";
import { ApproverUserEntry } from "../approvers/ApproverUserEntry";
import { AssignApproverButton } from "../approvers/AssignApproverButton";
import { KintaiTimestampButton, KintaiTimestampButtonProps } from "../approvers/KintaiTimestampButton";
import { MonthlyErrorsKF } from "../monthly/fields/errors/MonthlyErrors";
import { MonthlyInfosKF } from "../monthly/fields/errors/MonthlyInfos";
import { MonthlyWarningsKF } from "../monthly/fields/errors/MonthlyWarnings";
import { SPortSetFixedTimesSection, SPortSetFixedTimesSectionVM } from "./SPortSetFixedTimesSection";

export type KintaiReportRibbonVM = {
  name: string;
  userId: string;
  depName: string;

  errors: string[];
  warnings: string[];
  infos: string[];

  rankName: string;
  rankPosition: RankPositionType;

  mainKinmuType: MainKintaiTypeType;
  mainKinmuTypeDisabled: boolean;
  availableKintaiTypes: MainKintaiTypeType[];
  onChangeMainKinmuType: (type: MainKintaiTypeType) => void;
  loadingStatus: LoadingStatusType;

  designatedMinutes: Minutes | undefined;
  onChangeDesignatedMinutes: (value: Minutes | undefined) => void;
  designatedMinutesDisabled: boolean;

  showsPrinted: boolean;
  printDetailMode: PrintDetailMode;
  onSwitchPrintDetailMode: (detailMode: PrintDetailMode) => void;
  onSwitchInputMode: () => void;
  onSwitchPrintMode: () => void;

  approverModel: ApproverVM;
  reviewerModel: ApproverVM;

  selfTsModel: KintaiTimestampVM;
  approverTsModel: KintaiTimestampVM;
  reviewerTsModel: KintaiTimestampVM;
  clerkTsModel: KintaiTimestampVM;
  hrmTsModel: KintaiTimestampVM;

  saveDisabled: boolean;
  hasNoChange: boolean;
  validateInProgress: boolean;
  onSave: () => void;
  onReset: () => void;

  reloadDisabled: boolean;
  onReload: () => void;
  lastLoadTime: Date | undefined;

  showsBulkApproval: boolean;
  bulkApprovalDisabled: boolean;
  bulkApprovalCount: number;
  onBulkApproval: () => void;

  showsForceAttributeRecalc: boolean;
  onForceAttributeRecalc: () => void;

  showsForceRecalc: boolean;
  onForceRecalc: () => void;

  showsDeleteRetiredKintai: boolean;
  onDeleteRetiredKintai: () => void;
} & SPortSetFixedTimesSectionVM;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

const Container = styled.div`
  float: right;
`;

const WarningsContainer = styled.div`
  font-size: 8pt;
  line-height: 100%;
  display: inline-block;
  margin-right: 1em;
  & > * {
    display: block;
  }
`;

/**
 * ※ labelはコンポーネントで指定
 */
export type KintaiTimestampVM = Omit<Omit<KintaiTimestampButtonProps, "label">, "checkedLabel">;

export interface ApproverVM {
  userInfo?: ApproverUserEntry;
  candidates: ApproverUserEntry[];
  disabled: boolean;
  onChoose: (userId: string | undefined) => void;
  onCopyApproverFromLastMonth: () => void;
}

@observer
export class KintaiReportRibbon extends React.Component<{ model: KintaiReportRibbonVM; small: boolean }> {
  public render() {
    const { model, small } = this.props;

    if (model.loadingStatus !== "loaded") {
      return <LoadingRibbon />;
    }

    const { approverModel, reviewerModel } = model;
    const { selfTsModel, approverTsModel, reviewerTsModel, clerkTsModel, hrmTsModel } = model;

    const saveRibbon = (
      <SaveRibbon
        saveLabel={`${model.hasNoChange ? "" : "*"}${model.validateInProgress ? "検証" : "保存"}`}
        hasNoChange={model.hasNoChange}
        disabled={model.saveDisabled}
        onSave={model.onSave}
        onReset={model.onReset}
        small={small}
      />
    );

    if (small) {
      return (
        <Container>
          <WarningsContainer>
            <MonthlyWarningsKF mode="form" model={model} />
            <MonthlyErrorsKF mode="form" model={model} />
            {/* スペースがないので省略 */}
            {/* <MonthlyInfosKF mode="form" model={model} /> */}
          </WarningsContainer>

          {saveRibbon}
        </Container>
      );
    }

    return (
      <>
        <GuideRibbon markdown={Help} />

        <PageRibbonSection title="所属など">
          <PageRibbonLine>{model.depName}</PageRibbonLine>
          <PageRibbonLine>
            {model.name} ({model.userId})
          </PageRibbonLine>
          <PageRibbonLine>
            {model.rankName} ({rankPositionName(model.rankPosition)})
          </PageRibbonLine>
        </PageRibbonSection>

        <PageRibbonSection title={`エラー・警告`}>
          <PageRibbonLine>
            <MonthlyErrorsKF mode="form" model={model} />
          </PageRibbonLine>
          <PageRibbonLine>
            <MonthlyWarningsKF mode="form" model={model} />
          </PageRibbonLine>
          <PageRibbonLine>
            <MonthlyInfosKF mode="form" model={model} />
          </PageRibbonLine>
        </PageRibbonSection>

        <ScreenModeRibbon title={model.showsPrinted ? "表示" : "入力"}>
          <PageRibbonLine>
            <ButtonGroup>
              <Button
                active={!model.showsPrinted}
                small={true}
                intent={!model.showsPrinted ? Intent.PRIMARY : Intent.NONE}
                text="入力"
                icon="build"
                onClick={model.onSwitchInputMode}
              />
              <ButtonGroup>
                <Button
                  active={model.showsPrinted}
                  small={true}
                  intent={model.showsPrinted ? Intent.PRIMARY : Intent.NONE}
                  text={`表示 (${printDetailModeDef(model.printDetailMode).name})`}
                  icon={printDetailModeDef(model.printDetailMode).icon}
                  onClick={model.onSwitchPrintMode}
                />
                <Popover
                  boundary="viewport"
                  content={
                    <Menu>
                      {printDetailModeDefs.map(def => (
                        <MenuItem
                          key={def.code}
                          className={Classes.POPOVER_DISMISS}
                          active={model.printDetailMode === def.code}
                          icon={def.icon}
                          text={`${def.name}表示`}
                          onClick={() => model.onSwitchPrintDetailMode(def.code)}
                        />
                      ))}
                    </Menu>
                  }>
                  <Button small={true} intent={model.showsPrinted ? Intent.PRIMARY : Intent.NONE} icon="caret-down" />
                </Popover>
              </ButtonGroup>
            </ButtonGroup>
          </PageRibbonLine>
          <PageRibbonLine>
            <KinmuTypeButton
              disabled={model.mainKinmuTypeDisabled}
              buttonProps={{ small: true }}
              mainKinmuType={model.mainKinmuType}
              rankPosition={model.rankPosition}
              availableKintaiTypes={model.availableKintaiTypes}
              onChangeKinmuType={model.onChangeMainKinmuType}
            />
          </PageRibbonLine>
        </ScreenModeRibbon>

        <PageRibbonSection title="勤務値">
          <div style={{ width: "4em" }}>
            <HHMMField
              label="指示時間"
              disabled={model.designatedMinutesDisabled}
              mode="form"
              value={model.designatedMinutes}
              onChange={model.onChangeDesignatedMinutes}
            />
          </div>
        </PageRibbonSection>

        <SPortSetFixedTimesSection model={model} />

        <PageRibbonSection title="本人確認印">
          <PageRibbonLine>
            <KintaiTimestampButton label="提出する" checkedLabel="提出済" {...selfTsModel} />
          </PageRibbonLine>
        </PageRibbonSection>

        <PageRibbonSection title="部門確認印（査閲・承認）">
          <PageRibbonLine>
            <ButtonGroup>
              <KintaiTimestampButton label="査閲OK" checkedLabel="査閲済" {...reviewerTsModel} />
              <AssignApproverButton disabled={reviewerModel.disabled} currentUser={reviewerModel.userInfo}>
                <ApproverSelectWindow
                  users={reviewerModel.candidates}
                  currentUser={reviewerModel.userInfo}
                  onChooseApprover={reviewerModel.onChoose}
                  onCopyApproverFromLastMonth={reviewerModel.onCopyApproverFromLastMonth}
                />
              </AssignApproverButton>
            </ButtonGroup>
          </PageRibbonLine>
          <PageRibbonLine>
            <ButtonGroup>
              <KintaiTimestampButton label="承認OK" checkedLabel="承認済" {...approverTsModel} />
              <AssignApproverButton disabled={approverModel.disabled} currentUser={approverModel.userInfo}>
                <ApproverSelectWindow
                  users={approverModel.candidates}
                  currentUser={approverModel.userInfo}
                  onChooseApprover={approverModel.onChoose}
                  onCopyApproverFromLastMonth={approverModel.onCopyApproverFromLastMonth}
                />
              </AssignApproverButton>
            </ButtonGroup>
          </PageRibbonLine>
        </PageRibbonSection>
        <PageRibbonSection title="事務・人事確認印">
          <PageRibbonLine>
            <KintaiTimestampButton label="事務OK" checkedLabel="事務確認済" {...clerkTsModel} />
          </PageRibbonLine>
          <PageRibbonLine>
            <KintaiTimestampButton label="人事OK" checkedLabel="人事確認済" {...hrmTsModel} />
          </PageRibbonLine>
        </PageRibbonSection>

        <ReloadRibbon onReload={model.onReload} disabled={model.reloadDisabled} lastLoadTime={model.lastLoadTime} />

        {saveRibbon}

        {model.showsBulkApproval && (
          <PageRibbonSection title="一括承認">
            <PageRibbonLine>
              <Button
                small={true}
                disabled={model.bulkApprovalDisabled}
                onClick={model.onBulkApproval}
                intent={Intent.WARNING}
                text={`在宅のみ (${model.bulkApprovalCount})`}
              />
            </PageRibbonLine>
          </PageRibbonSection>
        )}

        {(model.showsForceAttributeRecalc || model.showsForceRecalc) && (
          <PageRibbonSection title="強制再計算">
            {model.showsForceAttributeRecalc && (
              <PageRibbonLine>
                <Button
                  small={true}
                  onClick={model.onForceAttributeRecalc}
                  intent={Intent.DANGER}
                  text="部門など強制再設定"
                />
              </PageRibbonLine>
            )}

            {model.showsForceRecalc && (
              <PageRibbonLine>
                <Button small={true} onClick={model.onForceRecalc} intent={Intent.DANGER} text="数値のみ強制再計算" />
              </PageRibbonLine>
            )}
          </PageRibbonSection>
        )}

        {model.showsDeleteRetiredKintai && (
          <PageRibbonSection title="勤怠表削除">
            <PageRibbonLine>
              <Button
                small={true}
                onClick={model.onDeleteRetiredKintai}
                intent={Intent.DANGER}
                text="退職後の勤怠表削除"
              />
            </PageRibbonLine>
          </PageRibbonSection>
        )}
      </>
    );
  }
}
