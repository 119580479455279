import { RegularDailyReportEditEntity } from "@webkintai/api";
import { NullKintai, 育介在宅 } from "@webkintai/bunrui";
import { hourBasedDaysEq, Minutes, minutesEq } from "@webkintai/core";
import { applySnapshot, getParentOfType, getSnapshot, types } from "mobx-state-tree";

import { dateEq } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { prefixedIdType } from "../../utils";
import { MonthlyKintai } from "../MonthlyKintai";
import { RegularDailyValues } from "./RegularDailyValues";
import { boolEq, regularItemState } from "./regularItemState";
import { regularTypeItemState } from "./regularTypeItemState";
import { workplaceItemState } from "./workplaceItemState";

const idPrefix = "RDK_";

const model = types
  .model("RegularDailyKintai", {
    id: prefixedIdType(idPrefix),
    date: types.Date,

    origin: RegularDailyValues,
    input: RegularDailyValues,
    computed: RegularDailyValues,

    type1: regularTypeItemState(1),
    type2: regularTypeItemState(2),
    type3: regularTypeItemState(3),
    type4: regularTypeItemState(4),

    doorEnter: regularItemState("doorEnter", "setDoorEnter", dateEq),
    doorLeave: regularItemState("doorLeave", "setDoorLeave", dateEq),

    ckrpEnter: regularItemState("ckrpEnter", "setCkrpEnter", dateEq),
    ckrpLeave: regularItemState("ckrpLeave", "setCkrpLeave", dateEq),

    transDay: regularItemState("transDay", "setTransDay", dateEq),

    hourlyLeave: regularItemState("hourlyLeave", "setHourlyLeave", hourBasedDaysEq),
    hourlyNursingcare: regularItemState("hourlyNursingcare", "setHourlyNursingcare", hourBasedDaysEq),
    hourlyFamilycare: regularItemState("hourlyFamilycare", "setHourlyFamilycare", hourBasedDaysEq),

    arrival: regularItemState("arrivalAsMinutes", "setArrivalFromMinutes", minutesEq, "arrival"),
    leave: regularItemState("leaveAsMinutes", "setLeaveFromMinutes", minutesEq, "leave"),
    rest: regularItemState("rest", "setRest", minutesEq),
    actual: regularItemState("actual", "setActual", minutesEq),
    flex: regularItemState("flex", "setFlex", minutesEq),
    holiday: regularItemState("holiday", "setHoliday", minutesEq),
    midnight: regularItemState("midnight", "setMidnight", minutesEq),
    allNightOff: regularItemState("allNightOff", "setAllNightOff", minutesEq),
    spWeekday: regularItemState("spWeekday", "setSpWeekday", minutesEq),
    spWeekdayMidnight: regularItemState("spWeekdayMidnight", "setSpWeekdayMidnight", minutesEq),
    spHoliday: regularItemState("spHoliday", "setSpHoliday", minutesEq),
    spHolidayMidnight: regularItemState("spHolidayMidnight", "setSpHolidayMidnight", minutesEq),
    diff: regularItemState("diff", "setDiff", minutesEq),
    off: regularItemState("off", "setOff", minutesEq),
    offCount: regularItemState("offCount", "setOffCount", (l: number | undefined, r: number | undefined) => l === r),
    over: regularItemState("over", "setOver", minutesEq),
    overHoliday: regularItemState("overHoliday", "setOverHoliday", minutesEq),
    overHolidayMidnight: regularItemState("overHolidayMidnight", "setOverHolidayMidnight", minutesEq),
    growingOver: regularItemState("growingOver", "setGrowingOver", minutesEq),
    overWeekday: regularItemState("overWeekday", "setOverWeekday", minutesEq),
    overWeekdayMidnight: regularItemState("overWeekdayMidnight", "setOverWeekdayMidnight", minutesEq),
    remarks: regularItemState("remarks", "setRemarks", (l: string, r: string) => l === r),

    unusedRestSunset: regularItemState("unusedRestSunset", "setUnusedRestSunset", boolEq),
    unusedRestNight: regularItemState("unusedRestNight", "setUnusedRestNight", boolEq),

    workplaceZaitakuLe4H: workplaceItemState("workplaceZaitakuLe4H", "setWorkplaceZaitakuLe4H"),
    workplaceZaitakuGt4H: workplaceItemState("workplaceZaitakuGt4H", "setWorkplaceZaitakuGt4H"),
    workplaceSyussya: workplaceItemState("workplaceSyussya", "setWorkplaceSyussya"),
    workplaceSonota: workplaceItemState("workplaceSonota", "setWorkplaceSonota"),
  })
  .views(self => {
    const root = () => getParentOfType(self, MonthlyKintai);

    return {
      get dailyApp() {
        return root().apps!.getDayOf(self.date);
      },
    };
  })
  .views(self => {
    return {
      get items() {
        return [
          self.type2,
          self.type3,
          self.type4,
          self.transDay,
          self.hourlyLeave,
          self.hourlyNursingcare,
          self.hourlyFamilycare,
          self.arrival,
          self.leave,
          self.rest,
          self.actual,
          self.flex,
          self.holiday,
          self.midnight,
          self.allNightOff,
          self.spWeekday,
          self.spWeekdayMidnight,
          self.spHoliday,
          self.spHolidayMidnight,
          self.diff,
          self.off,
          self.offCount,
          self.over,
          self.overHoliday,
          self.overHolidayMidnight,
          self.growingOver,
          self.overWeekday,
          self.overWeekdayMidnight,
          self.remarks,
          self.unusedRestSunset,
          self.unusedRestNight,
          self.workplaceZaitakuGt4H,
          self.workplaceZaitakuLe4H,
          self.workplaceSyussya,
          self.workplaceSonota,
        ];
      },
      get typesHasEssentiallyNoChange() {
        return hasNoChangeReduce([
          self.type2.hasEssentiallyNoChange,
          self.type3.hasEssentiallyNoChange,
          self.type4.hasEssentiallyNoChange,
        ]);
      },
      get canMadeBulkApproved() {
        return self.origin.type2 === 育介在宅 && self.origin.type3 === NullKintai;
      },
      get errors() {
        return self.computed.errors.filter(it => it.isError).map(it => it.message);
      },
      get warnings() {
        return self.computed.errors.filter(it => it.isWarning).map(it => it.message);
      },
      get infos() {
        return self.computed.errors.filter(it => it.isInfo).map(it => it.message);
      },
      get hasNoChange() {
        return this.items.map(it => it.hasNoChange).reduce((l, r) => l && r, true);
      },
      get typesAreLocked() {
        return self.dailyApp.kintaiTypeShouldBeLocked;
      },
      get workplacesApiValues(): RegularDailyReportEditEntity.WorkplacesEnum[] {
        const result: RegularDailyReportEditEntity.WorkplacesEnum[] = [];

        // 入力不可の場合は空として送信する
        if (self.input.disableWorkplaceInput) {
          return result;
        }

        if (self.workplaceZaitakuGt4H.value) {
          result.push(RegularDailyReportEditEntity.WorkplacesEnum.ZAITAKUGT4H);
        }
        if (self.workplaceZaitakuLe4H.value) {
          result.push(RegularDailyReportEditEntity.WorkplacesEnum.ZAITAKULE4H);
        }
        if (self.workplaceSyussya.value) {
          result.push(RegularDailyReportEditEntity.WorkplacesEnum.SYUSSYA);
        }
        if (self.workplaceSonota.value) {
          result.push(RegularDailyReportEditEntity.WorkplacesEnum.SONOTA);
        }

        return result;
      },
      get unusedRestTimesApiValues(): RegularDailyReportEditEntity.UnusedRestTimesEnum[] {
        const result = [];
        if (self.unusedRestSunset.value) {
          result.push(RegularDailyReportEditEntity.UnusedRestTimesEnum._18001830);
        }
        if (self.unusedRestNight.value) {
          result.push(RegularDailyReportEditEntity.UnusedRestTimesEnum._21002130);
        }

        return result;
      },
      get hasCkrpRecord(): boolean {
        return !!(self.ckrpEnter.value || self.ckrpLeave.value);
      },
    };
  })
  .actions(self => {
    return {
      applyErrors() {
        self.type1.applyErrors();
        self.type2.applyErrors();
        self.type3.applyErrors();
        self.type4.applyErrors();
        self.transDay.applyErrors();
        self.hourlyLeave.applyErrors();
        self.hourlyFamilycare.applyErrors();
        self.hourlyNursingcare.applyErrors();
        self.arrival.applyErrors();
        self.leave.applyErrors();
        self.rest.applyErrors();
        self.actual.applyErrors();
        self.flex.applyErrors();
        self.holiday.applyErrors();
        self.midnight.applyErrors();
        self.allNightOff.applyErrors();
        self.spWeekday.applyErrors();
        self.spWeekdayMidnight.applyErrors();
        self.spHoliday.applyErrors();
        self.spHolidayMidnight.applyErrors();
        self.diff.applyErrors();
        self.off.applyErrors();
        self.offCount.applyErrors();
        self.over.applyErrors();
        self.overHoliday.applyErrors();
        self.overHolidayMidnight.applyErrors();
        self.growingOver.applyErrors();
        self.overWeekday.applyErrors();
        self.overWeekdayMidnight.applyErrors();
        self.remarks.applyErrors();

        self.unusedRestSunset.applyErrors();
        self.unusedRestNight.applyErrors();

        self.workplaceZaitakuLe4H.applyErrors();
        self.workplaceZaitakuGt4H.applyErrors();
        self.workplaceSyussya.applyErrors();
        self.workplaceSonota.applyErrors();
      },
      reset() {
        const value = getSnapshot(self.origin);
        applySnapshot(self.input, value);
        applySnapshot(self.computed, value);
        this.applyErrors();
      },
      _applyEnterValue(ifAbsent: boolean, enterValue: Date | undefined) {
        if (!enterValue || (ifAbsent && self.arrival.value)) {
          return;
        }
        self.arrival.onChange(Minutes.fromDate(self.date, enterValue));
      },
      _applyLeaveValue(ifAbsent: boolean, leaveValue: Date | undefined) {
        if (!leaveValue || (ifAbsent && self.arrival.value)) {
          return;
        }
        self.leave.onChange(Minutes.fromDate(self.date, leaveValue));
      },
      applyDoorEnter(ifAbsent = false) {
        this._applyEnterValue(ifAbsent, self.doorEnter.value);
      },
      applyDoorLeave(ifAbsent = false) {
        this._applyLeaveValue(ifAbsent, self.doorLeave.value);
      },
      applyCkrpEnter(ifAbsent = false) {
        this._applyEnterValue(ifAbsent, self.ckrpEnter.value);
      },
      applyCkrpLeave(ifAbsent = false) {
        this._applyLeaveValue(ifAbsent, self.ckrpLeave.value);
      },
    };
  });

export const idRegularDailyKintai = (userId: string, date: Date) =>
  `${idPrefix}${userId}_${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`;
export const RegularDailyKintai: RegularDailyKintaiModelType = model;
type RegularDailyKintaiInferredType = typeof model;
export interface RegularDailyKintaiModelType extends RegularDailyKintaiInferredType {}
