import { flow } from "mobx";
import { getEnv, types } from "mobx-state-tree";

import { prevMonth } from "../../utils/calendar";
import { UsersSymbol, UsersType } from "../users/Users";
import {
  copyValuesFromAnotherMonthlyKintai,
  idMonthlyKintai,
  KintaiAttrCopyOptions,
  MonthlyKintai,
} from "./MonthlyKintai";

/**
 * 勤怠管理上のユーザのモデル
 *
 * 単一のユーザの配下に複数の年月単位の勤怠表が連なる
 */
const model = types
  .model("UserKintaiModel", {
    userId: types.identifier,
    months: types.map(MonthlyKintai),
  })
  .views(self => {
    const users = () => getEnv(self).get(UsersSymbol) as UsersType;
    return {
      get userName() {
        const user = users().getUserFromId(self.userId);
        if (user) {
          return user.userName;
        }

        const ent = self.months.values().next();
        if (ent) {
          return ent.value.userName;
        }

        return "";
      },
    };
  })
  .actions(self => {
    const prepare = (month: Date) => {
      const id = idMonthlyKintai(self.userId, month);
      return (
        self.months.get(id) ||
        (() => {
          const created = MonthlyKintai.create({
            id,
            userId: self.userId,
            month,
          });
          self.months.put(created);
          return created;
        })()
      );
    };

    const copyAttrsFromLastMonth = flow(function*(dest: typeof MonthlyKintai.Type, options: KintaiAttrCopyOptions) {
      const src = prepare(prevMonth(dest.month));
      yield src.loadIfNeeded();
      copyValuesFromAnotherMonthlyKintai(src, dest, options);
    });

    return {
      copyAttrsFromLastMonth,
      prepare,
      release(kintai: typeof MonthlyKintai.Type) {
        self.months.delete(kintai.id);
      },
    };
  });
export const KintaiUser: KintaiUserModelType = model;
type KintaiUserInferredType = typeof model;
export interface KintaiUserModelType extends KintaiUserInferredType {}
