import { action, computed, observable } from "mobx";

import { lazyInject } from "../../../didecorators";
import { KintaiSumPageModel, KintaiSumPageModelSymbol } from "../../../models/kintai_sum/KintaiSumPageModel";
import { getNendoMonths } from "../../../utils/calendar";
import { DeptSelectorVMImpl } from "../../common/dept-selector/DeptSelectorVMImpl";
import { KintaiSumPageSumType } from "./common/KintaiSumPageSumType";
import { KintaiSumPageVM } from "./KintaiSumPage";
import { KintaiSumPageRibbonVM } from "./ribbon/KintaiSumPageRibbon";
import { KintaiSumTableByOrderVM } from "./table/KintaiSumTableByOrder";
import { KintaiSumTableByOrderRowVMImpl } from "./table/KintaiSumTableByOrderRowVMImpl";
import { KintaiSumTableByOrderUserVM } from "./table/KintaiSumTableByOrderUser";
import { KintaiSumTableByOrderUserRowVMImpl } from "./table/KintaiSumTableByOrderUserRowVMImpl";
import { KintaiSumTableByUserVM } from "./table/KintaiSumTableByUser";
import { KintaiSumTableByUserMonthVMImpl } from "./table/KintaiSumTableByUserMonthUserVMImpl";

export class KintaiSumPageVMImpl
  implements
    KintaiSumPageVM,
    KintaiSumPageRibbonVM,
    KintaiSumTableByOrderUserVM,
    KintaiSumTableByOrderVM,
    KintaiSumTableByUserVM {
  public get ribbonModel() {
    return this;
  }

  public get byOrderUserTableVM() {
    return this;
  }
  public get byOrderTableVM() {
    return this;
  }
  public get byUserTableVM() {
    return this;
  }

  @computed
  public get loadingStatus() {
    return this.model.searchResultLoadingState;
  }

  @computed
  public get sumType() {
    return this.model.mode;
  }

  @computed
  public get nendo() {
    return this.model.targetNendo;
  }
  @computed
  public get nendoList() {
    return this.model.nendoList;
  }

  @computed
  public get targetDepCode() {
    return this.model.targetDepCode;
  }

  @computed
  public get deptsLoadingState() {
    return this.model.deptsLoadingState;
  }

  @computed
  public get deptSelectorVM() {
    const nendoDepts = this.model.nendoDepts!;
    return new DeptSelectorVMImpl(
      this.model.targetDept,
      nendoDepts,
      this.deptSelectorFilterText,
      selectedDept => {
        this.model.navigateToDepCode(selectedDept.depCode);
      },
      this.setDeptSelectorFilterText,
      false,
    );
  }

  @computed
  get filterText() {
    return this.model.filterText;
  }

  @computed
  get displayHourAsDecimal() {
    return this.model.displayHourAsDecimal;
  }

  @computed
  get projectOrderTotal() {
    return this.model.byOrderView.allTotal;
  }

  @computed
  get monthlyProjectOrderTotals() {
    return this.model.byOrderView.monthTotals;
  }

  @computed
  get byOrderUserRows() {
    return this.model.byOrderView.byOrderUserRows.map(it => new KintaiSumTableByOrderUserRowVMImpl(it));
  }

  @computed
  get byOrderRows() {
    return this.model.byOrderView.byOrderRows.map(it => new KintaiSumTableByOrderRowVMImpl(it));
  }

  @computed
  get byUserMonths() {
    return this.model.byUserView.months.map(month => new KintaiSumTableByUserMonthVMImpl(month));
  }

  @computed
  get showFilterByMonths() {
    return this.model.mode === "byUser";
  }

  @lazyInject(KintaiSumPageModelSymbol)
  private model: typeof KintaiSumPageModel.Type;

  @observable
  private deptSelectorFilterText: string = "";

  @action.bound
  public onChangeSumType(value: KintaiSumPageSumType) {
    this.model.setMode(value);
  }

  @action.bound
  public onChangeNendo(nendo: number) {
    this.model.navigateToNendo(nendo);
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  public get allFilterByMonthsSelected() {
    return this.model.allTargetMonthsSelected;
  }

  @action.bound
  public onChooseAllFilterByMonths() {
    this.model.setFilterByMonthAll();
  }

  @action.bound
  public onChangeDisplayHourAsDecimal(value: boolean) {
    this.model.setDisplayHourAsDecimal(value);
  }

  @computed
  public get filterByMonthOptions() {
    return getNendoMonths(2018).map(month => ({
      month: month.getMonth() + 1,
      selected: this.model.isInTargetMonth(month),
      onSelectFilterByMonth: () => {
        this.model.setFilterByMonth(month);
      },
    }));
  }

  @action.bound
  private setDeptSelectorFilterText(value: string) {
    this.deptSelectorFilterText = value;
  }
}
