import { action, computed } from "mobx";
import moment from "moment";

import { lazyInject } from "../../didecorators";
import { KintaiPageModel, KintaiPageModelSymbol } from "../../models/kintai/KintaiPageModel";
import { KintaiReportVMImpl } from "../common/kintai-table/KintaiReportVMImpl";
import { KintaiSubMenuVM } from "./header/KintaiSubMenu";
import { KintaiPageVM } from "./KintaiPage";

export class KintaiPageVMImpl implements KintaiPageVM, KintaiSubMenuVM {
  public kintaiReportVM: KintaiReportVMImpl;

  @lazyInject(KintaiPageModelSymbol)
  private model: typeof KintaiPageModel.Type;

  constructor() {
    this.kintaiReportVM = new KintaiReportVMImpl(this.model.kintaiReport);
  }
  public get kintaiSubMenuVM() {
    return this;
  }

  @computed
  public get date(): Date {
    return this.model.date;
  }

  @computed
  get nendoList() {
    return this.model.nendoList;
  }

  @action.bound
  public onChangeTargetDate(d: Date) {
    this.model.navigateToDate(d);
  }

  @action.bound
  public onMoveDate(delta: number) {
    this.model.navigateToDate(
      moment(this.date)
        .add(delta, "days")
        .toDate(),
    );
  }

  @action.bound
  public onMoveMonth(delta: number) {
    this.model.navigateToDate(
      moment(this.date)
        .add(delta, "months")
        .toDate(),
    );
  }

  @computed
  get filterText() {
    return this.model.filterText;
  }

  @action.bound
  public onFilterTextChange(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  get openByFilterTextDisabled() {
    return this.model.openByFilterTextDisabled;
  }

  @action.bound
  public onOpenFilterText() {
    if (this.model.openTargetUserId) {
      this.model.navigateToKintai(this.model.openTargetUserId);
    }
  }

  @computed
  get kintaiUserList() {
    return this.model.openedKintaiList.map(it => ({
      ...it,
      onNavigate: () => {
        this.model.navigateToKintai(it.userId);
      },
    }));
  }

  @computed
  get activeOpenedKintai() {
    return this.kintaiUserList.find(it => it.userId === this.model.openedKintaiUserId);
  }

  @computed
  get myKintaiIsOpened() {
    return this.model.myKintaiHasBeenOpened;
  }

  @action.bound
  public onNavigateToMyKintai() {
    this.model.navigateToKintai();
  }
}
