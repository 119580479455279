import React, { useCallback, useContext } from "react";

import { WkLinkContext } from "./WkLinkContext";

export const WkLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = props => {
  const router = useContext(WkLinkContext);
  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLAnchorElement>) => {
      if (!props.href) {
        return;
      }
      if (ev.ctrlKey || ev.shiftKey || ev.altKey || ev.metaKey) {
        // そのままブラウザ処理に委譲する
        return;
      }
      ev.preventDefault();
      router.route(props.href);
    },
    [props.href, router],
  );

  return (
    <a {...props} onClick={onClick}>
      {props.children}
    </a>
  );
};
