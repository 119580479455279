import { RankAttrListResult } from "@webkintai/api";
import { keyBy, sortBy } from "lodash-es";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";

import { RankApi } from "../../services/api/RankApi";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { prefixedIdType } from "../utils";
import { idNendoRank, NendoRank } from "./NendoRank";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;
export const idPrefix = "NendoRanks_";

const model = types
  .model("NendoRanks", {
    id: prefixedIdType(idPrefix),
    loadingStatus: types.optional(LoadingStatus, "loading"),
    nendo: types.number,
    ranks: types.map(NendoRank),
  })
  .views(self => {
    return {
      get ranksList() {
        return sortBy([...self.ranks.values()], rank => rank.rankCode);
      },
      getRank(rankCode: string) {
        return self.ranks.get(idNendoRank(self.nendo, rankCode));
      },
    };
  })
  .actions(self => {
    const rankApi = () => getDI(self, RankApi);

    return {
      loadIfNeeded() {
        return self.loadingStatus === "loaded" ? new Promise(done => done()) : this.load();
      },

      load: flow(function*() {
        self.loadingStatus = "loading";

        const result: RankAttrListResult = yield rankApi().getRank(self.nendo);

        applySnapshot(
          self.ranks,
          keyBy(
            result.rankAttrs.map(rankAttr => ({
              id: idNendoRank(self.nendo, rankAttr.rankCode),
              nendo: self.nendo,
              rankCode: rankAttr.rankCode,
              rankName: rankAttr.rankName || "",
              workingType: rankAttr.workingType ? (rankAttr.workingType.value as any) : undefined,
              rankPosition: rankAttr.position ? (rankAttr.position.value as any) : undefined,
              roleCode: rankAttr.role.value,
            })),
            "id",
          ),
        );

        self.loadingStatus = "loaded";
      }),
    };
  });

export type NendoRanksType = typeof NendoRanks.Type;

export const idNendoRanks = (nendo: number) => `${idPrefix}${nendo}`;

export const NendoRanksSymbol = "NendoRanks_Symbol";
export const NendoRanks: NendoRanksModelType = model;
type NendoRanksInferredType = typeof model;
export interface NendoRanksModelType extends NendoRanksInferredType {}
