import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalRestKFProps = {
  model: MTotalRestKFVM;
} & CellFormPrintAdaptable;

export interface MTotalRestKFVM {
  mTotalRest: KintaiInputItemState<Minutes>;
}

export const MTotalRestKF = observer((props: MTotalRestKFProps) => {
  const { model } = props;
  const itemState = model.mTotalRest;

  return <HHMMField {...props} label="mTotalRestKF" {...itemState} onChange={undefined} />;
});
