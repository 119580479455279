import { Button } from "@blueprintjs/core";
import classNames from "classnames";
import React from "react";

import { FileSelector } from "../FileSelector";
import { PageRibbonLine } from "../page/PageRibbonLine";
import { PageRibbonSection } from "../page/PageRibbonSection";

export interface ExcelIORibbonProps {
  onExcelExport?: () => void;
  onExcelImport?: (f: File) => void;
}

export const ExcelIORibbon = (props: ExcelIORibbonProps) => {
  const onFileSelect = props.onExcelImport || (() => {});

  return (
    <PageRibbonSection title="Excel連携">
      {props.onExcelExport && (
        <PageRibbonLine>
          <Button onClick={props.onExcelExport} small={true} icon="heat-grid">
            Excel書出
          </Button>
        </PageRibbonLine>
      )}
      {props.onExcelImport && (
        <PageRibbonLine>
          <FileSelector onFileSelect={onFileSelect}>
            {params => (
              <Button
                className={classNames(params.selectedFile && "FileSelected")}
                onClick={params.onOpenFileDialog}
                small={true}
                icon="export">
                Excel読込
              </Button>
            )}
          </FileSelector>
        </PageRibbonLine>
      )}
    </PageRibbonSection>
  );
};
