import { Minutes, minutesEq } from "@webkintai/core";
import { range } from "lodash-es";
import { applySnapshot, getParentOfType, getSnapshot, types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../../utils/model";
import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { MonthlyKintai } from "../MonthlyKintai";
import { PJReportDailyCell, reduceMinutes } from "./PJReportDailyCell";

const model = types
  .model("PJReportPillarDay", {
    date: types.Date,

    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string),

    input: types.array(PJReportDailyCell),
    origin: types.array(PJReportDailyCell),
    computed: types.array(PJReportDailyCell),
  })
  .views(self => {
    function root() {
      return getParentOfType(self, MonthlyKintai);
    }

    return {
      get cellsCount() {
        return 3;
      },
      get cells(): Array<KintaiInputItemState<Minutes | undefined>> {
        const disabled = root().isPJReportLocked;
        return range(0, this.cellsCount).map(idx => ({
          get value() {
            return self.input[idx].time;
          },
          onChange: (value: Minutes | undefined) => {
            self.input[idx].setHour(value);
          },
          errors: idx === 0 ? self.errors : [],
          warnings: idx === 0 ? self.warnings : [],
          infos: idx === 0 ? self.infos : [],
          disabled,
          get hasNoChange() {
            return minutesEq(self.input[idx].time, self.origin[idx].time);
          },
        }));
      },
    };
  })
  .views(self => {
    return {
      get total() {
        return reduceMinutes(self.input.map(it => it.time));
      },
      get hasNoChange() {
        return hasNoChangeReduce(self.cells.map(it => it.hasNoChange));
      },
    };
  })
  .actions(self => {
    return {
      reset() {
        const values = getSnapshot(self.origin);
        applySnapshot(self.input, values);
        applySnapshot(self.computed, values);
      },
    };
  });
export const PJReportPillarDay: PJReportPillarDayModelType = model;
type PJReportPillarDayInferredType = typeof model;
export interface PJReportPillarDayModelType extends PJReportPillarDayInferredType {}
