import { Tooltip } from "@blueprintjs/core";
import React from "react";

import { ErrorOverlay } from "../../../../form/ErrorOverlay";
import { ErrorCell, ErrorCountColor } from "../errors/ErrorComponents";

export interface RequiredApprovalErrorsKFProps {
  mode: "cell" | "form";
  label: string;
  errors: string[];
}
export const RequiredApprovalErrorsKF = (props: RequiredApprovalErrorsKFProps) => {
  const { mode, label, errors } = props;

  if (props.mode === "cell") {
    return (
      <ErrorOverlay mode="cell" warnings={errors} errors={[]}>
        <ErrorCell>
          <ErrorCountColor length={errors.length}>{errors.length}</ErrorCountColor>
        </ErrorCell>
      </ErrorOverlay>
    );
  }

  return (
    <Tooltip
      disabled={errors.length === 0}
      boundary="viewport"
      content={
        <div>
          {errors.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </div>
      }>
      <>
        {label}
        <ErrorCountColor length={errors.length}>{errors.length}件</ErrorCountColor>
      </>
    </Tooltip>
  );
};
