import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalTakenHourlyFamilycareKFProps = {
  model: MTotalTakenHourlyFamilycareKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTakenHourlyFamilycareKFVM {
  mTotalTakenHourlyFamilycare: KintaiInputItemState<HourBasedDays>;
}

export const MTotalTakenHourlyFamilycareKF = observer((props: MTotalTakenHourlyFamilycareKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalTakenHourlyFamilycare);

  return (
    <HourBasedDaysCountField
      showAsDateCount={false}
      {...props}
      label="MTotalTakenHourlyFamilycare"
      {...itemState}
      onChange={undefined}
    />
  );
});
