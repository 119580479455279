import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { RequiredApprovalErrorsKF } from "./RequiredApprovalErrorsKF";

export type MonthlyNotApprovedErrorsKFProps = {
  model: MonthlyNotApprovedErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyNotApprovedErrorsKFVM {
  monthlyNotApprovedErrors: string[];
}

export const MonthlyNotApprovedErrorsKF = observer((props: MonthlyNotApprovedErrorsKFProps) => {
  const { model, mode } = props;
  return <RequiredApprovalErrorsKF label="却下" errors={model.monthlyNotApprovedErrors} mode={mode} />;
});
