import { sortBy } from "lodash-es";
import { flow, IModelType, types } from "mobx-state-tree";

import { PHImportResult, PHInputExcel } from "../../services/excel/ph/PHInputExcel";
import { FileSaver } from "../../services/file/FileSaver";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getNendo } from "../../utils/calendar";
import { xlsxMimeType } from "../../utils/mimetypes";
import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { PHUserEntries } from "./PHUserEntries";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("PHPageModel", {
      loadingStatus: types.optional(LoadingStatus, "init"),
      inSaving: types.optional(types.boolean, false),

      phUserEntries: PHUserEntries,
      nendo: types.optional(types.number, getNendo(new Date())),
      filterText: types.optional(types.string, ""),
      excludeOldRetireUsers: types.optional(types.boolean, true),
      excludeHasNoChange: types.optional(types.boolean, false),
    })
    .views(self => {
      return {
        get searchWords() {
          return filterTextToSearchWords(self.filterText);
        },
      };
    })
    .views(self => {
      return {
        get hasNoChange() {
          return self.phUserEntries.hasNoChange;
        },
        get targetNendos() {
          return [self.nendo - 1, self.nendo, self.nendo + 1];
        },
        get users() {
          const filtered = [...self.phUserEntries.userEntries.values()]
            .filter(it => checkIfFilterTextMatches(it.filteredContent, self.searchWords))
            .filter(r => !self.excludeHasNoChange || !r.hasNoChange)
            .filter(it => !self.excludeOldRetireUsers || !it.isOldEnoughExcludeByRetirement);

          return sortBy(filtered, o => o.userId);
        },
        get saveDisabled() {
          return this.hasNoChange || self.inSaving;
        },
        get reloadDisabled() {
          return !this.hasNoChange || self.inSaving;
        },
      };
    })
    .actions(self => {
      const appNotifier = () => getDI(self, AppNotifier);
      const excelService = () => getDI(self, PHInputExcel);
      const fileSaver = () => getDI(self, FileSaver);

      const save = flow(function*() {
        let progress = "";
        try {
          self.inSaving = true;

          for (const nendo of self.targetNendos) {
            progress = `${nendo} 年の保存`;
            yield self.phUserEntries.saveNendo(nendo);
          }

          for (const nendo of self.targetNendos) {
            progress = `${nendo} 年のデータロード`;
            yield self.phUserEntries.loadNendo(nendo);
          }

          appNotifier().info({ message: "保存しました。" });
        } catch (exception) {
          appNotifier().error({ message: `有給休暇データの保存に失敗しました。箇所: ${progress}`, exception });
        } finally {
          self.inSaving = false;
        }
      });

      const triggerLoad = flow(function*(forceReload = false) {
        if (!forceReload && self.loadingStatus === "loaded") {
          return;
        }

        try {
          self.loadingStatus = "loading";

          yield self.phUserEntries.loadMasterIfNeeded(self.nendo);
          for (const nendo of self.targetNendos) {
            if (forceReload) {
              yield self.phUserEntries.loadNendo(nendo);
            } else {
              yield self.phUserEntries.loadNendoIfNeeded(nendo);
            }
          }

          self.loadingStatus = "loaded";
        } catch (exception) {
          self.loadingStatus = "failed";
          appNotifier().error({ message: "有給休暇データのロードに失敗しました", exception });
        }
      });

      const exportExcel = flow(function*(): any {
        const buffer: Buffer = yield excelService().exportExcel(self.users, self.targetNendos);
        fileSaver().saveFile(`Web勤怠v2_${self.nendo}年度周辺_有休休暇.xlsx`, buffer, xlsxMimeType);
      });

      const importExcel = flow(function*(file: File): any {
        const importResult: PHImportResult = yield excelService().importExcel(file);
        if (importResult.status === "failure") {
          appNotifier().error({ message: `読み込みに失敗しました - ${importResult.failureReason}` });
          return;
        }
        self.phUserEntries.importExcel(importResult);
      });

      return {
        setFilterText(value: string) {
          self.filterText = value;
        },

        route(pathFragment: string) {
          self.nendo = getNendo(new Date());
          triggerLoad();
        },
        reload() {
          triggerLoad(true);
        },

        save,
        reset() {
          self.phUserEntries.reset();
        },
        setExcludeOldRetireUsers(value: boolean) {
          self.excludeOldRetireUsers = value;
        },
        setExcludeHasNoChange(value: boolean) {
          self.excludeHasNoChange = value;
        },
        exportExcel,
        importExcel,
      };
    }),
  {},
);

export type PHPageModelType = typeof PHPageModel.Type;

export const PHPageModelSymbol = "PHPageModel_Symbol";
export const PHPageModel: PHPageModelModelType = model;
type PHPageModelInferredType = typeof model;
export interface PHPageModelModelType extends PHPageModelInferredType {}
