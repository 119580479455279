import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { DeptsPageModel, DeptsPageModelSymbol } from "../../../models/depts/DeptsPageModel";
import { DiPanelMode } from "../../../models/kintai/DiPanelMode";
import { getNendo } from "../../../utils/calendar";
import { DeptSelectorEntry } from "../../common/dept-selector/DeptSelectorEntry";
import { DeptSelectorVMImpl } from "../../common/dept-selector/DeptSelectorVMImpl";
import { DeptsTreePanelVM } from "./deptree/DeptsTreePanel";
import { DeptUsersPanelRowVMImpl } from "./deptree/DeptUsersPanelRowVMImpl";
import { DeptsPageVM } from "./DeptsPage";
import { DeptsPageRibbonVM } from "./ribbon/DeptsPageRibbon";

export class DeptsPageVMImpl implements DeptsPageVM, DeptsPageRibbonVM, DeptsTreePanelVM {
  @lazyInject(DeptsPageModelSymbol)
  private model: typeof DeptsPageModel.Type;

  @computed
  private get targetModel() {
    return this.model.targetNendoInCharges!;
  }

  public get ribbonModel() {
    return this;
  }
  public get gridModel() {
    return this;
  }

  public get deptUsersPanelVM() {
    return this;
  }

  public get deptsTreePanelVM() {
    return this;
  }

  public get deptSelectorVM() {
    return new DeptSelectorVMImpl(
      this.activeDept,
      this.targetModel.nendoDepts!,
      this.model.filterText,
      this.onSelectDept,
      this.model.setFilterText,
      false,
    );
  }

  @computed
  get panelMode() {
    return this.model.panelMode;
  }

  @action.bound
  public onChangePanelMode(value: DiPanelMode) {
    this.model.setPanelMode(value);
  }

  @computed
  get nendo() {
    return this.model.nendo;
  }

  @computed
  get nendoList() {
    return this.model.nendoList;
  }

  @computed
  get loadingStatus() {
    return this.model.loadingStatus;
  }

  @action.bound
  public onChangeNendo(date: Date) {
    this.model.navigateToNendo(getNendo(date));
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @computed
  get reloadDisabled() {
    return this.model.reloadDisabled;
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @computed
  get showsSaveRibbon() {
    return this.model.allowedToEdit;
  }

  @computed
  get saveDisabled() {
    return this.model.saveDisabled;
  }

  @action.bound
  public onReset() {
    this.targetModel.reset();
  }

  @action.bound
  public onSave() {
    this.targetModel.save();
  }

  @computed
  private get activeDept() {
    return this.targetModel.activeDept;
  }

  @action.bound
  public onSelectDept(selected: DeptSelectorEntry) {
    this.targetModel.setActiveDepCode(selected.depCode);
  }

  @computed
  private get disabled() {
    return !this.targetModel.allowedToEdit;
  }

  @computed
  get deptUserRows() {
    const picDept = this.targetModel.activeDeptPIC;
    if (!picDept) {
      return [];
    }

    return picDept.usersInDept.map(
      user => new DeptUsersPanelRowVMImpl(this.disabled, false, this, this.targetModel, picDept, user),
    );
  }

  @computed
  get superiorUserRows() {
    const picDept = this.targetModel.activeDeptPIC;
    if (!picDept) {
      return [];
    }

    return this.targetModel.activeDeptPIC!.usersInSuperiorDept.map(
      user => new DeptUsersPanelRowVMImpl(true, true, this, this.targetModel, picDept, user),
    );
  }

  @computed
  get selectedUserRows() {
    const picDept = this.targetModel.activeDeptPIC;
    if (!picDept) {
      return [];
    }

    return this.targetModel.activeDeptPIC!.selectedUserRows.map(
      user => new DeptUsersPanelRowVMImpl(this.disabled, false, this, this.targetModel, picDept, user),
    );
  }
}
