import React, { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";

import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { CellLabelField } from "./label/CellLabelField";
import { FormLabelField } from "./label/FormLabelField";

export type LabelFieldCoreProps = {
  className?: string;
  hasNoChange?: boolean;
  children?: React.ReactNode;
  alignment?: "center" | "right" | "left";
  onClick?: () => void;
  errors?: string[];
  warnings?: string[];
  infos?: string[];
  style?: React.CSSProperties;
};

export type LabelFieldProps = LabelFieldCoreProps & CellFormPrintAdaptable;

export const LabelField: React.FunctionComponent<LabelFieldProps> = props => {
  if (props.mode === "print") {
    return <>{props.children}</>;
  }
  if (props.mode === "cell") {
    return <CellLabelField {...props} />;
  }
  return <FormLabelField {...props} />;
};
