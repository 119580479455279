import { action, computed } from "mobx";

import { PJReportPillar } from "../../../../../models/kintai/pjreport/PJReportPillar";
import { PJReportPillarDay } from "../../../../../models/kintai/pjreport/PJReportPillarDay";
import { DailyPillarVM } from "./RegularDailyKintaiFormPJReport";

export class DailyPillarVMImpl implements DailyPillarVM {
  constructor(
    private readonly pillarIdx: number,
    private readonly pillar: typeof PJReportPillar.Type,
    private readonly day: typeof PJReportPillarDay.Type,
  ) {}

  public init() {}

  @computed
  public get id() {
    return this.pillar.id;
  }

  public get PJRHeaderNumber() {
    return this.pillarIdx + 1;
  }

  @computed
  private get header() {
    return this.pillar.header;
  }

  @computed.struct
  public get pjName() {
    return this.header.pjName;
  }

  @computed.struct
  public get pjCode() {
    return this.header.pjCode;
  }

  @computed.struct
  public get odCode() {
    return this.header.odCode;
  }

  @computed.struct
  public get cells() {
    const labels = this.header.labels;

    return this.day.cells.map((cell, idx) => ({
      PJRBodyCellTime: cell,
      PJRBodyCellLabel: labels[idx],
    }));
  }

  @computed.struct
  public get PJRFooterCellTimeTotal() {
    return this.pillar.total;
  }

  @computed.struct
  public get totalCells() {
    return this.pillar.totalCells.map(cell => ({
      PJRFooterCellTime: cell,
    }));
  }

  @action.bound
  public onDeletePillar() {
    this.pillar.remove();
  }

  @computed
  public get headerErrors() {
    return this.pillar.header.computed.errors;
  }

  @computed
  public get headerWarnings() {
    return this.pillar.header.computed.warnings;
  }
}
