import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type TimeSumKFProps = {
  model: TimeSumKFVM;
} & CellFormPrintAdaptable;

export interface TimeSumKFVM {
  timeSum: KintaiInputItemState<Minutes | undefined>;
}

export const TimeSumKF = observer((props: TimeSumKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.timeSum);

  return <HHMMField {...props} label="勤務累計時間" {...itemState} />;
});
