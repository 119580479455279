import { ApplicationsPageVM } from "./ApplicationsPage";
import { ApplicationsPageRibbonVM } from "./ApplicationsPageRibbon";
import { ApplicationsPageGridVM } from "./grid/ApplicationsPageGrid";

export class ApplicationsPageVMImpl implements ApplicationsPageVM, ApplicationsPageRibbonVM, ApplicationsPageGridVM {
  public get ribbonModel() {
    return this;
  }
  public get gridModel() {
    return this;
  }
}
