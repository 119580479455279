import { IModelType, types } from "mobx-state-tree";

import { notLeavingInNendo } from "../../../utils/leavedate";
import { NendoRank } from "../../ranks/NendoRank";
import { User } from "../../users/User";
import { prefixedIdType } from "../../utils";
import { NendoDept } from "../NendoDept";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "NendoInChargeUser_";

const model = types
  .model("NendoInChargeUser", {
    id: prefixedIdType(idPrefix),

    nendo: types.number,
    userId: types.string,

    rank: types.maybe(types.reference(NendoRank)),
    dept: types.maybe(types.reference(NendoDept)),
    leaveDate: types.maybe(types.Date),

    user: types.reference(User),
  })
  .views(self => {
    return {
      get userName() {
        return self.user.userName;
      },
      get rankCode() {
        return self.user.rankCode;
      },
      get depCode() {
        return self.user.depCode;
      },
      get rankName() {
        return (self.rank && self.rank.rankName) || "";
      },
      get depName() {
        return (self.dept && self.dept.name) || "";
      },
      get eligibleForAssignee() {
        return (
          // 管理職かつ、
          self.rank &&
          self.rank.isManager &&
          // 昨年以前に退職していないこと
          notLeavingInNendo(self.leaveDate, self.nendo)
        );
      },
    };
  })
  .actions(self => {
    return {};
  });

export const idNendoInChargeUser = (nendo: number, userId: string) => `${idPrefix}${nendo}_${userId}`;

export type NendoInChargeUserType = typeof NendoInChargeUser.Type;
export const NendoInChargeUser: NendoInChargeUserModelType = model;
type NendoInChargeUserInferredType = typeof model;
export interface NendoInChargeUserModelType extends NendoInChargeUserInferredType {}
