import styled from "@emotion/styled";
import React from "react";

const PlainHeaderLabel = styled.span`
  width: 100%;
  color: #333;
  background-color: #ccc;
  display: block;
  font-size: 80%;
  text-align: 100%;
  margin-bottom: 1px;
  padding-top: 1px;
  padding-left: 0.2ex;
  padding-right: 0.2ex;
`;

const hsh = (str: string) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const chr = str.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    hash = (hash << 5) - hash + chr;
    // tslint:disable-next-line:no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const GridHeaderLabel: React.FunctionComponent<{ label: string }> = props => {
  return (
    <PlainHeaderLabel
      style={{
        backgroundColor: `hsl(${Math.abs(hsh(props.label)) % 360}, 51.6%, 90%)`,
      }}>
      {props.children ? props.children : props.label}
    </PlainHeaderLabel>
  );
};
