import { observer } from "mobx-react";
import React from "react";

import { SelectField, SelectFieldOptionItem } from "../../../common/form/SelectField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcDeptNameCellVM {
  depCode: string | undefined;
  onChangeDepCode: (value: string | undefined) => void;
  depCodeHasNoChange: boolean;
  depOptions: SelectFieldOptionItem[];
}

@observer
export class AcDeptNameCell extends React.Component<{ model: AcDeptNameCellVM }> {
  public render() {
    const { model } = this.props;

    return (
      <SelectField
        mode="cell"
        value={model.depCode}
        onChange={model.onChangeDepCode}
        hasNoChange={model.depCodeHasNoChange}
        options={model.depOptions}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
