import { KintaiBunrui, RegularBunruiComb } from "@webkintai/bunrui";
import { applySnapshot, getParentOfType, types } from "mobx-state-tree";

import { KintaiDailyError } from "../KintaiDailyError";
import { MonthlyKintai } from "../MonthlyKintai";
import { RegularDailyKintai } from "./RegularDailyKintai";

export function regularTypeItemState(idx: number) {
  const name = `type${idx}`;
  const setterName = `setType${idx}`;
  return types.optional(
    types
      .model(name, {
        warnings: types.array(types.string),
        errors: types.array(types.string),
      })
      .views(self => {
        return {
          get root() {
            return getParentOfType(self, MonthlyKintai);
          },
          get daily() {
            return getParentOfType(self, RegularDailyKintai);
          },
          get input() {
            return getParentOfType(self, RegularDailyKintai).input;
          },
          get computed() {
            return getParentOfType(self, RegularDailyKintai).computed;
          },
          get origin() {
            return getParentOfType(self, RegularDailyKintai).origin;
          },
        };
      })
      .views(self => {
        return {
          get disabled() {
            if (idx === 1) {
              return true;
            }
            if (self.root.isRegularKintaiLocked) {
              return true;
            }
            if (self.daily.typesAreLocked) {
              return true;
            }
            return false;
          },
          get availableKintaiBunrui() {
            const comb = new RegularBunruiComb(self.root.availableRegularKintaiBunruiList, [
              self.input.type1,
              self.input.type2,
              self.input.type3,
              self.input.type4,
            ]);
            return comb.getAvailableBunruiList(idx);
          },
        };
      })
      .views(self => {
        return {
          get kintaiBunrui(): KintaiBunrui {
            return self.input[name];
          },
          get hasNoChange() {
            return self.disabled || this.hasEssentiallyNoChange;
          },
          get hasEssentiallyNoChange() {
            return self.origin[name] === self.input[name];
          },
        };
      })
      .actions(self => {
        const isMyError = (error: typeof KintaiDailyError.Type) =>
          (name === "type2" && error.hasField("types")) || error.hasField(name);
        const errorsOf = () => {
          return self.computed.errors
            .filter(isMyError)
            .filter(it => it.isError)
            .map(it => it.message);
        };
        const warningsOf = () => {
          return self.computed.errors
            .filter(isMyError)
            .filter(it => it.isWarning)
            .map(it => it.message);
        };
        return {
          onChange(value: KintaiBunrui) {
            self.input[setterName](value);
          },
          applyErrors() {
            const errors = errorsOf();
            const warnings = warningsOf();
            applySnapshot(self.errors, errors);
            applySnapshot(self.warnings, warnings);
          },
        };
      }),
    {},
  );
}
