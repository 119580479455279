import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { getNendoMonths } from "../../../../utils/calendar";
import { RenderedMedia } from "../../../common/export-table/ExportableTable";
import { HHMMField } from "../../../common/form/HHMMField";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkTable, WkTotalFooterTr } from "../../../common/plaintable/WkTables";
import { KintaiSumOrderCommonTotals } from "./KintaiSumOrderCommonTotals";

export type KintaiSumTableByOrderUserVM = {
  byOrderUserRows: KintaiSumTableByOrderUserRowVM[];
} & KintaiSumOrderCommonTotals;

export interface KintaiSumTableByOrderUserRowVM {
  id: string;
  odCode: string;
  pjCode: string;
  pjName: string;
  userId: string;
  userName: string;

  total: Minutes | undefined;

  months: KintaiSumTableByOrderUserRowMonthVM[];
}

export interface KintaiSumTableByOrderUserRowMonthVM {
  month: Date;
  total: Minutes | undefined;
  onNavigateToKintai: () => void;
}

// 4月〜3月の列挙が欲しいだけ
const monthSeries = getNendoMonths(2018).map(it => moment(it).format("MM月"));

@observer
export class KintaiSumTableByOrderUser extends React.Component<{
  renderedOn: RenderedMedia;
  displayHourAsDecimal: boolean;
  model: KintaiSumTableByOrderUserVM;
}> {
  public render() {
    const { model, renderedOn, displayHourAsDecimal } = this.props;

    if (model.byOrderUserRows.length === 0) {
      return <NoResultsSpread />;
    }

    return (
      <WkTable>
        <thead>
          <tr>
            <th>#</th>
            <th>OD-CD</th>
            <th>PJ-CD</th>
            <th>プロジェクト名</th>

            <th>社員番号</th>
            <th>氏名</th>

            {monthSeries.map(it => (
              <th key={it}>{it}</th>
            ))}

            <th>合計</th>
          </tr>
        </thead>
        <tbody>
          {model.byOrderUserRows.map((row, idx) => (
            <tr key={row.id}>
              <td>{idx + 1}</td>
              <td>{row.odCode}</td>
              <td>{row.pjCode}</td>
              <td>{row.pjName}</td>

              <td>{row.userId}</td>
              <td>{row.userName}</td>

              {monthSeries.map((it, mIdx) => {
                const uMonth = row.months[mIdx];
                return (
                  <td key={it}>
                    <a href="javascript: undefined" onClick={uMonth.onNavigateToKintai}>
                      <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={uMonth.total} />
                    </a>
                  </td>
                );
              })}

              <td>
                <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={row.total} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <WkTotalFooterTr>
            <td colSpan={6}>合計</td>

            {model.monthlyProjectOrderTotals.map((total, mIdx) => (
              <td key={mIdx}>
                <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={total.total} />
              </td>
            ))}

            <td>
              <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={model.projectOrderTotal} />
            </td>
          </WkTotalFooterTr>
        </tfoot>
      </WkTable>
    );
  }
}
