import styled from "@emotion/styled";
import React from "react";

const Frame = styled.div`
  flex: 1;

  overflow: auto;
  padding: 2em 5% 0 5%;
`;

const InsideFrame = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 20px;
  padding: 1em;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.05);
`;

export const ReadingsPane: React.FunctionComponent<{}> = props => (
  <Frame className="ApplyCommonBackground">
    <InsideFrame>{props.children}</InsideFrame>
  </Frame>
);
