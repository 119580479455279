import { IModelType, types } from "mobx-state-tree";

import { User } from "../../users/User";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.model("KintaiTimestamp", {
  user: types.reference(User),
  timestamp: types.Date,
});

export type KintaiTimestampType = typeof KintaiTimestamp.Type;
export const KintaiTimestamp: KintaiTimestampModelType = model;
type KintaiTimestampInferredType = typeof model;
export interface KintaiTimestampModelType extends KintaiTimestampInferredType {}
