import { observer } from "mobx-react";
import React from "react";

import { AppTemplate } from "../../../../../models/kintai/apps/DailyApplicationTemplates";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { SelectField } from "../../../form/SelectField";

export type AppPostTemplatesKFProps = {
  model: AppPostTemplatesKFVM;
} & CellFormPrintAdaptable;

export interface AppPostTemplatesKFVM {
  appTemplates: AppTemplate[];
  onSelectAppTemplate: (template: string | undefined) => void;
  appTemplateIsDisabled: boolean;
}

export const AppPostTemplatesKF = observer((props: AppPostTemplatesKFProps) => {
  const { model } = props;

  return (
    <SelectField
      {...props}
      label={"申請理由（雛形）"}
      value={""}
      disabled={model.appTemplateIsDisabled}
      onChange={model.onSelectAppTemplate}
      options={model.appTemplates.map(t => ({
        label: t.title,
        value: t.content,
      }))}
    />
  );
});
