import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";

export interface PJRFooterActualTimeKFProps {
  model: PJRFooterActualTimeKFVM;
}

export interface PJRFooterActualTimeKFVM {
  PJRFooterActualTime: Minutes | undefined;
}

export const PJRFooterActualTimeKF = observer((props: PJRFooterActualTimeKFProps) => {
  const { model } = props;

  return <HHMMField mode="print" value={model.PJRFooterActualTime} />;
});
