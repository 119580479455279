import { Button, Intent, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { ProjectInputStyle } from "../../../../../models/kintai/pjreport/ProjectInputStyle";
import { PJRBodyActualTimeKF, PJRBodyActualTimeKFVM } from "../../pjreport/fields/PJRBodyActualTimeKF";
import { PJRBodyCellTimeKF, PJRBodyCellTimeKFVM } from "../../pjreport/fields/PJRBodyCellTimeKF";
import { PJRBodyNotPopulatedTimeKF, PJRBodyNotPopulatedTimeKFVM } from "../../pjreport/fields/PJRBodyNotPopulatedTimeKF";
import { PJRFooterCellTimeKFVM } from "../../pjreport/fields/PJRFooterCellTimeKF";
import { PJRFooterCellTimeTotalKFVM } from "../../pjreport/fields/PJRFooterCellTimeTotalKF";
import { PJRHeaderJobNoKF } from "../../pjreport/fields/PJRHeaderJobNoKF";
import { PJRHeaderNumberKF, PJRHeaderNumberKFVM } from "../../pjreport/fields/PJRHeaderNumberKF";
import { PJRHeaderOdCodeKF, PJRHeaderOdCodeKFVM } from "../../pjreport/fields/PJRHeaderOdCodeKF";
import { PJRHeaderPjCodeKF, PJRHeaderPjCodeKFVM } from "../../pjreport/fields/PJRHeaderPjCodeKF";
import { PJRHeaderPjNameKF, PJRHeaderPjNameKFVM } from "../../pjreport/fields/PJRHeaderPjNameKF";
import { DailyKintaiFormLine2, DailyKintaiFormLine4 } from "../DailyKintaiFormPane";
import { JobNoEntry } from "./jobno/JobNoEntry";
import { JobNoSelectWindow } from "./jobno/JobNoSelectWindow";

export type RegularDailyKintaiFormPJReportVM = {
  onAddPillar: () => void;
  onCopyPillarsFromLastMonth: () => void;
  jobNoDefs: JobNoEntry[];
  projectInputStyle: ProjectInputStyle;
  dailyPillars: DailyPillarVM[];
  isLocked: boolean;
} & DailyTotalPillarVM;

export type DailyTotalPillarVM = PJRBodyActualTimeKFVM & PJRBodyNotPopulatedTimeKFVM;

export type DailyPillarVM = {
  id: string;
  onDeletePillar: () => void;
  cells: PJRBodyCellTimeKFVM[];
  totalCells: PJRFooterCellTimeKFVM[];
} & PJRHeaderPjNameKFVM &
  PJRHeaderPjCodeKFVM &
  PJRHeaderOdCodeKFVM &
  PJRHeaderNumberKFVM &
  PJRFooterCellTimeTotalKFVM;

const AddPillarButton: typeof Button = styled(Button)`
  margin-top: 1em;
  width: 100%;
  height: 4em;
` as any;

const Section = styled.div`
  border-top: 1px solid #ccc;
  padding-bottom: 1ex;

  h3 {
    background-color: #339933;
    color: #fff;
    font-size: 11pt;
    margin: 0;
    padding: 0;
    padding-left: 1ex;
  }
`;

const SectionBody = styled.div`
  padding-top: 0.5ex;
  padding-left: 0px;
  padding-right: 0px;
`;

const PJHeading = styled.h3`
  display: flex;
  flex-direction: row;
`;

const JobNoSelectWindowFrame = styled.div`
  display: flex;
  width: 320px;
  max-height: 500px;
`;

@observer
export class RegularDailyKintaiFormPJReport extends React.Component<{ model: RegularDailyKintaiFormPJReportVM }> {
  public render() {
    const { props } = this;
    const { model } = props;
    return (
      <>
        <DailyKintaiFormLine4>
          <PJRBodyActualTimeKF mode="form" model={model} />
          <PJRBodyNotPopulatedTimeKF mode="form" model={model} />
        </DailyKintaiFormLine4>

        {model.dailyPillars.map((pillar, idx) => (
          <Section key={pillar.id}>
            <PJHeading>
              <PJRHeaderNumberKF model={pillar} />
              {"　"}
              <PJRHeaderPjNameKF mode="form" model={pillar} />
            </PJHeading>

            <SectionBody>
              <DailyKintaiFormLine2>
                {model.projectInputStyle === "pjshushi" && (
                  <>
                    <PJRHeaderPjCodeKF mode="form" model={pillar} />
                    <PJRHeaderOdCodeKF mode="form" model={pillar} />
                  </>
                )}
                {model.projectInputStyle === "zac" && (
                  <>
                    <PJRHeaderJobNoKF mode="form" model={pillar} />
                    {!model.isLocked && (
                      <div>
                        <Popover
                          content={
                            <JobNoSelectWindowFrame>
                              <JobNoSelectWindow jobNoDefs={model.jobNoDefs} onChooseJobNo={pillar.odCode.onChange} />
                            </JobNoSelectWindowFrame>
                          }
                          boundary="window"
                          disabled={model.isLocked}
                          canEscapeKeyClose={true}
                          autoFocus={false}>
                          <Button
                            style={{ marginTop: 13, height: 24 }}
                            small={true}
                            icon={"search"}
                            text="ジョブNo. 候補"
                          />
                        </Popover>
                      </div>
                    )}
                  </>
                )}
              </DailyKintaiFormLine2>
              <DailyKintaiFormLine4>
                {pillar.cells.map((cell, i) => (
                  <PJRBodyCellTimeKF key={i} mode="form" model={cell} />
                ))}
              </DailyKintaiFormLine4>
              {!model.isLocked && (
                <div style={{ textAlign: "right" }}>
                  <Button intent={Intent.DANGER} small={true} icon={"trash"} onClick={pillar.onDeletePillar} />
                </div>
              )}
            </SectionBody>
          </Section>
        ))}

        {!model.isLocked && (
          <>
            <AddPillarButton onClick={model.onAddPillar} icon="add" text="プロジェクトを追加する" />
            <AddPillarButton onClick={model.onCopyPillarsFromLastMonth} icon="duplicate" text="前月からコピーする" />
          </>
        )}
      </>
    );
  }
}
