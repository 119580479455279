import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { AccountsPageModel } from "../../../../models/accounts/AccountsPageModel";
import { InputAccount } from "../../../../models/accounts/input/InputAccount";
import { Roles, RolesSymbol } from "../../../../models/roles/Roles";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { AccountsPageGridRowVM } from "./AccountsPageGrid";

export class AccountsPageGridRowVMImpl implements AccountsPageGridRowVM {
  @lazyInject(RolesSymbol)
  private roles: typeof Roles.Type;

  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  constructor(public readonly model: typeof AccountsPageModel.Type, public readonly row: typeof InputAccount.Type) {}

  @computed
  public get hasNoChange() {
    return this.row.hasNoChange;
  }

  @computed
  public get userId() {
    return this.row.userId;
  }

  @action.bound
  public onNavigateToKintai() {
    this.appRouter.navigate(paths.kintai.ofUser(this.userId).index());
  }

  @computed
  public get userName() {
    return this.row.value.userName;
  }

  @action.bound
  public onChangeUserName(value: string) {
    this.row.value.setUserName(value);
  }

  @computed
  public get depCode() {
    return this.row.value.depCode;
  }

  @computed
  public get depOptions() {
    return [
      { label: "", value: undefined },
      ...this.model.depts!.leafDepts.map(dept => ({
        label: dept.name,
        value: dept.depCode,
      })),
    ];
  }

  @computed
  public get rankCode() {
    return this.row.value.rankCode;
  }

  @computed
  public get rankOptions() {
    return [
      { label: "", value: undefined },
      ...this.model.ranks!.ranksList.map(rank => ({
        label: rank.rankName,
        value: rank.rankCode,
      })),
    ];
  }

  @computed
  public get mailAddr() {
    return this.row.value.mailAddress;
  }

  @computed
  public get absenceText() {
    return this.row.value.absenceText;
  }

  @computed
  public get leaveDate() {
    return this.row.value.leaveDate;
  }

  @computed
  public get roleValues() {
    return Array.from(this.roles.roles.values(), role => ({
      roleCode: role.roleCode,
      checked: this.row.value.roles.some(r => role.roleCode === r),
      onChange: (value: boolean) => {
        this.row.value.setRole(role.roleCode, value);
      },
    }));
  }

  @action.bound
  public onChangeDepCode(value: string | undefined) {
    this.row.value.setDepCode(value);
  }

  @action.bound
  public onChangeRankCode(value: string | undefined) {
    this.row.value.setRankCode(value);
  }

  @action.bound
  public onChangeMailAddr(value: string) {
    this.row.value.setMailAddr(value);
  }

  @action.bound
  public onChangeLeaveDate(value: Date | undefined) {
    this.row.value.setLeaveDate(value);
  }

  @action.bound
  public onOpenPasswordInitDialog() {
    this.model.setActivePasswordChangeAccount(this.row);
  }

  @action.bound
  public onOpenAbsenceDialog() {
    this.model.setActiveAbsenceAccount(this.row);
  }

  @computed
  public get passwordInitDisabled() {
    return this.row.passwordInitDisabled;
  }

  @computed
  public get userNameHasNoChange() {
    return this.row.userNameHasNoChange;
  }
  @computed
  public get depCodeHasNoChange() {
    return this.row.depCodeHasNoChange;
  }
  @computed
  public get rankCodeHasNoChange() {
    return this.row.rankCodeHasNoChange;
  }
  @computed
  public get mailAddrHasNoChange() {
    return this.row.mailAddressHasNoChange;
  }
  @computed
  public get leaveDateHasNoChange() {
    return this.row.leaveDateHasNoChange;
  }
  @computed
  public get absenceHasNoChange() {
    return this.row.absenceHasNoChange;
  }

  @computed
  public get errors() {
    return this.row.value.errors;
  }

  @computed
  public get rolesHasNoChange() {
    return this.row.rolesHasNoChange;
  }
}
