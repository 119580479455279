import { flatten, sortBy, uniq } from "lodash-es";
import { applySnapshot, detach, Instance, types } from "mobx-state-tree";
import moment from "moment";

import { everyWithNext } from "../../../utils/everyWithNext";
import { InputAccountAbsence, InputAccountAbsenceInstance } from "./InputAccountAbsence";

export const model = types.optional(
  types
    .model("InputAccountValue", {
      userName: types.optional(types.string, ""),
      depCode: types.maybe(types.string),
      rankCode: types.maybe(types.string),
      mailAddress: types.optional(types.string, ""),
      leaveDate: types.maybe(types.Date),
      roles: types.array(types.string),
      absences: types.array(InputAccountAbsence),
    })
    .views(self => {
      return {
        get latestAbsence() {
          const sorted = sortBy(self.absences, it => (it.from ? -it.from.getTime() : 0));
          return sorted.length > 0 ? sorted[0] : undefined;
        },

        get absenceText() {
          if (!this.latestAbsence) {
            return "(無)";
          }

          const format = (d: Date | undefined) => (d ? moment(d).format("YY/MM/DD") : "(未設定)");

          return `${self.absences.length}件: ${format(this.latestAbsence.from)}〜${format(this.latestAbsence.to)}${
            self.absences.length > 1 ? "等" : ""
          }`;
        },
        get searchedWords() {
          return [self.userName, self.mailAddress];
        },
        get absenceErrors() {
          const datesShouldBeMonotonicIncrease = sortBy(
            self.absences.filter(it => it.from && it.to),
            it => it.from!.getTime(),
          ).map(it => [it.from!, it.to!] as [Date, Date]);

          if (!everyWithNext(datesShouldBeMonotonicIncrease, (l, r) => l[1].getTime() < r[0].getTime())) {
            return ["日付は重複不可です。"];
          }
          return [];
        },
        get thisErrors() {
          return [...this.absenceErrors];
        },
        get errors() {
          return [...this.thisErrors, ...flatten(self.absences.map(it => it.errors))];
        },
      };
    })
    .actions(self => {
      return {
        addAbsence() {
          self.absences.push(InputAccountAbsence.create());
        },
        setUserName(value: string) {
          self.userName = value;
        },
        setDepCode(value: string | undefined) {
          self.depCode = value;
        },
        setRankCode(value: string | undefined) {
          self.rankCode = value;
        },
        setMailAddr(value: string) {
          self.mailAddress = value;
        },
        setLeaveDate(value: Date | undefined) {
          self.leaveDate = value;
        },
        setRole(roleCode: string, trueIfOn: boolean) {
          const newValues = trueIfOn ? uniq([...self.roles, roleCode]) : self.roles.filter(role => role !== roleCode);
          applySnapshot(self.roles, newValues);
        },
        removeAbsence(value: InputAccountAbsenceInstance) {
          detach(value);
        },
      };
    }),
  {},
);

export const InputAccountValue: InputAccountValueModelType = model;
type InputAccountValue_InferredType = typeof model;
export interface InputAccountValueModelType extends InputAccountValue_InferredType {}
type InputAccountValueIIf = Instance<typeof InputAccountValue>;
export interface InputAccountValueInstance extends InputAccountValueIIf {}
