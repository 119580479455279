import { observer } from "mobx-react";
import React from "react";

import { DateField } from "../../../common/form/DateField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcRetireDayCellVM {
  leaveDate: Date | undefined;
  onChangeLeaveDate: (value: Date | undefined) => void;
  leaveDateHasNoChange: boolean;
}

@observer
export class AcRetireDayCell extends React.Component<{ model: AcRetireDayCellVM }> {
  public render() {
    const { model } = this.props;

    return (
      <DateField
        mode="cell"
        cellPosition={NULL_CELL_POSITION}
        hasNoChange={model.leaveDateHasNoChange}
        onChange={model.onChangeLeaveDate}
        value={model.leaveDate}
      />
    );
  }
}
