import { Configuration, FetchAPI } from "@webkintai/api";
import { injectable } from "inversify";

export type ApiClass<T> = new (configuration?: Configuration, basePath?: string, fetch?: FetchAPI) => T;

@injectable()
export abstract class ApiFactory {
  public abstract generateLoginFreeApi<T>(clazz: ApiClass<T>): T;
  public abstract generateLoginAssumingApi<T>(clazz: ApiClass<T>): T;
  public abstract guardFailures<T>(requestingClause: () => Promise<T>): Promise<T>;
}
