const types = ["abcdefghijklmnopqrstuvwxyz", "ABCDEFHHIJKLMNOPQRSTUVWXYZ", "0123456789", "!@$[]Z&%#/_-"];

const length = 12;
export const passwordPolicyText = `${length}文字以上で、必ず英小文字、英大文字、数字、記号をそれぞれ1文字以上含むこと。`;

export const passwordPlaceholder = `半角英数字記号のみ`;

export const generatePasswordString = () => {
  let candidate = "";

  while (!checkIfSatisifiesCompanyConformance(candidate)) {
    candidate = "";
    for (let i = 0; i < 12; i++) {
      const type = types[Math.floor(Math.random() * types.length)];
      candidate += type.charAt(Math.floor(Math.random() * type.length));
    }
  }

  return candidate;
};

const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'`~|=\-^\[\]\\*+@_?<>/_\.,]).{12,}$/;

export const checkIfSatisifiesCompanyConformance = (password: string) => password.match(pattern) !== null;
