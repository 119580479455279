import { range } from "lodash-es";

import { getNendo } from "../utils/calendar";
import { DataType as EditedDataType, TimeProvider } from "./TimeProvider";

export class TimeProviderImpl extends TimeProvider {
  constructor() {
    super();
  }

  public get currentTime() {
    return new Date();
  }

  public get currentNendo() {
    return getNendo(this.currentTime);
  }

  public get referenceTargetNendoList() {
    return range(2012, this.currentNendo + 2);
  }

  public allowEditMasterData(at: Date, dataType: EditedDataType = "MasterData") {
    const nendo = getNendo(at);
    if (dataType === "InterviewList") {
      return this.currentNendo - 1 <= nendo && nendo <= this.currentNendo + 1;
    }
    return this.currentNendo <= nendo && nendo <= this.currentNendo + 1;
  }
}
