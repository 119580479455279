import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { ButtonField } from "../../../common/form/ButtonField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcRoleHeaderVM {
  roleCode: string;
  roleName: string;
  filtered: boolean;
  onToggleRoleFilter: () => void;
}

@observer
export class AcRoleHeader extends React.Component<{ model: AcRoleHeaderVM }> {
  public render() {
    const { model } = this.props;
    return (
      <AcRoleHeaderButton mode="cell" cellPosition={NULL_CELL_POSITION} onClick={model.onToggleRoleFilter}>
        ロール
        <br />
        {model.roleName}
        <br />
        {model.filtered ? "(絞込)" : ""}
      </AcRoleHeaderButton>
    );
  }
}

const AcRoleHeaderButton = styled(ButtonField)`
  padding: 0;
`;
