import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../form/HourBasedDaysCountField";

export type MTotalWorkplaceSonotaDaysKFProps = {
  model: MTotalWorkplaceSonotaDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalWorkplaceSonotaDaysKFVM {
  mTotalWorkplaceSonotaDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalWorkplaceSonotaDaysKF = observer((props: MTotalWorkplaceSonotaDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalWorkplaceSonotaDays;

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label=""
      {...itemState}
      value={itemState.value}
      onChange={undefined}
    />
  );
});
