import React from "react";

import { GridHeaderLabel } from "../../../common/table/GridHeaderLabel";

export const HeaderLabelBulkAuth = () => <GridHeaderLabel label="一括承認" />;
export const HeaderLabelWarningCount = () => <GridHeaderLabel label="件数" />;
export const HeaderLabelKintaiSinsei = () => <GridHeaderLabel label="勤怠申請" />;
export const HeaderLabelStamp = () => <GridHeaderLabel label="印" />;
export const HeaderLabelYasumi = () => <GridHeaderLabel label="（休）" />;
export const HeaderLabelTokukin = () => <GridHeaderLabel label="特勤" />;
export const HeaderLabelZangyou = () => <GridHeaderLabel label="残業" />;
export const HeaderLabelOver60 = () => <GridHeaderLabel label="60H超" />;
export const HeaderLabelLawOver = () => <GridHeaderLabel label="健康管理" />;
export const HeaderLabelJikangai = () => <GridHeaderLabel label="時間外" />;

export const HeaderLabelWorkplaceTotal = () => <GridHeaderLabel label="勤務場所" />;
