import { UserNendoDashboardData } from "../../../../../../schema/build/commonjs/main/typescript";
import { PrivateDDTableData, PrivateDDUserValueRow } from "../PrivateDDTableData";
import { sortByValue } from "./sortByValue";

export const calcPrvPaidLeaves = (value: UserNendoDashboardData): PrivateDDTableData<PrivateDDUserValueRow> => {
  const target = value.nendoData.lowestPlainPaidDays;
  const warningCount = target.filter(it => it.value.hours / 8 < 5).length;
  return {
    name: "年度内有休5日未満",
    warned: warningCount > 0,
    warningCount,
    unit: "日",
    rows: sortByValue(
      target.map(it => ({
        user: it.userAttributes,
        warned: it.value.hours / 8 < 5,
        value: it.value.hours / 8,
      })),
      "asc",
    ),
  };
};
