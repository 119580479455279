import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

export const SubMenuButton: React.FunctionComponent<
  JSX.LibraryManagedAttributes<typeof Button, Button["props"]>
> = props => <Button small={true} minimal={true} {...props} />;

export const LeftBorderedSubMenuButton = styled(SubMenuButton)`
  &:before {
    content: "";
    height: 12pt;
    border-left: 1px solid #ccc;
    width: 1px;
  }
`;
