import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalAllNightOffDaysKFProps = {
  model: MTotalAllNightOffDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalAllNightOffDaysKFVM {
  mTotalAllNightOffDays: KintaiInputItemState<Days>;
}

export const MTotalAllNightOffDaysKF = observer((props: MTotalAllNightOffDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalAllNightOffDays;

  return (
    <DayCountField
      {...props}
      label="mTotalAllNightOffDaysKF"
      {...itemState}
      value={itemState.value.days}
      onChange={undefined}
    />
  );
});
