import { LoginControllerApi, OidcControllerApi } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { LoginApi } from "./LoginApi";

export class LoginApiImpl extends LoginApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getOidcProviders() {
    return this.apiFactory.guardFailures(() => this.apiFactory.generateLoginFreeApi(OidcControllerApi).getProviders());
  }

  public doLogin(userId: string, password: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginFreeApi(LoginControllerApi).getLoginToken({
        userId,
        password,
      }),
    );
  }

  public getOidcLoginToken() {
    // doLoginのOIDCバージョン
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginFreeApi(OidcControllerApi).getOidcLoginToken({
        // 自分のCookie (JSからは確認不可能) に基づいてログイントークンが取得される
        credentials: "include",
      }),
    );
  }

  public doLogout() {
    // エラー時もリトライなど行わない (guardFailuresを使わない)
    return this.apiFactory.generateLoginAssumingApi(LoginControllerApi).logout();
  }
}
