import { KintaiBunrui, NullKintai, 平日 } from "@webkintai/bunrui";
import { Minutes } from "@webkintai/core";
import { flatMap } from "lodash-es";
import { getParentOfType, types } from "mobx-state-tree";
import moment from "moment";

import { KintaiMonthlyError } from "../KintaiMonthlyError";
import { MonthlyKintai } from "../MonthlyKintai";
import { RegularDailyKintai } from "./RegularDailyKintai";
import { RegularMonthlyTotal } from "./RegularMonthlyTotal";

const model = types
  .model("RegularKintai", {
    userId: types.string,
    month: types.Date,
    total: RegularMonthlyTotal,
    monthlyErrors: types.array(KintaiMonthlyError),
    days: types.array(RegularDailyKintai),
  })
  .views(self => {
    function pjReport() {
      return getParentOfType(self, MonthlyKintai).pjReport!;
    }

    return {
      get errors() {
        return [
          ...self.monthlyErrors.filter(it => it.isError).map(it => `（月報エラー）${it.message}`),
          ...flatMap(self.days, it => it.errors.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
          ...pjReport().totalErrors,
        ];
      },
      get warnings() {
        return [
          ...self.monthlyErrors.filter(it => it.isWarning).map(it => `（月報警告）${it.message}`),
          ...flatMap(self.days, it => it.warnings.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
          ...pjReport().totalWarnings,
        ];
      },
      get infos() {
        return [
          ...self.monthlyErrors.filter(it => it.isInfo).map(it => `（月報情報）${it.message}`),
          ...flatMap(self.days, it => it.infos.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
          ...pjReport().totalInfos,
        ];
      },
      get hasNoChange() {
        return self.days.map(it => it.hasNoChange).reduce((l, r) => l && r, true);
      },
      get hasAnyCkrpRecord() {
        return self.days.some(it => it.hasCkrpRecord);
      },
      getAt(date: Date) {
        return self.days[date.getDate() - 1];
      },
    };
  })
  .actions(self => {
    return {
      reset() {
        self.days.forEach(it => it.reset());
      },
      setDefaultTimes(arrival: Minutes | undefined, leave: Minutes | undefined) {
        const now = new Date().getTime();
        self.days
          .filter(day => !day.typesAreLocked)
          .filter(day => day.date.getTime() <= now)
          .filter(day => day.input.type1 === 平日)
          .filter(day => day.input.type2 === NullKintai)
          .forEach(day => {
            day.input.setDefaultArrivalLeave(arrival, leave);
          });
      },

      setAllBunrui2(kintaiBunrui: KintaiBunrui) {
        self.days
          .filter(day => !day.typesAreLocked)
          .filter(day => day.input.type1 === 平日)
          .filter(day => day.input.type2 === NullKintai)
          .forEach(day => {
            day.input.setType2(kintaiBunrui);
          });
      },

      clearAllBunrui() {
        self.days
          .filter(day => !day.typesAreLocked)
          .forEach(day => {
            day.input.setType4(NullKintai);
            day.input.setType3(NullKintai);
            day.input.setType2(NullKintai);
          });
      },

      setAllDaysDoorEnter() {
        self.days.forEach(day => day.applyDoorEnter(true));
      },
      setAllDaysDoorLeave() {
        self.days.forEach(day => day.applyDoorLeave(true));
      },
    };
  });
export const RegularKintai: RegularKintaiModelType = model;
type RegularKintaiInferredType = typeof model;
export interface RegularKintaiModelType extends RegularKintaiInferredType {}
