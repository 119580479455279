import { action, computed } from "mobx";

import { KintaiApplicationDay } from "../../../../../models/kintai/apps/KintaiApplicationDay";
import { KintaiReportPageModel } from "../../../../../models/kintai/KintaiReportPageModel";
import { SPortDailyFormTabId } from "../../../../../models/kintai/sport/SPortDailyFormTabId";
import { SPortDailyKintai } from "../../../../../models/kintai/sport/SPortDailyKintai";
import { DailyApplicationVMImpl } from "../../applications/dailyform/DailyApplicationVMImpl";
import { SPortDailyVM } from "./SPortDailyVM";

export class SPortDailyVMImpl implements SPortDailyVM {
  constructor(
    private readonly pageModel: typeof KintaiReportPageModel.Type,
    private readonly appModel: typeof KintaiApplicationDay.Type,
    private readonly model: typeof SPortDailyKintai.Type,
  ) {}

  @computed
  public get date() {
    return this.model.date;
  }

  @computed
  public get errors() {
    return this.model.errors;
  }

  @computed
  public get warnings() {
    return this.model.warnings;
  }

  @computed
  public get infos() {
    return this.model.infos;
  }

  @computed
  public get type1() {
    return this.model.type1.kintaiBunrui;
  }

  @action.bound
  public onNavigateToDate(value: Date) {
    this.pageModel.setDate(value);
  }

  @computed.struct
  public get input() {
    return this.model.input;
  }

  @computed.struct
  public get arrival() {
    return this.model.arrival;
  }

  @computed.struct
  public get leave() {
    return this.model.leave;
  }

  @computed.struct
  public get types() {
    return [this.model.type1, this.model.type2, this.model.type3];
  }

  @computed.struct
  public get transDay() {
    return this.model.transDay;
  }

  @computed.struct
  public get hourlyLeave() {
    return this.model.hourlyLeave;
  }

  @computed.struct
  public get hourlyNursingcare() {
    return this.model.hourlyNursingcare;
  }

  @computed.struct
  public get hourlyFamilycare() {
    return this.model.hourlyFamilycare;
  }

  @computed.struct
  public get rest() {
    return this.model.rest;
  }

  @computed.struct
  public get actual() {
    return this.model.actual;
  }

  @computed.struct
  public get midnight() {
    return this.model.midnight;
  }

  @computed.struct
  public get immunity() {
    return this.model.immunity;
  }

  @computed.struct
  public get timeSum() {
    return this.model.growingTime;
  }

  @computed.struct
  public get missed() {
    return this.model.missed;
  }

  @computed.struct
  public get overDiff() {
    return this.model.overDiff;
  }

  @computed.struct
  public get overRestNormal() {
    return this.model.overRestNormal;
  }
  @computed.struct
  public get overRestMidnight() {
    return this.model.overRestMidnight;
  }

  @computed.struct
  public get diff() {
    return this.model.diff;
  }

  @computed.struct
  public get overHoliday() {
    return this.model.overHoliday;
  }

  @computed.struct
  public get overHolidayMidnight() {
    return this.model.overHolidayMidnight;
  }

  @computed.struct
  public get growingOver() {
    return this.model.growingOver;
  }

  @computed.struct
  public get overWeekday() {
    return this.model.overWeekday;
  }

  @computed.struct
  public get overWeekdayMidnight() {
    return this.model.overWeekdayMidnight;
  }

  @computed.struct
  public get offCount() {
    return this.model.offCount;
  }

  @computed
  public get remarks() {
    return this.model.remarks;
  }

  @computed.struct
  public get sPortDailyFormTabId() {
    return this.pageModel.selectedSPortKintaiTabId;
  }

  @action.bound
  public onChangeSPortDailyFormTabId(value: typeof SPortDailyFormTabId.Type) {
    this.pageModel.setSelectedSPortKintaiTabId(value);
  }

  @computed
  public get dailyApp() {
    return new DailyApplicationVMImpl(this.pageModel, this.appModel);
  }

  @action.bound
  public onSwipe(direction: number) {
    this.pageModel.movePosition({ x: 0, y: direction });
  }
}
