import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLawBasisKFProps = {
  model: MTotalLawBasisKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLawBasisKFVM {
  mTotalLawBasis: KintaiInputItemState<Minutes>;
}

export const MTotalLawBasisKF = observer((props: MTotalLawBasisKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLawBasis;

  return <HHMMField {...props} label="mTotalLawBasisKF" {...itemState} onChange={undefined} />;
});
