import { observer } from "mobx-react";
import React, { useCallback } from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { EnterLeaveTimeField } from "../common/EnterLeaveTimeField";

export type DoorEnterKFProps = {
  model: DoorEnterKFVM;
} & CellFormPrintAdaptable;

export interface DoorEnterKFVM {
  date: Date;
  doorEnterTime: Date | undefined;
  onApplyDoorEnter: () => void;
}

export const DoorEnterKF = observer((props: DoorEnterKFProps) => {
  const { model } = props;
  const { date, doorEnterTime, onApplyDoorEnter } = model;

  const onClick = useCallback(() => {
    if (onApplyDoorEnter && doorEnterTime) {
      onApplyDoorEnter();
    }
  }, [onApplyDoorEnter, doorEnterTime]);

  return <EnterLeaveTimeField {...props} {...{ date, onClick }} enterLeaveTime={doorEnterTime} label="入室時間" />;
});
