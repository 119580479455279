import { computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { Login, LoginSymbol } from "../../../models/login/Login";
import {
  ProfileInterviewPageModel,
  ProfileInterviewPageModelSymbol,
} from "../../../models/profile/ProfileInterviewPageModel";
import { ProfilePageModel, ProfilePageModelSymbol } from "../../../models/profile/ProfilePageModel";
import { paths } from "../../../routing/paths";
import { AppRouter } from "../../../services/AppRouter";
import { ProfileSubMenuVM } from "./ProfileSubMenu";

export class ProfileSubMenuVMImpl implements ProfileSubMenuVM {
  @lazyInject(ProfilePageModelSymbol)
  private profile: typeof ProfilePageModel.Type;

  @lazyInject(ProfileInterviewPageModelSymbol)
  private profileInterviewPageModel: typeof ProfileInterviewPageModel.Type;

  @lazyInject(LoginSymbol)
  private login: typeof Login.Type;

  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  public get pageMode() {
    return this.profile.pageMode;
  }

  public onNavigateToInbox = () => {
    this.appRouter.navigate(paths.profile.inbox.index());
  };

  public onNavigateToSettings = () => {
    this.appRouter.navigate(paths.profile.settings.index());
  };

  @computed
  get showPhysicianInterview() {
    return this.pageMode === "interview" || this.profileInterviewPageModel.hasContent;
  }

  public onNavigateToPhysicianInterview = () => {
    this.appRouter.navigate(paths.profile.interview.index());
  };

  public onLogout = () => {
    this.login.logout();
  };
}
