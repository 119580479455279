import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../../models/statistics/usePrivateDDModel";
import { PrvUserDatesTable } from "../../../table/PrvUserDatesTable";
import { PrvUserTableWarningCounter } from "../../../table/PrvUserTableCounter";

export const PrvKintaiNotAppliedTableContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserDatesTable atMonth={model.date} data={model.nendo_kintaiAuthNotApplied} />;
});

export const PrvKintaiNotAppliedTableCounterContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserTableWarningCounter title="未申請の件数" data={model.nendo_kintaiAuthNotApplied} />;
});
