import { observer } from "mobx-react";
import React from "react";

import { GridSize } from "../../../common/table/GridSize";
import { GridTable, x } from "../../../common/table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { AcAbsencesCell, AcAbsencesCellVM } from "./AcAbsencesCell";
import { AcChangedCell, AcChangedCellVM } from "./AcChangedCell";
import { AcDeptNameCell, AcDeptNameCellVM } from "./AcDeptNameCell";
import { AcMailAddrCell, AcMailAddrCellVM } from "./AcMailAddrCell";
import { AcPasswordInitCell, AcPasswordInitCellVM } from "./AcPasswordInitCell";
import { AcRankNameCell, AcRankNameCellVM } from "./AcRankNameCell";
import { AcRetireDayCell, AcRetireDayCellVM } from "./AcRetireDayCell";
import { AcRoleCell, AcRoleCellVM } from "./AcRoleCell";
import { AcRoleHeader } from "./AcRoleHeader";
import { AcUserIdCell, AcUserIdCellVM } from "./AcUserIdCell";
import { AcUserNameCell, AcUserNameCellVM } from "./AcUserNameCell";

export interface AccountsPageGridVM {
  accountRows: AccountsPageGridRowVM[];
  roleColumns: RoleColumn[];
}

export interface RoleColumn {
  roleName: string;
  roleCode: string;
  filtered: boolean;
  onToggleRoleFilter: () => void;
}

export type AccountsPageGridRowVM = AcChangedCellVM &
  AcUserIdCellVM &
  AcUserNameCellVM &
  AcAbsencesCellVM &
  AcDeptNameCellVM &
  AcMailAddrCellVM &
  AcRankNameCellVM &
  AcRetireDayCellVM &
  AcRoleCellVM &
  AcPasswordInitCellVM;

@observer
export class AccountsPageGrid extends React.Component<{ model: AccountsPageGridVM }> {
  public render() {
    const { accountRows: rows } = this.props.model;
    return (
      <GridTable
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowIdGenerator={this.rowIdGenerator}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { accountRows: rows } = this.props.model;
    const row = rows[rowIndex - 1];
    return row.userId;
  };

  private get headerColumns(): TableColumnDef[] {
    const { accountRows: rows } = this.props.model;
    return [
      {
        id: "変更",
        width: GridSize.changesHereField,
        renderHeader: () => "変更",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcChangedCell model={row} />;
        },
      },
      {
        id: "社員番号",
        width: GridSize.userIdField,
        renderHeader: () => "社員番号",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcUserIdCell model={row} />;
        },
      },
    ];
  }

  private get bodyColumns(): TableColumnDef[] {
    const { accountRows: rows } = this.props.model;
    return [
      {
        id: "氏名",
        width: GridSize.userNameField,
        renderHeader: () => "氏名",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcUserNameCell model={row} />;
        },
      },
      {
        id: "所属",
        width: GridSize.deptNameField,
        renderHeader: () => "所属",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcDeptNameCell model={row} />;
        },
      },
      {
        id: "ランク",
        width: GridSize.rankNameField,
        renderHeader: () => "ランク",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcRankNameCell model={row} />;
        },
      },
      {
        id: "メールアドレス",
        width: GridSize.mailAddressField,
        renderHeader: () => "メールアドレス",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcMailAddrCell model={row} />;
        },
      },
      {
        id: "パスワード初期化",
        width: x("XXXXXXXX"),
        renderHeader: () => "パスワード初期化",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcPasswordInitCell model={row} />;
        },
      },
      {
        id: "休業設定",
        width: x("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"),
        renderHeader: () => "休業設定",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcAbsencesCell model={row} />;
        },
      },
      {
        id: "退職日",
        width: GridSize.yyyyMMDDField,
        renderHeader: () => "退職日",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <AcRetireDayCell model={row} />;
        },
      },
      ...this.props.model.roleColumns.map(
        roleCol =>
          ({
            id: roleCol.roleCode,
            width: x("XXXXXXXX"),
            renderHeader: () => <AcRoleHeader model={roleCol} />,
            renderBody: ({ rowIndex }) => {
              const row = rows[rowIndex - 1];
              return <AcRoleCell model={row} roleCol={roleCol} />;
            },
          } as TableColumnDef),
      ),
    ];
  }
}
