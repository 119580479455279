import { LoginTokenStore } from "./LoginTokenStore";

export const localStorageKey = "webkintaiv2_login_token";

export class LoginTokenStoreImpl extends LoginTokenStore {
  private rememberLogin: boolean = false;

  constructor() {
    super();
  }

  public setRememberLogin(remember: boolean) {
    this.rememberLogin = remember;
  }

  public save(token: string): void {
    if (!this.rememberLogin) {
      sessionStorage.setItem(localStorageKey, token);
      return;
    }

    localStorage.setItem(localStorageKey, token);
  }

  public load(): string | null {
    return sessionStorage.getItem(localStorageKey) || localStorage.getItem(localStorageKey);
  }

  public discard(): void {
    sessionStorage.removeItem(localStorageKey);
    localStorage.removeItem(localStorageKey);
  }
}
