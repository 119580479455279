import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { lazyInject } from "../../../didecorators";
import { Roles, RolesSymbol } from "../../../models/roles/Roles";
import { CellFormAdaptable } from "./CellFormAdaptable";
import { SelectField, SelectFieldOptionItem } from "./SelectField";

export type RoleTypeSelectProps = {
  value: string | undefined;
  disabled?: boolean;
  onChange: (value: string | undefined) => void;
} & CellFormAdaptable;

@observer
export class RoleTypeSelect extends React.Component<RoleTypeSelectProps> {
  @lazyInject(RolesSymbol)
  private roles: typeof Roles.Type;

  public render() {
    return (
      <SelectField
        {...this.props}
        options={this.options}
        onChange={this.props.onChange}
        value={this.props.value}
        disabled={this.props.disabled}
      />
    );
  }

  @computed
  private get options() {
    const result: SelectFieldOptionItem[] = [
      {
        label: "",
        value: undefined,
      },
    ];
    [...this.roles.roles.values()].map(role =>
      result.push({
        label: role.roleName,
        value: role.roleCode,
      }),
    );
    return result;
  }
}
