import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

import { paths } from "../../../../routing/paths";
import { WkLink } from "../../../common/link/WkLink";
import { mediaQuerySmall } from "../../../common/responsive/Responsive";
import { DashboardWidgetLastUpdate } from "../widget/lastupdate/DashboardWidgetLastUpdate";

export const DashboardApplet: React.FC<{
  show: boolean;
  lastUpdate: Date | undefined;
  data: DashboardAppletData[];
  onDismiss: () => void;
  children?: never;
}> = ({ onDismiss, show, data, lastUpdate }) => {
  const [initShow, setInitShow] = useState(show);
  useEffect(() => {
    if (show) {
      setInitShow(true);
    }
  }, [show]);

  if (!show && !initShow) {
    return <></>;
  }

  const rows = [...data.filter(it => it.warned), ...data.filter(it => !it.warned)];

  return (
    <Container className={show ? "show" : "hidden"}>
      <Heading onClick={onDismiss}>
        部下勤怠のお知らせ
        <LastUpdate lastUpdate={lastUpdate} />
      </Heading>
      <Body>
        {rows.map(it => (
          <React.Fragment key={it.title}>
            <BodyData className={it.warned ? "Warned" : "Green"}>{it.title}</BodyData>
            <BodyData className={it.warned ? "Warned" : "Green"}>
              {it.value} {it.unit}
            </BodyData>
          </React.Fragment>
        ))}
      </Body>

      <BottomNavigation href={paths.dashboard.statistics.index()}>勤怠ダッシュボードで確認する</BottomNavigation>
    </Container>
  );
};

export interface DashboardAppletData {
  title: string;
  unit: string;
  value: number;
  warned: boolean;
}

const fadeIn = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

const Container = styled.section`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  height: 11rem;
  width: 17.5rem;
  background-color: #ccc;
  border-radius: 0.5rem;
  z-index: 100;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  transition: opacity 0.4s;
  visibility: hidden;

  &.show {
    visibility: visible;
    animation: ${fadeIn} 0.4s;
  }
  &.hidden {
    visibility: hidden;
    animation: ${fadeOut} 0.4s;
  }

  ${mediaQuerySmall} {
    & {
      display: none;
    }
  }
`;

const Heading = styled.h1`
  flex: none;
  cursor: pointer;
  padding: 0.25rem;
  text-align: center;

  font-size: 1rem;
  margin: 0;
`;

const LastUpdate = styled(DashboardWidgetLastUpdate)`
  color: #000;
  padding: 0;
  font-weight: normal;
`;

const Body = styled.div`
  flex: auto;
  overflow: auto;
  background-color: #f3f3f3;

  padding: 0.25rem;

  display: grid;
  grid-template-columns: 2fr 1fr;
`;

const BodyData = styled.div`
  padding: 0;

  &.Green {
    color: green;
    opacity: 0.6;
  }

  &.Warned {
    color: red;
    font-weight: bold;
  }
`;

const BottomNavigation = styled(WkLink)`
  flex: none;
  display: block;

  pointer-events: auto;

  padding: 0.5rem 0rem 0.5rem 0rem;
  text-align: center;
  background-color: #f3f3f3;
`;
