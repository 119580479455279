import { observer } from "mobx-react";
import React from "react";

import { HourBasedDaysCountField } from "../../../common/form/HourBasedDaysCountField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { PHPagGridRowVM } from "./PHPageGrid";

@observer
export class PHDayCountCell extends React.Component<{ model: PHPagGridRowVM; col: number }> {
  public render() {
    const data = this.props.model.perYearVacations[this.props.col];
    // const row = this.props.model;
    return (
      <HourBasedDaysCountField
        mode="cell"
        showAsDateCount={false}
        disabled={data.disabled}
        label=""
        value={data.hours}
        onChange={data.onChangeHours}
        hasNoChange={data.hasNoChange}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
