import { observer } from "mobx-react";
import React from "react";

import { AdminPageScreenModes } from "../../../models/admin/AdminPageScreenModes";
import { SubMenuButton } from "../../common/page/SubMenuButton";

export interface AdminSubMenuVM {
  pageMode: typeof AdminPageScreenModes.Type;
  onNavigateToWarnings: () => void;
  onNavigateToAccounts: () => void;
  onNavigateToPh: () => void;
  onNavigateToDepts: () => void;
  onNavigateToCalendar: () => void;
  onNavigateToRanks: () => void;
  onNavigateToPjClose: () => void;
  onNavigateToSPort: () => void;
}

@observer
export class AdminSubMenu extends React.Component<{ model: AdminSubMenuVM }> {
  public render() {
    const { model } = this.props;
    const {
      pageMode,
      onNavigateToWarnings,
      onNavigateToAccounts,
      onNavigateToPh,
      onNavigateToDepts,
      onNavigateToCalendar,
      onNavigateToRanks,
      onNavigateToPjClose,
      onNavigateToSPort,
    } = model;

    return (
      <>
        <SubMenuButton active={pageMode === "warnings"} icon="warning-sign" onClick={onNavigateToWarnings}>
          要注意
        </SubMenuButton>
        <SubMenuButton active={pageMode === "accounts"} icon="person" onClick={onNavigateToAccounts}>
          アカウント
        </SubMenuButton>
        <SubMenuButton active={pageMode === "ph"} icon="glass" onClick={onNavigateToPh}>
          有給休暇
        </SubMenuButton>
        <SubMenuButton active={pageMode === "ranks"} icon="crown" onClick={onNavigateToRanks}>
          ランク
        </SubMenuButton>
        <SubMenuButton active={pageMode === "depts"} icon="shield" onClick={onNavigateToDepts}>
          部門勤怠管理責任者
        </SubMenuButton>
        <SubMenuButton active={pageMode === "calendar"} icon="calendar" onClick={onNavigateToCalendar}>
          カレンダー
        </SubMenuButton>
        <SubMenuButton active={pageMode === "pjclose"} icon="folder-close" onClick={onNavigateToPjClose}>
          月報締め
        </SubMenuButton>
        <SubMenuButton active={pageMode === "sport"} icon="tint" onClick={onNavigateToSPort}>
          S-Port
        </SubMenuButton>
      </>
    );
  }
}
