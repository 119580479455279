import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { DiPanelMode } from "../../../models/kintai/DiPanelMode";
import { decorateTitle } from "../../../utils/apptitle";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { DiPanel } from "../../common/page/DiPanel";
import { PageRibbon } from "../../common/page/PageRibbon";
import { DeptsTreePanel, DeptsTreePanelVM } from "./deptree/DeptsTreePanel";
import { DeptUsersPanel, DeptUsersPanelRowVM } from "./deptree/DeptUsersPanel";
import { DeptsPageRibbon, DeptsPageRibbonVM } from "./ribbon/DeptsPageRibbon";

const UsersPanelContainer = styled.div`
  flex: 1 auto;
  overflow: hidden;

  display: grid;

  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 1fr;

  grid-template-areas:
    "a b"
    "a c";

  & > * {
  }

  & > *:nth-child(1) {
    border-right: 1px solid #eee;
    grid-area: a;
  }
  & > *:nth-child(2) {
    grid-area: b;
    border-bottom: 1px solid #eee;
  }
  & > *:nth-child(3) {
    grid-area: c;
  }
`;

export interface DeptsPageVM {
  loadingStatus: LoadingStatusType;
  panelMode: DiPanelMode;
  onChangePanelMode: (value: DiPanelMode) => void;
  ribbonModel: DeptsPageRibbonVM;
  onReloadOnFailure: () => void;
  deptsTreePanelVM: DeptsTreePanelVM;
  superiorUserRows: DeptUsersPanelRowVM[];
  selectedUserRows: DeptUsersPanelRowVM[];
  deptUserRows: DeptUsersPanelRowVM[];
}

@observer
export class DeptsPage extends React.Component<{ model: DeptsPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<DeptsPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("部門管理")}</title>
        </Helmet>
        {model.loadingStatus !== "loaded" && (
          <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
        )}
        {model.loadingStatus === "loaded" && (
          <DiPanel
            mode={model.panelMode}
            onChangeMode={model.onChangePanelMode}
            headerTitle="部門選択"
            header={<DeptsTreePanel model={model.deptsTreePanelVM} />}
            bodyTitle="部門内編集"
            body={
              <UsersPanelContainer>
                <DeptUsersPanel title="この部門配下の社員" rows={model.deptUserRows} />
                <DeptUsersPanel title="上位部門の社員（編集不可）" rows={model.superiorUserRows} />
                <DeptUsersPanel title="選択中の社員" rows={model.selectedUserRows} />
              </UsersPanelContainer>
            }
          />
        )}
      </PageRibbon>
    );
  }
}
