import { Instance } from "mobx-state-tree";
import moment from "moment";

import { NendoCalendarInput } from "../../../models/calendar/input/NendoCalendarInput";
import { ExcelColDef, generateColumns } from "../ExcelColDef";
import { excelLoadSheet } from "../excelLoadSheet";
import { excelWriteBook } from "../excelWriteBook";
import { NendoCalendarInputExcel, NendoCalendarInputExcelImportResult } from "./NendoCalendarInputExcel";

export class NendoCalendarInputExcelImpl extends NendoCalendarInputExcel {
  constructor() {
    super();
  }

  public async importExcel(file: File, target: Instance<typeof NendoCalendarInput>) {
    const sheetLoadResult = await excelLoadSheet(file, sheetName);

    if (sheetLoadResult.status === "failure") {
      return sheetLoadResult;
    }

    const { sheet } = sheetLoadResult;

    sheet.eachRow(row => {
      if (row.number > 1) {
        const date = fields[0].deserializer(row.getCell(1).text);
        const isHoliday = fields[1].deserializer(row.getCell(2).text);
        const isFinalDay = fields[2].deserializer(row.getCell(3).text);

        if (date) {
          const targetDate = target.getDate(date);
          if (targetDate) {
            targetDate.setIsHoliday(isHoliday);
            if (isFinalDay) {
              target.getMonth(date)!.setIsFinalDay(date, true);
            }
          }
        }
      }
    });

    return {
      status: "success",
      failureReason: undefined,
    } as NendoCalendarInputExcelImportResult;
  }

  public exportExcel(value: Instance<typeof NendoCalendarInput>) {
    return excelWriteBook(async wb => {
      const sheet = wb.addWorksheet(sheetName);
      sheet.columns = generateColumns(fields);
      value.months.forEach(m =>
        m.days.map(row => {
          sheet.addRow([
            fields[0].serializer(row.date),
            fields[1].serializer(row.isHoliday),
            fields[2].serializer(row.isFinalDay),
          ]);
        }),
      );
    });
  }
}

const sheetName = "カレンダー";

const fields: Array<ExcelColDef<any>> = [
  {
    label: "日付",
    type: "key",
    serializer: (value: Date) => moment(value).format("YYYY-MM-DD"),
    deserializer: (value: string) => moment(value).toDate(),
  },
  {
    label: "公休指定",
    type: "input",
    serializer: (value: boolean) => (value ? "○" : ""),
    deserializer: (value: string) => value === "○",
  },
  {
    label: "最終日指定",
    type: "input",
    serializer: (value: boolean) => (value ? "○" : ""),
    deserializer: (value: string) => value === "○",
  },
];
