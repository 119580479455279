import { sortBy } from "lodash-es";

interface ArrivalLeave {
  arrival: Date | undefined;
  leave: Date | undefined;
}

export const makeArrivalLeaveOrdered = (target: ArrivalLeave) => {
  if (!target.arrival || !target.leave) {
    return;
  }

  const [a, l] = sortBy([target.arrival, target.leave], it => it.getTime());
  target.arrival = a;
  target.leave = l;
};
