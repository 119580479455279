import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { AccountPageModelSymbol, AccountsPageModel } from "../../../../models/accounts/AccountsPageModel";
import { PasswordInitDialogVM } from "./PasswordInitDialog";

export class PasswordInitDialogVMImpl implements PasswordInitDialogVM {
  @lazyInject(AccountPageModelSymbol)
  private model: typeof AccountsPageModel.Type;

  @computed
  private get target() {
    return this.model.activePasswordChangeAccount!;
  }

  @computed
  get userName() {
    return this.target.value.userName;
  }

  @computed
  get userId() {
    return this.target.userId;
  }
  @computed
  get mailAddr() {
    return this.target.value.mailAddress;
  }
  @computed
  get suggestedPassword() {
    return this.target.suggedstedPassword;
  }

  @computed
  get fixedPasswordIsUsed() {
    return this.target.fixedPasswordIsUsed;
  }

  @action.bound
  public onRegeneratePassword() {
    this.target.regenerateSuggestedPassword();
  }

  @action.bound
  public onInitializePassword() {
    this.target.initPassword().then(() => {
      this.model.setActivePasswordChangeAccount(undefined);
    });
  }
}
