import { KintaiBunrui, sportBunruiLists } from "@webkintai/bunrui";
import { applySnapshot, getParentOfType, types } from "mobx-state-tree";

import { KintaiApplicationDayType } from "../apps/KintaiApplicationDay";
import { KintaiDailyError } from "../KintaiDailyError";
import { MonthlyKintai } from "../MonthlyKintai";
import { SPortDailyKintai } from "./SPortDailyKintai";

export function sportTypeItemState(idx: number) {
  const name = `type${idx}`;
  const setterName = `setType${idx}`;
  return types.optional(
    types
      .model(name, {
        warnings: types.array(types.string),
        errors: types.array(types.string),
      })
      .views(self => {
        return {
          get root() {
            return getParentOfType(self, MonthlyKintai);
          },
          get daily() {
            return getParentOfType(self, SPortDailyKintai);
          },
          get input() {
            return getParentOfType(self, SPortDailyKintai).input;
          },
          get computed() {
            return getParentOfType(self, SPortDailyKintai).computed;
          },
          get origin() {
            return getParentOfType(self, SPortDailyKintai).origin;
          },
          get dailyApp(): KintaiApplicationDayType {
            return this.root.apps!.days[this.daily.date.getDate() - 1];
          },
        };
      })
      .views(self => {
        return {
          get disabled() {
            if (self.root.isSportKintaiLocked) {
              return true;
            }
            if (self.dailyApp.kintaiTypeShouldBeLocked) {
              return true;
            }
            return false;
          },
          get availableKintaiBunrui() {
            return sportBunruiLists[idx - 1].filter(it => it.canAppearDateAt(self.daily.date));
          },
        };
      })
      .views(self => {
        return {
          get kintaiBunrui(): KintaiBunrui {
            return self.input[name];
          },
          get hasNoChange() {
            return self.disabled || this.hasEssentiallyNoChange;
          },
          get hasEssentiallyNoChange() {
            return self.origin[name] === self.input[name];
          },
        };
      })
      .actions(self => {
        const isMyError = (error: typeof KintaiDailyError.Type) =>
          (name === "type1" && error.hasField("types")) || error.hasField(name);
        const errorsOf = () => {
          return self.computed.errors
            .filter(isMyError)
            .filter(it => it.isError)
            .map(it => it.message);
        };
        const warningsOf = () => {
          return self.computed.errors
            .filter(isMyError)
            .filter(it => it.isWarning)
            .map(it => it.message);
        };
        return {
          onChange(value: KintaiBunrui) {
            self.input[setterName](value);
          },
          applyErrors() {
            const errors = errorsOf();
            const warnings = warningsOf();
            applySnapshot(self.errors, errors);
            applySnapshot(self.warnings, warnings);
          },
        };
      }),
    {},
  );
}
