import { action, computed } from "mobx";

import { NendoMonthCalendarInput } from "../../../../models/calendar/input/NendoMonthCalendarInput";
import { CalendarPageVMImpl } from "../CalendarPageVMImpl";
import { CalendarPageDayVMImpl } from "./CalendarPageDayVMImpl";
import { CalendarPageGridMonthVM } from "./CalendarPageGrid";

export class CalendarPageMonthVMImpl implements CalendarPageGridMonthVM {
  constructor(
    private readonly pageModel: CalendarPageVMImpl,
    private readonly model: typeof NendoMonthCalendarInput.Type,
  ) {}

  @computed
  public get month() {
    return this.model.monthDate;
  }

  @computed
  public get hasNoChange() {
    return this.model.hasNoChange;
  }

  @computed
  get disabled() {
    return !this.pageModel.allowedToEdit;
  }

  @computed
  public get showsLastWorkingDayOption() {
    return this.model.showsLastWorkingDayOption;
  }

  public dayAt(day: number) {
    return this.days[day - 1];
  }

  @action.bound
  public onChangeDeptsDerivationOption(value: boolean) {
    this.model.setDerivesLastYearDepts(value);
  }

  @computed
  public get deptsDerivationOption() {
    return this.model.allowReviseWorkDept;
  }

  @computed
  private get days() {
    const setFinalDay = (date: Date, value: boolean) => {
      this.model.setIsFinalDay(date, value);
    };
    return this.model.days.map(dayModel => new CalendarPageDayVMImpl(this.pageModel, dayModel, setFinalDay));
  }
}
