import { Intent, Toaster } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import { AppNotifier } from "./AppNotifier";

export class AppNotifierImpl extends AppNotifier {
  private loginToaster = Toaster.create();

  constructor() {
    super();
  }

  public info(content: { message: string }): void {
    this.loginToaster.show({
      icon: IconNames.INFO_SIGN,
      intent: Intent.SUCCESS,
      timeout: 1500,
      ...content,
    });
  }

  public warn(content: { message: string }): void {
    this.loginToaster.show({
      icon: IconNames.WARNING_SIGN,
      intent: Intent.WARNING,
      ...content,
    });
  }

  public error(content: {
    message: string;
    exception?: { message?: string; errors?: Array<{ message: string }> };
  }): void {
    const { exception } = content;
    let { message } = content;

    console.error(message, exception);

    if (exception) {
      const { errors, message: exceptionMessage } = exception;
      if (errors && errors.length > 0) {
        message += `\n(${errors.map(it => it.message).join("\n")})`;
      } else if (exceptionMessage) {
        message += `\n(${exceptionMessage})`;
      }
    }

    this.loginToaster.show({
      icon: IconNames.WARNING_SIGN,
      intent: Intent.DANGER,
      ...content,
      message,
    });
  }
}
