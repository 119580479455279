import { ProgressBar } from "@blueprintjs/core";
import React from "react";

import { PageRibbonSection } from "../page/PageRibbonSection";

export const LoadingRibbon = () => (
  <PageRibbonSection title="ローディング中です">
    <div style={{ width: "10em", height: "1em" }}>
      <ProgressBar />
    </div>
  </PageRibbonSection>
);
