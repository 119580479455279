import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { ExportableTable } from "../../common/export-table/ExportableTable";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ScrollablePane } from "../../common/ScrollablePane";
import { SaburokuPageRibbon, SaburokuPageRibbonVM } from "./ribbon/SaburokuPageRibbon";
import { SaburokuPageTable, SaburokuPageTableVM } from "./table/SaburokuPageTable";

export interface SaburokuPageVM {
  ribbonModel: SaburokuPageRibbonVM;
  tableModel: SaburokuPageTableVM;
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
}

@observer
export class SaburokuPage extends React.Component<{ model: SaburokuPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<SaburokuPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("三六協定協議対象者一覧")}</title>
        </Helmet>

        <ScrollablePane>
          {model.loadingStatus !== "loaded" && (
            <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
          )}
          {model.loadingStatus === "loaded" && (
            <ExportableTable title="三六協定協議対象者一覧">
              {renderedOn => <SaburokuPageTable renderedOn={renderedOn} model={model.tableModel} />}
            </ExportableTable>
          )}
        </ScrollablePane>
      </PageRibbon>
    );
  }
}
