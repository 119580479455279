import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type GrowingOverKFProps = {
  model: GrowingOverKFVM;
} & CellFormPrintAdaptable;

export interface GrowingOverKFVM {
  growingOver: KintaiInputItemState<Minutes | undefined>;
}

export const GrowingOverKF = observer((props: GrowingOverKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.growingOver);

  return <HHMMField {...props} label="残業累計時間" {...itemState} />;
});
