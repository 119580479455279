import { computed } from "mobx";

import { valueOnlyKintaiInputItemState } from "../../../../models/kintai/kintaiinput/ValueOnlyKintaiItem";
import { KintaiListEntry } from "../../../../models/kintai_list/KintaiListEntry";
import { KintaiListPageModel } from "../../../../models/kintai_list/KintaiListPageModel";
import { CommonKintaiListPageGridRowVMImpl } from "../common/CommonKintaiListPageGridRowVMImpl";
import { RegularKintaiListPageGridRowVM } from "./RegularKintaiListPageGridRowVM";

export class RegularKintaiListPageGridRowVMImpl extends CommonKintaiListPageGridRowVMImpl
  implements RegularKintaiListPageGridRowVM {
  constructor(pageModel: typeof KintaiListPageModel.Type, monthlyKintai: typeof KintaiListEntry.Type) {
    super(pageModel, monthlyKintai);
  }

  @computed
  private get total() {
    return this.monthlyKintai.regularTotal!;
  }

  @computed
  public get mTotalTransHolidays() {
    return valueOnlyKintaiInputItemState(this.total.transHolidays);
  }

  @computed
  public get mTotalTransOnDays() {
    return valueOnlyKintaiInputItemState(this.total.transOnDays);
  }
  @computed
  public get mTotalTransOffDays() {
    return valueOnlyKintaiInputItemState(this.total.transOffDays);
  }
  @computed
  public get mTotalNormalDays() {
    return valueOnlyKintaiInputItemState(this.total.normalDays);
  }
  @computed
  public get mTotalHolidayDays() {
    return valueOnlyKintaiInputItemState(this.total.holidayDays);
  }
  @computed
  public get mTotalSundayOnCount() {
    return valueOnlyKintaiInputItemState(this.total.sundayOnCount);
  }
  @computed
  public get mTotalAllNightCount() {
    return valueOnlyKintaiInputItemState(this.total.allNightCount);
  }
  @computed
  public get mTotalSickoutDays() {
    return valueOnlyKintaiInputItemState(this.total.sickoutDays);
  }
  @computed
  public get mTotalNonAttendanceDays() {
    return valueOnlyKintaiInputItemState(this.total.nonAttendanceDays);
  }
  @computed
  public get mTotalLeaveDays() {
    return valueOnlyKintaiInputItemState(this.total.leaveDays);
  }
  @computed
  public get mTotalCompDays() {
    return valueOnlyKintaiInputItemState(this.total.compDays);
  }
  @computed
  public get mTotalAllNightOffDays() {
    return valueOnlyKintaiInputItemState(this.total.allNightOffDays);
  }
  @computed
  public get mTotalPositiveDays() {
    return valueOnlyKintaiInputItemState(this.total.positiveDays);
  }
  @computed
  public get mTotalPaidDays() {
    return valueOnlyKintaiInputItemState(this.total.paidDays);
  }
  @computed
  public get mTotalOtherDays() {
    return valueOnlyKintaiInputItemState(this.total.otherDays);
  }
  @computed
  public get mTotalOtherHours() {
    return valueOnlyKintaiInputItemState(this.total.otherHours);
  }
  @computed
  public get mTotalPaidAllDays() {
    return valueOnlyKintaiInputItemState(this.total.paidAllDays);
  }
  @computed
  public get mTotalPaidHalfDays() {
    return valueOnlyKintaiInputItemState(this.total.paidHalfDays);
  }
  @computed
  public get mTotalSummerDays() {
    return valueOnlyKintaiInputItemState(this.total.summerDays);
  }
  @computed
  public get mTotalSpDays() {
    return valueOnlyKintaiInputItemState(this.total.spDays);
  }
  @computed
  public get mTotalOff() {
    return valueOnlyKintaiInputItemState(this.total.off);
  }
  @computed
  public get mTotalHolidaySum() {
    return valueOnlyKintaiInputItemState(this.total.holidaySum);
  }
  @computed
  public get mTotalMidnight() {
    return valueOnlyKintaiInputItemState(this.total.midnight);
  }
  @computed
  public get mTotalAllNightOff() {
    return valueOnlyKintaiInputItemState(this.total.allNightOff);
  }
  @computed
  public get mTotalSpWeekday() {
    return valueOnlyKintaiInputItemState(this.total.spWeekday);
  }
  @computed
  public get mTotalSpWeekdayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.spWeekdayMidnight);
  }
  @computed
  public get mTotalSpHoliday() {
    return valueOnlyKintaiInputItemState(this.total.spHoliday);
  }
  @computed
  public get mTotalSpHolidayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.spHolidayMidnight);
  }
  @computed
  public get mTotalLiquidateSum() {
    return valueOnlyKintaiInputItemState(this.total.liquidateSum);
  }
  @computed
  public get mTotalOverWeekday() {
    return valueOnlyKintaiInputItemState(this.total.overWeekday);
  }
  @computed
  public get mTotalOverWeekdayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overWeekdayMidnight);
  }
  @computed
  public get mTotalOverHoliday() {
    return valueOnlyKintaiInputItemState(this.total.overHoliday);
  }
  @computed
  public get mTotalOverHolidayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overHolidayMidnight);
  }
  @computed
  public get mTotalOver() {
    return valueOnlyKintaiInputItemState(this.total.over);
  }
  @computed
  public get mTotalOverSixtyWeekday() {
    return valueOnlyKintaiInputItemState(this.total.overSixtyWeekday);
  }
  @computed
  public get mTotalOverSixtyWeekdayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overSixtyWeekdayMidnight);
  }
  @computed
  public get mTotalOverSixtyHoliday() {
    return valueOnlyKintaiInputItemState(this.total.overSixtyHoliday);
  }
  @computed
  public get mTotalOverSixtyHolidayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overSixtyHolidayMidnight);
  }
  @computed
  public get mTotalOverSixtyLiquidate() {
    return valueOnlyKintaiInputItemState(this.total.overSixtyLiquidate);
  }
  @computed
  public get mTotalLawBasis() {
    return valueOnlyKintaiInputItemState(this.total.lawBasis);
  }
  @computed
  public get mTotalLawOverToday() {
    return valueOnlyKintaiInputItemState(this.total.lawOverToday);
  }
  @computed
  public get mTotalLawOverNine() {
    return valueOnlyKintaiInputItemState(this.total.lawOverNine);
  }
  @computed
  public get mTotalLawOverCalc() {
    return valueOnlyKintaiInputItemState(this.total.lawOverCalc);
  }
  @computed
  public get mTotalWorkplaceZaitakuDays() {
    return valueOnlyKintaiInputItemState(this.total.workplaceZaitakuDays);
  }
  @computed
  public get mTotalWorkplaceSyussyaDays() {
    return valueOnlyKintaiInputItemState(this.total.workplaceSyussyaDays);
  }
  @computed
  public get mTotalWorkplaceSonotaDays() {
    return valueOnlyKintaiInputItemState(this.total.workplaceSonotaDays);
  }
}
