import styled from "@emotion/styled";
import { Minutes, minutesEq } from "@webkintai/core";
import classNames from "classnames";
import React from "react";
import { useRef } from "react";

import { recognizeAsHour } from "../../../../utils/hours";
import { FocusMarkerClassName } from "../../table/GridTable";
import { CellAdaptable } from "../CellFormAdaptable";
import { ErrorOverlay } from "../ErrorOverlay";
import { cellFormStyle, cellStyle, componentCommonClasses, hasNoChangeStyle } from "../FormCommon";
import { HHMMFieldCoreProps, hhmmHourAsDisplayText } from "../HHMMField";

export const CellHHMMField: React.FunctionComponent<HHMMFieldCoreProps & CellAdaptable> = ({
  mode,
  value,
  hasNoChange,
  disableZeroGray,
  disabledButShowsErrors,
  disabled,
  onChange,
  errors,
  warnings,
  infos,
  placeholder,
}) => {
  const showsZeroAsGray = disableZeroGray !== true && value && value.minutes === 0;
  const errorOverlayDisabled = disabledButShowsErrors !== true && disabled;

  const [inputText, setInputText] = React.useState(hhmmHourAsDisplayText(value));
  const [inInput, setInInput] = React.useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const inputElem = () => {
    if (ref.current) {
      return ref.current;
    }
    return undefined;
  };

  if (!inInput && inputText !== hhmmHourAsDisplayText(value)) {
    setInputText(hhmmHourAsDisplayText(value));
  }

  const onFocus: React.FormEventHandler<HTMLInputElement> = ev => {
    setInputText(hhmmHourAsDisplayText(value));
    setInInput(true);
  };

  const onChangeComponentText: React.FormEventHandler<HTMLInputElement> = ev => {
    setInputText(ev.currentTarget.value);
  };

  const onBlur: React.FormEventHandler<HTMLInputElement> = ev => {
    setInInput(false);
    const hour = recognizeAsHour(inputElem()!!.value);
    const asMinutes = hour !== undefined ? Minutes.of(hour) : undefined;
    setInputText(hhmmHourAsDisplayText(asMinutes));

    if (onChange) {
      if (!minutesEq(value, asMinutes)) {
        onChange(asMinutes);
      }
    }
  };

  return (
    <ErrorOverlay mode={mode} disabled={errorOverlayDisabled} errors={errors} warnings={warnings} infos={infos}>
      <Cell
        className={classNames(
          FocusMarkerClassName,
          componentCommonClasses({ hasNoChange, disabled }),
          showsZeroAsGray && "Zero",
        )}
        ref={ref}
        placeholder={placeholder || "00:00"}
        readOnly={disabled}
        onChange={onChangeComponentText}
        onFocus={onFocus}
        onBlur={onBlur}
        value={inputText}
      />
    </ErrorOverlay>
  );
};

const Cell = styled.input`
  ${cellStyle}
  ${cellFormStyle}
  ${hasNoChangeStyle}

  text-align: center;
  &.Zero {
    color: rgba(0, 0, 0, 0.3);
  }
`;
