import { inputEnablerBunruiListMap, KintaiBunrui } from "@webkintai/bunrui";
import { applySnapshot, getParentOfType, types } from "mobx-state-tree";

import { MonthlyKintai } from "../MonthlyKintai";
import { alwaysDisabledItems, SPortDailyKintai } from "./SPortDailyKintai";

export function sportItemState<T>(
  name: string,
  setterName: string,
  comparator: (l: T, r: T) => boolean,
  trueName?: string,
) {
  return types.optional(
    types
      .model(name, {
        errors: types.array(types.string),
        warnings: types.array(types.string),
        infos: types.array(types.string),
      })
      .views(self => {
        return {
          get root() {
            return getParentOfType(self, MonthlyKintai);
          },
          get input() {
            return getParentOfType(self, SPortDailyKintai).input;
          },
          get computed() {
            return getParentOfType(self, SPortDailyKintai).computed;
          },
          get origin() {
            return getParentOfType(self, SPortDailyKintai).origin;
          },
        };
      })
      .views(self => {
        const has = (bunruiList: KintaiBunrui[]) =>
          bunruiList.reduce((b, bunrui) => b || !!self.input.types.find(t => t === bunrui), false);
        return {
          get disabled() {
            if (alwaysDisabledItems[name]) {
              return true;
            }
            if (self.root.isSportKintaiLocked) {
              return true;
            }
            if (["hourlyLeave", "hourlyNursingcare", "hourlyFamilycare"].includes(name)) {
              return !has(inputEnablerBunruiListMap[trueName || name] || []);
            }
            return false;
          },
        };
      })
      .views(self => {
        return {
          get value(): T {
            if (self.disabled) {
              return self.computed[name];
            }
            if (self.input[name] === undefined) {
              return self.computed[name];
            }
            return self.input[name];
          },
          get hasNoChange() {
            return self.disabled || comparator(self.origin[name], self.input[name]);
          },
        };
      })
      .actions(self => {
        const errorsOf = (itemName: string) => {
          return self.computed.errors
            .filter(it => it.hasField(itemName))
            .filter(it => it.isError)
            .map(it => it.message);
        };
        const warningsOf = (itemName: string) => {
          return self.computed.errors
            .filter(it => it.hasField(itemName))
            .filter(it => it.isWarning)
            .map(it => it.message);
        };
        const infosOf = (itemName: string) => {
          return self.computed.errors
            .filter(it => it.hasField(itemName))
            .filter(it => it.isInfo)
            .map(it => it.message);
        };

        return {
          onChange(value: T) {
            self.input[setterName](value);
          },
          applyErrors() {
            applySnapshot(self.errors, errorsOf(trueName || name));
            applySnapshot(self.warnings, warningsOf(trueName || name));
            applySnapshot(self.infos, infosOf(trueName || name));
          },
        };
      }),
    {},
  );
}
