import { UserNendoDashboardData } from "../../../../../../schema/build/commonjs/main/typescript";
import { PrivateDDTableData, PrivateDDUserDatesRow } from "../PrivateDDTableData";
import { sortByDatesCount } from "./sortByDatesCount";

export const calcPrvKintaiAuthNotApproved = (
  value: UserNendoDashboardData,
): PrivateDDTableData<PrivateDDUserDatesRow> => {
  const target = value.nendoData.notApproved;
  return {
    name: "勤怠申請（未承認）有",
    warned: target.length > 0,
    warningCount: target.length,
    unit: "件",
    rows: sortByDatesCount(
      target.map(it => ({
        user: it.userAttributes,
        warned: true,
        dates: it.dates.map(date => new Date(date)),
      })),
      "desc",
    ),
  };
};
