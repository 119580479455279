import { Container } from "inversify";

import { AppStateResolver } from "../AppStateResolver";
import { PasswordTokenResetFormModelSymbol } from "./PasswordTokenResetFormModel";

export function registerSingleton(container: Container, appStateResolver: AppStateResolver) {
  container
    .bind(PasswordTokenResetFormModelSymbol)
    .toDynamicValue(() => appStateResolver().passwordTokenResetFormModel);
}
