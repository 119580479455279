import moment from "moment";
import React from "react";

import { CheckboxField } from "../../form/CheckboxField";

export const ExcludeOldRetiredMembersCheckbox: React.FunctionComponent<{
  checked: boolean;
  onChange: (value: boolean) => void;
}> = props => {
  return (
    <CheckboxField mode="form" value={props.checked} onChange={props.onChange} label="1年以上前の退職社員を除外" />
  );
};

export const isOldEnoughToBeExcludedByRetirement = (date: Date | undefined) =>
  date !== undefined && moment(new Date()).diff(date, "days") >= 365;
