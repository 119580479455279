import React from "react";

import { MTotalPaidDaysKF } from "../../monthly/fields/leaves/composite/MTotalPaidDaysKF";
import { MTotalHourlyFamilycareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyFamilycareKF";
import { MTotalHourlyLeaveKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyLeaveKF";
import { MTotalHourlyNursingcareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyNursingcareKF";
import { MTotalPaidAllDaysKF } from "../../monthly/fields/leaves/old/MTotalPaidAllDaysKF";
import { MTotalPaidHalfDaysKF } from "../../monthly/fields/leaves/old/MTotalPaidHalfDaysKF";
import { MTotalFamilycareDaysKF } from "../../monthly/fields/leaves/plain/MTotalFamilycareDaysKF";
import { MTotalNursingcareDaysKF } from "../../monthly/fields/leaves/plain/MTotalNursingcareDaysKF";
import { MTotalOtherDaysKF } from "../../monthly/fields/leaves/plain/MTotalOtherDays";
import { MTotalOtherDaysExcludingNuFaKF } from "../../monthly/fields/leaves/plain/MTotalOtherDaysExcludingNuFa";
import { MTotalPlainPaidDaysKF } from "../../monthly/fields/leaves/plain/MTotalPlainPaidDaysKF";
import { MTotalPositiveDaysKF } from "../../monthly/fields/MTotalPositiveDaysKF";
import { MTotalSpDaysKF } from "../../monthly/fields/MTotalSpDaysKF";
import { MTotalSummerDaysKF } from "../../monthly/fields/MTotalSummerDaysKF";
import { RegularMonthlyTotalVM } from "../../monthly/regular/RegularMonthlyTotalVM";
import { SPortMonthlyTotalVM } from "../../monthly/sport/SPortMonthlyTotalVM";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";

export const getVacancyTotalComponents = ({
  date,
  total,
}: {
  date: Date;
  total: RegularMonthlyTotalVM | SPortMonthlyTotalVM;
}) => {
  const v = new VacantDayItemsVisibility(date);

  if (v.newDisplaySinceHourlyNF) {
    // 2022年度〜
    const alldayColStyle: React.CSSProperties = { borderRightStyle: "dotted", borderRightColor: "#CCC" };
    const hourColStyle: React.CSSProperties = { borderLeftStyle: "none" };

    return {
      vacancyTotalHeader: <th colSpan={7}>休暇取得日数</th>,
      vacancyTotalSubHeader: (
        <>
          <th style={alldayColStyle} title="半休・全休のみの有休">
            有休
          </th>
          <th style={hourColStyle} title="時間休のみの有休">
            (H)
          </th>

          <th style={alldayColStyle} title="看護（全休）のみの有休">
            看護
          </th>
          <th style={hourColStyle} title="看護時休のみの有休">
            (H)
          </th>

          <th style={alldayColStyle} title="介護（全休）のみの有休">
            介護
          </th>
          <th style={hourColStyle} title="介護時休のみの有休">
            (H)
          </th>

          <th title="その他の特別休暇">その他</th>
        </>
      ),
      vacancyTotalBody: (
        <>
          <td>
            <MTotalPlainPaidDaysKF mode="print" model={total} />
          </td>
          <td>
            <MTotalHourlyLeaveKF mode="print" model={total} />
          </td>

          <td>
            <MTotalNursingcareDaysKF mode="print" model={total} />
          </td>
          <td>
            <MTotalHourlyNursingcareKF mode="print" model={total} />
          </td>

          <td>
            <MTotalFamilycareDaysKF mode="print" model={total} />
          </td>
          <td>
            <MTotalHourlyFamilycareKF mode="print" model={total} />
          </td>

          <td>
            <MTotalOtherDaysExcludingNuFaKF mode="print" model={total} />
          </td>
        </>
      ),
    };
  }

  if (v.showAggregatedPaidDays) {
    // 2018年度〜
    return {
      vacancyTotalHeader: <th colSpan={2}>休暇取得日数</th>,
      vacancyTotalSubHeader: (
        <>
          <th title="半休・時間休含むすべての有休">有休</th>
          <th title="その他の特別休暇">その他</th>
        </>
      ),
      vacancyTotalBody: (
        <>
          {/* 休暇: (総合的な)有休 */}
          <td>
            <MTotalPaidDaysKF mode="print" model={total} />
          </td>
          {/* 休暇: その他 */}
          <td>
            <MTotalOtherDaysKF mode="print" model={total} />
          </td>
        </>
      ),
    };
  }

  return {
    vacancyTotalHeader: <th colSpan={4 + (v.summerDays ? 1 : 0)}>休暇取得日数</th>,
    vacancyTotalSubHeader: (
      <>
        <th title="終日の有休">有休</th>
        <th>半休</th>
        {v.summerDays && <th>夏休</th>}
        <th>特休</th>
        <th>ポ休</th>
      </>
    ),
    vacancyTotalBody: (
      <>
        {/* 休暇: (終日)有休 */}
        <td>{<MTotalPaidAllDaysKF mode="print" model={total} />}</td>
        {/* 休暇: 半休 */}
        <td>{<MTotalPaidHalfDaysKF mode="print" model={total} />}</td>
        {/* 休暇: 夏休 */}
        {v.summerDays && <td>{<MTotalSummerDaysKF mode="print" model={total} />}</td>}
        {/* 休暇: 特休 */}
        <td>{<MTotalSpDaysKF mode="print" model={total} />}</td>
        {/* 休暇: ポ休 */}
        <td>{<MTotalPositiveDaysKF mode="print" model={total} />}</td>
      </>
    ),
  };
};
