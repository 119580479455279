import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../models/statistics/usePrivateDDModel";
import { WithLoading } from "../../../../models/statistics/WithLoading";
import { DashboardApplet, DashboardAppletData } from "./DashboardApplet";

export const DashboardAppletContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return (
    <DashboardApplet
      show={model.showApplet}
      onDismiss={() => model.setDismissApplet(true)}
      lastUpdate={model.latestLastUpdate}
      data={[
        ...revealLoading(model.monthly_lawover),
        ...revealLoading(model.monthly_lawoverCalc),
        ...revealLoading(model.monthly_kintaiWarnings),
        ...revealLoading(model.nendo_kintaiAuthNotApplied),
        ...revealLoading(model.nendo_kintaiAuthNotApproved),
        ...revealLoading(model.nendo_paidLeaves),
      ]}
    />
  );
});

function revealLoading(
  data: WithLoading<{ name: string; warningCount: number; warned: boolean }>,
): DashboardAppletData[] {
  if (data.state === "loading" || data.state === "no_data") {
    return [];
  }
  return [
    {
      title: data.name,
      value: data.warningCount,
      unit: "人",
      warned: data.warned,
    },
  ];
}
