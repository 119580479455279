import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";

export interface PJRFooterCellTimeTotalKFProps {
  model: PJRFooterCellTimeTotalKFVM;
}

export interface PJRFooterCellTimeTotalKFVM {
  PJRFooterCellTimeTotal: Minutes | undefined;
}

export const PJRFooterCellTimeTotalKF = observer((props: PJRFooterCellTimeTotalKFProps) => {
  const { model } = props;
  return <HHMMField mode="print" value={model.PJRFooterCellTimeTotal} />;
});
