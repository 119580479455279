import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type PJRHeaderJobNoKFProps = {
  model: PJRHeaderJobNoKFVM;
} & CellFormPrintAdaptable;

export interface PJRHeaderJobNoKFVM {
  odCode: KintaiInputItemState<string>;
}

export const PJRHeaderJobNoKF = observer((props: PJRHeaderJobNoKFProps) => {
  const { model } = props;
  const itemState = model.odCode;

  return <TextField {...props} label="ジョブNo." {...itemState} />;
});
