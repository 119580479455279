import { types } from "mobx-state-tree";

const model = types
  .model("KintaiDailyError", {
    code: types.string,
    fields: types.array(types.string),
    level: types.string,
    message: types.string,
  })
  .views(self => {
    return {
      hasField(fieldName: string) {
        return !!self.fields.find(it => it === fieldName);
      },

      get isError() {
        return self.level === "ERROR";
      },

      get isWarning() {
        return self.level === "WARN";
      },

      get isInfo() {
        return self.level === "INFO";
      },
    };
  });
export const KintaiDailyError: KintaiDailyErrorModelType = model;
type KintaiDailyErrorInferredType = typeof model;
export interface KintaiDailyErrorModelType extends KintaiDailyErrorInferredType {}
