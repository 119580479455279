import { lazyInject } from "../../didecorators";
import { AdminPageModel, AdminPageModelSymbol } from "../../models/admin/AdminPageModel";
import { AccountsPageVMImpl } from "./accounts/AccountsPageVMImpl";
import { AdminPageVM } from "./AdminPage";
import { CalendarPageVMImpl } from "./calendar/CalendarPageVMImpl";
import { DeptsPageVMImpl } from "./depts/DeptsPageVMImpl";
import { AdminSubMenuVMImpl } from "./header/AdminSubMenuVMImpl";
import { AdminIndexPageVMImpl } from "./index/AdminIndexPageVMImpl";
import { PHPageVMImpl } from "./ph/PHPageVMImpl";
import { PjClosePageVMImpl } from "./pjclose/PjClosePageVMImpl";
import { RankPageVMImpl } from "./ranks/RankPageVMImpl";
import { SPortAdminPageVMImpl } from "./sport/SPortAdminPageVMImpl";

export class AdminPageVMImpl implements AdminPageVM {
  public adminSubMenuVM = new AdminSubMenuVMImpl();
  public adminIndexPageVM = new AdminIndexPageVMImpl();
  public accountsPageVM = new AccountsPageVMImpl();
  public deptsPageVM = new DeptsPageVMImpl();
  public calendarPageVM = new CalendarPageVMImpl();
  public phPageVM = new PHPageVMImpl();
  public rankPageVM = new RankPageVMImpl();
  public pjClosePageVM = new PjClosePageVMImpl();
  public sportAdminPageVM = new SPortAdminPageVMImpl();

  @lazyInject(AdminPageModelSymbol)
  private adminPageModel: typeof AdminPageModel.Type;

  public get pageMode() {
    return this.adminPageModel.pageMode;
  }
}
