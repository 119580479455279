import { FatigueChecksResult, PhysicianInterviewsResult } from "@webkintai/api";
import { applySnapshot, flow, getEnv, IModelType, Instance, SnapshotIn, types } from "mobx-state-tree";

import { FatigueCheckApi } from "../../services/api/FatigueCheckApi";
import { PhysicianInterviewApi } from "../../services/api/PhysicianInterviewApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate, toApiLocalDate } from "../../utils/api";
import { getNendoAsDate } from "../../utils/calendar";
import { hasNoChangeReduce, hasNoErrorReduce } from "../../utils/model";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { apiValueToReceptionStatus, receptionStatusToApiValue } from "../common/ReceptionStatus";
import { NendoRanksType } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { prefixedIdType } from "../utils";
import {
  idNendoNendoFatigueCheckListEntry,
  NendoFatigueCheckListEntry,
  NendoFatigueCheckListEntryModelType,
} from "./NendoFatigueCheckListEntry";
import { idNendoNendoInterviewListEntry, NendoInterviewListEntry } from "./NendoInterviewListEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoInterviewList_";

const model = types
  .model("NendoInterviewList", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,

    loadingStatus: types.optional(LoadingStatus, "init"),

    ivEntries: types.array(NendoInterviewListEntry),
    ftgEntries: types.array(NendoFatigueCheckListEntry),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);

    return {
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo), "InterviewList");
      },
      get hasNoChange() {
        return hasNoChangeReduce([
          ...self.ivEntries.map(it => it.hasNoChange),
          ...self.ftgEntries.map(it => it.hasNoChange),
        ]);
      },
      get hasNoError() {
        return hasNoErrorReduce([
          ...self.ivEntries.map(it => it.errors.length === 0),
          ...self.ftgEntries.map(it => it.errors.length === 0),
        ]);
      },
    };
  })
  .actions(self => {
    const physicianInterviewApi = () => getDI(self, PhysicianInterviewApi);
    const fatigueCheckApi = () => getDI(self, FatigueCheckApi);
    const appNotifier = () => getDI(self, AppNotifier);
    const ranks = () => getEnv(self).get(RanksSymbol) as RanksType;

    const loadIfNeeded = () => {
      return self.loadingStatus === "loaded" ? new Promise(done => done()) : load();
    };

    const save = flow(function*() {
      const api = physicianInterviewApi();

      try {
        self.loadingStatus = "loading";
        yield savePhysicianInterview();
        yield saveFatigueChecks();
      } finally {
        self.loadingStatus = "loaded";
      }

      yield load();
    });

    const savePhysicianInterview = flow(function*() {
      const api = physicianInterviewApi();
      const targets = self.ivEntries.filter(it => !it.hasNoChange);

      if (targets.length === 0) {
        return;
      }

      try {
        for (const ent of targets) {
          const payload = {
            actualDate: toApiLocalDate(ent.actualDate),
            note: ent.note,
            receptionStatus: receptionStatusToApiValue(ent.receptionStatus),
            reservedDate: toApiLocalDate(ent.reservedDate),
          };
          if (ent.isNew) {
            yield api.createPhysicianInterviewStatus(ent.userId, ent.targetMonth, payload);
          } else {
            yield api.updatePhysicianInterviewStatus(ent.userId, ent.targetMonth, payload);
          }
        }
      } catch (exception) {
        appNotifier().error({ message: "産業医面接一覧の保存に失敗しました", exception });
        throw exception;
      }
    });

    const saveFatigueChecks = flow(function*() {
      const api = fatigueCheckApi();
      const targets = self.ftgEntries.filter(it => !it.hasNoChange);

      if (targets.length === 0) {
        return;
      }

      try {
        for (const ent of targets) {
          yield api.updateFatigueCheck(ent.userId, ent.targetMonth, {
            submittedDate: toApiLocalDate(ent.submitDate),
            note: ent.note,
            confirmed: ent.acceptStatus,
          });
        }
      } catch (exception) {
        appNotifier().error({ message: "疲労蓄積度一覧の保存に失敗しました", exception });
        throw exception;
      }
    });

    const reset = () => {
      load();
    };

    const load = flow(function*() {
      try {
        self.loadingStatus = "loading";

        yield Promise.all([loadPhysicianInterview(), loadFatigueChecks()]);

        self.loadingStatus = "loaded";
      } catch (exception) {
        self.loadingStatus = "failed";
        appNotifier().error({ message: "産業医面接一覧等の読み込みに失敗しました", exception });
        throw exception;
      }
    });

    const loadPhysicianInterview = flow(function*() {
      const result: PhysicianInterviewsResult = yield physicianInterviewApi().getPhysicianInterviews(self.nendo);
      const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(self.nendo);

      const ivResult: Array<SnapshotIn<typeof NendoInterviewListEntry>> = result.interviews.map(it => {
        const receptionStatus = apiValueToReceptionStatus(it.receptionStatus.value) || "未対応";
        const rank = it.user.rankCode ? nendoRanks.getRank(it.user.rankCode) : undefined;
        return {
          id: idNendoNendoInterviewListEntry(self.nendo, fromApiDate(it.ymd), it.user.userId),
          nendo: self.nendo,
          targetMonth: fromApiDate(it.ymd),
          userId: it.user.userId,

          userName: it.user.userName || "",
          depCode: it.depCode || "",
          depName: it.depName || "",
          rankName: rank ? rank.rankName : "",
          lawOver: it.lawOver,
          sundayOnCount: it.sundayOnCount,

          receptionStatus,
          org_receptionStatus: receptionStatus,
          reservedDate: fromApiDate(it.reservedDate),
          org_reservedDate: fromApiDate(it.reservedDate),
          actualDate: fromApiDate(it.actualDate),
          org_actualDate: fromApiDate(it.actualDate),
          note: it.note || "",
          org_note: it.note || "",
        };
      });
      applySnapshot(self.ivEntries, ivResult);
    });

    const loadFatigueChecks = flow(function*() {
      const result: FatigueChecksResult = yield fatigueCheckApi().getFatigueChecks(self.nendo);
      const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(self.nendo);

      const ftgResult: Array<SnapshotIn<typeof NendoFatigueCheckListEntry>> = result.fatigueChecks.map(it => {
        const rank = it.user.rankCode ? nendoRanks.getRank(it.user.rankCode) : undefined;

        return {
          id: idNendoNendoFatigueCheckListEntry(self.nendo, fromApiDate(it.ymd), it.user.userId),
          nendo: self.nendo,
          targetMonth: fromApiDate(it.ymd),
          userId: it.user.userId,

          userName: it.user.userName || "",
          depCode: it.depCode || "",
          depName: it.depName || "",
          rankName: rank ? rank.rankName : "",
          lawOver: it.lawOver,
          sundayOnCount: it.sundayOnCount,

          submitDate: fromApiDate(it.submittedDate),
          org_submitDate: fromApiDate(it.submittedDate),
          acceptStatus: it.confirmed,
          org_acceptStatus: it.confirmed,
          note: it.note || "",
          org_note: it.note || "",
        };
      });
      applySnapshot(self.ftgEntries, ftgResult);
    });

    const addNewInterviewFromFatigue = (ftg: Instance<NendoFatigueCheckListEntryModelType>) => {
      const id = idNendoNendoInterviewListEntry(self.nendo, ftg.targetMonth, ftg.userId);
      if (self.ivEntries.find(it => it.id === id)) {
        return;
      }

      self.ivEntries.push(
        NendoInterviewListEntry.create({
          id,
          nendo: self.nendo,
          targetMonth: ftg.targetMonth,
          userId: ftg.userId,

          userName: ftg.userName,
          depCode: ftg.depCode,
          depName: ftg.depName,
          rankName: ftg.rankName,
          lawOver: ftg.lawOver,
          sundayOnCount: ftg.sundayOnCount,

          isNew: true,

          receptionStatus: "未対応",
          org_receptionStatus: "未対応",
          note: "",
          org_note: "",
        }),
      );
    };

    return {
      addNewInterview: addNewInterviewFromFatigue,
      load,
      loadIfNeeded,
      save,
      reset,
    };
  });

export const idNendoNendoInterviewList = (nendo: number) => `${idPrefix}${nendo}`;

export type NendoInterviewListType = typeof NendoInterviewList.Type;
export const NendoInterviewList: NendoInterviewListModelType = model;
type NendoInterviewListInferredType = typeof model;
export interface NendoInterviewListModelType extends NendoInterviewListInferredType {}
