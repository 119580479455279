import Color from "color";

// ベースの色の彩度をハッシュの法の分、変更して文字列から色を得る関数

export const chartBorderColor = (label: string): string => {
  return baseBorderColor
    .hue(hueAngle(label))
    .rgb()
    .string();
};

export const chartBgColor = (label: string): string => {
  return baseBgColor
    .hue(hueAngle(label))
    .rgb()
    .string();
};

const baseBorderColor = Color({ r: 51, g: 217, b: 232 });
const baseBgColor = baseBorderColor.alpha(0.2);

const hash = (s: string) => {
  return s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

const hueAngle = (label: string) => {
  return hash(label) % 360;
};
