import { Dialog } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

export interface ApplicationConfirmVM {
  isOpen: boolean;
  title: string;
  message: string;
  yesButtonLabel: string;
  noButtonLabel: string;
  onCancel: () => void;
  onOk: () => void;
}

@observer
export class ApplicationConfirm extends React.Component<{ model: ApplicationConfirmVM }> {
  public render() {
    const { model } = this.props;

    return (
      <Dialog isOpen={model.isOpen} title={model.title} onClose={model.onCancel}>
        <div className="bp3-dialog-body">
          <MessageContainer>{model.message}</MessageContainer>
        </div>
        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <button type="button" className="bp3-button" onClick={model.onCancel}>
              {model.noButtonLabel}
            </button>
            <button type="submit" className="bp3-button bp3-intent-primary" onClick={model.onOk}>
              {model.yesButtonLabel}
            </button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const MessageContainer = styled.pre`
  margin: 0;
  padding: 0;
  font-weight: normal;
  white-space: pre-wrap;
`;
