import { observer } from "mobx-react";
import React from "react";

import { DeptSelector, DeptSelectorVM } from "../../../common/dept-selector/DeptSelector";

export interface DeptsTreePanelVM {
  deptSelectorVM: DeptSelectorVM;
}

@observer
export class DeptsTreePanel extends React.Component<{ model: DeptsTreePanelVM }> {
  public render() {
    const { model } = this.props;
    return <DeptSelector model={model.deptSelectorVM} />;
  }
}
