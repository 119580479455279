import { ProjectCloseStatusListResult } from "@webkintai/api";
import { applySnapshot, detach, flow, IModelType, types } from "mobx-state-tree";

import { KintaiProjectApi } from "../../services/api/KintaiProjectApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { fromApiDate, toApiLocalDate } from "../../utils/api";
import { getNendoMonths } from "../../utils/calendar";
import { haveSameMonth } from "../../utils/date";
import { hasNoChangeReduce } from "../../utils/model";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { prefixedIdType } from "../utils";
import { PjCloseNendoEditorMonth } from "./PjCloseNendoEditorMonth";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "PjClose_";

const model = types
  .model("PjCloseNendoEditor", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    months: types.array(PjCloseNendoEditorMonth),
    loadingStatus: types.optional(LoadingStatus, "loading"),
  })
  .views(self => {
    return {
      get allowedToEdit() {
        return true;
      },
      get hasNoChange() {
        return hasNoChangeReduce(self.months.map(it => it.hasNoChange));
      },
    };
  })
  .actions(self => {
    const kintaiProjectApi = () => getDI(self, KintaiProjectApi);
    const appNotifier = () => getDI(self, AppNotifier);

    const save = flow(function*() {
      self.loadingStatus = "loading";

      try {
        const api = kintaiProjectApi();

        yield api.saveProjectCloseStatus(
          self.months
            .filter(it => !it.hasNoChange)
            .map(it => ({
              month: toApiLocalDate(it.month)!,

              closed: it.closed,
            })),
        );

        appNotifier().info({ message: "月報の全社締め状況を保存しました" });
      } catch (exception) {
        appNotifier().error({ message: `月報の全社締め状況を保存に失敗しました`, exception });
        return;
      } finally {
        self.loadingStatus = "loaded";
      }

      load();
    });

    const loadIfNeeded = () => {
      return self.loadingStatus === "loaded" ? new Promise(done => done()) : load();
    };

    const load = flow(function*() {
      self.loadingStatus = "loading";
      const api = kintaiProjectApi();

      const { list } = (yield api.getProjectCloseStatus(self.nendo)) as ProjectCloseStatusListResult;

      try {
        self.months.forEach(detach);
        applySnapshot(
          self.months,
          getNendoMonths(self.nendo).map((month, idx) => {
            const entry = list.find(it => haveSameMonth(month, fromApiDate(it.month)));
            const closed = entry === undefined ? false : entry.closed;

            return {
              month,

              closed,
              org_closed: closed,
            };
          }),
        );
        self.loadingStatus = "loaded";
      } catch (exception) {
        appNotifier().error({ message: `月報の全社締め状況の読み込みに失敗しました`, exception });
        self.loadingStatus = "failed";
      }
    });

    return {
      load,
      loadIfNeeded,
      save,
      reset() {
        load();
      },
    };
  });
export const idPjCloseNendoGivenTimes = (nendo: number) => `${idPrefix}${nendo}`;

export type PjCloseNendoEditorType = typeof PjCloseNendoEditorEntity.Type;

export const PjCloseNendoEditorSymbol = "PjCloseNendoEditor_Symbol";
export const PjCloseNendoEditorEntity: PjCloseNendoEditorModelType = model;
type PjCloseNendoEditorInferredType = typeof model;
export interface PjCloseNendoEditorModelType extends PjCloseNendoEditorInferredType {}
