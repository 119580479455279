import { KintaiBunrui, kintaiBunruiOf, NullKintai } from "@webkintai/bunrui";
import { types } from "mobx-state-tree";

export const typesKintaiBunrui = types.custom<string, KintaiBunrui>({
  name: "KintaiBunrui",
  fromSnapshot(value: string) {
    return value ? (kintaiBunruiOf(value) as KintaiBunrui) : NullKintai;
  },
  toSnapshot(value: KintaiBunrui) {
    return value ? value.code : "";
  },
  isTargetType(value: string | KintaiBunrui): boolean {
    return value instanceof KintaiBunrui;
  },
  getValidationMessage(value: string): string {
    return "";
  },
});
