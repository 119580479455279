import { Minutes } from "@webkintai/core";
import { chunk } from "lodash-es";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { PrintDetailMode } from "../../../../../models/kintai/misc/printdetailmode/PrintDetailMode";
import { ProjectInputStyle } from "../../../../../models/kintai/pjreport/ProjectInputStyle";
import { RegularDailyKintaiGridVM } from "../../daily/regular/RegularDailyKintaiGrid";
import { RegularMonthlyTotalVM } from "../../monthly/regular/RegularMonthlyTotalVM";
import { PrintedTimestamps, PrintedTimestampsVM } from "../common/PrintedTimestamps";
import { KintaiPrintFrame, KintaiPrintPage } from "../KintaiPrintFrame";
import { RegularPrintedMainTable } from "./RegularPrintedMainTable";
import { RegularPrintedPJBody } from "./RegularPrintedPJBody";
import { RegularPrintedPJHeader } from "./RegularPrintedPJHeader";
import { RegularPrintedTotalTables } from "./RegularPrintedTotalTables";

export type RegularPrintedKintaiVM = {
  name: string;
  depName: string;
  rankName: string;
  userId: string;
  designatedMinutes?: Minutes;
  projectInputStyle: ProjectInputStyle;
  printDetailMode: PrintDetailMode;

  regularMonthlyTotal: RegularMonthlyTotalVM;
} & RegularDailyKintaiGridVM &
  PrintedTimestampsVM;

@observer
export class RegularPrintedKintai extends React.Component<{ model: RegularPrintedKintaiVM }> {
  public render() {
    const { model } = this.props;
    const { regularRows } = model;
    const firstRow = regularRows[0];
    const { date } = firstRow;
    const chunks = chunk(firstRow.dailyPillars, 7);
    const kintaiTableClassName = `RegularPrintedKintai_KintaiTable`;

    return (
      <KintaiPrintFrame title={`Web勤怠印刷 - ${model.name}(${model.userId})_${moment(date).format("YYYY年MM月")}`}>
        <style>{`
        .${kintaiTableClassName} {
          display: grid;
          grid-template-columns: 5fr 1fr;        
        }
        
        /* メイン月報 */
        .${kintaiTableClassName} > *:nth-child(1) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      
        /* いろいろ欄 */
        .${kintaiTableClassName} > *:nth-child(2) {
        }

        /* ハンコ欄 */
        .${kintaiTableClassName} > *:nth-child(3) {
        }
      
        /* 月報詳細 (ヘッダ・ボディ) */
        .${kintaiTableClassName} > *:nth-child(4) {
        }
      
        .${kintaiTableClassName} > *:nth-child(4),
        .${kintaiTableClassName} > *:nth-child(5) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        `}</style>

        <KintaiPrintPage noBreak={true}>
          <div className={kintaiTableClassName}>
            <RegularPrintedMainTable {...this.props} />
            <RegularPrintedTotalTables {...this.props} />
            <PrintedTimestamps {...this.props} date={date} />
          </div>
        </KintaiPrintPage>
        {chunks.map((c, idx) => (
          <KintaiPrintPage key={idx}>
            <RegularPrintedPJHeader {...this.props} />
            <RegularPrintedPJBody {...this.props} targetPillarIds={c.map(it => it.id)} />
          </KintaiPrintPage>
        ))}
      </KintaiPrintFrame>
    );
  }
}
