import { HourBasedDays } from "@webkintai/core";
import React from "react";

export const ExportHourBasedDays: React.FunctionComponent<{
  value: HourBasedDays | undefined;
  showAs: "dayCount" | "hourCount";
}> = ({ value, showAs }) => {
  if (value === undefined) {
    return <></>;
  }
  if (showAs === "dayCount") {
    return <>{value.dayCount}</>;
  }
  return <>{value.hours}</>;
};
