import { KintaiTransDayReportsResult } from "@webkintai/api";
import { applySnapshot, flow, getEnv, IModelType, SnapshotIn, types } from "mobx-state-tree";
import moment from "moment";

import { TransDayApi } from "../../services/api/TransDayApi";
import { fromApiDate } from "../../utils/api";
import { getNendo, keepDateDistanceWithin } from "../../utils/calendar";
import { dateTrunc } from "../../utils/date";
import { filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDeptsType } from "../depts/NendoDepts";
import { NendoRanksType } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { TransDayPageValidityFilter, TransDayPageValidityFilterType } from "./TransDayPageValidityFilter";
import { TransDayResultEntry } from "./TransDayResultEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const defaultMonthRange = 1;
const maxMonthRange = 12;

const model = types.optional(
  types
    .model("TransDayPageModel", {
      /** 最終検索条件 */
      lastSearchCondition: types.optional(types.string, ""),

      /** 検索 FROM */
      fromDate: types.optional(
        types.Date,
        dateTrunc(
          moment(new Date())
            .add(-defaultMonthRange, "months")
            .startOf("month")
            .toDate(),
        ),
      ),
      /** 検索 TO */
      toDate: types.optional(
        types.Date,
        dateTrunc(
          moment(new Date())
            .endOf("month")
            .toDate(),
        ),
      ),

      /** 検索結果 */
      searchResult: types.array(TransDayResultEntry),
      searchResultLoadingState: types.optional(LoadingStatus, "loaded"),

      /** 検索結果に対するフィルタ */
      includeManager: types.optional(types.boolean, false),
      filterText: types.optional(types.string, ""),
      filterForValidity: types.optional(TransDayPageValidityFilter, "ALL"),
    })
    .views(self => {
      return {
        get currentSearchCondition() {
          return self.fromDate + " " + self.toDate;
        },
        get filteredEntries() {
          return self.searchResult
            .filter(it => {
              if (!self.includeManager) {
                return !it.isManager;
              }
              return true;
            })
            .filter(it => it.filterMatched(filterTextToSearchWords(self.filterText)));
        },
      };
    })
    .actions(self => {
      const transDayApi = () => getDI(self, TransDayApi);
      const depts = () => getEnv(self).get(DeptsSymbol) as DeptsType;
      const ranks = () => getEnv(self).get(RanksSymbol) as RanksType;

      const setDateRange = (fieldName: "from" | "to", value: Date | undefined) => {
        if (fieldName === "from") {
          if (value === undefined) {
            value = moment(self.toDate)
              .add(-defaultMonthRange, "months")
              .toDate();
          }
          self.fromDate = value;
          self.toDate = keepDateDistanceWithin(self.fromDate, self.toDate, maxMonthRange, "months");
        }
        if (fieldName === "to") {
          if (value === undefined) {
            value = moment(self.fromDate)
              .add(defaultMonthRange, "months")
              .toDate();
          }
          self.toDate = value;
          self.fromDate = keepDateDistanceWithin(self.toDate, self.fromDate, maxMonthRange, "months");
        }
      };

      const reload = () => triggerSearch(true);

      const triggerSearch = flow(function*(forceReload: boolean = false) {
        try {
          if (!forceReload && self.lastSearchCondition === self.currentSearchCondition) {
            return;
          }

          self.lastSearchCondition = self.currentSearchCondition;
          self.searchResultLoadingState = "loading";

          // 部署名のために部署をロード
          const nendoDepts: NendoDeptsType = yield depts().prepareAndLoadMostRecent(getNendo(new Date()));

          // 管理職判定のためにランクをロード
          const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(getNendo(new Date()));

          const result: KintaiTransDayReportsResult = yield transDayApi().searchTransDayReports(
            self.fromDate,
            self.toDate,
          );
          const entriesResult: Array<SnapshotIn<typeof TransDayResultEntry>> = result.transDayReports.map(report => {
            const dept = report.user.depCode ? nendoDepts.getDept(report.user.depCode) : undefined;
            const rank = report.user.rankCode ? nendoRanks.getRank(report.user.rankCode) : undefined;
            return {
              userId: report.user.userId,
              userName: report.user.userName || "",
              deptCode: report.user.depCode || "",
              deptName: dept ? dept.name : "",
              isManager: !!(rank && rank.isManager),

              entries: report.reports.map(rep => ({
                date: fromApiDate(rep.date),
                types: rep.types.map(it => it.value),
                transDay: fromApiDate(rep.transDay),
                valid: rep.valid,
              })),
            };
          });
          applySnapshot(self.searchResult, entriesResult);
        } finally {
          self.searchResultLoadingState = "loaded";
        }
      });

      const route = (pathFragment: string) => {
        triggerSearch();
      };

      return {
        route,
        reload,
        setFromDate(value: Date | undefined) {
          if (value === undefined) {
            value = moment(self.toDate)
              .add(-defaultMonthRange, "months")
              .toDate();
          }
          self.fromDate = value;
          self.toDate = keepDateDistanceWithin(self.fromDate, self.toDate, maxMonthRange, "months");

          triggerSearch();
        },
        setToDate(value: Date | undefined) {
          if (value === undefined) {
            value = moment(self.fromDate)
              .add(defaultMonthRange, "months")
              .toDate();
          }
          self.toDate = value;
          self.fromDate = keepDateDistanceWithin(self.toDate, self.fromDate, maxMonthRange, "months");

          triggerSearch();
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
        setIncludeManager(value: boolean) {
          self.includeManager = value;
        },
        setFilterForValidity(value: TransDayPageValidityFilterType) {
          self.filterForValidity = value;
        },
      };
    }),
  {},
);

export type TransDayPageModelType = typeof TransDayPageModel.Type;

export const TransDayPageModelSymbol = "TransDayPageModel_Symbol";
export const TransDayPageModel: TransDayPageModelModelType = model;
type TransDayPageModelInferredType = typeof model;
export interface TransDayPageModelModelType extends TransDayPageModelInferredType {}
