import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { ApprovalStamp } from "./ApprovalStamp";

export type MonthlySelfStampProps = {
  model: MonthlySelfStampVM;
} & CellFormAdaptable;

export interface MonthlySelfStampVM {
  selfStamped: boolean;
}

export const MonthlySelfStamp = observer((props: MonthlySelfStampProps) => {
  return <ApprovalStamp {...props} stamped={props.model.selfStamped} />;
});
