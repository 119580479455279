import { action, computed } from "mobx";

import { NendoDayCalendarInput } from "../../../../models/calendar/input/NendoDayCalendarInput";
import { CalendarPageVMImpl } from "../CalendarPageVMImpl";
import { CalendarPageGridDayVM } from "./CalendarPageGrid";

export class CalendarPageDayVMImpl implements CalendarPageGridDayVM {
  constructor(
    private readonly pageModel: CalendarPageVMImpl,
    private readonly model: typeof NendoDayCalendarInput.Type,
    private readonly onSetFinalDay: (date: Date, value: boolean) => void,
  ) {}

  get date() {
    return this.model.date;
  }

  @computed
  get disabled() {
    return !this.pageModel.allowedToEdit;
  }

  @computed
  get isActive() {
    return false;
  }

  @computed
  get hasNoChange() {
    return this.model.hasNoChange;
  }

  @computed
  get isHoliday() {
    return this.model.isHoliday;
  }

  @action.bound
  public onChangeIsHoliday(value: boolean) {
    this.model.setIsHoliday(value);
  }

  @computed
  get isFinalDay() {
    return this.model.isFinalDay;
  }

  @action.bound
  public onChangeIsFinalDay(value: boolean) {
    this.onSetFinalDay(this.model.date, value);
  }
}
