import { Minutes } from "@webkintai/core";
import { KintaiBunrui } from "./KintaiBunrui"; // このファイルは自動生成されたものです。手で編集しないでください。

/** NULL勤怠オブジェクト */

export var NullKintai = new KintaiBunrui("", "", "", "(未選択)", [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050], -10, [1, 2, 3, 4], ["ポ休", "リ休", "介休", "介護休暇", "介護時休", "代休", "休出", "入前", "公休", "出張", "前休", "前特", "午前介休", "午前看休", "午後介休", "午後看休", "夏休", "外出", "婚休", "平日", "後休", "後特", "徹休", "徹夜", "忌休", "指定公休", "振休", "振出", "早退", "時単休", "有休", "欠勤", "災休", "特休", "特勤", "生理休暇", "産休", "直出", "直帰", "看護休暇", "看護時休", "編集", "育介在宅", "裁判休暇", "退後", "遅刻", "配出休"], ["FG", "FM", "NG", "NM"], [-10, -10, -10], false, false, false, null);
/** 所定休日 */

export var 公休 = new KintaiBunrui("公休", "所定休日", "所定休日", "社内の休日です。自動的に選択されます。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 1, [1], ["", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "外出", "遅刻", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [10, null, null], false, false, false, null);
/** 平日 */

export var 平日 = new KintaiBunrui("平日", "平日", "平日", "勤務日です。自動的に選択されます。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 2, [1], ["", "指定公休", "出張", "直出", "直帰", "育介在宅", "リ休", "有休", "前休", "後休", "時単休", "休出", "代休", "振出", "振休", "徹夜", "徹休", "特休", "婚休", "配出休", "忌休", "災休", "前特", "後特", "外出", "遅刻", "早退", "欠勤", "特勤", "生理休暇", "産休", "介休", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "夏休", "ポ休", "裁判休暇", "入前", "退後", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], false, false, false, null);
/** 出張勤務 */

export var 出張 = new KintaiBunrui("出張", "出張勤務", "出張", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 10, [2, 3, 4], ["", "公休", "指定公休", "平日", "直出", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FM", "FG", "NM", "NG"], [80, null, null], false, false, false, null);
/** 直出 */

export var 直出 = new KintaiBunrui("直出", "直出", "直出", "", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 20, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, false, false, null);
/** 直帰 */

export var 直帰 = new KintaiBunrui("直帰", "直帰", "直帰", "", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 30, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, false, false, null);
/** 在宅勤務 */

export var 育介在宅 = new KintaiBunrui("育介在宅", "在宅勤務", "在宅勤務", "", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 40, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, true, false, null);
/** リフレッシュ休日 */

export var リ休 = new KintaiBunrui("リ休", "リフレッシュ休日", "リ休", "（現在は廃止）", [2012, 2013, 2014, 2015], 315, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, null, null], false, false, false, null);
/** 年次有給休暇 */

export var 有休 = new KintaiBunrui("有休", "年次有給休暇", "有休", "終日の有給休暇です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 50, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [null, 10, null], true, true, false, null);
/** 有給休暇（午前） */

export var 前休 = new KintaiBunrui("前休", "有給休暇（午前）", "午前有休", "午前の有給休暇です。0.5日分消費します。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 60, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "時単休", "振出", "振休", "徹夜", "後特", "外出", "遅刻", "早退", "特勤", "午後看休", "看護時休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 60, null], true, true, false, null);
/** 有給休暇（午後） */

export var 後休 = new KintaiBunrui("後休", "有給休暇（午後）", "午後有休", "午後の有給休暇です。0.5日分消費します。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 70, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "時単休", "振出", "振休", "徹夜", "前特", "外出", "遅刻", "早退", "特勤", "午前看休", "看護時休", "午前介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 70, null], true, true, false, null);
/** 時間単位有休 */

export var 時単休 = new KintaiBunrui("時単休", "時間単位有休", "時間有休", "時間単位の有給休暇です。選んだ場合、時間有休欄を入力してください。（実働時間以下になるように入力してください。）", [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 75, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "振出", "振休", "徹夜", "前特", "後特", "外出", "遅刻", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 75, null], true, true, false, null);
/** 休日出勤 */

export var 休出 = new KintaiBunrui("休出", "休日出勤", "休日出勤", "選んだ場合、振替日（日付）を入力してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 80, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "振休", "徹夜", "外出", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [70, null, null], true, false, false, null);
/** 代休 */

export var 代休 = new KintaiBunrui("代休", "代休", "代休", "選んだ場合、振替日（日付）を入力してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 90, [2, 3, 4], ["", "平日"], ["FG", "NG"], [null, 50, null], true, false, false, null);
/** 振替出勤 */

export var 振出 = new KintaiBunrui("振出", "振替出勤", "振替出勤", "選んだ場合、振替日（日付）を入力してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 100, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FG", "NG"], [null, 30, null], true, false, false, null);
/** 振替休日 */

export var 振休 = new KintaiBunrui("振休", "振替休日", "振替休日", "選んだ場合、振替日（日付）を入力してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 110, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇", "編集"], ["FG", "NG"], [null, 40, null], true, false, false, null);
/** 徹夜勤務 */

export var 徹夜 = new KintaiBunrui("徹夜", "徹夜勤務", "徹夜勤務", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 120, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "休出", "振出", "振休", "徹休", "前特", "後特", "外出", "遅刻", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FG"], [null, null, 30], true, false, false, null);
/** 徹夜勤務休暇 */

export var 徹休 = new KintaiBunrui("徹休", "徹夜勤務休暇", "徹夜明休", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 130, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "徹夜", "外出", "特勤", "編集"], ["FG"], [null, 190, null], true, false, false, null);
/** 特別休暇 */

export var 特休 = new KintaiBunrui("特休", "特別休暇", "特別休暇", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 140, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 20, null], true, true, true, null);
/** 結婚休暇 */

export var 婚休 = new KintaiBunrui("婚休", "結婚休暇", "結婚休暇", "", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 341, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 261, null], true, true, false, null);
/** 配偶者出産休暇 */

export var 配出休 = new KintaiBunrui("配出休", "配偶者出産休暇", "出産休暇", "", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 342, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 262, null], true, true, false, null);
/** 忌引休暇 */

export var 忌休 = new KintaiBunrui("忌休", "忌引休暇", "忌引休暇", "", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 343, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 263, null], true, true, false, null);
/** 災害休暇 */

export var 災休 = new KintaiBunrui("災休", "災害休暇", "災害休暇", "", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 344, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 264, null], true, true, true, null);
/** 特別休暇（午前） */

export var 前特 = new KintaiBunrui("前特", "特別休暇（午前）", "午前特休", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 190, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "後休", "時単休", "振出", "振休", "徹夜", "外出", "遅刻", "早退", "特勤", "午後看休", "看護時休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 130, null], true, true, true, null);
/** 特別休暇（午後） */

export var 後特 = new KintaiBunrui("後特", "特別休暇（午後）", "午後特休", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 200, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "時単休", "振出", "振休", "徹夜", "外出", "遅刻", "早退", "特勤", "午前看休", "看護時休", "午前介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 140, null], true, true, true, null);
/** 私用外出 */

export var 外出 = new KintaiBunrui("外出", "私用外出", "私用外出", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 150, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "遅刻", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, false, false, null);
/** 遅刻 */

export var 遅刻 = new KintaiBunrui("遅刻", "遅刻", "遅刻", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 160, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "振出", "振休", "徹夜", "前特", "後特", "外出", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FG", "NG"], [null, null, 10], false, false, false, null);
/** 早退 */

export var 早退 = new KintaiBunrui("早退", "早退", "早退", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 170, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "振出", "振休", "徹夜", "前特", "後特", "外出", "遅刻", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FG", "NG"], [null, null, 20], true, false, false, null);
/** 欠勤 */

export var 欠勤 = new KintaiBunrui("欠勤", "欠勤", "欠勤", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 180, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 100, null], true, true, false, null);
/** 特別勤務 */

export var 特勤 = new KintaiBunrui("特勤", "特別勤務", "特別勤務", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 210, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇"], ["FG"], [null, 150, null], true, true, false, null);
/** 生理休暇 */

export var 生理休暇 = new KintaiBunrui("生理休暇", "生理休暇", "生理休暇", "", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 220, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, true, false, null);
/** 産前産後休暇 */

export var 産休 = new KintaiBunrui("産休", "産前産後休暇", "産休", "", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 230, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 160, null], true, true, false, null);
/** 介護休業 */

export var 介休 = new KintaiBunrui("介休", "介護休業", "介護休業", "介護休「暇」とは異なるので注意してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 240, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 180, null], false, false, false, null);
/** 子の看護休暇 */

export var 看護休暇 = new KintaiBunrui("看護休暇", "子の看護休暇", "看護休暇", "就業規則上、別途申出書(これまで同様)は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 260, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [null, 200, null], true, true, false, null);
/** 子の看護休暇（時間休） */

export var 看護時休 = new KintaiBunrui("看護時休", "子の看護休暇（時間休）", "看護時休", "時間単位の看護休暇です。選んだ場合、取得時間数を入力してください。", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 265, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "振出", "振休", "徹夜", "前特", "後特", "外出", "遅刻", "早退", "特勤", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 205, null], true, true, false, null);
/** 子の看護休暇（午前） */

export var 午前看休 = new KintaiBunrui("午前看休", "子の看護休暇（午前）", "午前看休", "（時間休の導入により廃止）就業規則上、別途申出書(これまで同様)は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021], 270, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "後休", "時単休", "振出", "振休", "徹夜", "後特", "外出", "遅刻", "早退", "特勤", "午後介休", "編集"], ["FM", "FG", "NM", "NG"], [null, 210, null], true, true, false, null);
/** 子の看護休暇（午後） */

export var 午後看休 = new KintaiBunrui("午後看休", "子の看護休暇（午後）", "午後看休", "（時間休の導入により廃止）就業規則上、別途申出書(これまで同様)は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021], 280, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "時単休", "振出", "振休", "徹夜", "前特", "外出", "遅刻", "早退", "特勤", "午前介休", "編集"], ["FM", "FG", "NM", "NG"], [null, 220, null], true, true, false, null);
/** 介護休暇 */

export var 介護休暇 = new KintaiBunrui("介護休暇", "介護休暇", "介護休暇", " 介護休「業」とは異なるので注意してください。 就業規則上、別途申出書（これまで同様）は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 290, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [null, 230, null], true, true, false, null);
/** 介護休暇（時間休） */

export var 介護時休 = new KintaiBunrui("介護時休", "介護休暇（時間休）", "介護時休", "時間単位の介護休暇です。選んだ場合、取得時間数を入力してください。", [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 295, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "振出", "振休", "徹夜", "前特", "後特", "外出", "遅刻", "早退", "特勤", "看護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, 235, null], true, true, false, null);
/** 介護休暇（午前） */

export var 午前介休 = new KintaiBunrui("午前介休", "介護休暇（午前）", "午前介休", "（時間休の導入により廃止） 介護休「業」とは異なるので注意してください。 就業規則上、別途申出書（これまで同様）は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021], 300, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "後休", "時単休", "振出", "振休", "徹夜", "後特", "外出", "遅刻", "早退", "特勤", "午後看休", "編集"], ["FM", "FG", "NM", "NG"], [null, 240, null], true, true, false, null);
/** 介護休暇（午後） */

export var 午後介休 = new KintaiBunrui("午後介休", "介護休暇（午後）", "午後介休", "（時間休の導入により廃止） 介護休「業」とは異なるので注意してください。 就業規則上、別途申出書（これまで同様）は必要となります。ご承知おきください。", [2018, 2019, 2020, 2021], 310, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "時単休", "振出", "振休", "徹夜", "前特", "外出", "遅刻", "早退", "特勤", "午前看休", "編集"], ["FM", "FG", "NM", "NG"], [null, 250, null], true, true, false, null);
/** 夏季特別休暇 */

export var 夏休 = new KintaiBunrui("夏休", "夏季特別休暇", "夏季休暇", "（現在は廃止）", [2012, 2013, 2014, 2015, 2016, 2017, 2018], 320, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 80, null], true, true, false, null);
/** ポジティブ休暇 */

export var ポ休 = new KintaiBunrui("ポ休", "ポジティブ休暇", "ポジ休暇", "（現在は廃止）", [2013, 2014, 2015, 2016, 2017, 2018], 330, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, 90, null], true, true, false, null);
/** 裁判員特別休暇 */

export var 裁判休暇 = new KintaiBunrui("裁判休暇", "裁判員特別休暇", "裁判休暇", "", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 340, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "振出", "振休", "特勤", "編集"], ["FM", "FG", "NM", "NG"], [null, 260, null], true, true, false, null);
/** 指定公休 */

export var 指定公休 = new KintaiBunrui("指定公休", "指定公休", "所定休日", "個別の雇用契約により職員就業規則に定める休日以外を休日とした場合に選択します。", [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 345, [2, 3, 4], ["", "平日", "出張", "直出", "直帰", "育介在宅", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "外出", "遅刻", "早退", "特勤", "午前看休", "午後看休", "看護時休", "午前介休", "午後介休", "介護時休", "編集"], ["FM", "FG", "NM", "NG"], [null, null, null], true, true, true, null);
/** 入社前 */

export var 入前 = new KintaiBunrui("入前", "入社前", "入社前", "月の途中から入社した場合は、入社前の平日にこれを指定してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 350, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, null, null], false, false, false, null);
/** 退職後 */

export var 退後 = new KintaiBunrui("退後", "退職後", "退職後", "月の途中で退職する場合は、退職後の残りの平日部分にこれを指定してください。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 360, [2, 3, 4], ["", "平日"], ["FM", "FG", "NM", "NG"], [null, null, null], false, false, false, null);
/** 編集 */

export var 編集 = new KintaiBunrui("編集", "編集", "編集", "（現在は廃止）", [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022], 370, [2, 3, 4], ["", "公休", "指定公休", "平日", "出張", "直出", "直帰", "育介在宅", "有休", "前休", "後休", "時単休", "休出", "振出", "振休", "徹夜", "徹休", "前特", "後特", "外出", "遅刻", "早退", "生理休暇", "看護休暇", "午前看休", "午後看休", "看護時休", "介護休暇", "午前介休", "午後介休", "介護時休", "裁判休暇"], ["FM", "NM", "NG"], [null, null, null], false, false, false, null);
/** 育児休業 */

export var 育休 = new KintaiBunrui("育休", "育児休業", "育児休業", "育児休業期間の場合、自動的に選択されます。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 1000, [1], [""], ["FM", "FG", "NM", "NG"], [null, 170, null], false, false, false, null);
/** 休職 */

export var 休職 = new KintaiBunrui("休職", "休職", "休職", "休職期間の場合、自動的に選択されます。", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 1010, [1], [""], ["FM", "FG", "NM", "NG"], [null, 110, null], false, false, false, null);
/** 出勤停止 */

export var 出勤停止 = new KintaiBunrui("出勤停止", "出勤停止", "出勤停止", "出勤停止期間の場合、自動的に選択されます。", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 1020, [1], [""], ["FM", "FG", "NM", "NG"], [null, 120, null], false, false, false, null);
/** 私傷病積立休暇 */

export var 積立休暇 = new KintaiBunrui("積立休暇", "私傷病積立休暇", "積立休暇", "該当の期間の場合、自動的に選択されます。", [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], 1030, [1], [""], ["FM", "FG", "NM", "NG"], [null, 270, null], false, false, false, null);
/** A勤 */

export var A勤 = new KintaiBunrui("A勤", "A勤", "Ａ勤", "S-Port勤怠表でのみ指定可能な出勤区分（シフトパターン）です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], null, [], [""], [], [20, null, null], false, false, false, {
  open: new Minutes(540),
  close: new Minutes(1290),
  breakTime: new Minutes(150),
  weekdayActual: new Minutes(600)
});
/** B1勤 */

export var B1勤 = new KintaiBunrui("B1勤", "B1勤", "B1勤", "S-Port勤怠表でのみ指定可能な出勤区分（シフトパターン）です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], null, [], [""], [], [30, null, null], false, false, false, {
  open: new Minutes(1260),
  close: new Minutes(2010),
  breakTime: new Minutes(150),
  weekdayActual: new Minutes(240)
});
/** B2勤 */

export var B2勤 = new KintaiBunrui("B2勤", "B2勤", "B2勤", "S-Port勤怠表でのみ指定可能な出勤区分（シフトパターン）です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], null, [], [""], [], [40, null, null], false, false, false, {
  open: new Minutes(1260),
  close: new Minutes(2010),
  breakTime: new Minutes(150),
  weekdayActual: new Minutes(330)
});
/** C勤 */

export var C勤 = new KintaiBunrui("C勤", "C勤", "Ｃ勤", "S-Port勤怠表でのみ指定可能な出勤区分（シフトパターン）です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], null, [], [""], [], [50, null, null], false, false, false, {
  open: new Minutes(540),
  close: new Minutes(1080),
  breakTime: new Minutes(60),
  weekdayActual: new Minutes(480)
});
/** 明休 */

export var 明休 = new KintaiBunrui("明休", "明休", "明休", "S-Port勤怠表でのみ指定可能な出勤区分です。", [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030], null, [], [""], [], [60, null, null], false, false, false, null);
/** 全分類のリスト */

export var allBunruiList = [NullKintai, 公休, 平日, 出張, 直出, 直帰, 育介在宅, リ休, 有休, 前休, 後休, 時単休, 休出, 代休, 振出, 振休, 徹夜, 徹休, 特休, 婚休, 配出休, 忌休, 災休, 前特, 後特, 外出, 遅刻, 早退, 欠勤, 特勤, 生理休暇, 産休, 介休, 看護休暇, 看護時休, 午前看休, 午後看休, 介護休暇, 介護時休, 午前介休, 午後介休, 夏休, ポ休, 裁判休暇, 指定公休, 入前, 退後, 編集, 育休, 休職, 出勤停止, 積立休暇, A勤, B1勤, B2勤, C勤, 明休];
/** 全分類のマップ(コード -> 分類) */

export var allBunruiMap = {
  "": NullKintai,
  公休: 公休,
  平日: 平日,
  出張: 出張,
  直出: 直出,
  直帰: 直帰,
  育介在宅: 育介在宅,
  リ休: リ休,
  有休: 有休,
  前休: 前休,
  後休: 後休,
  時単休: 時単休,
  休出: 休出,
  代休: 代休,
  振出: 振出,
  振休: 振休,
  徹夜: 徹夜,
  徹休: 徹休,
  特休: 特休,
  婚休: 婚休,
  配出休: 配出休,
  忌休: 忌休,
  災休: 災休,
  前特: 前特,
  後特: 後特,
  外出: 外出,
  遅刻: 遅刻,
  早退: 早退,
  欠勤: 欠勤,
  特勤: 特勤,
  生理休暇: 生理休暇,
  産休: 産休,
  介休: 介休,
  看護休暇: 看護休暇,
  看護時休: 看護時休,
  午前看休: 午前看休,
  午後看休: 午後看休,
  介護休暇: 介護休暇,
  介護時休: 介護時休,
  午前介休: 午前介休,
  午後介休: 午後介休,
  夏休: 夏休,
  ポ休: ポ休,
  裁判休暇: 裁判休暇,
  指定公休: 指定公休,
  入前: 入前,
  退後: 退後,
  編集: 編集,
  育休: 育休,
  休職: 休職,
  出勤停止: 出勤停止,
  積立休暇: 積立休暇,
  A勤: A勤,
  B1勤: B1勤,
  B2勤: B2勤,
  C勤: C勤,
  明休: 明休
};
/** 全分類のうち、レギュラー勤怠で利用できる分類のリスト */

export var allRegularBunruiList = allBunruiList.filter(function (e) {
  return e.isForRegular;
});
/** 入力項目名 => その項目が有効になる分類のリストのマップ */

export var inputEnablerBunruiListMap = {
  transDay: [休出, 代休, 振出, 振休],
  arrival: [公休, 平日, 出張, 直出, 直帰, 育介在宅, リ休, 前休, 後休, 時単休, 休出, 振出, 徹夜, 徹休, 前特, 後特, 外出, 遅刻, 早退, 特勤, 看護時休, 午前看休, 午後看休, 介護時休, 午前介休, 午後介休, 指定公休, 編集],
  leave: [公休, 平日, 出張, 直出, 直帰, 育介在宅, リ休, 前休, 後休, 時単休, 休出, 振出, 徹夜, 徹休, 前特, 後特, 外出, 遅刻, 早退, 特勤, 看護時休, 午前看休, 午後看休, 介護時休, 午前介休, 午後介休, 指定公休, 編集],
  diff: [],
  rest: [外出, 特勤, 編集],
  actual: [外出, 特勤, 編集],
  flex: [外出, 特勤],
  holiday: [外出, 特勤, 編集],
  midnight: [外出, 特勤, 編集],
  allNightOff: [外出, 特勤],
  overWeekday: [外出, 特勤, 編集],
  overWeekdayMidnight: [外出, 特勤, 編集],
  overHoliday: [外出, 特勤, 編集],
  overHolidayMidnight: [外出, 特勤, 編集],
  spWeekday: [特勤],
  spWeekdayMidnight: [特勤],
  spHoliday: [特勤],
  spHolidayMidnight: [特勤],
  hourlyLeave: [時単休],
  hourlyNursingcare: [看護時休],
  hourlyFamilycare: [介護時休],
  off: [],
  offCount: [],
  overSum: [],
  liquidate: [],
  remarks: [公休, 平日, 出張, 直出, 直帰, 育介在宅, リ休, 有休, 前休, 後休, 時単休, 休出, 代休, 振出, 振休, 徹夜, 徹休, 特休, 婚休, 配出休, 忌休, 災休, 前特, 後特, 外出, 遅刻, 早退, 欠勤, 特勤, 生理休暇, 産休, 介休, 看護休暇, 看護時休, 午前看休, 午後看休, 介護休暇, 介護時休, 午前介休, 午後介休, 夏休, ポ休, 裁判休暇, 指定公休, 入前, 退後, 編集, 育休, 休職, 出勤停止, 積立休暇],
  unusedRestSunset: [公休, 平日, 出張, 直出, 直帰, 育介在宅, 前休, 時単休, 休出, 振出, 徹夜, 徹休, 前特, 外出, 遅刻, 特勤, 看護時休, 午前看休, 介護時休, 午前介休, 指定公休],
  unusedRestNight: [公休, 平日, 出張, 直出, 直帰, 育介在宅, 前休, 時単休, 休出, 振出, 徹夜, 徹休, 前特, 外出, 遅刻, 特勤, 看護時休, 午前看休, 介護時休, 午前介休, 指定公休],
  workplaces: [公休, 平日, 出張, 直出, 直帰, 育介在宅, 前休, 後休, 時単休, 休出, 振出, 徹夜, 徹休, 前特, 後特, 外出, 遅刻, 早退, 特勤, 看護時休, 午前看休, 午後看休, 介護時休, 午前介休, 午後介休, 指定公休, 編集]
};