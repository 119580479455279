import { 時単休 } from "@webkintai/bunrui";
import { observer } from "mobx-react";
import React from "react";

import { MVacationTotalItemsVM } from "../../monthly/components/MVacationTotalItemsVM";
import { MTotalRestPaidDaysKF } from "../../monthly/fields/vacationtotal/composite/MTotalRestPaidDaysKF";
import { MTotalTakenPaidDaysKF } from "../../monthly/fields/vacationtotal/composite/MTotalTakenPaidDaysKF";
import { MTotalRestPositiveDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalRestPositiveDaysKF";
import { MTotalRestRefreshDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalRestRefreshDaysKF";
import { MTotalRestSummerDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalRestSummerDaysKF";
import { MTotalTakenPositiveDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalTakenPositiveDaysKF";
import { MTotalTakenRefreshDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalTakenRefreshDaysKF";
import { MTotalTakenSpDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalTakenSpDaysKF";
import { MTotalTakenSummerDaysKF } from "../../monthly/fields/vacationtotal/daysonly/MTotalTakenSummerDaysKF";
import { MTotalTakenHourlyFamilycareKF } from "../../monthly/fields/vacationtotal/hourly/MTotalTakenHourlyFamilycareKF";
import { MTotalTakenHourlyLeaveKF } from "../../monthly/fields/vacationtotal/hourly/MTotalTakenHourlyLeaveKF";
import { MTotalTakenHourlyNursingcareKF } from "../../monthly/fields/vacationtotal/hourly/MTotalTakenHourlyNursingcareKF";
import { MTotalTakenOtherHoursKF } from "../../monthly/fields/vacationtotal/hourly/MTotalTakenOtherHoursKF";
import { MTotalTakenFamilycareDaysKF } from "../../monthly/fields/vacationtotal/plain/MTotalTakenFamilycareDaysKF";
import { MTotalTakenNursingcareDaysKF } from "../../monthly/fields/vacationtotal/plain/MTotalTakenNursingcareDaysKF";
import {
  MTotalTakenOtherDaysExcludingNuFaKF,
} from "../../monthly/fields/vacationtotal/plain/MTotalTakenOtherDaysExcludingNuFaKF";
import { MTotalTakenOtherDaysKF } from "../../monthly/fields/vacationtotal/plain/MTotalTakenOtherDaysKF";
import { MTotalTakenPlainPaidDaysKF } from "../../monthly/fields/vacationtotal/plain/MTotalTakenPlainPaidDaysKF";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";

@observer
export class PrintedVacationsTotals extends React.Component<{
  nendo: number;
  vv: VacantDayItemsVisibility;
  model: MVacationTotalItemsVM;
}> {
  public render() {
    const { nendo, vv, model } = this.props;

    if (vv.newDisplaySinceHourlyNF) {
      // 2022年度 〜
      const alldayColStyle: React.CSSProperties = { borderRightStyle: "dotted", borderRightColor: "#CCC" };
      const hourColStyle: React.CSSProperties = { borderLeftStyle: "none" };

      return (
        <table>
          <thead>
            <tr>
              <th colSpan={7}>{nendo}年度　休日・休暇取得日数</th>
              <th colSpan={1}>{nendo}年度　休日・休暇残日数</th>
            </tr>
            <tr>
              <th style={alldayColStyle} title="半休・全休のみの有休">
                有休
              </th>
              <th style={hourColStyle} title="時間休のみの有休">
                (H)
              </th>

              <th style={alldayColStyle} title="看護（全休）のみの有休">
                看護
              </th>
              <th style={hourColStyle} title="看護時休のみの有休">
                (H)
              </th>

              <th style={alldayColStyle} title="介護（全休）のみの有休">
                介護
              </th>
              <th style={hourColStyle} title="介護時休のみの有休">
                (H)
              </th>

              <th title="その他の特別休暇">その他</th>

              <th>有休(時間休含)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <MTotalTakenPlainPaidDaysKF mode="print" model={model} />
              </td>
              <td>
                <MTotalTakenHourlyLeaveKF mode="print" model={model} />
              </td>

              <td>
                <MTotalTakenNursingcareDaysKF mode="print" model={model} />
              </td>
              <td>
                <MTotalTakenHourlyNursingcareKF mode="print" model={model} />
              </td>

              <td>
                <MTotalTakenFamilycareDaysKF mode="print" model={model} />
              </td>
              <td>
                <MTotalTakenHourlyFamilycareKF mode="print" model={model} />
              </td>

              <td>
                <MTotalTakenOtherDaysExcludingNuFaKF mode="print" model={model} />
              </td>

              <td>
                <MTotalRestPaidDaysKF mode="print" model={model} />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return (
      <table>
        <thead>
          <tr>
            <th
              colSpan={
                (vv.nendoTotalRefreshDays ? 1 : 0) +
                (vv.nendoTotalPositiveDays ? 1 : 0) +
                1 +
                (vv.nendoTotalHourlyLeave ? 1 : 0) +
                (vv.nendoTotalSummerDays ? 1 : 0) +
                (vv.nendoTotalSpecialDays ? 1 : 0) +
                (vv.nendoTotalOtherDays ? 1 : 0) +
                (vv.nendoTotalOtherHours ? 1 : 0)
              }>
              {nendo}年度　休日・休暇取得日数
            </th>
            <th
              colSpan={
                (vv.nendoTotalRefreshDays ? 1 : 0) +
                (vv.nendoTotalPositiveDays ? 1 : 0) +
                1 +
                (vv.nendoTotalSummerDays ? 1 : 0)
              }>
              {nendo}年度　休日・休暇残日数
            </th>
          </tr>
          <tr>
            {vv.nendoTotalRefreshDays && <th>リフ休</th>}
            {vv.nendoTotalPositiveDays && <th>ポ休</th>}
            <th>有休</th>
            {vv.nendoTotalHourlyLeave && <th>{時単休.abbrName}</th>}
            {vv.nendoTotalSummerDays && <th>夏休</th>}
            {vv.nendoTotalSpecialDays && <th>特休</th>}
            {vv.nendoTotalOtherDays && <th>その他</th>}
            {vv.nendoTotalOtherHours && <th>その他時間休</th>}
            {vv.nendoTotalRefreshDays && <th>リフ休</th>}
            {vv.nendoTotalPositiveDays && <th>ポ休</th>}
            <th>有休</th>
            {vv.nendoTotalSummerDays && <th>夏休</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            {vv.nendoTotalRefreshDays && (
              <td>
                <MTotalTakenRefreshDaysKF mode="print" model={model} />
              </td>
            )}
            {vv.nendoTotalPositiveDays && (
              <td>
                <MTotalTakenPositiveDaysKF mode="print" model={model} />
              </td>
            )}
            <td>
              <MTotalTakenPaidDaysKF mode="print" model={model} />
            </td>
            {vv.nendoTotalHourlyLeave && (
              <td>
                <MTotalTakenHourlyLeaveKF mode="print" model={model} />
                (H)
              </td>
            )}

            {vv.nendoTotalSummerDays && (
              <td>
                <MTotalTakenSummerDaysKF mode="print" model={model} />
              </td>
            )}
            {vv.nendoTotalSpecialDays && (
              <td>
                <MTotalTakenSpDaysKF mode="print" model={model} />
              </td>
            )}

            {vv.nendoTotalOtherDays && (
              <td>
                <MTotalTakenOtherDaysKF mode="print" model={model} />
              </td>
            )}
            {vv.nendoTotalOtherHours && (
              <td>
                <MTotalTakenOtherHoursKF mode="print" model={model} />
                (H)
              </td>
            )}

            {vv.nendoTotalRefreshDays && (
              <td>
                <MTotalRestRefreshDaysKF mode="print" model={model} />
              </td>
            )}
            {vv.nendoTotalPositiveDays && (
              <td>
                <MTotalRestPositiveDaysKF mode="print" model={model} />
              </td>
            )}
            <td>
              <MTotalRestPaidDaysKF mode="print" model={model} />
            </td>
            {vv.nendoTotalSummerDays && (
              <td>
                <MTotalRestSummerDaysKF mode="print" model={model} />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    );
  }
}
