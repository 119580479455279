import {
  CompanyDashboardControllerApi,
  CompanyNendoDashboardResult,
  PersonalDashboardControllerApi,
  PersonalMonthlyDashboardResult,
  PersonalNendoDashboardResult,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { DashboardApi } from "./DashboardApi";

export class DashboardApiImpl extends DashboardApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getCompanyDashboardByNendo(nendo: number): Promise<CompanyNendoDashboardResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(CompanyDashboardControllerApi).getCompanyDashboardByNendo(nendo),
    );
  }

  public getPersonalDashboardByNendo(userId: string, nendo: number): Promise<PersonalNendoDashboardResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(PersonalDashboardControllerApi)
        .getPersonalDashboardByNendo(userId, nendo),
    );
  }

  public getPersonalDashboardByMonth(
    userId: string,
    year: number,
    month: number,
  ): Promise<PersonalMonthlyDashboardResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(PersonalDashboardControllerApi)
        .getPersonalDashboardByMonth(userId, year, month),
    );
  }
}
