import { KintaiProjectControllerApi, ProjectCloseEntity, ProjectCloseStatusListResult } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { KintaiProjectApi } from "./KintaiProjectApi";

export class KintaiProjectApiImpl extends KintaiProjectApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getProjectCloseStatus(nendo: number): Promise<ProjectCloseStatusListResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiProjectControllerApi).getProjectCloseStatusList(nendo),
    );
  }

  public saveProjectCloseStatus(projectCloseEntity: ProjectCloseEntity[]): Promise<Response> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiProjectControllerApi)
        .saveProjectCloseStatusList(projectCloseEntity),
    );
  }
}
