import { Minutes } from "@webkintai/core";
import { action, computed } from "mobx";
import moment from "moment";

import { lazyInject } from "../../../../didecorators";
import { SaburokuEntry } from "../../../../models/saburoku/SaburokuEntry";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { SaburokuPageTableRowVM } from "./SaburokuPageTable";

export class SaburokuPageTableRowVMImpl implements SaburokuPageTableRowVM {
  @lazyInject(AppRouter)
  public appRouter: AppRouter;

  constructor(private readonly model: typeof SaburokuEntry.Type) {}

  @computed
  get id() {
    return this.model.id;
  }

  @computed
  get userId() {
    return this.model.userId;
  }

  @computed
  get userName() {
    return this.model.userName;
  }
  @computed
  get rankName() {
    return this.model.rankName;
  }

  @computed
  get depName() {
    return this.model.depName;
  }

  @computed
  get thisMonth() {
    const m = this.model.thisMonth;
    return {
      lawOver: m.lawOver,
      lawOverWarned: isWarnedOverCalc(m.lawOver),
      lawOverCalc: m.lawOverCalc,
      lawOverCalcWarned: isWarnedOverCalc(m.lawOverCalc),
      sundayOnCount: m.sundayOnCount,

      onNavigateToUserKintai: () => this.onNavigateToUserKintai(0),
    };
  }

  @computed
  get lastMonth() {
    const m = this.model.lastMonth;
    return {
      lawOver: m.lawOver,
      lawOverWarned: isWarnedOverCalc(m.lawOver),
      sundayOnCount: m.sundayOnCount,

      onNavigateToUserKintai: () => this.onNavigateToUserKintai(-1),
    };
  }

  @computed
  get monthBeforeLast() {
    const m = this.model.monthBeforeLast;
    return {
      lawOver: m.lawOver,
      lawOverWarned: isWarnedOverCalc(m.lawOver),
      onNavigateToUserKintai: () => this.onNavigateToUserKintai(-2),
    };
  }

  @computed
  get lawOverUntilLastMonth() {
    return this.model.lawOverUntilLastMonth;
  }
  @computed
  get lawOverUntilLastMonthWarned() {
    return isWarnedOverUntilLastMonth(this.lawOverUntilLastMonth);
  }

  @computed
  get overFortyFiveUntilLastMonthCount() {
    return this.model.overFortyFiveCountUntilLastMonth;
  }
  @computed
  get overFortyFiveUntilLastMonthCountWarned() {
    return this.overFortyFiveUntilLastMonthCount >= 6;
  }

  @action.bound
  public onNavigateToUserKintai(monthDelta: number) {
    this.appRouter.navigate(
      paths.kintai
        .ofUser(this.userId)
        .ofDate(
          moment(this.model.targetMonth)
            .add(monthDelta, "months")
            .toDate(),
        )
        .index(),
    );
  }
}

const isWarnedOverCalc = (m: Minutes | undefined) => {
  return !!(m && m.minutes >= 60 * 45);
};

const isWarnedOverUntilLastMonth = (m: Minutes | undefined) => {
  return !!(m && m.minutes >= 60 * 360);
};
