import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type AppApproveTextKFProps = {
  model: AppApproveTextKFVM;
} & CellFormPrintAdaptable;

export interface AppApproveTextKFVM {
  appApproveText: KintaiInputItemState<string>;
}

export const AppApproveTextKF = observer((props: AppApproveTextKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.appApproveText);

  return <TextField multiline={true} {...props} label="コメント（却下する際はコメントしてください）" {...itemState} />;
});
