import { observer } from "mobx-react";
import React from "react";

import { HasNoChangeField } from "../../../common/form/HasNoChangeLabel";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { PHPagGridRowVM } from "./PHPageGrid";

@observer
export class PHChangedCell extends React.Component<{ model: PHPagGridRowVM }> {
  public render() {
    const row = this.props.model;
    return <HasNoChangeField mode="cell" cellPosition={NULL_CELL_POSITION} hasNoChange={row.hasNoChange} />;
  }
}
