import {
  DailyApplicationApprovalEntity,
  DailyApplicationRequestEntity,
  KintaiApplicationsControllerApi,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { KintaiAppApi } from "./KintaiAppApi";

export class KintaiAppApiImpl extends KintaiAppApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getDailyAppStatus(userId: string, date: Date) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiApplicationsControllerApi)
        .getDailyApplication(userId, date.getFullYear(), date.getMonth() + 1, date.getDate()),
    );
  }

  public requestApp(userId: string, date: Date, payload: DailyApplicationRequestEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiApplicationsControllerApi)
        .requestDailyApplication(userId, date.getFullYear(), date.getMonth() + 1, date.getDate(), payload),
    );
  }
  public cancelApp(userId: string, date: Date) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiApplicationsControllerApi)
        .cancelDailyApplication(userId, date.getFullYear(), date.getMonth() + 1, date.getDate()),
    );
  }

  public approveApp(userId: string, date: Date, payload: DailyApplicationApprovalEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiApplicationsControllerApi)
        .approveDailyApplication(userId, date.getFullYear(), date.getMonth() + 1, date.getDate(), payload),
    );
  }

  public rejectApp(userId: string, date: Date, payload: DailyApplicationApprovalEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiApplicationsControllerApi)
        .rejectDailyApplication(userId, date.getFullYear(), date.getMonth() + 1, date.getDate(), payload),
    );
  }
}
