import { observer } from "mobx-react";
import React from "react";

import { ErrorOverlay } from "../../../form/ErrorOverlay";

export interface PJRHeaderPjNameKFProps {
  model: PJRHeaderPjNameKFVM;
  mode: "print" | "form" | "cell";
}

export interface PJRHeaderPjNameKFVM {
  pjName: string;
  headerErrors: string[];
  headerWarnings: string[];
}

export const PJRHeaderPjNameKF = observer((props: PJRHeaderPjNameKFProps) => {
  const { model } = props;

  const name = model.pjName || "（プロジェクト名：なし）";

  if (props.mode === "print") {
    return <>{name}</>;
  }

  return (
    <ErrorOverlay mode={props.mode} errors={model.headerErrors} warnings={model.headerWarnings}>
      <span>{name}</span>
    </ErrorOverlay>
  );
});
