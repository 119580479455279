import classNames from "classnames";

export const cellStyle = `
display: block;
width: 100%;
height: 100%;
font-family: monospace;

&.Changed {
  background-color: yellow;
}
`;

export const hasNoChangeClassName = "HasNoChange";

export const componentCommonClasses = (props: { hasNoChange?: boolean; disabled?: boolean }) =>
  classNames(props.hasNoChange === false && hasNoChangeClassName, props.disabled === true && DisabledClassName);

export const hasNoChangeStyle = `
&.${hasNoChangeClassName} {
  background-color: rgba(255, 255, 0, 0.6) !important;
  
  input, textarea {
    background-color: rgba(255, 255, 0, 0.6);
  }
}
`;
export const DisabledClassName = "ComponentDisabled";

export const DisabledColor = "rgba(0, 0, 0, 0.05)";

export const cellFormStyle = `
border: 0;
border-style: none;
border-radius: 0;
background-color: rgba(255, 255, 255, 0);

&::placeholder {
  color: #ccc;
  font-size: 80%;
}

&:disabled, &.${DisabledClassName} {
  background-color: ${DisabledColor};
  color: #333;
  cursor: not-allowed;
}

`;

export const formStyle = `
&.${DisabledClassName} {
  background-color: ${DisabledColor};
  color: #333;
  cursor: not-allowed;
}
`;

export const formLabelStyle = `
display: block;
white-space: nowrap;
font-size: 8pt;
color: #666;
`;
