import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

import { mediaQueryNotSmall, mediaQuerySmall } from "../responsive/Responsive";

const PageTileContainer = styled.div`
  flex: 1 auto;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

const PageHeader = styled.div`
  flex: 0 0 auto;

  padding: 0;
  margin: 0;
  box-shadow: 0;
  background-color: #f3f3f3;
  border-bottom: 1px solid #ddd;

  button {
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  ${mediaQuerySmall} {
    padding-top: 0;
    padding-bottom: 0;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 1px solid #ccc;

    button {
      padding: 0.6ex 0.8ex 0.6ex 0.8ex !important;
    }
  }
  ${mediaQueryNotSmall} {
    button {
      height: 30px;
    }
  }
`;

const PageBody = styled.div`
  flex: 1 auto;

  width: 100%;
  height: 100%;

  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const PageTile: React.FunctionComponent<{ className?: string; header?: React.ReactNode }> = props => (
  <PageTileContainer className={classNames(props.className)}>
    {props.header && <PageHeader>{props.header}</PageHeader>}
    <PageBody>{props.children}</PageBody>
  </PageTileContainer>
);
