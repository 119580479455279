import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { GridTable } from "../../../common/table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { kintaiListPageGridItemsBody, kintaiListPageGridItemsHeader } from "../common/CommonKintaiListPageGridItems";
import { NoRegKintaiListPageGridRowVM } from "./NoRegKintaiListPageGridRowVM";

export interface NoRegKintaiListPageGridVM {
  noRegRows: NoRegKintaiListPageGridRowVM[];
}

@observer
export class NoRegKintaiListPageGrid extends React.Component<{ model: NoRegKintaiListPageGridVM }> {
  public render() {
    const { noRegRows: rows } = this.props.model;

    return (
      <GridTable
        rowIdGenerator={this.rowIdGenerator}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { noRegRows: rows } = this.props.model;

    return rows[rowIndex - 1].userId;
  };

  @computed
  private get headerColumns(): TableColumnDef[] {
    const { noRegRows: rows } = this.props.model;

    return kintaiListPageGridItemsHeader(rows, false);
  }

  @computed
  private get bodyColumns(): TableColumnDef[] {
    const { noRegRows: rows } = this.props.model;
    const firstRow = rows[0]!;
    return kintaiListPageGridItemsBody(rows, true);
  }
}
