import styled from "@emotion/styled";
import React from "react";

import { ErrorOverlay } from "./ErrorOverlay";
import { formLabelStyle } from "./FormCommon";

const Label = styled.label<{ fullWidth?: boolean }>`
  display: inline-block;
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
`;

const LabelTitle = styled.span`
  ${formLabelStyle};
`;

export interface FormLabelProps {
  label?: React.ReactNode;
  fullWidth?: boolean;

  // エラー表示用
  disabled?: boolean;
  errors?: string[];
  warnings?: string[];
  infos?: string[];
}

export const FormLabel: React.FunctionComponent<FormLabelProps> = props => {
  let label = props.label && <LabelTitle>{props.label}</LabelTitle>;
  if (!props.disabled) {
    label = (
      <ErrorOverlay
        mode={"form"}
        disabled={props.disabled}
        errors={props.errors}
        warnings={props.warnings}
        infos={props.infos}>
        {label}
      </ErrorOverlay>
    );
  }

  return (
    <Label fullWidth={props.fullWidth}>
      {label}
      {props.children}
    </Label>
  );
};
