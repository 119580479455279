import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { ExportableTable } from "../../common/export-table/ExportableTable";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ScrollablePane } from "../../common/ScrollablePane";
import { ExportPageRibbon, ExportPageRibbonVM } from "./ribbon/ExportPageRibbon";
import { ExportPageTable, ExportPageTableVM } from "./table/ExportPageTable";

export interface ExportPageVM {
  ribbonModel: ExportPageRibbonVM;
  tableModel: ExportPageTableVM;
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
}

@observer
export class ExportPage extends React.Component<{ model: ExportPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<ExportPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("勤怠データ SBエクスポート")}</title>
        </Helmet>

        <ScrollablePane>
          {model.loadingStatus !== "loaded" && (
            <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
          )}
          {model.loadingStatus === "loaded" && (
            <ExportableTable title="（取扱要注意）SBエクスポートデータ">
              {renderedOn => <ExportPageTable renderedOn={renderedOn} model={model.tableModel} />}
            </ExportableTable>
          )}
        </ScrollablePane>
      </PageRibbon>
    );
  }
}
