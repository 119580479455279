import { InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { applyBoundary, inputIntegerHandler } from "../../../utils/react";
import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { cellFormStyle, cellStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export const DayCountField: React.FC<DayCountFieldProps> = props => {
  const { mode, label, value, min, max, disabled, unit, onChange } = {
    min: 0,
    max: 99,
    disabled: false,
    unit: 1,
    ...props,
  };

  if (mode === "print") {
    return <>{value}</>;
  }

  const onChangeHandler = inputIntegerHandler(min, max, onChange);
  const onKeyPressHandler = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (!onChange) {
      return;
    }
    const key = ev.which || ev.keyCode;
    switch (key) {
      case 38:
        onChange(applyBoundary((value || 0) + unit, min, max));
        break;
      case 40:
        onChange(applyBoundary((value || 0) - unit, min, max));
        break;
    }
  };

  if (mode === "cell") {
    return <Cell className={FocusMarkerClassName} disabled={disabled} value={value || ""} onChange={onChangeHandler} />;
  }

  const componentWidth = `${max}`.length;

  return (
    <FormLabel label={label}>
      <div style={{ width: `${componentWidth + 0.5}em` }}>
        <Form
          disabled={disabled}
          small={true}
          value={`${value}`}
          onChange={onChangeHandler}
          onKeyDown={onKeyPressHandler}
        />
      </div>
    </FormLabel>
  );
};

export type DayCountFieldProps = (
  | {
      label?: string;
      value?: number;
      min?: number;
      max?: number;
      disabled?: boolean;
      onChange?: (value: number) => void;
    }
  | {
      allowEmpty: true;
      label?: string;
      value?: number | undefined;
      min?: number;
      max?: number;
      disabled?: boolean;
      onChange?: (value: number | undefined) => void;
    }
) &
  CellFormPrintAdaptable;

const Cell = styled.input`
  ${cellStyle} ${cellFormStyle}
  text-align: right;
`;

const Form = styled(InputGroup)`
  display: inline-block;
  margin-right: 0.5ex;
`;
