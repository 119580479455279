import styled from "@emotion/styled";
import React from "react";

import { mediaQuerySmall } from "../../responsive/Responsive";

const Component = styled.div`
  flex: 1 auto;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  flex: 0 0 auto;
  overflow: hidden;

  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
`;

const Body = styled.div`
  flex: 1 1 0;
  overflow-y: auto;
  padding-top: 1ex;
`;

export const DailyKintaiFormLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  padding: 1ex;
`;

export const DailyKintaiFormLine4 = styled(DailyKintaiFormLine)`
  & > * {
    width: 25%;
    padding-right: 0.3ex;
    & > * {
      width: 100%;
    }
  }
`;

export const ArrivalLeaveFormLine = styled(DailyKintaiFormLine4)`
  input {
    font-size: 10pt !important;
  }
`;

export const DailyKintaiFormLine2 = styled(DailyKintaiFormLine)`
  ${mediaQuerySmall} {
    padding: 0.2ex;
  }

  & > * {
    width: 50%;
    padding-right: 1ex;
    & > * {
      width: 100%;
    }
  }
`;

export const DailyKintaiFormTabContainer = styled(DailyKintaiFormLine)`
  .bp3-tabs {
    flex: 1 auto;

    .bp3-tab-list {
      justify-content: space-evenly;
    }
  }
`;

export const EntireDailyKintaiFormLineChild = styled.div(props => ({
  width: "100%",
}));

export interface DailyKintaiFormPaneProps {
  header: React.ReactNode;
  onSwipe: (direction: 1 | -1) => void;
}

const SwipeSymbol = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -150px;
  opacity: 0;
  transition: all 0.5s;
  z-index: 10;
`;

const SwipeSymbolContainer = styled.div`
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  margin: auto auto;
  height: 50px;
  line-height: 50px;
  font-size: 18pt;
  width: 300px;
  text-align: center;
`;

/**
 * 勤怠画面左に出る部分
 */
export const DailyKintaiFormPane: React.FunctionComponent<DailyKintaiFormPaneProps> = props => {
  const [startPos, setStartPos] = React.useState(0);
  const [moveDelta, setDelta] = React.useState(0);
  const deltaBorder = 150;

  const borderIsOver = () => Math.abs(moveDelta) > deltaBorder;

  const onTouchStart = React.useCallback(
    (ev: React.TouchEvent<HTMLDivElement>) => {
      setStartPos(ev.changedTouches[0].pageX);
    },
    [setStartPos],
  );

  const onTouchMove = React.useCallback(
    (ev: React.TouchEvent<HTMLDivElement>) => {
      setDelta(startPos - ev.changedTouches[0].pageX);
    },
    [startPos, setDelta],
  );

  const onTouchEnd = React.useCallback(
    (ev: React.TouchEvent<HTMLDivElement>) => {
      if (borderIsOver()) {
        const direction = moveDelta > 0 ? 1 : -1;
        props.onSwipe(direction);
      }
      setDelta(0);
    },
    [moveDelta, setDelta, props.onSwipe],
  );

  return (
    <Component onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
      <SwipeSymbol
        style={{
          visibility: borderIsOver() ? "visible" : "hidden",
          opacity: borderIsOver() ? 1 : 0,
        }}>
        <SwipeSymbolContainer>{moveDelta > 0 ? "翌日 >>" : moveDelta < 0 ? "<< 前日" : ""}</SwipeSymbolContainer>
      </SwipeSymbol>
      <Header>{props.header}</Header>
      <Body>{props.children}</Body>
    </Component>
  );
};
