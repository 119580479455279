import { types } from "mobx-state-tree";

export const apiValueToReceptionStatus = (apiValue: string | undefined) =>
  (({
    "0": "未対応",
    "1": "予約済",
    "2": "受診済",
    "3": "受診無",
  }[apiValue || ""] || undefined) as (typeof ReceptionStatus.Type | undefined));

export const receptionStatusToApiValue = (screenValue: typeof ReceptionStatus.Type | undefined) =>
  ({
    未対応: "0",
    予約済: "1",
    受診済: "2",
    受診無: "3",
  }[screenValue || ""] || undefined);

export const ReceptionStatusValues: ["予約済", "未対応", "受診済", "受診無"] = ["予約済", "未対応", "受診済", "受診無"];

export const ReceptionStatus = types.enumeration(ReceptionStatusValues);

export type ReceptionStatusType = typeof ReceptionStatus.Type;
