import React from "react";

export interface MSetAllDaysDoorLeaveVM {
  onSetAllDaysDoorLeave: () => void;
}

export const MSetAllDaysDoorLeave: React.FC<{
  model: MSetAllDaysDoorLeaveVM;
}> = ({ model }) => {
  return <button onClick={model.onSetAllDaysDoorLeave}>一括</button>;
};
