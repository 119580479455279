import { Days, HourBasedDays, Minutes } from "@webkintai/core";
import { IModelType, types } from "mobx-state-tree";

import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { valueOnlyKintaiInputItemState } from "../kintaiinput/ValueOnlyKintaiItem";
import { RegularMonthlyTotalValues } from "./RegularMonthlyTotalValues";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const RegularMonthlyTotal = types
  .model("RegularMonthlyTotal", {
    origin: RegularMonthlyTotalValues,
    computed: RegularMonthlyTotalValues,
  })
  .views(self => {
    return {
      get mTotalLeaveDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.leaveDays);
      },
      get mTotalHourlyLeave(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyLeave);
      },
      get mTotalPlainPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.plainPaidDays);
      },
      get mTotalAbsenceDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.absenceDays);
      },
      get mTotalActual(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.actual);
      },
      get mTotalAllNightCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.allNightCount);
      },
      get mTotalAllNightOff(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.allNightOff);
      },
      get mTotalAllNightOffDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.allNightOffDays);
      },
      get mTotalCareDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.careDays);
      },
      get mTotalChildcareDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.childcareDays);
      },
      get mTotalCompDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.compDays);
      },
      get mTotalDiff(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.diff);
      },
      get mTotalFamilycareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.familycareDays);
      },
      get mTotalHourlyFamilycare(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyFamilycare);
      },
      get mTotalFlex(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.flex);
      },
      get mTotalGivenDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.givenDays);
      },
      get mTotalGivenHours(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.givenHours);
      },
      get mTotalHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.holiday);
      },
      get mTotalHolidayDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.holidayDays);
      },
      get mTotalHolidaySum(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.holidaySum);
      },
      get mTotalInjuriesDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.injuriesDays);
      },
      get mTotalJudgmentDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.judgmentDays);
      },
      get mTotalLawBasis(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawBasis);
      },
      get mTotalLawOver(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOver);
      },
      get mTotalLawOverCalc(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOverCalc);
      },
      get mTotalLawOverNine(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOverNine);
      },
      get mTotalLawOverNendoSum(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOverNendoSum);
      },
      get mTotalLawOverToday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOverToday);
      },
      get mTotalLawOverUntilLastMonthSum(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.lawOverUntilLastMonthSum);
      },
      get mTotalLiquidate(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.liquidate);
      },
      get mTotalLiquidateSum(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.liquidateSum);
      },
      get mTotalMaternityDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.maternityDays);
      },
      get mTotalMenstruationDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.menstruationDays);
      },
      get mTotalMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.midnight);
      },
      get mTotalMidnightCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.midnightCount);
      },
      get mTotalNonAttendanceDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.nonAttendanceDays);
      },
      get mTotalNormalDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.normalDays);
      },
      get mTotalNursingcareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.nursingcareDays);
      },
      get mTotalHourlyNursingcare(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyNursingcare);
      },
      get mTotalOff(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.off);
      },
      get mTotalOffCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.offCount);
      },
      get mTotalOver(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.over);
      },
      get mTotalOverFortyFiveUntilLastMonthCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.overFortyFiveUntilLastMonthCount);
      },
      get mTotalOverHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overHoliday);
      },
      get mTotalOverHolidayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overHolidayMidnight);
      },
      get mTotalOverSixtyFlexHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyFlexHoliday);
      },
      get mTotalOverSixtyHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyHoliday);
      },
      get mTotalOverSixtyHolidayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyHolidayMidnight);
      },
      get mTotalOverSixtyLiquidate(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyLiquidate);
      },
      get mTotalOverSixtyWeekday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyWeekday);
      },
      get mTotalOverSixtyWeekdayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overSixtyWeekdayMidnight);
      },
      get mTotalOverNendoSum(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overNendoSum);
      },
      get mTotalOverWeekday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overWeekday);
      },
      get mTotalOverWeekdayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overWeekdayMidnight);
      },
      get mTotalPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.paidDays);
      },
      get mTotalOtherDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherDays);
      },
      get mTotalOtherDaysExcludingNuFa(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherDaysExcludingNuFa);
      },
      get mTotalOtherHours(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherHours);
      },
      get mTotalPaidAllDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.paidAllDays);
      },
      get mTotalPaidHalfDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.paidHalfDays);
      },
      get mTotalPositiveDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.positiveDays);
      },
      get mTotalRefreshDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.refreshDays);
      },
      get mTotalRest(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.rest);
      },
      get mTotalSickoutDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.sickoutDays);
      },
      get mTotalSpDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.spDays);
      },
      get mTotalSpHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.spHoliday);
      },
      get mTotalSpHolidayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.spHolidayMidnight);
      },
      get mTotalSpWeekday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.spWeekday);
      },
      get mTotalSpWeekdayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.spWeekdayMidnight);
      },
      get mTotalSummerDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.summerDays);
      },
      get mTotalSundayOnCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.sundayOnCount);
      },
      get mTotalSuspensionDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.suspensionDays);
      },
      get mTotalTransHolidays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.transHolidays);
      },
      get mTotalTransOffDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.transOffDays);
      },
      get mTotalTransOnDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.transOnDays);
      },
      get mTotalWorkplaceZaitakuDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.workplaceZaitakuDays);
      },
      get mTotalWorkplaceSyussyaDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.workplaceSyussyaDays);
      },
      get mTotalWorkplaceSonotaDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.workplaceSonotaDays);
      },
    };
  });

export type RegularMonthlyTotalType = typeof RegularMonthlyTotal.Type;
