import { minutesSub } from "@webkintai/core";
import { getParentOfType, types } from "mobx-state-tree";

import { MonthlyKintai } from "../MonthlyKintai";
import { PJReport } from "./PJReport";
import { reduceMinutes } from "./PJReportDailyCell";

const model = types
  .model("PJReportTotalPillarDay", {
    date: types.Date,
    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string),
  })
  .views(self => {
    function getSameDayActual() {
      return getParentOfType(self, MonthlyKintai).regularKintai!.getAt(self.date).actual.value;
    }

    function getSameDayPillarsActual() {
      return reduceMinutes(getParentOfType(self, PJReport).livingPillars.map(it => it.getAt(self.date)!.total));
    }

    return {
      get actual() {
        return getSameDayActual();
      },
      get populated() {
        return getSameDayPillarsActual();
      },
      get notPopulated() {
        return minutesSub(getSameDayActual(), getSameDayPillarsActual());
      },
    };
  });
export const PJReportTotalPillarDay: PJReportTotalPillarDayModelType = model;
type PJReportTotalPillarDayInferredType = typeof model;
export interface PJReportTotalPillarDayModelType extends PJReportTotalPillarDayInferredType {}
