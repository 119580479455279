import React from "react";

export const RemakrsWrapperTd: React.FunctionComponent<{}> = props => (
  <td style={{ width: "10em" }}>
    <pre
      style={{
        textAlign: "left",
        fontSize: "80%",
        lineHeight: "100%",
        width: "100%",
        margin: "0",
        padding: "0",
        maxHeight: "2rem",
        whiteSpace: "pre-wrap",
        overflow: "hidden",
      }}>
      {props.children}
    </pre>
  </td>
);
