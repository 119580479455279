import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

const leftMargin = 10;
const tlBorderColor = "#cc9933";
const tlBorderWidth = 4;
export const TimelineList = styled.ul`
  margin-left: ${leftMargin}px;
  border-left: ${tlBorderWidth}px solid ${tlBorderColor};
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const tlElmPadLeft = 10;
const tlDotSize = 5;
export const TimelineElement = styled.li<{ targeted?: boolean }>`
  margin-left: ${leftMargin}px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0;
  padding-bottom: 10px;
  padding-right: 5px;
  min-height: 8em;
  padding-left: 10px;
  padding-right: 5px;
  list-style-type: none;
  ${({ targeted }) =>
    targeted === false &&
    `
    opacity: 0.5;
    &:hover { opacity: inherit;}
  `}

  &::before {
    position: relative;
    display: block;
    left: ${-14 - tlElmPadLeft}px;
    top: 10px;
    background-color: #fff;
    content: "";
    width: ${tlDotSize}px;
    height: ${tlDotSize}px;
    box-shadow: 0 0 0 4px ${tlBorderColor};
    border-radius: 50%;
  }
`;

export const TimelineHeader = styled.h3`
  padding: 0;
  margin: 0;
  margin-bottom: 1em;
`;

const TimelineTimeBlock = styled.span`
  display: block;
  color: #999;
  font-size: 70%;
  font-weight: normal;
`;

export const TimelineTime = (props: { date?: Date }) =>
  props.date ? <TimelineTimeBlock>{moment(props.date).format("YYYY/MM/DD (dd) HH:mm:ss")}</TimelineTimeBlock> : <></>;
