import { UsersResult } from "@webkintai/api";
import { keyBy } from "lodash-es";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";

import { UsersApi } from "../../services/api/UsersApi";
import { fromApiDate, undefinedIfNull } from "../../utils/api";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { Account } from "./Account";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("Accounts", {
      loadingStatus: types.optional(LoadingStatus, "loading"),
      accounts: types.map(Account),
    })
    .actions(self => {
      const usersApi = () => getDI(self, UsersApi);

      return {
        loadIfNeeded() {
          return self.loadingStatus === "loaded" ? new Promise(done => done()) : this.load();
        },
        load: flow(function*() {
          self.loadingStatus = "loading";
          const result: UsersResult = yield usersApi().getUsers();
          const values = Array.from(result.users, u => ({
            userId: u.userId,
            userName: u.userName || "",
            depCode: undefinedIfNull(u.depCode),
            rankCode: undefinedIfNull(u.rankCode),
            mailAddress: u.mailAddress || "",
            entryDate: fromApiDate(u.entryDate),
            leaveDate: fromApiDate(u.leaveDate),
            roles: u.roles.map(role => role.value),
          }));
          applySnapshot(self.accounts, keyBy(values, "userId"));

          self.loadingStatus = "loaded";
        }),
      };
    }),
  {},
);

export type AccountsType = typeof Accounts.Type;

export const AccountsSymbol = "Accounts_Symbol";
export const Accounts: AccountsModelType = model;
type AccountsInferredType = typeof model;
export interface AccountsModelType extends AccountsInferredType {}
