import { IconName } from "@blueprintjs/core";

interface UiDef {
  title: string;
  icon: IconName;
}

export const kinmuTypeUiDefs: { [key: string]: UiDef } = {
  Flex: {
    title: "FLEX",
    icon: "walk",
  },
  "Non-Flex": {
    title: "一般勤務",
    icon: "time",
  },
  "S-Port": {
    title: "S-Port",
    icon: "tint",
  },
};

export const kinmuTypeNoEntryUiDef: UiDef = {
  title: "未入力",
  icon: "issue",
};
