import { max, min, range } from "lodash-es";
import moment from "moment";

import { dateOf, findDayOfWeek, firstMonthDay, lastMonthDay } from "./date";

export const monthlyCalendarStartSunday = (month: Date) => findDayOfWeek(firstMonthDay(month), 0, -1);

export const monthlyCalendarFinishingSaturday = (month: Date) => findDayOfWeek(lastMonthDay(month), 5, 1);

export const weekdayNames = () => ["日", "月", "火", "水", "木", "金", "土"];

export const weekdayName = (date: Date) => weekdayNames()[date.getDay()];

export function monthlyCalendarDateList(month: Date): Date[][] {
  const result: Date[][] = [];

  let sunday = monthlyCalendarStartSunday(month);
  const lastDay = moment(monthlyCalendarFinishingSaturday(month));
  while (lastDay.diff(sunday, "days") >= 0) {
    result.push(
      range(0, 7).map(d =>
        moment(sunday)
          .add(d, "days")
          .toDate(),
      ),
    );
    sunday = moment(sunday)
      .add(7, "days")
      .toDate();
  }

  return result;
}

export const getNendoMonths = (year: number) => range(0, 12).map(m => dateOf(year, m + 4, 1));

export const getNendoAsDate = (year: number) => new Date(`${year}/04/01`);

export const getNendo = (d: Date) =>
  moment(d)
    .subtract(3, "months")
    .toDate()
    .getFullYear();

export const getNendoMonthOf = (nendo?: number | null, month?: number | null): Date => {
  if (!nendo) {
    return moment()
      .month(4 - 1)
      .date(1)
      .startOf("day")
      .toDate();
  }

  if (!month) {
    return moment()
      .year(nendo)
      .month(4 - 1)
      .date(1)
      .startOf("day")
      .toDate();
  }

  return moment()
    .year(nendo + (month <= 3 ? 1 : 0))
    .month(month - 1)
    .date(1)
    .startOf("day")
    .toDate();
};

/**
 * 指定の月が年度の4月から数えた場合のどこに位置するか返す
 *
 * e.g. 4月 => 0, 12月 => 8, 3月 => 11
 *
 * @param month 月
 */
export const getNendoMonthIndex = (month: Date) => {
  const sub = month.getMonth() + 1 - 4;
  return sub < 0 ? sub + 12 : sub;
};

export const prevMonth = (d: Date) =>
  moment(d)
    .add(-1, "months")
    .toDate();

export function* daysOfMonth(month: Date) {
  let d = firstMonthDay(month);
  const m = month.getMonth();
  while (m === d.getMonth()) {
    yield d;
    d = moment(d)
      .add(1, "days")
      .toDate();
  }
}

/**
 * relative が subject より within (unit) しか離れない範囲になるように調整する。
 */
export function keepDateDistanceWithin(subject: Date, relative: Date, within: number, unit: "days" | "months") {
  if (subject.getTime() === relative.getTime()) {
    return relative;
  }

  const relativeIsFuture = subject.getTime() < relative.getTime();

  if (relativeIsFuture) {
    return min([
      relative,
      moment(subject)
        .add(within, unit)
        .toDate(),
    ])!;
  } else {
    return max([
      relative,
      moment(subject)
        .add(-within, unit)
        .toDate(),
    ])!;
  }
}
