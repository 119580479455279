import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalRestSummerDaysKFProps = {
  model: MTotalRestSummerDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalRestSummerDaysKFVM {
  mTotalRestSummerDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalRestSummerDaysKF = observer((props: MTotalRestSummerDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalRestSummerDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalRestSummerDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
