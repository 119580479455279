import { IModelType, types } from "mobx-state-tree";

import { KintaiApplicationDay } from "./KintaiApplicationDay";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("KintaiApplications", {
    days: types.array(KintaiApplicationDay),
  })
  .views(self => {
    return {
      getDayOf(date: Date) {
        const idx = date.getDate() - 1;
        return self.days[idx]!;
      },
    };
  });

export type KintaiApplicationsType = typeof KintaiApplications.Type;
export const KintaiApplications: KintaiApplicationsModelType = model;
type KintaiApplicationsInferredType = typeof model;
export interface KintaiApplicationsModelType extends KintaiApplicationsInferredType {}
