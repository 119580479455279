import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalHourlyLeaveKFProps = {
  model: MTotalHourlyLeaveKFVM;
} & CellFormPrintAdaptable;

export interface MTotalHourlyLeaveKFVM {
  mTotalHourlyLeave: KintaiInputItemState<HourBasedDays>;
}

export const MTotalHourlyLeaveKF = observer((props: MTotalHourlyLeaveKFProps) => {
  const { model } = props;
  const itemState = model.mTotalHourlyLeave;

  return (
    <HourBasedDaysCountField
      showAsDateCount={false}
      {...props}
      label="mTotalHourlyLeaveKF"
      {...itemState}
      onChange={undefined}
    />
  );
});
