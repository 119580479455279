import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { GridTable, x } from "../../../common/table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { InboxPublishDateCell } from "./InboxPublishDateCell";
import { InboxReadFlagCell } from "./InboxReadFlagCell";
import { InboxSubjectCell } from "./InboxSubjectCell";

export interface InboxPageGridVM {
  rows: InboxPageGridRowVM[];
}

export interface InboxPageGridRowVM {
  id: string;
  active: boolean;
  subject: string;
  at: Date;
  read: boolean;
  onOpenMessage: () => void;
  onToggleRead: () => void;
}

export const inboxRowStyle = (row: InboxPageGridRowVM) =>
  ({
    fontWeight: row.read ? undefined : "bold",
    backgroundColor: row.active ? "#ffc" : undefined,
  } as React.CSSProperties);

@observer
export class InboxPageGrid extends React.Component<{ model: InboxPageGridVM }> {
  public render() {
    const { rows } = this.props.model;

    return (
      <GridTable
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowIdGenerator={this.rowIdGenerator}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => this.props.model.rows[rowIndex - 1].id;

  @computed
  private get headerColumns(): TableColumnDef[] {
    const { rows } = this.props.model;
    return [
      {
        id: "既読未読",
        width: x("XXXXX"),
        renderHeader: () => "未読",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <InboxReadFlagCell model={row} />;
        },
      },
    ];
  }

  @computed
  private get bodyColumns(): TableColumnDef[] {
    const { rows } = this.props.model;

    return [
      {
        id: "タイトル",
        width: 10 * x("XXXXX"),
        renderHeader: () => "タイトル",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <InboxSubjectCell model={row} />;
        },
      },
      {
        id: "通知時刻",
        width: 15 * x("X"),
        renderHeader: () => "通知時刻",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <InboxPublishDateCell model={row} />;
        },
      },
    ];
  }
}
