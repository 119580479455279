import { Classes } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import moment from "moment";
import React from "react";

import { monthlyCalendarDateList, weekdayNames } from "../../utils/calendar";
import { haveSameMonth } from "../../utils/date";

export interface MonthCalendarProps {
  month: Date;
  onNavigateToDate: (d: Date) => void;
  /** Default: true */
  renderOtherMonth?: boolean;
}

const Container = styled.div`
  display: table;
`;

const DoWNameHeader = styled.div`
  display: table-header-group;
  & > * {
    font-weight: bold;
  }
`;

const DoWBody = styled.div`
  display: table-row-group;
`;

const Week = styled.div`
  display: table-row;
`;

const CalendarCell = styled.div`
  display: table-cell;
  text-align: center;
  width: 2em;

  &.DifferentMonth {
    color: #ccc;
  }
`;

export const MonthlyCalendar = (props: MonthCalendarProps) => {
  const { onNavigateToDate, month } = props;

  return (
    <Container>
      <DoWNameHeader>
        {weekdayNames().map(name => (
          <CalendarCell key={name}>{name}</CalendarCell>
        ))}
      </DoWNameHeader>
      <DoWBody>
        {monthlyCalendarDateList(month).map(days => (
          <Week className="Week" key={days[0].toDateString()}>
            {days.map(day =>
              // 異月非表示の場合
              props.renderOtherMonth === false && !haveSameMonth(props.month, day) ? (
                <CalendarCell key={day.toDateString()} />
              ) : (
                <CalendarCell
                  className={classNames(haveSameMonth(month, day) ? "SameMonth" : "DifferentMonth")}
                  key={day.toDateString()}>
                  <DateLink day={day} onNavigateToDate={onNavigateToDate} />
                </CalendarCell>
              ),
            )}
          </Week>
        ))}
      </DoWBody>
    </Container>
  );
};

const DateLink = (props: { day: Date; onNavigateToDate: (d: Date) => void }) => {
  const { day, onNavigateToDate } = props;
  const onClick = () => {
    onNavigateToDate(day);
  };
  return (
    <a className={Classes.POPOVER_DISMISS} href="javascript: undefined" onClick={onClick}>
      {moment(day).format("DD")}
    </a>
  );
};
