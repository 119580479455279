import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type PJRHeaderPjCodeKFProps = {
  model: PJRHeaderPjCodeKFVM;
} & CellFormPrintAdaptable;

export interface PJRHeaderPjCodeKFVM {
  pjCode: KintaiInputItemState<string>;
}

export const PJRHeaderPjCodeKF = observer((props: PJRHeaderPjCodeKFProps) => {
  const { model } = props;
  const itemState = model.pjCode;

  return <TextField {...props} label="PJコード" {...itemState} />;
});
