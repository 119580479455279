import React from "react";
import { ReceptionStatusType, ReceptionStatusValues } from "../../../../models/common/ReceptionStatus";
import { CellFormPrintAdaptable } from "../../../common/form/CellFormAdaptable";
import { SelectField, SelectFieldOptionItem } from "../../../common/form/SelectField";

const options: SelectFieldOptionItem[] = ReceptionStatusValues.map(it => ({
  label: it,
  value: it,
}));
export const ReceptionStatusSelectField: React.FunctionComponent<{
  disabled?: boolean;
  value: ReceptionStatusType;
  onChange: (value: ReceptionStatusType) => void;
} & CellFormPrintAdaptable> = props => {
  return (
    <SelectField
      // dirty hack: "form" | "print" 型を与えるとTSが処理できないので "form" にしてる
      mode={props.mode as "form"}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange as any}
      options={options}
    />
  );
};
