import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../form/HourBasedDaysCountField";

export type MTotalWorkplaceZaitakuDaysKFProps = {
  model: MTotalWorkplaceZaitakuDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalWorkplaceZaitakuDaysKFVM {
  mTotalWorkplaceZaitakuDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalWorkplaceZaitakuDaysKF = observer((props: MTotalWorkplaceZaitakuDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalWorkplaceZaitakuDays;

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label=""
      {...itemState}
      value={itemState.value}
      onChange={undefined}
    />
  );
});
