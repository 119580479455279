import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { ExportEntry } from "../../../../models/export/ExportEntry";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { ExportPageTableRowVM } from "./ExportPageTable";

export class ExportPageTableVMImpl implements ExportPageTableRowVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;
  constructor(private readonly model: typeof ExportEntry.Type) {}

  @computed
  get id() {
    return this.model.id;
  }
  @computed
  get companyCode() {
    return this.model.companyCode;
  }
  @action.bound
  public onNavigateToKintai() {
    this.appRouter.navigate(
      paths.kintai
        .ofUser(this.userId)
        .ofDate(this.model.targetMonth)
        .index(),
    );
  }
  @computed
  get userId() {
    return this.model.userId;
  }
  @computed
  get userName() {
    return this.model.userName;
  }
  @computed
  get salaryCalcGroup() {
    return this.model.salaryCalcGroup;
  }
  @computed
  get processingType() {
    return this.model.processingType;
  }
  @computed
  get salaryMonth() {
    return this.model.salaryMonth;
  }
  @computed
  get paymentDate() {
    return this.model.paymentDate;
  }
  @computed
  get normalDays() {
    return this.model.normalDays;
  }
  @computed
  get sickoutDays() {
    return this.model.sickoutDays;
  }
  @computed
  get compAndAllNightOffDays() {
    return this.model.compAndAllNightOffDays;
  }
  @computed
  get paidOffDays() {
    return this.model.paidOffDays;
  }
  @computed
  get spDays() {
    return this.model.spDays;
  }
  @computed
  get refreshDays() {
    return this.model.refreshDays;
  }
  @computed
  get positiveDays() {
    return this.model.positiveDays;
  }
  @computed
  get summerDays() {
    return this.model.summerDays;
  }
  @computed
  get off() {
    return this.model.off;
  }
  @computed
  get midnight() {
    return this.model.midnight;
  }
  @computed
  get holiday() {
    return this.model.holiday;
  }
  @computed
  get liquidateSum() {
    return this.model.liquidateSum;
  }
  @computed
  get spWeekday() {
    return this.model.spWeekday;
  }
  @computed
  get spWeekdayMidnight() {
    return this.model.spWeekdayMidnight;
  }
  @computed
  get spHoliday() {
    return this.model.spHolidayMidnight;
  }
  @computed
  get spHolidayMidnight() {
    return this.model.spHolidayMidnight;
  }
  @computed
  get overSixtyFlexHoliday() {
    return this.model.overSixtyFlexHoliday;
  }
  @computed
  get overSixtyLiquidate() {
    return this.model.overSixtyLiquidate;
  }
  @computed
  get allNightOff() {
    return this.model.allNightOff;
  }
  @computed
  get hourlyLeave() {
    return this.model.hourlyLeave;
  }
  @computed
  get hourlyNursingcare() {
    return this.model.hourlyNursingcare;
  }
  @computed
  get hourlyFamilycare() {
    return this.model.hourlyFamilycare;
  }
  @computed
  get workplaceZaitakuDays() {
    return this.model.workplaceZaitakuDays;
  }
  @computed
  get workplaceSyussyaDays() {
    return this.model.workplaceSyussyaDays;
  }
}
