import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalNursingcareDaysKFProps = {
  model: MTotalNursingcareDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalNursingcareDaysKFVM {
  mTotalNursingcareDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalNursingcareDaysKF = observer((props: MTotalNursingcareDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalNursingcareDays;

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label=""
      {...itemState}
      value={itemState.value}
      onChange={undefined}
    />
  );
});
