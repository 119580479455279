import { observer } from "mobx-react";
import React from "react";

export interface ApplicationsPageRibbonVM {}

@observer
export class ApplicationsPageRibbon extends React.Component<{ model: ApplicationsPageRibbonVM }> {
  public render() {
    return <div />;
  }
}
