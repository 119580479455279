import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { LoginFormModel, LoginFormModelSymbol } from "../../../models/login/LoginFormModel";
import { LoginMethod } from "../../../models/login/LoginMethod";
import {
  PasswordChangeAppFormModel,
  PasswordChangeAppFormModelSymbol,
} from "../../../models/login/PasswordChangeAppFormModel";
import { LoginConsoleVM } from "./LoginConsole";
import { PasswordChangeAppConsoleVMImpl } from "./PasswordChangeAppConsoleVMImpl";

export class LoginConsoleVMImpl implements LoginConsoleVM {
  public passwordChangeAppConsoleVM = new PasswordChangeAppConsoleVMImpl();

  @lazyInject(LoginFormModelSymbol)
  private loginForm: typeof LoginFormModel.Type;

  @lazyInject(PasswordChangeAppFormModelSymbol)
  private passwordAppDialog: typeof PasswordChangeAppFormModel.Type;

  @computed
  public get inputLocked() {
    return this.loginForm.inputLocked;
  }

  @computed
  public get loginMessage() {
    return env.login_message;
  }

  @computed
  public get showConsole() {
    return this.loginForm.showConsole;
  }

  @computed
  public get oidcLoadingStatus() {
    return this.loginForm.oidcLoadingStatus;
  }

  @computed
  public get oidcSites() {
    return this.loginForm.oidcSites;
  }

  @computed
  public get loginMethod() {
    return this.loginForm.loginMethod;
  }

  @action.bound
  public onChangeLoginMethod(value: typeof LoginMethod.Type) {
    this.loginForm.setLoginMethod(value);
  }

  @computed
  public get userId() {
    return this.loginForm.userId;
  }

  @action.bound
  public onChangeUserId(value: string) {
    this.loginForm.setUserId(value);
  }

  @computed
  public get password() {
    return this.loginForm.password;
  }

  @action.bound
  public onChangePassword(value: string) {
    this.loginForm.setPassword(value);
  }

  @computed
  public get rememberLogin() {
    return this.loginForm.rememberLogin;
  }

  @action.bound
  public onChangeRememberLogin(checked: boolean) {
    this.loginForm.setRememberLogin(checked);
  }

  @computed
  public get allowPushSubmit() {
    return !!(this.userId && this.password);
  }

  @action.bound
  public onSubmitLogin() {
    this.loginForm.onSubmitLogin();
  }

  @computed
  public get loginDurationDays() {
    return Math.floor(env.login_token_duration / (24 * 60 * 60 * 1000));
  }

  @action.bound
  public onOpenPasswordChangeApp() {
    this.passwordAppDialog.setShowPasswordChangeDialog(true);
  }
}
