import { flatMap } from "lodash-es";
import { applySnapshot, flow, types } from "mobx-state-tree";

import { CalendarApi } from "../../../services/api/CalendarApi";
import {
  NendoCalendarInputExcel,
  NendoCalendarInputExcelImportResult,
} from "../../../services/excel/calendar/NendoCalendarInputExcel";
import { FileSaver } from "../../../services/file/FileSaver";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { TimeProvider } from "../../../services/TimeProvider";
import { toApiLocalDate } from "../../../utils/api";
import { getNendoAsDate } from "../../../utils/calendar";
import { haveSameMonth } from "../../../utils/date";
import { xlsxMimeType } from "../../../utils/mimetypes";
import { hasNoChangeReduce } from "../../../utils/model";
import { getDI } from "../../common/getDI";
import { prefixedIdType } from "../../utils";
import { NendoCalendar, NendoCalendarType } from "../NendoCalendar";
import { NendoMonthCalendarInput } from "./NendoMonthCalendarInput";

export const idPrefix = "NendoCalendar_";

const model = types
  .model("NendoCalendarInput", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    months: types.array(NendoMonthCalendarInput),
    summerHolidays: types.optional(types.number, 0),
    positiveHolidays: types.optional(types.number, 0),
    originalCalendar: types.maybe(types.reference(NendoCalendar)),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);

    return {
      get hasNoChange() {
        if (!self.originalCalendar) {
          return true;
        }
        return hasNoChangeReduce([
          self.originalCalendar.summerHolidays === self.summerHolidays,
          self.originalCalendar.positiveHolidays === self.positiveHolidays,
          ...self.months.map(m => m.hasNoChange),
        ]);
      },
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
      get holidayCount() {
        return self.months.reduce((l, r) => l + r.holidayCount, 0);
      },
      get workdayCount() {
        return self.months.reduce((l, r) => l + r.workdayCount, 0);
      },
      getMonth(d: Date) {
        return self.months.find(m => haveSameMonth(m.monthDate, d));
      },

      getDate(d: Date) {
        const month = this.getMonth(d);
        return month ? month.days[d.getDate() - 1] : null;
      },
      get finalDay() {
        const finalDay = flatMap(self.months.map(m => m.days.filter(d => d.isFinalDay)))[0];
        return finalDay;
      },
    };
  })
  .actions(self => {
    const excelService = (): NendoCalendarInputExcel => getDI(self, NendoCalendarInputExcel);
    const calendarApi = (): CalendarApi => getDI(self, CalendarApi);
    const fileSaver = (): FileSaver => getDI(self, FileSaver);
    const appNotifier = (): AppNotifier => getDI(self, AppNotifier);

    const exportExcel = flow(function*(): any {
      const buffer: Buffer = yield excelService().exportExcel(self as NendoCalendarInputType);
      fileSaver().saveFile(`Web勤怠v2_${self.nendo}年度_カレンダーマスタ.xlsx`, buffer, xlsxMimeType);
    });

    const save = flow(function*() {
      yield calendarApi().saveNendoCalendar(self.nendo, {
        nendo: self.nendo,
        lastDay: self.finalDay ? toApiLocalDate(self.finalDay.date) : undefined,
        vacation: {
          summer: {
            hours: self.summerHolidays * 8,
          },
          positive: {
            hours: self.positiveHolidays * 8,
          },
        },
        months: self.months.map(mon => {
          return {
            ymd: toApiLocalDate(mon.monthDate) as string,
            allowReviseWorkDept: mon.allowReviseWorkDept,
            dates: mon.days.map(day => {
              return {
                ymd: toApiLocalDate(day.date) as string,
                holiday: day.isHoliday,
              };
            }),
          };
        }),
      });
      appNotifier().info({ message: `${self.nendo} 年度のカレンダーを保存しました。` });
      yield self.originalCalendar!.load();
    });

    return {
      setOriginalCalendar(originalCalendar: NendoCalendarType) {
        self.originalCalendar = originalCalendar;
      },
      copy() {
        if (!self.originalCalendar) {
          return;
        }

        const nendoCalendar = self.originalCalendar;
        self.positiveHolidays = nendoCalendar.positiveHolidays;
        self.summerHolidays = nendoCalendar.summerHolidays;
        applySnapshot(
          self.months,
          nendoCalendar.months.map(m => ({
            id: m.id,
            nendo: m.nendo,
            month: m.month,
            derivesLastYearDepts: m.allowReviseWorkDept,
            origin: m.id,
            days: m.days.map(day => ({
              id: day.id,
              nendo: day.nendo,
              month: day.month,
              day: day.day,
              isHoliday: day.isHoliday,
              isFinalDay: day.isFinalDay,
              origin: day.id,
            })),
          })),
        );
      },
      setOriginal(nendoCalendar: NendoCalendarType) {
        self.originalCalendar = nendoCalendar;
      },
      setSummerHolidays(summerHolidays: number) {
        self.summerHolidays = summerHolidays;
      },
      setPositiveHolidays(positiveHolidays: number) {
        self.positiveHolidays = positiveHolidays;
      },
      importExcel: flow(function*(file: File): any {
        const importResult: NendoCalendarInputExcelImportResult = yield excelService().importExcel(
          file,
          self as NendoCalendarInputType,
        );
        if (importResult.status === "failure") {
          appNotifier().error({ message: `読み込みに失敗しました - ${importResult.failureReason}` });
        }
      }),
      exportExcel,
      save,
    };
  });

export type NendoCalendarInputType = typeof NendoCalendarInput.Type;

export const NendoCalendarInputSymbol = "NendoCalendarInput_Symbol";
export const NendoCalendarInput: NendoCalendarInputModelType = model;
type NendoCalendarInputInferredType = typeof model;
export interface NendoCalendarInputModelType extends NendoCalendarInputInferredType {}
