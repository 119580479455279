import { Button, ButtonGroup } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/dashboard/kintai_sum.md";
import { observer } from "mobx-react";
import React from "react";
import { sprintf } from "sprintf-js";

import { LoadingStatusType } from "../../../../models/common/LoadingStatusType";
import { getNendo, getNendoMonthOf } from "../../../../utils/calendar";
import { DeptSelectorVM } from "../../../common/dept-selector/DeptSelector";
import { DeptsSelectorButton } from "../../../common/dept-selector/DeptsSelectorButton";
import { FilterTextbox } from "../../../common/FilterTextbox";
import { CheckboxField } from "../../../common/form/CheckboxField";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { KintaiSumPageSumType } from "../common/KintaiSumPageSumType";
import { KinaiSumScreenModeSelectorRibbon } from "./KintaiSumScreenModeSelector";

export interface KintaiSumPageRibbonVM {
  nendo: number;
  onChangeNendo: (nendo: number) => void;
  nendoList: number[];
  deptsLoadingState: LoadingStatusType;
  deptSelectorVM: DeptSelectorVM;
  onReload: () => void;
  sumType: KintaiSumPageSumType;
  onChangeSumType: (value: KintaiSumPageSumType) => void;
  filterText: string;
  onChangeFilterText: (value: string) => void;
  displayHourAsDecimal: boolean;
  onChangeDisplayHourAsDecimal: (value: boolean) => void;
  showFilterByMonths: boolean;
  onChooseAllFilterByMonths: () => void;
  allFilterByMonthsSelected: boolean;
  filterByMonthOptions: FilterByMonthOption[];
}

export interface FilterByMonthOption {
  month: number;
  selected: boolean;
  onSelectFilterByMonth: () => void;
}

@observer
export class KintaiSumPageRibbon extends React.Component<{ model: KintaiSumPageRibbonVM }> {
  public render() {
    const { model } = this.props;

    const monthsButtons = model.filterByMonthOptions.map(opt => (
      <Button
        style={{ width: "3em" }}
        key={opt.month}
        active={opt.selected}
        small={true}
        text={`${sprintf("%02d", opt.month)}月`}
        onClick={opt.onSelectFilterByMonth}
      />
    ));

    const monthFilterRibbon = model.showFilterByMonths && (
      <FilterRibbon title="社員稼働表示対象月フィルタ">
        <PageRibbonLine>
          <ButtonGroup>{monthsButtons.filter((it, idx) => idx < 6)}</ButtonGroup>
        </PageRibbonLine>
        <PageRibbonLine>
          <ButtonGroup>
            <>
              {monthsButtons.filter((it, idx) => idx >= 6)}
              <Button
                small={true}
                active={model.allFilterByMonthsSelected}
                text="全て"
                onClick={model.onChooseAllFilterByMonths}
              />
            </>
          </ButtonGroup>
        </PageRibbonLine>
      </FilterRibbon>
    );

    return (
      <>
        <GuideRibbon markdown={Help} />

        <JumpRibbon>
          <PageRibbonLine>
            <KintaiCalendarSelectorButton
              buttonProps={{ small: true }}
              unit="year"
              nendoList={model.nendoList}
              currentDate={getNendoMonthOf(model.nendo)}
              onChangeDate={this.onChangeDate}
            />
          </PageRibbonLine>

          <PageRibbonLine>
            {model.deptsLoadingState === "loaded" && (
              <DeptsSelectorButton model={model.deptSelectorVM} buttonProps={{ small: true }} />
            )}
          </PageRibbonLine>
        </JumpRibbon>
        <ReloadRibbon onReload={model.onReload} />

        <KinaiSumScreenModeSelectorRibbon mode={model.sumType} onChangeMode={model.onChangeSumType} />
        <FilterRibbon>
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
        </FilterRibbon>
        {monthFilterRibbon}

        <PageRibbonSection title="表示形式">
          <PageRibbonLine>
            <CheckboxField
              mode="form"
              label="時間を小数で表示"
              value={model.displayHourAsDecimal}
              onChange={model.onChangeDisplayHourAsDecimal}
            />
          </PageRibbonLine>
        </PageRibbonSection>
      </>
    );
  }

  private onChangeDate = (d: Date) => {
    this.props.model.onChangeNendo(getNendo(d));
  };
}
