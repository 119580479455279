import styled from "@emotion/styled";
import React from "react";

export const ErrorCountColor: React.FunctionComponent<{ length: number }> = props => (
  <span
    style={{
      color: props.length === 0 ? "#aaa" : "#000",
      fontWeight: props.length === 0 ? undefined : "bold",
    }}>
    {props.children}
  </span>
);

export const ErrorCell = styled.div`
  text-align: center;
`;
