import { Button, ButtonGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import React from "react";

import { KintaiListDisplayedRowsType } from "../../../../models/kintai_list/KintaiListDisplayedRowsType";
import { kinmuTypeNoEntryUiDef, kinmuTypeUiDefs } from "../../../common/kinmu-type/kinmuTypeUiDefs";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";

const KinmuTypeSelectButton: typeof Button = styled(Button)`
  width: 8em;
` as any;

export type KinmuTypeRibbonSelectType = typeof KintaiListDisplayedRowsType.Type;

export interface KinmuTypeSelectSectionVM {
  selectedType: KinmuTypeRibbonSelectType;
  onChooseType: (value: KinmuTypeRibbonSelectType) => void;
  flexCount: number;
  nonFlexCount: number;
  sPortCount: number;
  noRegCount: number;
}

const CounterSpan = styled.span`
  display: inline-block;
  font-size: 90%;
  margin-left: 1ex;
  padding-left: 0.5ex;
  padding-right: 0.5ex;
  border-radius: 5px;
  background-color: #666;
  color: #fff;
`;

const Counter = (props: { count: number }) => <CounterSpan>{props.count}</CounterSpan>;

export const KinmuTypeSelectSection = observer((props: { model: KinmuTypeSelectSectionVM }) => {
  const { model } = props;
  const onClickFlexCount = React.useCallback(() => {
    model.onChooseType("Flex");
  }, [model.onChooseType]);
  const onClickNonFlexCount = React.useCallback(() => {
    model.onChooseType("Non-Flex");
  }, [model.onChooseType]);
  const onClickSPortCount = React.useCallback(() => {
    model.onChooseType("S-Port");
  }, [model.onChooseType]);
  const onClickNoRegCount = React.useCallback(() => {
    model.onChooseType("NoReg");
  }, [model.onChooseType]);

  const { selectedType } = model;

  const flexUiDef = kinmuTypeUiDefs.Flex;
  const nonFlexUiDef = kinmuTypeUiDefs["Non-Flex"];
  const sPortUiDef = kinmuTypeUiDefs["S-Port"];
  const noEntryUiDef = kinmuTypeNoEntryUiDef;

  return (
    <PageRibbonSection title="勤怠表種別">
      <PageRibbonLine>
        <ButtonGroup>
          <KinmuTypeSelectButton
            icon={flexUiDef.icon}
            active={selectedType === "Flex"}
            small={true}
            onClick={onClickFlexCount}>
            {flexUiDef.title}
            <Counter count={model.flexCount} />
          </KinmuTypeSelectButton>
          <KinmuTypeSelectButton
            icon={nonFlexUiDef.icon}
            active={selectedType === "Non-Flex"}
            small={true}
            onClick={onClickNonFlexCount}>
            {nonFlexUiDef.title}
            <Counter count={model.nonFlexCount} />
          </KinmuTypeSelectButton>
        </ButtonGroup>
      </PageRibbonLine>
      <PageRibbonLine>
        <ButtonGroup>
          <KinmuTypeSelectButton
            icon={sPortUiDef.icon}
            active={selectedType === "S-Port"}
            small={true}
            onClick={onClickSPortCount}>
            {sPortUiDef.title}
            <Counter count={model.sPortCount} />
          </KinmuTypeSelectButton>
          <KinmuTypeSelectButton
            icon={noEntryUiDef.icon}
            active={selectedType === "NoReg"}
            small={true}
            onClick={onClickNoRegCount}>
            {noEntryUiDef.title}
            <Counter count={model.noRegCount} />
          </KinmuTypeSelectButton>
        </ButtonGroup>
      </PageRibbonLine>
    </PageRibbonSection>
  );
});
