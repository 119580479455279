import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import {
  PasswordChangeAppFormModel,
  PasswordChangeAppFormModelSymbol,
} from "../../../models/login/PasswordChangeAppFormModel";
import { PasswordChangeAppConsoleVM } from "./PasswordChangeAppConsole";

export class PasswordChangeAppConsoleVMImpl implements PasswordChangeAppConsoleVM {
  @lazyInject(PasswordChangeAppFormModelSymbol)
  private model: typeof PasswordChangeAppFormModel.Type;

  @computed
  public get showConsole() {
    return this.model.showPasswordChangeDialog;
  }

  @computed
  public get inputLocked() {
    return this.model.inputLocked;
  }

  @computed
  public get allowPushSubmit() {
    return this.model.allowPushSubmit;
  }

  @computed
  public get userId() {
    return this.model.userId;
  }

  @computed
  public get email() {
    return this.model.email;
  }

  @action.bound
  public onChangeEmail(value: string) {
    this.model.setEmail(value);
  }

  @action.bound
  public onSubmit() {
    this.model.submit();
  }

  @action.bound
  public onClose() {
    this.model.setShowPasswordChangeDialog(false);
  }

  @action.bound
  public onChangeUserId(value: string) {
    this.model.setUserId(value);
  }
}
