import { action, computed } from "mobx";

import { KintaiApplicationDay } from "../../../../../models/kintai/apps/KintaiApplicationDay";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { KintaiReportPageModel } from "../../../../../models/kintai/KintaiReportPageModel";
import { DailyApplicationVM } from "./DailyApplicationVM";

export class DailyApplicationVMImpl implements DailyApplicationVM {
  constructor(
    private readonly pageModel: typeof KintaiReportPageModel.Type,
    private readonly model: typeof KintaiApplicationDay.Type,
  ) {}

  @computed
  get isLocked() {
    return this.model.isLocked;
  }

  @computed
  get appPostIsProxied() {
    return this.model.isProxiedApp;
  }

  @computed
  get appStage() {
    return this.model.appStage;
  }

  @action.bound
  public onNavigateToAppDailyForm() {
    this.pageModel.setToAppDailyForm();
  }

  @computed.struct
  get appPostText(): KintaiInputItemState<string> {
    return this.model.appPostText;
  }

  @computed
  get appPostUserName() {
    return this.model.applicantUserName || "";
  }

  @computed
  get appPostTimestamp() {
    return this.model.applicantTimestamp;
  }

  @action.bound
  public onAppPost() {
    this.model.postApp();
  }

  @computed
  get approveTimestamp() {
    return this.model.approvalTimestamp;
  }

  @computed
  get approveUserName() {
    return this.model.approverUserName;
  }

  @computed.struct
  get appApproveText() {
    return this.model.appApproveText;
  }

  @action.bound
  public onAppCancel() {
    this.model.cancelApp();
  }

  @computed
  public get appCancelIsDisabled() {
    return this.model.cancelDisabled;
  }

  @computed
  get postDisabled() {
    return this.appPostText.disabled || !this.model.satisfiesApplicantCommentCheck;
  }

  @computed
  get appTemplates() {
    return [{ title: "", content: "" }, ...this.model.templatesList];
  }

  @computed
  get appPostTextIsRequired() {
    return this.model.applicantCommentIsRequired;
  }

  @action.bound
  public onSelectAppTemplate(template: string | undefined) {
    this.model.appPostText.onChange(this.model.appPostText.value + (template || ""));
  }

  @computed
  get appTemplateIsDisabled(): boolean {
    return this.appPostText.disabled;
  }

  @action.bound
  public onAppReject() {
    this.model.rejectApp();
  }

  @computed
  get rejectDisabled(): boolean {
    return this.appApproveText.disabled || this.appApproveText.value.length === 0;
  }

  @action.bound
  public onAppApprove() {
    this.model.approveApp();
  }

  @computed
  get approveDisabled(): boolean {
    return this.appApproveText.disabled;
  }
}
