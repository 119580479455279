import { InputGroup } from "@blueprintjs/core";
import { debounce } from "lodash-es";
import React from "react";

export interface FilterTextboxProps {
  filterText: string;
  placeholder?: string;
  onChangeFilterText: (value: string) => void;
}

export const FilterTextbox: React.FunctionComponent<FilterTextboxProps> = props => {
  const [componentText, setComponentText] = React.useState(props.filterText);
  const [prevFilterText, setPrevFilterText] = React.useState(props.filterText);

  if (props.filterText !== prevFilterText) {
    setPrevFilterText(props.filterText);
    setComponentText(props.filterText);
  }

  const debouncedChangeListener = React.useCallback(
    debounce((value: string) => {
      props.onChangeFilterText(value);
    }, 300),
    [],
  );

  const onChangeFilterText: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      const value = ev.currentTarget.value;
      setComponentText(value);
      debouncedChangeListener(value);
    },
    [props.filterText, props.onChangeFilterText],
  );

  return (
    <InputGroup
      leftIcon="filter"
      placeholder={props.placeholder || "絞り込み"}
      small={true}
      value={componentText}
      onChange={onChangeFilterText}
    />
  );
};
