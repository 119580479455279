import styled from "@emotion/styled";
import React from "react";

export interface SplitViewProps {
  min: number;
  max: number;
  formerSize: number;
  onChangeFormerSize?: (newFormerSize: number) => void;
  former?: React.ReactNode;
  latter?: React.ReactNode;
}

const SplitViewContainer = styled.div`
  flex: 1 1 0;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const Splitter = styled.div<{ isDrugging: boolean }>`
  flex: 0 0 1px;

  min-height: 1;
  border: 1px solid #ccc;

  ${({ isDrugging }) => (isDrugging ? `cursor: grabbing;` : `cursor: ns-resize;`)}
`;

const Former = styled.div`
  flex: 1 0;

  display: flex;
  overflow: hidden;
`;

const Latter = styled.div`
  flex: 1 0;

  display: flex;
  overflow: hidden;
`;

export const SplitView: React.FunctionComponent<SplitViewProps> = props => {
  if (!props.former && !props.latter) {
    return <></>;
  }

  const noramlizeSize = React.useCallback((size: number) => Math.min(Math.max(size, props.min), props.max), [
    props.min,
    props.max,
  ]);

  const [prevFormerSize, setPrevFormerSize] = React.useState(noramlizeSize(props.formerSize));
  const [isDrugging, setDrugging] = React.useState(false);
  const [initPosition, setInitPosition] = React.useState(0);
  const [formerStateSize, setFormerStateSize] = React.useState(noramlizeSize(props.formerSize));
  if (props.formerSize !== prevFormerSize) {
    setFormerStateSize(props.formerSize);
    setPrevFormerSize(props.formerSize);
  }

  const startDrugging = React.useCallback((ev: React.MouseEvent<HTMLDivElement>) => {
    setInitPosition(ev.screenY);
    setDrugging(true);
  }, []);

  const whileDragging = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (!isDrugging) {
        return;
      }

      const delta = ev.screenY - initPosition;
      setFormerStateSize(props.formerSize + delta);
    },
    [isDrugging, props.formerSize],
  );
  const endDrugging = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>) => {
      if (props.onChangeFormerSize) {
        const delta = ev.screenY - initPosition;
        props.onChangeFormerSize(noramlizeSize(props.formerSize + delta));
      }
      setDrugging(false);
      setFormerStateSize(props.formerSize);
      setInitPosition(0);
    },
    [props.min, props.max, props.formerSize, props.onChangeFormerSize, initPosition, formerStateSize],
  );

  return (
    <SplitViewContainer
      onMouseMove={isDrugging ? whileDragging : undefined}
      onClick={isDrugging ? endDrugging : undefined}
      onMouseUp={isDrugging ? endDrugging : undefined}>
      {props.former && (
        <Former
          style={{
            ...(props.latter ? { minHeight: formerStateSize, maxHeight: formerStateSize } : { height: "100%" }),
            pointerEvents: isDrugging ? "none" : undefined,
          }}>
          {props.former}
        </Former>
      )}
      {props.former && props.latter && <Splitter isDrugging={isDrugging} onMouseDown={startDrugging} />}
      {props.latter && (
        <Latter
          style={{
            pointerEvents: isDrugging ? "none" : undefined,
          }}>
          {props.latter}
        </Latter>
      )}
    </SplitViewContainer>
  );
};
