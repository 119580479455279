import { Icon, Tooltip } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { FunctionComponent } from "react";
import React from "react";

import { ErrorsPopoverFrame } from "../errors/ErrorsPopoverFrame";

export interface ErrorOverlayProps extends ErrorOverlayErrors {
  mode: "cell" | "form" | "print";
  disabled?: boolean;
}

export interface ErrorOverlayErrors {
  errors?: string[];
  warnings?: string[];
  infos?: string[];
}

const ErrorOverlayContainer = styled.div`
  position: relative;
`;

const Overlay = styled.div<{ color: string; opacity: number }>`
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  opacity: ${({ opacity }) => opacity};

  &:hover {
    opacity: inherit;
  }

  & > * {
    display: inline-block;
  }
`;

export const ErrorOverlay: FunctionComponent<ErrorOverlayProps> = props => {
  if (
    props.mode === "print" ||
    props.disabled === true ||
    (props.warnings === undefined && props.errors === undefined && props.infos === undefined)
  ) {
    return <>{props.children}</>;
  }

  const errors = props.errors || [];
  const warnings = props.warnings || [];
  const infos = props.infos || [];
  const color = errors.length ? errorColor : warnings.length ? warningColor : infoColor;
  const opacity = errors.length + warnings.length + infos.length === 0 ? 0.2 : 0.5;

  const errorsIcon = errors.length ? (
    <Tooltip
      boundary="viewport"
      content={
        <ErrorsPopoverFrame>
          <div>【エラー】</div>
          {errors.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </ErrorsPopoverFrame>
      }>
      <Icon style={{ color: errorColor }} iconSize={10} icon="error" />
    </Tooltip>
  ) : (
    false
  );

  const warningsIcon = warnings.length ? (
    <Tooltip
      boundary="viewport"
      content={
        <ErrorsPopoverFrame>
          <div>【警告】</div>
          {warnings.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </ErrorsPopoverFrame>
      }>
      <Icon style={{ color: warningColor }} iconSize={10} icon="warning-sign" />
    </Tooltip>
  ) : (
    false
  );

  const infosIcon = infos.length ? (
    <Tooltip
      boundary="viewport"
      content={
        <ErrorsPopoverFrame>
          <div>【情報】</div>
          {infos.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </ErrorsPopoverFrame>
      }>
      <Icon style={{ color: infoColor }} iconSize={10} icon="info-sign" />
    </Tooltip>
  ) : (
    false
  );

  const overlay = (
    <Overlay color={color} opacity={opacity}>
      {infosIcon}
      {warningsIcon}
      {errorsIcon}
    </Overlay>
  );

  if (props.mode === "cell") {
    return (
      <>
        {props.children}
        {overlay}
      </>
    );
  }

  return (
    <ErrorOverlayContainer>
      {props.children}
      {overlay}
    </ErrorOverlayContainer>
  );
};

const errorColor = "red";
const warningColor = "#cc9900";
const infoColor = "green";
