import styled from "@emotion/styled";
import QR from "@webkintai/help/content/manuals/qr.png";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../utils/apptitle";
import { manualLinks } from "../../utils/manuals";
import { PageTile } from "../common/page/PageTile";
import { ReadingsPane } from "../common/ReadingsPane";
import { HelpSubMenu } from "./header/HelpSubMenu";

const HelpGuide = styled.div`
  border-bottom: 1px solid #eee;
  padding-top: 1em;
  width: 90%;
  margin: 0 auto 0 auto;
`;

const LogoContainer = styled.div`
  padding: 1ex 2ex 1ex 2ex;
  margin: 2em auto 2em auto;
  max-width: 30em;
  text-align: center;
  color: #999;
`;

const LogoHeading = styled.h1`
  color: #000;
  margin-top: 0px;
`;

const NoShow = styled.div`
  display: none;
`;

export interface HelpPageVM {}

@observer
export class HelpPage extends React.Component<{ model: HelpPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageTile header={<HelpSubMenu model={{}} />}>
        <Helmet>
          <title>{decorateTitle("ヘルプ")}</title>
        </Helmet>
        <ReadingsPane>
          <HelpGuide>
            <p>Web勤怠v2のマニュアルは下記にて閲覧することができます。</p>
            <ul>
              <li>
                <a target="_blank" href={manualLinks.general}>
                  利用者マニュアル
                </a>
              </li>
            </ul>
            <p>Web勤怠のバグ報告や要望・問い合わせは総務人事部までお願いします。</p>
          </HelpGuide>
          <LogoContainer>
            <img src={QR} />
            <LogoHeading>Web勤怠v2</LogoHeading>
            <NoShow>
              <div>version: {process.env.REACT_APP_VERSION}</div>
              <div>environment: {process.env.NODE_ENV}</div>
            </NoShow>
            <div>built at: {window.builtAt}</div>
            <div />
          </LogoContainer>
        </ReadingsPane>
      </PageTile>
    );
  }
}
