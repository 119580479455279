import { Days, HourBasedDays, Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { RenderedMedia } from "../../../common/export-table/ExportableTable";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkTable } from "../../../common/plaintable/WkTables";
import { ExportDays } from "./ExportDays";
import { ExportHourBasedDays } from "./ExportHourBasedDays";
import { ExportMinutes } from "./ExportMinutes";

export interface ExportPageTableVM {
  rows: ExportPageTableRowVM[];
}

export interface ExportPageTableRowVM {
  id: string;
  onNavigateToKintai: () => void;

  companyCode: string;
  userId: string;
  userName: string;
  salaryCalcGroup: string;
  processingType: string;

  salaryMonth: Date | undefined;
  paymentDate: Date | undefined;

  normalDays: HourBasedDays | undefined;
  sickoutDays: Days | undefined;
  compAndAllNightOffDays: Days | undefined;

  paidOffDays: HourBasedDays | undefined;
  spDays: HourBasedDays | undefined;
  refreshDays: Days | undefined;
  positiveDays: Days | undefined;
  summerDays: Days | undefined;

  off: Minutes | undefined;
  midnight: Minutes | undefined;
  holiday: Minutes | undefined;

  liquidateSum: Minutes | undefined;

  spWeekday: Minutes | undefined;
  spWeekdayMidnight: Minutes | undefined;
  spHoliday: Minutes | undefined;
  spHolidayMidnight: Minutes | undefined;

  overSixtyFlexHoliday: Minutes | undefined;
  overSixtyLiquidate: Minutes | undefined;
  allNightOff: Minutes | undefined;

  hourlyLeave: HourBasedDays | undefined;
  hourlyNursingcare: HourBasedDays | undefined;
  hourlyFamilycare: HourBasedDays | undefined;

  workplaceZaitakuDays: HourBasedDays | undefined;
  workplaceSyussyaDays: HourBasedDays | undefined;
}

@observer
export class ExportPageTable extends React.Component<{ renderedOn: RenderedMedia; model: ExportPageTableVM }> {
  public render() {
    const { model, renderedOn } = this.props;
    const inCsv = renderedOn === "csv";
    const { rows } = model;

    if (rows.length === 0) {
      return <NoResultsSpread />;
    }

    return (
      <WkTable>
        <thead>
          <tr>
            {!inCsv && <th>#</th>}

            <th>会社コード</th>
            <th>社員番号</th>
            <th>社員名</th>
            <th>給与計算グループ</th>
            <th>処理区分</th>

            <th>給与年月</th>
            <th>支給年月日</th>

            <th>(変動)出勤日数</th>
            <th>(変動)欠勤日数</th>
            <th>(変動)代休徹夜明休日数</th>

            <th>(変動)有休消化日数</th>
            <th>(変動)特別休暇日数</th>

            <th>(変動)遅刻早退外出時間</th>
            <th>(変動)深夜勤務時間</th>
            <th>(変動)休出普通時間</th>

            <th>(変動)清算時間</th>

            <th>(変動)普通特勤時間</th>
            <th>(変動)普通深夜特勤時間</th>
            <th>(変動)休日特勤時間</th>
            <th>(変動)休日深夜特勤時間</th>

            <th>(変動)休出普通時間(60H超)</th>
            <th>(変動)清算時間(60H超)</th>

            <th>(変動)徹休時間</th>

            <th>(変動)時間休消化時間</th>

            <th>(変動)在宅勤務日数</th>
            <th>(変動)通常出社回数</th>

            <th>時間単位看護休暇取得時間数</th>
            <th>時間単位介護休暇取得時間数</th>
          </tr>
        </thead>
        <tbody>
          {model.rows.map((row, idx) => (
            <tr key={row.id}>
              {!inCsv && <td>{idx + 1}</td>}

              <td>{row.companyCode}</td>
              <td>{row.userId}</td>

              <td>
                <a href="javascript: undefined" onClick={row.onNavigateToKintai}>
                  {row.userName}
                </a>
              </td>
              <td>{row.salaryCalcGroup || ""}</td>
              <td>{row.processingType || ""}</td>
              <td>{row.salaryMonth ? moment(row.salaryMonth).format("YYYYMM") : ""}</td>
              <td>{row.paymentDate ? moment(row.salaryMonth).format("YYYY/MM/DD") : ""}</td>

              <td>
                <ExportHourBasedDays showAs="dayCount" value={row.normalDays} />
              </td>
              <td>
                <ExportDays value={row.sickoutDays} />
              </td>
              <td>
                <ExportDays value={row.compAndAllNightOffDays} />
              </td>

              <td>
                <ExportHourBasedDays showAs="dayCount" value={row.paidOffDays} />
              </td>
              <td>
                <ExportHourBasedDays showAs="dayCount" value={row.spDays} />
              </td>

              <td>
                <ExportMinutes value={row.off} />
              </td>
              <td>
                <ExportMinutes value={row.midnight} />
              </td>
              <td>
                <ExportMinutes value={row.holiday} />
              </td>

              <td>
                <ExportMinutes value={row.liquidateSum} />
              </td>

              <td>
                <ExportMinutes value={row.spWeekday} />
              </td>
              <td>
                <ExportMinutes value={row.spWeekdayMidnight} />
              </td>
              <td>
                <ExportMinutes value={row.spHoliday} />
              </td>
              <td>
                <ExportMinutes value={row.spHolidayMidnight} />
              </td>

              <td>
                <ExportMinutes value={row.overSixtyFlexHoliday} />
              </td>
              <td>
                <ExportMinutes value={row.overSixtyLiquidate} />
              </td>
              <td>
                <ExportMinutes value={row.allNightOff} />
              </td>

              <td>
                <ExportHourBasedDays showAs="hourCount" value={row.hourlyLeave} />
              </td>

              <td>
                <ExportHourBasedDays showAs="dayCount" value={row.workplaceZaitakuDays} />
              </td>
              <td>
                <ExportHourBasedDays showAs="dayCount" value={row.workplaceSyussyaDays} />
              </td>

              <td>
                <ExportHourBasedDays showAs="hourCount" value={row.hourlyNursingcare} />
              </td>
              <td>
                <ExportHourBasedDays showAs="hourCount" value={row.hourlyFamilycare} />
              </td>
            </tr>
          ))}
        </tbody>
      </WkTable>
    );
  }
}
