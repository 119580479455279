import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type OverHolidayMidnightKFProps = {
  model: OverHolidayMidnightKFVM;
} & CellFormPrintAdaptable;

export interface OverHolidayMidnightKFVM {
  overHolidayMidnight: KintaiInputItemState<Minutes | undefined>;
}

export const OverHolidayMidnightKF = observer((props: OverHolidayMidnightKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.overHolidayMidnight);

  return <HHMMField {...props} label="休日深夜残業" {...itemState} />;
});
