import styled from "@emotion/styled";
import React from "react";

const Exterior = styled.div`
  position: relative;
  width: 0;
  height: 0;
`;

const Interior = styled.div`
  position: fixed;
  width: 400px;
  opacity: 0.4;
  &:hover {
    opacity: 1;
  }
`;

export const TableAttachedOptions: React.FunctionComponent<{}> = props => (
  <Exterior>
    <Interior>{props.children}</Interior>
  </Exterior>
);
