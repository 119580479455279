import { getEnv, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { AccountPageModelSymbol, AccountPageModelType } from "../accounts/AccountsPageModel";
import { CalendarPageModelSymbol, CalendarPageModelType } from "../calendar/CalendarPageModel";
import { getDI } from "../common/getDI";
import { DeptsPageModelSymbol, DeptsPageModelType } from "../depts/DeptsPageModel";
import { PHPageModelSymbol, PHPageModelType } from "../ph/PHPageModel";
import { PjClosePageModelSymbol, PjClosePageModelType } from "../pjclose/PjClosePageModel";
import { RankPageModelSymbol, RankPageModelType } from "../ranks/RankPageModel";
import { SPortAdminPageModelSymbol, SPortAdminPageModelType } from "../sport/SPortAdminPageModel";
import { AdminPageScreenModes } from "./AdminPageScreenModes";

const model = types.optional(
  types
    .model("AdminPageModel", {
      pageMode: types.optional(AdminPageScreenModes, "warnings"),
    })
    .views(self => ({
      get url(): string {
        return paths.admin.index() + "/" + self.pageMode;
      },
    }))
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const calendarPageModel = () => getEnv(self).get(CalendarPageModelSymbol) as CalendarPageModelType;
      const rankPageModel = () => getEnv(self).get(RankPageModelSymbol) as RankPageModelType;
      const phPageModel = () => getEnv(self).get(PHPageModelSymbol) as PHPageModelType;
      const accountPageModel = () => getEnv(self).get(AccountPageModelSymbol) as AccountPageModelType;
      const deptsPageModel = () => getEnv(self).get(DeptsPageModelSymbol) as DeptsPageModelType;
      const pjClosePageModel = () => getEnv(self).get(PjClosePageModelSymbol) as PjClosePageModelType;
      const sportAdminPageModel = () => getEnv(self).get(SPortAdminPageModelSymbol) as SPortAdminPageModelType;

      return {
        route(pathFragment: string) {
          const { menuItem, others } = fromPaths.admin.index(pathFragment);

          if (AdminPageScreenModes.is(menuItem)) {
            self.pageMode = menuItem;

            switch (menuItem) {
              case "calendar":
                calendarPageModel().route(others);
                break;
              case "ph":
                phPageModel().route(others);
                break;
              case "ranks":
                rankPageModel().route(others);
                break;
              case "accounts":
                accountPageModel().route(others);
                break;
              case "depts":
                deptsPageModel().route(others);
                break;
              case "pjclose":
                pjClosePageModel().route(others);
                break;
              case "sport":
                sportAdminPageModel().route(others);
                break;
            }
          } else {
            appRouter().replaceWithoutEffects(self.url);
          }
        },
      };
    }),
  {},
);

export const AdminPageModelSymbol = "Model_AdminPage";
export const AdminPageModel: AdminPageModelModelType = model;
type AdminPageModelInferredType = typeof model;
export interface AdminPageModelModelType extends AdminPageModelInferredType {}
