import Help from "@webkintai/help/content/manuals/admin/ph.md";
import { observer } from "mobx-react";
import React from "react";

import { FilterTextbox } from "../../../common/FilterTextbox";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { ExcelIORibbon } from "../../../common/ribbon/ExcelIORibbon";
import { ExcludeHasNoChangeCheckbox } from "../../../common/ribbon/filter/ExcludeHasNoChangeCheckbox";
import { ExcludeOldRetiredMembersCheckbox } from "../../../common/ribbon/filter/ExcludeOldRetiredMembersCheckbox";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { SaveRibbon } from "../../../common/ribbon/SaveRibbon";

export interface PHPageRibbonVM {
  filterText: string;
  onChangeFilterText: (value: string) => void;
  excludeOldRetireUsers: boolean;
  onChangeExcludeOldRetireUsers: (value: boolean) => void;
  excludeHasNoChange: boolean;
  onChangeExcludeHasNoChange: (value: boolean) => void;
  onReload: () => void;
  reloadDisabled: boolean;
  onExcelExport: () => void;
  onExcelImport: (file: File) => void;
  onReset: () => void;
  onSave: () => void;
  saveDisabled: boolean;
}

@observer
export class PHPageRibbon extends React.Component<{ model: PHPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    return (
      <>
        <GuideRibbon markdown={Help} />

        <FilterRibbon title="テキストフィルタ">
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
        </FilterRibbon>

        <FilterRibbon title="条件フィルタ">
          <PageRibbonLine>
            <ExcludeHasNoChangeCheckbox
              checked={model.excludeHasNoChange}
              onChange={model.onChangeExcludeHasNoChange}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <ExcludeOldRetiredMembersCheckbox
              checked={model.excludeOldRetireUsers}
              onChange={model.onChangeExcludeOldRetireUsers}
            />
          </PageRibbonLine>
        </FilterRibbon>

        <ExcelIORibbon onExcelExport={model.onExcelExport} onExcelImport={model.onExcelImport} />

        <ReloadRibbon disabled={model.reloadDisabled} onReload={model.onReload} />

        <SaveRibbon
          disabled={model.saveDisabled}
          onReset={model.onReset}
          onSave={model.onSave}
          useTimeoutConfirmReset={true}
        />
      </>
    );
  }
}
