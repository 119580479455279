import styled from "@emotion/styled";
import { Minutes } from "@webkintai/core";
import classNames from "classnames";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { ReceptionStatus } from "../../../../models/common/ReceptionStatus";
import { getNendo } from "../../../../utils/calendar";
import { ExportableTable, RenderedMedia } from "../../../common/export-table/ExportableTable";
import { ButtonField } from "../../../common/form/ButtonField";
import { DateField } from "../../../common/form/DateField";
import { HHMMField } from "../../../common/form/HHMMField";
import { TextField } from "../../../common/form/TextField";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { ReceptionStatusSelectField } from "./ReceptionStatusSelectField";
import { SheetAcceptStatusSelectField } from "./SheetAcceptStatusSelectField";

export interface InterviewListPageTableVM {
  nendo: Date;
  rows: InterviewListPageTableRowVM[];
}

export interface InterviewListPageTableRowVM {
  id: string;
  onNavigateToKintai: () => void;
  onAddNewInterview: () => void;

  deptName: string;
  rankName: string;
  userId: string;
  userName: string;

  targetMonth: Date;
  lawOver: Minutes;
  sundayOnCount: number;

  fatigue?: {
    hasNoChange: boolean;

    acceptStatus: boolean;
    onChangeAcceptStatus: (value: boolean) => void;
    acceptStatusDisabled: boolean;

    submitDate: Date | undefined;
    onChangeSubmitDate: (value: Date | undefined) => void;
    submitDateDisabled: boolean;

    note: string;
    onChangeNote: (value: string) => void;
    noteDisabled: boolean;

    errors: string[];
  };

  interview?: {
    hasNoChange: boolean;

    receptionStatus: typeof ReceptionStatus.Type;
    onChangeReceptionStatus: (value: typeof ReceptionStatus.Type) => void;
    receptionStatusDisabled: boolean;

    reservedDate: Date | undefined;
    onChangeReservedDate: (value: Date | undefined) => void;
    reservedDateDisabled: boolean;
    reservedDateErrors: string[];

    actualDate: Date | undefined;
    onChangeActualDate: (value: Date | undefined) => void;
    actualDateDisabled: boolean;
    actualDateErrors: string[];

    note: string;
    onChangeNote: (value: string) => void;
    noteDisabled: boolean;
  };
}

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #ccc;
    text-align: center;
  }

  th {
    background-color: #efe;
  }
`;

const Error = styled.div`
  color: red;
`;

@observer
export class InterviewListPageTable extends React.Component<{ model: InterviewListPageTableVM }> {
  public render() {
    const { model } = this.props;
    const { nendo, rows } = model;

    if (rows.length === 0) {
      return <NoResultsSpread />;
    }

    return (
      <ExportableTable title={`${getNendo(nendo)}年度_産業医面談`}>
        {renderedOn => (
          <Table>
            <thead>
              {renderedOn !== "csv" && (
                <>
                  <tr>
                    <th rowSpan={2}>#</th>
                    <th rowSpan={2}>対象年月</th>

                    <th colSpan={4}>本人情報</th>

                    <th colSpan={2}>勤務情報（抜粋）</th>

                    <th colSpan={3}>疲労蓄積度チェック</th>

                    <th colSpan={4}>産業医面接</th>
                  </tr>
                  <tr>
                    {/* 本人情報 */}
                    <th>社員番号</th>
                    <th>氏名</th>
                    <th>所属</th>
                    <th>ランク</th>

                    {/* 勤務情報（抜粋） */}
                    <th>健康管理時間</th>
                    <th>日曜出勤回数</th>

                    {/* 疲労蓄積度チェック */}
                    <th>シート受領確認</th>
                    <th>提出日（社員記入）</th>
                    <th>備考</th>

                    {/* 産業医面接 */}
                    <th>受診状況</th>
                    <th>面接予定日</th>
                    <th>面接日</th>
                    <th>備考</th>
                  </tr>
                </>
              )}
              {renderedOn === "csv" && (
                <>
                  <tr>
                    <th>#</th>
                    <th>対象年月</th>

                    {/* 本人情報 */}
                    <th>(本人情報)社員番号</th>
                    <th>(本人情報)氏名</th>
                    <th>(本人情報)所属</th>
                    <th>(本人情報)ランク</th>

                    {/* 勤務情報（抜粋） */}
                    <th>(勤務情報)健康管理時間</th>
                    <th>(勤務情報)日曜出勤回数</th>

                    {/* 疲労蓄積度チェック */}
                    <th>(疲労蓄積度)シート受領確認</th>
                    <th>(疲労蓄積度)提出日（社員記入）</th>
                    <th>(疲労蓄積度)備考</th>

                    {/* 産業医面接 */}
                    <th>(産業医面接)受診状況</th>
                    <th>(産業医面接)面接予定日</th>
                    <th>(産業医面接)面接日</th>
                    <th>(産業医面接)備考</th>
                  </tr>
                </>
              )}
            </thead>
            <tbody>
              {rows.map((row, idx) => (
                <Row key={row.id} idx={idx} row={row} renderedOn={renderedOn} />
              ))}
            </tbody>
          </Table>
        )}
      </ExportableTable>
    );
  }
}

const Tr = styled.tr`
  &.Changed {
    background-color: #ff9;
  }
`;

const Td = styled.td`
  &.Changed {
    background-color: #ff9;
  }
`;

const Row: React.FunctionComponent<{
  idx: number;
  row: InterviewListPageTableRowVM;
  renderedOn: RenderedMedia;
}> = observer(props => {
  const { idx, row, renderedOn } = props;

  return (
    <Tr>
      <td>{idx + 1}</td>

      {/* 本人情報 */}
      <td>
        <a href="javascript: undefined" onClick={row.onNavigateToKintai}>
          {moment(row.targetMonth).format("YYYY年MM月")}
        </a>
      </td>
      <td>{row.userId}</td>
      <td>{row.userName}</td>
      <td>{row.deptName}</td>
      <td>{row.rankName}</td>

      {/* 勤務情報（抜粋） */}
      <td>
        <HHMMField mode="print" value={row.lawOver} />
      </td>
      <td>{row.sundayOnCount}</td>

      <FatigueCells row={row.fatigue} renderedOn={renderedOn} />
      <InterviewCells onAddNewInterview={row.onAddNewInterview} row={row.interview} renderedOn={renderedOn} />
    </Tr>
  );
});

const FatigueCells: React.FC<{ row: InterviewListPageTableRowVM["fatigue"]; renderedOn: RenderedMedia }> = ({
  row,
  renderedOn,
}) => {
  const className = classNames({ Changed: row && !row.hasNoChange });
  return (
    <>
      {!row && (
        <>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </>
      )}
      {row && (
        <>
          <Td className={className}>
            <SheetAcceptStatusSelectField
              mode={renderMode(renderedOn)}
              disabled={row.acceptStatusDisabled}
              value={row.acceptStatus}
              onChange={row.onChangeAcceptStatus}
            />
          </Td>
          <Td className={className}>
            <DateField
              mode={renderMode(renderedOn)}
              value={row.submitDate}
              disabled={row.submitDateDisabled}
              onChange={row.onChangeSubmitDate}
            />
            {row.errors.map(it => (
              <Error key={it}>{it}</Error>
            ))}
          </Td>
          <Td className={className}>
            <TextField
              mode={renderMode(renderedOn)}
              multiline={true}
              value={row.note}
              disabled={row.noteDisabled}
              onChange={row.onChangeNote}
            />
          </Td>
        </>
      )}
    </>
  );
};

const InterviewCells: React.FC<{
  onAddNewInterview: InterviewListPageTableRowVM["onAddNewInterview"];
  row: InterviewListPageTableRowVM["interview"];
  renderedOn: RenderedMedia;
}> = ({ onAddNewInterview, row, renderedOn }) => {
  const className = classNames({ Changed: row && !row.hasNoChange });
  return (
    <>
      {!row && (
        <>
          {renderedOn === "screen" && (
            <td colSpan={4}>
              <ButtonField mode="form" onClick={onAddNewInterview}>
                新規追加
              </ButtonField>
            </td>
          )}
          {renderedOn !== "screen" && (
            <>
              <td />
              <td />
              <td />
              <td />
            </>
          )}
        </>
      )}

      {row && (
        <>
          <Td className={className}>
            <ReceptionStatusSelectField
              mode={renderMode(renderedOn)}
              disabled={row.receptionStatusDisabled}
              value={row.receptionStatus}
              onChange={row.onChangeReceptionStatus}
            />
          </Td>
          <Td className={className}>
            <DateField
              mode={renderMode(renderedOn)}
              value={row.reservedDate}
              disabled={row.reservedDateDisabled}
              onChange={row.onChangeReservedDate}
            />
            {row.reservedDateErrors.map(it => (
              <Error key={it}>{it}</Error>
            ))}
          </Td>
          <Td className={className}>
            <DateField
              mode={renderMode(renderedOn)}
              value={row.actualDate}
              disabled={row.actualDateDisabled}
              onChange={row.onChangeActualDate}
            />
            {row.actualDateErrors.map(it => (
              <Error key={it}>{it}</Error>
            ))}
          </Td>
          <Td className={className}>
            <TextField
              mode={renderMode(renderedOn)}
              multiline={true}
              value={row.note}
              disabled={row.noteDisabled}
              onChange={row.onChangeNote}
            />
          </Td>
        </>
      )}
    </>
  );
};

const renderMode = (renderedOn: RenderedMedia) => {
  // dirty hack: "form" | "print" 型を与えるとTSが処理できないので "form" にしてる
  return (renderedOn === "screen" ? "form" : "print") as "form";
};
