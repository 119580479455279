import { flatMap } from "lodash-es";
import { types } from "mobx-state-tree";
import moment from "moment";

import { KintaiMonthlyError } from "../KintaiMonthlyError";
import { SPortDailyKintai } from "./SPortDailyKintai";
import { SPortMonthlyTotal } from "./SPortMonthlyTotal";

// const idPrefix = "SPortKintai_";

const model = types
  .model("SPortKintai", {
    userId: types.string,
    month: types.Date,
    total: SPortMonthlyTotal,
    monthlyErrors: types.array(KintaiMonthlyError),
    days: types.array(SPortDailyKintai),
  })
  .views(self => {
    return {
      get errors() {
        return [
          ...self.monthlyErrors.filter(it => it.isError).map(it => `（月報エラー）${it.message}`),
          ...flatMap(self.days, it => it.errors.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
        ];
      },
      get warnings() {
        return [
          ...self.monthlyErrors.filter(it => it.isWarning).map(it => `（月報警告）${it.message}`),
          ...flatMap(self.days, it => it.warnings.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
        ];
      },
      get infos() {
        return [
          ...self.monthlyErrors.filter(it => it.isInfo).map(it => `（月報お知らせ）${it.message}`),
          ...flatMap(self.days, it => it.infos.map(e => `（${moment(it.date).format("MM/DD")}）${e}`)),
        ];
      },
      get hasNoChange() {
        return self.days.map(it => it.hasNoChange).reduce((l, r) => l && r, true);
      },
    };
  })
  .actions(self => {
    return {
      setFixedTimes() {
        self.days.forEach(it => it.setFixedTimes());
      },
      reset() {
        self.days.forEach(it => it.reset());
      },
    };
  });
export const SPortKintai: SPortKintaiModelType = model;
type SPortKintaiInferredType = typeof model;
export interface SPortKintaiModelType extends SPortKintaiInferredType {}
