import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { RequiredApprovalErrorsKF } from "./RequiredApprovalErrorsKF";

export type MonthlyNotAppliedErrorsKFProps = {
  model: MonthlyNotAppliedErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyNotAppliedErrorsKFVM {
  monthlyNotAppliedErrors: string[];
}

export const MonthlyNotAppliedErrorsKF = observer((props: MonthlyNotAppliedErrorsKFProps) => {
  const { model, mode } = props;
  return <RequiredApprovalErrorsKF label="要承認" errors={model.monthlyNotAppliedErrors} mode={mode} />;
});
