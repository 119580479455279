import Help from "@webkintai/help/content/manuals/dashboard/interview_list.md";
import { observer } from "mobx-react";
import React from "react";

import { FilterTextbox } from "../../../common/FilterTextbox";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { SaveRibbon } from "../../../common/ribbon/SaveRibbon";

export interface InterviewListPageRibbonVM {
  nendo: Date;
  onChangeNendo: (value: Date) => void;
  nendoList: number[];

  filterText: string;
  onChangeFilterText: (value: string) => void;

  reloadDisabled: boolean;
  onReload: () => void;

  editAllowed: boolean;
  saveDisabled: boolean;
  onReset: () => void;
  onSave: () => void;
}

@observer
export class InterviewListPageRibbon extends React.Component<{ model: InterviewListPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <PageRibbonLine>
            <KintaiCalendarSelectorButton
              buttonProps={{ small: true }}
              unit="year"
              nendoList={model.nendoList}
              currentDate={model.nendo}
              onChangeDate={model.onChangeNendo}
            />
          </PageRibbonLine>
        </JumpRibbon>

        <ReloadRibbon disabled={model.reloadDisabled} onReload={model.onReload} />

        <FilterRibbon>
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
        </FilterRibbon>

        {model.editAllowed && (
          <SaveRibbon
            disabled={model.saveDisabled}
            onReset={model.onReset}
            onSave={model.onSave}
            useTimeoutConfirmReset={true}
          />
        )}
      </>
    );
  }
}
