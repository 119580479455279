import { types } from "mobx-state-tree";

import { LoadingStatusType } from "./LoadingStatusType";

export const LoadingStatus = types.enumeration("LoadingStatus", ["init", "loading", "failed", "loaded"]);

export const mostFailingLoadingStatus = (loadingStatus: LoadingStatusType[]) => {
  return loadingStatus.reduce(
    (l, r) => {
      if (l === "failed" || r === "failed") {
        return "failed";
      }
      if (l === "loading" || r === "loading") {
        return "loading";
      }
      return r;
    },
    "loaded" as LoadingStatusType,
  );
};
