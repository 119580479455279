import { FatigueChecksResult, PhysicianInterviewsResult } from "@webkintai/api";
import { flatMap, sortBy } from "lodash-es";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";
import moment from "moment";

import { FatigueCheckApi } from "../../services/api/FatigueCheckApi";
import { PhysicianInterviewApi } from "../../services/api/PhysicianInterviewApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate, toApiLocalDate } from "../../utils/api";
import { getNendo } from "../../utils/calendar";
import { hasNoChangeReduce } from "../../utils/model";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { apiValueToReceptionStatus } from "../common/ReceptionStatus";
import { MonthlyFatigueCheckList } from "./MonthlyFatigueCheckList";
import { MonthlyInterviewPlan } from "./MonthlyInterviewPlan";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("ProfileInterviewPageModel", {
      ivMonths: types.array(MonthlyInterviewPlan),
      ftgMonths: types.array(MonthlyFatigueCheckList),
      loadingStatus: types.optional(LoadingStatus, "loading"),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        /**
         * 受診回答を入力する必要があるか？
         */
        get needsInterviewAnswer() {
          return self.ivMonths.filter(it => it.needsAnswer).length > 0;
        },
        /**
         * 疲労蓄積度チェックシートを提出する必要があるか？
         */
        get needsFatigueCheckAnswer() {
          return self.ftgMonths.filter(it => it.needsAnswer).length > 0;
        },
        /**
         * エントリがあるかどうか
         */
        get hasContent() {
          return self.ivMonths.length > 0 || self.ftgMonths.length > 0;
        },
        get hasNoChange() {
          return hasNoChangeReduce([
            ...self.ivMonths.map(it => it.hasNoChange),
            ...self.ftgMonths.map(it => it.hasNoChange),
          ]);
        },
        get targetNendoList() {
          const curYear = getNendo(new Date());
          return [curYear - 1, curYear];
        },
        get targetMonthLowerBoundary() {
          // 月単位で一年前
          return moment(new Date())
            .subtract(1, "year")
            .startOf("month")
            .toDate();
        },
        /**
         * 今日以降で一番近い面接日
         * （産業医面接予約していますメッセージ用）
         */
        get nearestFutureReservedDate() {
          const now = moment(timeProvider().currentTime);
          const found = sortBy(
            self.ivMonths
              .filter(it => it.inReserved && it.reservedDate)
              .filter(it => moment(it.reservedDate).diff(now, "days") >= 0),
            it => it.reservedDate!.getTime(),
          )[0];
          return found && found.reservedDate;
        },
        /**
         * 今日より前の一番新しい面接日
         * （産業医面接確認していますメッセージ用）
         */
        get pastLatestReservedDate() {
          const now = moment(timeProvider().currentTime);
          const found = sortBy(
            self.ivMonths
              .filter(it => it.inReserved && it.reservedDate)
              .filter(it => moment(it.reservedDate).diff(now, "days") < 0),
            it => -it.reservedDate!.getTime(),
          )[0];
          return found && found.reservedDate;
        },
      };
    })
    .actions(self => {
      const physicianInterviewApi = () => getDI(self, PhysicianInterviewApi);
      const fatigueCheckApi = () => getDI(self, FatigueCheckApi);
      const appNotifier = () => getDI(self, AppNotifier);

      const save = flow(function*() {
        self.loadingStatus = "loading";

        try {
          yield savePhysicianInterview();
          yield saveFatigueCheck();
        } finally {
          self.loadingStatus = "loaded";
        }

        yield load();
      });

      const savePhysicianInterview = flow(function*() {
        let progress = "";
        const targets = self.ivMonths.filter(it => !it.hasNoChange);
        if (targets.length === 0) {
          return;
        }

        try {
          const api = physicianInterviewApi();
          for (const month of self.ivMonths.filter(it => !it.hasNoChange)) {
            progress = `${moment(month.month).format("YYYY年MM月")}`;
            yield api.reservePhysicianInterviewsForMe(month.month, {
              reserveDay: toApiLocalDate(month.reservedDate)!,
            });
          }
          appNotifier().info({ message: "産業医面談予定を保存しました" });
        } catch (exception) {
          appNotifier().error({ message: `産業医面談予定の保存に失敗しました: 部分: ${progress}`, exception });
          throw exception;
        }
      });

      const saveFatigueCheck = flow(function*() {
        let progress = "";
        const targets = self.ftgMonths.filter(it => !it.hasNoChange);
        if (targets.length === 0) {
          return;
        }

        try {
          const api = fatigueCheckApi();
          for (const month of targets) {
            progress = `${moment(month.month).format("YYYY年MM月")}`;
            yield api.setFatigueSubmitStatusForMe(month.month, {
              submittedDate: toApiLocalDate(month.submitDate)!,
            });
          }
          appNotifier().info({ message: "疲労蓄積度の提出状況を保存しました" });
        } catch (exception) {
          appNotifier().error({ message: `疲労蓄積度の提出状況の保存に失敗しました: 部分: ${progress}`, exception });
          throw exception;
        }
      });

      const loadIfNeeded = () => {
        return self.loadingStatus === "loaded" ? new Promise(done => done()) : load();
      };

      // 1年前のもののみ集める
      const filterRecents = <T extends { ymd: string }>(list: T[]) =>
        list.filter(it => fromApiDate(it.ymd).getTime() >= self.targetMonthLowerBoundary.getTime());

      const load = flow(function*() {
        self.loadingStatus = "loading";

        try {
          yield Promise.all([loadPhysicianInterview(), loadFatigueChecks()]);
          self.loadingStatus = "loaded";
        } catch (exception) {
          console.error("error while loading interview/fatigue data", exception);
          self.loadingStatus = "failed";
        }
      });

      const loadPhysicianInterview = flow(function*() {
        const api = physicianInterviewApi();

        const results = (yield Promise.all(
          self.targetNendoList.map(nendo => {
            return api.getPhysicianInterviewsForMe(nendo);
          }),
        )) as PhysicianInterviewsResult[];

        const interviews = filterRecents(flatMap(results, result => result.interviews));

        applySnapshot(
          self.ivMonths,
          interviews.map(it => ({
            month: fromApiDate(it.ymd),
            lawOver: it.lawOver,
            sundayOnCount: it.sundayOnCount,
            receptionStatus: apiValueToReceptionStatus(it.receptionStatus.value) || "未対応",

            reservedDate: fromApiDate(it.reservedDate),
            org_reservedDate: fromApiDate(it.reservedDate),
          })),
        );
      });

      const loadFatigueChecks = flow(function*() {
        const api = fatigueCheckApi();

        const results = (yield Promise.all(
          self.targetNendoList.map(nendo => {
            return api.getFatigueChecksForMe(nendo);
          }),
        )) as FatigueChecksResult[];

        const fatigueChecks = filterRecents(flatMap(results, result => result.fatigueChecks));

        applySnapshot(
          self.ftgMonths,
          fatigueChecks.map(it => ({
            month: fromApiDate(it.ymd),
            lawOver: it.lawOver,
            sundayOnCount: it.sundayOnCount,

            submitDate: fromApiDate(it.submittedDate),
            org_submitDate: fromApiDate(it.submittedDate),
          })),
        );
      });

      const reset = () => {
        self.ivMonths.forEach(m => m.reset());
        self.ftgMonths.forEach(m => m.reset());
      };

      return {
        load,
        loadIfNeeded,
        save,
        reset,
        route(pathFragment: string) {
          // DO NOTHING
        },
      };
    }),
  {},
);

export type ProfileInterviewPageModelType = typeof ProfileInterviewPageModel.Type;

export const ProfileInterviewPageModelSymbol = "ProfileInterviewPageModel_Symbol";
export const ProfileInterviewPageModel: ProfileInterviewPageModelModelType = model;
type ProfileInterviewPageModelInferredType = typeof model;
export interface ProfileInterviewPageModelModelType extends ProfileInterviewPageModelInferredType {}
