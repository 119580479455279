import { IModelType, types } from "mobx-state-tree";

import { dateEq } from "../../utils/date";
import { hasNoChangeReduce } from "../../utils/model";
import { ReceptionStatus } from "../common/ReceptionStatus";
import { typesMinutes } from "../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("MonthlyInterviewPlan", {
    month: types.Date,
    lawOver: types.maybe(typesMinutes),
    sundayOnCount: types.maybe(types.number),
    receptionStatus: ReceptionStatus,

    reservedDate: types.maybe(types.Date),
    org_reservedDate: types.maybe(types.Date),
  })
  .views(self => {
    return {
      get needsAnswer() {
        return self.receptionStatus === "未対応" && self.org_reservedDate === undefined;
      },
      get inReserved() {
        return self.receptionStatus === "予約済";
      },
      get hasNoChange() {
        if (self.reservedDate === undefined) {
          return true;
        }
        return hasNoChangeReduce([dateEq(self.reservedDate, self.org_reservedDate)]);
      },
    };
  })
  .actions(self => {
    return {
      setReservedDate(value?: Date) {
        self.reservedDate = value;
      },
      reset() {
        self.reservedDate = self.org_reservedDate;
      },
    };
  });

export type NendoInterviewPlanType = typeof MonthlyInterviewPlan.Type;

export const NendoInterviewPlanSymbol = "NendoInterviewPlan_Symbol";
export const MonthlyInterviewPlan: MonthlyInterviewPlanModelType = model;
type MonthlyInterviewPlanInferredType = typeof model;
export interface MonthlyInterviewPlanModelType extends MonthlyInterviewPlanInferredType {}
