import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { ButtonField } from "../../../common/form/ButtonField";
import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcAbsencesCellVM {
  onOpenAbsenceDialog: () => void;
  absenceHasNoChange: boolean;
  absenceText: string;
}

@observer
export class AcAbsencesCell extends React.Component<{ model: AcAbsencesCellVM }> {
  public render() {
    const { model } = this.props;

    return (
      <Container>
        <LabelField mode="cell" cellPosition={NULL_CELL_POSITION} hasNoChange={model.absenceHasNoChange}>
          {model.absenceText}
        </LabelField>
        <div style={{ flex: "none" }}>
          <ButtonField mode="cell" cellPosition={NULL_CELL_POSITION} onClick={model.onOpenAbsenceDialog}>
            開く
          </ButtonField>
        </div>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
