import { observer } from "mobx-react";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { PHPagGridRowVM } from "./PHPageGrid";

@observer
export class PHDayCountDisplayCell extends React.Component<{ model: PHPagGridRowVM; col: number }> {
  public render() {
    const data = this.props.model.perYearVacations[this.props.col];
    return (
      <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
        {data.hours ? data.hours.dayCount : ""}
      </LabelField>
    );
  }
}
