import { HourBasedDays } from "@webkintai/core";
import { applySnapshot, getSnapshot, IModelType, types } from "mobx-state-tree";

import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { valueOnlyKintaiInputItemState } from "../kintaiinput/ValueOnlyKintaiItem";
import { VacationTotalValues } from "./VacantionTotalValues";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const VacationTotal = types
  .model("VacationTotal", {
    origin: VacationTotalValues,
    computed: VacationTotalValues,
  })
  .views(self => {
    return {
      get mTotalTakenRefreshDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenRefreshDays);
      },
      get mTotalTakenPositiveDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenPositiveDays);
      },
      get mTotalTakenPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenPaidDays);
      },
      get mTotalTakenPlainPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenPlainPaidDays);
      },
      get mTotalTakenHourlyLeave(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenHourlyLeave);
      },
      get mTotalTakenNursingcareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenNursingcareDays);
      },
      get mTotalTakenNursingcareHourlyLeave(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenHourlyNursingcare);
      },
      get mTotalTakenFamilycareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenFamilycareDays);
      },
      get mTotalTakenFamilycareHourlyLeave(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenHourlyFamilycare);
      },
      get mTotalTakenSummerDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenSummerDays);
      },
      get mTotalTakenSpDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenSpDays);
      },
      get mTotalTakenOtherDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenOtherDays);
      },
      get mTotalTakenOtherDaysExcludingNuFa(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenOtherDaysExcludingNuFa);
      },
      get mTotalTakenOtherHours(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.takenOtherHours);
      },
      get mTotalRestRefreshDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.restRefreshDays);
      },
      get mTotalRestPositiveDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.restPositiveDays);
      },
      get mTotalRestPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.restPaidDays);
      },
      get mTotalRestSummerDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.restSummerDays);
      },
    };
  })
  .actions(self => {
    return {
      reset() {
        applySnapshot(self.computed, getSnapshot(self.origin));
      },
    };
  });

export type VacationTotalValueType = typeof VacationTotal.Type;
