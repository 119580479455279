import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

export const DashboardWidgetLastUpdate: React.FC<{
  lastUpdate: Date | undefined;
  children?: never;
}> = ({ lastUpdate }) => {
  if (lastUpdate === undefined) {
    return (
      <Container>
        <Icon iconSize={12} icon="updated" />
        <TimeContainer>----/--/-- --:--</TimeContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Icon icon="updated" />
      <TimeContainer>{moment(lastUpdate).format("YYYY/MM/DD HH:mm")}</TimeContainer>
    </Container>
  );
};

const Container = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  place-items: center;
  color: #666;
  font-family: monospace;
  font-size: 0.75rem;
`;

const TimeContainer = styled.div`
  margin-left: 0.5rem;
`;
