import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { ErrorOverlay } from "./ErrorOverlay";
import { cellStyle } from "./FormCommon";

const Cell = styled.div`
  ${cellStyle} text-align: center;
`;

const Form = styled.div``;

export type LabelFieldProps = {
  className?: string;
  hasNoChange?: boolean;
  title?: string;
} & CellFormPrintAdaptable;

export class HasNoChangeField extends React.PureComponent<LabelFieldProps> {
  private ref = React.createRef<HTMLLabelElement>();

  public render() {
    const { props } = this;
    const { className, title } = this.props;
    const isChanged = props.hasNoChange === false;

    if (props.mode === "print") {
      return title || isChanged ? "*" : "";
    }

    if (props.mode === "cell") {
      const onFocus = () => {
        if (!props.isActive && props.onCellFocused) {
          props.onCellFocused(props.cellPosition);
        }
      };
      if (props.isActive) {
        if (this.ref.current) {
          this.ref.current!.focus();
        }
      }

      return (
        <ErrorOverlay mode="cell" warnings={props.warnings} errors={props.errors} infos={props.infos}>
          <Cell
            className={classNames(className)}
            style={{
              backgroundColor: isChanged ? "yellow" : "inherit",
            }}
            onClick={onFocus}
            ref={this.ref as any}>
            {title || (isChanged ? "*" : "")}
          </Cell>
        </ErrorOverlay>
      );
    }

    return <Form className={classNames(className)}>{isChanged ? "*" : ""}</Form>;
  }
}
