import { types } from "mobx-state-tree";

import { boolEq, regularRawItemState } from "./regularItemState";

export const workplaceItemState = (name: string, setterName: string) => {
  const originalType = regularRawItemState(name, setterName, boolEq, "workplaces");
  const wrapped: typeof originalType = originalType.extend(self => {
    return {
      views: {
        get disabled(): boolean {
          return self.input.disableWorkplaceInput;
        },
      },
    };
  });
  return types.optional(wrapped, {});
};
