import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";
import { NotImplemented } from "../../common/NotImplemented";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ApplicationsPageRibbon, ApplicationsPageRibbonVM } from "./ApplicationsPageRibbon";
import { ApplicationsPageGridVM } from "./grid/ApplicationsPageGrid";

export interface ApplicationsPageVM {
  ribbonModel: ApplicationsPageRibbonVM;
  gridModel: ApplicationsPageGridVM;
}

@observer
export class ApplicationsPage extends React.Component<{ model: ApplicationsPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<ApplicationsPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("勤怠申請一覧")}</title>
        </Helmet>

        <NotImplemented />

        {/* <ApplicationsPageGrid model={model.gridModel} /> */}
      </PageRibbon>
    );
  }
}
