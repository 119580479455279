import { keyBy, sortBy } from "lodash-es";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";

import { RankApi } from "../../../services/api/RankApi";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { TimeProvider } from "../../../services/TimeProvider";
import { getNendoAsDate } from "../../../utils/calendar";
import { getDI } from "../../common/getDI";
import { prefixedIdType } from "../../utils";
import { NendoRanks, NendoRanksType } from "../NendoRanks";
import { idNendoRankInput, NendoRankInput, NendoRankInputType } from "./NendoRankInput";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoRanks_";

const model = types
  .model("NendoRanksInput", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    ranks: types.map(NendoRankInput),
    origin: types.maybe(types.reference(NendoRanks)),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);
    return {
      get ranksArray(): NendoRankInputType[] {
        return sortBy([...self.ranks.values()], [it => (it.persisted ? 1 : 0), it => it.rankCode]);
      },
      get hasNoChange() {
        return this.ranksArray.map(rank => rank.hasNoChange).reduce((l, r) => l && r, true);
      },
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
    };
  })
  .actions(self => {
    const rankApi = () => getDI(self, RankApi);
    const appNotifier = () => getDI(self, AppNotifier);

    const addNewRank = (rankCode: string) => {
      const id = idNendoRankInput(self.nendo, rankCode);

      if (!rankCode.match(/^..$/)) {
        appNotifier().error({ message: `ランクコードは2文字を入力してください。` });
        return;
      }

      if (self.ranks.has(id)) {
        appNotifier().error({ message: `既にそのランク(${rankCode})は登録されています。` });
        return;
      }

      self.ranks.put(
        NendoRankInput.create({
          id,
          nendo: self.nendo,
          rankCode,
          rankName: "",

          roleCode: "user",
          persisted: false,
        }),
      );
    };

    const copy = () => {
      const ranks = [...self.origin!.ranks.values()].map(rank => ({
        id: idNendoRankInput(rank.nendo, rank.rankCode),
        nendo: self.nendo,
        rankCode: rank.rankCode,
        rankName: rank.rankName,
        origin: rank.id,
        workingType: rank.workingType,
        rankPosition: rank.rankPosition,
        roleCode: rank.roleCode,
        persisted: true,
      }));
      applySnapshot(self.ranks, keyBy(ranks, "id"));
    };

    const save = flow(function*() {
      // ランクの大本にまつわる変更
      const defChangeDefs = self.ranksArray.filter(it => !it.hasNoChangeInRankDef);
      yield rankApi().upsertRankDef(
        defChangeDefs.map(rank => ({
          rankCode: rank.rankCode,
          rankName: rank.rankName,
        })),
      );

      // 年度にまつわる変更
      const nendoChangedDefs = self.ranksArray.filter(it => !it.hasNoChangeInNendoDef);
      yield rankApi().saveRank(
        self.nendo,
        nendoChangedDefs.map(rank => ({
          rankCode: rank.rankCode,
          position: rank.rankPosition as any,
          role: rank.roleCode as any,
          workingType: rank.workingType as any,
        })),
      );
    });

    return {
      setOrigin(origin: NendoRanksType) {
        self.origin = origin;
      },
      addNewRank,
      copy,
      save,
    };
  });

export const idNendoRanksInput = (nendo: number) => `${idPrefix}${nendo}`;

export type NendoRanksInputType = typeof NendoRanksInput.Type;
export const copyBetweenNendoRanksInputs = (src: NendoRanksInputType, dest: NendoRanksInputType) => {
  for (const srcRank of src.ranksArray) {
    const rankCode = srcRank.rankCode;
    const destRank = dest.ranksArray.find(it => it.rankCode === rankCode);
    if (!destRank) {
      continue;
    }

    destRank.setRankPosition(srcRank.rankPosition);
    destRank.setRoleCode(srcRank.roleCode);
    destRank.setWorkingType(srcRank.workingType);
  }
};

export const NendoRanksInputSymbol = "NendoRanksInput_Symbol";
export const NendoRanksInput: NendoRanksInputModelType = model;
type NendoRanksInputInferredType = typeof model;
export interface NendoRanksInputModelType extends NendoRanksInputInferredType {}
