import { observer } from "mobx-react";
import React, { useCallback } from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { LabelField } from "../../../form/LabelField";
import { EnterLeaveTimeField } from "../common/EnterLeaveTimeField";

export type DoorLeaveKFProps = {
  model: DoorLeaveKFVM;
} & CellFormPrintAdaptable;

export interface DoorLeaveKFVM {
  date: Date;
  doorLeaveTime: Date | undefined;
  onApplyDoorLeave: () => void;
}

export const DoorLeaveKF = observer((props: DoorLeaveKFProps) => {
  const { model } = props;
  const { date, doorLeaveTime, onApplyDoorLeave } = model;

  const onClick = useCallback(() => {
    if (onApplyDoorLeave && doorLeaveTime) {
      onApplyDoorLeave();
    }
  }, [onApplyDoorLeave, doorLeaveTime]);

  return <EnterLeaveTimeField {...props} {...{ date, onClick }} enterLeaveTime={doorLeaveTime} label="退室時間" />;
});
