import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type OffKFProps = {
  model: OffKFVM;
} & CellFormPrintAdaptable;

export interface OffKFVM {
  off: KintaiInputItemState<Minutes | undefined>;
}

export const OffKF = observer((props: OffKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.off);

  return <HHMMField {...props} label="遅早外時間" {...itemState} />;
});
