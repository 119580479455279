import { KintaiExportsResult } from "@webkintai/api";
import { Days, HourBasedDays, Minutes } from "@webkintai/core";
import { sortBy } from "lodash-es";
import { applySnapshot, flow, IModelType, SnapshotIn, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { KintaiApi } from "../../services/api/KintaiApi";
import { AppRouter } from "../../services/AppRouter";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate } from "../../utils/api";
import { dateOf, lastMonth } from "../../utils/date";
import { filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { ExportEntry, ExportEntryType, idExportEntry } from "./ExportEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("ExportPageModel", {
      /** 最終検索条件 */
      lastSearchCondition: types.optional(types.string, ""),
      /** 対象年月 */
      targetMonth: types.optional(types.Date, lastMonth()),

      /** フィルタテキスト */
      filterText: types.optional(types.string, ""),

      /** 検索結果 */
      searchResult: types.array(ExportEntry),
      loadingStatus: types.optional(LoadingStatus, "init"),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get searchCondition() {
          return `${self.targetMonth.toISOString()}`;
        },
        get filteredRows() {
          const searchWords = filterTextToSearchWords(self.filterText);
          return sortBy(
            self.searchResult.filter(it => it.filterMatched(searchWords)),
            it => it.filterMatched(searchWords),
            row => row.userId,
          );
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const kintaiApi = () => getDI(self, KintaiApi);

      const triggerLoad = flow(function*(force = false) {
        if (!force && self.lastSearchCondition === self.searchCondition) {
          return;
        }
        self.lastSearchCondition = self.searchCondition;

        try {
          self.loadingStatus = "loading";

          const { targetMonth } = self;
          const result: KintaiExportsResult = yield kintaiApi().exportKintai(targetMonth);
          const searchResultContent: Array<SnapshotIn<ExportEntryType>> = result.reports.map(it => {
            const { user } = it;
            return {
              id: idExportEntry(targetMonth, user.userId),
              userId: user.userId,
              targetMonth,

              userName: user.userName || "",
              companyCode: it.companyCode,
              salaryCalcGroup: it.salaryCalcGroup,
              processingType: it.processingType,
              salaryMonth: fromApiDate(it.salaryMonth),
              paymentDate: fromApiDate(it.paymentDate),

              normalDays: HourBasedDays.apiValueOf(it.normalDays),
              sickoutDays: Days.apiValueOf(it.sickoutDays),
              compAndAllNightOffDays: Days.apiValueOf(it.compAndAllNightOffDays),

              paidOffDays: HourBasedDays.apiValueOf(it.paidOffDays),
              spDays: HourBasedDays.apiValueOf(it.spDays),
              refreshDays: Days.apiValueOf(it.refreshDays),
              positiveDays: Days.apiValueOf(it.positiveDays),
              summerDays: Days.apiValueOf(it.summerDays),

              off: Minutes.apiValueOf(it.off),
              midnight: Minutes.apiValueOf(it.midnight),
              holiday: Minutes.apiValueOf(it.holiday),

              liquidateSum: Minutes.apiValueOf(it.liquidateSum),

              spWeekday: Minutes.apiValueOf(it.spWeekday),
              spWeekdayMidnight: Minutes.apiValueOf(it.spWeekdayMidnight),
              spHoliday: Minutes.apiValueOf(it.spHoliday),
              spHolidayMidnight: Minutes.apiValueOf(it.spHolidayMidnight),

              overSixtyFlexHoliday: Minutes.apiValueOf(it.overSixtyFlexHoliday),
              overSixtyLiquidate: Minutes.apiValueOf(it.overSixtyLiquidate),
              allNightOff: Minutes.apiValueOf(it.allNightOff),

              hourlyLeave: HourBasedDays.apiValueOf(it.hourlyLeave),
              hourlyNursingcare: HourBasedDays.apiValueOf(it.hourlyNursingcare),
              hourlyFamilycare: HourBasedDays.apiValueOf(it.hourlyFamilycare),

              workplaceZaitakuDays: HourBasedDays.apiValueOf(it.workplaceZaitakuDays),
              workplaceSyussyaDays: HourBasedDays.apiValueOf(it.workplaceSyussyaDays),
            };
          });
          applySnapshot(self.searchResult, searchResultContent);

          self.loadingStatus = "loaded";
        } catch (exception) {
          self.loadingStatus = "failed";
        }
      });

      const reload = () => {
        triggerLoad(true);
      };

      return {
        route(pathFragment: string) {
          const { targetYear, targetMonth } = fromPaths.dashboard.export.ofDate.index(pathFragment);

          if (targetYear && targetMonth) {
            self.targetMonth = dateOf(targetYear, targetMonth);
          }

          triggerLoad();
        },
        navigateToMonth(value: Date) {
          appRouter().navigate(paths.dashboard.export.ofDate(value).index());
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
        reload,
      };
    }),
  {},
);

export type ExportPageModelType = typeof ExportPageModel.Type;

export const ExportPageModelSymbol = "ExportPageModel_Symbol";
export const ExportPageModel: ExportPageModelModelType = model;
type ExportPageModelInferredType = typeof model;
export interface ExportPageModelModelType extends ExportPageModelInferredType {}
