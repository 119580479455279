import { 介護時休, 時単休, 看護時休 } from "@webkintai/bunrui";
import { flatMap } from "lodash-es";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { RankPosition } from "../../../../../models/common/RankPosition";
import { MainKintaiType } from "../../../../../models/kintai/MainKintaiType";
import { GridSize } from "../../../table/GridSize";
import { GridTable } from "../../../table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../table/TableColumnDef";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import { MSetAllDaysBunrui, MSetAllDaysBunruiVM } from "../../monthly/components/MSetAllDaysBunrui";
import { MSetAllDaysDoorEnter, MSetAllDaysDoorEnterVM } from "../../monthly/components/MSetAllDaysDoorEnter";
import { MSetAllDaysDoorLeave, MSetAllDaysDoorLeaveVM } from "../../monthly/components/MSetAllDaysDoorLeave";
import { MonthlyHeadingKF, MonthlyHeadingKFVM } from "../../monthly/fields/MonthlyHeadingKF";
import { PJRBodyActualTimeKF } from "../../pjreport/fields/PJRBodyActualTimeKF";
import { PJRBodyCellTimeKF } from "../../pjreport/fields/PJRBodyCellTimeKF";
import { PJRBodyNotPopulatedTimeKF } from "../../pjreport/fields/PJRBodyNotPopulatedTimeKF";
import { PJRHeaderNumberKF } from "../../pjreport/fields/PJRHeaderNumberKF";
import { PJRHeaderPjNameKF } from "../../pjreport/fields/PJRHeaderPjNameKF";
import { RegularDailyItemsVisibility } from "../../visibility/RegularDailyItemsVisibility";
import {
  HeaderLabelHourlyLeave,
  HeaderLabelRest,
  HeaderLabelUnused,
  HeaderLabelWorkplace,
} from "../common/KintaiInputHeaderLabels";
import { ActualKF } from "../fields/ActualKF";
import { AllNightOffKF } from "../fields/AllNightOffKF";
import { ArrivalKF } from "../fields/ArrivalKF";
import { CkrpEnterKF } from "../fields/CkrpEnterKF";
import { CkrpLeaveKF } from "../fields/CkrpLeaveKF";
import { DateKF } from "../fields/DateKF";
import { DiffKF } from "../fields/DiffKF";
import { DoorEnterKF } from "../fields/DoorEnterKF";
import { DoorLeaveKF } from "../fields/DoorLeaveKF";
import { FlexKF } from "../fields/FlexKF";
import { GrowingOverKF } from "../fields/GrowingOverKF";
import { HolidayKF } from "../fields/HolidayKF";
import { HourlyFamilycareKF } from "../fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../fields/hourlyleave/HourlyNursingcare";
import { LeaveKF } from "../fields/LeaveKF";
import { MidnightKF } from "../fields/MidnightKF";
import { OffKF } from "../fields/OffKF";
import { OverHolidayKF } from "../fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../fields/OverHolidayMidnightKF";
import { OverWeekdayKF } from "../fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../fields/RemarksKF";
import { RestKF } from "../fields/RestKF";
import { SpHolidayKF } from "../fields/SpHolidayKF";
import { SpHolidayMidnightKF } from "../fields/SpHolidayMidnightKF";
import { SpWeekdayKF } from "../fields/SpWeekdayKF";
import { SpWeekdayMidnightKF } from "../fields/SpWeekdayMidnightKF";
import { TransDayKF } from "../fields/TransDayKF";
import { TypesKF } from "../fields/TypesKF";
import { UnusedRestNightKF } from "../fields/unused-rest/UnusedRestNightKF";
import { UnusedRestSunsetKF } from "../fields/unused-rest/UnusedRestSunsetKF";
import { WorkplaceSonotaKF } from "../fields/workplace/WorkplaceSonotaKF";
import { WorkplaceSyussyaKF } from "../fields/workplace/WorkplaceSyussyaKF";
import { WorkplaceZaitakuGT4HKF } from "../fields/workplace/WorkplaceZaitakuGT4HKF";
import { WorkplaceZaitakuLE4HKF } from "../fields/workplace/WorkplaceZaitakuLE4HKF";
import { KintaiGridMovingVM } from "../KintaiGridMovingVM";
import { RegularDailyVM } from "./RegularDailyVM";

export type RegularDailyKintaiGridVM = {
  rankPosition: typeof RankPosition.Type;
  kintaiType: typeof MainKintaiType.Type;
  showsProjectReport: boolean;
  showsCkrpRecords: boolean;
  regularRows: RegularDailyVM[];
  regularErrors: string[];
  regularWarnings: string[];
} & KintaiGridMovingVM &
  MonthlyHeadingKFVM &
  MSetAllDaysBunruiVM &
  MSetAllDaysDoorEnterVM &
  MSetAllDaysDoorLeaveVM;

@observer
export class RegularDailyKintaiGrid extends React.Component<{ model: RegularDailyKintaiGridVM }> {
  public render() {
    // console.log("RegularDailyKintaiGrid#render()");
    const { model } = this.props;
    const { regularRows: rows, showsProjectReport } = model;
    const firstRow = rows[0]!;

    return (
      <GridTable
        // 月報のカラムが増えると描画が乱れるので無理やり再描画させる
        key={showsProjectReport ? `Pillars_${firstRow.dailyPillars.length}` : "b"}
        hideCount={true}
        rowIdGenerator={this.rowIdGenerator}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        onMoveCell={model.onMoveCell}
        activeCell={model.pos}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { model } = this.props;
    const { regularRows: rows } = model;

    return rows[rowIndex - 1].date.getTime().toString();
  };

  @computed
  private get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { regularRows: rows, showsProjectReport } = model;

    return [
      {
        id: "date",
        width: GridSize.dateAndDayNameField,
        renderHeader: () => {
          return <MonthlyHeadingKF model={model} />;
        },
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <DateKF
              mode="cell"
              errors={row.errors}
              warnings={row.warnings}
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      ...((showsProjectReport
        ? [
            {
              id: "pjr_actual",
              width: GridSize.hhmmField,
              renderHeader: () => "就業時間",
              renderBody: (pos, isActive) => {
                const row = rows[pos.rowIndex - 1];
                return (
                  <PJRBodyActualTimeKF
                    mode="cell"
                    isActive={isActive}
                    cellPosition={pos}
                    onCellFocused={model.onCellFocused}
                    model={row}
                  />
                );
              },
            },
            {
              id: "pjr_notpopulated",
              width: GridSize.hhmmField,
              renderHeader: () => "その他間接",
              renderBody: (pos, isActive) => {
                const row = rows[pos.rowIndex - 1];
                return (
                  <PJRBodyNotPopulatedTimeKF
                    mode="cell"
                    isActive={isActive}
                    cellPosition={pos}
                    onCellFocused={model.onCellFocused}
                    model={row}
                  />
                );
              },
            },
          ]
        : []) as TableColumnDef[]),
    ];
  }

  @computed
  private get bodyColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { regularRows: rows, kintaiType, showsProjectReport, showsCkrpRecords, rankPosition } = model;
    const firstRow = rows[0]!;

    const visibility = new RegularDailyItemsVisibility(model.month, model.kintaiType, model.rankPosition);

    const columns: TableColumnDef[] = [];

    if (showsProjectReport) {
      columns.push(
        ...flatMap(firstRow.dailyPillars, (mPillar, pillarIdx) =>
          flatMap(
            mPillar.cells,
            (headerDailyPillar, cellIdx) =>
              ({
                id: `projectReport_${mPillar.id}_${cellIdx}`,
                width: GridSize.hhmmField,
                headerColSpan: cellIdx === 0 ? 3 : undefined,
                renderHeader: () =>
                  cellIdx === 0 ? (
                    <div>
                      <PJRHeaderNumberKF model={mPillar} />
                      <br />
                      <PJRHeaderPjNameKF mode="cell" model={mPillar} />
                    </div>
                  ) : (
                    false
                  ),
                renderBody: (pos, isActive) => {
                  const row = rows[pos.rowIndex - 1];
                  const dailyPillar = row.dailyPillars[pillarIdx];
                  return (
                    dailyPillar && (
                      <PJRBodyCellTimeKF
                        mode="cell"
                        isActive={isActive}
                        cellPosition={pos}
                        onCellFocused={model.onCellFocused}
                        model={row.dailyPillars[pillarIdx].cells[cellIdx]}
                      />
                    )
                  );
                },
              } as TableColumnDef),
          ),
        ),
      );
      return columns;
    }

    columns.push(
      {
        id: "arrival",
        width: GridSize.hhmmField,
        renderHeader: () => "出勤",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <ArrivalKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "leave",
        width: GridSize.hhmmField,
        renderHeader: () => "退勤",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <LeaveKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "doorenter",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <div>
            <div>入室</div>
            <div>
              <MSetAllDaysDoorEnter model={model} />
            </div>
          </div>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <DoorEnterKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
      {
        id: "doorleave",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <div>
            <div>退室</div>
            <div>
              <MSetAllDaysDoorLeave model={model} />
            </div>
          </div>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <DoorLeaveKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      },
    );

    if (showsCkrpRecords) {
      columns.push(
        {
          id: "doorenter",
          width: GridSize.hhmmField,
          renderHeader: () => (
            <div>
              <div>
                ここレポ
                <br />
                出勤
              </div>
            </div>
          ),
          renderBody: (pos, isActive) => {
            const row = rows[pos.rowIndex - 1];
            return (
              <CkrpEnterKF
                mode="cell"
                isActive={isActive}
                cellPosition={pos}
                onCellFocused={model.onCellFocused}
                model={row}
              />
            );
          },
        },
        {
          id: "doorleave",
          width: GridSize.hhmmField,
          renderHeader: () => (
            <div>
              <div>
                ここレポ
                <br />
                退勤
              </div>
            </div>
          ),
          renderBody: (pos, isActive) => {
            const row = rows[pos.rowIndex - 1];
            return (
              <CkrpLeaveKF
                mode="cell"
                isActive={isActive}
                cellPosition={pos}
                onCellFocused={model.onCellFocused}
                model={row}
              />
            );
          },
        },
      );
    }

    columns.push({
      id: "appStatus",
      width: GridSize.appStatus,
      renderHeader: () => "勤怠申請状況",
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <AppStageKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row.dailyApp}
          />
        );
      },
    });

    if (visibility.workplace) {
      const defs: Array<{
        code: string;
        label: React.ReactNode;
        body:
          | typeof WorkplaceZaitakuGT4HKF
          | typeof WorkplaceZaitakuLE4HKF
          | typeof WorkplaceSyussyaKF
          | typeof WorkplaceSonotaKF;
      }> = [
        {
          code: `WORKPLACE_ZAITAKU_GT_4H`,
          label: (
            <span>
              在宅
              <br />
              4超
            </span>
          ),
          body: WorkplaceZaitakuGT4HKF,
        },
        {
          code: `WORKPLACE_ZAITAKU_LE_4H`,
          label: (
            <span>
              在宅
              <br />
              ≦4
            </span>
          ),
          body: WorkplaceZaitakuLE4HKF,
        },
        {
          code: `WORKPLACE_SYUSSYA`,
          label: (
            <span>
              通常
              <br />
              出社
            </span>
          ),
          body: WorkplaceSyussyaKF,
        },
        {
          code: `WORKPLACE_SONOTA`,
          label: `その他`,
          body: WorkplaceSonotaKF,
        },
      ];

      columns.push(
        ...defs.map(
          def =>
            ({
              id: `${def.code}`,
              width: GridSize.workplaceField,
              renderHeader: () => (
                <div>
                  <HeaderLabelWorkplace />
                  {def.label}
                </div>
              ),
              renderBody: (pos, isActive) => {
                const row = rows[pos.rowIndex - 1];
                return (
                  <def.body
                    mode="cell"
                    isActive={isActive}
                    cellPosition={pos}
                    onCellFocused={model.onCellFocused}
                    model={row}
                  />
                );
              },
            } as TableColumnDef),
        ),
      );
    }

    columns.push(
      ...([1, 2, 3, 4] as [1, 2, 3, 4]).map(
        i =>
          ({
            id: `types_${i}`,
            width: GridSize.typeField,
            renderHeader: () => (
              <div>
                <div>分類 {i}</div>
                {i === 2 && <MSetAllDaysBunrui model={model} />}
              </div>
            ),
            renderBody: (pos, isActive) => {
              const row = rows[pos.rowIndex - 1];
              return (
                <TypesKF
                  mode="cell"
                  bunruiIdx={i}
                  isActive={isActive}
                  cellPosition={pos}
                  onCellFocused={model.onCellFocused}
                  model={row}
                />
              );
            },
          } as TableColumnDef),
      ),
    );

    if (visibility.transDay) {
      columns.push({
        id: "transDay",
        width: GridSize.yyyyMMDDField,
        renderHeader: () => "振替日など",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <TransDayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    columns.push({
      id: "hourlyLeave",
      width: GridSize.hourlyLeaveField,
      renderHeader: () => (
        <div>
          <HeaderLabelHourlyLeave />
          {時単休.abbrName}
        </div>
      ),
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <HourlyLeaveKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row}
          />
        );
      },
    });

    columns.push({
      id: "hourlyNursingcare",
      width: GridSize.hourlyLeaveField,
      renderHeader: () => (
        <div>
          <HeaderLabelHourlyLeave />
          {看護時休.abbrName}
        </div>
      ),
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <HourlyNursingcareKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row}
          />
        );
      },
    });

    columns.push({
      id: "hourlyFamilycare",
      width: GridSize.hourlyLeaveField,
      renderHeader: () => (
        <div>
          <HeaderLabelHourlyLeave />
          {介護時休.abbrName}
        </div>
      ),
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <HourlyFamilycareKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row}
          />
        );
      },
    });

    columns.push({
      id: "diff",
      width: GridSize.hhmmField,
      renderHeader: () => "差引",
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <DiffKF mode="cell" isActive={isActive} cellPosition={pos} onCellFocused={model.onCellFocused} model={row} />
        );
      },
    });
    columns.push({
      id: "rest",
      width: GridSize.hhmmField,
      renderHeader: () => (
        <>
          <HeaderLabelRest />
          時間
        </>
      ),
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <RestKF mode="cell" isActive={isActive} cellPosition={pos} onCellFocused={model.onCellFocused} model={row} />
        );
      },
    });
    if (visibility.unusedRestTimes) {
      columns.push({
        id: "unusedRestSunset",
        width: GridSize.unusedRestField,
        renderHeader: () => (
          <>
            <HeaderLabelUnused />
            夕方
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <UnusedRestSunsetKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
      columns.push({
        id: "unusedRestNight",
        width: GridSize.unusedRestField,
        renderHeader: () => (
          <>
            <HeaderLabelUnused />夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <UnusedRestNightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }
    columns.push({
      id: "actual",
      width: GridSize.hhmmField,
      renderHeader: () => "実働",
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <ActualKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row}
          />
        );
      },
    });
    if (visibility.flex) {
      columns.push({
        id: "flex",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            FLEX
            <br />
            対象
            <br />
            時間
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <FlexKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.holiday) {
      columns.push({
        id: "holiday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            休日
            <br />
            時間
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <HolidayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.overWeekday) {
      columns.push({
        id: "overWeekday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            平日
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverWeekdayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.overWeekdayMidnight) {
      columns.push({
        id: "overWeekdayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            平日
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverWeekdayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }
    if (visibility.overHoliday) {
      columns.push({
        id: "overHoliday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            休日
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverHolidayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }
    if (visibility.overHolidayMidnight) {
      columns.push({
        id: "overHolidayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            休日
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OverHolidayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.midnight) {
      columns.push({
        id: "midnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            深夜
            <br />
            時間
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <MidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.allNightOff) {
      columns.push({
        id: "allNightOff",
        width: GridSize.hhmmField,
        renderHeader: () => "徹休時間",
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <AllNightOffKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.spWeekdayHolidayItems) {
      columns.push({
        id: "spWeekday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            特勤
            <br />
            平日
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <SpWeekdayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
      columns.push({
        id: "spWeekdayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            特勤
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <SpWeekdayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
      columns.push({
        id: "spHoliday",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            特勤
            <br />
            休日
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <SpHolidayKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
      columns.push({
        id: "spHolidayMidnight",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            特勤
            <br />
            休日
            <br />
            深夜
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <SpHolidayMidnightKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }

    if (visibility.growingSum) {
      columns.push({
        id: "growingSum",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            残業
            <br />
            累計
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <GrowingOverKF
              mode="cell"
              isActive={isActive}
              cellPosition={pos}
              onCellFocused={model.onCellFocused}
              model={row}
            />
          );
        },
      });
    }
    if (visibility.off) {
      columns.push({
        id: "off",
        width: GridSize.hhmmField,
        renderHeader: () => (
          <>
            遅早外
            <br />
            時間
          </>
        ),
        renderBody: (pos, isActive) => {
          const row = rows[pos.rowIndex - 1];
          return (
            <OffKF mode="cell" isActive={isActive} cellPosition={pos} onCellFocused={model.onCellFocused} model={row} />
          );
        },
      });
    }

    columns.push({
      id: "remarks",
      width: GridSize.remarksField,
      renderHeader: () => "備考",
      renderBody: (pos, isActive) => {
        const row = rows[pos.rowIndex - 1];
        return (
          <RemarksKF
            mode="cell"
            isActive={isActive}
            cellPosition={pos}
            onCellFocused={model.onCellFocused}
            model={row}
          />
        );
      },
    });

    return columns;
  }
}
