import { DepartmentEntity, DepartmentResult } from "@webkintai/api";
import { flow, types } from "mobx-state-tree";

import { DeptsApi } from "../../services/api/DeptsApi";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { idNendoDept, NendoDept } from "./NendoDept";

const model = types
  .model("NendoDepts", {
    id: types.refinement(types.identifier, identifier => identifier.indexOf("NendoDepts-") === 0),
    nendo: types.number,
    _rootDept: types.maybeNull(types.reference(NendoDept)),
    depts: types.map(NendoDept),
    loadingStatus: types.optional(LoadingStatus, "loading"),
  })
  .views(self => ({
    get rootDept() {
      return self._rootDept!;
    },
    get leafDepts() {
      return [...self.depts.values()].filter(dep => dep.isLeaf);
    },
    getDept(depCode: string) {
      return self.depts.get(idNendoDept(self.nendo, depCode));
    },
  }))
  .actions(self => {
    const deptsApi = () => getDI(self, DeptsApi);

    return {
      loadIfNeeded() {
        return self.loadingStatus === "loaded" ? new Promise(done => done()) : this.load();
      },

      load: flow(function*() {
        const deptsResult: DepartmentResult = yield deptsApi().getDepartments(self.nendo);
        const hierarchy = flatternDepartmentEntry(deptsResult.department);
        const root = hierarchy[0];

        hierarchy.forEach(h => {
          self.depts.put(h);
          self._rootDept = root;
        });
        self.loadingStatus = "loaded";
      }),
    };
  });

export const idNendoDepts = (nendo: number) => `NendoDepts-${nendo}`;

function flatternDepartmentEntry(ent: DepartmentEntity) {
  const depts: Array<typeof NendoDept.Type> = [];
  depts.push(
    NendoDept.create({
      id: idNendoDept(ent.nendo, ent.depCode),
      nendo: ent.nendo,
      depCode: ent.depCode,
      name: ent.name,
      parentDepCode: ent.upperDepCode,
      childrenDeptCodes: (ent.children || []).map(e => e.depCode),
    }),
  );

  if (ent.children) {
    ent.children.forEach(e => {
      depts.push(...flatternDepartmentEntry(e));
    });
  }

  return depts;
}

export type NendoDeptsType = typeof NendoDepts.Type;
export const NendoDepts: NendoDeptsModelType = model;
type NendoDeptsInferredType = typeof model;
export interface NendoDeptsModelType extends NendoDeptsInferredType {}
