import { Instance, types } from "mobx-state-tree";

export const model = types.optional(
  types.enumeration("PrvActiveNendoContentType", ["notApplied", "notApproved", "paidLeaves"]),
  "notApplied",
);

export const PrvActiveNendoContentType: PrvActiveNendoContentType_ModelType = model;
type PrvActiveNendoContentType_InferredType = typeof model;
// tslint:disable-next-line:class-name
export interface PrvActiveNendoContentType_ModelType extends PrvActiveNendoContentType_InferredType {}
export type PrvActiveNendoContentTypeInstance = Instance<typeof PrvActiveNendoContentType>;
