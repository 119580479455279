import { MonthlyReportAttributesEntity } from "@webkintai/api";
import { Minutes } from "@webkintai/core";
import { IModelType, SnapshotIn, types } from "mobx-state-tree";

import { RankPosition } from "../../common/RankPosition";
import { typesMinutes } from "../../primitives/Minutes";
import { User, User_Type } from "../../users/User";
import { UsersType } from "../../users/Users";
import { fromWorkingTypeCodeToMainKintaiType, MainKintaiType, MainKintaiTypeType } from "../MainKintaiType";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("KintaiMonthlyAttributesValues", {
    mainKintaiType: types.optional(MainKintaiType, "Flex"),

    reviewer: types.maybe(types.reference(User)),
    approver: types.maybe(types.reference(User)),

    depCode: types.maybe(types.string),
    depName: types.maybe(types.string),
    rankCode: types.maybe(types.string),
    rankName: types.maybe(types.string),
    rankPosition: types.maybe(RankPosition),
    designatedMinutes: types.maybe(typesMinutes),
  })
  .actions(self => {
    return {
      setDesignatedMinutes(value: Minutes | undefined) {
        self.designatedMinutes = value;
      },
      setMainKintaiType(value: MainKintaiTypeType) {
        self.mainKintaiType = value;
      },
      setReviewer(value: User_Type) {
        self.reviewer = value;
      },
      removeReviewer() {
        self.reviewer = undefined;
      },
      setApprover(value: User_Type) {
        self.approver = value;
      },
      removeApprover() {
        self.approver = undefined;
      },
    };
  });

export const createKintaiMonthlyAttributesValuesFromApiValues = (
  users: UsersType,
  attributes: MonthlyReportAttributesEntity,
) => {
  const approver = attributes.approver ? users.mergeFromUserEntity(attributes.approver) : undefined;
  const reviewer = attributes.reviewer ? users.mergeFromUserEntity(attributes.reviewer) : undefined;

  return {
    mainKintaiType: fromWorkingTypeCodeToMainKintaiType(attributes.workingType.value),
    designatedMinutes: Minutes.apiValueOf(attributes.designatedHours),
    depCode: attributes.depCode || undefined,
    depName: attributes.depName || undefined,
    rankCode: attributes.rankCode || undefined,
    rankName: attributes.rankName || undefined,
    rankPosition: attributes.position ? attributes.position.value : undefined,
    approver: approver ? approver.userId : undefined,
    reviewer: reviewer ? reviewer.userId : undefined,
  } as SnapshotIn<typeof KintaiMonthlyAttributesValues.Type>;
};

export type KintaiMonthlyAttributesValuesType = typeof KintaiMonthlyAttributesValues.Type;

export const KintaiMonthlyAttributesValuesSymbol = "KintaiMonthlyAttributesValues_Symbol";
export const KintaiMonthlyAttributesValues: KintaiMonthlyAttributesValuesModelType = model;
type KintaiMonthlyAttributesValuesInferredType = typeof model;
export interface KintaiMonthlyAttributesValuesModelType extends KintaiMonthlyAttributesValuesInferredType {}
