import { observer } from "mobx-react";
import React from "react";

import { ProfilePageScreenModes } from "../../models/profile/ProfilePageScreenModes";
import { PageTile } from "../common/page/PageTile";
import { ProfileSubMenu, ProfileSubMenuVM } from "./header/ProfileSubMenu";
import { InboxPage, InboxPageVM } from "./inbox/InboxPage";
import { ProfileInterviewPage, ProfileInterviewPageVM } from "./interview/ProfileInterviewPage";
import { SettingsPage, SettingsPageVM } from "./settings/SettingsPage";

export interface ProfilePageVM {
  pageMode: typeof ProfilePageScreenModes.Type;
  profileSubMenuVM: ProfileSubMenuVM;
  inboxPageVM: InboxPageVM;
  settingsPageVM: SettingsPageVM;
  profileInterviewPageVM: ProfileInterviewPageVM;
}

@observer
export class ProfilePage extends React.Component<{ model: ProfilePageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageTile header={<ProfileSubMenu model={model.profileSubMenuVM} />}>
        {model.pageMode === "inbox" && <InboxPage model={model.inboxPageVM} />}
        {model.pageMode === "settings" && <SettingsPage model={model.settingsPageVM} />}
        {model.pageMode === "interview" && <ProfileInterviewPage model={model.profileInterviewPageVM} />}
      </PageTile>
    );
  }
}
