import { UserNendoDashboardData } from "../../../../../../schema/build/commonjs/main/typescript";
import { PrivateDDTableData, PrivateDDUserDatesRow } from "../PrivateDDTableData";
import { sortByDatesCount } from "./sortByDatesCount";

export const calcPrvKintaiAuthNotApplied = (
  value: UserNendoDashboardData,
): PrivateDDTableData<PrivateDDUserDatesRow> => {
  const target = value.nendoData.notApplied;
  return {
    name: "勤怠申請（未申請）有",
    warned: false,
    warningCount: target.length,
    unit: "件",
    rows: sortByDatesCount(
      target.map(it => ({
        user: it.userAttributes,
        warned: true,
        dates: it.dates.map(date => new Date(date)),
      })),
      "desc",
    ),
  };
};
