import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { GlobalWarnings, GlobalWarningsSymbol } from "../../../models/applications/GlobalWarnings";
import { paths } from "../../../routing/paths";
import { AppRouter } from "../../../services/AppRouter";
import { WarningBannerVM } from "./WarningBanner";

export class WarningBannerVMImpl implements WarningBannerVM {
  @lazyInject(GlobalWarningsSymbol)
  private globalWarnings: typeof GlobalWarnings.Type;

  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  @computed
  public get dismissed() {
    return this.globalWarnings.dismissed;
  }

  @action.bound
  public onDismiss() {
    this.globalWarnings.setDismissed(true);
  }

  @computed
  get warnPhysicianInterview() {
    return this.globalWarnings.showsInterviewWarning;
  }

  @computed
  get warnFatigueCheck() {
    return this.globalWarnings.showsFatigueCheckWarning;
  }

  @action.bound
  public onNavigateToPhysicianInterview() {
    this.appRouter.navigate(paths.profile.interview.index());
  }

  @computed
  get physicianInterviewNearestFutureReservedDate() {
    return this.globalWarnings.nearestFutureReservedDate;
  }

  @computed
  get physicianInterviewPastLatestReservedDate() {
    return this.globalWarnings.pastLatestReservedDate;
  }

  @computed
  get warn45OverHealthCareTime() {
    return this.globalWarnings.showsHealthCareOver45;
  }

  @computed
  get warn75OverHealthCareTime() {
    return this.globalWarnings.showsHealthCareOver75;
  }
}
