import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { getNendo } from "../../../../../utils/calendar";
import { MTotalAllNightCountKF } from "../../monthly/fields/MTotalAllNightCountKF";
import { MTotalAllNightOffDaysKF } from "../../monthly/fields/MTotalAllNightOffDaysKF";
import { MTotalCompDaysKF } from "../../monthly/fields/MTotalCompDaysKF";
import { MTotalGivenDaysKF } from "../../monthly/fields/MTotalGivenDaysKF";
import { MTotalGivenHoursKF } from "../../monthly/fields/MTotalGivenHoursKF";
import { MTotalGivenWorkKF } from "../../monthly/fields/MTotalGivenWorkKF";
import { MTotalHolidayDaysKF } from "../../monthly/fields/MTotalHolidayDaysKF";
import { MTotalLeaveDaysKF } from "../../monthly/fields/MTotalLeaveDaysKF";
import { MTotalMidnightKF } from "../../monthly/fields/MTotalMidnightKF";
import { MTotalMissedKF } from "../../monthly/fields/MTotalMissedKF";
import { MTotalNonAttendanceDaysKF } from "../../monthly/fields/MTotalNonAttendanceDaysKF";
import { MTotalNormalDaysKF } from "../../monthly/fields/MTotalNormalDaysKF";
import { MTotalOffCountKF } from "../../monthly/fields/MTotalOffCountKF";
import { MTotalOverHolidayKF } from "../../monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../monthly/fields/MTotalOverKF";
import { MTotalOverWeekdayKF } from "../../monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalSickoutDaysKF } from "../../monthly/fields/MTotalSickoutDaysKF";
import { MTotalTransOffDaysKF } from "../../monthly/fields/MTotalTransOffDaysKF";
import { MTotalTransOnDaysKF } from "../../monthly/fields/MTotalTransOnDaysKF";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";
import { getVacancyTotalComponents } from "../common/getVacancyTotalComponents";
import { PrintedVacationsTotals } from "../common/PrintedVacationsTotals";
import { SPortPrintedKintaiVM } from "./SPortPrintedKintai";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

@observer
export class SPortPrintedTotalTables extends React.Component<{ model: SPortPrintedKintaiVM }> {
  public render() {
    const { sPortMonthlyTotal: total, sPortRows, printDetailMode } = this.props.model;
    const date = sPortRows[0].date;
    const nendo = getNendo(date);

    const vacantVisibility = new VacantDayItemsVisibility(date);

    const { vacancyTotalHeader, vacancyTotalSubHeader, vacancyTotalBody } = getVacancyTotalComponents({ date, total });

    return (
      <div>
        {/* 月集計値 */}
        <div>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  所定
                  <br />
                  就業
                  <br />
                  日数
                </th>
                <th rowSpan={2}>
                  所定
                  <br />
                  就業
                  <br />
                  時間
                </th>
                <th colSpan={2}>振替</th>
                <th rowSpan={2}>
                  平常
                  <br />
                  出勤
                </th>
                <th rowSpan={2}>
                  休日
                  <br />
                  出勤
                </th>
                <th rowSpan={2}>
                  徹夜
                  <br />
                  回数
                </th>
                <th rowSpan={2}>
                  欠勤
                  <br />
                  日数
                </th>
                <th rowSpan={2}>
                  休業
                  <br />
                  日数
                </th>
                <th rowSpan={2}>
                  代休
                  <br />
                  日数
                </th>
                <th rowSpan={2}>
                  徹休
                  <br />
                  日数
                </th>

                {vacancyTotalHeader}

                <th rowSpan={2}>
                  遅刻
                  <br />
                  早退
                  <br />
                  外出
                </th>
                <th rowSpan={2}>
                  所定
                  <br />
                  勤務
                  <br />
                  時間
                </th>
                <th rowSpan={2}>
                  欠勤
                  <br />
                  時間
                </th>
                <th rowSpan={2}>
                  深夜
                  <br />
                  勤務
                  <br />
                  時間
                </th>
                <th colSpan={4}>時間外勤務時間</th>
                <th rowSpan={2}>
                  時間外
                  <br />
                  時間数
                </th>
              </tr>
              <tr>
                <th>出勤</th>
                <th>休日</th>

                {/* 休日系 */}
                {vacancyTotalSubHeader}

                {/* 4項目 */}
                <th>普通</th>
                <th>平日深夜</th>
                <th>休日</th>
                <th>休日深夜</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* 所定就業日数 */}
                <td>{<MTotalGivenDaysKF mode="print" model={total} />}</td>
                {/* 所定就業時間 */}
                <td>{<MTotalGivenHoursKF mode="print" model={total} />}</td>

                {/* 振替:出勤 */}
                <td>{<MTotalTransOnDaysKF mode="print" model={total} />}</td>
                {/* 振替:休日 */}
                <td>{<MTotalTransOffDaysKF mode="print" model={total} />}</td>

                {/* 平常出勤 */}
                <td>{<MTotalNormalDaysKF mode="print" model={total} />}</td>
                {/* 休日出勤 */}
                <td>{<MTotalHolidayDaysKF mode="print" model={total} />}</td>
                {/* 徹夜 */}
                <td>{<MTotalAllNightCountKF mode="print" model={total} />}</td>
                {vacantVisibility.sickoutDaysOrNonattendanceDays === "sickoutDays" ? (
                  /* (休業含)欠勤 */
                  <td>{<MTotalSickoutDaysKF mode="print" model={total} />}</td>
                ) : (
                  /* (欠勤)欠勤 */
                  <td>{<MTotalNonAttendanceDaysKF mode="print" model={total} />}</td>
                )}
                {/* 休業 */}
                {vacantVisibility.leaveDays && <td>{<MTotalLeaveDaysKF mode="print" model={total} />}</td>}
                {/* 代休 */}
                <td>{<MTotalCompDaysKF mode="print" model={total} />}</td>
                {/* 徹休 */}
                <td>{<MTotalAllNightOffDaysKF mode="print" model={total} />}</td>

                {vacancyTotalBody}

                {/* 遅刻早退外出 */}
                <td>
                  <MTotalOffCountKF mode="print" model={total} />
                </td>
                {/* 所定勤務時間 */}
                <td>
                  <MTotalGivenWorkKF mode="print" model={total} />
                </td>
                {/* 欠勤時間 */}
                <td>
                  <MTotalMissedKF mode="print" model={total} />
                </td>
                {/* 深夜勤務時間 */}
                <td>
                  <MTotalMidnightKF mode="print" model={total} />
                </td>

                {/* 時間外勤務時間 */}
                <td>
                  <MTotalOverWeekdayKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalOverWeekdayMidnightKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalOverHolidayKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalOverHolidayMidnightKF mode="print" model={total} />
                </td>

                {/* 時間外時間数 */}
                <td>
                  <MTotalOverKF mode="print" model={total} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <PrintedVacationsTotals model={total} nendo={nendo} vv={vacantVisibility} />
        </div>

        {printDetailMode !== "brief" && (
          <>
            <Row>
              ※この帳票を印刷捺印して提出する必要はありません。各担当者がオンラインで査閲・承認を行ってください。
            </Row>
          </>
        )}
      </div>
    );
  }
}
