import { flatMap } from "lodash-es";
import { getParentOfType, types } from "mobx-state-tree";

import { daysOfMonth } from "../../../utils/calendar";
import { compareMonth, dateOf } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { genUuid } from "../../../utils/uuid";
import { MonthlyKintai } from "../MonthlyKintai";
import { PJReportPillar } from "./PJReportPillar";
import { isSamePillarHeader } from "./PJReportPillarHeader";
import { PJReportTotalPillar } from "./PJReportTotalPillar";
import { ProjectInputStyle } from "./ProjectInputStyle";

const model = types
  .model("PJReport", {
    userId: types.string,
    month: types.Date,
    totalPillar: PJReportTotalPillar,
    pillars: types.array(PJReportPillar),
    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string),
  })
  .views(self => {
    function getRoot() {
      return getParentOfType(self, MonthlyKintai);
    }

    return {
      get projectInputStyle(): ProjectInputStyle {
        return compareMonth(self.month, dateOf(2019, 10), (l, r) => l.getTime() >= r.getTime()) ? "zac" : "pjshushi";
      },
      get isLocked() {
        return getRoot().isPJReportLocked;
      },
      get livingPillars() {
        return self.pillars.filter(it => !it.markDeleted);
      },
      get hasNoChange() {
        return hasNoChangeReduce([...self.pillars.map(it => it.hasNoChanges)]);
      },
      get availableJobNos() {
        return getRoot().availableJobNos;
      },
    };
  })
  .views(self => {
    return {
      get totalErrors() {
        return [
          ...self.errors.map(it => `【月報】 ${it}`),
          ...self.totalPillar.totalErrors,
          ...flatMap(self.livingPillars, it => it.totalErrors),
        ];
      },
      get totalWarnings() {
        return [
          ...self.warnings.map(it => `【月報】 ${it}`),
          ...self.totalPillar.totalWarnings,
          ...flatMap(self.livingPillars, it => it.totalWarnings),
        ];
      },
      get totalInfos() {
        return [
          ...self.infos.map(it => `【月報】 ${it}`),
          ...self.totalPillar.totalInfos,
          ...flatMap(self.livingPillars, it => it.totalInfos),
        ];
      },

    };
  })
  .actions(self => {
    return {
      reset() {
        self.pillars.forEach(it => {
          if (it.persisted) {
            it.reset();
            return;
          }

          this.removeAndUnmanagePillar(it.id);
        });
      },
      removeAndUnmanagePillar(pillarId: string) {
        const tobeRemoved = self.pillars.find(it => it.id === pillarId);
        if (tobeRemoved) {
          self.pillars.remove(tobeRemoved);
        }
      },
      derivePillar(src: typeof PJReportPillar.Type) {
        const { pjCode, odCode, pjName, labels } = src.header;
        // PJ ODコードどちらもない場合はコピーしない
        if (!src.header.pjCode.value && !src.header.odCode.value) {
          return;
        }

        for (const p of self.pillars) {
          if (isSamePillarHeader(src.header, p.header)) {
            p.setMarkDeleted(false);
            return;
          }
        }

        const newPillar = this.addEmptyPillar();
        const { header } = newPillar;
        header.input.setPjCode(pjCode.value);
        header.input.setOdCode(odCode.value);
        labels.forEach((label, idx) => {
          header.input.setLabel(idx, label.value);
        });
      },
      addEmptyPillar() {
        const headerValue = {
          pjName: "",
          pjCode: "",
          odCode: "",
          labels: ["", "", ""],
        };
        const created = PJReportPillar.create({
          id: genUuid(),
          persisted: false,

          header: {
            origin: headerValue,
            computed: headerValue,
            input: headerValue,
          },

          days: [...daysOfMonth(self.month)].map(d => {
            const emptyDay = [{ time: undefined }, { time: undefined }, { time: undefined }];
            return {
              date: d,
              input: emptyDay,
              origin: emptyDay,
              computed: emptyDay,
            };
          }),
        });
        self.pillars.push(created);
        return created;
      },
    };
  })
  .views(self => {
    return {};
  });

export const derivePillars = (src: typeof PJReport.Type, dest: typeof PJReport.Type) => {
  src.livingPillars.forEach(p => {
    dest.derivePillar(p);
  });
};
export const PJReport: PJReportModelType = model;
type PJReportInferredType = typeof model;
export interface PJReportModelType extends PJReportInferredType {}
