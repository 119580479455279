import { observer } from "mobx-react";
import React from "react";

import { DayCountField } from "../../../common/form/DayCountField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { SPortAdminPageGridMonthVM } from "./SPortAdminPageGrid";

@observer
export class SPortAdminGivenDaysCell extends React.Component<{ model: SPortAdminPageGridMonthVM }> {
  public render() {
    const { model } = this.props;
    return (
      <DayCountField
        allowEmpty={true}
        disabled={model.disabled}
        mode="cell"
        label=""
        max={31}
        value={model.givenDays}
        onChange={model.onChangeGivenDays}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
