import { isEqual } from "lodash-es";
import { action, computed, observable } from "mobx";
import React from "react";

import { lazyInject } from "../../../didecorators";
import { CalendarPageModel, CalendarPageModelSymbol } from "../../../models/calendar/CalendarPageModel";
import { CellPosition } from "../../common/table/CellPosition";
import { MoveDelta } from "../../common/table/MoveDelta";
import { CalendarPageVM } from "./CalendarPage";
import { CalendarPageRibbonVM } from "./CalendarPageRibbon";
import { CalendarPageGridVM } from "./grid/CalendarPageGrid";
import { CalendarPageMonthVMImpl } from "./grid/CalendarPageMonthVMImpl";

export class CalendarPageVMImpl implements CalendarPageVM, CalendarPageRibbonVM, CalendarPageGridVM {
  @computed
  public get ribbonModel() {
    return this;
  }
  @computed
  public get gridModel() {
    return this;
  }

  @computed
  public get nendo() {
    return this.calendarPageModel.nendo;
  }

  @computed
  public get nendoList() {
    return this.calendarPageModel.nendoList;
  }

  @computed
  public get loadingStatus() {
    return this.calendarPageModel.loadingStatus;
  }

  @computed
  public get positiveHolidays() {
    return this.nendoCalendar.positiveHolidays;
  }

  @computed
  public get summerHolidays() {
    return this.nendoCalendar.summerHolidays;
  }

  @computed
  public get holidayCount() {
    return this.nendoCalendar.holidayCount;
  }

  @computed
  public get workdayCount() {
    return this.nendoCalendar.workdayCount;
  }

  @computed
  public get allowedToEdit() {
    return this.nendoCalendar.allowedToEdit;
  }

  @computed
  public get saveDisabled() {
    return this.nendoCalendar.hasNoChange;
  }

  @computed
  public get months() {
    return this.nendoCalendar.months.map(month => new CalendarPageMonthVMImpl(this, month));
  }

  @computed
  private get nendoCalendar() {
    return this.calendarPageModel.nendoCalendarInput!;
  }

  @observable
  public activeCell: CellPosition = {
    columnIndex: 1,
    rowIndex: 1,
  };
  @lazyInject(CalendarPageModelSymbol)
  private calendarPageModel: typeof CalendarPageModel.Type;

  @action.bound
  public onChangeNendo(nendo: number) {
    this.calendarPageModel.navigateToNendo(nendo);
  }

  @action.bound
  public onChangePositiveHoliday(days: number) {
    this.nendoCalendar.setPositiveHolidays(days);
  }

  @action.bound
  public onChangeSummerHoliday(days: number) {
    this.nendoCalendar.setSummerHolidays(days);
  }

  @action.bound
  public onExcelExport() {
    this.nendoCalendar.exportExcel();
  }

  @action.bound
  public onExcelImport(file: File) {
    this.nendoCalendar.importExcel(file);
  }

  @action.bound
  public onSave() {
    this.nendoCalendar.save();
  }

  @action.bound
  public onReset() {
    this.nendoCalendar.copy();
  }

  @action.bound
  public onMoveCell(moveDelta: MoveDelta) {
    const { columnIndex, rowIndex } = this.activeCell;
    this.activeCell = {
      columnIndex: columnIndex + moveDelta.x,
      rowIndex: rowIndex + moveDelta.y,
    };
  }

  @action.bound
  public onCellFocused(position: CellPosition) {
    if (!isEqual(this.activeCell, position)) {
      this.activeCell = position;
    }
  }
}
