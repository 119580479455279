import { Days, HourBasedDays, Minutes } from "@webkintai/core";
import { IModelType, types } from "mobx-state-tree";

import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { valueOnlyKintaiInputItemState } from "../kintaiinput/ValueOnlyKintaiItem";
import { SPortMonthlyTotalValues } from "./SPortMonthlyTotalValues";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const SPortMonthlyTotal = types
  .model("SPortMonthlyTotal", {
    origin: SPortMonthlyTotalValues,
    computed: SPortMonthlyTotalValues,
  })
  .views(self => {
    return {
      get mTotalLeaveDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.leaveDays);
      },
      get mTotalHourlyLeave(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyLeave);
      },
      get mTotalPlainPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.plainPaidDays);
      },
      get mTotalAbsenceDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.absenceDays);
      },
      get mTotalActual(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.actual);
      },
      get mTotalAllNightCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.allNightCount);
      },
      get mTotalAllNightOffDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.allNightOffDays);
      },
      get mTotalCareDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.careDays);
      },
      get mTotalChildcareDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.childcareDays);
      },
      get mTotalCompDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.compDays);
      },
      get mTotalDiff(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.diff);
      },
      get mTotalFamilycareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.familycareDays);
      },
      get mTotalHourlyFamilycare(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyFamilycare);
      },
      get mTotalGivenDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.givenDays);
      },
      get mTotalGivenHours(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.givenHours);
      },
      get mTotalGivenWork(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.givenWork);
      },
      get mTotalHolidayDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.holidayDays);
      },
      get mTotalImmunity(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.immunity);
      },
      get mTotalInjuriesDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.injuriesDays);
      },
      get mTotalJudgmentDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.judgmentDays);
      },
      get mTotalMaternityDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.maternityDays);
      },
      get mTotalMenstruationDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.menstruationDays);
      },
      get mTotalMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.midnight);
      },
      get mTotalMissed(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.missed);
      },
      get mTotalNonAttendanceDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.nonAttendanceDays);
      },
      get mTotalNormalDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.normalDays);
      },
      get mTotalNursingcareDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.nursingcareDays);
      },
      get mTotalHourlyNursingcare(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.hourlyNursingcare);
      },
      get mTotalOffCount(): KintaiInputItemState<number> {
        return valueOnlyKintaiInputItemState(self.computed.offCount);
      },
      get mTotalOverDiff(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overDiff);
      },
      get mTotalOverHoliday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overHoliday);
      },
      get mTotalOverHolidayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overHolidayMidnight);
      },
      get mTotalOverRestDay(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overRestDay);
      },
      get mTotalOverRestMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overRestMidnight);
      },
      get mTotalOver(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.over);
      },
      get mTotalOverWeekday(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overWeekday);
      },
      get mTotalOverWeekdayMidnight(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.overWeekdayMidnight);
      },
      get mTotalPaidDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.paidDays);
      },
      get mTotalOtherDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherDays);
      },
      get mTotalOtherDaysExcludingNuFa(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherDaysExcludingNuFa);
      },
      get mTotalOtherHours(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.otherHours);
      },
      get mTotalPaidAllDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.paidAllDays);
      },
      get mTotalPaidHalfDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.paidHalfDays);
      },
      get mTotalPositiveDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.positiveDays);
      },
      get mTotalRest(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.rest);
      },
      get mTotalSickoutDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.sickoutDays);
      },
      get mTotalSpDays(): KintaiInputItemState<HourBasedDays> {
        return valueOnlyKintaiInputItemState(self.computed.spDays);
      },
      get mTotalSummerDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.summerDays);
      },
      get mTotalSuspensionDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.suspensionDays);
      },
      get mTotalTime(): KintaiInputItemState<Minutes> {
        return valueOnlyKintaiInputItemState(self.computed.time);
      },
      get mTotalTransOffDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.transOffDays);
      },
      get mTotalTransOnDays(): KintaiInputItemState<Days> {
        return valueOnlyKintaiInputItemState(self.computed.transOnDays);
      },
    };
  });

export type SPortMonthlyTotalType = typeof SPortMonthlyTotal.Type;
