import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { LabelField } from "../../../form/LabelField";

export type MTotalOverFortyFiveUntilLastMonthCountKFProps = {
  model: MTotalOverFortyFiveUntilLastMonthCountKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverFortyFiveUntilLastMonthCountKFVM {
  mTotalOverFortyFiveUntilLastMonthCount: KintaiInputItemState<number>;
}

export const MTotalOverFortyFiveUntilLastMonthCountKF = observer(
  (props: MTotalOverFortyFiveUntilLastMonthCountKFProps) => {
    const { model } = props;
    const itemState = model.mTotalOverFortyFiveUntilLastMonthCount;

    return (
      <LabelField {...props} {...itemState}>
        {itemState.value}
      </LabelField>
    );
  },
);
