import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { ExportableTable } from "../../common/export-table/ExportableTable";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ScrollablePane } from "../../common/ScrollablePane";
import { KintaiSumPageSumType } from "./common/KintaiSumPageSumType";
import { KintaiSumPageSumTypeTitles } from "./common/KintaiSumPageSumTypeTitles";
import { KintaiSumPageRibbon, KintaiSumPageRibbonVM } from "./ribbon/KintaiSumPageRibbon";
import { KintaiSumTableByOrder, KintaiSumTableByOrderVM } from "./table/KintaiSumTableByOrder";
import { KintaiSumTableByOrderUser, KintaiSumTableByOrderUserVM } from "./table/KintaiSumTableByOrderUser";
import { KintaiSumTableByUser, KintaiSumTableByUserVM } from "./table/KintaiSumTableByUser";

export interface KintaiSumPageVM {
  ribbonModel: KintaiSumPageRibbonVM;
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
  sumType: KintaiSumPageSumType;

  displayHourAsDecimal: boolean;
  byOrderUserTableVM: KintaiSumTableByOrderUserVM;
  byOrderTableVM: KintaiSumTableByOrderVM;
  byUserTableVM: KintaiSumTableByUserVM;
}

@observer
export class KintaiSumPage extends React.Component<{ model: KintaiSumPageVM }> {
  public render() {
    const { model } = this.props;
    const { displayHourAsDecimal } = model;

    return (
      <PageRibbon ribbon={<KintaiSumPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("部門内の月ごと勤怠一覧")}</title>
        </Helmet>

        <ScrollablePane>
          {model.loadingStatus !== "loaded" && (
            <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
          )}

          {model.loadingStatus === "loaded" && (
            <>
              {model.sumType === "byOrderUser" && (
                <ExportableTable title={KintaiSumPageSumTypeTitles.byOrderUser}>
                  {renderedOn => (
                    <KintaiSumTableByOrderUser
                      model={model.byOrderUserTableVM}
                      renderedOn={renderedOn}
                      displayHourAsDecimal={displayHourAsDecimal}
                    />
                  )}
                </ExportableTable>
              )}

              {model.sumType === "byOrder" && (
                <ExportableTable title={KintaiSumPageSumTypeTitles.byOrder}>
                  {renderedOn => (
                    <KintaiSumTableByOrder
                      model={model.byOrderTableVM}
                      renderedOn={renderedOn}
                      displayHourAsDecimal={displayHourAsDecimal}
                    />
                  )}
                </ExportableTable>
              )}

              {model.sumType === "byUser" && (
                <ExportableTable title={KintaiSumPageSumTypeTitles.byUser}>
                  {renderedOn => (
                    <KintaiSumTableByUser
                      model={model.byUserTableVM}
                      renderedOn={renderedOn}
                      displayHourAsDecimal={displayHourAsDecimal}
                    />
                  )}
                </ExportableTable>
              )}
            </>
          )}
        </ScrollablePane>
      </PageRibbon>
    );
  }
}
