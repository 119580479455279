import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React, { useState } from "react";

import { DashboardSimpleChartData } from "../../../../../models/statistics/pub/DashboardSimpleChartData";
import { WithLoading } from "../../../../../models/statistics/WithLoading";
import { WkDrawer } from "../../../../common/drawer/WkDrawer";
import { DashboardWidget } from "../DashboardWidget";
import { LoadingWidgetContent } from "../fault/LoadingWidgetContent";
import { NoDataWidgetContent } from "../fault/NoDataWidgetContent";
import { DashboardWidgetLastUpdate } from "../lastupdate/DashboardWidgetLastUpdate";
import { SimpleBarChart } from "./SimpleBarChart";

export const PubBarChartWidget: React.FC<{
  title: React.ReactNode;
  mood: "bad" | "good";
  data: WithLoading<DashboardSimpleChartData>;
  lastUpdate: Date | undefined;
  children?: never;
}> = ({ title, mood, data, lastUpdate }) => {
  const [opened, setOpened] = useState(false);

  return (
    <DashboardWidget mood={mood} title={title} lastUpdate={<DashboardWidgetLastUpdate lastUpdate={lastUpdate} />}>
      {data.state === "loading" && <Loading />}
      {data.state === "no_data" && <NoData />}
      {data.state === undefined && (
        <div style={{ position: "relative" }}>
          <SimpleBarChart unit={data.unit} data={data.chartRows.filter((_, idx) => idx < 5)} />
          <div style={{ position: "absolute", bottom: 0, left: 0 }}>
            <Button icon="document-open" onClick={() => setOpened(true)} />
          </div>
          <WkDrawer isOpen={opened} onClose={() => setOpened(false)}>
            <SimpleBarChart large={true} unit={data.unit} data={data.chartRows} />
          </WkDrawer>
        </div>
      )}
    </DashboardWidget>
  );
};

const Loading = styled(LoadingWidgetContent)`
  width: 300px;
  height: 200px;
`;

const NoData = styled(NoDataWidgetContent)`
  width: 300px;
  height: 200px;
`;
