import { Minutes } from "@webkintai/core";
import React from "react";
import { ButtonField } from "../../../form/ButtonField";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { hhmmHourAsDisplayText } from "../../../form/HHMMField";
import { LabelField } from "../../../form/LabelField";

export type EnterLeaveTimeFieldProps = {
  label: string;
  date: Date;
  enterLeaveTime: Date | undefined;
  onClick: () => void;
  disabled?: boolean | undefined;
} & CellFormPrintAdaptable;

export const EnterLeaveTimeField: React.FunctionComponent<EnterLeaveTimeFieldProps> = props => {
  if (props.mode === "print") {
    return <LabelField {...props}>{enterLeaveTimeField(props.date, props.enterLeaveTime, false)}</LabelField>;
  }

  return (
    <ButtonField {...props} label={props.label} onClick={props.onClick} disabled={props.disabled}>
      {enterLeaveTimeField(props.date, props.enterLeaveTime, true)}
    </ButtonField>
  );
};

const enterLeaveTimeField = (baseDate: Date, enterLeaveTime: Date | undefined, hh: boolean) => {
  if (!enterLeaveTime) {
    return "";
  }
  return hhmmHourAsDisplayText(Minutes.fromDate(baseDate, enterLeaveTime), hh);
};
