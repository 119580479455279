import {
  FatigueCheckEditEntity,
  FatigueChecksControllerApi,
  FatigueCheckSubmitRequest,
  MeControllerApi,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { FatigueCheckApi } from "./FatigueCheckApi";

export class FatigueCheckApiImpl extends FatigueCheckApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getFatigueChecks(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(FatigueChecksControllerApi).getFatigueChecks(nendo),
    );
  }

  public updateFatigueCheck(userId: string, yearMonth: Date, payload: FatigueCheckEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(FatigueChecksControllerApi)
        .updateFatigueCheck(userId, yearMonth.getFullYear(), yearMonth.getMonth() + 1, payload),
    );
  }

  public getFatigueChecksForMe(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).getUserFatigueChecks(nendo),
    );
  }

  public setFatigueSubmitStatusForMe(month: Date, payload: FatigueCheckSubmitRequest) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(MeControllerApi)
        .setUserFatigueCheckSubmittedDate(month.getFullYear(), month.getMonth() + 1, payload),
    );
  }
}
