import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type ApprovalStampProps = {
  stamped: boolean;
} & CellFormAdaptable;

export interface ApprovalStampVM {}

export const ApprovalStamp = observer((props: ApprovalStampProps) => {
  const { stamped } = props;

  if (props.mode === "cell") {
    return <CheckboxField {...props} mode="cell" value={stamped} />;
  }

  return <></>;
});
