import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalFlexKFProps = {
  model: MTotalFlexKFVM;
} & CellFormPrintAdaptable;

export interface MTotalFlexKFVM {
  mTotalFlex: KintaiInputItemState<Minutes>;
}

export const MTotalFlexKF = observer((props: MTotalFlexKFProps) => {
  const { model } = props;
  const itemState = model.mTotalFlex;

  return <HHMMField {...props} label="mTotalFlexKF" {...itemState} onChange={undefined} />;
});
