import { InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { HourBasedDays, hourBasedDaysEq } from "@webkintai/core";
import React from "react";

import { zenkakuNumToHankaku } from "../../../utils/react";
import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { ErrorOverlay, ErrorOverlayProps } from "./ErrorOverlay";
import { cellFormStyle, cellStyle, componentCommonClasses, hasNoChangeStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export type HourBasedDaysCountFieldProps = {
  label?: string;
  value?: HourBasedDays;
  hasNoChange?: boolean;
  disabled?: boolean;
  placeholder?: string;
  showAsDateCount?: boolean;
  onChange?: (value?: HourBasedDays) => void;
} & CellFormPrintAdaptable &
  ErrorOverlayProps;

const Cell = styled.input`
  ${cellStyle}
  ${cellFormStyle}
  ${hasNoChangeStyle}
  
  text-align: right;
`;

const Form = styled(InputGroup)`
  ${hasNoChangeStyle};
  display: inline-block;
  margin-right: 0.5ex;

  input {
    max-width: 6em;
  }
`;

export const HourBasedDaysCountField: React.FunctionComponent<HourBasedDaysCountFieldProps> = props => {
  const toStringFormat = props.showAsDateCount === false ? "hour" : "day";
  if (props.mode === "print") {
    return <>{props.value ? props.value.toString(toStringFormat) : ""}</>;
  }

  const ref = React.useRef<HTMLInputElement>(null);

  const onFocus: React.FormEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      setTimeout(() => {
        if (props.mode === "cell" && ref.current && props.isActive) {
          ref.current.select();
        }
      }, 0);

      if (props.mode === "cell" && props.onCellFocused && !props.isActive) {
        props.onCellFocused(props.cellPosition);
      }
    },
    [
      props.value && props.value.hours,
      props.mode === "cell" && props.onCellFocused,
      props.mode === "cell" && props.isActive,
    ],
  );

  const onChangeComponentText: React.FormEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      const value = ev.currentTarget.value;

      const converted = recognizeHourBasedDaysHourInput(value);
      if (props.onChange) {
        if (!hourBasedDaysEq(props.value, converted)) {
          props.onChange(converted);
        }
      }
    },
    [props.value, props.onChange],
  );

  const onBlur: React.FormEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      // if (props.mode === "cell" && props.onCellFocused && props.isActive) {
      //   props.onCellFocused(NULL_CELL_POSITION);
      // }
    },
    [props.mode === "cell" && props.onCellFocused, props.mode === "cell" && props.isActive],
  );

  const placeholder = props.placeholder || "0 (H)";

  if (props.mode === "cell") {
    const [exActive, setExActive] = React.useState(props.isActive);

    if (!exActive && props.isActive) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
        }
      });
    }
    if (props.isActive !== exActive) {
      setExActive(props.isActive);
    }

    return (
      <ErrorOverlay
        mode={props.mode}
        disabled={props.disabled}
        errors={props.errors}
        warnings={props.warnings}
        infos={props.infos}>
        <Cell
          className={componentCommonClasses(props)}
          type="text"
          ref={ref}
          placeholder={placeholder}
          readOnly={props.disabled}
          onFocus={onFocus}
          onChange={onChangeComponentText}
          onBlur={onBlur}
          value={props.value ? props.value.toString(toStringFormat) : ""}
        />
      </ErrorOverlay>
    );
  }

  return (
    <FormLabel
      label={props.label}
      disabled={props.disabled}
      errors={props.errors}
      warnings={props.warnings}
      infos={props.infos}>
      <Form
        className={componentCommonClasses(props)}
        placeholder={placeholder}
        disabled={props.disabled}
        small={true}
        onChange={onChangeComponentText}
        onFocus={onFocus}
        value={`${props.value ? props.value.toString(toStringFormat) : ""}`}
      />
    </FormLabel>
  );
};

export const recognizeHourBasedDaysHourInput = (value: string) => {
  const converted = zenkakuNumToHankaku(value).replace(/\D/g, "");
  if (converted === "") {
    return undefined;
  }
  return HourBasedDays.of(+converted);
};
