import { IModelType, types } from "mobx-state-tree";

import { isOldEnoughToBeExcludedByRetirement } from "../../components/common/ribbon/filter/ExcludeOldRetiredMembersCheckbox";
import { idPHEntry, PHEntry } from "./PHEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("PHUserEntry", {
    userId: types.identifier,
    userName: types.string,
    deptCode: types.maybe(types.string),
    deptName: types.maybe(types.string),
    rankCode: types.maybe(types.string),
    rankName: types.maybe(types.string),
    leaveDate: types.maybe(types.Date),
    entries: types.map(PHEntry),
  })
  .views(self => {
    return {
      getNendoEntry(nendo: number) {
        return self.entries.get(idPHEntry(self.userId, nendo));
      },
      get filteredContent() {
        return [self.userId, self.userName, self.deptCode, self.deptName, self.rankCode, self.rankName].filter(
          it => it,
        ) as string[];
      },
      get hasNoChange() {
        return [...self.entries.values()].map(v => v.hasNoChange).reduce((l, r) => l && r, true);
      },
      get isOldEnoughExcludeByRetirement() {
        return isOldEnoughToBeExcludedByRetirement(self.leaveDate);
      },
    };
  })
  .actions(self => {
    return {
      reset() {
        for (const ent of self.entries.values()) {
          ent.reset();
        }
      },
    };
  });

export type PHUserEntryType = typeof PHUserEntry.Type;

export const PHUserEntrySymbol = "PHUserEntry_Symbol";
export const PHUserEntry: PHUserEntryModelType = model;
type PHUserEntryInferredType = typeof model;
export interface PHUserEntryModelType extends PHUserEntryInferredType {}
