import { flow, IModelType, types } from "mobx-state-tree";

import { idNendoRanks, NendoRanks } from "./NendoRanks";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("Ranks", {
      nendoRanks: types.map(NendoRanks),
    })
    .actions(self => {
      return {
        prepare(nendo: number) {
          const id = idNendoRanks(nendo);
          const existing = self.nendoRanks.get(id);
          if (existing) {
            return existing;
          }

          const created = NendoRanks.create({
            id,
            nendo,
          });
          self.nendoRanks.put(created);

          return created;
        },
      };
    })
    .actions(self => {
      return {
        prepareAndLoadMostRecent: flow(function*(nendo: number) {
          try {
            const candidate = self.prepare(nendo);
            yield candidate.loadIfNeeded();
            return candidate;
          } catch (e) {
            console.error(`Failed to load depts at ${nendo}.`);
          }

          const lastYearCandidate = self.prepare(nendo - 1);
          yield lastYearCandidate.loadIfNeeded();
          return lastYearCandidate;
        }),
      };
    }),
  {},
);

export type RanksType = typeof Ranks.Type;

export const RanksSymbol = "Ranks_Symbol";
export const Ranks: RanksModelType = model;
type RanksInferredType = typeof model;
export interface RanksModelType extends RanksInferredType {}
