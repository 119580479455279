import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { ApprovalStamp } from "./ApprovalStamp";

export type MonthlyHrmStampProps = {
  model: MonthlyHrmStampVM;
} & CellFormAdaptable;

export interface MonthlyHrmStampVM {
  hrmStamped: boolean;
}

export const MonthlyHrmStamp = observer((props: MonthlyHrmStampProps) => {
  return <ApprovalStamp {...props} stamped={props.model.hrmStamped} />;
});
