import { LoginResult, OidcProvidersResult } from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class LoginApi {
  public abstract getOidcProviders(): Promise<OidcProvidersResult>;
  public abstract doLogin(userId: string, password: string): Promise<LoginResult>;
  public abstract getOidcLoginToken(): Promise<LoginResult>;
  public abstract doLogout(): Promise<Response>;
}
