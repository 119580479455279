import { CompanyNendoDashboardData } from "../../../../../schema/build/commonjs/main/typescript";
import { DashboardSimpleChartRow } from "./DashboardSimpleChartData";
import { enumerateMonthDeps } from "./enumerateMonthDeps";

/**
 * 年度内のデータについて、指定月までの、部門ごとの月別の有休取得 *日数* の平均の和を昇順で取得する。
 */
export const calcAvgPaidLeavesTakenAscRows = (
  value: CompanyNendoDashboardData,
  atMonth: Date,
): DashboardSimpleChartRow[] => {
  const result: DashboardSimpleChartRow[] = [];
  for (const dep of value.deps) {
    let allAvgTakenLeaveHours = 0;
    let totalUserCount = 0;
    for (const { monthDep } of enumerateMonthDeps(dep, atMonth)) {
      if (!monthDep) {
        continue;
      }

      const { totals, userCount } = monthDep;

      totalUserCount += userCount;
      allAvgTakenLeaveHours += userCount === 0 ? 0 : totals.takenLeaveHours.hours / userCount;
    }
    if (totalUserCount > 0) {
      result.push({
        code: dep.depAttributes.depCode,
        label: dep.depAttributes.depName,
        value: allAvgTakenLeaveHours / 8,
      });
    }
  }
  return result;
};
