import { CompanyNendoDashboardData } from "../../../../../schema/build/commonjs/main/typescript";
import { DashboardSimpleChartRow } from "./DashboardSimpleChartData";
import { enumerateMonthDeps } from "./enumerateMonthDeps";

/**
 * 年度内のデータについて、指定月までの、部門ごとの有休取得率のパーセンテージを昇順で取得する。
 */
export const calcAvgPaidLeavesRatioAscRows = (
  value: CompanyNendoDashboardData,
  atMonth: Date,
): DashboardSimpleChartRow[] => {
  const result: DashboardSimpleChartRow[] = [];
  for (const dep of value.deps) {
    let allTakenLeaveHours = 0;
    let allLeaveHours = 0;

    const targetMonths = Array.from(enumerateMonthDeps(dep, atMonth));
    for (const { monthDep } of enumerateMonthDeps(dep, atMonth)) {
      if (!monthDep) {
        continue;
      }

      const { totals } = monthDep;

      allTakenLeaveHours += totals.takenLeaveHours.hours;
      allLeaveHours += totals.leaveHours.hours / targetMonths.length;
    }

    if (allLeaveHours > 0) {
      result.push({
        code: dep.depAttributes.depCode,
        label: dep.depAttributes.depName,
        value: allLeaveHours === 0 ? 0 : (allTakenLeaveHours / allLeaveHours) * 100,
      });
    }
  }
  return result;
};
