import { Button, Intent, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { ApproverUserEntry } from "./ApproverUserEntry";

export interface AssignApproverButtonProps {
  disabled: boolean;
  currentUser?: ApproverUserEntry;
  children: React.ReactNode;
}

const WindowFrame = styled.div`
  display: flex;
  max-width: 320px;
  height: 500px;
`;

export const AssignApproverButton = (props: AssignApproverButtonProps) => (
  <Popover
    content={<WindowFrame>{props.children}</WindowFrame>}
    boundary="window"
    disabled={props.disabled}
    canEscapeKeyClose={true}
    autoFocus={false}>
    {props.currentUser ? (
      <Button
        disabled={props.disabled}
        small={true}
        intent={Intent.NONE}
        icon="person"
        text={`${props.currentUser.userId}: ${props.currentUser.userName}`}
      />
    ) : (
      <Button disabled={props.disabled} small={true} intent={Intent.WARNING} icon="warning-sign" text="担当を指定" />
    )}
  </Popover>
);
