import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../form/HourBasedDaysCountField";

export type HourlyNursingcareKFProps = {
  model: HourlyNursingcareKFVM;
} & CellFormPrintAdaptable;

export interface HourlyNursingcareKFVM {
  hourlyNursingcare: KintaiInputItemState<HourBasedDays | undefined>;
}

export const HourlyNursingcareKF = observer((props: HourlyNursingcareKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.hourlyNursingcare);

  return <HourBasedDaysCountField showAsDateCount={false} {...props} label="看護休時間数" {...itemState} />;
});
