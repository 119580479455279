import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { CalendarPageRibbon, CalendarPageRibbonVM } from "./CalendarPageRibbon";
import { CalendarPageGrid, CalendarPageGridVM } from "./grid/CalendarPageGrid";

export interface CalendarPageVM {
  loadingStatus: LoadingStatusType;
  ribbonModel: CalendarPageRibbonVM;
  gridModel: CalendarPageGridVM;
}

@observer
export class CalendarPage extends React.Component<{ model: CalendarPageVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    return (
      <PageRibbon ribbon={<CalendarPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("カレンダー管理")}</title>
        </Helmet>

        {loadingStatus !== "loaded" && <GridTableLoading />}
        {loadingStatus === "loaded" && <CalendarPageGrid model={model.gridModel} />}
      </PageRibbon>
    );
  }
}
