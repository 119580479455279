import { Button, Classes, Tab, Tabs } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import { sortBy } from "lodash-es";
import moment from "moment";
import React from "react";

import { getNendo, getNendoMonths } from "../../../utils/calendar";
import { getNendoYear, haveSameMonth } from "../../../utils/date";
import { genUuid } from "../../../utils/uuid";
import { MonthlyCalendar } from "../MonthlyCalendar";

export interface KintaiCalendarSelectorProps {
  unit: "year" | "month" | "day";
  nendoList: number[];
  currentDate: Date;
  onChangeDate: (d: Date) => void;
}

interface KintaiCalendarSelectorState {
  nendo: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CalendarSelection = styled.div`
  display: flex;
  flex-direction: row;

  & > .YearSelection {
    border-right: 1px solid #ccc;
    min-width: 6em;
    overflow-y: auto;
  }

  & > .MonthSelection {
    width: 100%;
    max-width: 18em;
    padding-top: 1ex;
    display: flex;
    flex-direction: column;

    .Buttons {
      padding-top: 0.5ex;
      padding-left: 1ex;
      button {
        width: 4em;
        margin-right: 0.25ex;
      }
    }

    .MonthlyCalendar {
      border-top: 1px solid #eee;
      margin-top: 1ex;
      padding-top: 1em;

      & > * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
`;

const SelectedYearText = styled.span<{ isSelected: boolean }>`
  ${({ isSelected }) => (isSelected ? "text-decoration: underline;" : "")}
`;

export const KintaiCalendarSelector: React.FunctionComponent<KintaiCalendarSelectorProps> = props => {
  const { onChangeDate, currentDate, unit } = props;
  const propsNendo = getNendo(props.currentDate);
  const [prevNendo, setPrevNendo] = React.useState(propsNendo);
  const [nendo, setNendo] = React.useState(propsNendo);
  const [id] = React.useState(() => genUuid());

  if (prevNendo !== propsNendo) {
    setNendo(propsNendo);
    setPrevNendo(propsNendo);
  }

  const onChangeYear = React.useCallback(
    (yearTabId: string) => {
      const inputNendo = tabIdToNendo(yearTabId);
      setNendo(inputNendo);
      if (props.unit === "year") {
        props.onChangeDate(
          moment(props.currentDate)
            .year(inputNendo)
            .toDate(),
        );
      }
    },
    [setNendo, props.unit, props.onChangeDate, props.currentDate],
  );

  const nendoList = sortBy(props.nendoList || [], it => -it);

  return (
    <Container>
      <CalendarSelection>
        <Tabs id={id} className="YearSelection" selectedTabId={`cal_${nendo}`} vertical={true} onChange={onChangeYear}>
          {nendoList.map(tabYear => (
            <Tab
              disabled={tabYear === nendo}
              key={tabYear}
              id={nendoToTabId(tabYear)}
              title={
                <SelectedYearText
                  isSelected={getNendoYear(currentDate) === tabYear}
                  className={classNames(unit === "year" && Classes.POPOVER_DISMISS)}>
                  {tabYear} 年度
                </SelectedYearText>
              }
            />
          ))}
        </Tabs>
        {unit !== "year" && (
          <div className="MonthSelection">
            <div className="Buttons">
              {getNendoMonths(nendo).map(month => (
                <MonthButton
                  terminates={unit === "month"}
                  key={`${month.toDateString()}`}
                  onChangeDate={onChangeDate}
                  currentDate={currentDate}
                  month={month}
                />
              ))}
            </div>
            {unit === "day" && getNendoYear(currentDate) === nendo && (
              <div className="MonthlyCalendar">
                <MonthlyCalendar onNavigateToDate={onChangeDate} month={currentDate} />
              </div>
            )}
          </div>
        )}
      </CalendarSelection>
    </Container>
  );
};

const nendoToTabId = (y: number) => `cal_${y}`;
const tabIdToNendo = (tabId: string) => +tabId.replace(/cal_/, "");

const MonthButton = (props: {
  terminates: boolean;
  month: Date;
  currentDate: Date;
  onChangeDate: (month: Date) => void;
}) => {
  const { month, currentDate, onChangeDate } = props;
  const onClick = () => onChangeDate(month);
  return (
    <Button
      onClick={onClick}
      className={classNames(props.terminates && Classes.POPOVER_DISMISS)}
      disabled={haveSameMonth(month, currentDate)}
      text={`${moment(month).format("MM")} 月`}
    />
  );
};
