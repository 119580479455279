import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../utils/apptitle";
import { KintaiReport, KintaiReportVM } from "../common/kintai-table/KintaiReport";
import { PageTile } from "../common/page/PageTile";
import { KintaiSubMenu, KintaiSubMenuVM } from "./header/KintaiSubMenu";

export interface KintaiPageVM {
  kintaiSubMenuVM: KintaiSubMenuVM;

  kintaiReportVM: KintaiReportVM;
}

@observer
export class KintaiPage extends React.Component<{ model: KintaiPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageTile header={<KintaiSubMenu model={model.kintaiSubMenuVM} />}>
        <Helmet>
          <title>{decorateTitle(`個人勤怠`)}</title>
        </Helmet>
        <KintaiReport model={model.kintaiReportVM} />
      </PageTile>
    );
  }
}
