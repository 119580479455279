import { Worksheet } from "exceljs";

import { fileReadPromise } from "../../utils/file";
import { WorkbookModule } from "./exceljsmodule";

export const excelLoadSheet = async (file: File, sheetName: string): Promise<ExcelSheetLoadResult> => {
  const result = await fileReadPromise(file);
  if (!result) {
    return {
      status: "failure",
      failureReason: "ファイルを読み込めませんでした。",
    };
  }
  const wb = new (await WorkbookModule())();
  try {
    await (wb.xlsx as any).load(result);
  } catch (error) {
    console.error(error);
    return {
      status: "failure",
      failureReason: "Excelブックとして読み込めませんでした。",
    };
  }

  const sheet = wb.getWorksheet(sheetName);
  if (!sheet) {
    return {
      status: "failure",
      failureReason: `「${sheetName}」シートがありませんでした。`,
    };
  }

  return {
    status: "success",
    sheet,
  };
};

export type ExcelSheetLoadResult =
  | {
      status: "success";
      sheet: Worksheet;
    }
  | {
      status: "failure";
      failureReason: string;
    };
