import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { AppState, AppStateSymbol } from "../../../models/AppState";
import { ApiServerFaultMsgVM } from "./ApiServerFaultMsg";

export class ApiServerFaultMsgVMImpl implements ApiServerFaultMsgVM {
  @lazyInject(AppStateSymbol)
  private appState: typeof AppState.Type;

  @computed
  get message() {
    return this.appState.apiServerFaultMsg || "";
  }

  @action.bound
  public onRetry() {
    this.appState.retryApiRequest();
  }
}
