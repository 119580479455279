import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { RequiredApprovalErrorsKF } from "./RequiredApprovalErrorsKF";

export type MonthlyPrivilegedNotApprovedErrorsKFProps = {
  model: MonthlyPrivilegedNotApprovedErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyPrivilegedNotApprovedErrorsKFVM {
  monthlyPrivilegedNotApprovedErrors: string[];
}

export const MonthlyPrivilegedNotApprovedErrorsKF = observer((props: MonthlyPrivilegedNotApprovedErrorsKFProps) => {
  const { model, mode } = props;
  return <RequiredApprovalErrorsKF label="却下" errors={model.monthlyPrivilegedNotApprovedErrors} mode={mode} />;
});
