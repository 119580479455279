import { createContext } from "react";

export const WkLinkContext = createContext<WkLinkRouter>({
  route(path: string) {
    console.log(`route to ${path}`);
  },
});

export interface WkLinkRouter {
  route: (path: string) => void;
}
