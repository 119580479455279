import { computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { AdminPageModel, AdminPageModelSymbol } from "../../../models/admin/AdminPageModel";
import { paths } from "../../../routing/paths";
import { AppRouter } from "../../../services/AppRouter";
import { AdminSubMenuVM } from "./AdminSubMenu";

export class AdminSubMenuVMImpl implements AdminSubMenuVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;
  @lazyInject(AdminPageModelSymbol)
  private adminPageModel: typeof AdminPageModel.Type;

  @computed
  get pageMode() {
    return this.adminPageModel.pageMode;
  }

  public onNavigateToWarnings = () => {
    this.appRouter.navigate(paths.admin.warnings.index());
  };
  public onNavigateToAccounts = () => {
    this.appRouter.navigate(paths.admin.accounts.index());
  };
  public onNavigateToPh = () => {
    this.appRouter.navigate(paths.admin.ph.index());
  };
  public onNavigateToDepts = () => {
    this.appRouter.navigate(paths.admin.depts.index());
  };
  public onNavigateToCalendar = () => {
    this.appRouter.navigate(paths.admin.calendar.index());
  };
  public onNavigateToRanks = () => {
    this.appRouter.navigate(paths.admin.ranks.index());
  };
  public onNavigateToPjClose = () => {
    this.appRouter.navigate(paths.admin.pjClose.index());
  };
  public onNavigateToSPort = () => {
    this.appRouter.navigate(paths.admin.sport.index());
  };
}
