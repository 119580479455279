import { WkAbilities, wkAbilities } from "@webkintai/privileges";
import { Instance, types } from "mobx-state-tree";

export const model = types.enumeration<WkAbilities>("typesAbilities", wkAbilities as any);

export const typesAbilities: typesAbilities_ModelType = model;
type typesAbilities_InferredType = typeof model;
// tslint:disable-next-line:class-name
export interface typesAbilities_ModelType extends typesAbilities_InferredType {}
export type typesAbilitiesInstance = Instance<typeof typesAbilities>;
