import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { LeaveKindType, LeaveKindValues, stringToLeaveKindType } from "../../../../../models/accounts/LeaveKind";
import { ErrorsList } from "../../../../common/errors/ErrorsList";
import { ErrorsLIstItem } from "../../../../common/errors/ErrorsListItem";
import { DateField } from "../../../../common/form/DateField";
import { SelectField } from "../../../../common/form/SelectField";
import { TextField } from "../../../../common/form/TextField";

export const AbsenceDialog: React.FC<{
  errors: string[];
  onAddRow: () => void;
}> = ({ onAddRow, errors, children }) => (
  <div>
    <div className="bp3-dialog-body" style={{ maxHeight: "30rem", overflow: "auto" }}>
      <p>ユーザの休業（期間重複不可・未来指定可）を設定します。</p>
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>休業種別</th>
            <th>開始日</th>
            <th>終了日</th>
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </Table>
      <ErrorsList>
        {errors.map((err, idx) => (
          <ErrorsLIstItem key={idx} style={{ color: "red" }}>
            {err}
          </ErrorsLIstItem>
        ))}
      </ErrorsList>
    </div>
    <div className="bp3-dialog-footer">
      <div className="bp3-dialog-footer-actions">
        <Button icon="add" text="行を追加する" onClick={onAddRow} />
      </div>
    </div>
  </div>
);

export const AbsenceDialogRow: React.FC<{
  no: number;

  type: LeaveKindType | undefined;
  onChangeType: (value: LeaveKindType | undefined) => void;
  typeDisabled?: boolean;

  from: Date | undefined;
  onChangeFrom: (value: Date | undefined) => void;
  fromDisabled?: boolean;

  to: Date | undefined;
  onChangeTo: (value: Date | undefined) => void;
  toDisabled?: boolean;

  remarks: string;
  onChangeRemarks: (value: string) => void;
  remarksDisabled?: boolean;

  onRemoveRow: () => void;

  errors: string[];

  children?: never;
}> = ({
  no,
  type,
  onChangeType,
  typeDisabled,
  from,
  onChangeFrom,
  fromDisabled,
  to,
  onChangeTo,
  toDisabled,
  remarks,
  onChangeRemarks,
  remarksDisabled,
  onRemoveRow,
  errors,
}) => (
  <>
    <tr>
      <td>{no}</td>

      <td style={{ width: "5rem" }}>
        <SelectField
          mode="form"
          disabled={typeDisabled}
          options={[
            { label: "", value: undefined },
            ...LeaveKindValues.map(it => ({
              label: it,
              value: it,
            })),
          ]}
          onChange={value => {
            const converted = value ? stringToLeaveKindType(value) : undefined;
            onChangeType(converted);
          }}
          value={type}
        />
      </td>
      <td>
        <DateField mode="form" disabled={fromDisabled} value={from} onChange={onChangeFrom} />
      </td>
      <td>
        <DateField mode="form" disabled={toDisabled} value={to} onChange={onChangeTo} />
      </td>
      <td>
        <Button icon="trash" onClick={onRemoveRow}>
          削除
        </Button>
      </td>
    </tr>
    <tr>
      <td />
      <th>備考:</th>
      <td colSpan={2}>
        <TextField mode="form" disabled={remarksDisabled} value={remarks} onChange={onChangeRemarks} />
      </td>
    </tr>
    <tr>
      <td />
      <td colSpan={3}>
        <ErrorsList>
          {errors.map((err, idx) => (
            <ErrorsLIstItem key={idx} style={{ color: "red" }}>
              {err}
            </ErrorsLIstItem>
          ))}
        </ErrorsList>
      </td>
    </tr>
  </>
);

const Container = styled.div`
  min-height: 10em;
  padding: 1ex;
`;

const Table = styled.table`
  width: 100%;
`;
