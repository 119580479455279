import { computed } from "mobx";

import { lazyInject } from "../../didecorators";
import { DashboardPageModel, DashboardPageModelSymbol } from "../../models/dashboard/DashboardPageModel";
import { ApplicationsPageVMImpl } from "./applications/ApplicationsPageVMImpl";
import { DashboardPageVM } from "./DashboardPage";
import { ExportPageVMImpl } from "./export/ExportPageVMImpl";
import { DashboardSubMenuVMImpl } from "./header/DashboardSubMenuVMImpl";
import { InterviewListPageVMImpl } from "./interview_list/InterviewListPageVMImpl";
import { KintaiListPageVMImpl } from "./kintai_list/KintaiListPageVMImpl";
import { KintaiSumPageVMImpl } from "./kintai_sum/KintaiSumPageVMImpl";
import { SaburokuPageVMImpl } from "./saburoku/SaburokuPageVMImpl";
import { TransDayPageVMImpl } from "./trans_day/TransDayPageVMImpl";

export class DashboardPageVMImpl implements DashboardPageVM {
  public dashboardSubMenuVM = new DashboardSubMenuVMImpl();
  public kintaiListPageVM = new KintaiListPageVMImpl();
  public kintaiSumPageVM = new KintaiSumPageVMImpl();
  public transDayPageVM = new TransDayPageVMImpl();
  public applicationsPageVM = new ApplicationsPageVMImpl();
  public saburokuPageVM = new SaburokuPageVMImpl();
  public interviewListPageVM = new InterviewListPageVMImpl();
  public exportPageVM = new ExportPageVMImpl();

  @lazyInject(DashboardPageModelSymbol)
  private dashboardPageModel: typeof DashboardPageModel.Type;

  @computed
  get pageMode() {
    return this.dashboardPageModel.pageMode;
  }
}
