import { Button, Intent, Tooltip } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/dashboard/kintai_list.md";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { LoadingStatusType } from "../../../../models/common/LoadingStatusType";
import { DeptSelectorVM } from "../../../common/dept-selector/DeptSelector";
import { DeptsSelectorButton } from "../../../common/dept-selector/DeptsSelectorButton";
import { FilterTextbox } from "../../../common/FilterTextbox";
import { CheckboxField } from "../../../common/form/CheckboxField";
import { SelectField } from "../../../common/form/SelectField";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { ResetButton } from "../../../common/ribbon/ResetButton";
import { KinmuTypeSelectSection, KinmuTypeSelectSectionVM } from "./KinmuTypeSelectSection";
import { KintaiListPageSearchType } from "./KintaiListPageSearchType";

export type KintaiListPageRibbonVM = {
  searchMode: KintaiListPageSearchType;
  onChangeSearchMode: (value: KintaiListPageSearchType) => void;
  targetMonth: Date;
  onChangeMonth: (d: Date) => void;
  nendoList: number[];
  deptsLoadingState: LoadingStatusType;
  deptSelectorVM: DeptSelectorVM;
  searchButtonEnabled: boolean;
  filterText: string;
  onChangeFilterText: (value: string) => void;
  filterApprovalOnYou: boolean;
  onChangeFilterApprovalOnYou: (value: boolean) => void;
  filterReviewerAssignedToMe: boolean;
  onChangeFilterReviewerAssignedToMe: (value: boolean) => void;
  filterApproverAssignedToMe: boolean;
  onChangeFilterApproverAssignedToMe: (value: boolean) => void;
  filterPrivilegedApproval: boolean;
  onChangeFilterPrivilegedApproval: (value: boolean) => void;
  onReload: () => void;
  lastLoadTime: Date | undefined;

  bulkAuthMode: boolean;
  onChangeBulkAuthMode: (value: boolean) => void;
  bulkAuthChangeCount: number;
  saveBulkAuthDisabled: boolean;
  onSaveBulkAuth: () => void;
  resetBulkAuthDisabled: boolean;
  onResetBulkAuth: () => void;

  showCloseDeptSection: boolean;
  closedMonth: Date | undefined;
  deptIsClosed: boolean;
  showCloseDept: boolean;
  onCloseDept: () => void;
  showReopenDept: boolean;
  onReopenDept: () => void;
} & KinmuTypeSelectSectionVM;

const options: Array<{ label: string; value: KintaiListPageSearchType }> = [
  { label: "部門ごと", value: "dept" },
  { label: "査閲・承認対象者", value: "reviewee" },
  { label: "総務・人事部承認対象", value: "privileged" },
];

@observer
export class KintaiListPageRibbon extends React.Component<{ model: KintaiListPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    return (
      <>
        <GuideRibbon markdown={Help} />
        <PageRibbonSection title="検索種別">
          <SelectField
            mode="form"
            options={options}
            onChange={model.onChangeSearchMode as any}
            value={model.searchMode}
          />
        </PageRibbonSection>
        <JumpRibbon>
          <PageRibbonLine>
            <KintaiCalendarSelectorButton
              buttonProps={{ small: true }}
              unit="month"
              currentDate={model.targetMonth}
              nendoList={model.nendoList}
              onChangeDate={this.props.model.onChangeMonth}
            />
          </PageRibbonLine>
          {model.searchMode === "dept" && (
            <PageRibbonLine>
              {model.deptsLoadingState === "loaded" && (
                <DeptsSelectorButton model={model.deptSelectorVM} buttonProps={{ small: true }} />
              )}
            </PageRibbonLine>
          )}
        </JumpRibbon>
        <ReloadRibbon onReload={model.onReload} lastLoadTime={model.lastLoadTime} />
        <KinmuTypeSelectSection model={model} />
        <FilterRibbon title="絞り込み">
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>

          {/* <KinmuTypeButton mainKinmuType="Flex" buttonProps={{ small: true }} /> */}
        </FilterRibbon>
        <FilterRibbon title="承認系フィルタ">
          <PageRibbonLine>
            <CheckboxField
              mode="form"
              label="あなたの承認待ちのみ"
              value={model.filterApprovalOnYou}
              onChange={model.onChangeFilterApprovalOnYou}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <CheckboxField
              mode="form"
              label="自分が査閲者"
              value={model.filterReviewerAssignedToMe}
              onChange={model.onChangeFilterReviewerAssignedToMe}
            />
            <CheckboxField
              mode="form"
              label="自分が承認者"
              value={model.filterApproverAssignedToMe}
              onChange={model.onChangeFilterApproverAssignedToMe}
            />
          </PageRibbonLine>
        </FilterRibbon>
        <FilterRibbon title="承認系フィルタ">
          <PageRibbonLine>
            <CheckboxField
              mode="form"
              label="総務・人事部承認待ちのみ"
              value={model.filterPrivilegedApproval}
              onChange={model.onChangeFilterPrivilegedApproval}
            />
          </PageRibbonLine>
        </FilterRibbon>
        <PageRibbonSection title="一括承認業務">
          <PageRibbonLine>
            <CheckboxField
              mode="form"
              label="一括承認モード"
              onChange={model.onChangeBulkAuthMode}
              value={model.bulkAuthMode}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <Button
              small={true}
              icon="endorsed"
              intent={Intent.PRIMARY}
              text={`一括承認実施${model.bulkAuthChangeCount > 0 ? ` (${model.bulkAuthChangeCount})` : ""}`}
              disabled={model.saveBulkAuthDisabled}
              onClick={model.onSaveBulkAuth}
            />
            <ResetButton disabled={model.resetBulkAuthDisabled} onReset={model.onResetBulkAuth} />
          </PageRibbonLine>
        </PageRibbonSection>
        {model.showCloseDeptSection && (
          <PageRibbonSection title="（事務のみ）部門勤怠締め">
            <PageRibbonLine>
              <Tooltip
                content={
                  <>
                    {model.closedMonth
                      ? `${moment(model.closedMonth).format("YYYY/MM")}まで承認済`
                      : "承認された履歴無し"}
                  </>
                }>
                {model.deptIsClosed ? "部門勤怠承認済み" : "部門勤怠未承認"}
              </Tooltip>
            </PageRibbonLine>

            <PageRibbonLine>
              {model.showCloseDept && (
                <Button
                  small={true}
                  icon="lock"
                  intent={Intent.DANGER}
                  text="部門の月の勤怠を締める"
                  onClick={model.onCloseDept}
                />
              )}
              {model.showReopenDept && (
                <Button
                  small={true}
                  icon="unlock"
                  intent={Intent.DANGER}
                  text="部門の月の勤怠締めをキャンセルする"
                  onClick={model.onReopenDept}
                />
              )}
            </PageRibbonLine>
            {/* <KinmuTypeButton mainKinmuType="Flex" buttonProps={{ small: true }} /> */}
          </PageRibbonSection>
        )}
      </>
    );
  }
}
