import { getEnv, Instance, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { TimeProvider } from "../../services/TimeProvider";
import { dateOf } from "../../utils/date";
import { getDI } from "../common/getDI";
import { PrivateDDModel } from "./prv/PrivateDDModel";
import { PublicDDModel } from "./pub/PublicDDModel";

export const model = types.optional(
  types
    .model("StasticsPageModel", {
      public: PublicDDModel,
      private: PrivateDDModel,

      targetMonth: types.optional(types.Date, new Date()),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
      };
    })
    .actions(self => {
      const appRouter = (): AppRouter => getEnv(self).get(AppRouter);

      return {
        appInit() {
          self.private.loadIfNeeded();
        },
        route(pathFragment: string) {
          const { targetYear, targetMonth } = fromPaths.dashboard.statistics.ofDate.index(pathFragment);
          const date = targetYear && targetMonth ? dateOf(targetYear, targetMonth) : undefined;
          if (date) {
            self.targetMonth = date;
          }

          self.public.route(self.targetMonth);
          self.private.route(self.targetMonth);
        },
        jumpToMonth(value: Date) {
          appRouter().navigate(paths.dashboard.statistics.ofDate(value).index());
        },
      };
    }),
  {},
);

export const StasticsPageModel: StasticsPageModelModelType = model;
type StasticsPageModel_InferredType = typeof model;
export interface StasticsPageModelModelType extends StasticsPageModel_InferredType {}
type StasticsPageModelIIf = Instance<typeof StasticsPageModel>;
export interface StasticsPageModelInstance extends StasticsPageModelIIf {}
