import { types } from "mobx-state-tree";

export const DashboardPageScreenModes = types.enumeration("DashboardPageScreenModes", [
  /** 統計 */
  "statistics",
  /** 勤怠一覧 */
  "kintai_list",
  /** 勤怠集計 */
  "kintai_sum",
  /** 振替日チェック */
  "trans_day",
  /** エクスポート */
  "export",
  /** 産業医 */
  "interview_list",
  /** サブロク */
  "saburoku",
  /** 申請 */
  "applications",
]);
