import { IModelType, types } from "mobx-state-tree";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("User", {
    userId: types.identifier,

    userName: types.string,
    mailAddress: types.maybe(types.string),
    rankCode: types.maybe(types.string),
    depCode: types.maybe(types.string),
    roleCodes: types.array(types.string),
  })
  .views(self => {
    return {};
  });

export type User_Type = typeof User.Type;
export const User: UserModelType = model;
type UserInferredType = typeof model;
export interface UserModelType extends UserInferredType {}
