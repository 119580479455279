import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type WorkplaceZaitakuLE4HKFProps = {
  model: WorkplaceZaitakuLE4HVMKF;
} & CellFormPrintAdaptable;

export interface WorkplaceZaitakuLE4HVMKF {
  workplaceZaitakuLE4H: KintaiInputItemState<boolean>;
}

export const WorkplaceZaitakuLE4HKF = observer((props: WorkplaceZaitakuLE4HKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.workplaceZaitakuLE4H);

  return <CheckboxField {...props} label="在宅4時間以下" {...itemState} />;
});
