import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { TransDayPageModel } from "../../../../models/trans_day/TransDayPageModel";
import { TransDayResultEachDayEntry } from "../../../../models/trans_day/TransDayResultEachDayEntry";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { TransDayRowDetailsVM } from "./TransDayPageTable";
import { TransDayPageTableRowVMImpl } from "./TransDayPageTableRowVMImpl";

export class TransDayRowDetailsVMImpl implements TransDayRowDetailsVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  constructor(
    private readonly parent: TransDayPageTableRowVMImpl,
    private readonly pageModel: typeof TransDayPageModel.Type,
    private readonly model: typeof TransDayResultEachDayEntry.Type,
  ) {}

  @computed
  public get id() {
    return `${this.model.date.getTime()}`;
  }

  @computed
  public get date() {
    return this.model.date;
  }

  @action.bound
  public onNavigateToDate() {
    if (!this.date) {
      return;
    }
    this.appRouter.navigate(
      paths.kintai
        .ofUser(this.parent.userId)
        .ofDate(this.date)
        .index(),
    );
  }

  @computed
  public get types() {
    return this.model.types;
  }

  @computed
  public get transDay() {
    return this.model.transDay;
  }

  @action.bound
  public onNavigateToTransDay() {
    if (!this.transDay) {
      return;
    }
    this.appRouter.navigate(
      paths.kintai
        .ofUser(this.parent.userId)
        .ofDate(this.transDay)
        .index(),
    );
  }

  @computed
  public get checkResult() {
    return this.model.valid;
  }

  @computed
  public get shows() {
    switch (this.pageModel.filterForValidity) {
      case "OK":
        return this.model.valid === true;
      case "NG":
        return this.model.valid === false;
      default:
      case "ALL":
        return true;
    }
  }
}
