import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalGivenWorkKFProps = {
  model: MTotalGivenWorkKFVM;
} & CellFormPrintAdaptable;

export interface MTotalGivenWorkKFVM {
  mTotalGivenWork: KintaiInputItemState<Minutes>;
}

export const MTotalGivenWorkKF = observer((props: MTotalGivenWorkKFProps) => {
  const { model } = props;
  const itemState = model.mTotalGivenWork;

  return <HHMMField {...props} label="mTotalGivenWorkKF" {...itemState} onChange={undefined} />;
});
