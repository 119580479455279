import { Button, Card, Elevation, Intent, Overlay } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

export interface ApiServerFaultMsgVM {
  message: string;
  onRetry: () => void;
}

const PortalDiv = styled.div`
  padding: 2em;
  width: 100%;
  margin: auto;
`;

@observer
export class ApiServerFaultMsg extends React.Component<{ model: ApiServerFaultMsgVM }> {
  public render() {
    const { model } = this.props;
    return (
      <Overlay
        autoFocus={false}
        isOpen={true}
        hasBackdrop={true}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}>
        <PortalDiv>
          <Card interactive={false} elevation={Elevation.TWO}>
            <h3>APIサーバが応答していないようです</h3>
            <div>
              <p>APIサーバから正常な応答がありませんでした。</p>
              <p>※少し時間をおいてからリトライするとうまくいくかもしれません。</p>
            </div>
            <Button intent={Intent.PRIMARY} text="リトライ" icon={"refresh"} onClick={model.onRetry} />
          </Card>
        </PortalDiv>
      </Overlay>
    );
  }
}
