import { injectable } from "inversify";
import moment from "moment";

import { ExcelColDef, generateColumns } from "../ExcelColDef";
import { excelLoadSheet } from "../excelLoadSheet";
import { excelWriteBook } from "../excelWriteBook";
import {
  AccountsExcel,
  AccountsExcelAbsentType,
  AccountsExcelExportEntity,
  AccountsExcelExportEntry,
  AccountsExcelImportResult,
} from "./AccountsExcel";

@injectable()
export class AccountsExcelImpl extends AccountsExcel {
  public async importExcel(file: File): Promise<AccountsExcelImportResult> {
    const sheetLoadResult = await excelLoadSheet(file, sheetName);
    if (sheetLoadResult.status === "failure") {
      return sheetLoadResult;
    }
    const { sheet } = sheetLoadResult;

    const rows: AccountsExcelExportEntry[] = [];

    sheet.eachRow(row => {
      if (row.number > 1) {
        const userId = fUserId.deserializer(row.getCell(1).text);

        if (userId.match(/^\d+$/)) {
          rows.push({
            userId,
            userName: fUserName.deserializer(row.getCell(2).text),
            depCode: fDepCode.deserializer(row.getCell(3).text),
            depName: fDepName.deserializer(row.getCell(4).text),
            rankCode: fRankCode.deserializer(row.getCell(5).text),
            rankName: fRankName.deserializer(row.getCell(6).text),
            mailAddress: fMailAddress.deserializer(row.getCell(7).text),
            absences: [],
            leaveDate: undefined,
            roles: [],
          });
        }
      }
    });

    return {
      status: "success",
      rows,
    };
  }
  public exportExcel(values: AccountsExcelExportEntity) {
    return excelWriteBook(async wb => {
      const sheet = wb.addWorksheet(sheetName);
      sheet.columns = generateColumns([
        fUserId,
        fUserName,
        fDepCode,
        fDepName,
        fRankCode,
        fRankName,
        fMailAddress,
        fAbsences,
        fLeaveDate,
        ...values.roleCodes.map(it => ({
          ...fRoles,
          label: fRoles.label + it,
        })),
      ]);

      values.entries.forEach(it => {
        sheet.addRow([
          fUserId.serializer(it.userId),
          fUserName.serializer(it.userName),
          fDepCode.serializer(it.depCode || ""),
          fDepName.serializer(it.depName || ""),
          fRankCode.serializer(it.rankCode || ""),
          fRankName.serializer(it.rankName || ""),
          fMailAddress.serializer(it.mailAddress),
          fAbsences.serializer(it.absences),
          fLeaveDate.serializer(it.leaveDate),
          ...values.roleCodes.map(roleCode => fRoles.serializer(!!it.roles.find(ir => roleCode === ir))),
        ]);
      });
    });
  }
}

const sheetName = "アカウント一覧";

const fUserId: ExcelColDef<string> = {
  label: "社員番号",
  type: "key",
  serializer: (value: string) => value,
  deserializer: (value: string) => value,
};

const fUserName: ExcelColDef<string> = {
  label: "氏名",
  type: "input",
  serializer: (value: string) => value,
  deserializer: (value: string) => value,
};

const fDepCode: ExcelColDef<string | undefined> = {
  label: "所属（コード）",
  type: "input",
  serializer: (value: string | undefined) => value || "",
  deserializer: (value: string) => value || undefined,
};
const fDepName: ExcelColDef<string> = {
  label: "所属（名称）",
  type: "readOnly",
  serializer: (value: string) => value,
  deserializer: (value: string) => value,
};
const fRankCode: ExcelColDef<string | undefined> = {
  label: "ランク（コード）",
  type: "input",
  serializer: (value: string | undefined) => value || "",
  deserializer: (value: string) => value || undefined,
};
const fRankName: ExcelColDef<string> = {
  label: "ランク（名称）",
  type: "readOnly",
  serializer: (value: string) => value,
  deserializer: (value: string) => value,
};
const fMailAddress: ExcelColDef<string> = {
  label: "メールアドレス",
  type: "input",
  serializer: (value: string) => value,
  deserializer: (value: string) => value,
};

const formatDate = (date: Date | undefined) => (date ? moment(date).format("YYYY/MM/DD") : "");

const fAbsences: ExcelColDef<AccountsExcelAbsentType[]> = {
  label: "休業",
  type: "readOnly",
  serializer: (value?: AccountsExcelAbsentType[]) =>
    value
      ? `${value
          .map(
            row =>
              `${row.type}: ${formatDate(row.from)} 〜 ${formatDate(row.to)}${row.remarks ? ` (${row.remarks})` : ""}`,
          )
          .join("\n")}`
      : "",
  deserializer: (value: string) => [],
};

const fLeaveDate: ExcelColDef<Date | undefined> = {
  label: "退職日",
  type: "readOnly",
  serializer: formatDate,
  deserializer: () => undefined,
};

const fRoles: ExcelColDef<boolean> = {
  label: "ロール_",
  type: "readOnly",
  serializer: (value: boolean) => (value ? "○" : ""),
  deserializer: (value: string) => !!value.match(/○/),
};
