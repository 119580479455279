import { computed } from "mobx";

import { ByOrderRow } from "../../../../models/kintai_sum/ByOrderView";
import { KintaiSumTableByOrderRowVM } from "./KintaiSumTableByOrder";

export class KintaiSumTableByOrderRowVMImpl implements KintaiSumTableByOrderRowVM {
  constructor(private readonly model: ByOrderRow) {}

  @computed
  get id() {
    return `${this.pjCode}_${this.odCode}`;
  }

  @computed
  get odCode() {
    return this.model.odCode;
  }

  @computed
  get pjCode() {
    return this.model.pjCode;
  }

  @computed
  get pjName() {
    return this.model.pjName;
  }

  @computed
  get total() {
    return this.model.total;
  }

  @computed
  get months() {
    return this.model.months;
  }
}
