import { Tab, Tabs } from "@blueprintjs/core";
import DailyAppHelp from "@webkintai/help/content/manuals/kintai/kintai_app.md";
import Help from "@webkintai/help/content/manuals/kintai/sport.md";
import { observer } from "mobx-react";
import React from "react";

import { SPortDailyFormTabIdType } from "../../../../../models/kintai/sport/SPortDailyFormTabId";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import {
  ArrivalLeaveFormLine,
  DailyKintaiFormLine4,
  DailyKintaiFormPane,
  DailyKintaiFormTabContainer,
} from "../DailyKintaiFormPane";
import { DailyKintaiHelpButton, DailyKintaiTabHelpButton } from "../DailyKintaiHelpButton";
import { ArrivalKF } from "../fields/ArrivalKF";
import { DateKF } from "../fields/DateKF";
import { LeaveKF } from "../fields/LeaveKF";
import { TypesKF } from "../fields/TypesKF";
import { SPortDailyKintaiFormApplications } from "./SPortDailyKintaiFormApplications";
import { SPortDailyKintaiFormBody } from "./SPortDailyKintaiFormBody";
import { SPortDailyVM } from "./SPortDailyVM";

@observer
export class SPortDailyKintaiForm extends React.Component<{ model: SPortDailyVM }> {
  public render() {
    const { props } = this;
    const { model } = props;

    return (
      <DailyKintaiFormPane
        onSwipe={model.onSwipe}
        header={
          <>
            <ArrivalLeaveFormLine>
              <DateKF mode="form" model={model} />
              <ArrivalKF mode="form" model={model} />
              <LeaveKF mode="form" model={model} />
              <DailyKintaiHelpButton markdown={Help} />
            </ArrivalLeaveFormLine>
            <DailyKintaiFormLine4>
              <TypesKF label="勤務分類" bunruiIdx={1} mode="form" model={model} />
              <TypesKF label="休暇分類" bunruiIdx={2} mode="form" model={model} />
              <TypesKF label="ほか分類" bunruiIdx={3} mode="form" model={model} />
            </DailyKintaiFormLine4>

            <DailyKintaiFormTabContainer>
              <Tabs selectedTabId={model.sPortDailyFormTabId} onChange={this.onChangeTab}>
                <Tab id={"kintai_details"}>勤怠詳細</Tab>
                <Tab id={"kintai_application"}>
                  <AppStageKF mode="print" model={model.dailyApp} />
                  <DailyKintaiTabHelpButton
                    markdown={DailyAppHelp}
                    active={model.sPortDailyFormTabId === "kintai_application"}
                  />
                </Tab>
              </Tabs>
            </DailyKintaiFormTabContainer>
          </>
        }>
        {model.sPortDailyFormTabId === "kintai_details" && <SPortDailyKintaiFormBody {...this.props} />}
        {model.sPortDailyFormTabId === "kintai_application" && <SPortDailyKintaiFormApplications {...this.props} />}
      </DailyKintaiFormPane>
    );
  }

  private onChangeTab = (tabId: string) =>
    this.props.model.onChangeSPortDailyFormTabId(tabId as SPortDailyFormTabIdType);
}
