import { Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { AbsenceDialogContainer } from "./grid/abcense/AbsenceDialogContainer";
import { AccountsPageGrid, AccountsPageGridVM } from "./grid/AccountsPageGrid";
import { PasswordInitDialog, PasswordInitDialogVM } from "./password/PasswordInitDialog";
import { AccountsPageRibbon, AccountsPageRibbonVM } from "./ribbon/AccountsPageRibbon";

export interface AccountsPageVM {
  showsPasswordInitDialog: boolean;
  onClosePasswordInitDialog: () => void;
  passwordInitDialogModel: PasswordInitDialogVM;

  pageRibbonModel: AccountsPageRibbonVM;
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;

  gridModel: AccountsPageGridVM;
}

@observer
export class AccountsPage extends React.Component<{ model: AccountsPageVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus, showsPasswordInitDialog } = model;

    return (
      <PageRibbon ribbon={<AccountsPageRibbon model={model.pageRibbonModel} />}>
        <Helmet>
          <title>{decorateTitle("アカウント管理")}</title>
        </Helmet>
        {loadingStatus !== "loaded" && (
          <GridTableLoading loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
        )}
        {loadingStatus === "loaded" && (
          <>
            <AccountsPageGrid model={model.gridModel} />
          </>
        )}

        {showsPasswordInitDialog && (
          <Dialog
            title="アカウントパスワードの初期化を行います"
            isOpen={showsPasswordInitDialog}
            lazy={true}
            onClose={model.onClosePasswordInitDialog}>
            <PasswordInitDialog model={model.passwordInitDialogModel} />
          </Dialog>
        )}

        <AbsenceDialogContainer />
      </PageRibbon>
    );
  }
}
