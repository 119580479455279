import { types } from "mobx-state-tree";

const model = types
  .model("KintaiMonthlyError", {
    code: types.string,
    level: types.string,
    message: types.string,
  })
  .views(self => {
    return {
      get isError() {
        return self.level === "ERROR";
      },

      get isWarning() {
        return self.level === "WARN";
      },

      get isInfo() {
        return self.level === "INFO";
      },
    };
  });
export const KintaiMonthlyError: KintaiMonthlyErrorModelType = model;
type KintaiMonthlyErrorInferredType = typeof model;
export interface KintaiMonthlyErrorModelType extends KintaiMonthlyErrorInferredType {}
