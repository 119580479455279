import { types } from "mobx-state-tree";

import { prefixedIdType } from "../utils";

export const idPrefix = "NendoDayCalendar_";
const model = types.model("NendoDayCalendar", {
  id: prefixedIdType(idPrefix),
  nendo: types.number,
  month: types.number,
  day: types.number,
  isHoliday: types.optional(types.boolean, false),
  isFinalDay: types.optional(types.boolean, false),
});

export const idNendoDayCalendar = (nendo: number, month: number, day: number) => `${idPrefix}${nendo}_${month}_${day}`;
export type NendoDayCalendarType = typeof NendoDayCalendar.Type;
export const NendoDayCalendar: NendoDayCalendarModelType = model;
type NendoDayCalendarInferredType = typeof model;
export interface NendoDayCalendarModelType extends NendoDayCalendarInferredType {}
