import { range } from "lodash-es";
import { observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { HHMMField } from "../../../form/HHMMField";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import { ActualKF } from "../../daily/fields/ActualKF";
import { AllNightOffKF } from "../../daily/fields/AllNightOffKF";
import { ArrivalKF } from "../../daily/fields/ArrivalKF";
import { CkrpEnterKF } from "../../daily/fields/CkrpEnterKF";
import { CkrpLeaveKF } from "../../daily/fields/CkrpLeaveKF";
import { DateKF } from "../../daily/fields/DateKF";
import { DiffKF } from "../../daily/fields/DiffKF";
import { DoorEnterKF } from "../../daily/fields/DoorEnterKF";
import { DoorLeaveKF } from "../../daily/fields/DoorLeaveKF";
import { FlexKF } from "../../daily/fields/FlexKF";
import { GrowingOverKF } from "../../daily/fields/GrowingOverKF";
import { HolidayKF } from "../../daily/fields/HolidayKF";
import { HourlyFamilycareKF } from "../../daily/fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../../daily/fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../../daily/fields/hourlyleave/HourlyNursingcare";
import { LeaveKF } from "../../daily/fields/LeaveKF";
import { MidnightKF } from "../../daily/fields/MidnightKF";
import { OffKF } from "../../daily/fields/OffKF";
import { OverHolidayKF } from "../../daily/fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../../daily/fields/OverHolidayMidnightKF";
import { OverWeekdayKF } from "../../daily/fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../../daily/fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../../daily/fields/RemarksKF";
import { RestKF } from "../../daily/fields/RestKF";
import { SpHolidayKF } from "../../daily/fields/SpHolidayKF";
import { SpHolidayMidnightKF } from "../../daily/fields/SpHolidayMidnightKF";
import { SpWeekdayKF } from "../../daily/fields/SpWeekdayKF";
import { SpWeekdayMidnightKF } from "../../daily/fields/SpWeekdayMidnightKF";
import { TransDayKF } from "../../daily/fields/TransDayKF";
import { BunruiIdx, TypesKF } from "../../daily/fields/TypesKF";
import { UnusedRestNightKF } from "../../daily/fields/unused-rest/UnusedRestNightKF";
import { UnusedRestSunsetKF } from "../../daily/fields/unused-rest/UnusedRestSunsetKF";
import { WorkplaceSonotaKF } from "../../daily/fields/workplace/WorkplaceSonotaKF";
import { WorkplaceSyussyaKF } from "../../daily/fields/workplace/WorkplaceSyussyaKF";
import { WorkplaceZaitakuGT4HKF } from "../../daily/fields/workplace/WorkplaceZaitakuGT4HKF";
import { WorkplaceZaitakuLE4HKF } from "../../daily/fields/workplace/WorkplaceZaitakuLE4HKF";
import { MTotalHourlyFamilycareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyFamilycareKF";
import { MTotalHourlyLeaveKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyLeaveKF";
import { MTotalHourlyNursingcareKF } from "../../monthly/fields/leaves/hourly/MTotalHourlyNursingcareKF";
import { MTotalActualKF } from "../../monthly/fields/MTotalActualKF";
import { MTotalAllNightOffKF } from "../../monthly/fields/MTotalAllNightOffKF";
import { MTotalDiffKF } from "../../monthly/fields/MTotalDiffKF";
import { MTotalFlexKF } from "../../monthly/fields/MTotalFlexKF";
import { MTotalHolidayKF } from "../../monthly/fields/MTotalHolidayKF";
import { MTotalLiquidateKF } from "../../monthly/fields/MTotalLiquidateKF";
import { MTotalMidnightKF } from "../../monthly/fields/MTotalMidnightKF";
import { MTotalOffKF } from "../../monthly/fields/MTotalOffKF";
import { MTotalOverHolidayKF } from "../../monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../monthly/fields/MTotalOverKF";
import { MTotalOverWeekdayKF } from "../../monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalRestKF } from "../../monthly/fields/MTotalRestKF";
import { MTotalSpHolidayKF } from "../../monthly/fields/MTotalSpHolidayKF";
import { MTotalSpHolidayMidnightKF } from "../../monthly/fields/MTotalSpHolidayMidnightKF";
import { MTotalSpWeekdayKF } from "../../monthly/fields/MTotalSpWeekdayKF";
import { MTotalSpWeekdayMidnightKF } from "../../monthly/fields/MTotalSpWeekdayMidnightKF";
import { PJRBodyActualTimeKF } from "../../pjreport/fields/PJRBodyActualTimeKF";
import { PJRBodyNotPopulatedTimeKF } from "../../pjreport/fields/PJRBodyNotPopulatedTimeKF";
import { PJRFooterActualTimeKF } from "../../pjreport/fields/PJRFooterActualTimeKF";
import { PJRFooterNotPopulatedTimeKF } from "../../pjreport/fields/PJRFooterNotPopulatedTimeKF";
import { RegularDailyItemsVisibility } from "../../visibility/RegularDailyItemsVisibility";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";
import { ArrivalLeaveTilda } from "../common/ArrivalLeaveTilda";
import { CkrpEntLeaveDisplay } from "../common/CkrpEntLeaveDisplay";
import { DoorEntLeaveDisplay } from "../common/DoorEntLeaveDisplay";
import { RemakrsWrapperTd } from "../common/RemarksWrapper";
import { RegularPrintedKintaiVM } from "./RegularPrintedKintai";

@observer
export class RegularPrintedMainTable extends React.Component<{ model: RegularPrintedKintaiVM }> {
  @observable
  private dailyInputMode: any = "print";

  public render() {
    const { model } = this.props;
    const { regularRows, regularMonthlyTotal: total, rankPosition, kintaiType, printDetailMode } = model;

    const isManager = rankPosition === "1";
    const date = regularRows[0].date;
    const visibility = new RegularDailyItemsVisibility(date, kintaiType, rankPosition);
    const vacantVisibility = new VacantDayItemsVisibility(date);

    return (
      <div>
        <table className="FullWidth">
          <thead>
            {/* ヘッダ: 1行目 */}
            <tr>
              <th>{date.getFullYear()}年</th>

              <th colSpan={1}>指示時間</th>
              <th colSpan={kintaiType === "Flex" ? 3 : 2}>
                <HHMMField mode="print" value={model.designatedMinutes} />
              </th>

              <th colSpan={2}>所属</th>
              <th colSpan={(kintaiType === "Flex" ? 6 : 4) + (vacantVisibility.newDisplaySinceHourlyNF ? 2 : 0)}>
                {model.depName}
              </th>

              <th>氏名</th>
              <th colSpan={(kintaiType === "Flex" ? 4 : 3) + (visibility.unusedRestTimes ? 2 : 0)}>{model.name}</th>

              {visibility.workplace && <th colSpan={4}>勤務場所</th>}

              <th colSpan={2}>ランク</th>
              <th colSpan={2}>{model.rankName}</th>

              <th>社員番号</th>
              <th colSpan={2}>{model.userId}</th>

              <th colSpan={2}>月報</th>

              <th rowSpan={3}>備考</th>
            </tr>

            {/* ヘッダ: 2行目 */}
            <tr>
              <th rowSpan={2}>
                {date.getMonth() + 1}
                <br />
                月分
              </th>

              <th colSpan={vacantVisibility.newDisplaySinceHourlyNF ? 5 : 7}>摘要</th>

              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th colSpan={3}>時間休(H)</th>
                  <th rowSpan={2}>
                    振替等
                    <br />
                    日付
                  </th>
                </>
              )}

              <th rowSpan={2} colSpan={3}>
                勤務時間
                {printDetailMode === "confirm" && (
                  <>
                    <DoorEntLeaveDisplay>入退室時間</DoorEntLeaveDisplay>
                    <CkrpEntLeaveDisplay>ここレポ出勤退勤時間</CkrpEntLeaveDisplay>
                  </>
                )}
              </th>
              <th rowSpan={2}>差引</th>

              {!visibility.unusedRestTimes && <th rowSpan={2}>休憩</th>}
              {visibility.unusedRestTimes && (
                <>
                  <th rowSpan={2} colSpan={1}>
                    休憩
                  </th>
                  <th rowSpan={1} colSpan={2}>
                    休憩未取得
                  </th>
                </>
              )}

              <th rowSpan={2}>実働</th>
              {kintaiType === "Flex" && (
                <th rowSpan={2}>
                  FLEX
                  <br />
                  対象
                  <br />
                  時間
                </th>
              )}
              <th rowSpan={2}>
                休日
                <br />
                時間
              </th>
              {kintaiType === "Flex" && (
                <th rowSpan={2}>
                  深夜
                  <br />
                  時間
                </th>
              )}
              {kintaiType === "Flex" && (
                <th rowSpan={2}>
                  徹休
                  <br />
                  時間
                </th>
              )}

              {visibility.workplace && (
                <>
                  <WorkplaceTh>
                    在宅
                    <br />
                    4H
                    <br />超
                  </WorkplaceTh>
                  <WorkplaceTh>
                    在宅
                    <br />
                    4H
                    <br />
                    以下
                  </WorkplaceTh>
                  <WorkplaceTh>通常出社先</WorkplaceTh>
                  <WorkplaceTh>その他</WorkplaceTh>
                </>
              )}

              <th rowSpan={1} colSpan={4}>
                {kintaiType === "Flex" && "特別勤務"}
                {kintaiType === "Non-Flex" && (
                  <>
                    {!isManager && "残業時間"}
                    {isManager && "深夜時間"}
                  </>
                )}
              </th>

              {kintaiType === "Flex" && (
                <th rowSpan={2}>
                  清算
                  <br />
                  時間
                </th>
              )}
              <th rowSpan={2}>
                残業
                <br />
                累計
              </th>
              <th rowSpan={2}>
                遅早外
                <br />
                時間
              </th>

              <th rowSpan={2}>
                就業
                <br />
                時間
              </th>
              <th rowSpan={2}>
                その他
                <br />
                間接
              </th>
            </tr>

            {/* ヘッダ: 3行目 */}
            <tr>
              {/* 分類 */}
              <th colSpan={1}>
                承認
                <br />
                状態
              </th>
              <th colSpan={4}>分類</th>

              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th>
                    有<br />休
                  </th>
                  <th>
                    看<br />護
                  </th>
                  <th>
                    介<br />護
                  </th>
                </>
              )}
              {!vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <th>
                    時間有休
                    <br />
                    (H)
                  </th>
                  <th>
                    振替等
                    <br />
                    日付
                  </th>
                </>
              )}

              {visibility.unusedRestTimes && (
                <>
                  <UnusedRestTh>
                    18:00
                    <br />
                    〜
                    <br />
                    18:30
                  </UnusedRestTh>
                  <UnusedRestTh>
                    21:00
                    <br />
                    〜
                    <br />
                    21:30
                  </UnusedRestTh>
                </>
              )}

              <th>普通</th>
              <th>
                平日
                <br />
                深夜
              </th>
              <th>休日</th>
              <th>
                休日
                <br />
                深夜
              </th>
            </tr>
          </thead>
          {printDetailMode !== "brief" && (
            <tbody>
              {regularRows.map((row, rowNumber) => (
                <tr key={row.date.getTime()} className={rowNumber % 2 === 0 ? "even" : "odd"}>
                  <td className="center">
                    <DateKF mode="print" model={row} />
                  </td>

                  <td className="center col_group_start">
                    <AppStageKF mode="print" model={row.dailyApp} />
                  </td>

                  {/* 分類 */}
                  {range(1, 5).map(i => (
                    <td key={i} className="left">
                      <TypesKF mode={this.dailyInputMode} model={row} bunruiIdx={i as BunruiIdx} />
                    </td>
                  ))}

                  <td>
                    <HourlyLeaveKF mode={this.dailyInputMode} model={row} />
                  </td>

                  {vacantVisibility.newDisplaySinceHourlyNF && (
                    <>
                      <td>
                        <HourlyNursingcareKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td>
                        <HourlyFamilycareKF mode={this.dailyInputMode} model={row} />
                      </td>
                    </>
                  )}

                  <td className="col_group_end">
                    <TransDayKF mode={this.dailyInputMode} model={row} />
                  </td>

                  {/* 勤務時間 */}
                  <td className="col_group_start">
                    <ArrivalKF mode={this.dailyInputMode} model={row} />
                    {printDetailMode === "confirm" && (
                      <>
                        <DoorEntLeaveDisplay>
                          <DoorEnterKF mode={this.dailyInputMode} model={row} />
                        </DoorEntLeaveDisplay>
                        <CkrpEntLeaveDisplay>
                          <CkrpEnterKF mode={this.dailyInputMode} model={row} />
                        </CkrpEntLeaveDisplay>
                      </>
                    )}
                  </td>
                  <td className="center">
                    <ArrivalLeaveTilda mode={this.dailyInputMode} model={row} />
                  </td>
                  <td className="col_group_end">
                    <LeaveKF mode={this.dailyInputMode} model={row} />
                    {printDetailMode === "confirm" && (
                      <>
                        <DoorEntLeaveDisplay>
                          <DoorLeaveKF mode={this.dailyInputMode} model={row} />
                        </DoorEntLeaveDisplay>
                        <CkrpEntLeaveDisplay>
                          <CkrpLeaveKF mode={this.dailyInputMode} model={row} />
                        </CkrpEntLeaveDisplay>
                      </>
                    )}
                  </td>

                  <td>
                    <DiffKF mode={this.dailyInputMode} model={row} />
                  </td>
                  <td>
                    <RestKF mode={this.dailyInputMode} model={row} />
                  </td>

                  {visibility.unusedRestTimes && (
                    <>
                      <td className="center col_group_start">
                        <UnusedRestSunsetKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td className="center col_group_end">
                        <UnusedRestNightKF mode={this.dailyInputMode} model={row} />
                      </td>
                    </>
                  )}
                  <td>
                    <ActualKF mode={this.dailyInputMode} model={row} />
                  </td>
                  {kintaiType === "Flex" && (
                    <td>
                      <FlexKF mode={this.dailyInputMode} model={row} />
                    </td>
                  )}
                  <td>
                    <HolidayKF mode={this.dailyInputMode} model={row} />
                  </td>
                  {kintaiType === "Flex" && (
                    <td>
                      <MidnightKF mode={this.dailyInputMode} model={row} />
                    </td>
                  )}
                  {kintaiType === "Flex" && (
                    <td>
                      <AllNightOffKF mode={this.dailyInputMode} model={row} />
                    </td>
                  )}

                  {/* 勤務場所 */}
                  {visibility.workplace && (
                    <>
                      <td className="center col_group_start">
                        <WorkplaceZaitakuGT4HKF mode="print" model={row} />
                      </td>
                      <td className="center">
                        <WorkplaceZaitakuLE4HKF mode="print" model={row} />
                      </td>
                      <td className="center">
                        <WorkplaceSyussyaKF mode="print" model={row} />
                      </td>
                      <td className="center col_group_end">
                        <WorkplaceSonotaKF mode="print" model={row} />
                      </td>
                    </>
                  )}

                  {/* 特別勤務 */}
                  {kintaiType === "Flex" && (
                    <>
                      <td className="col_group_start">
                        <SpWeekdayKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td>
                        <SpWeekdayMidnightKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td>
                        <SpHolidayKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td className="col_group_end">
                        <SpHolidayMidnightKF mode={this.dailyInputMode} model={row} />
                      </td>
                    </>
                  )}

                  {/* 残業 */}
                  {kintaiType === "Non-Flex" && (
                    <>
                      <td className="col_group_start">
                        <OverWeekdayKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td>
                        <OverWeekdayMidnightKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td>
                        <OverHolidayKF mode={this.dailyInputMode} model={row} />
                      </td>
                      <td className="col_group_end">
                        <OverHolidayMidnightKF mode={this.dailyInputMode} model={row} />
                      </td>
                    </>
                  )}

                  {/* 清算時間〜 */}
                  {kintaiType === "Flex" && <td>{/* ない */}</td>}

                  <td>
                    <GrowingOverKF mode={this.dailyInputMode} model={row} />
                  </td>
                  <td>
                    <OffKF mode={this.dailyInputMode} model={row} />
                  </td>

                  {/* 月報系 */}
                  <td className="col_group_start">
                    <PJRBodyActualTimeKF mode="print" model={row} />
                  </td>
                  <td className="col_group_end">
                    <PJRBodyNotPopulatedTimeKF mode="print" model={row} />
                  </td>

                  {/* 備考 */}
                  <RemakrsWrapperTd>
                    <RemarksKF mode={this.dailyInputMode} model={row} />
                  </RemakrsWrapperTd>
                </tr>
              ))}
            </tbody>
          )}
          <tfoot>
            <tr>
              {vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <td colSpan={6} />
                  <td>
                    <MTotalHourlyLeaveKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalHourlyNursingcareKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalHourlyFamilycareKF mode="print" model={total} />
                  </td>
                  <td colSpan={4} />
                </>
              )}
              {!vacantVisibility.newDisplaySinceHourlyNF && (
                <>
                  <td colSpan={11} />
                </>
              )}

              <td>
                <MTotalDiffKF mode="print" model={total} />
              </td>
              <td>
                <MTotalRestKF mode="print" model={total} />
              </td>
              {visibility.unusedRestTimes && (
                <>
                  <td />
                  <td />
                </>
              )}
              <td>
                <MTotalActualKF mode="print" model={total} />
              </td>
              {kintaiType === "Flex" && (
                <td>
                  <MTotalFlexKF mode="print" model={total} />
                </td>
              )}
              <td>
                <MTotalHolidayKF mode="print" model={total} />
              </td>
              {kintaiType === "Flex" && (
                <td>
                  <MTotalMidnightKF mode="print" model={total} />
                </td>
              )}
              {kintaiType === "Flex" && (
                <td>
                  <MTotalAllNightOffKF mode="print" model={total} />
                </td>
              )}

              {/* 勤務場所 */}
              {visibility.workplace && (
                <>
                  <td />
                  <td />
                  <td />
                  <td />
                </>
              )}

              {kintaiType === "Flex" && (
                <>
                  {/* Sp系 */}
                  <td>
                    <MTotalSpWeekdayKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalSpWeekdayMidnightKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalSpHolidayKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalSpHolidayMidnightKF mode="print" model={total} />
                  </td>
                </>
              )}

              {kintaiType === "Non-Flex" && (
                <>
                  <td>
                    <MTotalOverWeekdayKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalOverWeekdayMidnightKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalOverHolidayKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalOverHolidayMidnightKF mode="print" model={total} />
                  </td>
                </>
              )}

              {kintaiType === "Flex" && (
                <td>
                  <MTotalLiquidateKF mode="print" model={total} />
                </td>
              )}
              <td>
                <MTotalOverKF mode="print" model={total} />
              </td>
              <td>
                <MTotalOffKF mode="print" model={total} />
              </td>

              {/* 月報系 */}
              <td>
                <PJRFooterActualTimeKF model={total} />
              </td>
              <td>
                <PJRFooterNotPopulatedTimeKF model={total} />
              </td>

              {/* 備考 */}
              <td />
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }

  private switchMode = () => {
    runInAction(() => {
      if (this.dailyInputMode === "print") {
        this.dailyInputMode = "form";
      } else {
        this.dailyInputMode = "print";
      }
    });
  };
}

const UnusedRestTh: React.FC<{}> = ({ children }) => (
  <th
    style={{
      fontSize: "6pt",
      width: "5em",
      lineHeight: 0.8,
    }}>
    {children}
  </th>
);

const WorkplaceTh: React.FC = ({ children }) => (
  <th rowSpan={2} style={{ fontSize: "6pt", width: "1.8rem" }}>
    {children}
  </th>
);
