import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { ErrorOverlay, ErrorOverlayProps } from "./ErrorOverlay";
import { cellFormStyle, cellStyle, DisabledClassName, DisabledColor as disabledColor } from "./FormCommon";

export type CheckboxFieldProps = {
  label?: string;
  value: boolean;
  hasNoChange?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
} & ErrorOverlayProps &
  CellFormPrintAdaptable;

/**
 * form モード時のエラー表示はサポートしていないので別の方法で出力すること
 */
export const CheckboxField: React.FC<CheckboxFieldProps> = props => {
  const { mode, onChange, value, hasNoChange, disabled, label } = props;

  if (mode === "print") {
    return <>{value ? "○" : ""}</>;
  }

  const onClick = React.useCallback(() => {
    if (!onChange) {
      return;
    }
    onChange(!value);
  }, [onChange, value]);

  if (mode === "cell") {
    return (
      <ErrorOverlay mode={mode} errors={props.errors} warnings={props.warnings} infos={props.infos}>
        <Cell
          type="button"
          style={{
            color: value ? "inherit" : "#ccc",
            backgroundColor: hasNoChange === false ? "yellow" : disabled ? disabledColor : "inherit",
          }}
          className={classNames(FocusMarkerClassName, disabled && DisabledClassName)}
          disabled={disabled}
          value={value ? "✔" : "☐"}
          onClick={onClick}
        />
      </ErrorOverlay>
    );
  }

  return (
    <Form
      small={true}
      style={{
        backgroundColor: hasNoChange === false ? "yellow" : disabled ? disabledColor : "inherit",
      }}
      icon={value ? "tick" : "blank"}
      onClick={onClick}
      disabled={disabled}>
      {label}
    </Form>
  );
};

const Cell = styled.input`
  ${cellStyle} ${cellFormStyle}

  text-align: center;
`;

const Form = styled(Button)`
  &.NotChecked {
    .bp3-button-text {
      color: #ccc;
    }
  }
  .bp3-icon {
    padding-right: 4px;
    border-right: 1px dashed #ccc;
  }
`;
