import { Button, Intent } from "@blueprintjs/core";
import React from "react";

export const SaveButton: React.FunctionComponent<{
  disabled?: boolean;
  saveLabel?: string;
  onSave?: () => void;
}> = props => (
  <Button disabled={props.disabled} onClick={props.onSave} small={true} intent={Intent.PRIMARY} icon="floppy-disk">
    {props.saveLabel || "保存"}
  </Button>
);
