import { GivenWorkTimeControllerApi, GivenWorkTimeEntity, GivenWorkTimeResult } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { SPortAdminApi } from "./SPortAdminApi";

export class SPortAdminApiImpl extends SPortAdminApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getGivenWorktime(targetMonth: Date): Promise<GivenWorkTimeResult> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(GivenWorkTimeControllerApi)
        .getSPortGivenWorkTime(targetMonth.getFullYear(), targetMonth.getMonth() + 1),
    );
  }

  public saveGivenWorktime(targetMonth: Date, payload: GivenWorkTimeEntity): Promise<unknown> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(GivenWorkTimeControllerApi)
        .updateSPortGivenWorkTime(targetMonth.getFullYear(), targetMonth.getMonth() + 1, payload),
    );
  }
}
