import {
  FatigueCheckEditEntity,
  FatigueChecksResult,
  FatigueCheckSubmitRequest,
  MeFatigueChecksResult,
} from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class FatigueCheckApi {
  public abstract getFatigueChecks(nendo: number): Promise<FatigueChecksResult>;
  public abstract getFatigueChecksForMe(nendo: number): Promise<MeFatigueChecksResult>;

  public abstract updateFatigueCheck(
    userId: string,
    yearMonth: Date,
    payload: FatigueCheckEditEntity,
  ): Promise<unknown>;

  public abstract setFatigueSubmitStatusForMe(month: Date, payload: FatigueCheckSubmitRequest): Promise<unknown>;
}
