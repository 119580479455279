import { observer } from "mobx-react";
import React from "react";

import { MainKintaiType } from "../../../../models/kintai/MainKintaiType";
import { MTotalPaidDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/composite/MTotalPaidDaysKF";
import { MTotalPaidAllDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/old/MTotalPaidAllDaysKF";
import { MTotalPaidHalfDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/old/MTotalPaidHalfDaysKF";
import { MTotalOtherDaysKF } from "../../../common/kintai-table/monthly/fields/leaves/plain/MTotalOtherDays";
import { MTotalAllNightCountKF } from "../../../common/kintai-table/monthly/fields/MTotalAllNightCountKF";
import { MTotalAllNightOffDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalAllNightOffDaysKF";
import { MTotalAllNightOffKF } from "../../../common/kintai-table/monthly/fields/MTotalAllNightOffKF";
import { MTotalCompDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalCompDaysKF";
import { MTotalHolidayDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalHolidayDaysKF";
import { MTotalHolidaySumKF } from "../../../common/kintai-table/monthly/fields/MTotalHolidaySumKF";
import { MTotalLawBasisKF } from "../../../common/kintai-table/monthly/fields/MTotalLawBasisKF";
import { MTotalLawOverCalcKF } from "../../../common/kintai-table/monthly/fields/MTotalLawOverCalcKF";
import { MTotalLawOverNineKF } from "../../../common/kintai-table/monthly/fields/MTotalLawOverNineKF";
import { MTotalLawOverTodayKF } from "../../../common/kintai-table/monthly/fields/MTotalLawOverTodayKF";
import { MTotalLeaveDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalLeaveDaysKF";
import { MTotalLiquidateSumKF } from "../../../common/kintai-table/monthly/fields/MTotalLiquidateSumKF";
import { MTotalMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalMidnightKF";
import { MTotalNonAttendanceDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalNonAttendanceDaysKF";
import { MTotalNormalDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalNormalDaysKF";
import { MTotalOffKF } from "../../../common/kintai-table/monthly/fields/MTotalOffKF";
import { MTotalOtherHoursKF } from "../../../common/kintai-table/monthly/fields/MTotalOtherHours";
import { MTotalOverHolidayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../../common/kintai-table/monthly/fields/MTotalOverKF";
import { MTotalOverSixtyHolidayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverSixtyHolidayKF";
import {
  MTotalOverSixtyHolidayMidnightKF,
} from "../../../common/kintai-table/monthly/fields/MTotalOverSixtyHolidayMidnightKF";
import { MTotalOverSixtyLiquidateKF } from "../../../common/kintai-table/monthly/fields/MTotalOverSixtyLiquidateKF";
import { MTotalOverSixtyWeekdayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverSixtyWeekdayKF";
import {
  MTotalOverSixtyWeekdayMidnightKF,
} from "../../../common/kintai-table/monthly/fields/MTotalOverSixtyWeekdayMidnightKF";
import { MTotalOverWeekdayKF } from "../../../common/kintai-table/monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalPositiveDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalPositiveDaysKF";
import { MTotalSickoutDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSickoutDaysKF";
import { MTotalSpDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSpDaysKF";
import { MTotalSpHolidayKF } from "../../../common/kintai-table/monthly/fields/MTotalSpHolidayKF";
import { MTotalSpHolidayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalSpHolidayMidnightKF";
import { MTotalSpWeekdayKF } from "../../../common/kintai-table/monthly/fields/MTotalSpWeekdayKF";
import { MTotalSpWeekdayMidnightKF } from "../../../common/kintai-table/monthly/fields/MTotalSpWeekdayMidnightKF";
import { MTotalSummerDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalSummerDaysKF";
import { MTotalSundayOnCountKF } from "../../../common/kintai-table/monthly/fields/MTotalSundayOnCountKF";
import { MTotalTransOffDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalTransOffDaysKF";
import { MTotalTransOnDaysKF } from "../../../common/kintai-table/monthly/fields/MTotalTransOnDaysKF";
import {
  MTotalWorkplaceSonotaDaysKF,
} from "../../../common/kintai-table/monthly/fields/workplace/MTotalWorkplaceSonotaDaysKF";
import {
  MTotalWorkplaceSyussyaDaysKF,
} from "../../../common/kintai-table/monthly/fields/workplace/MTotalWorkplaceSyussyaDaysKF";
import {
  MTotalWorkplaceZaitakuDaysKF,
} from "../../../common/kintai-table/monthly/fields/workplace/MTotalWorkplaceZaitakuDaysKF";
import { RegularPrintedItemsVisibility } from "../../../common/kintai-table/visibility/RegularPrintedItemsVisibility";
import { VacantDayItemsVisibility } from "../../../common/kintai-table/visibility/VacantDayItemsVisibility";
import { GridSize } from "../../../common/table/GridSize";
import { GridTable } from "../../../common/table/GridTable";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { kintaiListPageGridItemsBody, kintaiListPageGridItemsHeader } from "../common/CommonKintaiListPageGridItems";
import {
  HeaderLabelLawOver,
  HeaderLabelOver60,
  HeaderLabelTokukin,
  HeaderLabelWorkplaceTotal,
  HeaderLabelYasumi,
  HeaderLabelZangyou,
} from "../common/KintaiListHeaderLabels";
import { RegularKintaiListPageGridRowVM } from "./RegularKintaiListPageGridRowVM";

export interface RegularKintaiListPageGridVM {
  regularRows: RegularKintaiListPageGridRowVM[];
  kintaiType: typeof MainKintaiType.Type;
  month: Date;
  showsBulkAuth: boolean;
}

const standardWidth = GridSize.hhmmTotalField;

@observer
export class RegularKintaiListPageGrid extends React.Component<{ model: RegularKintaiListPageGridVM }> {
  public render() {
    const { regularRows: rows } = this.props.model;
    const firstRow = rows[0]!;

    return (
      <GridTable
        rowIdGenerator={this.rowIdGenerator}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { regularRows: rows } = this.props.model;

    return rows[rowIndex - 1].userId;
  };

  private get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { regularRows: rows, showsBulkAuth } = model;

    return kintaiListPageGridItemsHeader(rows, showsBulkAuth);
  }

  private get bodyColumns(): TableColumnDef[] {
    const { regularRows: rows, month, kintaiType } = this.props.model;
    const printedItemVisibility = new RegularPrintedItemsVisibility(month, kintaiType);
    const vacantVisibility = new VacantDayItemsVisibility(month);

    const result = kintaiListPageGridItemsBody(rows);

    result.push({
      id: "振替出勤",
      width: standardWidth,
      renderHeader: () => (
        <>
          振替
          <br />
          出勤
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalTransOnDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    result.push({
      id: "振替休日",
      width: standardWidth,
      renderHeader: () => (
        <>
          振替
          <br />
          休日
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalTransOffDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    result.push({
      id: "平常出勤",
      width: GridSize.dayCountField,
      renderHeader: () => (
        <>
          平常
          <br />
          出勤
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalNormalDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    result.push({
      id: "休日出勤",
      width: standardWidth,
      renderHeader: () => (
        <>
          休日
          <br />
          出勤
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalHolidayDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });
    result.push({
      id: "日曜出勤回数",
      width: standardWidth,
      renderHeader: () => (
        <>
          日曜
          <br />
          出勤
          <br />
          回数
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalSundayOnCountKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    if (printedItemVisibility.midnightCount) {
      result.push({
        id: "徹夜回数",
        width: standardWidth,
        renderHeader: () => (
          <>
            徹夜
            <br />
            回数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalAllNightCountKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    if (vacantVisibility.sickoutDaysOrNonattendanceDays === "sickoutDays") {
      result.push({
        id: "欠勤回数(休業含)",
        width: standardWidth,
        renderHeader: () => (
          <>
            欠勤
            <br />
            回数
            <br />
            (休業等)
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalSickoutDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    } else {
      result.push({
        id: "欠勤回数",
        width: standardWidth,
        renderHeader: () => (
          <>
            欠勤
            <br />
            回数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalNonAttendanceDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    if (vacantVisibility.leaveDays) {
      result.push({
        id: "休業日数",
        width: standardWidth,
        renderHeader: () => (
          <>
            休業
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLeaveDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    result.push({
      id: "代休日数",
      width: standardWidth,
      renderHeader: () => (
        <>
          代休
          <br />
          日数
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalCompDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    if (printedItemVisibility.allnightOffDays) {
      result.push({
        id: "徹休日数",
        width: standardWidth,
        renderHeader: () => (
          <>
            徹休
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalAllNightOffDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    if (vacantVisibility.showAggregatedPaidDays) {
      result.push({
        id: "有休",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            有休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "その他",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelYasumi />
            その他
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalOtherDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });

      if (vacantVisibility.showAggregatedPaidHours) {
        result.push({
          id: "その他(時間)",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelYasumi />
              その他
              <br />
              時間休
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOtherHoursKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        });
      }
    } else {
      result.push({
        id: "ポ休",
        width: standardWidth,
        renderHeader: () => (
          <>
            (休)
            <br />
            ポ休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPositiveDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "有休",
        width: standardWidth,
        renderHeader: () => (
          <>
            (休)
            <br />
            有休
            <br />
            (終日)
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidAllDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      result.push({
        id: "半休",
        width: standardWidth,
        renderHeader: () => (
          <>
            (休)
            <br />
            半休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalPaidHalfDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
      if (vacantVisibility.summerDays) {
        result.push({
          id: "夏休",
          width: standardWidth,
          renderHeader: () => (
            <>
              (休)
              <br />
              夏休
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSummerDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        });
      }
      result.push({
        id: "特休",
        width: standardWidth,
        renderHeader: () => (
          <>
            (休)
            <br />
            特休
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalSpDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }
    result.push({
      id: "遅刻早退外出",
      width: standardWidth,
      renderHeader: () => (
        <>
          遅刻
          <br />
          早退
          <br />
          外出
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalOffKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });
    result.push({
      id: "休日時間",
      width: standardWidth,
      renderHeader: () => (
        <>
          休日
          <br />
          時間
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalHolidaySumKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });
    if (printedItemVisibility.midnight) {
      result.push({
        id: "深夜時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            深夜
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }
    if (printedItemVisibility.allNightOff) {
      result.push({
        id: "徹休時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            徹休
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalAllNightOffKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }
    if (printedItemVisibility.sp4Items) {
      result.push(
        {
          id: "特勤普通",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelTokukin />
              平日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSpWeekdayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "特勤平日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelTokukin />
              平日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSpWeekdayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "特勤休日",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelTokukin />
              休日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSpHolidayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "特勤休日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelTokukin />
              休日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalSpHolidayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
      );
    }
    if (printedItemVisibility.liquidate) {
      result.push({
        id: "清算時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            清算
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLiquidateSumKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      });
    }

    if (printedItemVisibility.over4Items) {
      result.push(
        {
          id: "残業深夜普通",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelZangyou />
              平日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverWeekdayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "残業深夜平日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelZangyou />
              平日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverWeekdayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "残業深夜休日",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelZangyou />
              休日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverHolidayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "残業深夜休日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelZangyou />
              休日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverHolidayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
      );
    }

    if (printedItemVisibility.overSixty4Items) {
      result.push(
        {
          id: "60H超時間外平日",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              平日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyWeekdayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "60H超時間外平日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              平日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyWeekdayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "60H超時間外休日",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              休日
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyHolidayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "60H超時間外休日深夜",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              休日
              <br />
              深夜
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyHolidayMidnightKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
      );
    }

    if (printedItemVisibility.overSixtyFlexLiquidates) {
      result.push(
        {
          id: "60H超休日時間",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              休日
              <br />
              時間
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyHolidayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "60H超清算時間",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelOver60 />
              清算
              <br />
              時間
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalOverSixtyLiquidateKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
      );
    }

    result.push({
      id: "時間外時間数",
      width: standardWidth,
      renderHeader: () => (
        <>
          時間外
          <br />
          時間数
        </>
      ),
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <MTotalOverKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
      },
    });

    result.push(
      {
        id: "法定基準時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            法定
            <br />
            基準
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLawBasisKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "健康管理時間",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelLawOver />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLawOverTodayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "健康管理時間（今日迄）",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelLawOver />
            今日迄
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLawOverTodayKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "健康管理時間（9日迄）",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelLawOver />
            9日迄
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLawOverNineKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "健康管理時間（当月予測）",
        width: standardWidth,
        renderHeader: () => (
          <>
            <HeaderLabelLawOver />
            当月予測
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MTotalLawOverCalcKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
    );

    if (printedItemVisibility.workplace) {
      result.push(
        {
          id: "勤務場所日数（在宅）",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelWorkplaceTotal />
              在宅勤務
              <br />
              日数
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalWorkplaceZaitakuDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "勤務場所日数（出社）",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelWorkplaceTotal />
              通常出社
              <br />
              先日数
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalWorkplaceSyussyaDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
        {
          id: "勤務場所日数（その他）",
          width: standardWidth,
          renderHeader: () => (
            <>
              <HeaderLabelWorkplaceTotal />
              その他
              <br />
              日数
            </>
          ),
          renderBody: ({ rowIndex }) => {
            const row = rows[rowIndex - 1];
            return <MTotalWorkplaceSonotaDaysKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
          },
        },
      );
    }

    return result;
  }
}
