import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalRestRefreshDaysKFProps = {
  model: MTotalRestRefreshDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalRestRefreshDaysKFVM {
  mTotalRestRefreshDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalRestRefreshDaysKF = observer((props: MTotalRestRefreshDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalRestRefreshDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalRestRefreshDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
