import React from "react";

export interface MSetAllDaysDoorEnterVM {
  onSetAllDaysDoorEnter: () => void;
}

export const MSetAllDaysDoorEnter: React.FC<{
  model: MSetAllDaysDoorEnterVM;
}> = ({ model }) => {
  return <button onClick={model.onSetAllDaysDoorEnter}>一括</button>;
};
