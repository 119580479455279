import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../common/form/CellFormAdaptable";
import { CheckboxField } from "../../../common/form/CheckboxField";
import { KintaiBulkAuthCheckboxesVM } from "./KintaiBulkAuthCheckboxesVM";

export type KintaiBulkAuthCheckboxProps = {
  type: "approverBA" | "clerkBA" | "hrmBA";
  model: KintaiBulkAuthCheckboxesVM;
} & CellFormAdaptable;

export interface KintaiBulkAuthCheckboxVM {
  disabled: boolean;
  checked: boolean;
  hasNoChange: boolean;
  onChange: (value: boolean) => void;
}

@observer
export class KintaiBulkAuthCheckbox extends React.Component<KintaiBulkAuthCheckboxProps> {
  public render() {
    const model = this.props.model[this.props.type];
    return (
      <CheckboxField
        {...this.props}
        disabled={model.disabled}
        onChange={model.onChange}
        value={model.checked}
        hasNoChange={model.hasNoChange}
      />
    );
  }
}
