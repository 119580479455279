import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverSixtyHolidayKFProps = {
  model: MTotalOverSixtyHolidayKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverSixtyHolidayKFVM {
  mTotalOverSixtyHoliday: KintaiInputItemState<Minutes>;
}

export const MTotalOverSixtyHolidayKF = observer((props: MTotalOverSixtyHolidayKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverSixtyHoliday;

  return <HHMMField {...props} label="mTotalOverSixtyHolidayKF" {...itemState} onChange={undefined} />;
});
