import {
  MeControllerApi,
  PhysicianInterviewEditEntity,
  PhysicianInterviewReserveRequest,
  PhysicianInterviewsControllerApi,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { PhysicianInterviewApi } from "./PhysicianInterviewApi";

export class PhysicianInterviewApiImpl extends PhysicianInterviewApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getPhysicianInterviews(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(PhysicianInterviewsControllerApi).getPhysicianInterviews(nendo),
    );
  }

  public updatePhysicianInterviewStatus(userId: string, yearMonth: Date, payload: PhysicianInterviewEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(PhysicianInterviewsControllerApi)
        .updatePhysicianInterview(userId, yearMonth.getFullYear(), yearMonth.getMonth() + 1, payload),
    );
  }

  public createPhysicianInterviewStatus(userId: string, yearMonth: Date, payload: PhysicianInterviewEditEntity) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(PhysicianInterviewsControllerApi)
        .createPhysicianInterview(userId, yearMonth.getFullYear(), yearMonth.getMonth() + 1, payload),
    );
  }

  public getPhysicianInterviewsForMe(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).getUserPhysicianInterviews(nendo),
    );
  }

  public reservePhysicianInterviewsForMe(month: Date, payload: PhysicianInterviewReserveRequest) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(MeControllerApi)
        .reserveUserPhysicianInterview(month.getFullYear(), month.getMonth() + 1, payload),
    );
  }
}
