import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { AppConfirmQueue, AppConfirmQueueSymbol } from "../../../models/applications/AppConfirmQueue";
import { ApplicationConfirmVM } from "./ApplicationConfirm";

export class ApplicationConfirmVMImpl implements ApplicationConfirmVM {
  @lazyInject(AppConfirmQueueSymbol)
  private appConfirmQueue: typeof AppConfirmQueue.Type;

  @computed
  private get lastMessage() {
    return this.appConfirmQueue.lastMessage;
  }

  @computed
  public get isOpen() {
    return !!this.lastMessage;
  }

  @computed
  public get title() {
    if (!this.lastMessage) {
      return "";
    }

    return this.lastMessage.title;
  }

  @computed
  public get message() {
    if (!this.lastMessage) {
      return "";
    }

    return this.lastMessage.message;
  }

  @computed
  public get yesButtonLabel() {
    return (this.lastMessage && this.lastMessage.yesButtonLabel) || "OK";
  }

  @computed
  public get noButtonLabel() {
    return (this.lastMessage && this.lastMessage.noButtonLabel) || "キャンセル";
  }

  @action.bound
  public onCancel() {
    this.appConfirmQueue.provideAnswer(false);
  }

  @action.bound
  public onOk() {
    this.appConfirmQueue.provideAnswer(true);
  }
}
