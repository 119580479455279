import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { ProfileSettingsPageModel, ProfileSettingsPageModelSymbol } from "../../../models/profile/ProfileSettingsPageModel";
import { SettingsPageRibbonVM } from "./ribbon/SettingsPageRibbon";
import { SettingsPageVM } from "./SettingsPage";

export class SettingsPageVMImpl implements SettingsPageVM, SettingsPageRibbonVM {
  @lazyInject(ProfileSettingsPageModelSymbol)
  private profileSettingsPage: typeof ProfileSettingsPageModel.Type;

  public get ribbonModel() {
    return this;
  }

  @computed
  get userId() {
    return this.profileSettingsPage.userId;
  }

  @computed
  get userName() {
    return this.profileSettingsPage.userName;
  }

  @computed
  get mailAddress() {
    return this.profileSettingsPage.mailAddress;
  }

  @computed
  get rankName() {
    return this.profileSettingsPage.rankName;
  }

  @computed
  get depCode() {
    return this.profileSettingsPage.depCode;
  }

  @computed
  get depName() {
    return this.profileSettingsPage.depName;
  }

  @computed
  get roleNames() {
    return this.profileSettingsPage.roleNames;
  }

  @action.bound
  public onOpeningPasswordChangeDialog() {
    this.profileSettingsPage.openPasswordDialog();
  }
}
