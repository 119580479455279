import { flatMap, sortBy } from "lodash-es";
import { getEnv, IModelType, types } from "mobx-state-tree";

import { dateOf } from "../../utils/date";
import { ProfileSymbol, ProfileType } from "../profile/Profile";
import { KintaiUser } from "./KintaiUser";
import { MonthlyKintai } from "./MonthlyKintai";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

/**
 * 勤怠表のルートモデル
 */
const model = types.optional(
  types
    .model("KintaiUsers", {
      users: types.map(KintaiUser),
    })
    .views(self => {
      return {
        ofUserId(userId: string) {
          return self.users.get(userId);
        },
        get allKintai() {
          return flatMap([...self.users.values()], u => [...u.months.values()]);
        },
      };
    })
    .actions(self => {
      const profile = () => getEnv(self).get(ProfileSymbol) as ProfileType;

      return {
        loadThisMonthKintaiOfMine() {
          const myUserId = profile().userId;
          const now = new Date();
          const thisMonth = dateOf(now.getFullYear(), now.getMonth() + 1);
          const kintai = this.open(myUserId, thisMonth, true);
          kintai.loadIfNeeded();
          return kintai;
        },

        prepare(userId: string) {
          return (
            self.users.get(userId) ||
            (() => {
              const created = KintaiUser.create({
                userId,
              });
              self.users.put(created);
              return created;
            })()
          );
        },
        open(userId: string, targetMonth: Date, retainAlways = false) {
          const opened = this.prepare(userId).prepare(targetMonth);
          if (retainAlways) {
            opened.setRetainAlways(true);
          }
          this.releaseOldCache();
          return opened;
        },
        releaseOldCache() {
          const tobeReleased = sortBy(
            self.allKintai.filter(it => it.canBeReleased),
            l => -l.lastLoadTime!.getTime(),
          ).filter((it, idx) => idx > 2);

          console.log(`Released: ${tobeReleased.length} / All: ${self.allKintai.length} `);
          tobeReleased.forEach(it => this.release(it));
        },
        release(kintai: typeof MonthlyKintai.Type) {
          console.log(`Release Kintai: ${kintai.userId} / ${kintai.month}`);
          kintai.release();
        },
      };
    }),
  {},
);

export type KintaiUsersType = typeof KintaiUsers.Type;

export const KintaiUsersSymbol = "KintaiUsers_Symbol";
export const KintaiUsers: KintaiUsersModelType = model;
type KintaiUsersInferredType = typeof model;
export interface KintaiUsersModelType extends KintaiUsersInferredType {}
