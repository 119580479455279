import { computed } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { GridTable, x } from "../../../common/table/GridTable";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { TableColumnDef } from "../../../common/table/TableColumnDef";
import { PjCloseCell } from "./PjCloseCell";

export interface PjClosePageGridVM {
  months: PjClosePageGridMonthVM[];
}

export interface PjClosePageGridMonthVM {
  month: Date;
  hasNoChange: boolean;
  disabled: boolean;
  closed: boolean;
  onChangeClosed: (value: boolean) => void;
}

@observer
export class PjClosePageGrid extends React.Component<{ model: PjClosePageGridVM }> {
  public render() {
    const { model } = this.props;
    return (
      <GridTable
        hideCount={true}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        activeCell={NULL_CELL_POSITION}
        renderFooter={false}
        rowCount={12}
      />
    );
  }

  @computed
  private get headerColumns() {
    const { model } = this.props;
    const { months } = model;
    return [
      {
        id: "月",
        width: x("XX XX"),
        renderHeader: () => "月",
        renderBody: ({ rowIndex }) => {
          const row = months[rowIndex - 1];
          return (
            <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
              {moment(row.month).format("MM 月")}
            </LabelField>
          );
        },
      },
    ] as TableColumnDef[];
  }

  @computed
  private get bodyColumns() {
    const { model } = this.props;
    const { months } = model;
    return [
      {
        id: "pjClosed",
        width: x("xxxxxxxxxxxxxx"),
        renderHeader: () => <>月報の締め状態</>,
        renderBody: ({ rowIndex }) => {
          const row = months[rowIndex - 1];
          return <PjCloseCell model={row} />;
        },
      },
    ] as TableColumnDef[];
  }
}
