import { wkRoleAbilities } from "@webkintai/privileges";
import { IModelType, types } from "mobx-state-tree";

import { Role } from "./Role";

// このファイルは自動生成されたものです。手で編集しないでください。
// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("Roles", {
      roles: types.optional(types.map(Role), wkRoleAbilities),
    })
    .views(self => {
      return {
        of(roleCode: string) {
          return self.roles.get(roleCode);
        },
      };
    }),
  {},
);

export type RolesType = typeof Roles.Type;

export const RolesSymbol = "Roles_Symbol";
export const Roles: RolesModelType = model;
type RolesInferredType = typeof model;
export interface RolesModelType extends RolesInferredType {}
