import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { NendoFatigueCheckListEntry } from "../../../../models/interview_list/NendoFatigueCheckListEntry";
import { NendoInterviewListEntry } from "../../../../models/interview_list/NendoInterviewListEntry";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { InterviewListPageVMImpl } from "../InterviewListPageVMImpl";
import { InterviewListPageTableRowVM } from "./InterviewListPageTable";

export class InterviewListPageTableRowVMImpl implements InterviewListPageTableRowVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  constructor(
    private readonly pageModel: InterviewListPageVMImpl,
    private readonly ivModel: typeof NendoInterviewListEntry.Type | undefined,
    private readonly ftgModel: typeof NendoFatigueCheckListEntry.Type | undefined,
  ) {}

  get commonModel() {
    if (this.ivModel) {
      return this.ivModel;
    }
    if (this.ftgModel) {
      return this.ftgModel;
    }
    throw new Error("both null");
  }

  @computed
  get id() {
    return `${this.commonModel.id}`;
  }

  @action.bound
  public onNavigateToKintai() {
    this.appRouter.navigate(
      paths.kintai
        .ofUser(this.commonModel.userId)
        .ofDate(this.commonModel.targetMonth)
        .index(),
    );
  }

  @action.bound
  public onAddNewInterview() {
    if (!this.ftgModel) {
      return;
    }

    this.ftgModel.addNewInterview();
  }

  @computed
  get editNotAllowed(): boolean {
    return !this.pageModel.editAllowed;
  }

  @computed
  get deptName(): string {
    return this.commonModel.depName;
  }

  @computed
  get rankName(): string {
    return this.commonModel.rankName;
  }

  @computed
  get userId(): string {
    return this.commonModel.userId;
  }

  @computed
  get userName(): string {
    return this.commonModel.userName;
  }

  @computed
  get targetMonth(): Date {
    return this.commonModel.targetMonth;
  }

  @computed
  get lawOver() {
    return this.commonModel.lawOver;
  }

  @computed
  get sundayOnCount() {
    return this.commonModel.sundayOnCount;
  }

  @computed
  get fatigue() {
    const { ftgModel } = this;
    if (!ftgModel) {
      return undefined;
    }

    return {
      hasNoChange: ftgModel.hasNoChange,

      acceptStatus: ftgModel.acceptStatus,
      onChangeAcceptStatus: ftgModel.setAcceptStatus,
      acceptStatusDisabled: this.editNotAllowed,

      submitDate: ftgModel.submitDate,
      onChangeSubmitDate: ftgModel.setSubmitDate,
      submitDateDisabled: this.editNotAllowed,

      note: ftgModel.note,
      onChangeNote: ftgModel.setNote,
      noteDisabled: this.editNotAllowed,

      errors: [],
    };
  }

  @computed
  get interview() {
    const { ivModel } = this;

    if (!ivModel) {
      return undefined;
    }

    return {
      hasNoChange: ivModel.hasNoChange,

      receptionStatus: ivModel.receptionStatus,
      onChangeReceptionStatus: ivModel.setReceptionStatus,
      receptionStatusDisabled: this.editNotAllowed,

      reservedDate: ivModel.reservedDate,
      onChangeReservedDate: ivModel.setReservedDate,
      reservedDateDisabled: this.editNotAllowed || ivModel.reservedDateDisabled,
      reservedDateErrors: ivModel.reservedDateErrors,

      actualDate: ivModel.actualDate,
      onChangeActualDate: ivModel.setActualDate,
      actualDateDisabled: this.editNotAllowed || ivModel.actualDateDisabled,
      actualDateErrors: ivModel.actualDateErrors,

      note: ivModel.note,
      onChangeNote: ivModel.setNote,
      noteDisabled: this.editNotAllowed,
    };
  }
}
