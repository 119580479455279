import { IModelType, types } from "mobx-state-tree";

import { dateEq } from "../../utils/date";
import { hasNoChangeReduce } from "../../utils/model";
import { typesMinutes } from "../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("MonthlyFatigueCheckList", {
    month: types.Date,
    lawOver: types.maybe(typesMinutes),
    sundayOnCount: types.maybe(types.number),

    submitDate: types.maybe(types.Date),
    org_submitDate: types.maybe(types.Date),
  })
  .views(self => {
    return {
      get hasNoChange() {
        if (self.submitDate === undefined) {
          return true;
        }
        return hasNoChangeReduce([dateEq(self.submitDate, self.org_submitDate)]);
      },
      get needsAnswer() {
        return self.submitDate === undefined;
      },
    };
  })
  .actions(self => {
    return {
      setSubmitDate(value?: Date) {
        self.submitDate = value;
      },
      reset() {
        self.submitDate = self.org_submitDate;
      },
    };
  });

export const NendoInterviewPlanSymbol = "NendoInterviewPlan_Symbol";
export const MonthlyFatigueCheckList: MonthlyFatigueCheckListModelType = model;
type MonthlyFatigueCheckListInferredType = typeof model;
export interface MonthlyFatigueCheckListModelType extends MonthlyFatigueCheckListInferredType {}
