import { MainKintaiType } from "../../../../models/kintai/MainKintaiType";
import { compareMonth, dateOf } from "../../../../utils/date";

/**
 * 印刷表示画面の表示条件
 */
export class RegularPrintedItemsVisibility {
  constructor(private readonly month: Date, private readonly kintaiType: typeof MainKintaiType.Type) {}

  private get isFlex() {
    return this.kintaiType === "Flex";
  }

  private get isNonFlex() {
    return this.kintaiType === "Non-Flex";
  }

  public get midnightCount() {
    return this.isFlex;
  }

  public get allnightOffDays() {
    return this.isFlex;
  }

  public get midnight() {
    return this.isFlex;
  }

  public get allNightOff() {
    return this.isFlex;
  }

  public get sp4Items() {
    return this.isFlex;
  }

  public get liquidate() {
    return this.isFlex;
  }

  public get over4Items() {
    return this.isNonFlex;
  }

  public get overSixty4Items() {
    return this.isNonFlex;
  }

  public get overSixtyFlexLiquidates() {
    return this.isFlex;
  }

  public get workplace() {
    return compareMonth(this.month, workplaceEnabledFrom, (l, r) => l.getTime() >= r.getTime());
  }
}

const workplaceEnabledFrom = dateOf(2021, 10, 1);
