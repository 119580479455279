import React from "react";
import { useWindowSize } from "react-hooks-window-size";

import { RESPONSIVE_LARGE_SCREEN_WIDTH, RESPONSIVE_MIDDLE_SCREEN_WIDTH } from "../../../utils/responsive";

export interface ResponsiveSpecification {
  large: boolean;
  middle: boolean;
  small: boolean;
}

export const Responsive: React.FunctionComponent<{
  children: (size: ResponsiveSpecification) => React.ReactNode;
}> = props => {
  const size = useResponsive();

  return <>{props.children(size)}</>;
};

export const useResponsive = () => {
  const { width } = useWindowSize();

  if (width >= RESPONSIVE_LARGE_SCREEN_WIDTH) {
    return { large: true, middle: false, small: false };
  }
  if (width >= RESPONSIVE_MIDDLE_SCREEN_WIDTH) {
    return { large: false, middle: true, small: false };
  }
  return { large: false, middle: false, small: true };
};

export const mediaQueryLarge = `@media (min-width: ${RESPONSIVE_LARGE_SCREEN_WIDTH}px)`;

export const mediaQueryNotLarge = `@media (max-width: ${RESPONSIVE_LARGE_SCREEN_WIDTH - 1}px)`;

export const mediaQueryMiddle = `@media (min-width: ${RESPONSIVE_MIDDLE_SCREEN_WIDTH}px, max-width: ${RESPONSIVE_LARGE_SCREEN_WIDTH -
  1}px)`;
export const mediaQueryNotSmall = `@media (min-width: ${RESPONSIVE_MIDDLE_SCREEN_WIDTH}px)`;

export const mediaQuerySmall = `@media (max-width: ${RESPONSIVE_MIDDLE_SCREEN_WIDTH - 1}px)`;
