import { observer } from "mobx-react";
import React from "react";

import { TextField } from "../../../common/form/TextField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RankRowVM } from "./RankPageGrid";

@observer
export class RKRankNameCell extends React.Component<{ model: RankRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <TextField
        mode="cell"
        disabled={row.disabled}
        value={row.rankName}
        onChange={row.onChangeRankName}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
