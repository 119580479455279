import { RankPosition } from "../../../../models/common/RankPosition";
import { MainKintaiType } from "../../../../models/kintai/MainKintaiType";
import { getNendo } from "../../../../utils/calendar";
import { RegularPrintedItemsVisibility } from "./RegularPrintedItemsVisibility";

/**
 * 日々入力画面の表示条件
 */
export class RegularDailyItemsVisibility {
  constructor(
    private readonly month: Date,
    private readonly kintaiType: typeof MainKintaiType.Type,
    private readonly rankPosition: typeof RankPosition.Type,
  ) {}

  private get nendo() {
    return getNendo(this.month);
  }

  private get isManager() {
    return this.rankPosition === "1";
  }

  public get transDay() {
    return !this.isManager;
  }

  public get flex() {
    return this.kintaiType === "Flex" && !this.isManager;
  }

  public get holiday() {
    return this.kintaiType === "Flex" && !this.isManager;
  }

  public get midnight() {
    return this.kintaiType === "Flex";
  }

  public get allNightOff() {
    return this.kintaiType === "Flex" && !this.isManager;
  }

  public get spWeekdayHolidayItems() {
    return this.kintaiType === "Flex" && !this.isManager;
  }

  public get growingSum() {
    return !this.isManager;
  }

  public get off() {
    return this.kintaiType === "Non-Flex" || !this.isManager;
  }

  public get overWeekdayHolidayItems() {
    return this.kintaiType === "Non-Flex";
  }

  public get overHoliday() {
    return this.overWeekdayHolidayItems && !this.isManager;
  }

  public get overHolidayMidnight() {
    return this.overWeekdayHolidayItems;
  }
  public get overWeekday() {
    return this.overWeekdayHolidayItems && !this.isManager;
  }

  public get overWeekdayMidnight() {
    return this.overWeekdayHolidayItems;
  }

  public get unusedRestTimes() {
    return this.nendo >= 2020 && this.kintaiType === "Flex" && !this.isManager;
  }

  public get workplace() {
    return new RegularPrintedItemsVisibility(this.month, this.kintaiType).workplace;
  }
}
