import { KintaiReportsResult, UserMonthlyReportEntity, UsersResult } from "@webkintai/api";
import { encode } from "iconv-lite";
import { flatMap, uniq } from "lodash-es";
import { applySnapshot, flow, getEnv, SnapshotIn, types } from "mobx-state-tree";
import moment from "moment";

import { stringListToCsv } from "../../components/common/export-table/HtmlToCsv";
import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { DeptsApi } from "../../services/api/DeptsApi";
import { KintaiApi, kintaiApiTimestampTypesToText } from "../../services/api/KintaiApi";
import { AppRouter } from "../../services/AppRouter";
import { FileSaver } from "../../services/file/FileSaver";
import { AppConfirm } from "../../services/msg/AppConfirm";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { MyPrivileges } from "../../services/prv/MyPrivileges";
import { TimeProvider } from "../../services/TimeProvider";
import { dateOf, getNendoYear } from "../../utils/date";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDepts } from "../depts/NendoDepts";
import {
  collectMonthlyReportAllErrWarnInfos,
  collectMonthlyReportAllKintaiApprovalErrors,
} from "../kintai/api/UserMonthlyReport";
import { createKintaiMonthlyAttributesValuesFromApiValues } from "../kintai/attr/KintaiMonthlyAttributesValues";
import { KintaiReportPageModel } from "../kintai/KintaiReportPageModel";
import { KintaiUsersSymbol, KintaiUsersType } from "../kintai/KintaiUsers";
import { apiValueToTimestamps } from "../kintai/timestamps/KintaiTimestamps";
import { ProfileSymbol, ProfileType } from "../profile/Profile";
import { UsersSymbol, UsersType } from "../users/Users";
import { DeptKintaiCloseAvailableOps } from "./DeptKintaiCloseAvailableOps";
import { DeptKintaiCloseEntry } from "./DeptKintaiCloseEntry";
import { KintaiListDisplayedRowsType } from "./KintaiListDisplayedRowsType";
import { KintaiListEntry, KintaiListEntryType, StampIfAnyResult } from "./KintaiListEntry";
import { KintaiListPageModelMode } from "./KintaiListPageModelMode";

const model = types.optional(
  types
    .model("KintaiListPageModel", {
      /** 検索・表示モード */
      mode: types.optional(KintaiListPageModelMode, "dept"),
      /** 最終検索条件 */
      lastSearchCondition: types.optional(types.string, ""),

      /** 対象年度 */
      nendoDepts: types.maybe(types.reference(NendoDepts)),
      /** 対象年月 */
      targetMonth: types.optional(types.Date, new Date()),

      /** 対象部門コード */
      targetDepCode: types.maybeNull(types.string),
      /** 部門マスタロード状況 */
      deptsLoadingState: types.optional(LoadingStatus, "init"),

      /** フィルタ */
      filterText: types.optional(types.string, ""),
      filterReviewerAssignedToMe: types.optional(types.boolean, false),
      filterApproverAssignedToMe: types.optional(types.boolean, false),
      filterApprovalOnYou: types.optional(types.boolean, false),
      filterPrivilegedApproval: types.optional(types.boolean, false),

      /** 検索結果 */
      searchResult: types.array(KintaiListEntry),
      searchResultLoadingState: types.optional(LoadingStatus, "init"),
      lastLoadTime: types.maybe(types.Date),

      /* 一括承認 */
      bulkAuthMode: types.optional(types.boolean, false),
      firstTimeBulkAuthOn: types.optional(types.boolean, true),

      /** 勤怠の締め */
      deptCloses: types.map(DeptKintaiCloseEntry),
      currentDeptCloseStatus: types.maybe(types.reference(DeptKintaiCloseEntry)),

      /** 編集モード時内容 */
      activeRow: KintaiReportPageModel,

      /** 画面設定 */
      gridFrameSize: types.optional(types.number, 150),
      displayedRowsType: types.optional(KintaiListDisplayedRowsType, "Flex"),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      const myPrivileges = (): MyPrivileges => getEnv(self).get(MyPrivileges);
      const profile = (): ProfileType => getEnv(self).get(ProfileSymbol);

      const applyFilter = (source: KintaiListEntryType[]) => {
        const myId = profile().userId;

        return source
          .filter(it => it.filterMatched(self.filterText.split(/\s+/g)))
          .filter(it => {
            if (self.filterReviewerAssignedToMe && it.reviewerId !== myId) {
              return false;
            }
            if (self.filterApproverAssignedToMe && it.approverId !== myId) {
              return false;
            }

            return true;
          })
          .filter(it => {
            return self.filterApprovalOnYou === false || it.requireApprovalOnYou;
          })
          .filter(it => {
            return self.filterPrivilegedApproval === false || it.requireApprovalPrivileged;
          });
      };

      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get targetDept() {
          if (!self.targetDepCode) {
            return null;
          }
          if (!self.nendoDepts) {
            return null;
          }
          if (self.nendoDepts.loadingStatus !== "loaded") {
            return null;
          }
          return self.nendoDepts.getDept(self.targetDepCode) || null;
        },
        get currentFilteredRows() {
          switch (self.displayedRowsType) {
            case "Flex":
              return this.filteredFlexRows;
            case "Non-Flex":
              return this.nonFlexRows;
            case "S-Port":
              return this.sPortRows;
            case "NoReg":
              return this.noRegistrationRows;
            default:
              return [];
          }
        },
        get flexRows() {
          return self.searchResult.filter(it => it.isFlex);
        },
        get filteredFlexRows() {
          return applyFilter(this.flexRows);
        },
        get nonFlexRows() {
          return self.searchResult.filter(it => it.isNonFlex);
        },
        get filteredNonFlexRows() {
          return applyFilter(this.nonFlexRows);
        },
        get sPortRows() {
          return self.searchResult.filter(it => it.isSPort);
        },
        get filteredSPortRows() {
          return applyFilter(this.sPortRows);
        },
        get noRegistrationRows() {
          return self.searchResult.filter(it => it.isNotRegistered);
        },
        get filteredNoRegistrationRows() {
          return applyFilter(this.noRegistrationRows);
        },
        get showActiveRow() {
          return self.activeRow.userKintai !== undefined;
        },

        get currentSearchCondition() {
          return self.mode + " " + self.targetDepCode + " " + self.targetMonth.toDateString();
        },
        get eligibleToShowCloseDept() {
          return self.mode === "dept" && !!self.targetDepCode && myPrivileges().has("DISP_CLOSE_DPT_KINTAI");
        },
        get showCloseDept() {
          return (
            this.eligibleToShowCloseDept &&
            !!this.targetDept &&
            !!self.currentDeptCloseStatus &&
            self.currentDeptCloseStatus.loadingState === "loaded"
          );
        },
        get isDeptKintaiClosed() {
          if (!this.showCloseDept || !self.currentDeptCloseStatus) {
            return false;
          }

          return self.currentDeptCloseStatus.isClosedForMonth(self.targetMonth);
        },
        get deptKintaiCloseAvailableOp(): DeptKintaiCloseAvailableOps {
          if (!this.showCloseDept || !self.currentDeptCloseStatus) {
            return "no_touch";
          }
          return self.currentDeptCloseStatus.getAvailableOpForMonth(self.targetMonth);
        },
        get closedMonth(): Date | undefined {
          if (!self.currentDeptCloseStatus) {
            return undefined;
          }
          return self.currentDeptCloseStatus.closedMonth;
        },
        get approversBAList() {
          return self.searchResult.filter(it => it.approverBAStamped);
        },
        get clerkBAList() {
          return self.searchResult.filter(it => it.clerkBAStamped);
        },
        get hrmBAList() {
          return self.searchResult.filter(it => it.hrmBAStamped);
        },
        get bulkAuthChangeCount() {
          return self.searchResult.filter(it => !it.anyBAStampHasNoChange).length;
        },
        get saveBulkAuthDisabled() {
          return (
            self.bulkAuthMode === false ||
            (self.searchResultLoadingState === "loaded" && this.bulkAuthChangeCount === 0)
          );
        },
        get resetBulkAuthDisabled() {
          return this.saveBulkAuthDisabled;
        },
      };
    })
    .actions(self => {
      const depts = (): DeptsType => getEnv(self).get(DeptsSymbol);
      const appRouter = (): AppRouter => getEnv(self).get(AppRouter);
      const deptsApi = (): DeptsApi => getEnv(self).get(DeptsApi);
      const kintaiApi = (): KintaiApi => getEnv(self).get(KintaiApi);
      const profile = (): ProfileType => getEnv(self).get(ProfileSymbol);
      const users = (): UsersType => getEnv(self).get(UsersSymbol);
      const kintaiUsers = (): KintaiUsersType => getEnv(self).get(KintaiUsersSymbol);
      const appNotifier = (): AppNotifier => getEnv(self).get(AppNotifier);
      const appConfirm = (): AppConfirm => getEnv(self).get(AppConfirm);
      const timeProvider = (): TimeProvider => getEnv(self).get(TimeProvider);
      const fileSaver = () => getDI(self, FileSaver);

      const prepareDeptKintaiClose = (depCode: string) => {
        return (
          self.deptCloses.get(depCode) ||
          (() => {
            const created = DeptKintaiCloseEntry.create({ depCode });
            self.deptCloses.put(created);
            return created;
          })()
        );
      };

      const triggerSearch = flow(function*(forceReload: boolean = false) {
        const { mode, targetDepCode, targetMonth } = self;
        if (mode === "dept" && !self.targetDepCode) {
          return;
        }
        if (!forceReload && self.lastSearchCondition === self.currentSearchCondition) {
          return;
        }
        try {
          self.lastSearchCondition = self.currentSearchCondition;
          self.searchResultLoadingState = "loading";

          const searchResult: Array<SnapshotIn<typeof KintaiListEntry>> = [];
          const addToSearchResult = (userMonthlyReport: UserMonthlyReportEntity) => {
            const { attributes, timestamps, regularReport, sportReport } = userMonthlyReport;

            const allSpecifiedTypes = uniq([
              ...flatMap(regularReport.days, d => d.types),
              ...flatMap(sportReport.days, d => d.types),
            ]);

            searchResult.push({
              month: targetMonth,
              userId: userMonthlyReport.user.userId,

              userName: userMonthlyReport.user.userName || "",
              depName: userMonthlyReport.attributes.depName || "",
              rankName: userMonthlyReport.attributes.rankName || "",

              attrs: attributes ? createKintaiMonthlyAttributesValuesFromApiValues(users(), attributes) : undefined,
              timestamps: timestamps ? apiValueToTimestamps(users(), timestamps) : undefined,

              errors: collectMonthlyReportAllErrWarnInfos("ERROR", userMonthlyReport),
              warnings: collectMonthlyReportAllErrWarnInfos("WARN", userMonthlyReport),
              infos: collectMonthlyReportAllErrWarnInfos("INFO", userMonthlyReport),

              notAppliedErrors: collectMonthlyReportAllKintaiApprovalErrors(userMonthlyReport, "not_applied"),
              notApprovedErrors: collectMonthlyReportAllKintaiApprovalErrors(userMonthlyReport, "not_approved"),
              priviledgedNotApprovedErrors: collectMonthlyReportAllKintaiApprovalErrors(
                userMonthlyReport,
                "privileged_not_approved",
              ),
              rejectedErrors: collectMonthlyReportAllKintaiApprovalErrors(userMonthlyReport, "rejected"),

              typesSearchIndex: allSpecifiedTypes.map(it => it.value),
              regularTotal: regularReport ? regularReport.total : undefined,
              sportTotal: sportReport ? sportReport.total : undefined,
            });
          };

          if (mode === "dept") {
            if (!targetDepCode) {
              return;
            }
            self.currentDeptCloseStatus = prepareDeptKintaiClose(targetDepCode);
            if (self.eligibleToShowCloseDept) {
              self.currentDeptCloseStatus.load();
            }

            // 部門内検索
            (yield deptsApi().getKintaiReports(
              targetDepCode,
              targetMonth.getFullYear(),
              targetMonth.getMonth() + 1,
            )).monthlyReports.forEach(addToSearchResult);

            // 未入力の一覧も取得.
            const noEntryUsers: UsersResult = yield kintaiApi().searchNoEntryUsers(targetMonth, targetDepCode);

            noEntryUsers.users.map(user => {
              searchResult.push({
                month: targetMonth,
                userId: user.userId,

                userName: user.userName || "",
                depName: "",
                rankName: "",
              });
            });
          }
          const processByUsers = async (targetUsers: UsersResult) => {
            if (targetUsers.users.length > 0) {
              const kintaiReports: KintaiReportsResult = await kintaiApi().getKintaiReportsByUserIds(
                self.targetMonth.getFullYear(),
                self.targetMonth.getMonth() + 1,
                targetUsers.users.map(user => user.userId),
              );

              kintaiReports.monthlyReports.forEach(addToSearchResult);
            }
          };
          if (mode === "reviewee") {
            // 査閲者検索
            const targetUsers: UsersResult = yield kintaiApi().searchReviewerSpecified(
              self.targetMonth,
              profile().userId,
            );
            yield processByUsers(targetUsers);
          }
          if (mode === "privileged") {
            // 総務人事部勤怠区分持ち検索
            const targetUsers: UsersResult = yield kintaiApi().searchPrivilegedKintaiBunruiSpecified(self.targetMonth);
            yield processByUsers(targetUsers);
          }

          applySnapshot(self.searchResult, searchResult);
          self.searchResult.forEach(s => s.resetBAStamped());
          self.lastLoadTime = timeProvider().currentTime;
          self.searchResultLoadingState = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "勤怠一覧のロードに失敗しました。", exception });
          self.searchResultLoadingState = "failed";
        }
      });

      const triggerLoadDepts = flow(function*() {
        try {
          self.deptsLoadingState = "loading";
          self.nendoDepts = yield depts().prepareAndLoadMostRecent(getNendoYear(self.targetMonth));
          self.deptsLoadingState = "loaded";
        } catch (exception) {
          self.deptsLoadingState = "failed";
          appNotifier().error({ message: "部門のロードに失敗しました", exception });
        }
      });

      const closeDept = flow(function*() {
        if (self.currentDeptCloseStatus) {
          yield self.currentDeptCloseStatus.closeDept(self.targetMonth);
        }
      });

      const reopenDept = flow(function*() {
        if (self.currentDeptCloseStatus) {
          yield self.currentDeptCloseStatus.reopenDept(self.targetMonth);
        }
      });

      const saveBulkAuth = flow(function*() {
        if (
          !(yield appConfirm().confirm({
            title: "承認の確認",
            message: `${self.bulkAuthChangeCount} 件の勤怠表について、承認印の変更をすべて保存しますか？`,
          }))
        ) {
          return;
        }

        self.searchResultLoadingState = "loading";
        appNotifier().info({ message: "承認中です。しばらく時間がかかります。" });
        const results: StampIfAnyResult[] = [];
        try {
          for (const target of self.searchResult.filter(it => !it.anyBAStampHasNoChange)) {
            const result = yield target.saveBAStamps();
            results.push(...result);
          }

          fileSaver().saveFile(
            `一括承認結果_${timeProvider().currentTime.getTime()}.csv`,
            encode(stampIfAnyResultsToCsvContent(results), "Shift_JIS"),
          );

          const errorCount = results.filter(it => !it.success).length;
          if (errorCount > 0) {
            appNotifier().error({
              message: `一括承認対象のうち ${errorCount} 件でエラーが起きました。ファイルを確認してください。`,
            });
          } else {
            appNotifier().info({ message: `一括承認が完了しました。` });
          }
        } finally {
          triggerSearch(true);
        }
      });

      const resetBulkAuth = flow(function*() {
        if (
          !(yield appConfirm().confirm({ title: "リセット確認", message: "承認対象のマークをすべて解除しますか？" }))
        ) {
          return;
        }
        self.searchResult.forEach(it => it.resetBAStamped());
      });

      return {
        composeURL() {},
        navigateToMode(mode: typeof KintaiListPageModelMode.Type) {
          appRouter().navigate(paths.dashboard.kintai_list[mode].index());
        },
        navigateToMonth(month: Date) {
          appRouter().navigate(paths.dashboard.kintai_list[self.mode].ofDate(month).index());
        },
        navigateToDepCode(depCode: string) {
          appRouter().navigate(paths.dashboard.kintai_list.dept.ofDate(self.targetMonth).search(depCode));
        },
        route(pathFragment: string) {
          const { targetMode, targetYear, targetMonth, targetDepCode } = fromPaths.dashboard.kintai_list.search(
            pathFragment,
          );

          if (targetYear && targetMonth) {
            this.setTargetMonth(dateOf(targetYear, targetMonth));
          }

          if (targetDepCode) {
            this.setTargetDepCode(targetDepCode);
          } else {
            if (!self.targetDepCode) {
              const { depCode } = profile();
              if (depCode) {
                this.setTargetDepCode(depCode);
              }
            }
          }

          if (targetMode) {
            this.setMode(targetMode);
          }

          triggerLoadDepts();
          triggerSearch();
        },
        reload() {
          triggerSearch(true);
        },
        setMode(mode: typeof KintaiListPageModelMode.Type) {
          self.mode = mode;
        },
        setTargetMonth(targetMonth: Date) {
          self.targetMonth = targetMonth;
        },
        setTargetDepCode(depCode: string) {
          self.targetDepCode = depCode;
        },
        setDisplayedRowsType(value: typeof KintaiListDisplayedRowsType.Type) {
          self.displayedRowsType = value;
        },
        closeDept,
        reopenDept,

        triggerLoadDepts,
        setFilterText(filterText: string) {
          self.filterText = filterText;
        },
        setrFilterApprovalOnYou(value: boolean) {
          self.filterApprovalOnYou = value;
        },
        setActiveRow(userId?: string) {
          if (!userId) {
            self.activeRow.userKintai = undefined;
            return;
          }
          const target = self.currentFilteredRows.find(r => r.userId === userId);
          if (target) {
            const kintai = kintaiUsers().open(userId, target.month);
            self.activeRow.userId = userId;
            self.activeRow.date = self.targetMonth;
            self.activeRow.userKintai = kintai;
            kintai.loadIfNeeded();
          }
        },
        setActiveToTheFirstRow() {
          if (self.currentFilteredRows.length) {
            this.setActiveRow(self.currentFilteredRows[0].userId);
          }
        },
        moveActiveRow(direction: number) {
          const rows = self.currentFilteredRows;
          if (rows.length === 0) {
            return;
          }

          if (!self.activeRow.userId) {
            this.setActiveToTheFirstRow();
            return;
          }

          const idx = rows.findIndex(r => r.userId === self.activeRow.userId);
          if (idx < 0) {
            this.setActiveToTheFirstRow();
            return;
          }

          this.setActiveRow(rows[(rows.length + idx + direction) % rows.length].userId);
        },
        setGridFrameSize(value: number) {
          self.gridFrameSize = value;
        },
        navigateToActiveRowKintai() {
          if (self.activeRow.userId && self.activeRow.userKintai) {
            appRouter().navigate(
              paths.kintai
                .ofUser(self.activeRow.userId)
                .ofDate(self.activeRow.userKintai.month)
                .index(),
            );
          }
        },
        setFilterReviewerAssignedToMe(value: boolean) {
          self.filterReviewerAssignedToMe = value;
        },
        setFilterApproverAssignedToMe(value: boolean) {
          self.filterApproverAssignedToMe = value;
        },
        setFilterPrivilegedApproval(value: boolean) {
          self.filterPrivilegedApproval = value;
        },
        setBulkAuthMode: flow(function*(value: boolean) {
          if (value && self.firstTimeBulkAuthOn) {
            if (
              !(yield appConfirm().confirm({
                title: "機能利用の確認",
                message:
                  "本機能は勤怠の承認件数が多い社員のための機能です。少人数の承認、査閲などはそれぞれの社員の勤怠表画面から直接行ってください。本機能を利用する社員は勤怠表の各社員の数値をきちんと確認した上で利用してください。",
                yesButtonLabel: "OK",
                noButtonLabel: undefined,
              }))
            ) {
              return;
            }
            self.firstTimeBulkAuthOn = false;
          }
          self.bulkAuthMode = value;
        }),
        saveBulkAuth,
        resetBulkAuth,
      };
    }),
  {},
);

const stampIfAnyResultsToCsvContent = (result: StampIfAnyResult[]) => {
  return stringListToCsv([
    ["社員番号", "対象月", "種別", "操作", "操作日時", "結果", "詳細"],
    ...result.map(it => [
      `${it.userId}`,
      `${moment(it.month).format("YYYY年MM月")}`,
      `${kintaiApiTimestampTypesToText(it.type)}`,
      `${it.operationType}`,
      `${it.operatedAt.toString()}`,
      `${it.success ? "成功" : "失敗"}`,
      `${
        it.exception
          ? "失敗の場合、二重押印が原因の場合があります。次の情報は参考程度にしてください。 - " +
            JSON.stringify(it.exception).replace(/[\r\n]/g, "")
          : ""
      }`,
    ]),
  ]);
};

export type KintaiListPageModelType = typeof KintaiListPageModel.Type;

export const KintaiListPageModelSymbol = "KintaiListPageModel_Symbol";
export const KintaiListPageModel: KintaiListPageModelModelType = model;
type KintaiListPageModelInferredType = typeof model;
export interface KintaiListPageModelModelType extends KintaiListPageModelInferredType {}
