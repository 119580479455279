import Help from "@webkintai/help/content/manuals/dashboard/statistics.md";
import { observer } from "mobx-react-lite";
import React from "react";

import { useStasticsPageModel } from "../../../models/statistics/useStasticsPageModel";
import { KintaiCalendarSelectorButton } from "../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { GuideRibbon } from "../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../common/ribbon/JumpRibbon";

export const StatisticsPageRibbon: React.FC<{ children?: never }> = observer(() => {
  const model = useStasticsPageModel();

  return (
    <>
      <GuideRibbon markdown={Help} />
      <JumpRibbon>
        <KintaiCalendarSelectorButton
          buttonProps={{ small: true }}
          unit="month"
          currentDate={model.targetMonth}
          nendoList={model.nendoList}
          onChangeDate={model.jumpToMonth}
        />
      </JumpRibbon>
    </>
  );
});
