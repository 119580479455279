import { action, computed } from "mobx";
import React from "react";

import { lazyInject } from "../../../didecorators";
import { PjClosePageModel, PjClosePageModelSymbol } from "../../../models/pjclose/PjClosePageModel";
import { PjClosePageGridVM } from "./grid/PjClosePageGrid";
import { PjClosePageGridVMImpl } from "./grid/PjClosePageGridVMImpl";
import { PjClosePageVM } from "./PjClosePage";
import { PjClosePageRibbonVM } from "./PjClosePageRibbon";

export class PjClosePageVMImpl implements PjClosePageVM, PjClosePageRibbonVM, PjClosePageGridVM {
  @computed
  private get nendoTarget() {
    return this.pageModel.currentTarget!;
  }

  @computed
  public get ribbonModel() {
    return this;
  }
  @computed
  public get gridModel() {
    return this;
  }

  @computed
  public get nendo() {
    return this.pageModel.nendo;
  }

  @computed
  public get nendoList() {
    return this.pageModel.nendoList;
  }

  @computed
  public get loadingStatus() {
    return this.pageModel.loadingStatus;
  }

  @computed
  public get months() {
    return this.nendoTarget.months.map(it => new PjClosePageGridVMImpl(this, it));
  }

  @lazyInject(PjClosePageModelSymbol)
  private pageModel: typeof PjClosePageModel.Type;

  @action.bound
  public onChangeNendo(nendo: number) {
    this.pageModel.navigateToNendo(nendo);
  }

  @computed
  public get allowedToEdit() {
    return this.nendoTarget.allowedToEdit;
  }

  @computed
  public get saveDisabled() {
    return this.nendoTarget.hasNoChange;
  }

  @action.bound
  public onSave() {
    this.nendoTarget.save();
  }

  @action.bound
  public onReset() {
    this.nendoTarget.reset();
  }
}
