import { types } from "mobx-state-tree";

import { prefixedIdType } from "../utils";
import { NendoDayCalendar } from "./NendoDayCalendar";

export const idPrefix = "NendoMonthCalendar_";

const model = types.model("NendoMonthCalendar", {
  id: prefixedIdType(idPrefix),
  nendo: types.number,
  month: types.number,
  days: types.array(NendoDayCalendar),
  allowReviseWorkDept: types.optional(types.boolean, false),
});

export const idNendoMonthCalendar = (nendo: number, month: number) => `${idPrefix}${nendo}_${month}`;
export type NendoMonthCalendarType = typeof NendoMonthCalendar.Type;
export const NendoMonthCalendar: NendoMonthCalendarModelType = model;
type NendoMonthCalendarInferredType = typeof model;
export interface NendoMonthCalendarModelType extends NendoMonthCalendarInferredType {}
