import { Button, ButtonGroup, Classes, IconName } from "@blueprintjs/core";
import React from "react";

import { isManagerRankPosition, RankPositionType } from "../../../models/common/RankPosition";
import { MainKintaiType, MainKintaiTypeType } from "../../../models/kintai/MainKintaiType";
import { kinmuTypeUiDefs } from "./kinmuTypeUiDefs";

export interface KinmuTypeSelectorProps {
  disabled?: boolean;
  rankPosition: RankPositionType;
  mainKinmuType: MainKintaiTypeType;
  availableKintaiTypes: MainKintaiTypeType[];
  onChangeKinmuType?: (type: MainKintaiTypeType) => void;
}

export class KinmuTypeSelector extends React.PureComponent<KinmuTypeSelectorProps> {
  public render() {
    const { props } = this;
    return (
      <ButtonGroup vertical={true}>
        {(["Flex", "Non-Flex", "S-Port"] as Array<typeof MainKintaiType.Type>).map(kinmuType => {
          const { title, icon } = kinmuTypeUiDefs[kinmuType]!;
          const isSelected = kinmuType === this.props.mainKinmuType;
          const disabled =
            this.props.disabled === true || isSelected || !this.props.availableKintaiTypes.find(it => kinmuType === it);
          return (
            <KinmuTypeSelectorButton
              key={kinmuType}
              title={`${title}${isManagerRankPosition(props.rankPosition) ? "(管理職)" : ""}`}
              kintaiType={kinmuType}
              onClick={this.props.onChangeKinmuType}
              disabled={disabled}
              isSelected={isSelected}
              icon={icon}
            />
          );
        })}
      </ButtonGroup>
    );
  }
}

const KinmuTypeSelectorButton = (props: {
  disabled: boolean;
  kintaiType: MainKintaiTypeType;
  onClick?: (kintaiType: MainKintaiTypeType) => void;
  isSelected: boolean;
  title: string;
  icon: IconName;
}) => {
  const onClick = () => {
    if (props.disabled || !props.onClick) {
      return;
    }
    props.onClick(props.kintaiType);
  };
  return (
    <Button
      className={Classes.POPOVER_DISMISS}
      disabled={props.disabled}
      onClick={onClick}
      active={props.isSelected}
      icon={props.icon}>
      {props.title}
    </Button>
  );
};
