import { observer } from "mobx-react";
import React from "react";

export interface PJRHeaderNumberKFProps {
  model: PJRHeaderNumberKFVM;
}

export interface PJRHeaderNumberKFVM {
  PJRHeaderNumber: number;
}

export const PJRHeaderNumberKF = observer((props: PJRHeaderNumberKFProps) => {
  const { model } = props;

  return (
    <>
      No.
      {model.PJRHeaderNumber}
    </>
  );
});
