import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalCompDaysKFProps = {
  model: MTotalCompDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalCompDaysKFVM {
  mTotalCompDays: KintaiInputItemState<Days>;
}

export const MTotalCompDaysKF = observer((props: MTotalCompDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalCompDays;

  return (
    <DayCountField
      {...props}
      label="mTotalCompDaysKF"
      {...itemState}
      value={itemState.value.days}
      onChange={undefined}
    />
  );
});
