import { IModelType, types } from "mobx-state-tree";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export interface AppConfirmMessage {
  title: string;
  message: string;
  yesButtonLabel?: string;
  noButtonLabel?: string;
  resolver: (value: boolean) => void;
}

const model = types.optional(
  types
    .model("AppConfirmQueue", {
      queueLength: types.optional(types.number, 0),
    })
    .volatile(self => ({
      confirmQueue: [] as AppConfirmMessage[],
    }))
    .views(self => {
      return {
        get lastMessage() {
          if (self.queueLength === 0) {
            return undefined;
          }

          return self.confirmQueue[self.queueLength - 1];
        },
      };
    })
    .actions(self => {
      return {
        queue(message: AppConfirmMessage) {
          self.confirmQueue.push(message);
          self.queueLength++;
        },
        provideAnswer(value: boolean) {
          if (self.lastMessage) {
            self.lastMessage.resolver(value);
            self.queueLength--;
            self.confirmQueue.pop();
          }
        },
      };
    }),
  {},
);

export type AppConfirmQueueType = typeof AppConfirmQueue.Type;

export const AppConfirmQueueSymbol = "AppConfirmQueue_Symbol";
export const AppConfirmQueue: AppConfirmQueueModelType = model;
type AppConfirmQueueInferredType = typeof model;
export interface AppConfirmQueueModelType extends AppConfirmQueueInferredType {}
