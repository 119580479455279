import { computed } from "mobx";

import { PHPageModel } from "../../../../models/ph/PHPageModel";
import { PHUserEntry } from "../../../../models/ph/PHUserEntry";
import { PHPagGridRowVM } from "./PHPageGrid";
import { PHPerYearVacationVMImpl } from "./PHPerYearVacationVMImpl";

export class PHPagGridRowVMImpl implements PHPagGridRowVM {
  constructor(public readonly model: typeof PHPageModel.Type, public readonly user: typeof PHUserEntry.Type) {}

  @computed
  public get userId() {
    return this.user.userId;
  }

  @computed
  public get userName() {
    return this.user.userName;
  }

  @computed
  public get deptName() {
    return this.user.deptName || "";
  }

  @computed
  public get rankName() {
    return this.user.rankName || "";
  }

  @computed
  public get hasNoChange() {
    return this.user.hasNoChange;
  }

  @computed
  public get perYearVacations() {
    return this.model.targetNendos.map(nendo => {
      const target = this.user.getNendoEntry(nendo);

      return new PHPerYearVacationVMImpl(target);
    });
  }
}
