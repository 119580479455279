import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { getNendoMonths } from "../../../../utils/calendar";
import { RenderedMedia } from "../../../common/export-table/ExportableTable";
import { HHMMField } from "../../../common/form/HHMMField";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkTable, WkTotalFooterTr } from "../../../common/plaintable/WkTables";
import { KintaiSumOrderCommonTotals } from "./KintaiSumOrderCommonTotals";

export type KintaiSumTableByOrderVM = {
  byOrderRows: KintaiSumTableByOrderRowVM[];
} & KintaiSumOrderCommonTotals;

export interface KintaiSumTableByOrderRowVM {
  id: string;
  odCode: string;
  pjCode: string;
  pjName: string;

  total: Minutes | undefined;

  months: KintaiSumTableByOrderRowMonthVM[];
}

export interface KintaiSumTableByOrderRowMonthVM {
  month: Date;
  total: Minutes | undefined;
}

// 4月〜3月の列挙が欲しいだけ
const monthSeries = getNendoMonths(2018).map(it => moment(it).format("MM月"));

@observer
export class KintaiSumTableByOrder extends React.Component<{
  renderedOn: RenderedMedia;
  displayHourAsDecimal: boolean;
  model: KintaiSumTableByOrderVM;
}> {
  public render() {
    const { model, renderedOn, displayHourAsDecimal } = this.props;

    if (model.byOrderRows.length === 0) {
      return <NoResultsSpread />;
    }

    return (
      <WkTable>
        <thead>
          <tr>
            <th>#</th>
            <th>OD-CD</th>
            <th>PJ-CD</th>
            <th>プロジェクト名</th>

            {monthSeries.map(it => (
              <th key={it}>{it}</th>
            ))}

            <th>合計</th>
          </tr>
        </thead>
        <tbody>
          {model.byOrderRows.map((row, idx) => (
            <tr key={row.id}>
              <td>{idx + 1}</td>
              <td>{row.odCode}</td>
              <td>{row.pjCode}</td>
              <td>{row.pjName}</td>

              {monthSeries.map((it, mIdx) => (
                <td key={it}>
                  <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={row.months[mIdx].total} />
                </td>
              ))}

              <td>
                <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={row.total} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <WkTotalFooterTr>
            <td colSpan={4}>合計</td>

            {model.monthlyProjectOrderTotals.map((total, mIdx) => (
              <td key={mIdx}>
                <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={total.total} />
              </td>
            ))}

            <td>
              <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={model.projectOrderTotal} />
            </td>
          </WkTotalFooterTr>
        </tfoot>
      </WkTable>
    );
  }
}
