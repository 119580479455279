import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalTransOnDaysKFProps = {
  model: MTotalTransOnDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTransOnDaysKFVM {
  mTotalTransOnDays: KintaiInputItemState<Days>;
}

export const MTotalTransOnDaysKF = observer((props: MTotalTransOnDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalTransOnDays;

  return <DayCountField {...props} label="" {...itemState} value={itemState.value.days} onChange={undefined} />;
});
