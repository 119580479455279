import { getEnv, IModelType, types } from "mobx-state-tree";

import { isManagerRankPosition, RankPosition } from "../common/RankPosition";
import { WorkingType } from "../common/WorkStyle";
import { RolesSymbol, RolesType } from "../roles/Roles";
import { prefixedIdType } from "../utils";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoRank_";

const model = types
  .model("NendoRank", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    rankCode: types.string,
    rankName: types.string,

    workingType: types.maybe(WorkingType),
    rankPosition: types.maybe(RankPosition),
    roleCode: types.string,
  })
  .views(self => {
    const rolesDef = (): RolesType => getEnv(self).get(RolesSymbol);

    return {
      get role() {
        return rolesDef().of(self.roleCode);
      },
      get isManager() {
        return !!(self.rankPosition && isManagerRankPosition(self.rankPosition));
      },
    };
  });

export type NendoRankType = typeof NendoRank.Type;

export const idNendoRank = (nendo: number, rankCode: string) => `${idPrefix}${nendo}_${rankCode}`;

export const NendoRankSymbol = "NendoRank_Symbol";
export const NendoRank: NendoRankModelType = model;
type NendoRankInferredType = typeof model;
export interface NendoRankModelType extends NendoRankInferredType {}
