import { getParentOfType, IModelType, types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../../utils/model";
import { RankPosition } from "../../common/RankPosition";
import { WorkingType } from "../../common/WorkStyle";
import { prefixedIdType } from "../../utils";
import { NendoRank } from "../NendoRank";
import { NendoRanksInput } from "./NendoRanksInput";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoRank_";

const model = types
  .model("NendoRankInput", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    rankCode: types.string,
    rankName: types.string,

    origin: types.maybe(types.reference(NendoRank)),
    workingType: types.maybe(WorkingType),
    rankPosition: types.maybe(RankPosition),
    roleCode: types.string,
    persisted: types.boolean,
  })
  .views(self => {
    return {
      get allowedToEdit() {
        return getParentOfType(self, NendoRanksInput).allowedToEdit;
      },
      get hasNoChangeInRankDef() {
        if (!self.persisted) {
          return false;
        }
        const org = self.origin!;
        return hasNoChangeReduce([self.rankName === org.rankName, self.workingType === org.workingType]);
      },
      get hasNoChangeInNendoDef() {
        if (!self.persisted) {
          return false;
        }
        const org = self.origin!;
        return hasNoChangeReduce([
          self.rankPosition === org.rankPosition,
          self.workingType === org.workingType,
          self.roleCode === org.roleCode,
        ]);
      },
      get hasNoChange() {
        return hasNoChangeReduce([this.hasNoChangeInRankDef, this.hasNoChangeInNendoDef]);
      },
    };
  })
  .actions(self => {
    return {
      setWorkingType(value: typeof WorkingType.Type | undefined) {
        self.workingType = value;
      },
      setRankPosition(value: typeof RankPosition.Type | undefined) {
        self.rankPosition = value;
      },
      setRankName(value: string) {
        self.rankName = value;
      },
      setRoleCode(value: string | undefined) {
        if (value) {
          self.roleCode = value;
        }
      },
    };
  });

export const idNendoRankInput = (nendo: number, rankCode: string) => `${idPrefix}${nendo}_${rankCode}`;
export type NendoRankInputType = typeof NendoRankInput.Type;

export const NendoRankInputSymbol = "NendoRankInput_Symbol";
export const NendoRankInput: NendoRankInputModelType = model;
type NendoRankInputInferredType = typeof model;
export interface NendoRankInputModelType extends NendoRankInputInferredType {}
