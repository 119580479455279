import { flow, IModelType, types } from "mobx-state-tree";

import { PasswordApi } from "../../services/api/PasswordApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getDI } from "../common/getDI";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("PasswordChangeAppFormModel", {
      showPasswordChangeDialog: types.optional(types.boolean, false),
      userId: types.optional(types.string, ""),
      email: types.optional(types.string, ""),
      inputLocked: types.optional(types.boolean, false),
    })
    .views(self => {
      return {
        get allowPushSubmit() {
          return self.userId.length > 0 && self.email.length > 0;
        },
      };
    })
    .actions(self => {
      const passwordApi = () => getDI(self, PasswordApi);
      const appNotifier = () => getDI(self, AppNotifier);

      const setShowPasswordChangeDialog = (value: boolean) => {
        self.showPasswordChangeDialog = value;
      };

      const submit = flow(function*() {
        self.inputLocked = true;
        try {
          yield passwordApi().requestReset(self.userId, self.email);
          appNotifier().info({ message: "パスワードリセット申請が完了しました。" });
          setShowPasswordChangeDialog(false);
        } catch (exception) {
          appNotifier().error({ message: "パスワードリセット申請に失敗しました。", exception });
        } finally {
          self.inputLocked = false;
        }
      });

      return {
        setUserId(value: string) {
          self.userId = value;
        },
        setEmail(value: string) {
          self.email = value;
        },
        setShowPasswordChangeDialog,
        submit,
      };
    }),
  {},
);

export type PasswordChangeAppFormModelType = typeof PasswordChangeAppFormModel.Type;

export const PasswordChangeAppFormModelSymbol = "PasswordChangeAppFormModel_Symbol";
export const PasswordChangeAppFormModel: PasswordChangeAppFormModelModelType = model;
type PasswordChangeAppFormModelInferredType = typeof model;
export interface PasswordChangeAppFormModelModelType extends PasswordChangeAppFormModelInferredType {}
