import styled from "@emotion/styled";
import React from "react";

export const DashboardTemplate: React.FC<{
  publicGood: React.ReactNode;
  publicBad: React.ReactNode;
  privateData: React.ReactNode;

  children?: never;
}> = ({ publicGood, publicBad, privateData }) => {
  return (
    <Container>
      {publicGood}
      {publicBad}
      {privateData}
    </Container>
  );
};

const Container = styled.div`
  flex: auto;
  overflow: auto;

  background-color: #eee;
  padding: 1rem;

  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  & > * {
    margin: 0.25rem;
  }
`;

const MaxWidth = styled.div`
  flex: auto;
  max-width: 1328px;
  margin: auto;

  display: flex;
  flex-wrap: wrap;
`;

const PublicSection = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  margin-bottom: 1rem;
  margin-right: 1rem;

  & > * + * {
    margin-left: 1rem;
  }
`;

const PublicAllSection = styled.div`
  flex: none;
`;

const PrivateSection = styled.div`
  flex: auto;
  min-width: 20rem;

  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > * + * {
    margin-left: 2rem;
  }
`;
