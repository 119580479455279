import { sortBy } from "lodash-es";
import { getEnv, IModelType, types } from "mobx-state-tree";

import { NotificationType } from "./Notification";
import { NotificationsSymbol, NotificationsType } from "./Notifications";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("NotificationPageModel", {
      onlyUnread: types.boolean,
      openedMessageId: types.maybe(types.string),
      messageListFrameSize: types.optional(types.number, 170),
    })
    .views(self => {
      const notifications = (): NotificationsType => getEnv(self).get(NotificationsSymbol);
      return {
        get notifications() {
          return notifications();
        },
        get openedMessage() {
          if (!self.openedMessageId) {
            return;
          }
          return notifications().notifications.get(self.openedMessageId);
        },
        get sortedNotifications() {
          const result = sortBy(
            Array.from(this.notifications.notifications.values()),
            notif => -notif.publishDate.getTime(),
          );

          return self.onlyUnread ? result.filter(msg => !msg.read) : result;
        },
        get unreadCount() {
          return notifications().unreadCount;
        },
      };
    })
    .actions(self => {
      return {
        setOnlyUnread(value: boolean) {
          self.onlyUnread = value;
        },
        toggleRead() {
          if (self.openedMessage) {
            self.openedMessage.toggleRead();
          }
        },
        markAllAsRead() {
          self.notifications.markAllAsRead();
        },
        openMessage(message: NotificationType) {
          self.openedMessageId = message.id;
          message.openMessage();
        },
        route(pathFragment: string) {
          // DO NOTHING.
        },
        setMessageListFrameSize(value: number) {
          self.messageListFrameSize = value;
        },
      };
    }),
  {
    onlyUnread: false,
  },
);

export type NotificationPageModelType = typeof NotificationPageModel.Type;

export const NotificationPageModelSymbol = "NotificationPageModel_Symbol";
export const NotificationPageModel: NotificationPageModelModelType = model;
type NotificationPageModelInferredType = typeof model;
export interface NotificationPageModelModelType extends NotificationPageModelInferredType {}
