import { KintaiListEntry } from "../../../../models/kintai_list/KintaiListEntry";
import { KintaiListPageModel } from "../../../../models/kintai_list/KintaiListPageModel";
import { CommonKintaiListPageGridRowVMImpl } from "../common/CommonKintaiListPageGridRowVMImpl";
import { NoRegKintaiListPageGridRowVM } from "./NoRegKintaiListPageGridRowVM";

export class NoRegKintaiListPageGridRowVMImpl extends CommonKintaiListPageGridRowVMImpl
  implements NoRegKintaiListPageGridRowVM {
  constructor(pageModel: typeof KintaiListPageModel.Type, monthlyKintai: typeof KintaiListEntry.Type) {
    super(pageModel, monthlyKintai);
  }
}
