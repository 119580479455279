import { IModelType, types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../utils/model";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("PjCloseNendoEditorMonth", {
    month: types.Date,

    closed: types.boolean,
    org_closed: types.boolean,
  })
  .views(self => {
    return {
      get hasNoChange() {
        return hasNoChangeReduce([self.closed === self.org_closed]);
      },
    };
  })
  .actions(self => {
    return {
      setClosed(value: boolean) {
        self.closed = value;
      },
      reset() {
        self.closed = self.org_closed;
      },
    };
  });
export const PjCloseNendoEditorMonth: PjCloseNendoEditorMonthModelType = model;
type PjCloseNendoEditorMonthInferredType = typeof model;
export interface PjCloseNendoEditorMonthModelType extends PjCloseNendoEditorMonthInferredType {}
