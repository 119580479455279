import {
  DepartmentResult,
  DeptPersonsInChargeResult,
  DeptPersonsInChargeUpdateRequest,
  KintaiAggregationsResult,
  KintaiDepartmentCloseResult,
  KintaiDepartmentCloseStatusResult,
  KintaiDepartmentReopenResult,
  KintaiReportsResult,
} from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class DeptsApi {
  public abstract getDepartments(nendo: number): Promise<DepartmentResult>;
  public abstract getKintaiReports(depCode: string, year: number, month: number): Promise<KintaiReportsResult>;
  public abstract getKintaiNendoSummaries(depCode: string, nendo: number): Promise<KintaiAggregationsResult>;
  public abstract getDepartmentReportsCloseStatus(depCode: string): Promise<KintaiDepartmentCloseStatusResult>;
  public abstract closeKintaiReports(
    depCode: string,
    year: number,
    month: number,
  ): Promise<KintaiDepartmentCloseResult>;
  public abstract reopenKintaiReports(
    depCode: string,
    year: number,
    month: number,
  ): Promise<KintaiDepartmentReopenResult>;

  public abstract getPIC(nendo: number): Promise<DeptPersonsInChargeResult>;
  public abstract savePIC(nendo: number, payload: DeptPersonsInChargeUpdateRequest): Promise<unknown>;
}
