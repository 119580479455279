import { NotificationResult } from "@webkintai/api";
import { flow, IModelType, types } from "mobx-state-tree";

import { NotificationsApi } from "../../services/api/NotificationsApi";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("Notification", {
    id: types.identifier,
    subject: types.string,
    read: types.boolean,
    body: types.maybe(types.string),
    bodyLoadingState: types.optional(LoadingStatus, "loading"),
    publishDate: types.Date,
  })
  .actions(self => {
    const notificationsApi = () => getDI(self, NotificationsApi);

    const loadBody = flow(function*() {
      if (self.bodyLoadingState === "loaded") {
        return;
      }
      self.bodyLoadingState = "loading";
      const result: NotificationResult = yield notificationsApi().getNotificationDetails(+self.id);
      self.body = result.notification.contentBody;
      self.bodyLoadingState = "loaded";
    });
    const setRead = (read: boolean) => {
      self.read = read;
      notificationsApi().setRead([{ id: +self.id, read }]);
    };
    const toggleRead = () => {
      setRead(!self.read);
    };

    return {
      setRead,
      toggleRead,
      openMessage: flow(function*() {
        yield loadBody();
        setRead(true);
      }),
      loadBody,
    };
  });

export type NotificationType = typeof Notification.Type;

export const NotificationSymbol = "Notification_Symbol";
export const Notification: NotificationModelType = model;
type NotificationInferredType = typeof model;
export interface NotificationModelType extends NotificationInferredType {}
