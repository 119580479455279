import React from "react";

import { CellFormAdaptable } from "./CellFormAdaptable";
import { SelectField } from "./SelectField";

export type WorkingTypeSelectProps = {
  value: WorkingTypeValue;
  onChange: (value: WorkingTypeValue) => void;
  disabled?: boolean;
  className?: string;
} & CellFormAdaptable;

export type WorkingTypeValue = "0" | "1" | undefined;

export const WorkingTypeSelect = (props: WorkingTypeSelectProps) => {
  const onChangeHandler = (value: string | undefined) => {
    if (value === "0" || value === "1") {
      props.onChange(value);
      return;
    }
    props.onChange(undefined);
  };

  return (
    <SelectField
      {...props}
      onChange={onChangeHandler}
      label="勤務種別"
      value={props.value}
      disabled={props.disabled}
      options={[{ value: undefined, label: "" }, { value: "0", label: "一般勤務" }, { value: "1", label: "FLEX勤務" }]}
    />
  );
};
