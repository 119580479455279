import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalActualKFProps = {
  model: MTotalActualKFVM;
} & CellFormPrintAdaptable;

export interface MTotalActualKFVM {
  mTotalActual: KintaiInputItemState<Minutes>;
}

export const MTotalActualKF = observer((props: MTotalActualKFProps) => {
  const { model } = props;
  const itemState = model.mTotalActual;

  return <HHMMField {...props} label="mTotalAllNightOffKF" {...itemState} onChange={undefined} />;
});
