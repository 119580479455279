import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled-base";
import classNames from "classnames";
import React from "react";

import { GuideButton } from "../../guide/GuideButton";

export interface DailyKintaiHelpButtonProps {
  className?: string;
  markdown: string;
}

export const DailyKintaiHelpButton: React.FunctionComponent<DailyKintaiHelpButtonProps> = props => (
  <GuideButton
    className={props.className}
    markdown={props.markdown}
    buttonProps={{
      fill: false,
      icon: undefined,
      minimal: true,
      children: (
        <span style={{ display: "block", textAlign: "center" }}>
          <Icon icon="help" />
        </span>
      ),
    }}
  />
);

const StyledDailyKintaiTabHelpButton = styled(DailyKintaiHelpButton)`
  margin-left: 1ex;
  opacity: 0.2;
  pointer-events: none;
  transition: opacity 0.2s ease;

  &.Active {
    opacity: 1;
    pointer-events: auto;
  }
`;

export const DailyKintaiTabHelpButton: React.FunctionComponent<DailyKintaiHelpButtonProps & {
  active: boolean;
}> = props => (
  <StyledDailyKintaiTabHelpButton {...props} className={classNames(props.active && "Active", props.className)} />
);
