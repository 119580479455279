import { HourBasedDays, Minutes } from "@webkintai/core";
import { range } from "lodash-es";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { MonthValues } from "../../../../models/kintai_sum/ByUserView";
import { RenderedMedia } from "../../../common/export-table/ExportableTable";
import { HHMMField } from "../../../common/form/HHMMField";
import { HourBasedDaysCountField } from "../../../common/form/HourBasedDaysCountField";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkAverageFooterTr, WkTable, WkTd, WkTotalFooterTr } from "../../../common/plaintable/WkTables";

export interface KintaiSumTableByUserVM {
  byUserMonths: KintaiSumTableByUserMonthVM[];
}

export interface KintaiSumTableByUserMonthVM {
  month: Date;
  dayCount: number;
  lawBasis: Minutes | undefined;
  users: KintaiSumTableByUserMonthUserVM[];
  total: MonthValues;
  average: MonthValues;
}

export type KintaiSumTableByUserMonthUserVM = {
  userId: string;
  userName: string;
  depName: string;
  rankName: string;

  onNavigateToKintai: () => void;
} & MonthValues;

export const ColSpanOrCols: React.FunctionComponent<{
  colSpan: number;
  renderedOn: RenderedMedia;
}> = props => {
  return props.renderedOn === "csv" ? (
    <>
      {range(0, props.colSpan).map(idx => (
        <td key={idx}>{idx === props.colSpan - 1 ? props.children : ""}</td>
      ))}
    </>
  ) : (
    <td colSpan={props.colSpan}>{props.children}</td>
  );
};

const Over45: React.FunctionComponent<{ value: Minutes | undefined; printHourAsDecimal: boolean }> = props => (
  <WkTd warned={props.value && props.value.minutes >= 45 * 60}>
    <HHMMField mode="print" printHourAsDecimal={props.printHourAsDecimal} value={props.value} />
  </WkTd>
);
const Over360: React.FunctionComponent<{ value: Minutes | undefined; printHourAsDecimal: boolean }> = props => (
  <WkTd warned={props.value && props.value.minutes >= 360 * 60}>
    <HHMMField mode="print" printHourAsDecimal={props.printHourAsDecimal} value={props.value} />
  </WkTd>
);

@observer
export class KintaiSumTableByUser extends React.Component<{
  renderedOn: RenderedMedia;
  displayHourAsDecimal: boolean;
  model: KintaiSumTableByUserVM;
}> {
  public render() {
    const { renderedOn, model, displayHourAsDecimal } = this.props;
    const { byUserMonths } = model;
    if (byUserMonths.length === 0 || byUserMonths[0].users.length === 0) {
      return <NoResultsSpread />;
    }

    const { users } = byUserMonths[0];

    return (
      <WkTable>
        <thead>
          <tr>
            {renderedOn !== "csv" ? (
              <>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}>社員番号</th>
                <th rowSpan={2}>氏名</th>
                <th rowSpan={2}>部門</th>
                <th rowSpan={2}>ランク</th>
              </>
            ) : (
              <>
                <th />
                <th />
                <th />
                <th />
                <th />
              </>
            )}

            {/* 月ごと */}
            {byUserMonths.map(month => (
              <React.Fragment key={month.month.toISOString()}>
                <th>{moment(month.month).format("YYYY年MM月")}</th>
                <th>日数</th>
                <th>{month.dayCount}</th>
                <th>法定基準時間</th>
                <th>
                  <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={month.lawBasis} />
                </th>
                {renderedOn === "csv" ? (
                  <>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </>
                ) : (
                  <th colSpan={6} />
                )}
              </React.Fragment>
            ))}
          </tr>
          <tr>
            {renderedOn === "csv" ? (
              <>
                <th rowSpan={2}>#</th>
                <th rowSpan={2}>社員番号</th>
                <th rowSpan={2}>氏名</th>
                <th rowSpan={2}>部門</th>
                <th rowSpan={2}>ランク</th>
              </>
            ) : (
              <></>
            )}

            {/* 月ごと */}
            {byUserMonths.map(month => (
              <React.Fragment key={month.month.toISOString()}>
                <th>作業時間</th>
                <th>残業指示</th>
                <th>残業実績</th>
                <th>振・休出</th>
                <th>残業累計</th>
                <th>健康管理</th>
                <th>健康累計</th>
                <th>9日迄</th>
                <th>予測時間</th>
                <th>時間休(H)</th>
                <th>有休(時休除く)</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>

        <tbody>
          {users.map((user, uIdx) => (
            <tr key={user.userId}>
              <td>{uIdx + 1}</td>
              <td>{user.userId}</td>
              <td>{user.userName}</td>
              <td>{user.depName}</td>
              <td>{user.rankName}</td>

              {/* 月ごと */}
              {byUserMonths.map(month => {
                const userMonth = month.users.find(u => u.userId === user.userId);

                return (
                  <React.Fragment key={month.month.toISOString()}>
                    {!userMonth && (
                      <>
                        {range(0, 11).map(it => (
                          <td key={it} />
                        ))}
                      </>
                    )}
                    {userMonth && (
                      <>
                        <td>
                          <a href="javascript: undefined" onClick={userMonth.onNavigateToKintai}>
                            <HHMMField
                              mode="print"
                              printHourAsDecimal={displayHourAsDecimal}
                              value={userMonth.actual}
                            />
                          </a>
                        </td>
                        <td>
                          <HHMMField
                            mode="print"
                            printHourAsDecimal={displayHourAsDecimal}
                            value={userMonth.designatedHours}
                          />
                        </td>
                        <Over45 printHourAsDecimal={displayHourAsDecimal} value={userMonth.over} />
                        <td>{userMonth.transOnHolidayDays ? userMonth.transOnHolidayDays.days : ""}</td>
                        <Over360 printHourAsDecimal={displayHourAsDecimal} value={userMonth.overNendoSum} />
                        <Over45 printHourAsDecimal={displayHourAsDecimal} value={userMonth.lawOver} />
                        <Over360 printHourAsDecimal={displayHourAsDecimal} value={userMonth.lawOverNendoSum} />
                        <td>
                          <HHMMField
                            mode="print"
                            printHourAsDecimal={displayHourAsDecimal}
                            value={userMonth.lawOverNine}
                          />
                        </td>
                        <Over45 printHourAsDecimal={displayHourAsDecimal} value={userMonth.lawOverCalc} />
                        <td>
                          <HourBasedDaysCountField mode="print" showAsDateCount={false} value={userMonth.hourlyLeave} />
                        </td>
                        <td>
                          <HourBasedDaysCountField mode="print" value={userMonth.plainPaidDays} />
                        </td>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
        </tbody>
        <tfoot>
          <WkTotalFooterTr>
            <ColSpanOrCols renderedOn={renderedOn} colSpan={5}>
              合計
            </ColSpanOrCols>

            {/* 月ごと */}
            {byUserMonths.map(month => {
              const value = month.total;

              return (
                <React.Fragment key={month.month.toISOString()}>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.actual} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.designatedHours} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.over} />
                  </td>
                  <td>{value.transOnHolidayDays ? value.transOnHolidayDays.days : ""}</td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.overNendoSum} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.lawOver} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.lawOverNendoSum} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.lawOverNine} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.lawOverCalc} />
                  </td>
                  <td>
                    <HourBasedDaysCountField mode="print" showAsDateCount={false} value={value.hourlyLeave} />
                  </td>
                  <td>
                    <HourBasedDaysCountField mode="print" value={value.plainPaidDays} />
                  </td>
                </React.Fragment>
              );
            })}
          </WkTotalFooterTr>
          <WkAverageFooterTr>
            <ColSpanOrCols renderedOn={renderedOn} colSpan={5}>
              平均
            </ColSpanOrCols>
            {/* 月ごと */}
            {byUserMonths.map(month => {
              const value = month.average;

              return (
                <React.Fragment key={month.month.toISOString()}>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.actual} />
                  </td>
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.designatedHours} />
                  </td>
                  <Over45 printHourAsDecimal={displayHourAsDecimal} value={value.over} />
                  <td>{value.transOnHolidayDays ? value.transOnHolidayDays.days : ""}</td>
                  <Over360 printHourAsDecimal={displayHourAsDecimal} value={value.overNendoSum} />
                  <Over45 printHourAsDecimal={displayHourAsDecimal} value={value.lawOver} />
                  <Over360 printHourAsDecimal={displayHourAsDecimal} value={value.lawOverNendoSum} />
                  <td>
                    <HHMMField mode="print" printHourAsDecimal={displayHourAsDecimal} value={value.lawOverNine} />
                  </td>
                  <Over45 printHourAsDecimal={displayHourAsDecimal} value={value.lawOverCalc} />
                  <td>
                    <HourBasedDaysCountField
                      mode="print"
                      showAsDateCount={false}
                      value={roundHourBasedDays(value.hourlyLeave)}
                    />
                  </td>
                  <td>
                    <HourBasedDaysCountField mode="print" value={roundHourBasedDays(value.plainPaidDays)} />
                  </td>
                </React.Fragment>
              );
            })}
          </WkAverageFooterTr>
        </tfoot>
      </WkTable>
    );
  }
}

const roundHourBasedDays = (value: HourBasedDays | undefined): HourBasedDays | undefined => {
  if (!value) {
    return value;
  }
  return HourBasedDays.of(Math.round(100 * value.hours) / 100);
};
