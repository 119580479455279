import { NonIdealState, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

export interface AppInitMsgVM {
  message: string;
}

const Container = styled.div`
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  height: 20em;
  max-height: 60%;
  width: 20em;
  max-width: 80%;
  border-radius: 10px;
`;

@observer
export class AppInitMsg extends React.Component<{ model: AppInitMsgVM }> {
  public render() {
    const { model } = this.props;
    return (
      <Container>
        <NonIdealState icon={<Spinner />} title={model.message} />
      </Container>
    );
  }
}
