import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { InboxPageGridRowVM, inboxRowStyle } from "./InboxPageGrid";

@observer
export class InboxPublishDateCell extends React.Component<{ model: InboxPageGridRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <LabelField mode="cell" style={inboxRowStyle(row)} cellPosition={NULL_CELL_POSITION} onClick={row.onOpenMessage}>
        {moment(row.at).format("MM/DD (HH:mm)")}
      </LabelField>
    );
  }
}
