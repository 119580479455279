import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { PHPageGrid, PHPageGridVM } from "./grid/PHPageGrid";
import { PHPageRibbon, PHPageRibbonVM } from "./ribbon/PHPageRibbon";

export interface PHPageVM {
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
  ribbonModel: PHPageRibbonVM;
  gridModel: PHPageGridVM;
}

@observer
export class PHPage extends React.Component<{ model: PHPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<PHPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("有給休暇管理")}</title>
        </Helmet>

        {model.loadingStatus !== "loaded" && (
          <GridTableLoading loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
        )}
        {model.loadingStatus === "loaded" && <PHPageGrid model={model.gridModel} />}
      </PageRibbon>
    );
  }
}
