import { flow, IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { PasswordApi } from "../../services/api/PasswordApi";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getDI } from "../common/getDI";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("PasswordTokenResetFormModel", {
      showPasswordTokenResetDialog: types.optional(types.boolean, false),
      token: types.maybe(types.string),
      newPassword: types.optional(types.string, ""),
      newPasswordForConfirm: types.optional(types.string, ""),
      inputLocked: types.optional(types.boolean, false),
    })
    .volatile(self => {
      return {
        notifyProcessingDone: () => {},
      };
    })
    .views(self => {
      return {
        get isInPasswordTokenResetMode() {
          return (self.token || "").length > 0;
        },
        get passwordMatched() {
          return self.newPassword === self.newPasswordForConfirm;
        },
        get errors() {
          if (self.newPassword.length === 0) {
            return ["パスワードを入力してください"];
          }

          if (!this.passwordMatched) {
            return ["パスワードが一致しません"];
          }
          return [];
        },
      };
    })
    .actions(self => {
      const passwordApi = () => getDI(self, PasswordApi);
      const appNotifier = () => getDI(self, AppNotifier);
      const appRouter = () => getDI(self, AppRouter);

      const resetWithUrl = (path: string) => {
        self.token = fromPaths.password_token_reset.index(path);
      };

      const submit = flow(function*() {
        if (!self.token) {
          return;
        }
        self.inputLocked = true;
        try {
          yield passwordApi().resetPasswordByToken(self.token, self.newPassword);
          appNotifier().info({ message: "パスワードリセットが完了しました。" });
          self.showPasswordTokenResetDialog = false;
          self.notifyProcessingDone();
        } catch (exception) {
          appNotifier().error({ message: "パスワードリセットに失敗しました。", exception });
        } finally {
          self.inputLocked = false;
        }
      });

      const cancel = () => {
        self.showPasswordTokenResetDialog = false;
        self.notifyProcessingDone();
      };

      const startResetProcedure = () =>
        new Promise(done => {
          self.showPasswordTokenResetDialog = true;
          appRouter().replaceWithoutEffects(paths.index());
          self.notifyProcessingDone = done;
        });

      return {
        acceptUrl: resetWithUrl,
        setNewPassword(value: string) {
          self.newPassword = value;
        },
        setNewPasswordForConfirm(value: string) {
          self.newPasswordForConfirm = value;
        },
        submit,
        cancel,
        startResetProcedure,
      };
    }),
  {},
);

export type PasswordTokenResetFormModelType = typeof PasswordTokenResetFormModel.Type;

export const PasswordTokenResetFormModelSymbol = "PasswordTokenResetFormModel_Symbol";
export const PasswordTokenResetFormModel: PasswordTokenResetFormModelModelType = model;
type PasswordTokenResetFormModelInferredType = typeof model;
export interface PasswordTokenResetFormModelModelType extends PasswordTokenResetFormModelInferredType {}
