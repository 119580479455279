import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MissedKFProps = {
  model: MissedKFVM;
} & CellFormPrintAdaptable;

export interface MissedKFVM {
  missed: KintaiInputItemState<Minutes | undefined>;
}

export const MissedKF = observer((props: MissedKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.missed);

  return <HHMMField {...props} label="欠勤時間" {...itemState} />;
});
