import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalHourlyFamilycareKFProps = {
  model: MTotalHourlyFamilycareKFVM;
} & CellFormPrintAdaptable;

export interface MTotalHourlyFamilycareKFVM {
  mTotalHourlyFamilycare: KintaiInputItemState<HourBasedDays>;
}

export const MTotalHourlyFamilycareKF = observer((props: MTotalHourlyFamilycareKFProps) => {
  const { model } = props;
  const itemState = model.mTotalHourlyFamilycare;

  return (
    <HourBasedDaysCountField
      showAsDateCount={false}
      {...props}
      label="mTotalHourlyFamilycareKF"
      {...itemState}
      onChange={undefined}
    />
  );
});
