import { DeptPersonsInChargeResult, UsersResult } from "@webkintai/api";
import { flatMap, keyBy } from "lodash-es";
import { applySnapshot, flow, getEnv, IModelType, types } from "mobx-state-tree";

import { DeptsApi } from "../../../services/api/DeptsApi";
import { UsersApi } from "../../../services/api/UsersApi";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { TimeProvider } from "../../../services/TimeProvider";
import { fromApiDate } from "../../../utils/api";
import { getNendoAsDate } from "../../../utils/calendar";
import { hasNoChangeReduce } from "../../../utils/model";
import { getDI } from "../../common/getDI";
import { LoadingStatus } from "../../common/LoadingStatus";
import { NendoRanksType } from "../../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../../ranks/Ranks";
import { UsersSymbol, UsersType } from "../../users/Users";
import { prefixedIdType } from "../../utils";
import { DeptsSymbol, DeptsType } from "../Depts";
import { NendoDepts, NendoDeptsType } from "../NendoDepts";
import { idNendoInCharge, NendoInCharge } from "./NendoInCharge";
import { idNendoInChargeUser, NendoInChargeUser } from "./NendoInChargeUser";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoInCharges_";

const model = types
  .model("NendoInCharges", {
    id: prefixedIdType(idPrefix),

    activeDepCode: types.maybe(types.string),
    nendoDepts: types.maybe(types.reference(NendoDepts)),
    nendo: types.number,
    deptsPIC: types.map(types.late(() => NendoInCharge)),
    inSaving: types.optional(types.boolean, false),

    users: types.map(NendoInChargeUser),

    loadingStatus: types.optional(LoadingStatus, "init"),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);
    const getDeptPIC = (depCode: string) => self.deptsPIC.get(idNendoInCharge(self.nendo, depCode));

    const listUsersAtDepts = (targetDepCodes: string[]) => {
      const targetDepCodesMap = keyBy(targetDepCodes, it => it);
      return [...self.users.values()].filter(it => !!targetDepCodesMap[it.depCode || ""]);
    };
    const listUsersAtDeptsHavingCheck = (targetDepCodes: string[]) => {
      return flatMap(targetDepCodes, it => {
        const pic = getDeptPIC(it);
        return pic ? pic.selectedUserIds : [];
      });
    };
    const getUsersFromIds = (userIds: string[]) => {
      const userIdsMap = keyBy(userIds, it => it);
      return [...self.users.values()].filter(it => !!userIdsMap[it.userId]);
    };

    return {
      get hasNoChange() {
        return hasNoChangeReduce(Array.from(self.deptsPIC.values(), it => it.hasNoChange));
      },
      get activeDept() {
        if (!self.nendoDepts || self.activeDepCode === undefined) {
          return undefined;
        }
        return self.nendoDepts.getDept(self.activeDepCode);
      },
      get activeDeptPIC() {
        if (self.activeDepCode === undefined) {
          return undefined;
        }

        return self.deptsPIC.get(idNendoInCharge(self.nendo, self.activeDepCode));
      },
      usersCoveringDept(depCode: string) {
        if (!self.nendoDepts || !self.users) {
          return [];
        }
        const found = self.nendoDepts.getDept(depCode);
        if (!found) {
          return [];
        }

        return getUsersFromIds(listUsersAtDeptsHavingCheck(found.allAncestorDepts.map(it => it.depCode)));
      },
      usersInDept(depCode: string) {
        if (!self.nendoDepts || !self.users) {
          return [];
        }
        const found = self.nendoDepts.getDept(depCode);
        if (!found) {
          return [];
        }

        return listUsersAtDepts([depCode, ...found.allDescendantDepts.map(it => it.depCode)]);
      },
      usersSelected(depCode: string) {
        if (!self.nendoDepts || !self.users) {
          return [];
        }
        const found = self.nendoDepts.getDept(depCode);
        if (!found) {
          return [];
        }

        return getUsersFromIds(listUsersAtDeptsHavingCheck([depCode]));
      },
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
    };
  })
  .actions(self => {
    const users = () => getEnv(self).get(UsersSymbol) as UsersType;
    const usersApi = () => getDI(self, UsersApi);
    const ranks = () => getEnv(self).get(RanksSymbol) as RanksType;
    const depts = () => getEnv(self).get(DeptsSymbol) as DeptsType;
    const deptsApi = () => getDI(self, DeptsApi);
    const appNotifier = () => getDI(self, AppNotifier);

    const load = flow(function*() {
      const { nendo } = self;
      try {
        self.loadingStatus = "loading";
        const nendoDepts: NendoDeptsType = yield depts().prepareAndLoadMostRecent(nendo);
        self.nendoDepts = nendoDepts;
        const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(nendo);

        const usersResult: UsersResult = yield usersApi().getUsers();
        applySnapshot(
          self.users,
          keyBy(
            usersResult.users.map(userResult => {
              const user = users().mergeFromUserEntity(userResult);
              const { userId } = user;
              const rank = user.rankCode && nendoRanks.getRank(user.rankCode);
              const dept = user.depCode && nendoDepts.getDept(user.depCode);
              return {
                id: idNendoInChargeUser(self.nendo, userId),

                nendo,
                userId,

                rank: rank && rank.id,
                dept: dept && dept.id,
                leaveDate: fromApiDate(userResult.leaveDate),

                user: userId,
              };
            }),
            u => u.id,
          ),
        );

        const deptsResult: DeptPersonsInChargeResult = yield deptsApi().getPIC(nendo);

        applySnapshot(
          self.deptsPIC,
          keyBy(
            Array.from(nendoDepts.depts, ([k, dep]) => {
              const { depCode } = dep;
              const depResult = deptsResult.personsInCharge.find(it => it.depCode === depCode);
              const selectedUserIds: string[] = depResult ? depResult.userIds : [];

              return {
                id: idNendoInCharge(self.nendo, depCode),

                nendo: self.nendo,
                depCode,

                selectedUserIds,
                org_selectedUserIds: selectedUserIds,
              };
            }),
            it => it.id,
          ),
        );

        self.loadingStatus = "loaded";
      } catch (exception) {
        self.loadingStatus = "failed";
        throw exception;
      }
    });

    const reset = () => {
      for (const d of self.deptsPIC.values()) {
        d.reset();
      }
    };

    const save = flow(function*() {
      try {
        self.inSaving = true;

        yield deptsApi().savePIC(self.nendo, {
          personsInCharge: [...self.deptsPIC.values()].map(it => ({
            depCode: it.depCode,
            userIds: [...it.selectedUserIds.values()],
          })),
        });

        appNotifier().info({ message: "部門勤怠責任者を保存しました" });
        yield load();
      } catch (exception) {
        appNotifier().error({ message: "部門勤怠責任者の保存に失敗しました", exception });
      } finally {
        self.inSaving = false;
      }
    });

    return {
      loadIfNeeded() {
        return self.loadingStatus === "loaded" ? new Promise(done => done()) : load();
      },
      load,
      setActiveDepCode(value: string) {
        self.activeDepCode = value;
      },
      save,
      reset,
    };
  });

export const idNendoInCharges = (nendo: number) => `${idPrefix}${nendo}`;

export type NendoInChargesType = typeof NendoInCharges.Type;
export const NendoInCharges: NendoInChargesModelType = model;
type NendoInChargesInferredType = typeof model;
export interface NendoInChargesModelType extends NendoInChargesInferredType {}
