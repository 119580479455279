import { observer } from "mobx-react";
import React, { useCallback } from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { EnterLeaveTimeField } from "../common/EnterLeaveTimeField";

export type CkrpLeaveKFProps = {
  model: CkrpLeaveKFVM;
} & CellFormPrintAdaptable;

export interface CkrpLeaveKFVM {
  date: Date;
  ckrpLeaveTime: Date | undefined;
  onApplyCkrpLeave: () => void;
}

export const CkrpLeaveKF = observer((props: CkrpLeaveKFProps) => {
  const { model } = props;
  const { date, ckrpLeaveTime, onApplyCkrpLeave } = model;

  const onClick = useCallback(() => {
    if (onApplyCkrpLeave && ckrpLeaveTime) {
      onApplyCkrpLeave();
    }
  }, [onApplyCkrpLeave, ckrpLeaveTime]);

  return (
    <EnterLeaveTimeField
      {...props}
      {...{ date, onClick }}
      enterLeaveTime={ckrpLeaveTime}
      label="ここレポ退勤"
      disabled={!ckrpLeaveTime}
    />
  );
});
