import { Container } from "inversify";

import { AppStateResolver } from "./models/AppStateResolver";
import { WHEN_ON_NAVIGATE_SYMBOL } from "./services/AppRouter";

// declare interface Window {
//   webkintai_container: Container | null;
// }

export function createContainer(appStateResolver: AppStateResolver): Container {
  const container = new Container();
  console.log("Container recreated", container);

  // サービス系
  const allServices = require.context("./services", true, /\.inversify.ts/);
  allServices.keys().forEach(key => allServices(key).register(container));

  // モデル・シングルトン系
  const allSingletons = require.context("./models", true, /\.inversify.ts/);
  allSingletons.keys().forEach(key => allSingletons(key).registerSingleton(container, appStateResolver));
  container.bind(WHEN_ON_NAVIGATE_SYMBOL).toConstantValue((path: string) => appStateResolver().onNavigate(path));

  (window as any).webkintai_container = container;

  return container;
}
