import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { ExportPageModel, ExportPageModelSymbol } from "../../../models/export/ExportPageModel";
import { ExportPageVM } from "./ExportPage";
import { ExportPageRibbonVM } from "./ribbon/ExportPageRibbon";
import { ExportPageTableVM } from "./table/ExportPageTable";
import { ExportPageTableVMImpl } from "./table/ExportPageTableRowVMImpl";

export class ExportPageVMImpl implements ExportPageVM, ExportPageRibbonVM, ExportPageTableVM {
  @lazyInject(ExportPageModelSymbol)
  private model: typeof ExportPageModel.Type;

  public get ribbonModel() {
    return this;
  }
  public get tableModel() {
    return this;
  }

  @computed
  public get rows() {
    return this.model.filteredRows.map(it => new ExportPageTableVMImpl(it));
  }

  @computed
  get loadingStatus() {
    return this.model.loadingStatus;
  }

  @computed
  get targetMonth() {
    return this.model.targetMonth;
  }

  @action.bound
  public onChangeTargetMonth(value: Date) {
    this.model.navigateToMonth(value);
  }

  @computed
  get nendoList() {
    return this.model.nendoList;
  }

  @computed
  get filterText() {
    return this.model.filterText;
  }
  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }
}
