import { IModelType, types } from "mobx-state-tree";

import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { typesDays } from "../primitives/Days";
import { typesHourBasedDays } from "../primitives/HourBasedDays";
import { typesMinutes } from "../primitives/Minutes";
import { prefixedIdType } from "../utils";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "ExportEntry_";

const model = types
  .model("ExportEntry", {
    id: prefixedIdType(idPrefix),
    userId: types.string,
    targetMonth: types.Date,

    userName: types.string,
    companyCode: types.string,
    salaryCalcGroup: types.string,
    processingType: types.string,
    salaryMonth: types.maybe(types.Date),
    paymentDate: types.maybe(types.Date),

    normalDays: types.maybe(typesHourBasedDays),
    sickoutDays: types.maybe(typesDays),
    compAndAllNightOffDays: types.maybe(typesDays),

    paidOffDays: types.maybe(typesHourBasedDays),
    spDays: types.maybe(typesHourBasedDays),
    refreshDays: types.maybe(typesDays),
    positiveDays: types.maybe(typesDays),
    summerDays: types.maybe(typesDays),

    off: types.maybe(typesMinutes),
    midnight: types.maybe(typesMinutes),
    holiday: types.maybe(typesMinutes),

    liquidateSum: types.maybe(typesMinutes),

    spWeekday: types.maybe(typesMinutes),
    spWeekdayMidnight: types.maybe(typesMinutes),
    spHoliday: types.maybe(typesMinutes),
    spHolidayMidnight: types.maybe(typesMinutes),

    overSixtyFlexHoliday: types.maybe(typesMinutes),
    overSixtyLiquidate: types.maybe(typesMinutes),
    allNightOff: types.maybe(typesMinutes),

    hourlyLeave: types.maybe(typesHourBasedDays),
    hourlyNursingcare: types.maybe(typesHourBasedDays),
    hourlyFamilycare: types.maybe(typesHourBasedDays),

    workplaceZaitakuDays: types.maybe(typesHourBasedDays),
    workplaceSyussyaDays: types.maybe(typesHourBasedDays),
  })
  .views(self => {
    return {
      get filteredTexts() {
        return [self.userName, self.userId];
      },
    };
  })
  .views(self => {
    return {
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(self.filteredTexts, searchWords);
      },
    };
  });

export const idExportEntry = (targetMonth: Date, userId: string) => `${idPrefix}${userId}_${targetMonth.toISOString()}`;

export type ExportEntryType = typeof ExportEntry.Type;
export const ExportEntry: ExportEntryModelType = model;
type ExportEntryInferredType = typeof model;
export interface ExportEntryModelType extends ExportEntryInferredType {}
