import { types } from "mobx-state-tree";

import { getNendoMonthOf } from "../../../utils/calendar";
import { dateEq } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { prefixedIdType } from "../../utils";
import { NendoMonthCalendar } from "../NendoMonthCalendar";
import { NendoDayCalendarInput } from "./NendoDayCalendarInput";

export const idPrefix = "NendoMonthCalendar_";

const model = types
  .model("NendoMonthCalendarInput", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    month: types.number,
    allowReviseWorkDept: types.optional(types.boolean, false),
    days: types.array(NendoDayCalendarInput),
    origin: types.reference(NendoMonthCalendar),
  })
  .views(self => ({
    get holidayCount() {
      return self.days.reduce((l, r) => l + (r.isHoliday ? 1 : 0), 0);
    },
    get workdayCount() {
      return self.days.reduce((l, r) => l + (r.isHoliday ? 0 : 1), 0);
    },
    get hasNoChange() {
      return hasNoChangeReduce(self.days.map(day => day.hasNoChange));
    },
    get monthDate() {
      return getNendoMonthOf(self.nendo, self.month);
    },
    get showsLastWorkingDayOption() {
      return self.month === 12;
    },
    dayAt(day: number) {
      return self.days[day - 1];
    },
  }))
  .actions(self => ({
    setIsFinalDay(date: Date, value: boolean) {
      if (!self.showsLastWorkingDayOption) {
        return;
      }

      self.days.forEach(day => day.setIsFinalDay(dateEq(date, day.date) ? value : false));
    },
    setDerivesLastYearDepts(value: boolean) {
      self.allowReviseWorkDept = value;
    },
  }));

export type NendoMonthCalendarInputType = typeof NendoMonthCalendarInput.Type;
export const NendoMonthCalendarInput: NendoMonthCalendarInputModelType = model;
type NendoMonthCalendarInputInferredType = typeof model;
export interface NendoMonthCalendarInputModelType extends NendoMonthCalendarInputInferredType {}
