import { HourBasedDays } from "@webkintai/core";
import { flatMap } from "lodash-es";
import { observer } from "mobx-react";
import React from "react";

import { GridSize } from "../../../common/table/GridSize";
import { GridTable } from "../../../common/table/GridTable";
import { RowIdGenerator, TableColumnDef } from "../../../common/table/TableColumnDef";
import { PHChangedCell } from "./PHChangedCell";
import { PHDayCountCell } from "./PHDayCountCell";
import { PHDayCountDisplayCell } from "./PHDayCountDisplayCell";
import { PHDeptNameCell } from "./PHDeptNameCell";
import { PHRankNameCell } from "./PHRankNameCell";
import { PHUserIdCell } from "./PHUserIdCell";
import { PHUserNameCell } from "./PHUserNameCell";

export interface PHPageGridVM {
  rows: PHPagGridRowVM[];
  targetNendos: number[];
}

export interface PHPagGridRowVM {
  userId: string;
  userName: string;
  deptName: string;
  rankName: string;
  perYearVacations: PHPerYearVacationVM[];
  hasNoChange: boolean;
}

export interface PHPerYearVacationVM {
  hours: HourBasedDays | undefined;
  disabled: boolean;
  onChangeHours: (value: HourBasedDays | undefined) => void;
  hasNoChange: boolean;
}

@observer
export class PHPageGrid extends React.Component<{ model: PHPageGridVM }> {
  public render() {
    const { model } = this.props;
    const { rows } = model;
    return (
      <GridTable
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowIdGenerator={this.rowIdGenerator}
        rowCount={rows.length}
      />
    );
  }

  private rowIdGenerator: RowIdGenerator = rowIndex => {
    const { rows } = this.props.model;
    const row = rows[rowIndex - 1];
    return row.userId;
  };

  public get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { rows } = model;

    return [
      {
        id: "変更",
        width: GridSize.changesHereField,
        renderHeader: () => "変更",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <PHChangedCell model={row} />;
        },
      },
      {
        id: "社員番号",
        width: GridSize.userIdField,
        renderHeader: () => "社員番号",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <PHUserIdCell model={row} />;
        },
      },
    ];
  }

  public get bodyColumns(): TableColumnDef[] {
    const { model } = this.props;
    const { rows } = model;

    return [
      {
        id: "氏名",
        width: GridSize.userNameField,
        renderHeader: () => "氏名",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <PHUserNameCell model={row} />;
        },
      },
      {
        id: "所属",
        width: GridSize.deptNameField,
        renderHeader: () => "所属",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <PHDeptNameCell model={row} />;
        },
      },
      {
        id: "ランク",
        width: GridSize.rankNameField,
        renderHeader: () => "ランク",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <PHRankNameCell model={row} />;
        },
      },
      ...flatMap(model.targetNendos, (nendo, i) => {
        return [
          {
            id: `${nendo}`,
            width: GridSize.hourCountField,
            renderHeader: () => (
              <>
                {nendo}
                <br />
                時間
                <br />
                入力
              </>
            ),
            renderBody: ({ rowIndex }) => {
              const row = rows[rowIndex - 1];
              return <PHDayCountCell model={row} col={i} />;
            },
          },
          {
            id: `${nendo}(日)`,
            width: GridSize.dayCountField,
            renderHeader: () => (
              <>
                <br />
                日換算
              </>
            ),
            renderBody: ({ rowIndex }) => {
              const row = rows[rowIndex - 1];
              return <PHDayCountDisplayCell model={row} col={i} />;
            },
          },
        ] as TableColumnDef[];
      }),
    ];
  }
}
