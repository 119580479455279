import { x } from "./GridTable";

export const GridSize = {
  rowNumberField: x("XXXX"),
  changesHereField: x("xx"),
  userIdField: x("xxxxxxxxxx"),
  userNameField: x("xxxxxx  xxxxxx"),
  rankCodeField: x("xxxx"),
  rankNameField: x("XXXXXXXXXXXXXXXXX"),
  deptNameField: x("XXXXXXXXXXXXXXXXXXXXXXXXXXX"),
  workingTypeField: x("FLEXZZZZXX"),
  positionTypeField: x("FLEXZZZZXX"),
  roleNameField: x("FLEXZZZZXX"),
  mailAddressField: x("XXXXXXX@shinwart.co.jp"),
  dateAndDayNameField: x("XX (XX)"),
  dayCountField: x("xx.xxx"),
  hourlyLeaveField: x("0.125"),
  hourCountField: 3 * x("XX"),
  remarksField: x("XXXXXXXXXXXXXXXXXXXXXXXXXXX"),
  unusedRestField: x("XXXXX"),
  workplaceField:  x("XXXXX"),
  hhmmField: x("XXX:XX"),
  hhmmTotalField: x("XXXX:XX"),
  typeField: x("XXXXXXXX"),
  appStatus: x("XXXXXXXX"),
  yyyyMMDDField: x("YYYY/MM/DD"),
  numericCountField: 3.5 * x("XX"),
  warningCountField: 3 * x("XX"),
};
