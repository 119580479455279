import { Button, Classes, InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { inputStringHandler } from "../../../utils/react";
import { SubMenuButton } from "../../common/page/SubMenuButton";

export interface KintaiUserSelectorVM {
  onNavigateToMyKintai: () => void;
  kintaiUserList: KintaiListEntry[];
  filterText: string;
  onFilterTextChange: (filterText: string) => void;
  openByFilterTextDisabled: boolean;
  onOpenFilterText: () => void;
}

export interface KintaiListEntry {
  userId: string;
  userName: string;
  onNavigate: () => void;
}

const Container = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: column;
  padding: 0.2ex 0.2ex 1ex 0.2ex;
`;

const Heading = styled.h3`
  font-size: 12pt;
  text-align: center;
  margin: 1ex 0 0 0;
  padding: 1em 0 1ex 0;
  border-top: 1px solid #eee;
`;

const Filter = styled(InputGroup)`
  flex-basis: auto;
`;

const Users = styled.div`
  flex: 1 auto;

  padding-top: 1ex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const UserEntry = styled(SubMenuButton)`
  flex: 1 auto;
`;

const UserId = styled.span`
  display: block;
  color: #999;
  font-size: 80%;
`;

const UserName = styled.span`
  display: block;
`;

const FilterAndOpenSection = styled.form`
  display: flex;
  flex-direction: row;
`;

@observer
export class KintaiUserSelector extends React.Component<{
  model: KintaiUserSelectorVM;
}> {
  private onChange = inputStringHandler(this.props.model.onFilterTextChange);

  public render() {
    const { model } = this.props;

    return (
      <Container>
        <UserEntry text="自分の勤怠を開く" onClick={model.onNavigateToMyKintai} className={Classes.POPOVER_DISMISS} />

        <Heading>勤怠表オープン履歴</Heading>

        <FilterAndOpenSection action="javascript: undefined" onSubmit={model.onOpenFilterText}>
          <Filter
            leftIcon="filter"
            placeholder="絞り込み or 社員番号"
            value={model.filterText}
            onChange={this.onChange}
          />
          <Button text="開く" disabled={model.openByFilterTextDisabled} onClick={model.onOpenFilterText} />
        </FilterAndOpenSection>

        <Users>
          {model.kintaiUserList.map(user => (
            <UserEntry key={user.userId} onClick={user.onNavigate} className={Classes.POPOVER_DISMISS}>
              <UserId>{user.userId}</UserId>
              <UserName>{user.userName}</UserName>
            </UserEntry>
          ))}
        </Users>
      </Container>
    );
  }
}
