import { KintaiDepartmentCloseResult, KintaiDepartmentCloseStatusResult } from "@webkintai/api";
import { flow, IModelType, types } from "mobx-state-tree";

import { DeptsApi } from "../../services/api/DeptsApi";
import { AppConfirm } from "../../services/msg/AppConfirm";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { fromApiDate } from "../../utils/api";
import { compareMonth } from "../../utils/date";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptKintaiCloseAvailableOps } from "./DeptKintaiCloseAvailableOps";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("DeptKintaiCloseEntry", {
    depCode: types.identifier,

    closedMonth: types.maybe(types.Date),
    loadingState: types.optional(LoadingStatus, "loading"),
  })
  .views(self => {
    return {
      isClosedForMonth(month: Date) {
        if (self.loadingState !== "loaded") {
          return true;
        }

        if (!self.closedMonth) {
          return false;
        }

        return compareMonth(month, self.closedMonth, (l, r) => l.getTime() <= r.getTime());
      },
      getAvailableOpForMonth(month: Date): DeptKintaiCloseAvailableOps {
        if (self.loadingState !== "loaded") {
          return "no_touch";
        }

        // 何もない場合は締め操作が可能
        if (!self.closedMonth) {
          return "close";
        }

        return compareMonth(month, self.closedMonth, (l, r) => {
          const forM = l.getTime();
          const closedMonth = r.getTime();

          // 未来月ならclose可能
          if (forM > closedMonth) {
            return "close";
          }

          // 同月なら再度開くことが可能
          if (forM === closedMonth) {
            return "reopen";
          }

          return "no_touch";
        });
      },
    };
  })
  .actions(self => {
    const deptsApi = () => getDI(self, DeptsApi);
    const appConfirm = () => getDI(self, AppConfirm);
    const appNotifier = () => getDI(self, AppNotifier);

    const loadIfNeeded = () => {
      return self.loadingState === "loaded" ? new Promise(done => done()) : load();
    };

    const load = flow(function*() {
      self.loadingState = "loading";
      const result = (yield deptsApi().getDepartmentReportsCloseStatus(
        self.depCode,
      )) as KintaiDepartmentCloseStatusResult;
      self.closedMonth = fromApiDate(result.closedMonth);
      self.loadingState = "loaded";
    });

    const closeDept = flow(function*(targetMonth: Date) {
      const confirmResult: boolean = yield appConfirm().confirm({
        title: "部門勤怠締めの確認",
        message: `選択中の部門（${self.depCode}）について月の勤怠を締めます。（締める時に、該当の月について、全て事務の確認（印）が完了している必要があります。）`,
      });
      if (!confirmResult) {
        return;
      }

      try {
        self.loadingState = "loading";
        const result: KintaiDepartmentCloseResult = yield deptsApi().closeKintaiReports(
          self.depCode,
          targetMonth.getFullYear(),
          targetMonth.getMonth() + 1,
        );
        if (result.status === "success") {
          appNotifier().info({ message: "部門勤怠の締めを実施しました" });
        } else {
          appNotifier().error({
            message: `部門勤怠の締めに失敗しました。${
              result.unapprovedUsers.length
                ? `次のユーザの勤怠の事務スタンプがついていません: ${result.unapprovedUsers
                    .map(it => `${it.userName}(${it.userId})`)
                    .join("、")}`
                : ""
            }`,
          });
        }
        load();
      } catch (exception) {
        appNotifier().error({ message: "部門勤怠の締めに失敗しました", exception });
      } finally {
        self.loadingState = "loaded";
      }
    });

    const reopenDept = flow(function*(targetMonth: Date) {
      const confirmResult: boolean = yield appConfirm().confirm({
        title: "部門勤怠締めキャンセルの確認",
        message: `選択中の部門（${self.depCode}）について月の勤怠を再度開きます。前月まで承認済みとします。`,
      });
      if (!confirmResult) {
        return;
      }

      try {
        self.loadingState = "loading";

        yield deptsApi().reopenKintaiReports(self.depCode, targetMonth.getFullYear(), targetMonth.getMonth() + 1);
        load();
        appNotifier().info({ message: "部門勤怠の締めをキャンセルしました" });
      } catch (exception) {
        appNotifier().error({ message: "部門勤怠の締めのキャンセルに失敗しました", exception });
      } finally {
        self.loadingState = "loaded";
      }
    });
    return {
      load,
      loadIfNeeded,
      closeDept,
      reopenDept,
    };
  });
export const DeptKintaiCloseEntry: DeptKintaiCloseEntryModelType = model;
type DeptKintaiCloseEntryInferredType = typeof model;
export interface DeptKintaiCloseEntryModelType extends DeptKintaiCloseEntryInferredType {}
