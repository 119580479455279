import styled from "@emotion/styled";
import React from "react";

export const WkTable = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #ccc;
    text-align: center;
  }

  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  th {
    background-color: #efe;
  }
`;

export interface WkTdProps {
  warned?: boolean;
}

export const WkTd: React.FunctionComponent<WkTdProps> = props => (
  <td style={props.warned ? { backgroundColor: "#fee" } : undefined}>{props.children}</td>
);

export const WkTotalFooterTr = styled.tr`
  background-color: rgba(230, 240, 255, 0.8);

  td {
    font-weight: bold;
  }
`;

export const WkAverageFooterTr = styled.tr`
  background-color: rgba(255, 255, 230, 0.8);

  td {
    font-weight: bold;
  }
`;
