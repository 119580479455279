import { flatMap } from "lodash-es";
import { computed } from "mobx";
import { observer } from "mobx-react";
import React from "react";

import { CellPosition } from "../../../common/table/CellPosition";
import { GridTable, x } from "../../../common/table/GridTable";
import { MoveDelta } from "../../../common/table/MoveDelta";
import { TableColumnDef } from "../../../common/table/TableColumnDef";
import { CalendarDateRowNumber } from "./CalendarDateRowNumber";
import { CalendarFinalDayBody, CalendarFinalDayHeader } from "./CalendarFinalDay";
import { CalendarHolidayBody, CalendarHolidayHeader } from "./CalendarHoliday";
import { CalendarWeekdayBody, CalendarWeekdayHeader } from "./CalendarWeekday";

export interface CalendarPageGridVM {
  activeCell: CellPosition;
  months: CalendarPageGridMonthVM[];
  onMoveCell: (delta: MoveDelta) => void;
  onCellFocused: (cellPosition: CellPosition) => void;
}

export interface CalendarPageGridMonthVM {
  month: Date;
  hasNoChange: boolean;
  disabled: boolean;
  showsLastWorkingDayOption: boolean;
  deptsDerivationOption: boolean;
  dayAt: (day: number) => CalendarPageGridDayVM | undefined;
  onChangeDeptsDerivationOption: (value: boolean) => void;
}

export interface CalendarPageGridDayVM {
  date: Date;
  hasNoChange: boolean;
  disabled: boolean;
  isActive: boolean;
  isHoliday: boolean;
  onChangeIsHoliday: (value: boolean) => void;
  isFinalDay: boolean;
  onChangeIsFinalDay: (value: boolean) => void;
}

@observer
export class CalendarPageGrid extends React.Component<{ model: CalendarPageGridVM }> {
  public render() {
    const { model } = this.props;
    return (
      <GridTable
        key={model.months.length ? model.months[0].month.toString() : 0}
        hideCount={true}
        onMoveCell={model.onMoveCell}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        activeCell={model.activeCell}
        renderFooter={false}
        rowCount={31}
      />
    );
  }

  @computed
  private get headerColumns() {
    const { model } = this.props;
    return [
      {
        id: "日付",
        width: x("xxxxx"),
        renderHeader: () => "日付",
        renderBody: (cellPosition: CellPosition, isActive: boolean) => (
          <CalendarDateRowNumber cellPosition={cellPosition} onCellFocused={model.onCellFocused} isActive={isActive} />
        ),
      },
    ] as TableColumnDef[];
  }

  @computed
  private get bodyColumns() {
    const { model } = this.props;
    return [
      ...flatMap(model.months, month => [
        ...[
          {
            id: `${month.month.getTime()}_曜日`,
            width: x("XXXX"),
            renderHeader: (cellPosition: CellPosition) => (
              <CalendarWeekdayHeader cellPosition={cellPosition} month={month} />
            ),
            renderBody: (cellPosition: CellPosition, isActive: boolean) => (
              <CalendarWeekdayBody
                cellPosition={cellPosition}
                onCellFocused={model.onCellFocused}
                isActive={isActive}
                month={month}
              />
            ),
          },
          {
            id: `${month.month.getTime()}_公休`,
            width: x("XXXX"),
            renderHeader: (cellPosition: CellPosition) => (
              <CalendarHolidayHeader cellPosition={cellPosition} month={month} />
            ),
            renderBody: (cellPosition: CellPosition, isActive: boolean) => {
              return (
                <CalendarHolidayBody
                  cellPosition={cellPosition}
                  onCellFocused={model.onCellFocused}
                  month={month}
                  isActive={isActive}
                />
              );
            },
          },
        ],
        ...(month.showsLastWorkingDayOption
          ? [
              {
                id: `${month.month.getTime()}_最終出勤日`,
                width: x("最終出勤日"),
                renderHeader: (cellPosition: CellPosition) => (
                  <CalendarFinalDayHeader cellPosition={cellPosition} month={month} />
                ),
                renderBody: (cellPosition: CellPosition, isActive: boolean) => (
                  <CalendarFinalDayBody
                    cellPosition={cellPosition}
                    onCellFocused={model.onCellFocused}
                    isActive={isActive}
                    month={month}
                  />
                ),
              },
            ]
          : []),
      ]),
    ] as TableColumnDef[];
  }
}
