import { lazyInject } from "../../didecorators";
import { ProfilePageModel, ProfilePageModelSymbol } from "../../models/profile/ProfilePageModel";
import { ProfileSubMenuVMImpl } from "./header/ProfileSubMenuVMImpl";
import { InboxPageVMImpl } from "./inbox/InboxPageVMImpl";
import { ProfileInterviewPageVMImpl } from "./interview/ProfileInterviewPageVMImpl";
import { ProfilePageVM } from "./ProfilePage";
import { SettingsPageVMImpl } from "./settings/SettingsPageVMImpl";

export class ProfilePageVMImpl implements ProfilePageVM {
  public profileSubMenuVM = new ProfileSubMenuVMImpl();
  public inboxPageVM = new InboxPageVMImpl();
  public settingsPageVM = new SettingsPageVMImpl();
  public profileInterviewPageVM = new ProfileInterviewPageVMImpl();

  @lazyInject(ProfilePageModelSymbol)
  private profilePageModel: typeof ProfilePageModel.Type;

  public get pageMode() {
    return this.profilePageModel.pageMode;
  }
}
