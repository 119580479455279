import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";

export type AppApproveButtonKFProps = {
  model: AppApproveButtonKFVM;
} & CellFormPrintAdaptable;

export interface AppApproveButtonKFVM {
  onAppApprove: () => void;
  approveDisabled: boolean;
}

export const AppApproveButtonKF = observer((props: AppApproveButtonKFProps) => {
  const { model } = props;

  return (
    <Button disabled={model.approveDisabled} onClick={model.onAppApprove} intent={Intent.PRIMARY} text="承認する" />
  );
});
