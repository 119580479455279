import { HourBasedDays } from "@webkintai/core";
import { IModelType, types } from "mobx-state-tree";

import { typesDays } from "../../primitives/Days";
import { typesHourBasedDays } from "../../primitives/HourBasedDays";
import { typesMinutes } from "../../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("SPortMonthlyTotalValues", {
    absenceDays: typesDays,
    actual: typesMinutes,
    allNightCount: types.number,
    allNightOffDays: typesDays,
    careDays: typesDays,
    childcareDays: typesDays,
    compDays: typesDays,
    diff: typesMinutes,
    hourlyLeave: typesHourBasedDays,
    hourlyNursingcare: typesHourBasedDays,
    hourlyFamilycare: typesHourBasedDays,
    familycareDays: typesHourBasedDays,
    givenDays: typesDays,
    givenHours: typesMinutes,
    givenWork: typesMinutes,
    holidayDays: typesDays,
    immunity: typesMinutes,
    injuriesDays: typesDays,
    judgmentDays: typesDays,
    maternityDays: typesDays,
    menstruationDays: typesDays,
    midnight: typesMinutes,
    missed: typesMinutes,
    nonAttendanceDays: typesDays,
    normalDays: typesHourBasedDays,
    nursingcareDays: typesHourBasedDays,
    offCount: types.number,
    overDiff: typesMinutes,
    overHoliday: typesMinutes,
    overHolidayMidnight: typesMinutes,
    overRestDay: typesMinutes,
    overRestMidnight: typesMinutes,
    over: typesMinutes,
    overWeekday: typesMinutes,
    overWeekdayMidnight: typesMinutes,
    paidAllDays: typesDays,
    paidHalfDays: typesHourBasedDays,
    plainPaidAllDays: typesDays,
    plainPaidHalfDays: typesHourBasedDays,
    positiveDays: typesDays,
    rest: typesMinutes,
    sickoutDays: typesDays,
    spDays: typesHourBasedDays,
    summerDays: typesDays,
    suspensionDays: typesDays,
    time: typesMinutes,
    transOffDays: typesDays,
    transOnDays: typesDays,
  })
  .views(self => {
    return {
      /** 休業日数 */
      get leaveDays() {
        return self.absenceDays
          .add(self.suspensionDays)
          .add(self.childcareDays)
          .add(self.careDays)
          .add(self.maternityDays);
      },
      /** 有休の集計値 */
      get paidDays() {
        return self.paidHalfDays.addDays(self.paidAllDays);
      },
      get plainPaidDays() {
        return self.plainPaidHalfDays.addDays(self.plainPaidAllDays);
      },
      /** その他休暇の集計値 */
      get otherDays() {
        return new HourBasedDays(0)
          .add(self.spDays)
          .addDays(self.menstruationDays)
          .add(self.nursingcareDays)
          .add(self.familycareDays)
          .addDays(self.judgmentDays)
          .addDays(self.injuriesDays);
      },
      /** その他休暇(介護・看護除く)の集計値 */
      get otherDaysExcludingNuFa() {
        return new HourBasedDays(0)
          .add(self.spDays)
          .addDays(self.menstruationDays)
          .addDays(self.judgmentDays)
          .addDays(self.injuriesDays);
      },
      /** その他休暇(時間休)の集計値 */
      get otherHours() {
        return new HourBasedDays(0).add(self.hourlyNursingcare).add(self.hourlyFamilycare);
      },
    };
  });

export type SPortMonthlyTotalValuesType = typeof SPortMonthlyTotalValues.Type;
export const SPortMonthlyTotalValues: SPortMonthlyTotalValuesModelType = model;
type SPortMonthlyTotalValuesInferredType = typeof model;
export interface SPortMonthlyTotalValuesModelType extends SPortMonthlyTotalValuesInferredType {}
