import { observer } from "mobx-react";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { InboxPageGridRowVM, inboxRowStyle } from "./InboxPageGrid";

@observer
export class InboxSubjectCell extends React.Component<{ model: InboxPageGridRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <LabelField
        mode="cell"
        style={inboxRowStyle(row)}
        cellPosition={NULL_CELL_POSITION}
        alignment="left"
        onClick={row.onOpenMessage}>
        {row.subject}
      </LabelField>
    );
  }
}
