import { max, min } from "lodash-es";
import { Instance, types } from "mobx-state-tree";

import { dateEq } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { LeaveKind, LeaveKindType } from "../LeaveKind";

export const model = types
  .model("InputAccountAbsence", {
    type: types.maybe(LeaveKind),
    from: types.maybe(types.Date),
    to: types.maybe(types.Date),
    remarks: "",
  })
  .views(self => {
    return {
      get inputDisabled() {
        return self.type === undefined;
      },
      get errors() {
        if (self.type === undefined) {
          return ["休業種別は必須です"];
        }
        if (self.from === undefined) {
          return ["開始日は必須です"];
        }
        if (self.to === undefined) {
          return ["終了日は必須です"];
        }
        return [];
      },
    };
  })
  .actions(self => {
    const setAbsentOrderings = () => {
      if (self.from && self.to) {
        const from = min([self.from, self.to]);
        const to = max([self.from, self.to]);
        self.from = from;
        self.to = to;
      }
    };

    return {
      setType(value: LeaveKindType | undefined) {
        self.type = value;
        setAbsentOrderings();
      },
      setFrom(value: Date | undefined) {
        self.from = value;
        setAbsentOrderings();
      },
      setTo(value: Date | undefined) {
        self.to = value;
        setAbsentOrderings();
      },
      setRemarks(value: string) {
        self.remarks = value;
      },
    };
  });

export const eqInputAccountAbsence = (l: InputAccountAbsenceInstance, r: InputAccountAbsenceInstance) => {
  return hasNoChangeReduce([l.type === r.type, dateEq(l.from, r.from), dateEq(l.to, r.to), l.remarks === r.remarks]);
};

export const InputAccountAbsence: InputAccountAbsenceModelType = model;
type InputAccountAbsence_InferredType = typeof model;
export interface InputAccountAbsenceModelType extends InputAccountAbsence_InferredType {}
type InputAccountAbsenceIIf = Instance<typeof InputAccountAbsence>;
export interface InputAccountAbsenceInstance extends InputAccountAbsenceIIf {}
