import { Minutes } from "@webkintai/core";
import { flatMap, range } from "lodash-es";
import { types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../../utils/model";
import { kintaiErrorDateFormat } from "../utils/KintaiErrorMessage";
import { reduceMinutes } from "./PJReportDailyCell";
import { PJReportPillarDay } from "./PJReportPillarDay";
import { PJReportPillarHeader } from "./PJReportPillarHeader";

const model = types
  .model("PJReportPillar", {
    id: types.identifier,

    persisted: types.boolean,
    markDeleted: types.optional(types.boolean, false),
    header: PJReportPillarHeader,
    days: types.array(PJReportPillarDay),
  })
  .views(self => {
    return {
      get totalErrors() {
        return [
          ...self.header.errors.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.errors.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },
      get totalWarnings() {
        return [
          ...self.header.warnings.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.warnings.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },
      get totalInfos() {
        return [
          ...self.header.infos.map(it => `【月報】 ${it}`),
          ...flatMap(self.days, d => d.infos.map(it => `【月報 | ${kintaiErrorDateFormat(d.date)}】 ${it}`)),
        ];
      },
      getAt(date: Date) {
        return self.days[date.getDate() - 1];
      },
      get hasNoChanges() {
        if (self.markDeleted) {
          return false;
        }
        if (!self.persisted) {
          return false;
        }

        return hasNoChangeReduce([self.header.hasNoChange, ...self.days.map(it => it.hasNoChange)]);
      },
      get totalCells(): Array<Minutes | undefined> {
        return range(0, 3).map(idx => {
          const verticalCells = self.days.map(it => it.input[idx]!.time);
          return reduceMinutes(verticalCells);
        });
      },
    };
  })
  .views(self => {
    return {
      get total(): Minutes | undefined {
        return reduceMinutes(self.totalCells);
      },
    };
  })
  .actions(self => {
    return {
      setMarkDeleted(value: boolean = true) {
        self.markDeleted = value;
      },
      remove() {
        self.markDeleted = true;
      },
      reset() {
        self.header.reset();
        self.days.forEach(d => {
          d.reset();
        });
        self.markDeleted = false;
      },
    };
  });
export const PJReportPillar: PJReportPillarModelType = model;
type PJReportPillarInferredType = typeof model;
export interface PJReportPillarModelType extends PJReportPillarInferredType {}
