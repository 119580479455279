import { observer } from "mobx-react";
import React from "react";

import { TextField } from "../../../common/form/TextField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcUserNameCellVM {
  userName: string;
  userNameHasNoChange: boolean;
  onChangeUserName: (value: string) => void;
}

@observer
export class AcUserNameCell extends React.Component<{ model: AcUserNameCellVM }> {
  public render() {
    const { model } = this.props;
    return (
      <TextField
        mode="cell"
        value={model.userName}
        onChange={model.onChangeUserName}
        hasNoChange={model.userNameHasNoChange}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
