import { sprintf } from "sprintf-js";

export function recognizeAsHour(text: string): number | undefined {
  if (!text.match(/\d/)) {
    return undefined;
  }

  text = text.replace(/[^0-9\.:]/g, "");

  if (text.match(/^\d*\.\d*$/)) {
    return Math.floor(+text * 60);
  }

  if (text.match(/^\d*:\d*$/)) {
    const [maybeHour, maybeMin] = text.split(":");
    return +maybeHour * 60 + (+maybeMin || 0);
  }

  const numericOnly = text.replace(/\D/g, "");
  if (numericOnly.length <= 2) {
    return +numericOnly * 60;
  }

  const [_, hour, min] = numericOnly.match(/^(\d+)(\d\d)$/)!;
  return +hour * 60 + +min;
}

export function minAsHourText(minutes: undefined | number, hhZeroFill = false, decimal = false) {
  if (minutes === undefined) {
    return "";
  }

  const absMin = Math.abs(minutes);

  return (
    (minutes < 0 ? "-" : "") +
    (decimal
      ? `${Math.round((absMin / 60) * 1000) / 1000}`
      : sprintf(`${hhZeroFill ? "%02d" : "%d"}:%02d`, absMin / 60, absMin % 60))
  );
}
