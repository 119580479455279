export const RESPONSIVE_LARGE_SCREEN_WIDTH = 1024;
export const RESPONSIVE_MIDDLE_SCREEN_WIDTH = 667;

export enum ResponsiveSize {
  "LargeScreen",
  "MiddleScreen",
  "SmallScreen",
}

export function getResponsiveSize(windowWidth: number) {
  if (windowWidth >= RESPONSIVE_LARGE_SCREEN_WIDTH) {
    return "LargeScreen";
  }

  if (windowWidth >= RESPONSIVE_MIDDLE_SCREEN_WIDTH) {
    return "MiddleScreen";
  }

  return "SmallScreen";
}
