import { CalendarResult } from "@webkintai/api";
import { flatMap } from "lodash-es";
import { applySnapshot, flow, types } from "mobx-state-tree";

import { CalendarApi } from "../../services/api/CalendarApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { dateEq } from "../../utils/date";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { prefixedIdType } from "../utils";
import { idNendoDayCalendar } from "./NendoDayCalendar";
import { idNendoMonthCalendar, NendoMonthCalendar } from "./NendoMonthCalendar";

export const idPrefix = "NendoCalendar_";

const model = types
  .model("NendoCalendar", {
    id: prefixedIdType(idPrefix),
    loadingStatus: types.optional(LoadingStatus, "loading"),
    nendo: types.number,
    months: types.array(NendoMonthCalendar),
    summerHolidays: types.optional(types.number, 0),
    positiveHolidays: types.optional(types.number, 0),
  })
  .views(self => ({
    get finalDay() {
      const finalDay = flatMap(self.months.map(m => m.days.filter(d => d.isFinalDay)))[0];
      return finalDay;
    },
  }))
  .actions(self => {
    const calendarApi = () => getDI(self, CalendarApi);
    const appNotifier = () => getDI(self, AppNotifier);

    return {
      loadIfNeeded() {
        return self.loadingStatus === "loaded" ? new Promise(done => done()) : this.load();
      },
      load: flow(function*() {
        try {
          self.loadingStatus = "loading";
          const { calendar, status }: CalendarResult = yield calendarApi().getNendoCalendar(self.nendo);

          const { vacation, lastDay } = calendar;
          if (vacation) {
            self.summerHolidays = calendar.vacation.summer.hours / 8;
            self.positiveHolidays = calendar.vacation.positive.hours / 8;
          } else {
            self.summerHolidays = 0;
            self.positiveHolidays = 0;
          }
          const lastDayDate = lastDay ? new Date(lastDay) : null;

          applySnapshot(
            self.months,
            calendar.months.map(m => {
              const month = new Date(m.ymd).getMonth() + 1;
              return {
                id: idNendoMonthCalendar(self.nendo, month),
                nendo: self.nendo,
                month,
                allowReviseWorkDept: m.allowReviseWorkDept,
                days: m.dates.map(d => {
                  const dayDate = new Date(d.ymd);
                  const day = dayDate.getDate();
                  return {
                    id: idNendoDayCalendar(self.nendo, month, day),
                    nendo: self.nendo,
                    month,
                    day,
                    isHoliday: d.holiday,
                    isFinalDay: !!(lastDayDate && dateEq(lastDayDate, dayDate)),
                  };
                }),
              };
            }),
          );

          self.loadingStatus = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "カレンダーのロード中にエラーが発生しました", exception });
          self.loadingStatus = "failed";
        }
      }),
    };
  });

export const idNendoCalendar = (nendo: number) => `${idPrefix}${nendo}`;
export const idNendoCalendarInput = (nendo: number) => `${idPrefix}${nendo}_Input`;

export type NendoCalendarType = typeof NendoCalendar.Type;

export const NendoCalendarSymbol = "NendoCalendar_Symbol";
export const NendoCalendar: NendoCalendarModelType = model;
type NendoCalendarInferredType = typeof model;
export interface NendoCalendarModelType extends NendoCalendarInferredType {}
