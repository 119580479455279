import Help from "@webkintai/help/content/manuals/profile/interview.md";
import { observer } from "mobx-react";
import React from "react";
import { ShrinkRibbonGuideButton } from "../../common/guide/ShrinkRibbonGuideButton";
import { GuideRibbon } from "../../common/ribbon/GuideRibbon";
import { SaveRibbon } from "../../common/ribbon/SaveRibbon";

export interface ProfileInterviewPageRibbonVM {
  saveDisabled: boolean;
  onSave: () => void;
  onReset: () => void;
}

@observer
export class ProfileInterviewPageRibbon extends React.Component<{
  model: ProfileInterviewPageRibbonVM;
  small: boolean;
}> {
  public render() {
    const { model, small } = this.props;
    const saveRibbon = (
      <SaveRibbon disabled={model.saveDisabled} onSave={model.onSave} onReset={model.onReset} small={small} />
    );

    if (small) {
      return (
        <>
          {saveRibbon}
          <ShrinkRibbonGuideButton markdown={Help} />
        </>
      );
    }

    return (
      <>
        <GuideRibbon markdown={Help} />
        {saveRibbon}
      </>
    );
  }
}
