import { KintaiReportsControllerApi, KintaiTransDayReportsResult } from "@webkintai/api";
import { inject } from "inversify";
import { sortBy } from "lodash-es";

import { toApiLocalDate } from "../../utils/api";
import { ApiFactory } from "../ApiFactory";
import { TransDayApi } from "./TransDayApi";

export class TransDayApiImpl extends TransDayApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public searchTransDayReports(from: Date, to: Date): Promise<KintaiTransDayReportsResult> {
    [from, to] = sortBy([from, to], it => it.getTime());
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .getTransDayReports(toApiLocalDate(from)!, toApiLocalDate(to)!),
    );
  }
}
