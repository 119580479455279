import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { PHPageModel, PHPageModelSymbol } from "../../../models/ph/PHPageModel";
import { PHPageGridVM } from "./grid/PHPageGrid";
import { PHPagGridRowVMImpl } from "./grid/PHPagGridRowVMImpl";
import { PHPageVM } from "./PHPage";
import { PHPageRibbonVM } from "./ribbon/PHPageRibbon";

export class PHPageVMImpl implements PHPageVM, PHPageRibbonVM, PHPageGridVM {
  @lazyInject(PHPageModelSymbol)
  private model: typeof PHPageModel.Type;

  public get ribbonModel() {
    return this;
  }
  public get gridModel() {
    return this;
  }

  @computed
  public get loadingStatus() {
    return this.model.loadingStatus;
  }

  @computed
  public get targetNendos() {
    return this.model.targetNendos;
  }

  @computed
  public get rows() {
    return this.model.users.map(user => new PHPagGridRowVMImpl(this.model, user));
  }

  @action.bound
  public onReset() {
    this.model.reset();
  }

  @computed
  public get saveDisabled() {
    return this.model.saveDisabled;
  }

  @computed
  public get reloadDisabled() {
    return this.model.reloadDisabled;
  }

  @action.bound
  public onSave() {
    this.model.save();
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @computed
  public get filterText() {
    return this.model.filterText;
  }

  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  public get excludeOldRetireUsers() {
    return this.model.excludeOldRetireUsers;
  }

  @action.bound
  public onChangeExcludeOldRetireUsers(value: boolean) {
    return this.model.setExcludeOldRetireUsers(value);
  }

  @computed
  public get excludeHasNoChange() {
    return this.model.excludeHasNoChange;
  }

  @action.bound
  public onChangeExcludeHasNoChange(value: boolean) {
    this.model.setExcludeHasNoChange(value);
  }

  @action.bound
  public onExcelExport() {
    this.model.exportExcel();
  }

  @action.bound
  public onExcelImport(file: File) {
    this.model.importExcel(file);
  }
}
