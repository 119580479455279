import { action, computed } from "mobx";

import { NendoInCharge } from "../../../../models/depts/incharge/NendoInCharge";
import { NendoInCharges } from "../../../../models/depts/incharge/NendoInCharges";
import { NendoInChargeUser } from "../../../../models/depts/incharge/NendoInChargeUser";
import { DeptsPageVMImpl } from "../DeptsPageVMImpl";
import { DeptUsersPanelRowVM } from "./DeptUsersPanel";

export class DeptUsersPanelRowVMImpl implements DeptUsersPanelRowVM {
  constructor(
    public readonly disabled: boolean,
    public readonly alreadyOn: boolean,
    private readonly pageModel: DeptsPageVMImpl,
    private readonly parentModel: typeof NendoInCharges.Type,
    private readonly model: typeof NendoInCharge.Type,
    private readonly user: typeof NendoInChargeUser.Type,
  ) {}

  @computed
  get userId() {
    return this.user.userId;
  }

  @computed
  get userName() {
    return this.user.userName;
  }

  @computed
  get depName() {
    return this.user.depName;
  }

  @computed
  get rankName() {
    return this.user.rankName;
  }

  @computed
  get isSelected() {
    return this.alreadyOn || this.model.userIdIsSelected(this.userId);
  }

  @action.bound
  public onToggleSelected() {
    if (this.disabled) {
      return;
    }
    this.model.toggleUserId(this.userId);
  }
}
