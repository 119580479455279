import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import {
  PasswordTokenResetFormModel,
  PasswordTokenResetFormModelSymbol,
} from "../../../models/login/PasswordTokenResetFormModel";
import { PasswordChangeConsoleVM, PasswordChangeMode } from "./PasswordChangeConsole";

export class PasswordTokenResetConsoleVMImpl implements PasswordChangeConsoleVM {
  @lazyInject(PasswordTokenResetFormModelSymbol)
  private form: typeof PasswordTokenResetFormModel.Type;

  public get mode() {
    return "token_reset" as PasswordChangeMode;
  }

  @computed
  public get showConsole() {
    return this.form.showPasswordTokenResetDialog;
  }

  @computed
  public get forced() {
    return true;
  }

  @computed
  public get userId() {
    return "";
  }

  @computed
  public get currentPassword() {
    return "";
  }

  @computed
  public get newPassword() {
    return this.form.newPassword;
  }

  @computed
  public get newPasswordForConfirm() {
    return this.form.newPasswordForConfirm;
  }

  @computed
  public get errors() {
    return this.form.errors;
  }

  @computed
  public get allowSubmit() {
    return this.errors.length === 0;
  }

  @action.bound
  public onChangeCurrentPassword(value: string) {
    // DO NOTHING
  }

  @action.bound
  public onChangeNewPassword(value: string) {
    this.form.setNewPassword(value);
  }
  @action.bound
  public onChangeNewPasswordForConfirm(value: string) {
    this.form.setNewPasswordForConfirm(value);
  }

  @action.bound
  public onSubmit() {
    this.form.submit();
  }

  @action.bound
  public onClose() {
    this.form.cancel();
  }
}
