import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { ErrorOverlay } from "../../../../form/ErrorOverlay";
import { ErrorCell, ErrorCountColor } from "./ErrorComponents";

export type MonthlyErrorsKFProps = {
  model: MonthlyErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyErrorsKFVM {
  errors: string[];
}

export const MonthlyErrorsKF = observer((props: MonthlyErrorsKFProps) => {
  const { model } = props;

  if (props.mode === "cell") {
    return (
      <ErrorOverlay mode="cell" errors={model.errors} warnings={[]}>
        <ErrorCell>
          <ErrorCountColor length={model.errors.length}>{model.errors.length}</ErrorCountColor>
        </ErrorCell>
      </ErrorOverlay>
    );
  }

  return (
    <Tooltip
      disabled={model.errors.length === 0}
      boundary="viewport"
      content={
        <div>
          {model.errors.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </div>
      }>
      <>
        エラー: <ErrorCountColor length={model.errors.length}>{model.errors.length}件</ErrorCountColor>
      </>
    </Tooltip>
  );
});
