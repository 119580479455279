import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../form/HourBasedDaysCountField";

export type MTotalSpDaysKFProps = {
  model: MTotalSpDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalSpDaysKFVM {
  mTotalSpDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalSpDaysKF = observer((props: MTotalSpDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalSpDays;

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label=""
      {...itemState}
      value={itemState.value}
      onChange={undefined}
    />
  );
});
