import {
  DepartmentsControllerApi,
  DeptPersonsInChargeUpdateRequest,
  KintaiDepartmentsReportControllerApi,
  KintaiReportsControllerApi,
} from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { DeptsApi } from "./DeptsApi";

export class DeptsApiImpl extends DeptsApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getDepartments(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(DepartmentsControllerApi).getDepartments(nendo),
    );
  }

  public getKintaiReports(depCode: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiDepartmentsReportControllerApi)
        .getKintaiReports(depCode, year, month),
    );
  }

  public getKintaiNendoSummaries(depCode: string, nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(KintaiReportsControllerApi).getKintaiAggregations(nendo, depCode),
    );
  }

  public getDepartmentReportsCloseStatus(depCode: string) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiDepartmentsReportControllerApi)
        .getDepartmentReportsCloseStatus(depCode),
    );
  }

  public closeKintaiReports(depCode: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiDepartmentsReportControllerApi)
        .closeDepartmentReports(depCode, year, month),
    );
  }

  public reopenKintaiReports(depCode: string, year: number, month: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiDepartmentsReportControllerApi)
        .reopenDepartmentReports(depCode, year, month),
    );
  }

  public getPIC(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(DepartmentsControllerApi).getPersonsInCharge(nendo),
    );
  }

  public savePIC(nendo: number, payload: DeptPersonsInChargeUpdateRequest) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(DepartmentsControllerApi).updatePersonsInCharge(nendo, payload),
    );
  }
}
