import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type PJRBodyNotPopulatedTimeKFProps = {
  model: PJRBodyNotPopulatedTimeKFVM;
} & CellFormPrintAdaptable;

export interface PJRBodyNotPopulatedTimeKFVM {
  PJRBodyNotPopulatedTime: Minutes | undefined;
  PJBodyErrors: string[];
  PJBodyWarnings: string[];
}

export const PJRBodyNotPopulatedTimeKF = observer((props: PJRBodyNotPopulatedTimeKFProps) => {
  const { model } = props;

  return (
    <HHMMField
      label="その他間接時間"
      {...props}
      disabled={true}
      disabledButShowsErrors={true}
      errors={model.PJBodyErrors}
      warnings={model.PJBodyWarnings}
      value={model.PJRBodyNotPopulatedTime}
    />
  );
});
