import { flow, IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendo } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { LoadingStatusType } from "../common/LoadingStatusType";
import { idSPortNendoGivenTimes, SPortNendoGivenTimes } from "./SPortNendoGivenTimes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("SPortAdminPageModel", {
      nendo: types.optional(types.number, getNendo(new Date())),
      currentTarget: types.maybe(types.reference(SPortNendoGivenTimes)),
      givenTimeNendoTimes: types.map(SPortNendoGivenTimes),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get allowedToEdit() {
          if (!self.currentTarget) {
            return false;
          }
          return self.currentTarget.allowedToEdit;
        },
        get loadingStatus(): LoadingStatusType {
          if (self.currentTarget === undefined) {
            return "init";
          }

          return self.currentTarget.loadingStatus;
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);

      const prepare = (nendo: number) => {
        const id = idSPortNendoGivenTimes(nendo);
        const found = self.givenTimeNendoTimes.get(id);
        return found
          ? found
          : (() => {
              const created = SPortNendoGivenTimes.create({
                id,
                nendo,
              });
              self.givenTimeNendoTimes.put(created);
              return created;
            })();
      };

      const triggerLoadNendo = flow(function*() {
        self.currentTarget = prepare(self.nendo);
        yield self.currentTarget!.loadIfNeeded();
      });

      return {
        route(pathFragment: string) {
          const { targetYear } = fromPaths.admin.sport.ofYear.index(pathFragment);
          if (targetYear) {
            self.nendo = targetYear;
          }
          triggerLoadNendo();
        },
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.admin.sport.ofNendo(nendo).index());
        },
      };
    }),
  {},
);

export type SPortAdminPageModelType = typeof SPortAdminPageModel.Type;

export const SPortAdminPageModelSymbol = "SPortAdminPageModel_Symbol";
export const SPortAdminPageModel: SPortAdminPageModelModelType = model;
type SPortAdminPageModelInferredType = typeof model;
export interface SPortAdminPageModelModelType extends SPortAdminPageModelInferredType {}
