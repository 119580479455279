import { Tab, Tabs } from "@blueprintjs/core";
import DailyKintaiHelp from "@webkintai/help/content/manuals/kintai/daily_kintai.md";
import DailyAppHelp from "@webkintai/help/content/manuals/kintai/kintai_app.md";
import ProjectReportHelp from "@webkintai/help/content/manuals/kintai/kintai_projectreport.md";
import { observer } from "mobx-react";
import React from "react";

import { RegularDailyFormTabIdType } from "../../../../../models/kintai/regular/RegularDailyFormTabId";
import { AppStageKF } from "../../applications/fields/AppStageKF";
import { RegularDailyItemsVisibility } from "../../visibility/RegularDailyItemsVisibility";
import {
  ArrivalLeaveFormLine,
  DailyKintaiFormLine4,
  DailyKintaiFormPane,
  DailyKintaiFormTabContainer,
} from "../DailyKintaiFormPane";
import { DailyKintaiHelpButton, DailyKintaiTabHelpButton } from "../DailyKintaiHelpButton";
import { ArrivalKF } from "../fields/ArrivalKF";
import { DateKF } from "../fields/DateKF";
import { LeaveKF } from "../fields/LeaveKF";
import { TypesKF } from "../fields/TypesKF";
import { WorkplaceFormLabelKF } from "../fields/workplace/WorkplaceFormLabelKF";
import { WorkplaceSonotaKF } from "../fields/workplace/WorkplaceSonotaKF";
import { WorkplaceSyussyaKF } from "../fields/workplace/WorkplaceSyussyaKF";
import { WorkplaceZaitakuGT4HKF } from "../fields/workplace/WorkplaceZaitakuGT4HKF";
import { WorkplaceZaitakuLE4HKF } from "../fields/workplace/WorkplaceZaitakuLE4HKF";
import { RegularDailyKintaiFormApplications } from "./RegularDailyKintaiFormApplications";
import { RegularDailyKintaiFormBody } from "./RegularDailyKintaiFormBody";
import { RegularDailyKintaiFormPJReport } from "./RegularDailyKintaiFormPJReport";
import { RegularDailyVM } from "./RegularDailyVM";

@observer
export class RegularDailyKintaiForm extends React.Component<{ model: RegularDailyVM }> {
  public render() {
    const { props } = this;
    const { model } = props;

    const visibility = new RegularDailyItemsVisibility(model.date, model.kintaiType, model.rankPosition);

    return (
      <DailyKintaiFormPane
        onSwipe={model.onSwipe}
        header={
          <>
            <ArrivalLeaveFormLine>
              <DateKF mode="form" model={model} />
              <ArrivalKF mode="form" model={model} />
              <LeaveKF mode="form" model={model} />
              <DailyKintaiHelpButton markdown={DailyKintaiHelp} />
            </ArrivalLeaveFormLine>

            {visibility.workplace && (
              <WorkplaceFormLabelKF
                model={model}
                lines={[
                  <React.Fragment key={0}>
                    <WorkplaceZaitakuGT4HKF mode="form" model={model} />
                    <WorkplaceZaitakuLE4HKF mode="form" model={model} />
                  </React.Fragment>,
                  <React.Fragment key={1}>
                    <WorkplaceSyussyaKF mode="form" model={model} />
                    <WorkplaceSonotaKF mode="form" model={model} />
                  </React.Fragment>,
                ]}
              />
            )}

            <DailyKintaiFormLine4>
              {([1, 2, 3, 4] as [1, 2, 3, 4]).map(i => (
                <TypesKF key={i} bunruiIdx={i} mode="form" model={model} />
              ))}
            </DailyKintaiFormLine4>

            <DailyKintaiFormTabContainer>
              <Tabs selectedTabId={model.regularDailyFormTabId} onChange={this.onChangeTab}>
                <Tab id={"kintai_details"}>勤怠詳細</Tab>
                <Tab id={"kintai_application"}>
                  <AppStageKF mode="print" model={model.dailyApp} />
                  <DailyKintaiTabHelpButton
                    active={model.regularDailyFormTabId === "kintai_application"}
                    markdown={DailyAppHelp}
                  />
                </Tab>
                <Tab id={"kintai_monthly_report"}>
                  月報（工数）
                  <DailyKintaiTabHelpButton
                    active={model.regularDailyFormTabId === "kintai_monthly_report"}
                    markdown={ProjectReportHelp}
                  />
                </Tab>
              </Tabs>
            </DailyKintaiFormTabContainer>
          </>
        }>
        {model.regularDailyFormTabId === "kintai_details" && <RegularDailyKintaiFormBody {...this.props} />}
        {model.regularDailyFormTabId === "kintai_application" && <RegularDailyKintaiFormApplications {...this.props} />}
        {model.regularDailyFormTabId === "kintai_monthly_report" && <RegularDailyKintaiFormPJReport {...this.props} />}
      </DailyKintaiFormPane>
    );
  }

  private onChangeTab = (tabId: string) =>
    this.props.model.onChangeRegularDailyFormTabId(tabId as RegularDailyFormTabIdType);
}
