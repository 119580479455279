import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";
import { CellPosition } from "react-virtualized/dist/es/CellMeasurer";

import { FocusMarkerClassName } from "../GridTable";
import { eqTableColumnDef, RowIdGenerator, TableColumnDef } from "../TableColumnDef";
import { eqCellPosition } from "./cellsfunc";
import { fixedEdgeRightBorderStyle } from "./HeaderCell";

interface Props {
  isActive: boolean;
  style: any;
  cellPosition: CellPosition;
  colDef: TableColumnDef;
  fixedColumnCount: number;
  rowIdGenerator: RowIdGenerator;
  onMoveCellKeyPressed: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onMoveOnCell: (cellPosition: CellPosition) => void;
}

const Cell = styled.div`
  font-size: 13px;
  font-family: "Courier New", Courier, monospace;
  background-color: #fff;

  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &.FixedEdgeCellStyle {
    ${fixedEdgeRightBorderStyle}
  }

  &.Even {
    background-color: #f3f3ff;
  }

  &.Odd {
  }

  &.Active {
    border-right: 1px solid #6699ff;
    border-bottom: 1px solid #6699ff;
    box-shadow: 0px 0px 3px 3px rgba(128, 192, 255, 0.8) inset;
  }
`;

export class BodyCell extends React.Component<Props> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.containerRef = React.createRef();
  }

  public shouldComponentUpdate(nextProps: Props, nextState: {}) {
    if (
      !!this.props.isActive !== !!nextProps.isActive ||
      this.props.rowIdGenerator !== nextProps.rowIdGenerator ||
      this.props.onMoveCellKeyPressed !== nextProps.onMoveCellKeyPressed ||
      !eqTableColumnDef(this.props.colDef, nextProps.colDef) ||
      !eqCellPosition(this.props.cellPosition, nextProps.cellPosition)
    ) {
      return true;
    }

    if (this.props.style.left !== nextProps.style.left || this.props.style.top !== nextProps.style.top) {
      const { current } = this.containerRef;
      if (current) {
        current.style.top = nextProps.style.top;
        current.style.left = nextProps.style.left;
      }
    }

    return false;
  }

  public render() {
    const { props, containerRef } = this;
    const { colDef, fixedColumnCount, style, rowIdGenerator, onMoveCellKeyPressed, isActive, cellPosition } = props;
    const { rowIndex, columnIndex } = cellPosition;
    const { id } = colDef;

    if (props.isActive) {
      requestAnimationFrame(() => {
        if (!containerRef.current) {
          return;
        }

        const $focus: any = containerRef.current.querySelector(`.${FocusMarkerClassName}`);
        if (!$focus) {
          return;
        }

        if ($focus.focus instanceof Function) {
          $focus.focus();
        }
        if ($focus.select instanceof Function) {
          $focus.select();
        }
      });
    }

    return (
      <Cell
        ref={containerRef}
        key={rowIdGenerator(rowIndex) + "," + id}
        tabIndex={-1}
        onClick={this.onClick}
        onKeyDown={onMoveCellKeyPressed}
        style={style}
        className={classNames(
          columnIndex === fixedColumnCount - 1 && "FixedEdgeCellStyle",
          rowIndex % 2 === 0 ? "Even" : "Odd",
          isActive && "Active",
        )}>
        {props.colDef.renderBody(cellPosition, isActive)}
      </Cell>
    );
  }
  private onClick = () => {
    this.props.onMoveOnCell(this.props.cellPosition);
  };
}
