import {
  ApproversResult,
  JobNoListResult,
  KintaiReportEditEntity,
  KintaiReportResult,
  MinutesEntity,
  MonthlyReportTimestampsEntity,
  RestTimeEntity,
  WorkplaceEntity,
} from "@webkintai/api";
import { allRegularBunruiList } from "@webkintai/bunrui";
import { HourBasedDays, Minutes } from "@webkintai/core";
import { debounce, range } from "lodash-es";
import { applySnapshot, flow, getEnv, getParent, onPatch, SnapshotIn, types } from "mobx-state-tree";
import moment from "moment";

import { KintaiApi } from "../../services/api/KintaiApi";
import { KintaiAppApi } from "../../services/api/KintaiAppApi";
import { AppConfirm } from "../../services/msg/AppConfirm";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { MyPrivileges } from "../../services/prv/MyPrivileges";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate, toApiLocalDate } from "../../utils/api";
import { daysOfMonth } from "../../utils/calendar";
import { compareMonth, dateEq } from "../../utils/date";
import { isApiEnumSpecified } from "../../utils/isApiEnumSpecified";
import { hasNoChangeReduce } from "../../utils/model";
import { genUuid } from "../../utils/uuid";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { Profile, ProfileSymbol } from "../profile/Profile";
import { User } from "../users/User";
import { UsersSymbol, UsersType } from "../users/Users";
import { prefixedIdType } from "../utils";
import { errorMessages, infoMessages, warningMessages } from "./api/ApiError";
import { returnedResultHasError } from "./api/UserMonthlyReport";
import { apiValueToKintaiApplicationDayValues, idKintaiApplicationDay } from "./apps/KintaiApplicationDay";
import { KintaiApplications } from "./apps/KintaiApplications";
import { KintaiMonthlyAttributes } from "./attr/KintaiMonthlyAttributes";
import { createKintaiMonthlyAttributesValuesFromApiValues } from "./attr/KintaiMonthlyAttributesValues";
import { JobNoEntry } from "./JobNoEntry";
import { fromMainKintaiTypeToWorkingType, fromWorkingTypeCodeToMainKintaiType, MainKintaiType } from "./MainKintaiType";
import { MonthlyKintaiGlobalWarnings } from "./MonthlyKintailGlobalWarnings";
import { derivePillars, PJReport } from "./pjreport/PJReport";
import { idRegularDailyKintai, RegularDailyKintai } from "./regular/RegularDailyKintai";
import { RegularDailyValues } from "./regular/RegularDailyValues";
import { RegularKintai } from "./regular/RegularKintai";
import { MonthlyKintaiAllowedSavedTarget } from "./saveTarget/MonthlyKintaiAllowedSavedTarget";
import { idSPortDailyKintai, SPortDailyKintai } from "./sport/SPortDailyKintai";
import { SPortDailyValues } from "./sport/SPortDailyValues";
import { SPortKintai } from "./sport/SPortKintai";
import { apiValueToTimestamps, KintaiTimestamps } from "./timestamps/KintaiTimestamps";
import { VacationTotal } from "./vacationtotal/VacantionTotal";
import { apiValueToVacationTotalValue } from "./vacationtotal/VacantionTotalValues";

const idPrefix = "MonthlyKintai_";
/**
 * 特定ユーザの特定月の勤怠表のモデル
 */
const model = types
  .model("MonthlyKintai", {
    id: prefixedIdType(idPrefix),
    userId: types.string,
    userName: types.maybe(types.string),
    month: types.Date,

    retainAlways: types.optional(types.boolean, false),
    inLoading: types.optional(types.boolean, false),
    loadingStatus: types.optional(LoadingStatus, "init"),
    loadingErrorMessage: types.optional(types.string, ""),
    validatingStatus: types.optional(LoadingStatus, "loaded"),
    inSaving: types.optional(types.boolean, false),
    lastLoadTime: types.maybe(types.Date),
    lastValidateVersion: types.optional(types.number, 0),
    approverLoadingStatus: types.optional(LoadingStatus, "init"),

    savedTargets: MonthlyKintaiAllowedSavedTarget,
    approvers: types.array(types.reference(User)),
    availableJobNos: types.array(JobNoEntry),
    availableKintaiTypes: types.array(MainKintaiType),

    attrs: types.maybe(KintaiMonthlyAttributes),
    timestamps: KintaiTimestamps,
    apps: types.maybe(KintaiApplications),
    regularKintai: types.maybe(RegularKintai),
    sPortKintai: types.maybe(SPortKintai),
    pjReport: types.maybe(PJReport),
    vacationTotal: types.maybe(VacationTotal),
  })
  .views(self => {
    return {
      get mainKintaiType() {
        return self.attrs!.mainKintaiType;
      },
      get projectInputStyle() {
        return self.pjReport!.projectInputStyle;
      },
    };
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);
    const profile = () => getEnv(self).get(ProfileSymbol) as typeof Profile.Type;
    const myPrivileges = () => getDI(self, MyPrivileges);

    function myUserId() {
      return profile().userId;
    }

    const compareUserIdIfAny = (v?: { userId: string }) => v !== undefined && v.userId === myUserId();

    return {
      get hasNoChange() {
        return hasNoChangeReduce([
          !self.pjReport || self.pjReport.hasNoChange,
          !self.attrs || self.attrs.hasNoChange,
          !self.regularKintai || self.regularKintai.hasNoChange,
          !self.sPortKintai || self.sPortKintai.hasNoChange,
        ]);
      },
      get canBeReleased() {
        return !self.retainAlways && self.loadingStatus === "loaded" && this.hasNoChange;
      },
      get isManager() {
        return self.attrs!.isManger;
      },
      get availableRegularKintaiBunruiList() {
        return allRegularBunruiList
          .filter(bunrui => bunrui.canAppearDateAt(self.month))
          .filter(bunrui => bunrui.isForRegularRank(self.mainKintaiType === "Flex", this.isManager));
      },
      get isMine() {
        return compareUserIdIfAny(self);
      },
      get selfStampIsAllowed() {
        return this.isMine;
      },
      get reviewerStampIsAllowed() {
        return compareUserIdIfAny(self.attrs!.origin.reviewer);
      },
      get approverStampIsAllowed() {
        return compareUserIdIfAny(self.attrs!.origin.approver);
      },
      get clerkStampIsAllowed() {
        return myPrivileges().has("DISP_KINTAI_CLERK_TS");
      },
      get hrmStampIsAllowed() {
        return myPrivileges().has("DISP_KINTAI_HRM_TS");
      },
      get hasSavePrivilege() {
        return this.isMine || profile().hasAbility("DISP_OTHERSKINTAI_SAVE");
      },
      get isPrivilegeNotAllowed() {
        return !this.hasSavePrivilege;
      },
      get isRegularKintaiLocked() {
        return this.isPrivilegeNotAllowed || !self.savedTargets.regularKintai;
      },
      get isSportKintaiLocked() {
        return this.isPrivilegeNotAllowed || !self.savedTargets.sportKintai;
      },
      get isPJReportLocked() {
        return this.isPrivilegeNotAllowed || !self.savedTargets.projectReport;
      },
      get isReloadLocked() {
        return self.inLoading;
      },
      get isSaveLocked() {
        return this.isPrivilegeNotAllowed || !self.savedTargets.anyAllowed;
      },
      get reviewesSelectionIsLocked() {
        return self.inSaving || !this.hasSavePrivilege;
      },
      get applicationIsLocked() {
        return self.inSaving || !this.hasSavePrivilege;
      },
      /** （通常）勤怠申請承認自体を許可しているか？ */
      get approveAppsAllowed() {
        return this.reviewerStampIsAllowed || this.approverStampIsAllowed;
      },
      /** （特権）勤怠申請承認を許可しているか？ */
      get privilegedApproveAppsAllowed() {
        return myPrivileges().has("DISP_PRIVILEGEDKINTAI_APP");
      },
      /** 画面上部に出す警告 */
      get globalWarnings(): MonthlyKintaiGlobalWarnings[] {
        const result: MonthlyKintaiGlobalWarnings[] = [];
        if (self.regularKintai) {
          const lawOverCalc = self.regularKintai.total.mTotalLawOverCalc;
          if (lawOverCalc.value.minutes > 75 * 60) {
            result.push("lawOverCalcWillExceed75");
          } else if (lawOverCalc.value.minutes > 45 * 60) {
            result.push("lawOverCalcWillExceed45");
          }
        }

        return result;
      },
      /**
       * 前月以前の勤怠か
       */
      get isPastMonthKintai() {
        return compareMonth(self.month, timeProvider().currentTime, (l, r) => {
          return l.getTime() < r.getTime();
        });
      },
      /**
       * 一括承認可能なレギュラー勤怠日の列を返す
       */
      get bulkApprovableRegularDays() {
        const regularKintai = self.regularKintai;
        const apps = self.apps;
        if (!regularKintai || !apps) {
          return [];
        }
        if (!this.approveAppsAllowed) {
          return [];
        }

        return regularKintai.days
          .filter(it => it.canMadeBulkApproved)
          .filter(it => apps.getDayOf(it.date).appStage === "approve_is_required");
      },
      get bulkApprovalDisabled() {
        return this.isSaveLocked || this.bulkApprovableRegularDays.length === 0;
      },
      get hasAnyCkrpRecord() {
        if (!self.regularKintai) {
          return false;
        }
        return self.regularKintai.hasAnyCkrpRecord;
      },
    };
  })
  .actions(self => {
    const kintaiApi = (): KintaiApi => getEnv(self).get(KintaiApi);
    const kintaiAppApi = () => getDI(self, KintaiAppApi);
    const users = (): UsersType => getEnv(self).get(UsersSymbol);
    const appConfirm = (): AppConfirm => getEnv(self).get(AppConfirm);
    const appNotifier = (): AppNotifier => getEnv(self).get(AppNotifier);
    const timeProvider = () => getDI(self, TimeProvider);

    const reset = () => {
      if (self.regularKintai) {
        self.regularKintai.reset();
      }
      if (self.sPortKintai) {
        self.sPortKintai.reset();
      }
      if (self.attrs) {
        self.attrs.reset();
      }
      if (self.pjReport) {
        self.pjReport.reset();
      }
      if (self.vacationTotal) {
        self.vacationTotal.reset();
      }
    };
    const reflectTimestamp = (timestamps: MonthlyReportTimestampsEntity) => {
      applySnapshot(self.timestamps, apiValueToTimestamps(users(), timestamps));
    };
    const validateVersion = (inc: number = 1) => {
      return (self.lastValidateVersion += inc);
    };

    const reflectKintaiResult = (result: KintaiReportResult, onlyValidate = false) => {
      const { monthlyReport, allowedSaveTarget } = result;

      if (!monthlyReport) {
        return;
      }
      self.userName = monthlyReport.user.userName;

      const { attributes } = monthlyReport;
      if (attributes) {
        self.availableKintaiTypes.replace(
          attributes.availableWorkingTypes.map(it => fromWorkingTypeCodeToMainKintaiType(it.value)),
        );

        const attrValues = createKintaiMonthlyAttributesValuesFromApiValues(users(), attributes);

        if (onlyValidate) {
          applySnapshot(self.attrs!.computed, attrValues);
        } else {
          self.attrs = KintaiMonthlyAttributes.create({
            origin: attrValues,
            input: attrValues,
            computed: attrValues,
          });
        }
      }

      self.savedTargets.reflect(allowedSaveTarget);

      const { timestamps } = monthlyReport;
      reflectTimestamp(timestamps);

      const { applications } = monthlyReport;

      self.apps = KintaiApplications.create({
        days: [...daysOfMonth(self.month)].map(date => {
          const corr = applications.days.find(d => dateEq(fromApiDate(d.date), date));
          return {
            id: idKintaiApplicationDay(self.userId, date),

            userId: self.userId,
            date,

            ...(corr ? apiValueToKintaiApplicationDayValues(corr) : {}),
          };
        }),
      });

      const { vacations } = result;
      if (vacations) {
        const vacationsValue = apiValueToVacationTotalValue(vacations);
        if (onlyValidate) {
          applySnapshot(self.vacationTotal!.computed, vacationsValue);
        } else {
          self.vacationTotal = VacationTotal.create({
            origin: vacationsValue,
            computed: vacationsValue,
          });
        }
      }

      (() => {
        const regularDays: Array<SnapshotIn<typeof RegularDailyKintai>> = monthlyReport.regularReport!.days.map(day => {
          const kintaiTypes = day.types.map(t => t.value) || [];

          const values: SnapshotIn<typeof RegularDailyValues> = {
            date: fromApiDate(day.date),
            errors: day.errors,

            type1: `${kintaiTypes[0] || ""}`,
            type2: `${kintaiTypes[1] || ""}`,
            type3: `${kintaiTypes[2] || ""}`,
            type4: `${kintaiTypes[3] || ""}`,

            transDay: fromApiDate(day.transDay),

            hourlyLeave: HourBasedDays.apiValueOf(day.hourlyLeave),
            hourlyNursingcare: HourBasedDays.apiValueOf(day.hourlyNursingcare),
            hourlyFamilycare: HourBasedDays.apiValueOf(day.hourlyFamilycare),

            arrival: fromApiDate(day.arrival),
            leave: fromApiDate(day.leave),

            doorEnter: fromApiDate(day.doorMinEnter),
            doorLeave: fromApiDate(day.doorMaxLeave),

            ckrpEnter: fromApiDate(day.ckrpMinEnter),
            ckrpLeave: fromApiDate(day.ckrpMaxLeave),

            rest: Minutes.apiValueOf(day.rest),
            actual: Minutes.apiValueOf(day.actual),
            flex: Minutes.apiValueOf(day.flex),

            holiday: Minutes.apiValueOf(day.holiday),
            midnight: Minutes.apiValueOf(day.midnight),
            allNightOff: Minutes.apiValueOf(day.allNightOff),

            spWeekday: Minutes.apiValueOf(day.spWeekday),
            spWeekdayMidnight: Minutes.apiValueOf(day.spWeekdayMidnight),
            spHoliday: Minutes.apiValueOf(day.spHoliday),
            spHolidayMidnight: Minutes.apiValueOf(day.spHolidayMidnight),

            diff: Minutes.apiValueOf(day.diff),

            off: Minutes.apiValueOf(day.off),
            offCount: day.offCount,

            over: Minutes.apiValueOf(day.over),
            overHoliday: Minutes.apiValueOf(day.overHoliday),
            overHolidayMidnight: Minutes.apiValueOf(day.overHolidayMidnight),
            growingOver: Minutes.apiValueOf(day.growingOver),
            overWeekday: Minutes.apiValueOf(day.overWeekday),
            overWeekdayMidnight: Minutes.apiValueOf(day.overWeekdayMidnight),

            remarks: day.remarks || "",

            unusedRestSunset: isApiEnumSpecified(day.unusedRestTimes, RestTimeEntity.ValueEnum._18001830),
            unusedRestNight: isApiEnumSpecified(day.unusedRestTimes, RestTimeEntity.ValueEnum._21002130),

            workplaceZaitakuGt4H: isApiEnumSpecified(day.workplaces, WorkplaceEntity.ValueEnum.ZAITAKUGT4H),
            workplaceZaitakuLe4H: isApiEnumSpecified(day.workplaces, WorkplaceEntity.ValueEnum.ZAITAKULE4H),
            workplaceSyussya: isApiEnumSpecified(day.workplaces, WorkplaceEntity.ValueEnum.SYUSSYA),
            workplaceSonota: isApiEnumSpecified(day.workplaces, WorkplaceEntity.ValueEnum.SONOTA),
          };

          return {
            id: idRegularDailyKintai(self.userId, fromApiDate(day.date)),
            date: fromApiDate(day.date),

            origin: values,
            input: values,
            computed: values,
          };
        });

        const regularTotal = monthlyReport.regularReport!.total;

        if (onlyValidate) {
          self.regularKintai!.days.forEach((d, i) => {
            const dateEntry = regularDays[i].computed;
            applySnapshot(d.computed, dateEntry);
          });
          applySnapshot(self.regularKintai!.total.computed, regularTotal);
        } else {
          self.regularKintai = RegularKintai.create({
            userId: self.userId,
            month: self.month,
            days: regularDays,
            total: {
              origin: regularTotal,
              computed: regularTotal,
            },
          });
        }
        self.regularKintai!.days.forEach(d => {
          d.applyErrors();
        });
      })();

      (() => {
        const sPortDays: Array<SnapshotIn<typeof SPortDailyKintai>> = monthlyReport.sportReport!.days.map(day => {
          const kintaiTypes = day.types.map(t => t.value) || [];

          const values: SnapshotIn<typeof SPortDailyValues> = {
            date: fromApiDate(day.date),
            errors: day.errors,

            type1: `${kintaiTypes[0] || ""}`,
            type2: `${kintaiTypes[1] || ""}`,
            type3: `${kintaiTypes[2] || ""}`,

            transDay: fromApiDate(day.transDay),

            hourlyLeave: HourBasedDays.apiValueOf(day.hourlyLeave),
            hourlyNursingcare: HourBasedDays.apiValueOf(day.hourlyNursingcare),
            hourlyFamilycare: HourBasedDays.apiValueOf(day.hourlyFamilycare),

            arrival: fromApiDate(day.arrival),
            leave: fromApiDate(day.leave),
            diff: Minutes.apiValueOf(day.diff),

            rest: Minutes.apiValueOf(day.rest),
            actual: Minutes.apiValueOf(day.actual),
            midnight: Minutes.apiValueOf(day.midnight),
            immunity: Minutes.apiValueOf(day.immunity),
            growingTime: Minutes.apiValueOf(day.growingTime),
            missed: Minutes.apiValueOf(day.missed),

            overDiff: Minutes.apiValueOf(day.overDiff),
            overRestNormal: Minutes.apiValueOf(day.overRestNormal),
            overRestMidnight: Minutes.apiValueOf(day.overRestMidnight),
            overWeekday: Minutes.apiValueOf(day.overWeekday),
            overWeekdayMidnight: Minutes.apiValueOf(day.overWeekdayMidnight),
            overHoliday: Minutes.apiValueOf(day.overHoliday),
            overHolidayMidnight: Minutes.apiValueOf(day.overHolidayMidnight),
            growingOver: Minutes.apiValueOf(day.growingOver),

            offCount: day.offCount,
            remarks: day.remarks || "",
          };

          return {
            id: idSPortDailyKintai(self.userId, fromApiDate(day.date)),
            date: fromApiDate(day.date),

            origin: values,
            input: values,
            computed: values,
          };
        });

        const sPortTotal = monthlyReport.sportReport!.total;

        if (onlyValidate) {
          self.sPortKintai!.days.forEach((d, i) => {
            applySnapshot(d.computed, sPortDays[i].computed);
          });
          applySnapshot(self.sPortKintai!.total.computed, sPortTotal);
        } else {
          self.sPortKintai = SPortKintai.create({
            userId: self.userId,
            month: self.month,
            days: sPortDays,
            total: {
              origin: sPortTotal,
              computed: sPortTotal,
            },
          });
        }
        self.sPortKintai!.days.forEach(d => {
          d.applyErrors();
        });
      })();

      // 月報
      (() => {
        const { projectReport } = monthlyReport;
        if (projectReport) {
          if (onlyValidate) {
            applySnapshot(self.pjReport!.errors, errorMessages(projectReport.errors));
            applySnapshot(self.pjReport!.warnings, warningMessages(projectReport.errors));
            applySnapshot(self.pjReport!.infos, infoMessages(projectReport.errors));

            applySnapshot(self.pjReport!.totalPillar.errors, errorMessages(projectReport.otherColumn.errors));
            applySnapshot(self.pjReport!.totalPillar.warnings, warningMessages(projectReport.otherColumn.errors));
            applySnapshot(self.pjReport!.totalPillar.infos, infoMessages(projectReport.otherColumn.errors));

            projectReport.otherColumn.days.forEach((d, tDayIdx) => {
              applySnapshot(self.pjReport!.totalPillar.days[tDayIdx].errors, errorMessages(d.errors));
              applySnapshot(self.pjReport!.totalPillar.days[tDayIdx].warnings, warningMessages(d.errors));
              applySnapshot(self.pjReport!.totalPillar.days[tDayIdx].infos, infoMessages(d.errors));
            });

            projectReport.columns.forEach((col, idx) => {
              const corrPillar = self.pjReport!.livingPillars[idx];
              if (corrPillar) {
                corrPillar.header.computed.setPjName(col.total.projectName || "");
                applySnapshot(corrPillar.header.computed.errors, errorMessages(col.errors));
                applySnapshot(corrPillar.header.computed.warnings, warningMessages(col.errors));
                applySnapshot(corrPillar.header.computed.infos, infoMessages(col.errors));
              }
              col.days.forEach((d, dayIdx) => {
                applySnapshot(corrPillar.days[dayIdx].errors, errorMessages(d.errors));
                applySnapshot(corrPillar.days[dayIdx].warnings, warningMessages(d.errors));
                applySnapshot(corrPillar.days[dayIdx].infos, infoMessages(d.errors));
              });
            });
          } else {
            const pillars = projectReport.columns.map(col => {
              const { total } = col;
              const headerValue = {
                pjName: total.projectName || "",
                pjCode: total.projectCode || "",
                odCode: total.orderCode || "",
                errors: errorMessages(col.errors),
                warnings: warningMessages(col.errors),
                infos: infoMessages(col.errors),
                labels: range(0, 3).map(it => total.labels[it] || ""),
              };

              return {
                id: genUuid(),

                persisted: true,

                header: {
                  origin: headerValue,
                  computed: headerValue,
                  input: headerValue,
                },

                days: col.days.map(d => {
                  const day = range(0, 3)
                    .map(cellIdx => d.worktimes[cellIdx])
                    .map(wt => ({ time: Minutes.apiValueOf(wt) }));

                  return {
                    date: fromApiDate(d.date),
                    errors: errorMessages(d.errors),
                    warnings: warningMessages(d.errors),
                    infos: infoMessages(d.errors),
                    input: day,
                    origin: day,
                    computed: day,
                  };
                }),
              };
            });

            self.pjReport = PJReport.create({
              userId: self.userId,
              month: self.month,
              totalPillar: {
                days: [...daysOfMonth(self.month)].map((d, idx) => ({
                  date: d,
                  errors: errorMessages(projectReport.otherColumn.days[idx].errors),
                  warnings: warningMessages(projectReport.otherColumn.days[idx].errors),
                  infos: infoMessages(projectReport.otherColumn.days[idx].errors),
                })),
                errors: errorMessages(projectReport.otherColumn.errors),
                warnings: warningMessages(projectReport.otherColumn.errors),
                infos: infoMessages(projectReport.otherColumn.errors),
              },
              pillars,
              errors: errorMessages(projectReport.errors),
              warnings: warningMessages(projectReport.errors),
              infos: infoMessages(projectReport.errors),
            });
          }
        }
      })();

      if (!onlyValidate) {
        self.lastLoadTime = timeProvider().currentTime;
        self.loadingStatus = "loaded";
      }
    };

    const save = flow(function*(onlyValidate = false) {
      const { regularKintai, sPortKintai, pjReport, attrs } = self;
      if (!regularKintai || !sPortKintai || !pjReport || !attrs) {
        return;
      }

      if (!onlyValidate) {
        if (
          !self.isMine &&
          !(yield appConfirm().confirm({
            title: "保存の確認",
            message: "この勤怠表はあなたの勤怠ではありませんが、保存しますか？",
          }))
        ) {
          return;
        }

        self.inSaving = true;
        self.loadingStatus = "loading";
      }
      const myVersion = validateVersion(onlyValidate ? 1 : 1000);

      const payload: KintaiReportEditEntity = {
        attributes: {
          designatedHours: attrs.designatedMinutes ? attrs.designatedMinutes.asApiEntity : { minutes: 0 },
          workingType: fromMainKintaiTypeToWorkingType(self.mainKintaiType),
        },

        saveTarget: self.savedTargets,

        projectColumns: pjReport.livingPillars.map(pillar => ({
          days: pillar.days.map(pd => ({
            date: toApiLocalDate(pd.date)!,
            worktimes: pd.cells.map(c => (c.value ? c.value.asApiEntity : undefined)) as MinutesEntity[],
          })),
          total: {
            labels: pillar.header.labels.map(it => it.value),
            orderCode: pillar.header.odCode.value || undefined,
            projectCode:
              pjReport.projectInputStyle === "pjshushi" ? pillar.header.pjCode.value || undefined : undefined,
          },
        })),

        regularDays: regularKintai.days.map(day => {
          const kintaiTypes = day.input.types.map(t => t.code || null);

          return {
            date: toApiLocalDate(day.date)!,

            types: kintaiTypes as any[],
            transDay: day.transDay.value ? toApiLocalDate(day.transDay.value) : undefined,

            hourlyLeave: day.hourlyLeave.value,
            hourlyNursingcare: day.hourlyNursingcare.value,
            hourlyFamilycare: day.hourlyFamilycare.value,

            actual: day.actual.value ? day.actual.value.asApiEntity : undefined,
            allNightOff: day.allNightOff.value ? day.allNightOff.value.asApiEntity : undefined,
            arrival: day.arrival.value ? day.arrival.value.intoDate(day.date) : undefined,
            leave: day.leave.value ? day.leave.value.intoDate(day.date) : undefined,
            diff: day.diff.value ? day.diff.value.asApiEntity : undefined,
            flex: day.flex.value ? day.flex.value.asApiEntity : undefined,
            holiday: day.holiday.value ? day.holiday.value.asApiEntity : undefined,
            midnight: day.midnight.value ? day.midnight.value.asApiEntity : undefined,
            off: day.off.value ? day.off.value.asApiEntity : undefined,
            offCount: day.offCount.value === undefined ? day.offCount.value : undefined,
            over: day.over.value ? day.over.value.asApiEntity : undefined,
            overHoliday: day.overHoliday.value ? day.overHoliday.value.asApiEntity : undefined,
            overHolidayMidnight: day.overHolidayMidnight.value ? day.overHolidayMidnight.value.asApiEntity : undefined,
            growingOver: day.growingOver.value ? day.growingOver.value.asApiEntity : undefined,
            overWeekday: day.overWeekday.value ? day.overWeekday.value.asApiEntity : undefined,
            overWeekdayMidnight: day.overWeekdayMidnight.value ? day.overWeekdayMidnight.value.asApiEntity : undefined,
            remarks: day.remarks.value,
            rest: day.rest.value ? day.rest.value.asApiEntity : undefined,
            spHoliday: day.spHoliday.value ? day.spHoliday.value.asApiEntity : undefined,
            spHolidayMidnight: day.spHolidayMidnight.value ? day.spHolidayMidnight.value.asApiEntity : undefined,
            spWeekday: day.spWeekday.value ? day.spWeekday.value.asApiEntity : undefined,
            spWeekdayMidnight: day.spWeekdayMidnight.value ? day.spWeekdayMidnight.value.asApiEntity : undefined,
            workplaces: day.workplacesApiValues,
            unusedRestTimes: day.unusedRestTimesApiValues,
          };
        }),
        sportDays: sPortKintai.days.map(day => {
          const kintaiTypes = day.input.types.map(t => t.code || null);

          return {
            date: toApiLocalDate(day.date)!,

            types: kintaiTypes as any[],
            transDay: day.transDay.value ? toApiLocalDate(day.transDay.value) : undefined,

            hourlyLeave: day.hourlyLeave.value,
            hourlyNursingcare: day.hourlyNursingcare.value,
            hourlyFamilycare: day.hourlyFamilycare.value,

            arrival: day.arrival.value ? day.arrival.value.intoDate(day.date) : undefined,
            leave: day.leave.value ? day.leave.value.intoDate(day.date) : undefined,
            diff: day.diff.value ? day.diff.value.asApiEntity : undefined,
            rest: day.rest.value ? day.rest.value.asApiEntity : undefined,
            actual: day.actual.value ? day.actual.value.asApiEntity : undefined,
            midnight: day.midnight.value ? day.midnight.value.asApiEntity : undefined,
            immunity: day.immunity.value ? day.immunity.value.asApiEntity : undefined,
            growingTime: day.growingTime.value ? day.growingTime.value.asApiEntity : undefined,
            missed: day.missed.value ? day.missed.value.asApiEntity : undefined,

            overDiff: day.overDiff.value ? day.overDiff.value.asApiEntity : undefined,
            overRestNormal: day.overRestNormal.value ? day.overRestNormal.value.asApiEntity : undefined,
            overRestMidnight: day.overRestMidnight.value ? day.overRestMidnight.value.asApiEntity : undefined,
            overWeekday: day.overWeekday.value ? day.overWeekday.value.asApiEntity : undefined,
            overWeekdayMidnight: day.overWeekdayMidnight.value ? day.overWeekdayMidnight.value.asApiEntity : undefined,
            overHoliday: day.overHoliday.value ? day.overHoliday.value.asApiEntity : undefined,
            overHolidayMidnight: day.overHolidayMidnight.value ? day.overHolidayMidnight.value.asApiEntity : undefined,
            growingOver: day.growingOver.value ? day.growingOver.value.asApiEntity : undefined,

            offCount: day.offCount.value || 0,
            remarks: day.remarks.value,
          };
        }),
      };

      try {
        const kintaiResult: KintaiReportResult = yield onlyValidate
          ? kintaiApi().validateKintai(
              self.userId,
              self.month.getFullYear(),
              self.month.getMonth() + 1,

              payload,
            )
          : kintaiApi().saveKintai(
              self.userId,
              self.month.getFullYear(),
              self.month.getMonth() + 1,

              payload,
            );

        const satisfiesReflectVersion = self.lastValidateVersion === myVersion;

        if (!onlyValidate) {
          if (returnedResultHasError(kintaiResult.monthlyReport!)) {
            appNotifier().error({
              message: `勤怠表にエラーがあるため、保存できませんでした。内容を確認してください。`,
            });
            reflectKintaiResult(kintaiResult, true);
            return;
          }

          appNotifier().info({ message: `勤怠表を保存しました。` });
          reflectKintaiResult(kintaiResult, false);
        } else {
          if (satisfiesReflectVersion) {
            reflectKintaiResult(kintaiResult, onlyValidate);
          } else {
            console.log("Failed to match satisfied version", self.lastValidateVersion, ">", myVersion);
          }
        }
      } catch (exception) {
        if (!onlyValidate) {
          appNotifier().error({ message: "勤怠保存中にエラーが発生しました。", exception });
        }
      } finally {
        self.inSaving = false;
        if (!onlyValidate) {
          self.loadingStatus = "loaded";
        }
      }
    });

    const validate = flow(function*() {
      if (self.loadingStatus !== "loaded") {
        return;
      }
      self.validatingStatus = "loading";
      yield save(true);
      self.validatingStatus = "loaded";
    });

    const load = flow(function*() {
      if (self.inLoading === true) {
        return;
      }
      self.loadingStatus = "loading";
      self.inLoading = true;
      try {
        yield loadApprovers();
        yield loadJobNos();
        const kintaiResult: KintaiReportResult = yield kintaiApi().getKintai(
          self.userId,
          self.month.getFullYear(),
          self.month.getMonth() + 1,
        );
        reflectKintaiResult(kintaiResult);

        self.loadingStatus = "loaded";
      } catch (exception) {
        appNotifier().error({ message: "勤怠表の読み込みに失敗しました。", exception });
        self.loadingStatus = "failed";
        self.loadingErrorMessage = `勤怠表の読み込みに失敗しました: ${exception.message || ""}`;
      } finally {
        self.inLoading = false;
      }
    });

    const loadJobNos = flow(function*() {
      try {
        const result: JobNoListResult = yield kintaiApi().getJobNos(
          self.userId,
          self.month.getFullYear(),
          self.month.getMonth() + 1,
        );

        applySnapshot(
          self.availableJobNos,
          result.jobNos.map(it => ({
            jobNo: it.jobNo,
            jobName: it.jobName,
          })),
        );
      } catch (exception) {
        console.log("Error while loading JobNos. (But ignored)", exception);
      }
    });

    const loadApprovers = flow(function*() {
      self.approverLoadingStatus = "loading";
      const result: ApproversResult = yield kintaiApi().getApprovers(
        self.userId,
        self.month.getFullYear(),
        self.month.getMonth() + 1,
      );
      const ourusers = users();
      applySnapshot(
        self.approvers,
        result.users.map(u => ourusers.mergeFromUserEntity(u).userId),
      );
    });

    const loadApproversIfNeeded = () => {
      return self.approverLoadingStatus === "loaded" ? new Promise<void>(done => done()) : loadApprovers();
    };

    const loadIfNeeded = () => {
      return self.loadingStatus === "loaded" ? new Promise<void>(done => done()) : load();
    };

    const setTimestamp = flow(function*(type: "self" | "clerk" | "review" | "department" | "hrm") {
      if (
        !self.isPastMonthKintai &&
        !(yield appConfirm().confirm({
          title: "まだ当月以降の勤怠です",
          message:
            "この勤怠表の締め処理にはまだ早いですが、操作を続行しますか？（※勤怠表の翌月以降にならないとこのメッセージは出現します）",
        }))
      ) {
        return;
      }

      if (
        type === "self" &&
        !(yield appConfirm().confirm({
          title: "勤怠表提出の確認",
          message: "この勤怠表に問題がないと確認し、提出可能として押印しますか？",
        }))
      ) {
        return;
      }

      try {
        yield kintaiApi().setTimestamp(self.userId, self.month.getFullYear(), self.month.getMonth() + 1, type);
        appNotifier().info({ message: `タイムスタンプを押印しました` });
      } catch (exception) {
        appNotifier().error({ message: "タイムスタンプの押印に失敗しました", exception });
      }
      validate();
    });

    const clearTimestamp = flow(function*(type: "self" | "clerk" | "review" | "department" | "hrm") {
      if (
        !(yield appConfirm().confirm({
          title: "タイムスタンプ消去の確認",
          message: "押印（タイムスタンプ）を消去してもよろしいでしょうか？",
        }))
      ) {
        return;
      }
      try {
        yield kintaiApi().clearTimestamp(self.userId, self.month.getFullYear(), self.month.getMonth() + 1, type);
        appNotifier().info({ message: `タイムスタンプを消去しました` });
      } catch (exception) {
        appNotifier().error({ message: "タイムスタンプの消去に失敗しました", exception });
      }
      validate();
    });

    const derivesAttrsFromLastMonth = flow(function*(options: KintaiAttrCopyOptions) {
      // 相互依存になるので型のチェックが通らない
      yield findKintaiUser(self).copyAttrsFromLastMonth(self as any, options);
      if (options.copyApprovers) {
        self.attrs!.saveReviewerAndApprovers();
      }
    });

    const forceAttributeRecalc = flow(function*() {
      if (
        !(yield appConfirm().confirm({
          title: "強制再設定の確認",
          message: "勤怠表の属性（部門等）を現在のものに強制的に置き換えますが、よろしいですか？",
        }))
      ) {
        return;
      }

      try {
        yield kintaiApi().refreshKintaiAttributes(self.userId, self.month.getFullYear(), self.month.getMonth() + 1);
        appNotifier().info({ message: `勤怠表の属性を強制再設定しました。` });
        load();
      } catch (exception) {
        appNotifier().error({ message: "勤怠表の属性の強制再設定に失敗しました。", exception });
      }
    });

    const forceRecalc = flow(function*() {
      if (
        !(yield appConfirm().confirm({
          title: "強制再計算の確認",
          message: "DB上の値を現ルールに従って再計算します。（所属の変更などは行いません）",
        }))
      ) {
        return;
      }

      try {
        yield kintaiApi().recalcKintai(self.userId, self.month.getFullYear(), self.month.getMonth() + 1);
        appNotifier().info({ message: `勤怠表を強制再計算しました。` });
        load();
      } catch (exception) {
        appNotifier().error({ message: "勤怠表の強制再計算に失敗しました。", exception });
      }
    });

    const deleteRetiredKintai = flow(function*() {
      if (
        !(yield appConfirm().confirm({
          title: "退職者の勤怠表削除の確認",
          message: "退職翌月以後の勤怠表のみ削除可能です。削除しますか？（条件にあわない場合は失敗します）",
        }))
      ) {
        return;
      }

      try {
        yield kintaiApi().removeKintai(self.userId, self.month.getFullYear(), self.month.getMonth() + 1);
        appNotifier().info({ message: `勤怠表を削除しました。` });
        load();
      } catch (exception) {
        appNotifier().error({ message: "勤怠表の削除に失敗しました。", exception });
      }
    });

    const checkIfOtherKintaiAppReviewer = flow(function*() {
      if (self.isMine) {
        return true;
      }
      return yield appConfirm().confirm({
        title: "他人勤怠の査閲者・承認者変更の確認",
        message: "自分の勤怠表ではありませんが、査閲者・承認者の変更を続行しますか？",
      });
    });

    const setReviewer = flow(function*(userId: string | undefined) {
      if (!(yield checkIfOtherKintaiAppReviewer())) {
        return;
      }

      if (userId) {
        const user = users().getUserFromId(userId);
        if (user) {
          self.attrs!.saveReviewer(user);
        }
      } else {
        self.attrs!.removeReviewer();
      }
    });
    const setApprover = flow(function*(userId: string | undefined) {
      if (!(yield checkIfOtherKintaiAppReviewer())) {
        return;
      }

      if (userId) {
        const user = users().getUserFromId(userId);
        if (user) {
          self.attrs!.saveApprover(user);
        }
      } else {
        self.attrs!.removeApprover();
      }
    });
    const bulkApprove = flow(function*() {
      const targetDays = self.bulkApprovableRegularDays;
      if (
        !(yield appConfirm().confirm({
          title: "在宅のみ一括承認の確認",
          message: `勤怠表上の在宅勤務のみの勤怠申請${
            targetDays.length
          }件について、一括承認しますか？（承認コメント無しで承認されます）
          申請日付: ${targetDays.map(it => moment(it.date).format("DD(ddd)")).join(" ")}`,
        }))
      ) {
        return;
      }

      try {
        self.inSaving = true;
        let succeededCount = 0;
        let errorCount = 0;
        for (const target of targetDays) {
          try {
            yield kintaiAppApi().approveApp(self.userId, target.date, { approverComment: "" });
            succeededCount++;
          } catch (exception) {
            appNotifier().error({
              message: `${moment(target.date).format("YYYY/MM/DD")}: 一括承認に失敗しました`,
              exception,
            });
            errorCount++;
          }
        }
        appNotifier().info({
          message: `一括承認が完了しました（成功: ${succeededCount}件, 失敗: ${errorCount}件）`,
        });
      } finally {
        self.inSaving = false;
        load();
      }
    });
    return {
      loadIfNeeded,
      loadApproversIfNeeded,
      load,
      save,
      validate,
      reset,
      setTimestamp,
      clearTimestamp,
      setReviewer,
      setApprover,
      setRetainAlways(value: boolean) {
        self.retainAlways = value;
      },
      reflectKintaiResult,
      derivesAttrsFromLastMonth,
      bulkApprove,
      forceRecalc,
      forceAttributeRecalc,
      deleteRetiredKintai,
      release() {
        applySnapshot(self, {
          id: self.id,
          userId: self.userId,
          userName: self.userName,
          month: self.month,
        });
      },
    };
  })
  .actions(self => {
    return {
      triggerValidate: debounce(self.validate, 1000),
    };
  })
  .actions(self => ({
    afterCreate() {
      onPatch(self, patch => {
        if (patch.path.indexOf("/input/") >= 0) {
          // 少しだけ間をおいて
          setTimeout(() => {
            self.triggerValidate();
          }, 100);
        }
      });
    },
  }));

const findKintaiUser = (self: any) => {
  return getParent(getParent(self)) as any;
};

export interface KintaiAttrCopyOptions {
  copyApprovers?: boolean;
  copyPJReportPilalrs?: boolean;
}

export function copyValuesFromAnotherMonthlyKintai(
  src: typeof MonthlyKintai.Type,
  dest: typeof MonthlyKintai.Type,
  options: KintaiAttrCopyOptions,
) {
  if (options.copyApprovers) {
    if (dest.attrs && src.attrs) {
      if (src.attrs.approver) {
        dest.attrs.input.setApprover(src.attrs.approver);
      }
      if (src.attrs.reviewer) {
        dest.attrs.input.setReviewer(src.attrs.reviewer);
      }
    }
  }
  if (options.copyPJReportPilalrs) {
    if (dest.pjReport && src.pjReport) {
      derivePillars(src.pjReport, dest.pjReport);
    }
  }
}

export const idMonthlyKintai = (userId: string, month: Date) =>
  `${idPrefix}${userId}_${month.getFullYear()}_${month.getMonth()}`;
export const MonthlyKintai: MonthlyKintaiModelType = model;
type MonthlyKintaiInferredType = typeof model;
export interface MonthlyKintaiModelType extends MonthlyKintaiInferredType {}
