interface ErrorMessage {
  message: string;
  level: "WARN" | "ERROR" | "INFO" | string;
}

export const errorMessages = (errors: ErrorMessage[]) =>
  errors.filter(it => it.level === "ERROR").map(it => it.message);

export const warningMessages = (errors: ErrorMessage[]) =>
  errors.filter(it => it.level === "WARN").map(it => it.message);

export const infoMessages = (errors: ErrorMessage[]) =>
  errors.filter(it => it.level === "INFO").map(it => it.message);
