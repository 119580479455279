import { IModelType, types } from "mobx-state-tree";

import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { typesMinutes } from "../primitives/Minutes";
import { prefixedIdType } from "../utils";
import { SaburokuEntryDetails } from "./SaburokuEntryDetails";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "SaburokuEntry_";

const model = types
  .model("SaburokuEntry", {
    id: prefixedIdType(idPrefix),

    rankCode: types.string,
    rankName: types.string,
    isManager: types.boolean,

    depCode: types.string,
    depName: types.string,

    userId: types.string,
    userName: types.string,
    targetMonth: types.Date,

    thisMonth: SaburokuEntryDetails,
    lastMonth: SaburokuEntryDetails,
    monthBeforeLast: SaburokuEntryDetails,

    lawOverUntilLastMonth: typesMinutes,
    overFortyFiveCountUntilLastMonth: types.number,
  })
  .views(self => {
    return {
      get searchedWords() {
        return [self.userId, self.userName, self.depCode, self.depName, self.rankCode, self.rankName];
      },
    };
  })
  .views(self => {
    return {
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(self.searchedWords, searchWords);
      },
    };
  });

export const idSaburokuEntry = (userId: string, targetMonth: Date) =>
  `${idPrefix}${userId}_${targetMonth.toISOString()}`;

export type SaburokuEntryType = typeof SaburokuEntry.Type;
export const SaburokuEntry: SaburokuEntryModelType = model;
type SaburokuEntryInferredType = typeof model;
export interface SaburokuEntryModelType extends SaburokuEntryInferredType {}
