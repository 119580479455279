import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

import { PageRibbonLine } from "../page/PageRibbonLine";
import { PageRibbonSection } from "../page/PageRibbonSection";

export interface ReloadRibbonProps {
  disabled?: boolean;
  label?: string;
  lastLoadTime?: Date;
  onReload?: () => void;
}

const LastLoadTime = styled.span`
  display: block;
  border-top: 1px dashed #ccc;
  font-size: 70%;
  line-height: 100%;
  padding-top: 0.3ex;
  padding-bottom: 0.2ex;
`;

const LastLoadTitle = styled.span`
  display: block;
`;

const LastLoadHour = styled.span`
  display: block;
`;

export const ReloadRibbon = (props: ReloadRibbonProps) => {
  const { disabled, onReload, label, lastLoadTime } = props;

  const reloadButton = (
    <Button disabled={disabled} onClick={onReload} small={true} icon="refresh">
      {label || "再読込"}
      {lastLoadTime && (
        <>
          <LastLoadTime>
            <LastLoadTitle>(最終読込)</LastLoadTitle>
            <LastLoadHour>{moment(lastLoadTime).format("HH:mm:ss")}</LastLoadHour>
          </LastLoadTime>
        </>
      )}
    </Button>
  );

  return <PageRibbonSection title="再読込">{<PageRibbonLine>{reloadButton}</PageRibbonLine>}</PageRibbonSection>;
};
