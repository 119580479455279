import { observer } from "mobx-react";
import React from "react";

import { ButtonField } from "../../../common/form/ButtonField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcPasswordInitCellVM {
  onOpenPasswordInitDialog: () => void;
  passwordInitDisabled: boolean;
}

@observer
export class AcPasswordInitCell extends React.Component<{ model: AcPasswordInitCellVM }> {
  public render() {
    const { model } = this.props;
    return (
      <ButtonField
        mode="cell"
        disabled={model.passwordInitDisabled}
        onClick={model.onOpenPasswordInitDialog}
        cellPosition={NULL_CELL_POSITION}>
        初期化
      </ButtonField>
    );
  }
}
