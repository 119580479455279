import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Menu,
  ProgressBar,
  Switch,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import styled from "@emotion/styled";
import Help from "@webkintai/help/content/manuals/overview/login.md";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoginMethod } from "../../../models/login/LoginMethod";
import { decorateTitle } from "../../../utils/apptitle";
import { passwordPlaceholder } from "../../../utils/password";
import { GuideButton } from "../../common/guide/GuideButton";
import { PasswordChangeAppConsole, PasswordChangeAppConsoleVM } from "./PasswordChangeAppConsole";
import { IconNames } from "@blueprintjs/icons";
import { LoadingStatusType } from "../../../models/common/LoadingStatusType";

export interface LoginConsoleVM {
  passwordChangeAppConsoleVM: PasswordChangeAppConsoleVM;
  loginMessage: string | undefined;
  showConsole: boolean;
  inputLocked: boolean;
  loginMethod: typeof LoginMethod.Type;
  onChangeLoginMethod: (value: typeof LoginMethod.Type) => void;
  oidcLoadingStatus: LoadingStatusType;
  oidcSites: Array<{ id: string; name: string; url: string }>;
  userId: string;
  onChangeUserId: (value: string) => void;
  password: string;
  onChangePassword: (value: string) => void;
  rememberLogin: boolean;
  onChangeRememberLogin: (value: boolean) => void;
  allowPushSubmit: boolean;
  onSubmitLogin: () => void;
  loginDurationDays: number;
  onOpenPasswordChangeApp: () => void;
}

const GuideButtonContainer = styled.div`
  float: right;
`;

const ForgotPassword = styled.a`
  margin-top: 2ex;
  padding-top: 0.5ex;
  font-size: 80%;
  display: block;
  text-align: center;
`;

@observer
export class LoginConsole extends React.Component<{ model: LoginConsoleVM }> {
  private userNameRef = React.createRef<HTMLInputElement>();

  public componentDidMount() {
    setTimeout(() => {
      if (this.userNameRef.current) {
        this.userNameRef.current.focus();
      }
    }, 0);
  }

  public render() {
    const { model } = this.props;

    return (
      <>
        <Dialog
          isOpen={model.showConsole}
          title="ログインが必要です"
          canEscapeKeyClose={false}
          canOutsideClickClose={false}
          autoFocus={true}
          isCloseButtonShown={false}>
          {model.showConsole && (
            <Helmet>
              <title>{decorateTitle("ログインが必要です")}</title>
            </Helmet>
          )}

          <div className={Classes.DIALOG_BODY}>
            {model.loginMessage && <p>{model.loginMessage}</p>}

            <GuideButtonContainer>
              <GuideButton markdown={Help} />
            </GuideButtonContainer>

            <Tabs id="LoginMethodTabs" selectedTabId={model.loginMethod} onChange={this.onChangeLoginMethodTab}>
              <Tab id={"LoginMethodOIDC"} title="連携ログイン" />
              <Tab id={"LoginMethodIDPassword"} title="ID/パスワード" />
            </Tabs>
            <LoginTabContent>
              {model.loginMethod === "LoginMethodOIDC" && (
                <ButtonGroup large={true} vertical={true} fill={true} alignText={"left"}>
                  {model.oidcLoadingStatus !== "loaded" && (
                    <Button disabled={true}>
                      <ProgressBar />
                    </Button>
                  )}
                  {model.oidcLoadingStatus === "loaded" && (
                    <>
                      {model.oidcSites.length > 0 &&
                        model.oidcSites.map(it => (
                          <Button
                            key={it.id}
                            icon={IconNames.LOG_IN}
                            onClick={() => {
                              window.location.href = it.url;
                            }}>
                            {it.name} でログインする
                          </Button>
                        ))}
                      {model.oidcSites.length === 0 && (
                        <Button disabled={true}>利用可能な連携サイトがありません</Button>
                      )}
                    </>
                  )}
                </ButtonGroup>
              )}
              {model.loginMethod === "LoginMethodIDPassword" && (
                <form
                  method="get"
                  action="javascript:undefined"
                  onKeyPress={this.onEnterForm}
                  onSubmit={this.onSubmitForm}>
                  <FormGroup label="社員番号" labelFor="login-form-logind">
                    <input
                      name="userId"
                      id="login-form-logind"
                      autoComplete="username"
                      ref={this.userNameRef}
                      className={Classes.INPUT}
                      disabled={model.inputLocked}
                      placeholder="社員番号(半角数字)"
                      type="text"
                      onChange={this.onChangeUserId}
                      value={model.userId}
                    />
                  </FormGroup>

                  <FormGroup
                    label={<span>パスワード</span>}
                    labelFor="login-form-password"
                    helperText="ログインに複数回連続して失敗した場合、15分ほどアカウントがロックされます。">
                    <input
                      name="password"
                      id="login-form-password"
                      autoComplete="current-password"
                      disabled={model.inputLocked}
                      className={Classes.INPUT}
                      placeholder={passwordPlaceholder}
                      type="password"
                      onChange={this.onChangePassword}
                      value={model.password}
                    />
                  </FormGroup>

                  <FormGroup
                    label="ログインを記憶する"
                    labelFor="login-remember-login"
                    disabled={model.inputLocked}
                    helperText={`ブラウザを閉じた後もログインを継続する場合は有効にしてください。（${model.loginDurationDays}日間有効）`}>
                    <Switch
                      id="login-remember-login"
                      checked={model.rememberLogin}
                      onChange={this.onChangeRememberLogin}
                    />
                  </FormGroup>
                  <Button
                    type="submit"
                    disabled={!model.allowPushSubmit || model.inputLocked}
                    onClick={model.onSubmitLogin}
                    intent={Intent.PRIMARY}
                    fill={true}
                    large={true}
                    text={model.userId ? `${model.userId}としてログイン` : "ログイン"}
                  />
                  <ForgotPassword href="javascript: undefined" onClick={model.onOpenPasswordChangeApp}>
                    パスワードを忘れた場合
                  </ForgotPassword>
                </form>
              )}
            </LoginTabContent>
          </div>
        </Dialog>
        <PasswordChangeAppConsole model={model.passwordChangeAppConsoleVM} />
      </>
    );
  }

  private onEnterForm = (ev: React.KeyboardEvent) => {
    if (ev.charCode === 13) {
      this.onSubmitForm();
    }
  };

  private onSubmitForm = () => {
    if (this.props.model.allowPushSubmit) {
      this.props.model.onSubmitLogin();
    }
  };

  private onChangeLoginMethodTab = (newTabId: string) => {
    this.props.model.onChangeLoginMethod(newTabId as typeof LoginMethod.Type);
  };

  private onChangeUserId: React.ReactEventHandler<HTMLInputElement> = ev =>
    this.props.model.onChangeUserId(ev.currentTarget.value);

  private onChangePassword: React.ReactEventHandler<HTMLInputElement> = ev =>
    this.props.model.onChangePassword(ev.currentTarget.value);

  private onChangeRememberLogin: React.ReactEventHandler<HTMLInputElement> = ev =>
    this.props.model.onChangeRememberLogin(ev.currentTarget.checked);
}

const LoginTabContent = styled.div`
  padding-top: 1rem;
  min-height: min(80vh, 25rem);
`;
