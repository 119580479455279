import { Button, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

export interface KintaiTimestampButtonProps {
  label: string;
  checkedLabel: string;
  timestamp?: KintaiTimestamp;
  disabled: boolean;
  onTimestamp: () => void;
  onRemoveTimestamp: () => void;
}

export interface KintaiTimestamp {
  userName: string;
  at: Date;
}

const TimeMain = styled.div`
  font-family: monospace;
  font-size: 80%;
  line-height: 95%;
`;
const TimeSub = styled.div`
  font-family: monospace;
  font-size: 50%;
  line-height: 95%;
`;

export const KintaiTimestampButton = (props: KintaiTimestampButtonProps) => {
  const { timestamp, disabled, onTimestamp, onRemoveTimestamp, label, checkedLabel } = props;

  if (!timestamp) {
    return (
      <Button
        icon="star-empty"
        disabled={disabled}
        small={true}
        intent={Intent.NONE}
        text={`${label}`}
        onClick={onTimestamp}
      />
    );
  }
  return (
    <Button icon="star" disabled={disabled} small={true} intent={Intent.SUCCESS} onClick={onRemoveTimestamp}>
      <TimeMain>
        {moment(timestamp.at).format("MM/DD")} {checkedLabel}
      </TimeMain>
      <TimeSub>
        ({moment(timestamp.at).format("HH:mm")} {timestamp.userName})
      </TimeSub>
    </Button>
  );
};
