import { computed } from "mobx";

import { valueOnlyKintaiInputItemState } from "../../../../models/kintai/kintaiinput/ValueOnlyKintaiItem";
import { KintaiListEntry } from "../../../../models/kintai_list/KintaiListEntry";
import { KintaiListPageModel } from "../../../../models/kintai_list/KintaiListPageModel";
import { CommonKintaiListPageGridRowVMImpl } from "../common/CommonKintaiListPageGridRowVMImpl";
import { SPortKintaiListPageGridRowVM } from "./SPortKintaiListPageGridRowVM";

export class SPortKintaiListPageGridRowVMImpl extends CommonKintaiListPageGridRowVMImpl
  implements SPortKintaiListPageGridRowVM {
  constructor(pageModel: typeof KintaiListPageModel.Type, monthlyKintai: typeof KintaiListEntry.Type) {
    super(pageModel, monthlyKintai);
  }

  @computed
  private get total() {
    return this.monthlyKintai.sportTotal!;
  }

  @computed
  public get mTotalLeaveDays() {
    return valueOnlyKintaiInputItemState(this.total.leaveDays);
  }
  @computed
  public get mTotalAllNightCount() {
    return valueOnlyKintaiInputItemState(this.total.allNightCount);
  }
  @computed
  public get mTotalAllNightOffDays() {
    return valueOnlyKintaiInputItemState(this.total.allNightOffDays);
  }
  @computed
  public get mTotalCompDays() {
    return valueOnlyKintaiInputItemState(this.total.compDays);
  }
  @computed
  public get mTotalHolidayDays() {
    return valueOnlyKintaiInputItemState(this.total.holidayDays);
  }
  @computed
  public get mTotalMidnight() {
    return valueOnlyKintaiInputItemState(this.total.midnight);
  }
  @computed
  public get mTotalMissed() {
    return valueOnlyKintaiInputItemState(this.total.missed);
  }
  @computed
  public get mTotalNormalDays() {
    return valueOnlyKintaiInputItemState(this.total.normalDays);
  }
  @computed
  public get mTotalOverHoliday() {
    return valueOnlyKintaiInputItemState(this.total.overHoliday);
  }
  @computed
  public get mTotalOverHolidayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overHolidayMidnight);
  }
  @computed
  public get mTotalOver() {
    return valueOnlyKintaiInputItemState(this.total.over);
  }
  @computed
  public get mTotalOverWeekday() {
    return valueOnlyKintaiInputItemState(this.total.overWeekday);
  }
  @computed
  public get mTotalOverWeekdayMidnight() {
    return valueOnlyKintaiInputItemState(this.total.overWeekdayMidnight);
  }
  @computed
  public get mTotalPaidAllDays() {
    return valueOnlyKintaiInputItemState(this.total.paidAllDays);
  }
  @computed
  public get mTotalPositiveDays() {
    return valueOnlyKintaiInputItemState(this.total.positiveDays);
  }
  @computed
  public get mTotalOffCount() {
    return valueOnlyKintaiInputItemState(this.total.offCount);
  }

  @computed
  public get mTotalSickoutDays() {
    return valueOnlyKintaiInputItemState(this.total.sickoutDays);
  }
  @computed
  public get mTotalSummerDays() {
    return valueOnlyKintaiInputItemState(this.total.summerDays);
  }
  @computed
  public get mTotalTransOffDays() {
    return valueOnlyKintaiInputItemState(this.total.transOffDays);
  }
  @computed
  public get mTotalTransOnDays() {
    return valueOnlyKintaiInputItemState(this.total.transOnDays);
  }
  @computed
  get mTotalNonAttendanceDays() {
    return valueOnlyKintaiInputItemState(this.total.nonAttendanceDays);
  }
  @computed
  get mTotalPaidDays() {
    return valueOnlyKintaiInputItemState(this.total.paidDays);
  }
  @computed
  get mTotalOtherDays() {
    return valueOnlyKintaiInputItemState(this.total.otherDays);
  }
  @computed
  get mTotalOtherHours() {
    return valueOnlyKintaiInputItemState(this.total.otherHours);
  }
  @computed
  get mTotalPaidHalfDays() {
    return valueOnlyKintaiInputItemState(this.total.paidHalfDays);
  }
  @computed
  get mTotalSpDays() {
    return valueOnlyKintaiInputItemState(this.total.spDays);
  }
  @computed
  get mTotalGivenWork() {
    return valueOnlyKintaiInputItemState(this.total.givenWork);
  }
}
