import { NonIdealState } from "@blueprintjs/core";
import React from "react";

import { FaultFrame } from "./FaultFrame";

export const NoDataWidgetContent: React.FC<{ className?: string; children?: never }> = ({ className }) => {
  return (
    <FaultFrame className={className}>
      <NonIdealState icon="circle" title="データがありません" />
    </FaultFrame>
  );
};
