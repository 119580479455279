import { sleep } from "../utils/async";
import { CronTasks } from "./CronTasks";

export class CronTasksImpl extends CronTasks {
  private version: number = 0;

  public init() {
    this.version++;
  }

  public registerTask(interval: number, task: () => Promise<unknown>) {
    const localVersion = this.version;
    (async () => {
      while (true) {
        await sleep(interval);

        // バージョンが上がっていれば実施せず廃棄
        if (this.version !== localVersion) {
          return;
        }

        try {
          await task();
        } catch (exception) {}
      }
    })();
  }

  public dispose() {
    this.init();
    console.log(`CronTasks@${this.version} is disposed. => ${this.version}`);
  }
}
