import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalTakenRefreshDaysKFProps = {
  model: MTotalTakenRefreshDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTakenRefreshDaysKFVM {
  mTotalTakenRefreshDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalTakenRefreshDaysKF = observer((props: MTotalTakenRefreshDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalTakenRefreshDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalTakenRefreshDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
