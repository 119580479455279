import { computed } from "mobx";
import { lazyInject } from "../../../didecorators";

import { AppState, AppStateSymbol } from "../../../models/AppState";
import { AppInitMsgVM } from "./AppInitMsg";

export class AppInitMsgVMImpl implements AppInitMsgVM {
  @lazyInject(AppStateSymbol)
  private appState: typeof AppState.Type;

  @computed
  get message() {
    return this.appState.initStatusText;
  }
}
