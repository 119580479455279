import { KintaiReportsControllerApi } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { SaburokuApi } from "./SaburokuApi";

export class SaburokuApiImpl extends SaburokuApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }
  public getLawOverReports(targetMonth: Date) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory
        .generateLoginAssumingApi(KintaiReportsControllerApi)
        .getLawOverReports(targetMonth.getFullYear(), targetMonth.getMonth() + 1),
    );
  }
}
