import { computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { DashboardPageModel, DashboardPageModelSymbol } from "../../../models/dashboard/DashboardPageModel";
import { paths } from "../../../routing/paths";
import { AppRouter } from "../../../services/AppRouter";
import { MyPrivileges } from "../../../services/prv/MyPrivileges";
import { DashboardSubMenuVM } from "./DashboardSubMenu";

export class DashboardSubMenuVMImpl implements DashboardSubMenuVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  @lazyInject(DashboardPageModelSymbol)
  private dashboardPageModel: typeof DashboardPageModel.Type;

  @lazyInject(MyPrivileges)
  private myPrivileges: MyPrivileges;

  @computed
  get pageMode() {
    return this.dashboardPageModel.pageMode;
  }

  @computed
  public get showStatistics() {
    return this.myPrivileges.has("DISP_MENU_STATISTICS");
  }
  public onNavigateToStatistics = () => {
    this.appRouter.navigate(paths.dashboard.statistics.index());
  };

  @computed
  public get showKintaiList() {
    return this.myPrivileges.has("DISP_MENU_KINTAILIST");
  }
  public onNavigateToKintaiList = () => {
    this.appRouter.navigate(paths.dashboard.kintai_list.index());
  };

  @computed
  public get showKintaiSum() {
    return this.myPrivileges.has("DISP_MENU_KINTAISUM");
  }
  public onNavigateToKintaiSum = () => {
    this.appRouter.navigate(paths.dashboard.kintai_sum.index());
  };

  @computed
  public get showTransDay() {
    return this.myPrivileges.has("DISP_MENU_TRANSDAYLIST");
  }
  public onNavigateToTransDay = () => {
    this.appRouter.navigate(paths.dashboard.trans_day.index());
  };

  @computed
  public get showInterviewList() {
    return this.myPrivileges.has("DISP_MENU_MEDLIST");
  }
  public onNavigateToInterviewList = () => {
    this.appRouter.navigate(paths.dashboard.interview_list.index());
  };

  @computed
  public get showSaburoku() {
    return this.myPrivileges.has("DISP_MENU_SABUROKU");
  }
  public onNavigateToSaburoku = () => {
    this.appRouter.navigate(paths.dashboard.saburoku.index());
  };

  public onNavigateToApplications = () => {
    this.appRouter.navigate(paths.dashboard.applications.index());
  };

  @computed
  public get showExport() {
    return this.myPrivileges.has("DISP_MENU_EXPORT");
  }
  public onNavigateToExport = () => {
    this.appRouter.navigate(paths.dashboard.export.index());
  };
}
