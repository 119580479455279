import { observer } from "mobx-react-lite";
import React from "react";

import { usePublicDDModel } from "../../../../../../models/statistics/usePublicDDModel";
import { PubBarChartWidget } from "../../chart/PubBarChartWidget";

export const PubAvgPaidLeavesTakenDescWidgetContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePublicDDModel();
  const data = model.avg_paidLeavesTaken_desc;
  return (
    <PubBarChartWidget
      mood="good"
      title="[年度内] 有休取得日数 (平均) - 上位"
      data={data}
      lastUpdate={model.contentLastUpdate}
    />
  );
});
