import { UserEntity } from "@webkintai/api";
import { IModelType, SnapshotIn, types } from "mobx-state-tree";

import { User, User_Type } from "./User";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("Users", {
      users: types.map(User),
    })
    .views(self => {
      return {
        getUserFromId(userId: string) {
          return self.users.get(userId);
        },
      };
    })
    .actions(self => {
      return {
        mergeFromUserEntity(data: UserEntity): User_Type {
          return this.merge({
            userId: data.userId,

            userName: data.userName || "",
            mailAddress: data.mailAddress || undefined,
            rankCode: data.rankCode || undefined,
            depCode: data.depCode || undefined,
            roleCodes: data.roles.map(it => it.value),
          });
        },

        merge(data: SnapshotIn<User_Type>): User_Type {
          if (self.users.has(data.userId)) {
            return self.users.get(data.userId)!;
          }

          self.users.put(User.create(data));
          return this.merge(data);
        },
      };
    }),
  {},
);

export type UsersType = typeof Users.Type;

export const UsersSymbol = "Users_Symbol";
export const Users: UsersModelType = model;
type UsersInferredType = typeof model;
export interface UsersModelType extends UsersInferredType {}
