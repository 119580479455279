import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLawOverNineKFProps = {
  model: MTotalLawOverNineKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLawOverNineKFVM {
  mTotalLawOverNine: KintaiInputItemState<Minutes>;
}

export const MTotalLawOverNineKF = observer((props: MTotalLawOverNineKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLawOverNine;

  return <HHMMField {...props} label="mTotalLawOverNineKF" {...itemState} onChange={undefined} />;
});
