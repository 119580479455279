import styled from "@emotion/styled";
import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";

import { FormAdaptable } from "../CellFormAdaptable";
import { ErrorOverlay } from "../ErrorOverlay";
import { LabelFieldCoreProps } from "../LabelField";

export const FormLabelField: React.FC<LabelFieldCoreProps & FormAdaptable> = props => {
  const { className } = props;

  const onClick = useCallback(() => {
    if (props.onClick) {
      props.onClick();
    }
  }, [props.onClick]);

  return (
    <ErrorOverlay mode="form" warnings={props.warnings} errors={props.errors}>
      <Form onClick={onClick} className={classNames(className)} style={props.style}>
        {props.children}
      </Form>
    </ErrorOverlay>
  );
};

const Form = styled.div``;
