import { MeControllerApi, ProfileUpdateRequest, UserResult } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { ProfileApi } from "./ProfileApi";

export class ProfileApiImpl extends ProfileApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }
  public getProfile(): Promise<UserResult> {
    return this.apiFactory.guardFailures(() => this.apiFactory.generateLoginAssumingApi(MeControllerApi).getProfile());
  }
  public updateProfile(req: ProfileUpdateRequest): Promise<Response> {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).updateProfile(req),
    );
  }
}
