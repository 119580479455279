import { Button, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { dateOf } from "../../../utils/date";
import { KintaiCalendarSelectorButton } from "../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageHeaderLeft } from "../../common/page/PageHeaderLeft";
import { PageHeaderRight } from "../../common/page/PageHeaderRight";
import { LeftBorderedSubMenuButton, SubMenuButton } from "../../common/page/SubMenuButton";
import { Responsive } from "../../common/responsive/Responsive";
import { KintaiListEntry, KintaiUserSelector, KintaiUserSelectorVM } from "./KintaiUserSelector";

export type KintaiSubMenuVM = {
  date: Date;
  nendoList: number[];

  activeOpenedKintai: KintaiListEntry | undefined;

  myKintaiIsOpened: boolean;

  onChangeTargetDate: (d: Date) => void;
  onMoveDate: (delta: number) => void;
  onMoveMonth: (delta: number) => void;
} & KintaiUserSelectorVM;

const DirectionButton: typeof Button = styled(SubMenuButton)`
  width: 3.5em;
` as any;

const PopoverFrame = styled.div`
  max-height: 25em;
  max-width: 300px;
  display: flex;
`;

const UserId = styled.span`
  display: none;
`;

const UserName = styled.span`
  display: block;
`;

@observer
export class KintaiSubMenu extends React.Component<{ model: KintaiSubMenuVM }> {
  public render() {
    const { model } = this.props;
    // ここで参照しないとobserveできない
    const { date, onChangeTargetDate } = model;

    const monthSelectButton = (
      <KintaiCalendarSelectorButton
        buttonProps={{
          minimal: true,
          small: true,
        }}
        nendoList={model.nendoList}
        unit={"month"}
        currentDate={date}
        onChangeDate={onChangeTargetDate}
      />
    );

    const navigateToTodayButton = (
      <LeftBorderedSubMenuButton text="今日" icon="ninja" onClick={this.onNavigateToToday} />
    );

    const myKintaiButton = (
      <Popover
        minimal={true}
        position={Position.BOTTOM}
        boundary="viewport"
        content={
          <PopoverFrame>
            <KintaiUserSelector model={model} />
          </PopoverFrame>
        }>
        <SubMenuButton
          icon={model.myKintaiIsOpened ? "heart" : "people"}
          rightIcon={"caret-down"}
          text={
            model.myKintaiIsOpened ? (
              "マイ勤怠"
            ) : model.activeOpenedKintai ? (
              <>
                <UserId>{model.activeOpenedKintai.userId}</UserId>
                <UserName>{model.activeOpenedKintai.userName}</UserName>
              </>
            ) : (
              "--"
            )
          }
        />
      </Popover>
    );

    return (
      <Responsive>
        {({ small }) =>
          small ? (
            <>
              <PageHeaderLeft>
                {monthSelectButton}
                {navigateToTodayButton}
              </PageHeaderLeft>
              <PageHeaderRight>
                {!(model.myKintaiIsOpened && model.kintaiUserList.length <= 1) && myKintaiButton}
              </PageHeaderRight>
            </>
          ) : (
            <>
              <PageHeaderLeft>
                <DirectionButton icon={"double-chevron-left"} onClick={this.onPreviousMonth} />
                {monthSelectButton}
                <DirectionButton icon={"double-chevron-right"} onClick={this.onNextMonth} />
                {navigateToTodayButton}
              </PageHeaderLeft>
              <PageHeaderRight>{myKintaiButton}</PageHeaderRight>
            </>
          )
        }
      </Responsive>
    );
  }

  private onNavigateToToday = () => this.props.model.onChangeTargetDate(dateOf());

  private onPreviousDate = () => {
    this.props.model.onMoveDate(-1);
  };

  private onNextDate = () => {
    this.props.model.onMoveDate(1);
  };

  private onPreviousMonth = () => {
    this.props.model.onMoveMonth(-1);
  };

  private onNextMonth = () => {
    this.props.model.onMoveMonth(1);
  };
}
