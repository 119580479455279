import { NendoDeptType } from "../../../models/depts/NendoDept";
import { DeptSelectorEntry } from "./DeptSelectorEntry";

export class DeptSelectorEntryImpl implements DeptSelectorEntry {
  constructor(private readonly dept: NendoDeptType, private readonly returnChildren: boolean) {}

  get depName() {
    return this.dept.name;
  }

  get depCode() {
    return this.dept.depCode;
  }

  get children() {
    return this.returnChildren
      ? this.dept.childrenDepts.map(c => new DeptSelectorEntryImpl(c, this.returnChildren))
      : [];
  }
}
