import { action, computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { Notification } from "../../../../models/notify/Notification";
import { NotificationPageModel, NotificationPageModelSymbol } from "../../../../models/notify/NotificationPageModel";
import { InboxPageGridRowVM } from "./InboxPageGrid";

export class InboxPageGridRowVMImpl implements InboxPageGridRowVM {
  @lazyInject(NotificationPageModelSymbol)
  private model: typeof NotificationPageModel.Type;

  constructor(private readonly row: typeof Notification.Type) {}

  @computed
  get id() {
    return this.row.id;
  }

  @computed
  get subject() {
    return this.row.subject;
  }

  @computed
  get at() {
    return this.row.publishDate;
  }

  @computed
  get read() {
    return this.row.read;
  }

  @computed
  get active() {
    return this.model.openedMessageId === this.row.id;
  }

  @action.bound
  public onOpenMessage() {
    this.model.openMessage(this.row);
  }

  @action.bound
  public onToggleRead() {
    this.row.toggleRead();
  }
}
