import { flatMap } from "lodash-es";
import { flow, getEnv, IModelType, types } from "mobx-state-tree";

import { KintaiApi, KintaiApiTimestampTypes } from "../../services/api/KintaiApi";
import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { KintaiMonthlyAttributesValues } from "../kintai/attr/KintaiMonthlyAttributesValues";
import { typesKintaiBunrui } from "../kintai/kintaibunrui/typesKintaiBunrui";
import { RegularMonthlyTotalValues } from "../kintai/regular/RegularMonthlyTotalValues";
import { SPortMonthlyTotalValues } from "../kintai/sport/SPortMonthlyTotalValues";
import { KintaiTimestamps } from "../kintai/timestamps/KintaiTimestamps";
import { ProfileSymbol, ProfileType } from "../profile/Profile";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("KintaiListEntry", {
    month: types.Date,
    userId: types.string,

    userName: types.string,
    depName: types.string,
    rankName: types.string,

    attrs: types.maybe(KintaiMonthlyAttributesValues),
    timestamps: types.maybe(KintaiTimestamps),

    errors: types.array(types.string),
    warnings: types.array(types.string),
    infos: types.array(types.string),

    notAppliedErrors: types.array(types.string),
    notApprovedErrors: types.array(types.string),
    priviledgedNotApprovedErrors: types.array(types.string),
    rejectedErrors: types.array(types.string),

    typesSearchIndex: types.array(typesKintaiBunrui),
    regularTotal: types.maybe(RegularMonthlyTotalValues),
    sportTotal: types.maybe(SPortMonthlyTotalValues),

    // 一括承認
    approverBAStamped: types.optional(types.boolean, false),
    clerkBAStamped: types.optional(types.boolean, false),
    hrmBAStamped: types.optional(types.boolean, false),
  })
  .views(self => {
    return {
      get typeNames() {
        return flatMap(self.typesSearchIndex.map(it => [it.code, it.abbrName]));
      },
      get reviewerId() {
        const { attrs } = self;
        return attrs && attrs.reviewer && attrs.reviewer.userId;
      },
      get approverId() {
        const { attrs } = self;
        return attrs && attrs.approver && attrs.approver.userId;
      },
    };
  })
  .views(self => {
    const profile = () => getEnv(self).get(ProfileSymbol) as ProfileType;

    return {
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(
          [self.userId, self.userName, self.depName, self.rankName, ...self.typeNames],
          searchWords,
        );
      },
      get isFlex() {
        return self.attrs && self.attrs.mainKintaiType === "Flex";
      },
      get isNonFlex() {
        return self.attrs && self.attrs.mainKintaiType === "Non-Flex";
      },
      get isSPort() {
        return self.attrs && self.attrs.mainKintaiType === "S-Port";
      },
      get isNotRegistered() {
        return !self.attrs;
      },
      get requireApprovalOnYouErrors() {
        return this.isMeAssigned ? self.notApprovedErrors : [];
      },
      get requireApprovalOnYou() {
        return this.requireApprovalOnYouErrors.length > 0;
      },
      get requireApprovalPrivileged() {
        return self.priviledgedNotApprovedErrors.length > 0;
      },
      get isMeAssigned() {
        const userId = profile().userId;
        if (!self.attrs) {
          return false;
        }

        return (
          (self.attrs.reviewer && self.attrs.reviewer.userId === userId) ||
          (self.attrs.approver && self.attrs.approver.userId === userId)
        );
      },
      get selfStamped() {
        return !!(self.timestamps && self.timestamps.self);
      },
      get reviewerStamped() {
        return !!(self.timestamps && self.timestamps.review);
      },
      get approverStamped() {
        return !!(self.timestamps && self.timestamps.department);
      },
      get approverBAStampable() {
        return self.approverId === profile().userId;
      },
      get approverBAHasNoChange() {
        return this.approverStamped === self.approverBAStamped;
      },
      get clerkStamped() {
        return !!(self.timestamps && self.timestamps.clerk);
      },
      get clerkBAStampable() {
        return profile().hasAbility("DISP_KINTAI_CLERK_TS");
      },
      get clerkBAHasNoChange() {
        return this.clerkStamped === self.clerkBAStamped;
      },
      get hrmStamped() {
        return !!(self.timestamps && self.timestamps.hrm);
      },
      get hrmBAStampable() {
        return profile().hasAbility("DISP_KINTAI_HRM_TS");
      },
      get hrmBAHasNoChange() {
        return this.hrmStamped === self.hrmBAStamped;
      },
      get anyBAStampHasNoChange() {
        return this.approverBAHasNoChange && this.clerkBAHasNoChange && this.hrmBAHasNoChange;
      },
    };
  })
  .actions(self => {
    const kintaiApi = () => getDI(self, KintaiApi);

    const saveBAStamps = flow(function*() {
      const stampIfAny = async (stampIfTrue: boolean, type: KintaiApiTimestampTypes) => {
        const operationType = stampIfTrue ? "押印" : "押印取消";
        const operatedAt = new Date();
        try {
          await (stampIfTrue
            ? kintaiApi().setTimestamp(self.userId, self.month.getFullYear(), self.month.getMonth() + 1, type)
            : kintaiApi().clearTimestamp(self.userId, self.month.getFullYear(), self.month.getMonth() + 1, type));

          return {
            userId: self.userId,
            month: self.month,
            operatedAt,
            type,
            operationType,
            success: true,
          };
        } catch (exception) {
          return {
            userId: self.userId,
            month: self.month,
            operatedAt,
            type,
            operationType,
            success: false,
            exception,
          };
        }
      };

      const results: StampIfAnyResult[] = [];
      if (!self.approverBAHasNoChange) {
        results.push(yield stampIfAny(self.approverBAStamped, "department"));
      }
      if (!self.clerkBAHasNoChange) {
        results.push(yield stampIfAny(self.clerkBAStamped, "clerk"));
      }
      if (!self.hrmBAHasNoChange) {
        results.push(yield stampIfAny(self.hrmBAStamped, "hrm"));
      }

      return results;
    });

    return {
      setApproverBAStamped(value: boolean) {
        if (!self.approverBAStampable) {
          return;
        }
        self.approverBAStamped = value;
      },
      setClerkBAStamped(value: boolean) {
        if (!self.clerkBAStampable) {
          return;
        }
        self.clerkBAStamped = value;
      },
      setHRMBAStamped(value: boolean) {
        if (!self.hrmBAStampable) {
          return;
        }
        self.hrmBAStamped = value;
      },
      resetBAStamped() {
        self.approverBAStamped = self.approverStamped;
        self.clerkBAStamped = self.clerkStamped;
        self.hrmBAStamped = self.hrmStamped;
      },
      saveBAStamps,
    };
  });

export interface StampIfAnyResult {
  userId: string;
  month: Date;
  operatedAt: Date;
  type: KintaiApiTimestampTypes;
  operationType: string;
  success: boolean;
  exception: any;
}
export type KintaiListEntryType = typeof KintaiListEntry.Type;
export const KintaiListEntry: KintaiListEntryModelType = model;
type KintaiListEntryInferredType = typeof model;
export interface KintaiListEntryModelType extends KintaiListEntryInferredType {}
