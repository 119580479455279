import { observer } from "mobx-react";
import React from "react";

import { CellComponentProps, InteractiveCellComponentProps } from "../../../common/form/CellFormAdaptable";
import { CheckboxField } from "../../../common/form/CheckboxField";
import { LabelField } from "../../../common/form/LabelField";
import { CellPosition } from "../../../common/table/CellPosition";
import { CalendarPageGridMonthVM } from "./CalendarPageGrid";

export const CalendarHolidayHeader = observer(
  (props: CellComponentProps & { cellPosition: CellPosition; month: CalendarPageGridMonthVM }) => (
    <LabelField cellPosition={props.cellPosition} mode="cell">
      <br />休
    </LabelField>
  ),
);
@observer
export class CalendarHolidayBody extends React.Component<
  InteractiveCellComponentProps & {
    month: CalendarPageGridMonthVM;
    isActive: boolean;
  }
> {
  public render() {
    const { props } = this;
    const day = props.month.dayAt(props.cellPosition.rowIndex);
    if (!day) {
      return <></>;
    }

    const onChange = day.onChangeIsHoliday;

    return (
      <CheckboxField
        mode="cell"
        cellPosition={props.cellPosition}
        isActive={props.isActive}
        hasNoChange={day.hasNoChange}
        disabled={day.disabled}
        onChange={onChange}
        onCellFocused={props.onCellFocused}
        value={day.isHoliday}
      />
    );
  }
}
