import { Button, Intent, NonIdealState, ProgressBar } from "@blueprintjs/core";
import React from "react";

import { LoadingProps } from "./LaodingProps";

export interface LoadingStateSpreadProps extends LoadingProps {}
export const LoadingStateSpread: React.FunctionComponent<LoadingStateSpreadProps> = props => {
  const { loadingStatus, errorContent, onReloadOnFailure } = props;

  if (loadingStatus === "init") {
    return <NonIdealState icon="circle" title="検索を待っています" />;
  }

  if (loadingStatus === undefined || loadingStatus === "loading") {
    return (
      <NonIdealState title="ローディング中です">
        <ProgressBar />
      </NonIdealState>
    );
  }

  if (loadingStatus === "failed") {
    return (
      <NonIdealState
        icon="error"
        title={errorContent || "データのロードに失敗しました"}
        action={
          onReloadOnFailure ? (
            <Button icon="refresh" text="再読込する" intent={Intent.PRIMARY} onClick={onReloadOnFailure} />
          ) : (
            undefined
          )
        }
      />
    );
  }

  return <></>;
};
