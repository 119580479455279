import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";
import { PageRibbon } from "../../common/page/PageRibbon";
import { SplitView } from "../../common/SplitView";
import { InboxPageGrid, InboxPageGridVM } from "./grid/InboxPageGrid";
import { OpenedMessage, OpenedMessageVM } from "./OpenedMessage";
import { InboxPageRibbon, InboxPageRibbonVM } from "./ribbon/InboxPageRibbon";

export interface InboxPageVM {
  ribbonModel: InboxPageRibbonVM;
  gridModel: InboxPageGridVM;
  openedMessageModel: OpenedMessageVM | null;
  messageFrameSize: number;
  onChangeMesageFrameSize: (value: number) => void;
}

@observer
export class InboxPage extends React.Component<{ model: InboxPageVM }> {
  public render() {
    const { model } = this.props;

    return (
      <PageRibbon
        hideExpandButton={true}
        shrinkRibbon={<InboxPageRibbon model={model.ribbonModel} shrink={true} />}
        ribbon={<InboxPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("通知の一覧")}</title>
        </Helmet>

        <SplitView
          former={<InboxPageGrid model={model.gridModel} />}
          formerSize={model.messageFrameSize}
          min={100}
          max={400}
          onChangeFormerSize={model.onChangeMesageFrameSize}
          latter={
            model.openedMessageModel ? (
              <OpenedMessage model={model.openedMessageModel} />
            ) : (
              <NonIdealState icon="document" title="なにかメッセージを開いてください" />
            )
          }
        />
      </PageRibbon>
    );
  }
}
