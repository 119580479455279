import React from "react";

export interface FileSelectorProps {
  onFileSelect: (file: File) => void;
  children: FileSelectorRenderer;
}

export interface FileSelectorState {
  selectedFile: File | null;
}

export type FileSelectorRenderer = (params: FileSelection) => React.ReactNode;

export interface FileSelection {
  onOpenFileDialog: () => void;
  selectedFile: File | null;
}

export class FileSelector extends React.Component<FileSelectorProps, FileSelectorState> {
  private fileInputRef = React.createRef<HTMLInputElement>();

  constructor(props: FileSelectorProps) {
    super(props);
    this.state = {
      selectedFile: null,
    };
  }

  public render() {
    const onOpenFileDialog = () => {
      const { current } = this.fileInputRef;
      if (!current) {
        return;
      }
      current.click();
      current.onchange = () => {
        if (current.files && current.files[0]) {
          const selectedFile = current.files[0];
          this.setState({ selectedFile });
          this.props.onFileSelect(selectedFile);

          current.value = "";
        }
      };
    };

    return (
      <>
        <input
          ref={this.fileInputRef}
          style={{ display: "block", position: "absolute", top: 0, left: 0, width: 0, height: 0, visibility: "hidden" }}
          type="file"
        />
        {this.props.children({ onOpenFileDialog, selectedFile: this.state.selectedFile })}
      </>
    );
  }
}
