import { RankAttrEditEntity, RankEntity, RanksControllerApi } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { RankApi } from "./RankApi";

export class RankApiImpl extends RankApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public getRank(nendo: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(RanksControllerApi).getRankAttrList(nendo),
    );
  }

  public saveRank(nendo: number, entries: RankAttrEditEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(RanksControllerApi).updateRankAttrList(nendo, entries),
    );
  }

  public upsertRankDef(entries: RankEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(RanksControllerApi).updateRankList(entries),
    );
  }
}
