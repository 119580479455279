import { observer } from "mobx-react";
import React from "react";

import { CellComponentProps, InteractiveCellComponentProps } from "../../../common/form/CellFormAdaptable";
import { CheckboxField } from "../../../common/form/CheckboxField";
import { LabelField } from "../../../common/form/LabelField";
import { CalendarPageGridMonthVM } from "./CalendarPageGrid";

export const CalendarFinalDayHeader = observer((props: CellComponentProps & { month: CalendarPageGridMonthVM }) => (
  <LabelField cellPosition={props.cellPosition} mode="cell" hasNoChange={props.month.hasNoChange}>
    <br />
    最終
    <br />
    出勤
  </LabelField>
));

@observer
export class CalendarFinalDayBody extends React.Component<
  InteractiveCellComponentProps & { month: CalendarPageGridMonthVM }
> {
  public render() {
    const { props } = this;
    const day = props.month.dayAt(props.cellPosition!.rowIndex);
    if (!day) {
      return <></>;
    }

    const onChange = day.onChangeIsFinalDay;

    return (
      <CheckboxField
        onCellFocused={props.onCellFocused}
        cellPosition={props.cellPosition}
        mode="cell"
        hasNoChange={day.hasNoChange}
        disabled={day.disabled}
        onChange={onChange}
        isActive={props.isActive}
        value={day.isFinalDay}
      />
    );
  }
}
