import Help from "@webkintai/help/content/manuals/admin/pjclose.md";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { KintaiCalendarSelectorButton } from "../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { GuideRibbon } from "../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../common/ribbon/JumpRibbon";
import { SaveRibbon } from "../../common/ribbon/SaveRibbon";

export interface PjClosePageRibbonVM {
  nendo: number;
  nendoList: number[];
  onChangeNendo: (nendo: number) => void;
  loadingStatus: LoadingStatusType;
  allowedToEdit: boolean;
  saveDisabled: boolean;
  /** 保存ボタン */
  onSave: () => void;
  /** リセットボタン */
  onReset: () => void;
}

@observer
export class PjClosePageRibbon extends React.Component<{ model: PjClosePageRibbonVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    const onChangeDate = (d: Date) => {
      this.props.model.onChangeNendo(d.getFullYear());
    };
    const currentNendo = new Date(model.nendo, 4, 1);

    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <KintaiCalendarSelectorButton
            buttonProps={{ small: true }}
            unit="year"
            nendoList={model.nendoList}
            currentDate={currentNendo}
            onChangeDate={onChangeDate}
          />
        </JumpRibbon>
        {loadingStatus === "loaded" && (
          <>
            {model.allowedToEdit && (
              <SaveRibbon
                disabled={model.saveDisabled}
                onSave={model.onSave}
                onReset={model.onReset}
                useTimeoutConfirmReset={true}
              />
            )}
          </>
        )}
      </>
    );
  }
}
