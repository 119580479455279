import { sortBy, uniqBy } from "lodash-es";
import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import {
  InterviewListPageModel,
  InterviewListPageModelSymbol,
} from "../../../models/interview_list/InterviewListPageModel";
import { getNendo, getNendoAsDate } from "../../../utils/calendar";
import { dateEq } from "../../../utils/date";
import { InterviewListPageVM } from "./InterviewListPage";
import { InterviewListPageRibbonVM } from "./ribbon/InterviewListPageRibbon";
import { InterviewListPageTableVM } from "./table/InterviewListPageTable";
import { InterviewListPageTableRowVMImpl } from "./table/InterviewListPageTableRowVMImpl";

export class InterviewListPageVMImpl
  implements InterviewListPageVM, InterviewListPageRibbonVM, InterviewListPageTableVM {
  @lazyInject(InterviewListPageModelSymbol)
  private model: typeof InterviewListPageModel.Type;

  get ribbonModel() {
    return this;
  }

  get tableModel() {
    return this;
  }

  @computed
  get editAllowed() {
    return this.model.allowedToEdit;
  }

  @computed
  get loadingStatus() {
    return this.model.currentNendoLoadingStatus;
  }

  @computed
  get nendo() {
    return getNendoAsDate(this.model.targetNendo);
  }

  @computed
  get nendoList() {
    return this.model.nendoList;
  }

  @action.bound
  public onChangeNendo(value: Date) {
    this.model.navigateToNendo(getNendo(value));
  }

  @computed
  get filterText() {
    return this.model.filterText;
  }
  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  get reloadDisabled() {
    return this.model.reloadDisabled;
  }
  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @computed
  get saveDisabled() {
    return this.model.saveDisabled;
  }
  @action.bound
  public onSave() {
    this.model.save();
  }

  @action.bound
  public onReset() {
    this.model.reset();
  }

  @computed
  get rows() {
    const ivList = this.model.filteredCurrentNendoInterviewList;
    const ftgList = this.model.filteredCurrentNendoFatigueCheckList;

    // 月（降順）・社員番号（昇順）で表示する
    const monthsAndUserIds = [...ivList, ...ftgList].map(it => ({ targetMonth: it.targetMonth, userId: it.userId }));
    const rowKeys = sortBy(
      uniqBy(monthsAndUserIds, it => `${it.targetMonth.getTime()}_${it.userId}`),
      it => -it.targetMonth.getTime(),
      it => it.userId,
    );

    return rowKeys.map(
      rowKey =>
        new InterviewListPageTableRowVMImpl(
          this,
          ivList.find(it => dateEq(it.targetMonth, rowKey.targetMonth) && it.userId === rowKey.userId),
          ftgList.find(it => dateEq(it.targetMonth, rowKey.targetMonth) && it.userId === rowKey.userId),
        ),
    );
  }
}
