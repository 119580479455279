import React from "react";
import { ReceptionStatusValues } from "../../../../models/common/ReceptionStatus";
import { CellFormPrintAdaptable } from "../../../common/form/CellFormAdaptable";
import { SelectField, SelectFieldOptionItem } from "../../../common/form/SelectField";

const options: SelectFieldOptionItem[] = ReceptionStatusValues.map(it => ({
  label: it,
  value: it,
}));

export const SheetAcceptStatusSelectField: React.FunctionComponent<{
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
} & CellFormPrintAdaptable> = props => {
  return (
    <SelectField
      // dirty hack: "form" | "print" 型を与えるとTSが処理できないので "form" にしてる
      mode={props.mode as "form"}
      disabled={props.disabled}
      value={`${props.value}`}
      onChange={value => props.onChange(value === "true")}
      options={[
        {
          value: "false",
          label: "未受領",
        },
        {
          value: "true",
          label: "受領済",
        },
      ]}
    />
  );
};
