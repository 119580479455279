import { flow } from "mobx";
import { IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { MyPrivileges } from "../../services/prv/MyPrivileges";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendo } from "../../utils/calendar";
import { filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { idNendoNendoInterviewList, NendoInterviewList } from "./NendoInterviewList";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("InterviewListPageModel", {
      targetNendo: types.optional(types.number, getNendo(new Date())),
      nendoInterviewLists: types.map(NendoInterviewList),
      currentNendoInterviewList: types.maybe(types.reference(NendoInterviewList)),

      filterText: types.optional(types.string, ""),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      const myPrivileges = () => getDI(self, MyPrivileges);

      return {
        get allowedToEdit() {
          if (!myPrivileges().has("DISP_MEDLIST_SAVE")) {
            return false;
          }

          if (!self.currentNendoInterviewList) {
            return false;
          }

          return self.currentNendoInterviewList.allowedToEdit;
        },
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get currentNendoLoadingStatus() {
          if (!self.currentNendoInterviewList) {
            return "loading";
          }
          return self.currentNendoInterviewList.loadingStatus;
        },
        get reloadDisabled() {
          if (!self.currentNendoInterviewList) {
            return true;
          }
          return !self.currentNendoInterviewList.hasNoChange;
        },
        get saveDisabled() {
          if (!this.allowedToEdit) {
            return true;
          }
          if (!self.currentNendoInterviewList) {
            return true;
          }

          return !self.currentNendoInterviewList.hasNoError || self.currentNendoInterviewList.hasNoChange;
        },
        get filteredCurrentNendoInterviewList() {
          if (!self.currentNendoInterviewList) {
            return [];
          }

          const searchWords = filterTextToSearchWords(self.filterText);
          return self.currentNendoInterviewList.ivEntries.filter(it => it.filterMatched(searchWords));
        },
        get filteredCurrentNendoFatigueCheckList() {
          if (!self.currentNendoInterviewList) {
            return [];
          }

          const searchWords = filterTextToSearchWords(self.filterText);
          return self.currentNendoInterviewList.ftgEntries.filter(it => it.filterMatched(searchWords));
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const appNotifier = () => getDI(self, AppNotifier);

      const prepare = (nendo: number) => {
        const id = idNendoNendoInterviewList(nendo);
        return (() => {
          const existing = self.nendoInterviewLists.get(id);
          if (existing) {
            return existing;
          }

          const created = NendoInterviewList.create({
            id,
            nendo,
          });
          self.nendoInterviewLists.put(created);
          return created;
        })();
      };

      const triggerLoad = (forceReload = false) => {
        self.currentNendoInterviewList = prepare(self.targetNendo);
        if (!forceReload) {
          self.currentNendoInterviewList.loadIfNeeded();
        } else {
          self.currentNendoInterviewList.load();
        }
      };

      const reload = () => triggerLoad(true);

      const save = flow(function*() {
        if (!self.currentNendoInterviewList) {
          return;
        }
        try {
          yield self.currentNendoInterviewList.save();
          appNotifier().info({ message: "産業医一覧を保存しました" });
        } catch (exception) {
          appNotifier().error({ message: "産業医一覧の保存に失敗しました", exception });
        }
      });

      const reset = () => {
        if (!self.currentNendoInterviewList) {
          return;
        }
        self.currentNendoInterviewList.reset();
      };

      return {
        prepare,
        reload,
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.dashboard.interview_list.ofNendo(nendo).index());
        },
        route(pathFragment: string) {
          const { targetYear } = fromPaths.dashboard.interview_list.ofYear.index(pathFragment);

          if (targetYear) {
            self.targetNendo = targetYear;
          }

          triggerLoad();
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
        save,
        reset,
      };
    }),
  {},
);

export type InterviewListPageModelType = typeof InterviewListPageModel.Type;

export const InterviewListPageModelSymbol = "InterviewListPageModel_Symbol";
export const InterviewListPageModel: InterviewListPageModelModelType = model;
type InterviewListPageModelInferredType = typeof model;
export interface InterviewListPageModelModelType extends InterviewListPageModelInferredType {}
