import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { NotificationPageModel, NotificationPageModelSymbol } from "../../../models/notify/NotificationPageModel";
import { InboxPageGridVM } from "./grid/InboxPageGrid";
import { InboxPageGridRowVMImpl } from "./grid/InboxPageGridRowVMImpl";
import { InboxPageVM } from "./InboxPage";
import { OpenedMessageVMImpl } from "./OpenedMessageVMImpl";
import { InboxPageRibbonVM } from "./ribbon/InboxPageRibbon";

export class InboxPageVMImpl implements InboxPageVM, InboxPageRibbonVM, InboxPageGridVM {
  @lazyInject(NotificationPageModelSymbol)
  private model: typeof NotificationPageModel.Type;

  public get ribbonModel() {
    return this;
  }
  public get gridModel() {
    return this;
  }

  @computed
  public get openedMessageModel() {
    if (!this.model.openedMessage) {
      return null;
    }
    return new OpenedMessageVMImpl(this.model.openedMessage);
  }

  @computed
  public get messageOpened() {
    return !!this.model.openedMessage;
  }

  @computed
  public get messageRead() {
    if (!this.openedMessageModel) {
      return false;
    }
    return this.openedMessageModel.messageRead;
  }

  @computed
  public get unreadCount() {
    return this.model.unreadCount;
  }

  @computed
  public get rows() {
    return this.model.sortedNotifications.map(notif => new InboxPageGridRowVMImpl(notif));
  }

  @computed
  get onlyUnread() {
    return this.model.onlyUnread;
  }

  @action.bound
  public onChangeOnlyUnread(value: boolean) {
    this.model.setOnlyUnread(value);
  }

  @action.bound
  public onToggleRead() {
    this.model.toggleRead();
  }

  @computed
  public get markAllAsReadDisabled() {
    return this.model.unreadCount === 0;
  }

  @action.bound
  public onMarkAllAsRead() {
    this.model.markAllAsRead();
  }

  @computed
  get messageFrameSize() {
    return this.model.messageListFrameSize;
  }

  @action.bound
  public onChangeMesageFrameSize(value: number) {
    this.model.setMessageListFrameSize(value);
  }
}
