import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { DiPanelMode } from "../../../models/kintai/DiPanelMode";
import { MainKintaiTypeType } from "../../../models/kintai/MainKintaiType";
import { GridTableLoading } from "../loading/GridTableLoading";
import { DiPanel } from "../page/DiPanel";
import { PageRibbon } from "../page/PageRibbon";
import { ScrollablePane } from "../ScrollablePane";
import { RegularDailyKintaiForm } from "./daily/regular/RegularDailyKintaiForm";
import { RegularDailyKintaiGrid, RegularDailyKintaiGridVM } from "./daily/regular/RegularDailyKintaiGrid";
import { RegularDailyVM } from "./daily/regular/RegularDailyVM";
import { SPortDailyKintaiForm } from "./daily/sport/SPortDailyKintaiForm";
import { SPortDailyKintaiGrid, SPortDailyKintaiGridVM } from "./daily/sport/SPortDailyKintaiGrid";
import { SPortDailyVM } from "./daily/sport/SPortDailyVM";
import { RegularPrintedKintai, RegularPrintedKintaiVM } from "./print/regular/RegularPrintedKintai";
import { SPortPrintedKintai, SPortPrintedKintaiVM } from "./print/sport/SPortPrintedKintai";
import { KintaiReportRibbon, KintaiReportRibbonVM } from "./ribbon/KintaiReportRibbon";

export interface KintaiReportVM {
  id: string;
  loadingStatus: LoadingStatusType;
  loadingErrorMessage: string;
  onReloadOnFailure: () => void;
  kintaiType: MainKintaiTypeType;
  targetDate: Date;
  // onChangeTargetDate: (date: Date) => void;
  regularToday: RegularDailyVM;
  regularMonthlyVM: RegularDailyKintaiGridVM;
  regularMonthlyPrintedKintaiVM: RegularPrintedKintaiVM;
  sPortToday: SPortDailyVM;
  sPortMonthlyVM: SPortDailyKintaiGridVM;
  sPortMonthlyPrintedKintaiVM: SPortPrintedKintaiVM;

  ribbonVM: KintaiReportRibbonVM;
  showsPrinted: boolean;
  diPanelMode: DiPanelMode;
  onChangeDiPanelMode: (mode: DiPanelMode) => void;
}

@observer
export class KintaiReport extends React.Component<{ model: KintaiReportVM }> {
  public render() {
    const { model } = this.props;

    if (model.loadingStatus !== "loaded") {
      return (
        <PageRibbon ribbon={<KintaiReportRibbon model={model.ribbonVM} small={false} />}>
          <GridTableLoading
            loadingStatus={model.loadingStatus}
            errorContent={model.loadingErrorMessage}
            onReloadOnFailure={model.onReloadOnFailure}
          />
        </PageRibbon>
      );
    }

    const [dailyForm, grid, print] = (() => {
      switch (model.kintaiType) {
        case "Flex":
        case "Non-Flex":
          return [
            <RegularDailyKintaiForm key="kintai" model={model.regularToday} />,
            <RegularDailyKintaiGrid key="grid" model={model.regularMonthlyVM} />,
            <RegularPrintedKintai key="print" model={model.regularMonthlyPrintedKintaiVM} />,
          ];
        default:
          return [
            <SPortDailyKintaiForm key="kintai" model={model.sPortToday} />,
            <SPortDailyKintaiGrid key="grid" model={model.sPortMonthlyVM} />,
            <SPortPrintedKintai key="print" model={model.sPortMonthlyPrintedKintaiVM} />,
          ];
      }
    })();

    return (
      <PageRibbon
        ribbon={<KintaiReportRibbon model={model.ribbonVM} small={false} />}
        shrinkRibbon={<KintaiReportRibbon model={model.ribbonVM} small={true} />}>
        {// 印刷モード
        model.showsPrinted && <ScrollablePane>{print}</ScrollablePane>}
        {// 通常モード
        !model.showsPrinted && (
          <DiPanel
            key={model.id}
            mode={model.diPanelMode}
            header={dailyForm}
            headerTitle="まいにち入力"
            body={grid}
            bodyTitle="勤怠表"
            onChangeMode={model.onChangeDiPanelMode}
          />
        )}
      </PageRibbon>
    );
  }
}
