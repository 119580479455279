import { Button, Classes, InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { sortBy } from "lodash-es";
import React from "react";

import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../../../../../utils/searchwords";
import { JobNoEntry } from "./JobNoEntry";

export interface JobNoSelectWindowProps {
  jobNoDefs: JobNoEntry[];
  onChooseJobNo: (jobNo: string) => void;
}
export const JobNoSelectWindow = (props: JobNoSelectWindowProps) => {
  const [filterText, setFilterText] = React.useState("");
  const onChangeFilterText = React.useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setFilterText(ev.currentTarget.value);
    },
    [setFilterText],
  );

  const filteredJobNoDefs = sortBy(
    props.jobNoDefs.filter(it => checkIfFilterTextMatches([it.jobName, it.jobNo], filterTextToSearchWords(filterText))),
    it => it.jobNo,
  );

  const onClickJobNo = (jobNo: string) => () => {
    props.onChooseJobNo(jobNo);
  };

  return (
    <Container>
      <Filter>
        <InputGroup value={filterText} onChange={onChangeFilterText} placeholder="絞り込む" leftIcon="filter" />
      </Filter>
      <Body>
        {filteredJobNoDefs.length === 0 && <NoEnt>候補なし</NoEnt>}
        {filteredJobNoDefs.length > 0 && (
          <Table className="bp3-html-table bp3-html-table-striped">
            <thead>
              <tr>
                <th style={{ width: "6em" }} />
                <th>ジョブNo.</th>
                <th>ジョブ名</th>
              </tr>
            </thead>
            <tbody>
              {filteredJobNoDefs.map(it => (
                <tr key={it.jobNo}>
                  <td>
                    <Button className={Classes.POPOVER_DISMISS} text="選択" onClick={onClickJobNo(it.jobNo)} />
                  </td>
                  <td>{it.jobNo}</td>
                  <td>{it.jobName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Body>
      <CloseDialog>
        <Button className={Classes.POPOVER_DISMISS} minimal={true} fill={true} icon="cross" text="閉じる" />
      </CloseDialog>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
`;

const Filter = styled.div`
  flex: none;
  padding: 8px;
`;

const Body = styled.div`
  flex: auto;
  overflow-y: scroll;
`;

const CloseDialog = styled.div`
  flex: none;
  padding-top: 1ex;
`;

const NoEnt = styled.div`
  padding: 2em;
  text-align: center;
  font-weight: bold;
`;
