import { PaidVacationEditEntity, PaidVacationsResult, UserEntity } from "@webkintai/api";
import { applySnapshot, flow, getEnv, getSnapshot, IModelType, types } from "mobx-state-tree";

import { UsersApi } from "../../services/api/UsersApi";
import { PHImportResult } from "../../services/excel/ph/PHInputExcel";
import { fromApiDate } from "../../utils/api";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDepts } from "../depts/NendoDepts";
import { NendoRanks } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { idPHEntry } from "./PHEntry";
import { PHUserEntry } from "./PHUserEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const PHUserEntriesLoadedNendo = types.model("PHUserEntriesLoadedNendo", {
  nendo: types.identifierNumber,
});

const model = types.optional(
  types
    .model("PHUserEntries", {
      loadedNendo: types.map(PHUserEntriesLoadedNendo),
      userEntries: types.map(PHUserEntry),
      depts: types.maybe(types.reference(NendoDepts)),
      ranks: types.maybe(types.reference(NendoRanks)),
      masterLoadingState: types.optional(LoadingStatus, "init"),
    })
    .views(self => {
      return {
        get hasNoChange() {
          return Array.from(self.userEntries.values(), user => user.hasNoChange).reduce((l, r) => l && r, true);
        },
      };
    })
    .actions(self => {
      const usersApi = (): UsersApi => getDI(self, UsersApi);
      const ranks = (): RanksType => getEnv(self).get(RanksSymbol);
      const depts = (): DeptsType => getEnv(self).get(DeptsSymbol) as DeptsType;
      function prepareUser(user: UserEntity) {
        return (
          self.userEntries.get(user.userId) ||
          (() => {
            const dept = user.depCode && self.depts && self.depts.getDept(user.depCode);
            const rank = user.rankCode && self.ranks && self.ranks.getRank(user.rankCode);

            const created = PHUserEntry.create({
              userId: user.userId,
              userName: user.userName || "",
              leaveDate: fromApiDate(user.leaveDate),
              deptCode: user.depCode || undefined,
              deptName: dept ? dept.name : undefined,
              rankCode: user.rankCode || undefined,
              rankName: rank ? rank.rankName : undefined,
            });
            self.userEntries.put(created);
            return created;
          })()
        );
      }

      const importExcel = (entries: PHImportResult) => {
        if (entries.status !== "success") {
          return;
        }

        entries.rows.forEach(row => {
          const user = self.userEntries.get(row.userId);
          if (!user) {
            return;
          }

          row.vacHoursPerNendo.forEach(nendoInput => {
            const nendoEntry = user.getNendoEntry(nendoInput.nendo);
            if (!nendoEntry || !nendoEntry.allowedToEdit) {
              return;
            }
            nendoEntry.setInputPhDayCount(nendoInput.hours);
          });
        });
      };

      return {
        loadMasterIfNeeded: flow(function*(nendo: number) {
          if (self.masterLoadingState === "loaded") {
            return;
          }

          self.masterLoadingState = "loading";
          try {
            self.ranks = yield ranks().prepareAndLoadMostRecent(nendo);
            self.depts = yield depts().prepareAndLoadMostRecent(nendo);
            self.masterLoadingState = "loaded";
          } catch (exception) {
            self.masterLoadingState = "failed";
            throw exception;
          }
        }),
        loadNendoIfNeeded(nendo: number) {
          return self.loadedNendo.has(`${nendo}`) ? new Promise(done => done()) : this.loadNendo(nendo);
        },
        loadNendo: flow(function*(nendo: number) {
          const result: PaidVacationsResult = yield usersApi().getPaidVacations(nendo);
          result.vacations.forEach(vac => {
            const user = prepareUser(vac.user);
            const id = idPHEntry(vac.user.userId, nendo);
            applySnapshot(user.entries, {
              ...getSnapshot(user.entries),
              [id]: {
                id,
                userId: vac.user.userId,
                nendo,
                phDayCount: vac.additionDays || undefined,
                inputPhDayCount: vac.additionDays || undefined,
              },
            });
          });
          self.loadedNendo.set(`${nendo}`, { nendo });
        }),
        saveNendo: flow(function*(nendo: number) {
          const changes: PaidVacationEditEntity[] = [];
          for (const u of self.userEntries.values()) {
            const ent = u.getNendoEntry(nendo);
            if (!ent || ent.hasNoChange) {
              continue;
            }

            changes.push({
              userId: u.userId,
              additionDays: ent.inputPhDayCount,
            });
          }

          if (changes.length) {
            yield usersApi().savePaidVacations(nendo, changes);
          }
        }),
        reset() {
          for (const u of self.userEntries.values()) {
            u.reset();
          }
        },
        importExcel,
      };
    }),
  {},
);

export type PHUserEntriesType = typeof PHUserEntries.Type;

export const PHUserEntriesSymbol = "PHUserEntries_Symbol";
export const PHUserEntries: PHUserEntriesModelType = model;
type PHUserEntriesInferredType = typeof model;
export interface PHUserEntriesModelType extends PHUserEntriesInferredType {}
