import { Switch } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { sortBy } from "lodash-es";
import { observer } from "mobx-react";
import React from "react";

import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkTable } from "../../../common/plaintable/WkTables";

export interface DeptUsersPanelRowVM {
  userId: string;
  userName: string;
  depName: string;
  rankName: string;
  disabled: boolean;
  isSelected: boolean;
  onToggleSelected: () => void;
}

const Container = styled.div`
  flex: 1 1 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  flex: 0 0 auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #f9f9f9;
`;

const NRSContainer = styled.div`
  flex: 1 auto;
`;

const TableArea = styled.div`
  flex: 0 auto;
  overflow: auto;

  padding: 0.5ex 0.5ex 0 0.5ex;

  & > * {
    width: 100%;
  }
`;

@observer
export class DeptUsersPanel extends React.Component<{ title: string; rows: DeptUsersPanelRowVM[] }> {
  public render() {
    const { title, rows } = this.props;

    const sortedRows = sortBy(rows, it => it.userId);

    return (
      <Container>
        <Title>{title}</Title>

        {sortedRows.length === 0 && (
          <NRSContainer>
            <NoResultsSpread />
          </NRSContainer>
        )}
        {sortedRows.length > 0 && (
          <TableArea>
            <WkTable>
              <thead>
                <tr>
                  <th>#</th>
                  <th>社員番号</th>
                  <th>氏名</th>
                  <th>所属</th>
                  <th>ランク</th>
                  <th>部門勤怠管理者</th>
                </tr>
              </thead>
              <tbody>
                {sortedRows.map((row, idx) => (
                  <DeptUserRow key={row.userId} rowNum={idx + 1} model={row} />
                ))}
              </tbody>
            </WkTable>
          </TableArea>
        )}
      </Container>
    );
  }
}

const DeptUserRow: React.FunctionComponent<{ rowNum: number; model: DeptUsersPanelRowVM }> = observer(props => {
  const { model } = props;

  return (
    <tr>
      <td>{props.rowNum}</td>
      <td>{model.userId}</td>
      <td>{model.userName}</td>
      <td>{model.depName}</td>
      <td>{model.rankName}</td>
      <td>
        <Switch
          style={{ marginBottom: 0 }}
          checked={model.isSelected}
          disabled={model.disabled}
          onChange={model.onToggleSelected}
          innerLabelChecked="責任者"
        />
      </td>
    </tr>
  );
});
