import moment from "moment";
import { sprintf } from "sprintf-js";

import { convertZenkakuNumToHankaku } from "./chars";

export const applicationReleaseDate = () => dateOf(2019, 7, 1);

export const dateOf = (year?: number | null, month?: number | null, date?: number | null): Date => {
  if (!year) {
    return moment()
      .startOf("day")
      .toDate();
  }
  if (!month) {
    return moment()
      .year(year)
      .startOf("year")
      .toDate();
  }
  if (!date) {
    return moment()
      .year(year)
      .month(month - 1)
      .startOf("month")
      .toDate();
  }
  return moment()
    .year(year)
    .month(month - 1)
    .date(date)
    .startOf("day")
    .toDate();
};

export function timeTextInputToMinutes(timeText: string | null): number | null {
  if (timeText === null || timeText === "") {
    return null;
  }

  const onlyNumbers = convertZenkakuNumToHankaku(timeText)
    .replace(/\D/g, "")
    .slice(-4);
  const matchResult = onlyNumbers.substr(0, 4).match(/^(.*?)(..?)$/);
  if (!matchResult) {
    return null;
  }

  const hourTxt = matchResult[1];
  const minTxt = matchResult[2];

  return (hourTxt ? Number.parseInt(hourTxt, 10) * 60 : 0) + Number.parseInt(minTxt, 10);
}

export function minutesToTimeTextNumber(minutes: number | null): string {
  return minutesToTimeText(minutes).replace(":", "");
}

export function minutesToTimeText(minutes: number | null): string {
  if (minutes === null) {
    return "";
  }

  return sprintf("%02d:%02d", Math.floor(minutes / 60), minutes % 60);
}

export function haveSameMonth(l: Date, r: Date) {
  return l.getFullYear() === r.getFullYear() && l.getMonth() === r.getMonth();
}

export const getNendoYear = (d: Date) =>
  moment(d)
    .add(-3, "months")
    .toDate()
    .getFullYear();

export function haveSameNendo(l: Date, r: Date) {
  return getNendoYear(l) === getNendoYear(r);
}

export function findDayOfWeek(d: Date, day: number, direction: 1 | -1) {
  day = day % 7;
  while (d.getDay() !== day) {
    d = moment(d)
      .add(direction, "days")
      .toDate();
  }
  return d;
}

export const firstMonthDay = (d: Date) =>
  moment(d)
    .date(1)
    .toDate();

export const lastMonthDay = (d: Date) =>
  moment(d)
    .add(1, "months")
    .date(1)
    .add(-1, "days")
    .toDate();

export const dateTrunc = (d: Date) =>
  moment(d)
    .startOf("day")
    .toDate();

export const dateEq = (l: Date | undefined, r: Date | undefined) => {
  if (l === r) {
    return true;
  }
  if (l === undefined || r === undefined) {
    return false;
  }

  const toDate = (d: Date) => dateTrunc(d).getTime();
  return toDate(l) === toDate(r);
};

export const datetimeEq = (l: Date | undefined, r: Date | undefined) => {
  if (l === r) {
    return true;
  }
  if (l === undefined || r === undefined) {
    return false;
  }

  return l.getTime() === r.getTime();
};

export const monthDate = (d: Date) => dateOf(d.getFullYear(), d.getMonth() + 1);

export const compareMonth = <T>(l: Date, r: Date, comparator: (ll: Date, rr: Date) => T) =>
  comparator(monthDate(l), monthDate(r));

export const lastMonth = (date = new Date()) => {
  return moment(monthDate(date))
    .subtract(1, "months")
    .toDate();
};
