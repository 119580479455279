import { KintaiInputItemState } from "./kintaiinput/KintaiInput";

export const extractAsProps = <T>(target: KintaiInputItemState<T>) => ({
  errors: [...target.errors],
  warnings: [...target.warnings],
  hasNoChange: target.hasNoChange,
  disabled: target.disabled,
  value: target.value,
  onChange: target.onChange,
});
