import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { PrintDetailMode } from "../../../../../models/kintai/misc/printdetailmode/PrintDetailMode";
import { SPortDailyKintaiGridVM } from "../../daily/sport/SPortDailyKintaiGrid";
import { SPortMonthlyTotalVM } from "../../monthly/sport/SPortMonthlyTotalVM";
import { PrintedTimestamps, PrintedTimestampsVM } from "../common/PrintedTimestamps";
import { KintaiPrintFrame, KintaiPrintPage } from "../KintaiPrintFrame";
import { SPortPrintedMainTable } from "./SPortPrintedMainTable";
import { SPortPrintedTotalTables } from "./SPortPrintedTotalTables";

export type SPortPrintedKintaiVM = {
  name: string;
  depName: string;
  rankName: string;
  userId: string;
  designatedMinutes?: Minutes;
  printDetailMode: PrintDetailMode;

  sPortMonthlyTotal: SPortMonthlyTotalVM;
} & SPortDailyKintaiGridVM &
  PrintedTimestampsVM;

@observer
export class SPortPrintedKintai extends React.Component<{ model: SPortPrintedKintaiVM }> {
  public render() {
    const { model } = this.props;
    const { sPortRows } = model;
    const firstRow = sPortRows[0];
    const { date } = firstRow;
    const kintaiTableClassName = `SPortPrintedKintai_KintaiTable`;

    return (
      <KintaiPrintFrame title={`Web勤怠印刷 - ${model.name}(${model.userId})_${moment(date).format("YYYY年MM月")}`}>
        <style>{`
.${kintaiTableClassName} {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

/* メイン月報 */
.${kintaiTableClassName} > *:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
}

/* いろいろ欄 */
.${kintaiTableClassName} > *:nth-child(2) {

}

/* ハンコ欄 */
.${kintaiTableClassName} > *:nth-child(3) {
}

/* 月報詳細 (ヘッダ・ボディ) */
.${kintaiTableClassName} > *:nth-child(4) {
}

.${kintaiTableClassName} > *:nth-child(4),
.${kintaiTableClassName} > *:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
}
      `}</style>
        <KintaiPrintPage noBreak={true}>
          <div className={kintaiTableClassName}>
            <SPortPrintedMainTable {...this.props} />
            <SPortPrintedTotalTables {...this.props} />
            <PrintedTimestamps {...this.props} date={date} />
          </div>
        </KintaiPrintPage>
      </KintaiPrintFrame>
    );
  }
}
