import { Button, Classes, Intent, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { KintaiBunrui, NullKintai } from "@webkintai/bunrui";
import { observer } from "mobx-react";
import React from "react";

import { TypesKF } from "../../daily/fields/TypesKF";

export interface MSetAllDaysBunruiVM {
  showSetAllBunrui: boolean;
  setAllBunruiKintaiBunruiList: KintaiBunrui[];
  onSetAllBunrui: (value: KintaiBunrui) => void;
  onClearAllBunrui: () => void;
}

const WindowContainer = styled.div`
  width: 13em;
  padding: 1ex;
`;

const AllSetSection = styled.div``;

const AllResetSection = styled.div`
  margin-top: 1ex;
  border-top: 1px solid #ccc;
  padding-top: 1ex;
`;

interface Props {
  model: MSetAllDaysBunruiVM;
}
interface State {
  type: KintaiBunrui;
}

@observer
export class MSetAllDaysBunrui extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: NullKintai,
    };
  }

  public render() {
    const vm = this.props.model;
    if (!vm.showSetAllBunrui) {
      return <></>;
    }

    const model = {
      types: [
        {
          kintaiBunrui: this.state.type,
          disabled: false,
          onChange: this.setType,
          availableKintaiBunrui: vm.setAllBunruiKintaiBunruiList,
        },
      ],
    };

    return (
      <Popover
        content={
          <WindowContainer>
            <AllSetSection>
              <p>一括で平日の空の部分に分類を設定できます。</p>

              <TypesKF mode="form" label="分類" model={model} bunruiIdx={1} />
              <Button
                disabled={this.state.type === NullKintai}
                onClick={this.onSet}
                className={Classes.POPOVER_DISMISS}
                intent={Intent.PRIMARY}>
                設定
              </Button>
            </AllSetSection>

            <AllResetSection>
              <p>あるいは、全ての分類を初期化できます。</p>
              <Button
                text="分類1以外を全て空白に初期化"
                onClick={this.onClearAll}
                className={Classes.POPOVER_DISMISS}
                intent={Intent.DANGER}
              />
            </AllResetSection>
          </WindowContainer>
        }
        boundary="viewport">
        <button>一括</button>
      </Popover>
    );
  }

  private setType = (type: KintaiBunrui) => {
    this.setState({ type });
  };

  private onSet = () => {
    this.props.model.onSetAllBunrui(this.state.type);
  };

  private onClearAll = () => {
    this.props.model.onClearAllBunrui();
  };
}
