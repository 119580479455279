import { observer } from "mobx-react";
import React from "react";

import { RoleTypeSelect } from "../../../common/form/RoleTypeSelect";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RankRowVM } from "./RankPageGrid";

@observer
export class RKRoleTypeSelectCell extends React.Component<{ model: RankRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <RoleTypeSelect
        mode="cell"
        disabled={row.disabled}
        value={row.roleCode}
        onChange={row.onChangeRoleCode}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
