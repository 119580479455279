import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLiquidateKFProps = {
  model: MTotalLiquidateKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLiquidateKFVM {
  mTotalLiquidate: KintaiInputItemState<Minutes>;
}

export const MTotalLiquidateKF = observer((props: MTotalLiquidateKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLiquidate;

  return <HHMMField {...props} label="mTotalLiquidateKF" {...itemState} onChange={undefined} />;
});
