import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { PasswordChangeFormModel, PasswordChangeFormModelSymbol } from "../../../models/login/PasswordChangeFormModel";
import { PasswordChangeConsoleVM, PasswordChangeMode } from "./PasswordChangeConsole";

export class PasswordChangeConsoleVMImpl implements PasswordChangeConsoleVM {
  @lazyInject(PasswordChangeFormModelSymbol)
  private form: typeof PasswordChangeFormModel.Type;

  public get mode() {
    return "password_reset" as PasswordChangeMode;
  }

  @computed
  public get showConsole() {
    return this.form.showConsole;
  }

  @computed
  public get forced() {
    return !this.form.cancelable;
  }

  @computed
  public get userId() {
    return this.form.userId;
  }

  @computed
  public get currentPassword() {
    return this.form.currentPassword;
  }

  @computed
  public get newPassword() {
    return this.form.newPassword;
  }

  @computed
  public get newPasswordForConfirm() {
    return this.form.newPasswordForConfirm;
  }

  @computed
  public get errors() {
    return this.form.errors;
  }

  @computed
  public get allowSubmit() {
    return this.errors.length === 0;
  }

  @action.bound
  public onChangeCurrentPassword(value: string) {
    this.form.setCurrentPassword(value);
  }

  @action.bound
  public onChangeNewPassword(value: string) {
    this.form.setNewPassword(value);
  }
  @action.bound
  public onChangeNewPasswordForConfirm(value: string) {
    this.form.setNewPasswordForConfirm(value);
  }

  @action.bound
  public onSubmit() {
    this.form.savePassword();
  }

  @action.bound
  public onClose() {
    this.form.notifyClose();
  }
}
