import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type HolidayKFProps = {
  model: HolidayKFVM;
} & CellFormPrintAdaptable;

export interface HolidayKFVM {
  holiday: KintaiInputItemState<Minutes | undefined>;
}

export const HolidayKF = observer((props: HolidayKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.holiday);

  return <HHMMField {...props} label="休日時間" {...itemState} />;
});
