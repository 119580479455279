import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalHolidayDaysKFProps = {
  model: MTotalHolidayDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalHolidayDaysKFVM {
  mTotalHolidayDays: KintaiInputItemState<Days>;
}

export const MTotalHolidayDaysKF = observer((props: MTotalHolidayDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalHolidayDays;

  return (
    <DayCountField
      {...props}
      label="mTotalAllNightOffDaysKF"
      {...itemState}
      value={itemState.value.days}
      onChange={undefined}
    />
  );
});
