import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { ApprovalStamp } from "./ApprovalStamp";

export type MonthlyApproverStampProps = {
  model: MonthlyApproverStampVM;
} & CellFormAdaptable;

export interface MonthlyApproverStampVM {
  approverStamped: boolean;
}

export const MonthlyApproverStamp = observer((props: MonthlyApproverStampProps) => {
  return <ApprovalStamp {...props} stamped={props.model.approverStamped} />;
});
