import { Icon } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { KintaiApplicationStage } from "../../../../../models/kintai/apps/KintaiApplicationStage";
import { TimelineElement, TimelineHeader, TimelineList, TimelineTime } from "../../../timeline/TimelineList";
import { InlineTooltip } from "../../../tooltip/TextTooltip";
import { AppApproveButtonKF } from "../fields/AppApproveButtonKF";
import { AppApproveTextKF } from "../fields/AppApproveTextKF";
import { AppCancelKF } from "../fields/AppCancelKF";
import { AppPostButtonKF } from "../fields/AppPostButtonKF";
import { AppPostTemplatesKF } from "../fields/AppPostTemplatesKF";
import { AppPostTextKF } from "../fields/AppPostTextKF";
import { AppRejectButtonKF } from "../fields/AppRejectButtonKF";
import { DailyApplicationVM } from "./DailyApplicationVM";

const AppFormLine = styled.div`
  display: flex;
`;

const AppProxiedText = styled.div`
  font-size: 90%;
`;

const progressNumberDef: { [k in KintaiApplicationStage]: number } = {
  app_is_required_before_saving: -1,
  no_need_to_app: 0,
  app_is_required: 1,
  approve_is_required: 2,
  privileged_approve_is_required: 2,
  rejected: 3,
  approved: 3,
};
@observer
export class DailyApplicationForm extends React.Component<{ model: DailyApplicationVM }> {
  public render() {
    const { props } = this;
    const { model } = props;
    const { appStage } = model;

    const progressNumber = progressNumberDef[appStage];

    return (
      <>
        <TimelineList>
          {progressNumber >= 3 && (
            <TimelineElement>
              <TimelineHeader>
                勤怠申請は{appStage === "rejected" ? "却下されました" : "承認されました"}
                <TimelineTime date={model.approveTimestamp} />
              </TimelineHeader>

              <p>承認（却下）担当者: {model.approveUserName}</p>
            </TimelineElement>
          )}
          {progressNumber >= 2 && (
            <>
              <TimelineElement targeted={!model.appApproveText.disabled}>
                <TimelineHeader>
                  {appStage === "approve_is_required" && <>(査閲者・承認者のみ) </>}
                  {appStage === "privileged_approve_is_required" && <>(総務人事部のみ) </>}
                  承認か却下をする必要があります
                </TimelineHeader>

                <AppApproveTextKF mode="form" model={model} />
                <AppRejectButtonKF mode="form" model={model} />
                <AppApproveButtonKF mode="form" model={model} />
              </TimelineElement>
              {!model.isLocked && (
                <TimelineElement targeted={!model.appCancelIsDisabled}>
                  <TimelineHeader>(本人のみ) 申請をキャンセルできます</TimelineHeader>

                  <AppCancelKF mode="form" model={model} />
                </TimelineElement>
              )}

              <TimelineElement>
                <TimelineHeader>
                  申請は完了しました
                  {model.appPostIsProxied && <AppProxiedText>※ 本人以外による代理申請です</AppProxiedText>}
                  <TimelineTime date={model.appPostTimestamp} />
                </TimelineHeader>
                <p>申請者: {model.appPostUserName}</p>
              </TimelineElement>
            </>
          )}

          {progressNumber >= 1 && (
            <TimelineElement targeted={!model.appPostText.disabled}>
              <TimelineHeader>(本人のみ) 申請してください</TimelineHeader>

              <AppFormLine>
                <AppPostTemplatesKF mode="form" model={model} />
              </AppFormLine>
              <AppFormLine>
                <AppPostTextKF mode="form" model={model} />
              </AppFormLine>
              <AppFormLine>
                <AppPostButtonKF mode="form" model={model} />
              </AppFormLine>
            </TimelineElement>
          )}

          {progressNumber >= 0 && (
            <TimelineElement>
              <TimelineHeader>
                {appStage === "no_need_to_app" ? (
                  <>
                    申請は不要です <Icon icon="thumbs-up" />
                  </>
                ) : (
                  "申請が必要です"
                )}
              </TimelineHeader>
              <div style={{ ...(appStage === "no_need_to_app" ? { color: "#ccc" } : {}) }}>
                <p>
                  休暇・欠勤・早退など
                  <strong>
                    <InlineTooltip
                      content={
                        <div>
                          <p>該当する分類についてはヘルプを参照してください。</p>
                        </div>
                      }>
                      一部の勤怠分類
                    </InlineTooltip>
                    を入力＋保存した場合
                  </strong>
                  は、勤怠の申請の上、査閲者・承認者の<strong>承認が必要</strong>となります。
                </p>
                <p>※ 特定の勤怠分類については、査閲者・承認者ではなく総務人事部が集中的に承認を行います。</p>
                <p>※ 申請するためには一旦勤怠表を保存する必要があります。</p>
              </div>
            </TimelineElement>
          )}

          {progressNumber === -1 && (
            <TimelineElement>
              <TimelineHeader>申請が必要ですが、まずは勤怠表を保存してください</TimelineHeader>
            </TimelineElement>
          )}
        </TimelineList>
      </>
    );
  }
}
