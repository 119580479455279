import { IModelType, types } from "mobx-state-tree";

import { dateEq, monthDate } from "../../utils/date";
import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { KintaiSumMonthEntry } from "./KintaiSumMonthEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const idPrefix = "KintaiSumEntry_";

const model = types
  .model("KintaiSumEntry", {
    id: types.string,

    nendo: types.number,
    userId: types.string,

    userName: types.string,
    depCode: types.string,
    depName: types.string,
    rankCode: types.string,
    rankName: types.string,

    months: types.array(KintaiSumMonthEntry),
  })
  .views(self => {
    return {
      get searchedWords() {
        return [self.userId, self.userName, self.depCode, self.depName, self.rankCode, self.rankName];
      },
    };
  })
  .views(self => {
    return {
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(self.searchedWords, searchWords);
      },
      at(month: Date) {
        return self.months.find(m => dateEq(monthDate(m.month), monthDate(month)));
      },
    };
  });

export const idKintaiSumEntry = (nendo: number, userId: string) => `${idPrefix}${nendo}_${userId}`;

export type KintaiSumEntryType = typeof KintaiSumEntry.Type;
export const KintaiSumEntry: KintaiSumEntryModelType = model;
type KintaiSumEntryInferredType = typeof model;
export interface KintaiSumEntryModelType extends KintaiSumEntryInferredType {}
