import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../../models/statistics/usePrivateDDModel";
import { PrvUserTableWarningCounter } from "../../../table/PrvUserTableCounter";
import { PrvUserValueTable } from "../../../table/PrvUserValueTable";

export const PrvPaidLeavesTableContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserValueTable data={model.nendo_paidLeaves} atMonth={model.date} />;
});

export const PrvPaidLeavesTableCounterContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserTableWarningCounter title="有休取得日数" data={model.nendo_paidLeaves} />;
});
