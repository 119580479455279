import { KintaiAggregationsResult } from "@webkintai/api";
import { Days, HourBasedDays, Minutes, minutesAdd } from "@webkintai/core";
import { difference, flatMap, groupBy, map, sortBy, uniq } from "lodash-es";
import { applySnapshot, flow, getEnv, types } from "mobx-state-tree";
import moment from "moment";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { DeptsApi } from "../../services/api/DeptsApi";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate } from "../../utils/api";
import { getNendo, getNendoMonthIndex, getNendoMonths } from "../../utils/calendar";
import { dateEq } from "../../utils/date";
import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDepts, NendoDeptsType } from "../depts/NendoDepts";
import { typesMinutes } from "../primitives/Minutes";
import { ProfileSymbol, ProfileType } from "../profile/Profile";
import { NendoRanksType } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { ByOrderMonthFooter, ByOrderRow, ByOrderUserRow, ByOrderView } from "./ByOrderView";
import { ByUserView, MonthValues } from "./ByUserView";
import { idKintaiSumEntry, KintaiSumEntry } from "./KintaiSumEntry";
import { idKintaiSumMonthEntry } from "./KintaiSumMonthEntry";
import { idKintaiSumMonthProjectEntry } from "./KintaiSumMonthProjectEntry";
import { KintaiSumPageMode } from "./KintaiSumPageMode";

const model = types.optional(
  types
    .model("KintaiSumPageModel", {
      /** 検索・表示モード */
      mode: types.optional(KintaiSumPageMode, "byUser"),
      /** 最終検索条件 */
      lastSearchCondition: types.optional(types.string, ""),

      /** 対象年度 */
      nendoDepts: types.maybe(types.reference(NendoDepts)),
      /** 対象年月 */
      targetNendo: types.optional(types.number, getNendo(new Date())),
      /** 社員ごとの場合、表示対象月 */
      targetMonths: types.optional(types.array(types.number), [new Date().getMonth() + 1]),

      /** 対象部門コード */
      targetDepCode: types.maybeNull(types.string),
      /** 部門マスタロード状況 */
      deptsLoadingState: types.optional(LoadingStatus, "loading"),

      searchResult: types.array(KintaiSumEntry),
      lawBasisList: types.array(typesMinutes),
      searchResultLoadingState: types.optional(LoadingStatus, "loaded"),

      filterText: types.optional(types.string, ""),
      displayHourAsDecimal: types.optional(types.boolean, false),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get searchWords() {
          return filterTextToSearchWords(self.filterText);
        },
        isInTargetMonth(month: Date) {
          return self.targetMonths.findIndex(m => month.getMonth() + 1 === m) >= 0;
        },
        get allTargetMonthsSelected() {
          return self.targetMonths.length === 12;
        },
      };
    })
    .views(self => {
      const getLawBasis = (month: Date) => self.lawBasisList[getNendoMonthIndex(month)];
      const months = () => getNendoMonths(self.targetNendo);
      const totalReducer = (arr: Array<{ total: Minutes | undefined } | undefined>) =>
        arr.reduce<Minutes | undefined>((l, r) => (r && r.total ? (l ? l.add(r.total) : r.total) : l), undefined);

      const foldMinutes = (arr: Array<Minutes | undefined>) =>
        arr.reduce<Minutes>((l, r) => (r ? l.add(r) : l), new Minutes(0));
      const foldHourBasedDays = (arr: Array<HourBasedDays | undefined>) =>
        arr.reduce<HourBasedDays>((l, r) => (r ? l.add(r) : l), new HourBasedDays(0));
      const foldDays = (arr: Array<Days | undefined>) => arr.reduce<Days>((l, r) => (r ? l.add(r) : l), new Days(0));

      return {
        get byOrderView(): ByOrderView {
          const emptyIsLast = (key: string) => key || "z";

          const rows = flatMap([...self.searchResult], user =>
            flatMap([...user.months], month =>
              flatMap([...month.projects], project => ({
                month: month.month,
                odCode: project.odCode,
                pjCode: project.pjCode,
                userId: user.userId,

                pjName: project.name,
                userName: user.userName,
                worktimeSum: project.worktimeSum,
              })),
            ),
          ).filter(row =>
            checkIfFilterTextMatches([row.odCode, row.pjCode, row.pjName, row.userId, row.userName], self.searchWords),
          );

          const byOrderUserRows: ByOrderUserRow[] = sortBy(
            map(
              groupBy(rows, row => [row.userId, row.pjCode, row.odCode]),
              (value, key) => {
                const repr = value[0];

                const monthValues = months().map(month => {
                  const total = value
                    .filter(vl => dateEq(vl.month, month))
                    .map(it => it.worktimeSum)
                    .reduce(minutesAdd, undefined);

                  return {
                    month,
                    total,
                  };
                });

                return {
                  ...repr,
                  total: totalReducer(monthValues),
                  months: monthValues,
                };
              },
            ),
            [row => emptyIsLast(row.pjCode), row => emptyIsLast(row.odCode), row => emptyIsLast(row.userId)],
          );

          const byOrderRows: ByOrderRow[] = sortBy(
            map(
              groupBy(byOrderUserRows, row => [row.pjCode, row.odCode]),
              (value, key) => {
                const repr = value[0];

                const monthValues = months().map(month => {
                  return {
                    month,
                    total: totalReducer(value.map(v => v.months.find(m => dateEq(m.month, month)))),
                  };
                });

                return {
                  ...repr,
                  total: totalReducer(monthValues),
                  months: monthValues,
                };
              },
            ),
            [row => emptyIsLast(row.pjCode), row => emptyIsLast(row.odCode)],
          );

          const allMonths = flatMap(byOrderRows, row => row.months);

          const monthTotals: ByOrderMonthFooter[] = months().map(month => {
            return {
              month,
              total: totalReducer(allMonths.filter(m => dateEq(m.month, month))),
            };
          });

          const allTotal: Minutes | undefined = totalReducer(monthTotals);

          return {
            byOrderRows,
            byOrderUserRows,
            monthTotals,
            allTotal,
          };
        },
        get byUserView(): ByUserView {
          return {
            months: months()
              .filter(month => self.isInTargetMonth(month))
              .map((month, idx) => {
                const users = sortBy(
                  self.searchResult.filter(it => it.filterMatched(self.searchWords)),
                  it => it.userId,
                ).map(user => {
                  const monthValue = user.at(month);

                  return {
                    userId: user.userId,
                    userName: user.userName,
                    depCode: user.depCode,
                    depName: user.depName,
                    rankCode: user.rankCode,
                    rankName: user.rankName,

                    actual: monthValue ? monthValue.actual : undefined,
                    designatedHours: monthValue ? monthValue.designatedHours : undefined,
                    transOnHolidayDays: monthValue ? monthValue.transOnDays.add(monthValue.holidayDays) : undefined,
                    holidayDays: monthValue ? monthValue.holidayDays : undefined,
                    lawOver: monthValue ? monthValue.lawOver : undefined,
                    lawOverCalc: monthValue ? monthValue.lawOverCalc : undefined,
                    lawOverNendoSum: monthValue ? monthValue.lawOverNendoSum : undefined,
                    lawOverNine: monthValue ? monthValue.lawOverNine : undefined,
                    over: monthValue ? monthValue.over : undefined,
                    overNendoSum: monthValue ? monthValue.overNendoSum : undefined,

                    hourlyLeave: monthValue ? monthValue.hourlyLeave : undefined,
                    paidDays: monthValue ? monthValue.paidDays : undefined,
                    plainPaidDays: monthValue ? monthValue.plainPaidDays : undefined,
                  };
                });

                const total: MonthValues = {
                  actual: foldMinutes(users.map(u => u.actual)),
                  designatedHours: foldMinutes(users.map(u => u.designatedHours)),
                  transOnHolidayDays: foldDays(users.map(u => u.transOnHolidayDays)),
                  lawOver: foldMinutes(users.map(u => u.lawOver)),
                  lawOverCalc: foldMinutes(users.map(u => u.lawOverCalc)),
                  lawOverNendoSum: foldMinutes(users.map(u => u.lawOverNendoSum)),
                  lawOverNine: foldMinutes(users.map(u => u.lawOverNine)),
                  over: foldMinutes(users.map(u => u.over)),
                  overNendoSum: foldMinutes(users.map(u => u.overNendoSum)),

                  hourlyLeave: foldHourBasedDays(users.map(u => u.hourlyLeave)),
                  paidDays: foldHourBasedDays(users.map(u => u.paidDays)),
                  plainPaidDays: foldHourBasedDays(users.map(u => u.plainPaidDays)),
                };

                const totalCount = users.filter(u => u.actual !== undefined).length;

                const average: MonthValues = {
                  actual: total.actual ? total.actual.divRound(totalCount) : undefined,
                  designatedHours: total.designatedHours ? total.designatedHours.divRound(totalCount) : undefined,
                  transOnHolidayDays: total.transOnHolidayDays
                    ? total.transOnHolidayDays.divRound(totalCount)
                    : undefined,
                  lawOver: total.lawOver ? total.lawOver.divRound(totalCount) : undefined,
                  lawOverCalc: total.lawOverCalc ? total.lawOverCalc.divRound(totalCount) : undefined,
                  lawOverNendoSum: total.lawOverNendoSum ? total.lawOverNendoSum.divRound(totalCount) : undefined,
                  lawOverNine: total.lawOverNine ? total.lawOverNine.divRound(totalCount) : undefined,
                  over: total.over ? total.over.divRound(totalCount) : undefined,
                  overNendoSum: total.overNendoSum ? total.overNendoSum.divRound(totalCount) : undefined,

                  hourlyLeave: total.hourlyLeave ? total.hourlyLeave.divRound(totalCount) : undefined,
                  paidDays: total.paidDays ? total.paidDays.divRound(totalCount) : undefined,
                  plainPaidDays: total.plainPaidDays ? total.plainPaidDays.divRound(totalCount) : undefined,
                };

                return {
                  month,
                  dayCount: moment(month)
                    .endOf("month")
                    .toDate()
                    .getDate(),
                  lawBasis: getLawBasis(month),
                  users,
                  total,
                  average,
                };
              }),
          };
        },
      };
    })
    .views(self => {
      return {
        get targetDept() {
          if (!self.targetDepCode) {
            return null;
          }
          if (!self.nendoDepts) {
            return null;
          }
          if (self.nendoDepts.loadingStatus !== "loaded") {
            return null;
          }
          return self.nendoDepts.getDept(self.targetDepCode) || null;
        },
        get currentSearchCondition() {
          return self.mode + " " + self.targetDepCode + " " + self.targetNendo;
        },
      };
    })
    .actions(self => {
      const depts = (): DeptsType => getEnv(self).get(DeptsSymbol);
      const ranks = (): RanksType => getEnv(self).get(RanksSymbol);
      const appRouter = () => getDI(self, AppRouter);
      const appNotifier = () => getDI(self, AppNotifier);
      const deptsApi = () => getDI(self, DeptsApi);
      const profile = (): ProfileType => getEnv(self).get(ProfileSymbol);

      const triggerSearch = flow(function*(forceReload: boolean = false) {
        const nendo = self.targetNendo;
        const depCode = self.targetDepCode;

        if (!depCode) {
          return;
        }

        if (!forceReload && self.lastSearchCondition === self.currentSearchCondition) {
          return;
        }
        self.lastSearchCondition = self.currentSearchCondition;

        try {
          self.searchResultLoadingState = "loading";
          const nendoDepts: NendoDeptsType = yield depts().prepareAndLoadMostRecent(nendo);
          const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(nendo);

          const result: KintaiAggregationsResult = yield deptsApi().getKintaiNendoSummaries(depCode, nendo);
          applySnapshot(
            self.lawBasisList,
            result.attributes.map(attr => Minutes.apiValueOf(attr.lawBasis)!),
          );
          const resultContent = result.aggregations.map(it => {
            const { user, months } = it;
            const { userId } = user;
            const dept = user.depCode ? nendoDepts.getDept(user.depCode) : undefined;
            const rank = user.rankCode ? nendoRanks.getRank(user.rankCode) : undefined;
            return {
              id: idKintaiSumEntry(nendo, userId),

              nendo,
              userId,

              userName: user.userName || "",
              depCode: user.depCode || "",
              depName: dept ? dept.name : "",

              rankCode: user.rankCode || "",
              rankName: rank ? rank.rankName : "",

              months: months.map(m => {
                const month = fromApiDate(m.month);
                return {
                  id: idKintaiSumMonthEntry(nendo, userId, month),

                  nendo,
                  userId,
                  month,

                  actual: Minutes.apiValueOf(m.actual)!,
                  designatedHours: Minutes.apiValueOf(m.designatedHours),
                  holidayDays: Days.apiValueOf(m.holidayDays)!,
                  lawOver: Minutes.apiValueOf(m.lawOver)!,
                  lawOverCalc: Minutes.apiValueOf(m.lawOverCalc)!,
                  lawOverNendoSum: Minutes.apiValueOf(m.lawOverNendoSum)!,
                  lawOverNine: Minutes.apiValueOf(m.lawOverNine)!,
                  over: Minutes.apiValueOf(m.over)!,
                  overNendoSum: Minutes.apiValueOf(m.overNendoSum)!,
                  transOnDays: Days.apiValueOf(m.transOnDays)!,

                  hourlyLeave: HourBasedDays.apiValueOf(m.hourlyLeave)!,
                  paidAllDays: Days.apiValueOf(m.paidAllDays)!,
                  paidHalfDays: HourBasedDays.apiValueOf(m.paidHalfDays)!,
                  plainPaidAllDays: Days.apiValueOf(m.plainPaidAllDays)!,
                  plainPaidHalfDays: HourBasedDays.apiValueOf(m.plainPaidHalfDays)!,

                  projects: m.projects.map(pj => {
                    const { columnNo } = pj;
                    return {
                      id: idKintaiSumMonthProjectEntry(nendo, userId, month, pj.columnNo),

                      nendo,
                      userId,
                      month,
                      columnNo,

                      pjCode: pj.projectCode || "",
                      odCode: pj.orderCode || "",

                      name: pj.projectName || "",
                      worktimeSum: Minutes.apiValueOf(pj.worktimeSum)!,
                    };
                  }),
                };
              }),
            };
          });
          applySnapshot(self.searchResult, resultContent);
          self.searchResultLoadingState = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "勤怠集計のロードに失敗しました。", exception });
        }
      });

      const triggerLoadDepts = flow(function*() {
        try {
          self.deptsLoadingState = "loading";
          self.nendoDepts = yield depts().prepareAndLoadMostRecent(self.targetNendo);
          self.deptsLoadingState = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "部門の取得に失敗しました", exception });
          self.deptsLoadingState = "failed";
        }
      });

      return {
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.dashboard.kintai_sum.ofYear(nendo).index());
        },
        navigateToDepCode(depCode: string) {
          appRouter().navigate(paths.dashboard.kintai_sum.ofYear(self.targetNendo).search(depCode));
        },
        route(pathFragment: string) {
          const { targetYear, targetDepCode } = fromPaths.dashboard.kintai_sum.search(pathFragment);

          if (targetYear) {
            this.setTargetNendo(targetYear);
          }

          if (targetDepCode) {
            this.setTargetDepCode(targetDepCode);
          } else {
            if (!self.targetDepCode) {
              const { depCode } = profile();
              if (depCode) {
                this.setTargetDepCode(depCode);
              }
            }
          }

          triggerLoadDepts();
          triggerSearch();
        },
        reload() {
          triggerSearch(true);
        },
        setMode(mode: typeof KintaiSumPageMode.Type) {
          self.mode = mode;
        },
        setTargetNendo(targetNendo: number) {
          self.targetNendo = targetNendo;
        },
        setTargetDepCode(depCode: string) {
          self.targetDepCode = depCode;
        },
        triggerLoadDepts,

        setFilterText(value: string) {
          self.filterText = value;
        },
        setFilterByMonthAll(value?: boolean) {
          if (value === undefined) {
            value = !self.allTargetMonthsSelected;
          }
          getNendoMonths(self.targetNendo).forEach(month => this.setFilterByMonth(month, value));
        },

        setFilterByMonth(month: Date, value?: boolean) {
          if (value === undefined) {
            value = !self.isInTargetMonth(month);
          }
          const nextValue = value
            ? uniq([...self.targetMonths, month.getMonth() + 1])
            : difference([...self.targetMonths], [month.getMonth() + 1]);
          applySnapshot(self.targetMonths, nextValue);
        },

        setDisplayHourAsDecimal(value: boolean) {
          self.displayHourAsDecimal = value;
        },
      };
    }),
  {},
);

export type KintaiSumPageModelType = typeof KintaiSumPageModel.Type;

export const KintaiSumPageModelSymbol = "KintaiSumPageModel_Symbol";
export const KintaiSumPageModel: KintaiSumPageModelModelType = model;
type KintaiSumPageModelInferredType = typeof model;
export interface KintaiSumPageModelModelType extends KintaiSumPageModelInferredType {}
