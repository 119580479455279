import { Button, ButtonGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { ScreenModeRibbon } from "../../../common/ribbon/ScreenModeRibbon";

import { KintaiSumPageSumType } from "../common/KintaiSumPageSumType";
import { KintaiSumPageSumTypeTitles } from "../common/KintaiSumPageSumTypeTitles";

export interface KinaiSumScreenModeSelectorRibbonProps {
  mode: KintaiSumPageSumType;
  onChangeMode: (value: KintaiSumPageSumType) => void;
}

const Btn: typeof Button = styled(Button)`
  width: 10.5em;
` as any;

export const KinaiSumScreenModeSelectorRibbon: React.FunctionComponent<
  KinaiSumScreenModeSelectorRibbonProps
> = props => {
  const { mode, onChangeMode } = props;

  const onClick = (value: KintaiSumPageSumType) =>
    React.useCallback(() => {
      onChangeMode(value);
    }, [onChangeMode, mode]);

  return (
    <ScreenModeRibbon title={KintaiSumPageSumTypeTitles[mode]}>
      <PageRibbonLine>
        <ButtonGroup>
          <Btn
            icon="layers"
            active={mode === "byOrderUser"}
            small={true}
            onClick={onClick("byOrderUser")}
            text={KintaiSumPageSumTypeTitles.byOrderUser}
          />
          <Btn
            icon="layer"
            active={mode === "byOrder"}
            small={true}
            onClick={onClick("byOrder")}
            text={KintaiSumPageSumTypeTitles.byOrder}
          />
        </ButtonGroup>
      </PageRibbonLine>
      <PageRibbonLine>
        <ButtonGroup>
          <Btn
            icon="person"
            active={mode === "byUser"}
            small={true}
            onClick={onClick("byUser")}
            text={KintaiSumPageSumTypeTitles.byUser}
          />
        </ButtonGroup>
      </PageRibbonLine>
    </ScreenModeRibbon>
  );
};
