import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";

export interface PJRFooterCellTimeKFProps {
  model: PJRFooterCellTimeKFVM;
}

export interface PJRFooterCellTimeKFVM {
  PJRFooterCellTime: Minutes | undefined;
}

export const PJRFooterCellTimeKF = observer((props: PJRFooterCellTimeKFProps) => {
  const { model } = props;
  return <HHMMField mode="print" value={model.PJRFooterCellTime} />;
});
