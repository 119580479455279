import { Button, Classes, InputGroup, Spinner } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { inputStringHandler } from "../../../utils/react";
import { DeptSelectorEntry } from "./DeptSelectorEntry";

export interface DeptSelectorVM {
  status: LoadingStatusType;
  roots: DeptSelectorEntry[];
  filterText: string;
  activeDept: DeptSelectorEntry | null;
  onSelectDept: (selected: DeptSelectorEntry) => void;
  onFilterTextChange: (filterText: string) => void;
}

export interface DeptSelectorProps {
  model: DeptSelectorVM;
}

const Container = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: column;
`;

const Filter = styled(InputGroup)`
  flex-basis: auto;
`;

const Depts = styled.div`
  flex-basis: fill;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

@observer
export class DeptSelector extends React.Component<DeptSelectorProps> {
  private onChange = inputStringHandler(this.props.model.onFilterTextChange);

  public render() {
    const { model } = this.props;
    if (model.status !== "loaded") {
      return <Spinner />;
    }

    const onSelected = model.onSelectDept;

    return (
      <Container>
        <Filter leftIcon="filter" placeholder="絞り込み検索" value={model.filterText} onChange={this.onChange} />
        <Depts>
          {model.roots.map(root => (
            <DeptNode key={root.depCode} depth={0} active={model.activeDept} onSelected={onSelected} dept={root} />
          ))}
        </Depts>
      </Container>
    );
  }
}

const DeptCodeLabel = styled.span`
  display: block;
  font-size: 5pt;
  color: rgba(0, 0, 0, 0.4);
`;

const DeptButtonLabel = styled.span`
  display: block;
  width: 20em;
`;

const DeptNode = (props: {
  dept: DeptSelectorEntry;
  depth: number;
  active: DeptSelectorEntry | null;
  onSelected: (entry: DeptSelectorEntry) => void;
}) => {
  const { dept, depth, active, onSelected } = props;
  const onClick = () => {
    onSelected(dept);
  };
  const isSelected = !!(active && active.depCode === dept.depCode);
  return (
    <DeptList>
      <DeptLem>
        <Button
          className={Classes.POPOVER_DISMISS}
          active={isSelected}
          disabled={isSelected}
          minimal={true}
          small={true}
          onClick={onClick}>
          <DeptButtonLabel style={{ paddingLeft: `${depth * 1}em` }}>
            <DeptCodeLabel>{dept.depCode}</DeptCodeLabel>
            {dept.depName}
          </DeptButtonLabel>
        </Button>
        {dept.children.map(c => (
          <DeptNode key={c.depCode} depth={depth + 1} active={active} onSelected={onSelected} dept={c} />
        ))}
      </DeptLem>
    </DeptList>
  );
};

const DeptList = styled.div`
  margin: 0;
  padding: 0;
`;
const DeptLem = styled.div``;
