import { Hotkey, Hotkeys } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { KintaiListDisplayedRowsType } from "../../../models/kintai_list/KintaiListDisplayedRowsType";
import { decorateTitle } from "../../../utils/apptitle";
import { KintaiReport, KintaiReportVM } from "../../common/kintai-table/KintaiReport";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { NoResultsSpread } from "../../common/NoResultsSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { SplitView } from "../../common/SplitView";
import { KintaiPageFrame, KintaiPageFrameVM } from "./frame/KintaiPageFrame";
import { NoRegKintaiListPageGrid, NoRegKintaiListPageGridVM } from "./noregistration/NoRegKintaiListPageGrid";
import { RegularKintaiListPageGrid, RegularKintaiListPageGridVM } from "./regular/RegularKintaiListPageGrid";
import { KintaiListPageRibbon, KintaiListPageRibbonVM } from "./ribbon/KintaiListPageRibbon";
import { SPortKintaiListPageGrid, SPortKintaiListPageGridVM } from "./sport/SPortKintaiListPageGrid";

export type KintaiListPageVM = {
  selectedType: typeof KintaiListDisplayedRowsType.Type;
  ribbonModel: KintaiListPageRibbonVM;
  regularGridModel: RegularKintaiListPageGridVM;
  sportGridModel: SPortKintaiListPageGridVM;
  noRegGridModel: NoRegKintaiListPageGridVM;
  searchResultLoadingState: LoadingStatusType;
  activeKintaiReport?: KintaiReportVM;

  onReloadOnFailure: () => void;
} & KintaiPageFrameVM;

const Split = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const GridContainer = styled.div<{ showActiveRow: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ showActiveRow }) => (showActiveRow ? `min-height: 9em;` : `height: 100%;`)}
`;

const InlineEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

@observer
export class KintaiListPage extends React.Component<{ model: KintaiListPageVM }> {
  public render() {
    const { model } = this.props;
    const grid = (() => {
      if (model.searchResultLoadingState === "init") {
        return <NoResultsSpread title="検索条件を指定してください" />;
      }

      if (model.searchResultLoadingState !== "loaded") {
        return (
          <GridTableLoading
            loadingStatus={model.searchResultLoadingState}
            onReloadOnFailure={model.onReloadOnFailure}
          />
        );
      }

      switch (model.selectedType) {
        case "Flex":
        case "Non-Flex":
          return <RegularKintaiListPageGrid model={model.regularGridModel} />;
        case "S-Port":
          return <SPortKintaiListPageGrid model={model.sportGridModel} />;
        case "NoReg":
        default:
          return <NoRegKintaiListPageGrid model={model.noRegGridModel} />;
      }
    })();

    return (
      <PageRibbon ribbon={<KintaiListPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("部門内の月ごと勤怠一覧")}</title>
        </Helmet>

        <SplitView
          min={100}
          max={500}
          formerSize={model.gridFrameSize}
          onChangeFormerSize={model.onChangeGridFrameSize}
          former={grid}
          latter={
            model.activeKintaiReport && (
              <KintaiPageFrame model={model}>
                <KintaiReport model={model.activeKintaiReport} />
              </KintaiPageFrame>
            )
          }
        />
      </PageRibbon>
    );
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey global={true} combo="F3" label="前の勤務表にジャンプ" onKeyDown={this.prev} />
        <Hotkey global={true} combo="F4" label="次の勤務表にジャンプ" onKeyDown={this.next} />
      </Hotkeys>
    );
  }

  private prev = () => {
    this.props.model.onMoveActiveRowPosition(-1);
  };

  private next = () => {
    this.props.model.onMoveActiveRowPosition(1);
  };
}
