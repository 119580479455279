import { IModelType, types } from "mobx-state-tree";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.model("Account", {
  userId: types.identifier,
  userName: types.string,
  depCode: types.maybe(types.string),
  rankCode: types.maybe(types.string),
  mailAddress: types.string,
  entryDate: types.maybe(types.Date),
  leaveDate: types.maybe(types.Date),
  roles: types.array(types.string),
});

export type AccountType = typeof Account.Type;

export const AccountSymbol = "Account_Symbol";
export const Account: AccountModelType = model;
type AccountInferredType = typeof model;
export interface AccountModelType extends AccountInferredType {}
