import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../../models/statistics/usePrivateDDModel";
import { PrvUserTableWarningCounter } from "../../../table/PrvUserTableCounter";
import { PrvUserValueTable } from "../../../table/PrvUserValueTable";

export const PrvLawoverCalcTableContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserValueTable data={model.monthly_lawoverCalc} atMonth={model.date} />;
});

export const PrvLawoverCalcTableCounterContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserTableWarningCounter title="健康管理時間（当月予測）" data={model.monthly_lawoverCalc} />;
});
