import { sortBy, uniqBy } from "lodash-es";
import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import {
  ProfileInterviewPageModel,
  ProfileInterviewPageModelSymbol,
} from "../../../models/profile/ProfileInterviewPageModel";
import { dateEq } from "../../../utils/date";
import { ProfileInterviewPageVM } from "./ProfileInterviewPage";
import { ProfileInterviewPageRibbonVM } from "./ProfileInterviewPageRibbon";
import { ProfileInterviewPageRowVMImpl } from "./ProfileInterviewPageRowVMImpl";

export class ProfileInterviewPageVMImpl implements ProfileInterviewPageVM, ProfileInterviewPageRibbonVM {
  @lazyInject(ProfileInterviewPageModelSymbol)
  private pageModel: typeof ProfileInterviewPageModel.Type;

  public get ribbonModel() {
    return this;
  }

  @computed
  get loadingStatus() {
    return this.pageModel.loadingStatus;
  }

  @computed
  get months() {
    const months = sortBy(
      uniqBy([...this.pageModel.ivMonths.map(it => it.month), ...this.pageModel.ftgMonths.map(it => it.month)], it =>
        it.getTime(),
      ),
      it => it.getTime(),
    );

    return months.map(
      month =>
        new ProfileInterviewPageRowVMImpl(
          this,
          this.pageModel.ivMonths.find(it => dateEq(month, it.month)),
          this.pageModel.ftgMonths.find(it => dateEq(month, it.month)),
        ),
    );
  }

  @computed
  get saveDisabled() {
    return this.pageModel.hasNoChange;
  }

  @action.bound
  public onSave() {
    this.pageModel.save();
  }

  @action.bound
  public onReset() {
    this.pageModel.reset();
  }
}
