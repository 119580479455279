import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type ActualKFProps = {
  label?: string;
  model: ActualKFVM;
} & CellFormPrintAdaptable;

export interface ActualKFVM {
  actual: KintaiInputItemState<Minutes | undefined>;
}

export const ActualKF = observer((props: ActualKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.actual);
  return <HHMMField {...props} label={props.label || "実働時間"} {...itemState} />;
});
