import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalSickoutDaysKFProps = {
  model: MTotalSickoutDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalSickoutDaysKFVM {
  mTotalSickoutDays: KintaiInputItemState<Days>;
}

export const MTotalSickoutDaysKF = observer((props: MTotalSickoutDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalSickoutDays;

  return <DayCountField {...props} label="" {...itemState} value={itemState.value.days} onChange={undefined} />;
});
