import { observer } from "mobx-react";
import React from "react";

import { WorkingTypeSelect } from "../../../common/form/WorkingTypeSelect";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RankRowVM } from "./RankPageGrid";

@observer
export class RKWorkingTypeCell extends React.Component<{ model: RankRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <WorkingTypeSelect
        mode="cell"
        disabled={row.disabled}
        value={row.workingType}
        onChange={row.onChangeWorkStyleCode}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
