import React, { useContext } from "react";

const map = new Map<any, any>();

export const createGlobalStore = <T>(key: any, geneateStore: (prevStore: T | undefined) => T) => {
  const store = geneateStore(map.get(key));
  map.set(key, store);
  const context = React.createContext<T>(store);

  return {
    Provider: context.Provider,
    Consumer: context.Consumer,
    useStore: () => useContext(context),
    getStore: () => map.get(key),
  };
};
