import { inject } from "inversify";

import { AppRouter, WHEN_ON_NAVIGATE_SYMBOL } from "./AppRouter";

export class AppRouterImpl extends AppRouter {
  constructor(@inject(WHEN_ON_NAVIGATE_SYMBOL) private readonly whenOnNavigate: (path: string) => void) {
    super();
  }

  public start() {
    const existingListener = window.webkintai_listner;
    if (existingListener) {
      this.dispose();
    }

    console.log("Registering AppRouter...");
    const newListener: EventListener = (evt: Event) => {
      this.onNavigate(location.pathname + location.search);
    };
    window.webkintai_listner = newListener;
    window.addEventListener("popstate", newListener);

    if (!existingListener) {
      console.log("Initializing routing...");
      this.onNavigate(location.pathname + location.search);
    }
  }

  public dispose() {
    if (window.webkintai_listner) {
      console.log("Removing existing state listeners...");
      window.removeEventListener("popstate", window.webkintai_listner);
      window.webkintai_listner = null;
    }
  }

  public navigate(path: string) {
    history.pushState(null, document.title, path);
    this.onNavigate(path);
  }

  public onNavigate(path: string) {
    this.whenOnNavigate(path);
  }

  public replaceWithoutEffects(path: string) {
    history.replaceState(null, document.title, path);
  }
}
