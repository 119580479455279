import { UserResult } from "@webkintai/api";
import { WkAbilities } from "@webkintai/privileges";
import { flatMap, union } from "lodash-es";
import { flow, getEnv, Instance, types } from "mobx-state-tree";

import { ProfileApi } from "../../services/api/ProfileApi";
import { getNendo } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { Depts, DeptsSymbol } from "../depts/Depts";
import { NendoDept } from "../depts/NendoDept";
import { NendoRank } from "../ranks/NendoRank";
import { Ranks, RanksSymbol } from "../ranks/Ranks";
import { RoleType } from "../roles/Role";
import { RolesSymbol, RolesType } from "../roles/Roles";
import { User } from "../users/User";
import { UsersSymbol, UsersType } from "../users/Users";

const model = types.optional(
  types
    .model("Profile", {
      me: types.maybe(types.reference(User)),
      dept: types.maybe(types.reference(NendoDept)),
      rank: types.maybe(types.reference(NendoRank)),
    })
    .views(self => {
      const rolesDef = (): RolesType => getEnv(self).get(RolesSymbol);

      return {
        get userId() {
          if (!self.me) {
            return "";
          }
          return self.me.userId;
        },
        /** ユーザ名 */
        get userName() {
          if (!self.me) {
            return "";
          }
          return self.me.userName;
        },
        get roles() {
          const defs = rolesDef();
          const roles = [...(self.rank ? [self.rank.role] : []), ...self.me!.roleCodes.map(it => defs.of(it))];
          return roles.filter(it => it !== undefined) as RoleType[];
        },
        get abilities() {
          return union(flatMap(this.roles, it => it.abilities));
        },
        get depCode() {
          return self.me && self.me.depCode;
        },
      };
    })
    .views(self => {
      return {
        hasAbility(ability: WkAbilities) {
          return !!self.abilities.find(it => it === ability);
        },
        get roleNames() {
          return self.roles.map(it => it.roleName);
        },
        get isExective() {
          return !!self.roles.find(it => it.roleCode === "exective");
        },
      };
    })
    .actions(self => {
      const profileApi = () => getDI(self, ProfileApi);
      const users = (): UsersType => getEnv(self).get(UsersSymbol);
      const depts = (): typeof Depts.Type => getEnv(self).get(DeptsSymbol);
      const ranks = (): typeof Ranks.Type => getEnv(self).get(RanksSymbol);

      const loadDept = flow(function*() {
        const nendoDepts = yield depts().prepareAndLoadMostRecent(getNendo(new Date()));
        if (self.me && self.me.depCode) {
          self.dept = nendoDepts.getDept(self.me!.depCode);
        }
      });

      const loadRank = flow(function*() {
        const nendoRanks = yield ranks().prepareAndLoadMostRecent(getNendo(new Date()));
        if (self.me && self.me.rankCode) {
          const rank = nendoRanks.getRank(self.me!.rankCode);
          if (rank) {
            self.rank = rank.id;
          }
        }
      });

      const loadProfile = flow(function*() {
        const profile: UserResult = yield profileApi().getProfile();
        const { user } = profile;
        if (user) {
          // Dynatrace用にユーザIDをグローバル変数に入れる
          window.currentUserId = user.userId;

          self.me = users().mergeFromUserEntity(user);
          yield loadDept();
          yield loadRank();
        }
      });

      return {
        loadProfile,
      };
    }),
  {},
);

export type ProfileType = typeof Profile.Type;

export const ProfileSymbol = "Model_Profile";
export const Profile: ProfileModelType = model;
type ProfileInferredType = typeof model;
export interface ProfileModelType extends ProfileInferredType {}

type ProfileIIf = Instance<typeof Profile>;
export interface ProfileInstance extends ProfileIIf {}
