import styled from "@emotion/styled";
import React from "react";
import CountUp from "react-countup";

export interface BadgeProps {
  count: number;
  hide?: boolean;
}

const WholeContainer = styled.span`
  position: relative;
`;

const BadgeFloat = styled.span`
  display: block;
  position: absolute;
  pointer-events: none;
  width: 3em;
  height: 0;
  right: 0;
  top: 0;
  text-align: right;
`;

const BadgeContainer = styled.span<{ hide?: boolean }>`
  display: inline-block;
  ${({ hide }) => hide && `display: none;`}

  line-height: 8pt;
  padding-top: 0;
  padding-left: 0.4ex;
  padding-right: 0.4ex;

  font-size: 8pt;
  color: white;
  background-color: red;
  text-align: center;

  border: 2px solid red;
  border-radius: 5px;

  opacity: 0.7;
`;

export const Badge: React.FunctionComponent<BadgeProps> = props => {
  const [prevCount, setPrevCount] = React.useState(0);
  if (props.count !== prevCount) {
    setPrevCount(props.count);
  }

  return (
    <WholeContainer>
      {props.children}
      <BadgeFloat>
        <BadgeContainer hide={props.hide}>
          <CountUp duration={0.5} useEasing={false} start={prevCount || 0} end={props.count} decimals={0} />
        </BadgeContainer>
      </BadgeFloat>
    </WholeContainer>
  );
};
