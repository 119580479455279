import styled from "@emotion/styled";
import React from "react";

import { mediaQueryNotLarge, mediaQueryNotSmall } from "../responsive/Responsive";
import { ResponsiveHOC } from "../responsive/ResponsiveHOC";

const Component = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #999;
  border-radius: 0.4ex;
  margin-right: 0.2ex;
`;

const RibbonSectionTitle = styled.div`
  flex: 0 auto;

  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #aaa;
  text-align: center;
  font-size: 8pt;
  font-weight: bold;
`;

const RibbonSectionBody = styled.div`
  flex: 1 auto;

  padding: 0.3ex 0.4ex 0ex 0.4ex;

  ${mediaQueryNotSmall} {
    min-height: 55px;
  }

  ${mediaQueryNotLarge} {
    padding: 0;
  }

  .bp3-button {
    margin: 0;
  }
`;

export const PageRibbonSection = ResponsiveHOC<{ title?: string; children: React.ReactNode }>(props => (
  <Component>
    {props.title && <RibbonSectionTitle>{props.title}</RibbonSectionTitle>}
    <RibbonSectionBody>{props.children}</RibbonSectionBody>
  </Component>
));
