import { InputGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import moment from "moment";
import React from "react";

import { dateEq } from "../../../utils/date";
import { recognizeAsDate } from "../../../utils/react";
import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { ErrorOverlay } from "./ErrorOverlay";
import { cellFormStyle, cellStyle, componentCommonClasses, hasNoChangeStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export const DateField: React.FC<DateFieldProps> = ({
  mode,
  value,
  label,
  onChange,
  placeholder,
  disabled,
  errors,
  warnings,
  infos,
  hasNoChange,
}) => {
  if (mode === "print") {
    return <>{dateAsDisplayText(value)}</>;
  }

  const ref = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLDivElement>(null);

  const inputElem = () => {
    if (ref.current) {
      return ref.current;
    }
    if (formRef.current) {
      return formRef.current.querySelector("input");
    }
    return undefined;
  };
  const setDefaultValue = () => {
    const $i = inputElem();

    if ($i) {
      setTimeout(() => {
        $i.defaultValue = "";
      });
    }
  };

  const [inputText, setInputText] = React.useState(dateAsDisplayText(value));
  const [inInput, setInInput] = React.useState(false);

  if (!inInput && inputText !== dateAsDisplayText(value)) {
    setInputText(dateAsDisplayText(value));
  }

  const onFocus: React.FormEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      setInputText(dateAsInputText(value));
      setInInput(true);
    },
    [value],
  );

  const onChangeComponentText: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    ev => {
      const text = ev.currentTarget.value.replace(/\s/g, "");
      setInputText(text);
    },
    [setInputText, onChange],
  );

  const onBlur: React.FormEventHandler<HTMLInputElement> = ev => {
    setInInput(false);
    const text = recognizeAsDate(inputElem()!!.value);
    setInputText(dateAsDisplayText(text));

    if (onChange) {
      if (!dateEq(value, text)) {
        onChange(text);
      }
    }
  };

  const placeholderText = placeholder || "0000/00/00";

  const className = classNames(
    FocusMarkerClassName,
    componentCommonClasses({
      disabled,
      hasNoChange,
    }),
  );

  if (mode === "cell") {
    return (
      <ErrorOverlay mode={mode} disabled={disabled} errors={errors} warnings={warnings} infos={infos}>
        <Cell
          className={className}
          type="text"
          ref={ref}
          placeholder={placeholderText}
          readOnly={disabled}
          onChange={onChangeComponentText}
          onFocus={onFocus}
          onBlur={onBlur}
          value={inputText}
        />
      </ErrorOverlay>
    );
  }

  const dateFormattedDate = dateAsDisplayText(recognizeAsDate(inputText)).replace(/\//g, "-");

  return (
    <FormLabel label={label} disabled={disabled} errors={errors} warnings={warnings} infos={infos}>
      <div ref={formRef}>
        <Form
          className={className}
          type="date"
          small={true}
          placeholder={placeholderText}
          disabled={disabled}
          onChange={onChangeComponentText}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={setDefaultValue}
          value={dateFormattedDate}
        />
      </div>
    </FormLabel>
  );
};

export type DateFieldProps = {
  label?: string;
  value: Date | undefined;
  disabled?: boolean;
  placeholder?: string;
  hasNoChange?: boolean;
  unit?: "year" | "month" | "date";
  onChange?: (value: Date | undefined) => void;
  errors?: string[];
  warnings?: string[];
  infos?: string[];
} & CellFormPrintAdaptable;

const Cell = styled.input`
  ${cellStyle}
  ${cellFormStyle}
  ${hasNoChangeStyle}
  `;

const Form = styled(InputGroup)`
  ${hasNoChangeStyle}
  &.bp3-input-group.bp3-small input {
    font-size: 80% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

function dateAsInputText(d: Date | undefined) {
  if (!d) {
    return "";
  }
  return moment(d).format("YYYYMMDD");
}

function dateAsDisplayText(d: Date | undefined) {
  if (!d) {
    return "";
  }
  return moment(d).format("YYYY/MM/DD");
}
