import { observer } from "mobx-react";
import React from "react";

import { SelectField, SelectFieldOptionItem } from "../../../common/form/SelectField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcRankNameCellVM {
  rankCode: string | undefined;
  onChangeRankCode: (value: string | undefined) => void;
  rankOptions: SelectFieldOptionItem[];
  rankCodeHasNoChange: boolean;
}

@observer
export class AcRankNameCell extends React.Component<{ model: AcRankNameCellVM }> {
  public render() {
    const { model } = this.props;

    return (
      <SelectField
        mode="cell"
        value={model.rankCode}
        hasNoChange={model.rankCodeHasNoChange}
        onChange={model.onChangeRankCode}
        options={model.rankOptions}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
