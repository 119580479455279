import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { DayCountField } from "../../../form/DayCountField";

export type MTotalLeaveDaysKFProps = {
  model: MTotalLeaveDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLeaveDaysKFVM {
  mTotalLeaveDays: KintaiInputItemState<Days>;
}

export const MTotalLeaveDaysKF = observer((props: MTotalLeaveDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLeaveDays;

  return (
    <DayCountField
      allowEmpty={true}
      {...props}
      label="mTotalLeaveDaysKF"
      {...itemState}
      value={itemState.value.days}
      onChange={undefined}
    />
  );
});
