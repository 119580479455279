import { Column } from "exceljs";

export interface ExcelColDef<T> {
  label: string;
  type: ExcelColumnInputType;
  serializer: ExcelSerializer<T>;
  deserializer: ExcelDeserializer<T>;
}

export type ExcelColumnInputType = "key" | "input" | "readOnly";

export type ExcelSerializer<T> = (value: T) => string;
export type ExcelDeserializer<T> = (value: string) => T;

export const generateColumns = (colDefs: Array<ExcelColDef<any>>): Array<Partial<Column>> =>
  colDefs.map(def => ({
    header: def.label,
    key: def.label,
    width: 30,
    style: {
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: inputTypeToColor(def.type) },
      },
    },
  }));

const inputTypeToColor = (inputType: ExcelColumnInputType) => {
  switch (inputType) {
    case "key":
      return "FFFF9999";
    case "input":
      return "FFFFFF66";
    case "readOnly":
      return "FFFFFFFF";
  }
};
