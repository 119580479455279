import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type AllNightOffKFProps = {
  model: AllNightOffKFVM;
} & CellFormPrintAdaptable;

export interface AllNightOffKFVM {
  allNightOff: KintaiInputItemState<Minutes | undefined>;
}

export const AllNightOffKF = observer((props: AllNightOffKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.allNightOff);
  return <HHMMField {...props} label="徹休時間" {...itemState} />;
});
