import { KintaiBunrui, NullKintai } from "@webkintai/bunrui";
import { HourBasedDays, Minutes } from "@webkintai/core";
import { getParentOfType, types } from "mobx-state-tree";
import moment from "moment";

import { BunruiIdx } from "../../../components/common/kintai-table/daily/fields/TypesKF";
import { typesHourBasedDays } from "../../primitives/HourBasedDays";
import { typesMinutes } from "../../primitives/Minutes";
import { typesKintaiBunrui } from "../kintaibunrui/typesKintaiBunrui";
import { KintaiDailyError } from "../KintaiDailyError";
import { MonthlyKintai } from "../MonthlyKintai";
import { makeArrivalLeaveOrdered } from "../utils/KintaiValueSettings";

const model = types
  .model("SPortDailyValues", {
    date: types.Date,
    errors: types.array(KintaiDailyError),

    type1: types.optional(typesKintaiBunrui, NullKintai),
    type2: types.optional(typesKintaiBunrui, NullKintai),
    type3: types.optional(typesKintaiBunrui, NullKintai),

    transDay: types.maybe(types.Date),
    hourlyLeave: types.maybe(typesHourBasedDays),
    hourlyNursingcare: types.maybe(typesHourBasedDays),
    hourlyFamilycare: types.maybe(typesHourBasedDays),

    arrival: types.maybe(types.Date),
    leave: types.maybe(types.Date),
    diff: types.maybe(typesMinutes),
    rest: types.maybe(typesMinutes),
    actual: types.maybe(typesMinutes),
    midnight: types.maybe(typesMinutes),
    immunity: types.maybe(typesMinutes),
    growingTime: types.maybe(typesMinutes),
    missed: types.maybe(typesMinutes),

    overDiff: types.maybe(typesMinutes),
    overRestNormal: types.maybe(typesMinutes),
    overRestMidnight: types.maybe(typesMinutes),
    overWeekday: types.maybe(typesMinutes),
    overWeekdayMidnight: types.maybe(typesMinutes),
    overHoliday: types.maybe(typesMinutes),
    overHolidayMidnight: types.maybe(typesMinutes),
    growingOver: types.maybe(typesMinutes),

    offCount: types.maybe(types.number),

    remarks: types.optional(types.string, ""),
  })
  .views(self => {
    return {
      get root() {
        return getParentOfType(self, MonthlyKintai);
      },
      get types() {
        return [self.type1, self.type2, self.type3];
      },
      get isManager() {
        return this.root.isManager;
      },
    };
  })
  .views(self => {
    return {
      get privilegedApproveIsRequired(): boolean {
        return self.types.some(it => it.requirePrivilegedAuthorization);
      },
      get approveIsRequired(): boolean {
        return self.types.some(it => it.checkIfApproveIsRequired(self.isManager));
      },
      get arrivalAsMinutes() {
        return Minutes.fromDate(self.date, self.arrival);
      },
      get leaveAsMinutes() {
        return Minutes.fromDate(self.date, self.leave);
      },
    };
  })
  .actions(self => {
    return {
      setType1(value: KintaiBunrui) {
        self.type1 = value;
      },
      setType2(value: KintaiBunrui) {
        self.type2 = value;
      },
      setType3(value: KintaiBunrui) {
        self.type3 = value;
      },
      setTransDay(value?: Date) {
        self.transDay = value;
      },
      setHourlyLeave(value?: HourBasedDays) {
        self.hourlyLeave = value;
      },
      setHourlyNursingcare(value?: HourBasedDays) {
        self.hourlyNursingcare = value;
      },
      setHourlyFamilycare(value?: HourBasedDays) {
        self.hourlyFamilycare = value;
      },
      setType(index: BunruiIdx, value: KintaiBunrui) {
        self.types[index - 1] = value;
      },
      setArrival(value?: Date) {
        self.arrival = value;
        makeArrivalLeaveOrdered(self);
      },
      setArrivalFromMinutes(value?: Minutes) {
        this.setArrival(value !== undefined ? value.intoDate(self.date) : value);
      },
      setLeave(value?: Date) {
        self.leave = value;
        makeArrivalLeaveOrdered(self);
      },
      setLeaveFromMinutes(value?: Minutes) {
        const addOffset = value && self.arrivalAsMinutes && self.arrivalAsMinutes.minutes >= value.minutes ? 1 : 0;
        this.setLeave(
          value !== undefined
            ? moment(value.intoDate(self.date))
                .add(addOffset, "days")
                .toDate()
            : value,
        );
      },
      setDiff(value?: Minutes) {
        self.diff = value;
      },
      setRest(value?: Minutes) {
        self.rest = value;
      },
      setActual(value?: Minutes) {
        self.actual = value;
      },
      setMidnight(value?: Minutes) {
        self.midnight = value;
      },
      setImmunity(value?: Minutes) {
        self.immunity = value;
      },
      setGrowingTime(value?: Minutes) {
        self.growingTime = value;
      },
      setMissed(value?: Minutes) {
        self.missed = value;
      },
      setOverDiff(value?: Minutes) {
        self.overDiff = value;
      },
      setOverRestNormal(value?: Minutes) {
        self.growingOver = value;
      },
      setOverRestMidnight(value?: Minutes) {
        self.growingOver = value;
      },
      setOverWeekday(value?: Minutes) {
        self.overWeekday = value;
      },
      setOverWeekdayMidnight(value?: Minutes) {
        self.overWeekdayMidnight = value;
      },
      setOverHoliday(value?: Minutes) {
        self.overHoliday = value;
      },
      setOverHolidayMidnight(value?: Minutes) {
        self.overHolidayMidnight = value;
      },
      setGrowingOver(value?: Minutes) {
        self.growingOver = value;
      },
      setOffCount(value?: number) {
        self.offCount = value;
      },
      setRemarks(value?: string) {
        self.remarks = (value || "").substr(0, 800);
      },
    };
  })
  .actions(self => {
    return {
      setFixedTimes() {
        const { type1 } = self;
        if (!type1 || !type1.sportFixedTimes) {
          return;
        }
        const { sportFixedTimes } = type1;

        if (self.arrival === undefined) {
          self.setArrivalFromMinutes(sportFixedTimes.open);
        }
        if (self.leave === undefined) {
          self.setLeaveFromMinutes(sportFixedTimes.close);
        }
        if (self.actual === undefined) {
          self.setActual(sportFixedTimes.weekdayActual);
        }
      },
    };
  });
export const SPortDailyValues: SPortDailyValuesModelType = model;
type SPortDailyValuesInferredType = typeof model;
export interface SPortDailyValuesModelType extends SPortDailyValuesInferredType {}
