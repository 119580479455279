import { injectable } from "inversify";

export const WHEN_ON_NAVIGATE_SYMBOL = "whenOnNavigate";

@injectable()
export abstract class AppRouter {
  public abstract start(): void;

  public abstract dispose(): void;

  public abstract navigate(path: string): void;

  public abstract onNavigate(path: string): void;

  /** 入れ替えるが、特に副作用をもたらさない */
  public abstract replaceWithoutEffects(path: string): void;
}
