import { UserMonthlyReportEntity } from "@webkintai/api";
import { KintaiBunrui, kintaiBunruiOf, NullKintai } from "@webkintai/bunrui";
import moment from "moment";

const anyError = (errors: Array<{ level: string }>) => !!errors.find(it => it.level === "ERROR");

export const returnedResultHasError = (report: UserMonthlyReportEntity) => {
  const { regularReport, sportReport, projectReport } = report;
  const r = report.attributes!.workingType.value === "2" ? sportReport : regularReport;
  if (r) {
    if (anyError(r.errors)) {
      return true;
    }
    for (const d of r.days) {
      if (anyError(d.errors)) {
        return true;
      }
    }
  }
  if (projectReport) {
    if (anyError(projectReport.errors)) {
      return true;
    }

    if (anyError(projectReport.otherColumn.errors)) {
      return true;
    }

    for (const d of projectReport.otherColumn.days) {
      if (anyError(d.errors)) {
        return true;
      }
    }

    for (const c of projectReport.columns) {
      if (anyError(c.errors)) {
        return true;
      }

      for (const d of c.days) {
        if (anyError(d.errors)) {
          return true;
        }
      }
    }
  }

  return false;
};

const dateLabel = (d: string) => moment(d).format("DD日");

export const collectMonthlyReportAllErrWarnInfos = (
  level: "ERROR" | "WARN" | "INFO",
  report: UserMonthlyReportEntity,
) => {
  const result: string[] = [];

  const appendErrors = (label: string, errors: Array<{ level: string; message: string }>) => {
    result.push(...errors.filter(it => it.level === level).map(it => `${label}${it.message}`));
  };

  const { regularReport, sportReport, projectReport } = report;
  const r = report.attributes!.workingType.value === "2" ? sportReport : regularReport;
  if (r) {
    appendErrors("", r.errors);

    for (const d of r.days) {
      appendErrors(`${dateLabel(d.date)}: `, d.errors);
    }
  }

  if (projectReport) {
    appendErrors("【月報】", projectReport.errors);
    appendErrors("【月報】", projectReport.otherColumn.errors);

    for (const d of projectReport.otherColumn.days) {
      appendErrors(`【月報 ${dateLabel(d.date)}】`, d.errors);
    }

    for (const c of projectReport.columns) {
      appendErrors(`【月報 | ${c.columnNo}】`, c.errors);

      for (const d of c.days) {
        appendErrors(`【月報 | ${c.columnNo} | ${dateLabel(d.date)}】`, d.errors);
      }
    }
  }

  return result;
};

type CollectType = "all" | "not_applied" | "not_approved" | "privileged_not_approved" | "rejected";

export const collectMonthlyReportAllKintaiApprovalErrors = (
  report: UserMonthlyReportEntity,
  collectType: CollectType,
) => {
  const result: string[] = [];

  const appendErrors = (label: string, errors: Array<{ code: string; message: string }>, types: KintaiBunrui[]) => {
    const hasPrivilegedApprovalType = types.some(it => it.requirePrivilegedAuthorization);
    result.push(
      ...errors
        .filter(it => it.code.indexOf("RequireAuthorization") >= 0)
        .filter(it => {
          switch (collectType) {
            case "all":
              return true;
            case "not_applied":
              return it.message.indexOf("申請が必要") >= 0;
            case "not_approved":
              if (hasPrivilegedApprovalType) {
                return 0;
              }
              return it.message.indexOf("承認が必要") >= 0;
            case "privileged_not_approved":
              if (!hasPrivilegedApprovalType) {
                return 0;
              }
              return it.message.indexOf("承認が必要") >= 0;
            case "rejected":
              return it.message.indexOf("申請が却下") >= 0;
          }
        })
        .map(it => `${label}${it.message}`),
    );
  };

  const { regularReport, sportReport } = report;
  const r = report.attributes!.workingType.value === "2" ? sportReport : regularReport;
  if (r) {
    for (const d of r.days) {
      const types: KintaiBunrui[] = d.types.map(it => kintaiBunruiOf(it.value) || NullKintai);
      appendErrors(`${dateLabel(d.date)}: `, d.errors, types);
    }
  }

  return result;
};
