import React from "react";

import { CellFormAdaptable } from "./CellFormAdaptable";
import { SelectField } from "./SelectField";

export type ManagerTypeSelectProps = {
  value: ManagerTypeValue;
  disabled?: boolean;
  onChange: (value: ManagerTypeValue) => void;
} & CellFormAdaptable;

export type ManagerTypeValue = "0" | "1" | undefined;

export const ManagerTypeSelect = (props: ManagerTypeSelectProps) => {
  const onChangeHandler = (value: string | undefined) => {
    if (value === "0" || value === "1") {
      props.onChange(value);
      return;
    }
    props.onChange(undefined);
  };

  return (
    <SelectField
      {...props}
      onChange={onChangeHandler}
      label="一般・管理職"
      value={props.value}
      disabled={props.disabled}
      options={[{ value: undefined, label: "" }, { value: "0", label: "一般職" }, { value: "1", label: "管理職" }]}
    />
  );
};
