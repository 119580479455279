import { minutesEq } from "@webkintai/core";
import { applySnapshot, flow, getParentOfType, getSnapshot, IModelType, types } from "mobx-state-tree";

import { KintaiApi } from "../../../services/api/KintaiApi";
import { AppNotifier } from "../../../services/msg/AppNotifier";
import { hasNoChangeReduce } from "../../../utils/model";
import { getDI } from "../../common/getDI";
import { User_Type } from "../../users/User";
import { MonthlyKintai } from "../MonthlyKintai";
import { KintaiMonthlyAttributesValues } from "./KintaiMonthlyAttributesValues";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const KintaiMonthlyAttributes = types
  .model("KintaiMonthlyAttributes", {
    origin: KintaiMonthlyAttributesValues,
    input: KintaiMonthlyAttributesValues,
    computed: KintaiMonthlyAttributesValues,
  })
  .views(self => {
    const compareUserIdIfAny = (l?: { userId: string }, r?: { userId: string }) =>
      l === r || (l !== undefined && r !== undefined && l.userId === r.userId);

    return {
      get hasNoChange() {
        const { input, origin } = self;
        return hasNoChangeReduce([
          input.mainKintaiType === origin.mainKintaiType,
          minutesEq(input.designatedMinutes, origin.designatedMinutes),
        ]);
      },
    };
  })
  .views(self => {
    return {
      get reviewer() {
        return self.input.reviewer;
      },
      get approver() {
        return self.input.approver;
      },
      get mainKintaiType() {
        return self.input.mainKintaiType;
      },
      get depCode() {
        return self.computed.depCode;
      },
      get depName() {
        return self.computed.depName;
      },
      get rankCode() {
        return self.computed.rankCode;
      },
      get rankName() {
        return self.computed.rankName;
      },
      get rankPosition() {
        return self.computed.rankPosition;
      },
      get isManger() {
        return this.rankPosition === "1";
      },
      get designatedMinutes() {
        return self.input.designatedMinutes;
      },
    };
  })
  .actions(self => {
    const monthlyKintai = () => getParentOfType(self, MonthlyKintai);
    const appNotifier = () => getDI(self, AppNotifier);
    const kintaiApi = () => getDI(self, KintaiApi);

    const notifySamePersonsRevApp = () => {
      appNotifier().error({ message: "査閲者・承認者に同一人物は指定できません" });
    };

    const saveApprovers = flow(function*() {
      const userId = monthlyKintai().userId;
      const month = monthlyKintai().month;

      try {
        yield kintaiApi().updateApprovers(userId, month, {
          approverId: self.input.approver ? self.input.approver.userId : undefined,
          reviewerId: self.input.reviewer ? self.input.reviewer.userId : undefined,
        });
        appNotifier().info({ message: "査閲者・承認者を保存しました。" });
        self.origin.approver = self.input.approver;
        self.origin.reviewer = self.input.reviewer;
      } catch (exception) {
        appNotifier().error({ message: "査閲者・承認者の保存に失敗しました。", exception });
      }
    });

    return {
      reset() {
        const values = getSnapshot(self.origin);
        applySnapshot(self.input, values);
        applySnapshot(self.computed, values);
      },
      saveReviewerAndApprovers() {
        saveApprovers();
      },
      saveReviewer(value: User_Type) {
        if (self.approver && self.approver.userId === value.userId) {
          notifySamePersonsRevApp();
          return;
        }
        self.input.setReviewer(value);
        saveApprovers();
      },
      removeReviewer() {
        self.input.removeReviewer();
        saveApprovers();
      },
      saveApprover(value: User_Type) {
        if (self.reviewer && self.reviewer.userId === value.userId) {
          notifySamePersonsRevApp();
          return;
        }

        self.input.setApprover(value);
        saveApprovers();
      },
      removeApprover() {
        self.input.removeApprover();
        saveApprovers();
      },
      resetTimestamps() {},
    };
  });

export type KintaiMonthlyAttributesType = typeof KintaiMonthlyAttributes.Type;

export const KintaiMonthlyAttributesSymbol = "KintaiMonthlyAttributes_Symbol";
