import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type LeaveKFProps = {
  model: LeaveKFVM;
} & CellFormPrintAdaptable;

export interface LeaveKFVM {
  leave: KintaiInputItemState<Minutes | undefined>;
}

export const LeaveKF = observer((props: LeaveKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.leave);

  return <HHMMField {...props} label="退勤時間" allowTimeInput={true} disableZeroGray={true} {...itemState} />;
});
