import { observer } from "mobx-react";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { PHPagGridRowVM } from "./PHPageGrid";

@observer
export class PHRankNameCell extends React.Component<{ model: PHPagGridRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
        {row.rankName}
      </LabelField>
    );
  }
}
