import { Dialog } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { InputAccountAbsenceInstance } from "../../../../../models/accounts/input/InputAccountAbsence";
import { useAccountsPageModel } from "../../../../../models/accounts/useAccountsPageModel";
import { AbsenceDialog, AbsenceDialogRow } from "./AbsenceDialog";

export interface AbsenceDialogVM {
  rows: InputAccountAbsenceInstance[];
}

export const AbsenceDialogContainer: React.FC<{ children?: never }> = observer(() => {
  const model = useAccountsPageModel();

  if (!model.activeAbsenceAccount) {
    return <></>;
  }

  return (
    <Dialog
      isOpen={true}
      title="休業の設定"
      onClose={() => {
        model.setActiveAbsenceAccount(undefined);
      }}>
      <AbsenceDialog
        onAddRow={model.activeAbsenceAccount.value.addAbsence}
        errors={model.activeAbsenceAccount.value.absenceErrors}>
        {model.activeAbsenceAccount.value.absences.map((row, idx) => (
          <AbsenceDialogRowContainer key={idx} no={idx + 1} row={row} />
        ))}
      </AbsenceDialog>
    </Dialog>
  );
});

const AbsenceDialogRowContainer: React.FC<{
  no: number;
  row: InputAccountAbsenceInstance;
  children?: never;
}> = observer(({ no, row }) => {
  const model = useAccountsPageModel();

  return (
    <AbsenceDialogRow
      no={no}
      type={row.type}
      onChangeType={row.setType}
      from={row.from}
      onChangeFrom={row.setFrom}
      fromDisabled={row.inputDisabled}
      to={row.to}
      onChangeTo={row.setTo}
      toDisabled={row.inputDisabled}
      remarks={row.remarks}
      onChangeRemarks={row.setRemarks}
      remarksDisabled={row.inputDisabled}
      onRemoveRow={() => {
        if (!model.activeAbsenceAccount) {
          return;
        }
        model.activeAbsenceAccount.value.removeAbsence(row);
      }}
      errors={row.errors}
    />
  );
});
