import { observer } from "mobx-react";
import React from "react";

import { ProfilePageScreenModes } from "../../../models/profile/ProfilePageScreenModes";
import { PageHeaderLeft } from "../../common/page/PageHeaderLeft";
import { PageHeaderRight } from "../../common/page/PageHeaderRight";
import { SubMenuButton } from "../../common/page/SubMenuButton";

export interface ProfileSubMenuVM {
  pageMode: typeof ProfilePageScreenModes.Type;
  onNavigateToInbox: () => void;
  onNavigateToSettings: () => void;
  showPhysicianInterview: boolean;
  onNavigateToPhysicianInterview: () => void;
  onLogout: () => void;
}

@observer
export class ProfileSubMenu extends React.Component<{ model: ProfileSubMenuVM }> {
  public render() {
    const { model } = this.props;

    return (
      <>
        <PageHeaderLeft>
          <SubMenuButton onClick={model.onNavigateToInbox} active={model.pageMode === "inbox"} icon="inbox">
            通知
          </SubMenuButton>
          <SubMenuButton onClick={model.onNavigateToSettings} active={model.pageMode === "settings"} icon="cog">
            ユーザ情報
          </SubMenuButton>
          {model.showPhysicianInterview && (
            <SubMenuButton
              onClick={model.onNavigateToPhysicianInterview}
              active={model.pageMode === "interview"}
              icon="heart">
              産業医面接等
            </SubMenuButton>
          )}
        </PageHeaderLeft>
        <PageHeaderRight>
          <SubMenuButton onClick={model.onLogout} small={true} minimal={true} icon="log-out">
            ログアウト
          </SubMenuButton>
        </PageHeaderRight>
      </>
    );
  }
}
