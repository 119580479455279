import styled from "@emotion/styled";
import { KintaiBunrui } from "@webkintai/bunrui";
import { max } from "lodash-es";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { FunctionComponent } from "react";

import { NoResultsSpread } from "../../../common/NoResultsSpread";

export interface TransDayPageTableVM {
  rows: TransDayPageTableRowVM[];
}

export interface TransDayPageTableRowVM {
  userId: string;
  userName: string;
  deptCode: string;
  deptName: string;
  isManager: boolean;

  details: TransDayRowDetailsVM[];
}

export interface TransDayRowDetailsVM {
  id: string;
  date: Date;
  onNavigateToDate: () => void;
  types: KintaiBunrui[];
  transDay: Date | undefined;
  onNavigateToTransDay: () => void;
  checkResult: boolean;
}

const Container = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  border-spacing: 0px;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid #ccc;
  }

  tr {
  }
`;

const UserTd = styled.td`
  min-width: 8em;
  max-width: 8em;
  text-align: center;
`;

const DetailTh = styled.th`
  min-width: 6em;
  max-width: 6em;
  background-color: #eeffee;
`;

const DetailTd: FunctionComponent<{ checkResult: boolean; style?: any }> = ({ checkResult, children, style }) => (
  <DetailTdInt style={style} className={checkResult ? "CheckResult" : ""}>
    {children}
  </DetailTdInt>
);

const DetailTdInt = styled.td`
  max-width: 9em;
  min-width: 9em;
  font-size: 95%;
  text-align: center;

  &.CheckResult {
    backgroundcolor: #ddd;
  }
`;

const Type = styled.div``;

const DateLink: FunctionComponent<{ date: Date | undefined; onClick: () => void }> = props =>
  props.date ? (
    <a href="javascript: undefined" onClick={props.onClick}>
      {formatDate(props.date)}
    </a>
  ) : (
    <></>
  );

const formatDate = (d: Date | undefined) => (d ? moment(d).format("YYYY/MM/DD (ddd)") : "");

const detailsItemCount = 4;

@observer
export class TransDayPageTable extends React.Component<{ model: TransDayPageTableVM }> {
  public render() {
    const { model } = this.props;
    const { rows } = model;

    if (rows.length === 0) {
      return <NoResultsSpread />;
    }

    const detailsColSpan = 1 + (max(rows.map(it => it.details.length)) || 0);

    return (
      <Container>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>部門</th>
              <th>氏名</th>
              <th colSpan={detailsColSpan}>詳細</th>
            </tr>
          </thead>

          <tbody>
            {rows.map((it, idx) => (
              <React.Fragment key={it.userId}>
                <tr key={it.userId}>
                  <td rowSpan={detailsItemCount}>{idx + 1}</td>
                  <UserTd rowSpan={detailsItemCount}>
                    <div>{it.deptName}</div>
                    <div>({it.deptCode})</div>
                  </UserTd>
                  <UserTd rowSpan={detailsItemCount}>
                    <div>{it.userName}</div>
                    <div>({it.userId})</div>
                    {it.isManager && <div>※（現）管理職</div>}
                  </UserTd>
                  <DetailTh>日付</DetailTh>
                  {it.details.map(iit => (
                    <DetailTd
                      key={iit.id}
                      checkResult={iit.checkResult}
                      style={{ fontWeight: "bold", backgroundColor: "#efe" }}>
                      <DateLink date={iit.date} onClick={iit.onNavigateToDate} />
                    </DetailTd>
                  ))}
                </tr>
                <tr>
                  <DetailTh>分類</DetailTh>
                  {it.details.map(iit => (
                    <DetailTd key={iit.id} checkResult={iit.checkResult}>
                      {iit.types.map((t, tidx) => (
                        <Type key={tidx}>{t.name}</Type>
                      ))}
                    </DetailTd>
                  ))}
                </tr>
                <tr>
                  <DetailTh>振替日</DetailTh>
                  {it.details.map(iit => (
                    <DetailTd key={iit.id} checkResult={iit.checkResult}>
                      <DateLink date={iit.transDay} onClick={iit.onNavigateToTransDay} />
                    </DetailTd>
                  ))}
                </tr>
                <tr>
                  <DetailTh>OK/NG</DetailTh>
                  {it.details.map(iit => (
                    <DetailTd key={iit.id} checkResult={iit.checkResult}>
                      {iit.checkResult ? "OK" : "NG"}
                    </DetailTd>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}
