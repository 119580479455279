import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type WorkplaceSyussyaKFProps = {
  model: WorkplaceSyussyaKFVM;
} & CellFormPrintAdaptable;

export interface WorkplaceSyussyaKFVM {
  workplaceSyussya: KintaiInputItemState<boolean>;
}

export const WorkplaceSyussyaKF = observer((props: WorkplaceSyussyaKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.workplaceSyussya);

  return <CheckboxField {...props} label="通常出社先" {...itemState} />;
});
