import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { RankPageGrid, RankPageGridVM } from "./grid/RankPageGrid";
import { RankPageRibbon, RankPageRibbonVM } from "./ribbon/RankPageRibbon";

export interface RankPageVM {
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
  pageRibbonModel: RankPageRibbonVM;
  rankPageGridModel: RankPageGridVM;
}

@observer
export class RankPage extends React.Component<{ model: RankPageVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;
    return (
      <PageRibbon ribbon={<RankPageRibbon model={model.pageRibbonModel} />}>
        <Helmet>
          <title>{decorateTitle("ランク管理")}</title>
        </Helmet>

        {loadingStatus !== "loaded" && (
          <GridTableLoading loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
        )}
        {loadingStatus === "loaded" && <RankPageGrid model={model.rankPageGridModel} />}
      </PageRibbon>
    );
  }
}
