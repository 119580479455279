import { types } from "mobx-state-tree";

/**
 * ユーザが選んだ勤怠表の種別
 *
 * Flex / 非Flex / S-Port のいずれか
 */
export const MainKintaiType = types.enumeration("KintaiType", ["Flex", "Non-Flex", "S-Port"]);

export function fromWorkingTypeCodeToMainKintaiType(workingTypeCode: string): MainKintaiTypeType {
  switch (workingTypeCode) {
    case "0":
      return "Non-Flex";
    case "2":
      return "S-Port";
    default:
      return "Flex";
  }
}

export enum WorkingTypeEnum {
  _0 = "0",
  _1 = "1",
  _2 = "2",
}

export function fromMainKintaiTypeToWorkingType(mainKintaiType: MainKintaiTypeType): WorkingTypeEnum {
  switch (mainKintaiType) {
    case "Non-Flex":
      return WorkingTypeEnum._0;
    case "S-Port":
      return WorkingTypeEnum._2;
    default:
      return WorkingTypeEnum._1;
  }
}

export type MainKintaiTypeType = typeof MainKintaiType.Type;
