import { observer } from "mobx-react";
import React from "react";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";

import { ApprovalStamp } from "./ApprovalStamp";

export type MonthlyReviewerStampProps = {
  model: MonthlyReviewerStampVM;
} & CellFormAdaptable;

export interface MonthlyReviewerStampVM {
  reviewerStamped: boolean;
}

export const MonthlyReviewerStamp = observer((props: MonthlyReviewerStampProps) => {
  return <ApprovalStamp {...props} stamped={props.model.reviewerStamped} />;
});
