import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOffKFProps = {
  model: MTotalOffKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOffKFVM {
  mTotalOff: KintaiInputItemState<Minutes>;
}

export const MTotalOffKF = observer((props: MTotalOffKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOff;

  return <HHMMField {...props} label="mTotalOffKF" {...itemState} onChange={undefined} />;
});
