import React from "react";

import { Responsive, ResponsiveSpecification } from "./Responsive";

export const ResponsiveHOC = <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps & ResponsiveSpecification>,
) => {
  return class ResponsiveHOCComponent extends React.Component<OriginalProps> {
    public static displayName = `ResponsiveComponent_${WrappedComponent.displayName}`;
    public render() {
      return <Responsive>{params => <WrappedComponent {...params} {...this.props} />}</Responsive>;
    }
  } as React.ComponentType<OriginalProps>;
};
