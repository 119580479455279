import { observer } from "mobx-react";
import React from "react";

import { DailyFormTransDayContainer } from "../DailyFormTransDayContainer";
import { DailyKintaiFormLine4, EntireDailyKintaiFormLineChild } from "../DailyKintaiFormPane";
import { ActualKF } from "../fields/ActualKF";
import { DiffKF } from "../fields/DiffKF";
import { GrowingOverKF } from "../fields/GrowingOverKF";
import { HourlyFamilycareKF } from "../fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../fields/hourlyleave/HourlyNursingcare";
import { ImmunityKF } from "../fields/ImmunityKF";
import { MidnightKF } from "../fields/MidnightKF";
import { MissedKF } from "../fields/MissedKF";
import { OverDiffKF } from "../fields/OverDiffKF";
import { OverHolidayKF } from "../fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../fields/OverHolidayMidnightKF";
import { OverRestMidnightKF } from "../fields/OverRestMidnightKF";
import { OverRestNormalKF } from "../fields/OverRestNormalKF";
import { OverWeekdayKF } from "../fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../fields/RemarksKF";
import { RestKF } from "../fields/RestKF";
import { TimeSumKF } from "../fields/TimeSumKF";
import { TransDayKF } from "../fields/TransDayKF";
import { SPortDailyVM } from "./SPortDailyVM";

@observer
export class SPortDailyKintaiFormBody extends React.Component<{ model: SPortDailyVM }> {
  public render() {
    const { props } = this;
    const { model } = props;
    return (
      <>
        <DailyKintaiFormLine4>
          <DailyFormTransDayContainer>
            <TransDayKF mode="form" model={model} />
          </DailyFormTransDayContainer>
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <HourlyLeaveKF mode="form" model={model} />
          <HourlyNursingcareKF mode="form" model={model} />
          <HourlyFamilycareKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <DiffKF mode="form" model={model} />
          <RestKF mode="form" model={model} />
          <ActualKF mode="form" label="普通実働" model={model} />
          <MissedKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <OverDiffKF mode="form" model={model} />
          <MidnightKF mode="form" model={model} />
          <ImmunityKF mode="form" model={model} />
          <TimeSumKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <OverRestNormalKF mode="form" model={model} />
          <OverRestMidnightKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <OverWeekdayKF mode="form" model={model} />
          <OverWeekdayMidnightKF mode="form" model={model} />
          <OverHolidayKF mode="form" model={model} />
          <OverHolidayMidnightKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <GrowingOverKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <EntireDailyKintaiFormLineChild>
            <RemarksKF mode="form" model={model} />
          </EntireDailyKintaiFormLineChild>
        </DailyKintaiFormLine4>
      </>
    );
  }
}
