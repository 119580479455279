import { IModelType, types } from "mobx-state-tree";

import { typesKintaiBunrui } from "../kintai/kintaibunrui/typesKintaiBunrui";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.model("TransDayResultEachDayEntry", {
  date: types.Date,
  types: types.array(typesKintaiBunrui),
  transDay: types.maybe(types.Date),
  valid: types.boolean,
});

export const TransDayResultEachDayEntrySymbol = "TransDayResultEachDayEntry_Symbol";
export const TransDayResultEachDayEntry: TransDayResultEachDayEntryModelType = model;
type TransDayResultEachDayEntryInferredType = typeof model;
export interface TransDayResultEachDayEntryModelType extends TransDayResultEachDayEntryInferredType {}
