import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { PageRibbonSection } from "../../page/PageRibbonSection";

export interface SPortSetFixedTimesSectionVM {
  showsSetSPortTimeFixedTimes: boolean;
  setSPortTimeFixedTimesDisabled: boolean;
  onSetSPortTimeFixedTimes: () => void;
}

@observer
export class SPortSetFixedTimesSection extends React.Component<{ model: SPortSetFixedTimesSectionVM }> {
  public render() {
    const { model } = this.props;

    return (
      <>
        {model.showsSetSPortTimeFixedTimes && (
          <PageRibbonSection title="S-Port 定時設定">
            <Button
              disabled={model.setSPortTimeFixedTimesDisabled}
              small={true}
              text="定時設定"
              icon="time"
              onClick={model.onSetSPortTimeFixedTimes}
            />
          </PageRibbonSection>
        )}
      </>
    );
  }
}
