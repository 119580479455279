export const fileReadPromise = (file: File) =>
  new Promise<null | ArrayBuffer>(done => {
    const fr = new FileReader();

    fr.onload = ev => {
      if (!fr.result) {
        done(null);
      }
      done(fr.result as ArrayBuffer);
    };
    fr.onerror = ev => {
      done(null);
    };
    fr.onabort = ev => {
      done(null);
    };
    fr.readAsArrayBuffer(file);
  });
