import { UserVacationsEntity, VacationDaysDetailEntity } from "@webkintai/api";
import { HourBasedDays } from "@webkintai/core";
import { IModelType, SnapshotIn, types } from "mobx-state-tree";

import { typesHourBasedDays } from "../../primitives/HourBasedDays";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const VacationTotalValues = types
  .model("VacationTotalValues", {
    // 実際は1日単位
    takenRefreshDays: typesHourBasedDays,
    // 実際は1日単位
    takenPositiveDays: typesHourBasedDays,
    takenPaidDays: typesHourBasedDays,
    // 実際は1日単位
    takenSummerDays: typesHourBasedDays,
    takenSpDays: typesHourBasedDays,

    takenHourlyLeave: typesHourBasedDays,
    takenHourlyNursingcare: typesHourBasedDays,
    takenHourlyFamilycare: typesHourBasedDays,

    takenMenstruationDays: typesHourBasedDays,
    takenNursingcareDays: typesHourBasedDays,
    takenFamilycareDays: typesHourBasedDays,
    takenJudgmentDays: typesHourBasedDays,
    takenInjuriesDays: typesHourBasedDays,

    // 実際は1日単位
    restRefreshDays: typesHourBasedDays,
    // 実際は1日単位
    restPositiveDays: typesHourBasedDays,
    restPaidDays: typesHourBasedDays,
    // 実際は1日単位
    restSummerDays: typesHourBasedDays,
  })
  .views(self => {
    return {
      /** 時間休を含まない累計値 */
      get takenPlainPaidDays() {
        return new HourBasedDays(0).add(self.takenPaidDays).add(HourBasedDays.of(-self.takenHourlyLeave.hours)!);
      },
      /** 介護・看護含むその他休暇 */
      get takenOtherDays() {
        return new HourBasedDays(0)
          .add(self.takenSpDays)
          .add(self.takenMenstruationDays)
          .add(self.takenNursingcareDays)
          .add(self.takenFamilycareDays)
          .add(self.takenJudgmentDays)
          .add(self.takenInjuriesDays);
      },
      /** 介護・看護含まないその他休暇 */
      get takenOtherDaysExcludingNuFa() {
        return new HourBasedDays(0)
          .add(self.takenSpDays)
          .add(self.takenMenstruationDays)
          .add(self.takenJudgmentDays)
          .add(self.takenInjuriesDays);
      },
      /** 介護・看護含むその他休暇(時間) */
      get takenOtherHours() {
        return new HourBasedDays(0).add(self.takenHourlyNursingcare).add(self.takenHourlyFamilycare);
      },
    };
  });

export const apiValueToVacationTotalValue = (vacations: UserVacationsEntity) => {
  const takenDays = (ent: VacationDaysDetailEntity) => HourBasedDays.apiValueOf(ent.usedDays)!;
  const restDays = (ent: VacationDaysDetailEntity) => HourBasedDays.apiValueOf(ent.remainingDays)!;

  const result: SnapshotIn<typeof VacationTotalValues> = {
    takenRefreshDays: takenDays(vacations.refreshVacation),
    takenPositiveDays: takenDays(vacations.positiveVacation),
    takenPaidDays: takenDays(vacations.paidVacation),
    takenSummerDays: takenDays(vacations.summerVacation),
    takenSpDays: takenDays(vacations.specialVacation),

    takenHourlyLeave: takenDays(vacations.hourlyLeave),
    takenHourlyNursingcare: takenDays(vacations.hourlyNursingcareVacation),
    takenHourlyFamilycare: takenDays(vacations.hourlyFamilycareVacation),

    takenMenstruationDays: takenDays(vacations.menstruationVacation),
    takenNursingcareDays: takenDays(vacations.nursingcareVacation),
    takenFamilycareDays: takenDays(vacations.familycareVacation),
    takenJudgmentDays: takenDays(vacations.judgmentVacation),
    takenInjuriesDays: takenDays(vacations.injuriesVacation),

    restRefreshDays: restDays(vacations.refreshVacation),
    restPositiveDays: restDays(vacations.positiveVacation),
    restPaidDays: restDays(vacations.paidVacation),
    restSummerDays: restDays(vacations.summerVacation),
  };
  return result;
};

export type VacationTotalValueType = typeof VacationTotalValues.Type;
