import { DailyApplicationApprovalEntity, DailyApplicationRequestEntity, DailyApplicationResult } from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class KintaiAppApi {
  public abstract getDailyAppStatus(userId: string, date: Date): Promise<DailyApplicationResult>;
  public abstract requestApp(userId: string, date: Date, payload: DailyApplicationRequestEntity): Promise<unknown>;
  public abstract cancelApp(userId: string, date: Date): Promise<unknown>;
  public abstract approveApp(userId: string, date: Date, payload: DailyApplicationApprovalEntity): Promise<unknown>;
  public abstract rejectApp(userId: string, date: Date, payload: DailyApplicationApprovalEntity): Promise<unknown>;
}
