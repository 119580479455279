import { IModelType, types } from "mobx-state-tree";

import { typesMinutes } from "../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.model("SaburokuEntryDetails", {
  lawOver: typesMinutes,
  lawOverCalc: typesMinutes,
  sundayOnCount: types.number,
});

export type SaburokuEntryDetailsType = typeof SaburokuEntryDetails.Type;
export const SaburokuEntryDetails: SaburokuEntryDetailsModelType = model;
type SaburokuEntryDetailsInferredType = typeof model;
export interface SaburokuEntryDetailsModelType extends SaburokuEntryDetailsInferredType {}
