import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ReadingsPane } from "../../common/ReadingsPane";
import { SettingsPageRibbon, SettingsPageRibbonVM } from "./ribbon/SettingsPageRibbon";

export interface SettingsPageVM {
  userId: string;
  userName: string;
  mailAddress: string;
  rankName: string;
  depCode: string;
  depName: string;
  roleNames: string[];
  ribbonModel: SettingsPageRibbonVM;
}

@observer
export class SettingsPage extends React.Component<{ model: SettingsPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon
        shrinkRibbon={<SettingsPageRibbon model={model.ribbonModel} small={true} />}
        hideExpandButton={true}
        ribbon={<SettingsPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("ユーザ情報")}</title>
        </Helmet>

        <ReadingsPane>
          <h3>下記のユーザでログイン中です</h3>

          <dl>
            <dt>社員番号</dt>
            <dd>{model.userId}</dd>

            <dt>氏名</dt>
            <dd>{model.userName}</dd>

            <dt>メールアドレス</dt>
            <dd>{model.mailAddress}</dd>

            <dt>部門</dt>
            <dd>
              {model.depName} ({model.depCode})
            </dd>

            <dt>ランク</dt>
            <dd>{model.rankName}</dd>

            <dt>Web勤怠内での権限</dt>
            <dd>{model.roleNames.join(", ")}</dd>
          </dl>

          <p>（※記載された情報が間違っている場合は、総務人事部に連絡下さい。）</p>
        </ReadingsPane>
      </PageRibbon>
    );
  }
}
const id = (idstring: string) => `SettingsPageElement_${idstring}`;
