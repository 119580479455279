import { DefaultResetPasswordSuggestionResult, UsersResult } from "@webkintai/api";
import { encode } from "iconv-lite";
import { flatten, sortBy } from "lodash-es";
import { applySnapshot, detach, flow, getEnv, IModelType, SnapshotIn, types } from "mobx-state-tree";

import { AccountsAdditionStatus } from "../../components/admin/accounts/common/AccountsAdditionStatus";
import { UsersApi } from "../../services/api/UsersApi";
import { AccountsExcel, AccountsExcelImportResult } from "../../services/excel/accounts/AccountsExcel";
import { FileSaver } from "../../services/file/FileSaver";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { fromApiDate, toApiLocalDate, undefinedIfNull } from "../../utils/api";
import { getNendo } from "../../utils/calendar";
import { xlsxMimeType } from "../../utils/mimetypes";
import { generatePasswordString } from "../../utils/password";
import { filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDepts } from "../depts/NendoDepts";
import { NendoRanks } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { RolesSymbol, RolesType } from "../roles/Roles";
import { InputAccount, InputAccountInstance } from "./input/InputAccount";
import { InputAccountValueModelType } from "./input/InputAccountValue";
import { apiAbsenceTypeToLeaveKind, leaveKindToApiAbsenceType } from "./LeaveKind";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("AccountsPageModel", {
      loadingStatus: types.optional(LoadingStatus, "init"),

      newUserIdText: types.optional(types.string, ""),

      filterText: types.optional(types.string, ""),
      filteredRole: types.array(types.string),
      excludeOldRetireUsers: types.optional(types.boolean, true),
      excludeHasNoChange: types.optional(types.boolean, false),
      depts: types.maybe(types.reference(NendoDepts)),
      ranks: types.maybe(types.reference(NendoRanks)),
      inputAccounts: types.array(InputAccount),

      inSaving: types.optional(types.boolean, false),

      activePasswordChangeAccount: types.safeReference(InputAccount),
      activeAbsenceAccount: types.safeReference(InputAccount),

      defaultPassword: types.maybe(types.string),
    })
    .views(self => {
      return {
        get searchWords() {
          return filterTextToSearchWords(self.filterText);
        },
        get fixedPasswordIsUsed() {
          return !!(env.use_suggested_reset_password ? self.defaultPassword : undefined);
        },
        get alreadyExsiting(): { [key: string]: InputAccountInstance } {
          return self.inputAccounts
            .map(it => [it.userId, it] as const)
            .reduce((l, r) => Object.assign(l, { [r[0]]: r[1] }), {});
        },
      };
    })
    .views(self => ({
      get hasNoChange() {
        return Array.from(self.inputAccounts, acc => acc.hasNoChange).reduce((l, r) => l && r, true);
      },
      get errors() {
        return flatten(self.inputAccounts.map(it => it.errors));
      },
      get nendo() {
        return getNendo(new Date());
      },
      isRoleFiltered(roleName: string) {
        return !!self.filteredRole.find(it => it === roleName);
      },
      get filteredRows() {
        const roleCodes = [...self.filteredRole.values()];
        return sortBy(
          self.inputAccounts
            .filter(r => !self.excludeOldRetireUsers || !r.isOldEnoughExcludeByRetirement)
            .filter(r => !self.excludeHasNoChange || !r.hasNoChange)
            .filter(r => r.filterMatched(self.searchWords, roleCodes)),
          [acc => (acc.persisted ? 1 : 0), acc => acc.userId],
        );
      },
      get newUserIdTextDisabled() {
        return !self.newUserIdText.match(/^[0-9]{4,}$/);
      },
      get resetDisabled() {
        return self.inSaving;
      },
      get saveDisabled() {
        return this.hasNoChange || this.errors.length > 0 || self.inSaving;
      },
      additionStatusOfUserId(userId: string): AccountsAdditionStatus {
        return self.alreadyExsiting[userId] ? "追加済" : "未追加";
      },
    }))
    .actions(self => {
      const appNotifier = () => getDI(self, AppNotifier);
      const ranks = () => getEnv(self).get(RanksSymbol) as RanksType;
      const depts = () => getEnv(self).get(DeptsSymbol) as DeptsType;
      const usersApi = () => getDI(self, UsersApi);
      const fileSaver = () => getDI(self, FileSaver);

      const generatePassword = () => {
        return (self.fixedPasswordIsUsed ? self.defaultPassword : undefined) || generatePasswordString();
      };

      const save = flow(function*() {
        const targets = self.inputAccounts.filter(acc => !acc.hasNoChange);
        const targetsToBeAdded = targets.filter(it => !it.persisted);
        const targetsToBeUpdated = targets.filter(it => it.persisted);
        const targetsToBeAbsenceSaved = targets.filter(it => !it.absenceHasNoChange);

        let progress = "";
        interface UserInitPassword {
          userId: string;
          userName: string;
          initPassword: string;
        }
        const passwordList: UserInitPassword[] = [];
        try {
          self.inSaving = true;
          const createUserEntity = (ac: InputAccountInstance) => {
            return {
              userId: ac.userId,

              userName: ac.value.userName,
              depCode: ac.value.depCode,
              rankCode: ac.value.rankCode,
              mailAddress: ac.value.mailAddress,
              leaveDate: toApiLocalDate(ac.value.leaveDate),
              roles: ac.value.roles as any,
            };
          };

          for (const userToBeAdded of targetsToBeAdded) {
            progress = `ユーザの新規追加(ユーザID: ${userToBeAdded.userId}, ユーザ名: ${userToBeAdded.value.userName})`;
            yield usersApi().addUser(createUserEntity(userToBeAdded));
            userToBeAdded.setPersisted(true);

            progress = `新規追加したユーザのパスワード初期化(ユーザID: ${userToBeAdded.userId}, ユーザ名: ${userToBeAdded.value.userName})`;
            const passwordEntry: UserInitPassword = {
              userId: userToBeAdded.userId,
              userName: userToBeAdded.value.userName,
              initPassword: generatePassword(),
            };
            yield usersApi().setInitPassword(passwordEntry.userId, passwordEntry.initPassword);
            passwordList.push(passwordEntry);
          }

          for (const absUser of targetsToBeAbsenceSaved) {
            progress = `ユーザの休業登録(ユーザID: ${absUser.userId}, ユーザ名: ${absUser.value.userName})`;

            yield usersApi().updateUserAbsences(
              absUser.userId,
              absUser.value.absences.map(it => ({
                type: leaveKindToApiAbsenceType(it.type!),
                startDate: toApiLocalDate(it.from)!,
                endDate: toApiLocalDate(it.to)!,
                remarks: it.remarks || undefined,
              })),
            );
          }

          progress = "ユーザの更新";
          yield usersApi().updateUsers(targetsToBeUpdated.map(createUserEntity));
          appNotifier().info({ message: "保存しました" });
          yield triggerLoad(true);
        } catch (exception) {
          appNotifier().error({ message: `エラーが発生しました。箇所: ${progress}`, exception });
        } finally {
          self.inSaving = false;
          if (passwordList.length > 0) {
            fileSaver().saveFile(
              "ユーザ初期パスワード一覧.csv",
              encode(
                "ユーザID,ユーザ名,パスワード\r\n" +
                  passwordList.map(it => `"${it.userId}","${it.userName}",${it.initPassword}`).join("\r\n"),
                "Shift_JIS",
              ),
            );
          }
        }
      });

      const triggerLoad = flow(function*(force = false) {
        if (!force && self.loadingStatus === "loaded") {
          return;
        }

        try {
          self.loadingStatus = "loading";

          yield loadList();

          self.ranks = yield ranks().prepareAndLoadMostRecent(self.nendo);
          self.depts = yield depts().prepareAndLoadMostRecent(self.nendo);

          const defaultResetPasswordResult: DefaultResetPasswordSuggestionResult = yield usersApi().getDefaultResetPassword();
          self.defaultPassword = defaultResetPasswordResult.defaultResetPassword;

          self.loadingStatus = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "データのロードに失敗しました。", exception });
          self.loadingStatus = "failed";
        }
      });

      const loadList = flow(function*() {
        detach(self.inputAccounts);
        const result: UsersResult = yield usersApi().getUsers();
        applySnapshot(
          self.inputAccounts,
          result.users.map(u => {
            const value: SnapshotIn<InputAccountValueModelType> = {
              userName: u.userName || "",
              depCode: undefinedIfNull(u.depCode),
              rankCode: undefinedIfNull(u.rankCode),
              mailAddress: u.mailAddress || "",
              leaveDate: fromApiDate(u.leaveDate),
              roles: u.roles.map(it => it.value),
              absences: u.absences.map(it => ({
                type: apiAbsenceTypeToLeaveKind(it.type),
                from: fromApiDate(it.startDate),
                to: fromApiDate(it.endDate),
                remarks: it.remarks || "",
              })),
            };

            return {
              userId: u.userId,

              orgValue: value,
              value,

              persisted: true,
            };
          }),
        );
      });

      return {
        setActivePasswordChangeAccount(account: InputAccountInstance | undefined) {
          self.activePasswordChangeAccount = account;
          if (account) {
            if (!account.suggedstedPassword) {
              account.regenerateSuggestedPassword();
            }
          }
        },
        setActiveAbsenceAccount(account: InputAccountInstance | undefined) {
          self.activeAbsenceAccount = account;
        },
        setNewUserIdText(value: string) {
          self.newUserIdText = value;
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
        toggleRoleFilter(roleCode: string) {
          if (self.isRoleFiltered(roleCode)) {
            self.filteredRole.remove(roleCode);
          } else {
            self.filteredRole.push(roleCode);
          }
        },
        setExcludeOldRetireUsers(value: boolean) {
          self.excludeOldRetireUsers = value;
        },
        setExcludeHasNoChange(value: boolean) {
          self.excludeHasNoChange = value;
        },
        generatePassword,
        save,
        reset() {
          triggerLoad(true);
        },
        reload() {
          triggerLoad(true);
        },
        route(pathFragment: string) {
          triggerLoad();
        },
        addUser() {
          const userId = self.newUserIdText;
          this.addUserWithId(userId);
        },
        addUserWithId(userId: string): InputAccountInstance | undefined {
          if (self.alreadyExsiting[userId]) {
            appNotifier().error({ message: `既に登録されている社員番号(${userId})です` });
            return undefined;
          }

          const created = InputAccount.create({
            userId,
            persisted: false,
          });
          self.inputAccounts.push(created);
          return created;
        },
        removeCandidateUser(userId: string) {
          const found = self.alreadyExsiting[userId];
          if (found) {
            detach(found);
          }
        },
      };
    })
    .actions(self => {
      const appNotifier = () => getDI(self, AppNotifier);
      const excel = () => getDI(self, AccountsExcel);
      const roles = () => getEnv(self).get(RolesSymbol) as RolesType;
      const fileSaver = () => getDI(self, FileSaver);

      const importExcel = flow(function*(f: File): any {
        const importResult: AccountsExcelImportResult = yield excel().importExcel(f);

        if (importResult.status === "failure") {
          appNotifier().error({ message: `読み込みに失敗しました - ${importResult.failureReason}` });
          return;
        }

        importResult.rows.forEach(row => {
          const user = self.alreadyExsiting[row.userId] || self.addUserWithId(row.userId);
          if (!user) {
            return;
          }
          user.value.setUserName(row.userName);
          user.value.setDepCode(row.depCode);
          user.value.setRankCode(row.rankCode);
          user.value.setMailAddr(row.mailAddress);
        });
      });

      const exportExcel: () => {} = flow(function*(): any {
        const buffer: Buffer = yield excel().exportExcel({
          entries: self.filteredRows.map(it => ({
            userId: it.userId,
            userName: it.value.userName,
            depCode: it.value.depCode,
            depName: it.depName,
            rankCode: it.value.rankCode,
            rankName: it.rankName,
            mailAddress: it.value.mailAddress,
            absences: it.value.absences.map(absrow => ({
              type: absrow.type,
              from: absrow.from,
              to: absrow.to,
              remarks: absrow.remarks,
            })),
            leaveDate: it.value.leaveDate,
            roles: [...it.value.roles],
          })),
          roleCodes: [...roles().roles.keys()],
        });
        fileSaver().saveFile(`Web勤怠v2_アカウント情報一覧.xlsx`, buffer, xlsxMimeType);
      });

      return {
        importExcel,
        exportExcel,
      };
    }),
  {},
);

export type AccountPageModelType = typeof AccountsPageModel.Type;

export const AccountPageModelSymbol = "AccountPageModel_Symbol";
export const AccountsPageModel: AccountsPageModelModelType = model;
type AccountsPageModelInferredType = typeof model;
export interface AccountsPageModelModelType extends AccountsPageModelInferredType {}
