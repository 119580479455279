import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";
import { RegularPrintedKintaiVM } from "./RegularPrintedKintai";

@observer
export class RegularPrintedPJHeader extends React.Component<{ model: RegularPrintedKintaiVM }> {
  public render() {
    const { model } = this.props;
    const { regularRows, printDetailMode } = model;
    const date = regularRows[0].date;

    if (printDetailMode === "brief") {
      return <></>;
    }

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>年月</th>
              <th>指示時間</th>
              <th>所属</th>
              <th>氏名</th>
              <th>ランク</th>
              <th>社員番号</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{moment(date).format("YYYY年MM月")}</td>
              <td>
                <HHMMField mode="print" value={model.designatedMinutes} />
              </td>
              <td>{model.depName}</td>
              <td>{model.name}</td>
              <td>{model.rankName}</td>
              <td>{model.userId}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
