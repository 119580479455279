import { Minutes } from "@webkintai/core";
import { types } from "mobx-state-tree";

import { typesMinutes } from "../../primitives/Minutes";

const model = types
  .model("PJReportDailyCell", {
    time: types.maybe(typesMinutes),
  })
  .actions(self => {
    return {
      setHour(value: Minutes | undefined) {
        self.time = value;
      },
    };
  })
  .views(self => {
    return {};
  });

export const reduceMinutes = (values: Array<Minutes | undefined>) => {
  return values.reduce((l, r) => {
    if (!r) {
      return l;
    }
    if (!l) {
      return r;
    }
    return l.add(r);
  }, undefined as Minutes | undefined);
};
export const PJReportDailyCell: PJReportDailyCellModelType = model;
type PJReportDailyCellInferredType = typeof model;
export interface PJReportDailyCellModelType extends PJReportDailyCellInferredType {}
