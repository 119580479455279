import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverSixtyLiquidateKFProps = {
  model: MTotalOverSixtyLiquidateKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverSixtyLiquidateKFVM {
  mTotalOverSixtyLiquidate: KintaiInputItemState<Minutes>;
}

export const MTotalOverSixtyLiquidateKF = observer((props: MTotalOverSixtyLiquidateKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverSixtyLiquidate;

  return <HHMMField {...props} label="mTotalOverSixtyLiquidateKF" {...itemState} onChange={undefined} />;
});
