import { Container } from "inversify";

import { CronTasks } from "./CronTasks";
import { CronTasksImpl } from "./CronTasksImpl";

export function register(container: Container) {
  container
    .bind(CronTasks)
    .to(CronTasksImpl)
    .inSingletonScope();
}
