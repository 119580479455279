import { observer } from "mobx-react-lite";
import React from "react";

import { usePublicDDModel } from "../../../../../../models/statistics/usePublicDDModel";
import { PubBarChartWidget } from "../../chart/PubBarChartWidget";

export const PubAvgLawoverDescWidgetContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePublicDDModel();
  const data = model.avg_lawover_desc;
  return (
    <PubBarChartWidget
      mood="bad"
      title="[年度内] 健康管理時間 (平均) - 上位"
      data={data}
      lastUpdate={model.contentLastUpdate}
    />
  );
});
