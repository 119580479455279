import { types } from "mobx-state-tree";

export const RankPosition = types.enumeration("RankPosition", ["0", "1"]);

export type RankPositionType = typeof RankPosition.Type;

export function rankPositionName(rankPosition?: RankPositionType) {
  switch (rankPosition) {
    case "1":
      return "管理職";
    default:
      return "一般職";
  }
}

export function isManagerRankPosition(rankPosition: RankPositionType) {
  return rankPosition === "1";
}
