import { observer } from "mobx-react";
import React from "react";

import { CheckboxField } from "../../../common/form/CheckboxField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { PjClosePageGridMonthVM } from "./PjClosePageGrid";

@observer
export class PjCloseCell extends React.Component<{ model: PjClosePageGridMonthVM }> {
  public render() {
    const { model } = this.props;
    return (
      <CheckboxField
        disabled={model.disabled}
        mode="cell"
        label=""
        value={model.closed}
        onChange={model.onChangeClosed}
        hasNoChange={model.hasNoChange}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
