import { IModelType, types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../utils/model";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("SPortNendoGivenTimeMonth", {
    month: types.Date,
    givenDays: types.maybe(types.number),
    givenHours: types.maybe(types.number),
    org_givenDays: types.maybe(types.number),
    org_givenHours: types.maybe(types.number),
  })
  .views(self => {
    return {
      get hasNoChange() {
        return hasNoChangeReduce([self.givenDays === self.org_givenDays, self.givenHours === self.org_givenHours]);
      },
    };
  })
  .actions(self => {
    return {
      setGivenDays(value?: number) {
        self.givenDays = value;
      },
      setGivenHours(value?: number) {
        self.givenHours = value;
      },
      reset() {
        self.givenDays = self.org_givenDays;
        self.givenHours = self.org_givenHours;
      },
    };
  });
export const SPortNendoGivenTimeMonth: SPortNendoGivenTimeMonthModelType = model;
type SPortNendoGivenTimeMonthInferredType = typeof model;
export interface SPortNendoGivenTimeMonthModelType extends SPortNendoGivenTimeMonthInferredType {}
