import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { ErrorsPopoverFrame } from "../../../../errors/ErrorsPopoverFrame";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { ErrorOverlay } from "../../../../form/ErrorOverlay";
import { ErrorCell, ErrorCountColor } from "./ErrorComponents";

export type MonthlyWarningsKFProps = {
  model: MonthlyWarningsKFVM;
} & CellFormAdaptable;

export interface MonthlyWarningsKFVM {
  warnings: string[];
}

export const MonthlyWarningsKF = observer((props: MonthlyWarningsKFProps) => {
  const { model } = props;

  if (props.mode === "cell") {
    return (
      <ErrorOverlay mode="cell" warnings={model.warnings} errors={[]}>
        <ErrorCell>
          <ErrorCountColor length={model.warnings.length}>{model.warnings.length}</ErrorCountColor>
        </ErrorCell>
      </ErrorOverlay>
    );
  }

  return (
    <Tooltip
      disabled={model.warnings.length === 0}
      boundary="viewport"
      content={
        <ErrorsPopoverFrame>
          {model.warnings.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </ErrorsPopoverFrame>
      }>
      <span>
        警告: <ErrorCountColor length={model.warnings.length}>{model.warnings.length}件</ErrorCountColor>
      </span>
    </Tooltip>
  );
});
