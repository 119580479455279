import { observer } from "mobx-react";
import React, { useCallback } from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { EnterLeaveTimeField } from "../common/EnterLeaveTimeField";

export type CkrpEnterKFProps = {
  model: CkrpEnterKFVM;
} & CellFormPrintAdaptable;

export interface CkrpEnterKFVM {
  date: Date;
  ckrpEnterTime: Date | undefined;
  onApplyCkrpEnter: () => void;
}

export const CkrpEnterKF = observer((props: CkrpEnterKFProps) => {
  const { model } = props;
  const { date, ckrpEnterTime, onApplyCkrpEnter } = model;

  const onClick = useCallback(() => {
    if (onApplyCkrpEnter && ckrpEnterTime) {
      onApplyCkrpEnter();
    }
  }, [onApplyCkrpEnter, ckrpEnterTime]);

  return (
    <EnterLeaveTimeField
      {...props}
      {...{ date, onClick }}
      enterLeaveTime={ckrpEnterTime}
      label="ここレポ出勤"
      disabled={!ckrpEnterTime}
    />
  );
});
