import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type SpHolidayKFProps = {
  model: SpHolidayKFVM;
} & CellFormPrintAdaptable;

export interface SpHolidayKFVM {
  spHoliday: KintaiInputItemState<Minutes | undefined>;
}

export const SpHolidayKF = observer((props: SpHolidayKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.spHoliday);

  return <HHMMField {...props} label="特勤 休日時間" {...itemState} />;
});
