import {
  MePhysicianInterviewsResult,
  PhysicianInterviewEditEntity,
  PhysicianInterviewReserveRequest,
  PhysicianInterviewsResult,
} from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class PhysicianInterviewApi {
  public abstract getPhysicianInterviews(nendo: number): Promise<PhysicianInterviewsResult>;
  public abstract getPhysicianInterviewsForMe(nendo: number): Promise<MePhysicianInterviewsResult>;

  public abstract updatePhysicianInterviewStatus(
    userId: string,
    yearMonth: Date,
    payload: PhysicianInterviewEditEntity,
  ): Promise<unknown>;

  public abstract createPhysicianInterviewStatus(
    userId: string,
    yearMonth: Date,
    payload: PhysicianInterviewEditEntity,
  ): Promise<unknown>;

  public abstract reservePhysicianInterviewsForMe(
    month: Date,
    payload: PhysicianInterviewReserveRequest,
  ): Promise<unknown>;
}
