import { Button } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/admin/ranks.md";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../../models/common/LoadingStatusType";
import { TextField } from "../../../common/form/TextField";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { SaveRibbon } from "../../../common/ribbon/SaveRibbon";

export interface RankPageRibbonVM {
  loadingStatus: LoadingStatusType;
  nendo: Date;
  nendoList: number[];
  onChangeNendo: (date: Date) => void;
  allowedToEdit: boolean;
  onChangeNewRankCodeText: (value: string) => void;
  newRankCodeText: string;
  onAddNewRank: () => void;
  newRankCodeTextDisabled: boolean;

  onInitializeWithPrevNendo: () => void;
  initializeWithPrevYearDisabled: boolean;
  onReload: () => void;
  reloadDisabled: boolean;
  onReset: () => void;
  onSave: () => void;
  saveDisabled: boolean;
}

@observer
export class RankPageRibbon extends React.Component<{ model: RankPageRibbonVM }> {
  public render() {
    const { model } = this.props;

    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <KintaiCalendarSelectorButton
            buttonProps={{ small: true }}
            nendoList={model.nendoList}
            unit="year"
            currentDate={model.nendo}
            onChangeDate={model.onChangeNendo}
          />
        </JumpRibbon>

        {model.allowedToEdit && (
          <>
            <PageRibbonSection title="新規ランク追加">
              <PageRibbonLine>
                <TextField
                  mode="form"
                  maxLength={2}
                  placeholder="新規ランクコード"
                  onChange={model.onChangeNewRankCodeText}
                  value={model.newRankCodeText}
                />
              </PageRibbonLine>
              <PageRibbonLine>
                <Button onClick={model.onAddNewRank} disabled={model.newRankCodeTextDisabled} small={true} icon="add">
                  新規ランク追加
                </Button>
              </PageRibbonLine>
            </PageRibbonSection>

            <PageRibbonSection title="操作">
              <Button
                small={true}
                text="前年度をコピー"
                icon="clipboard"
                disabled={model.initializeWithPrevYearDisabled}
                onClick={model.onInitializeWithPrevNendo}
              />
            </PageRibbonSection>
          </>
        )}
        <ReloadRibbon onReload={model.onReload} disabled={model.reloadDisabled} />
        {model.allowedToEdit && (
          <SaveRibbon
            onSave={model.onSave}
            onReset={model.onReset}
            disabled={model.saveDisabled}
            useTimeoutConfirmReset={true}
          />
        )}
      </>
    );
  }
}
