export var hourBasedDaysEq = function hourBasedDaysEq(l, r) {
  if (l === r) {
    return true;
  }

  if (l === undefined || r === undefined) {
    return false;
  }

  return l.hours === r.hours;
};