import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ScrollablePane } from "../../common/ScrollablePane";
import { InterviewListPageRibbon, InterviewListPageRibbonVM } from "./ribbon/InterviewListPageRibbon";
import { InterviewListPageTable, InterviewListPageTableVM } from "./table/InterviewListPageTable";

export interface InterviewListPageVM {
  ribbonModel: InterviewListPageRibbonVM;
  tableModel: InterviewListPageTableVM;
  loadingStatus: LoadingStatusType;
  onReloadOnFailure: () => void;
}

@observer
export class InterviewListPage extends React.Component<{ model: InterviewListPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<InterviewListPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("産業医面接対象者一覧")}</title>
        </Helmet>

        <ScrollablePane>
          {model.loadingStatus !== "loaded" && (
            <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
          )}
          {model.loadingStatus === "loaded" && <InterviewListPageTable model={model.tableModel} />}
        </ScrollablePane>
      </PageRibbon>
    );
  }
}
