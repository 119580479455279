import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalSpWeekdayKFProps = {
  model: MTotalSpWeekdayKFVM;
} & CellFormPrintAdaptable;

export interface MTotalSpWeekdayKFVM {
  mTotalSpWeekday: KintaiInputItemState<Minutes>;
}

export const MTotalSpWeekdayKF = observer((props: MTotalSpWeekdayKFProps) => {
  const { model } = props;
  const itemState = model.mTotalSpWeekday;

  return <HHMMField {...props} label="mTotalSpWeekdayKF" {...itemState} onChange={undefined} />;
});
