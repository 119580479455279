import { Button, Intent } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";

export type AppCancelKFProps = {
  model: AppCancelKFVM;
} & CellFormPrintAdaptable;

export interface AppCancelKFVM {
  onAppCancel: () => void;
  appCancelIsDisabled: boolean;
}

export const AppCancelKF = observer((props: AppCancelKFProps) => {
  const { model } = props;

  return (
    <Button
      disabled={model.appCancelIsDisabled}
      onClick={model.onAppCancel}
      text="申請をキャンセルする"
      intent={Intent.DANGER}
      icon="trash"
    />
  );
});
