import { inject } from "inversify";

import { AppState, AppStateSymbol } from "../models/AppState";
import { Login, LoginSymbol } from "../models/login/Login";
import { ApiClass, ApiFactory } from "./ApiFactory";

export class ApiFactoryImpl extends ApiFactory {
  constructor(
    @inject(AppStateSymbol) private readonly appState: typeof AppState.Type,
    @inject(LoginSymbol) private readonly login: typeof Login.Type,
  ) {
    super();
  }

  public generateLoginFreeApi<T>(clazz: ApiClass<T>): T {
    return new clazz(undefined, env.api_server);
  }
  public generateLoginAssumingApi<T>(clazz: ApiClass<T>): T {
    return new clazz(
      {
        apiKey: `Bearer ${this.login.loginToken}`,
      },
      env.api_server,
    );
  }

  public async guardFailures<T>(requestingClause: () => Promise<T>): Promise<T> {
    try {
      const result: T = await requestingClause();
      return result;
    } catch (error) {
      if (!error.json) {
        // APIサーバ接続エラー
        await this.appState.recoverFromApiServerFailureRecovery(error);
        // リトライ
        return this.guardFailures(requestingClause);
      }
      const errorContent = await error.json();
      if (
        // タイムアウト
        errorContent.status === "jwt_expired" ||
        // JWT署名エラー
        (errorContent.message || "").indexOf("io.jsonwebtoken.SignatureException") >= 0
      ) {
        // タイムアウトの場合は再度ログインを求める
        await this.login.recoverFromLoginExpired();
        // リトライ
        return this.guardFailures(requestingClause);
      } else {
        throw errorContent;
      }
    }
  }
}
