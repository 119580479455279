import { hourBasedDaysEq, minutesEq } from "@webkintai/core";
import { applySnapshot, getSnapshot, types } from "mobx-state-tree";

import { dateEq } from "../../../utils/date";
import { hasNoChangeReduce } from "../../../utils/model";
import { prefixedIdType } from "../../utils";
import { SPortDailyValues } from "./SPortDailyValues";
import { sportItemState } from "./sportItemState";
import { sportTypeItemState } from "./sportTypeItemState";

const idPrefix = "SDK_";

export const alwaysDisabledItems = {
  midnight: true,
  immunity: true,
  offCount: true,
  growingTime: true,
  growingOver: true,
};

const model = types
  .model("SPortDailyKintai", {
    id: prefixedIdType(idPrefix),
    date: types.Date,

    origin: SPortDailyValues,
    input: SPortDailyValues,
    computed: SPortDailyValues,

    type1: sportTypeItemState(1),
    type2: sportTypeItemState(2),
    type3: sportTypeItemState(3),
    type4: sportTypeItemState(4),

    transDay: sportItemState("transDay", "setTransDay", dateEq),

    hourlyLeave: sportItemState("hourlyLeave", "setHourlyLeave", hourBasedDaysEq),
    hourlyNursingcare: sportItemState("hourlyNursingcare", "setHourlyNursingcare", hourBasedDaysEq),
    hourlyFamilycare: sportItemState("hourlyFamilycare", "setHourlyFamilycare", hourBasedDaysEq),

    arrival: sportItemState("arrivalAsMinutes", "setArrivalFromMinutes", minutesEq, "arrival"),
    leave: sportItemState("leaveAsMinutes", "setLeaveFromMinutes", minutesEq, "leave"),
    diff: sportItemState("diff", "setDiff", minutesEq),
    rest: sportItemState("rest", "setRest", minutesEq),
    actual: sportItemState("actual", "setActual", minutesEq),
    midnight: sportItemState("midnight", "setMidnight", minutesEq),
    immunity: sportItemState("immunity", "setImmunity", minutesEq),
    growingTime: sportItemState("growingTime", "setGrowingTime", minutesEq),
    missed: sportItemState("missed", "setMissed", minutesEq),
    overDiff: sportItemState("overDiff", "setOverDiff", minutesEq),
    overRestNormal: sportItemState("overRestNormal", "setOverRestNormal", minutesEq),
    overRestMidnight: sportItemState("overRestMidnight", "setOverRestMidnight", minutesEq),
    overWeekday: sportItemState("overWeekday", "setOverWeekday", minutesEq),
    overWeekdayMidnight: sportItemState("overWeekdayMidnight", "setOverWeekdayMidnight", minutesEq),
    overHoliday: sportItemState("overHoliday", "setOverHoliday", minutesEq),
    overHolidayMidnight: sportItemState("overHolidayMidnight", "setOverHolidayMidnight", minutesEq),
    growingOver: sportItemState("growingOver", "growingOverSum", minutesEq),
    offCount: sportItemState("offCount", "setOffCount", (l: number | undefined, r: number | undefined) => l === r),
    remarks: sportItemState("remarks", "setRemarks", (l: string, r: string) => l === r),
  })
  .views(self => {
    return {
      get items() {
        return [
          self.type1,
          self.type2,
          self.type3,
          self.transDay,
          self.hourlyLeave,
          self.hourlyNursingcare,
          self.hourlyFamilycare,
          self.arrival,
          self.leave,
          self.diff,
          self.rest,
          self.actual,
          self.midnight,
          self.immunity,
          self.growingTime,
          self.missed,
          self.overDiff,
          self.overRestNormal,
          self.overRestMidnight,
          self.overWeekday,
          self.overWeekdayMidnight,
          self.overHoliday,
          self.overHolidayMidnight,
          self.growingOver,
          self.offCount,
          self.remarks,
        ];
      },
      get typesHasEssentiallyNoChange() {
        return hasNoChangeReduce([
          self.type2.hasEssentiallyNoChange,
          self.type3.hasEssentiallyNoChange,
          self.type4.hasEssentiallyNoChange,
        ]);
      },
      get errors() {
        return self.computed.errors.filter(it => it.isError).map(it => it.message);
      },
      get warnings() {
        return self.computed.errors.filter(it => it.isWarning).map(it => it.message);
      },
      get infos() {
        return self.computed.errors.filter(it => it.isInfo).map(it => it.message);
      },
      get hasNoChange() {
        return this.items.map(it => it.hasNoChange).reduce((l, r) => l && r, true);
      },
    };
  })
  .actions(self => {
    return {
      applyErrors() {
        self.type1.applyErrors();
        self.type2.applyErrors();
        self.type3.applyErrors();
        self.transDay.applyErrors();
        self.hourlyLeave.applyErrors();
        self.hourlyFamilycare.applyErrors();
        self.hourlyNursingcare.applyErrors();
        self.arrival.applyErrors();
        self.leave.applyErrors();
        self.diff.applyErrors();
        self.rest.applyErrors();
        self.actual.applyErrors();
        self.midnight.applyErrors();
        self.immunity.applyErrors();
        self.growingTime.applyErrors();
        self.missed.applyErrors();
        self.overDiff.applyErrors();
        self.overRestNormal.applyErrors();
        self.overRestMidnight.applyErrors();
        self.overWeekday.applyErrors();
        self.overWeekdayMidnight.applyErrors();
        self.overHoliday.applyErrors();
        self.overHolidayMidnight.applyErrors();
        self.growingOver.applyErrors();
        self.offCount.applyErrors();
        self.remarks.applyErrors();
      },
      reset() {
        const value = getSnapshot(self.origin);
        applySnapshot(self.input, value);
        applySnapshot(self.computed, value);
        this.applyErrors();
      },
      setFixedTimes() {
        self.input.setFixedTimes();
      },
    };
  });

export const idSPortDailyKintai = (userId: string, date: Date) =>
  `${idPrefix}${userId}_${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`;
export const SPortDailyKintai: SPortDailyKintaiModelType = model;
type SPortDailyKintaiInferredType = typeof model;
export interface SPortDailyKintaiModelType extends SPortDailyKintaiInferredType {}
