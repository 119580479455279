import { saveAs } from "file-saver";

import { FileSaver } from "./FileSaver";

export class FileSaverImpl extends FileSaver {
  constructor() {
    super();
  }

  public saveFile(fileName: string, content: Buffer, contentType?: string) {
    saveAs(new Blob([content], contentType ? { type: contentType } : {}), fileName, true);
  }
}
