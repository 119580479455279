import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalHourlyNursingcareKFProps = {
  model: MTotalHourlyNursingcareKFVM;
} & CellFormPrintAdaptable;

export interface MTotalHourlyNursingcareKFVM {
  mTotalHourlyNursingcare: KintaiInputItemState<HourBasedDays>;
}

export const MTotalHourlyNursingcareKF = observer((props: MTotalHourlyNursingcareKFProps) => {
  const { model } = props;
  const itemState = model.mTotalHourlyNursingcare;

  return (
    <HourBasedDaysCountField
      showAsDateCount={false}
      {...props}
      label="mTotalHourlyNursingcareKF"
      {...itemState}
      onChange={undefined}
    />
  );
});
