import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type RemarksKFProps = {
  model: RemarksKFVM;
} & CellFormPrintAdaptable;

export interface RemarksKFVM {
  remarks: KintaiInputItemState<string>;
}

const coronaText = "在宅：新型コロナ";

export const RemarksKF = observer((props: RemarksKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.remarks);
  const corona = React.useCallback(() => {
    if (model.remarks.value.indexOf(coronaText) < 0) {
      model.remarks.onChange(`${coronaText}\n${model.remarks.value}`);
    }
  }, [model.remarks, model.remarks.value, model.remarks.onChange]);

  const textField = <TextField {...props} multiline={true} label="備考" {...itemState} />;

  if (props.mode === "print") {
    return textField;
  }

  if (props.mode === "cell") {
    return (
      <CellContainer>
        <CellTextFieldContainer>
          <TextField {...props} multiline={true} label="備考" {...itemState} />
        </CellTextFieldContainer>
      </CellContainer>
    );
  }

  return (
    <>
      <TextField {...props} multiline={true} label="備考" {...itemState} />
    </>
  );
});

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CellTextFieldContainer = styled.div`
  flex: 1;
`;

const CellButton = styled.button`
  flex: none;
  width: 3em;
  padding: 0;
`;
