import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

import { commandOrCtrlPushed } from "../../../utils/react";
import { FocusMarkerClassName } from "../table/GridTable";
import { CellFormPrintAdaptable, getCellMove } from "./CellFormAdaptable";
import { ErrorOverlay, ErrorOverlayProps } from "./ErrorOverlay";
import { cellFormStyle, cellStyle, componentCommonClasses, hasNoChangeStyle } from "./FormCommon";
import { FormLabel } from "./FormLabel";

export const SelectField: React.FC<SelectFieldProps> = props => {
  const { options, value } = props;
  const finalOptions = [...(props.appendUndefinedItem ? [{ label: "", value: undefined }] : []), ...options];
  const option = options.find(op => op.value === value);
  if (props.mode === "print") {
    return <>{option ? option.label : ""}</>;
  }

  const ref = React.useRef<HTMLSelectElement>(null);
  const [focused, setFocused] = React.useState((props.mode === "cell" && props.isActive) || false);
  const [expanded, setExpanded] = React.useState(false);
  const [onChangeCancellable, setOnChangeCancellable] = React.useState(false);

  const onBlur: React.FormEventHandler<unknown> = React.useCallback(
    ev => {
      setFocused(false);
    },
    [setFocused, props.mode === "cell" && props.onCellFocused, props.mode === "cell" && props.isActive],
  );

  const onChangeHandler = React.useCallback(
    (text: string) => {
      if (props.disabled) {
        return;
      }
      if (props.options.find(it => undefSafe(it.value) === undefSafe(text))) {
        props.onChange(decodeUndefSafe(text));
      }
    },
    [props.disabled, props.onChange],
  );

  const onChange: React.FormEventHandler<HTMLInputElement | HTMLSelectElement> = React.useCallback(
    ev => {
      if (onChangeCancellable) {
        setTimeout(() => {
          setOnChangeCancellable(false);
        });
        return;
      }
      onChangeHandler(ev.currentTarget.value);
    },
    [onChangeHandler, onChangeCancellable, setOnChangeCancellable],
  );

  if (props.mode === "cell") {
    const onFocus: React.FormEventHandler<HTMLInputElement | HTMLSelectElement> = React.useCallback(
      ev => {
        if (props.mode !== "cell") {
          return;
        }
        setFocused(true);
        setExpanded(true);
      },
      [setFocused, setExpanded, props.isActive, props.onCellFocused, props.cellPosition],
    );
    const onMouseOver: React.FormEventHandler<HTMLInputElement | HTMLSelectElement> = React.useCallback(
      ev => {
        setExpanded(true);
      },
      [setExpanded],
    );

    const useSingleOption = props.disabled || (option && !expanded);

    const onKeyDown = React.useCallback(
      (ev: React.KeyboardEvent<unknown>) => {
        if (commandOrCtrlPushed(ev) && ev.key === "c") {
          copiedData = props.value;
        }

        if (commandOrCtrlPushed(ev) && ev.key === "v") {
          onChangeHandler(copiedData || "");
        } else if (ev.keyCode === 8 || ev.keyCode === 46) {
          onChangeHandler("");
        }

        if (navigator.userAgent.toLowerCase().indexOf("firefox") >= 0) {
          const move = getCellMove(ev);
          if (move !== null) {
            if (move.x) {
              setOnChangeCancellable(true);
            }
          }
        }
      },
      [props.value, onChangeHandler, setOnChangeCancellable],
    );
    return (
      <ErrorOverlay mode="cell" errors={props.errors} warnings={props.warnings} infos={props.infos}>
        <Cell
          className={classNames(FocusMarkerClassName, componentCommonClasses(props))}
          value={undefSafe(value)}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onClick={onFocus}
          onFocus={onFocus}
          onMouseOver={onMouseOver}
          onMouseOut={onBlur}
          style={{
            cursor: props.disabled ? "not-allowed" : "pointer",
          }}
          ref={ref}>
          {(useSingleOption ? (option ? [option] : []) : finalOptions).map(opt => (
            <option key={undefSafe(opt.value)} value={undefSafe(opt.value)}>
              {opt.label}
            </option>
          ))}
        </Cell>
      </ErrorOverlay>
    );
  }

  return (
    <FormLabel
      label={props.label}
      disabled={props.disabled}
      errors={props.errors}
      warnings={props.warnings}
      infos={props.infos}>
      <FormBorder className="bp3-select">
        <Form
          className={classNames("bp3-small", componentCommonClasses(props))}
          value={value}
          onChange={onChange}
          disabled={props.disabled}>
          {finalOptions.map(opt => (
            <option key={undefSafe(opt.value)} value={undefSafe(opt.value)}>
              {opt.label}
            </option>
          ))}
        </Form>
      </FormBorder>
    </FormLabel>
  );
};

export type SelectFieldProps = {
  label?: string;
  disabled?: boolean;
  value: string | undefined;
  hasNoChange?: boolean;
  appendUndefinedItem?: boolean;
  options: SelectFieldOptionItem[];
  onChange: (value: string | undefined) => void;
} & CellFormPrintAdaptable &
  ErrorOverlayProps;

export interface SelectFieldOptionItem {
  value: string | undefined;
  label: string;
}

const Cell = styled.select`
  ${cellStyle}
  ${cellFormStyle}
  ${hasNoChangeStyle}
	-moz-appearance: none; 
	-webkit-appearance: none; 
  appearance: none;
  `;

const FormBorder = styled.div`
  &::after {
    font-size: 12px !important;
  }
`;

const Form = styled.select`
  padding: 0;
  height: 24px !important;
  padding-left: 0.5ex !important;
  font-size: 80% !important;
  ${hasNoChangeStyle};
`;

let copiedData: string | undefined;

const undefSafeValue = "___UNDEF_VALUE";
const undefSafe = (value: string | undefined) => value || undefSafeValue;
const decodeUndefSafe = (value: string | undefined) => (value === undefSafeValue ? undefined : value);
