import { isEqual, range } from "lodash-es";
import { applySnapshot, getParentOfType, getSnapshot, types } from "mobx-state-tree";

import { hasNoChangeReduce } from "../../../utils/model";
import { KintaiInputItemState } from "../kintaiinput/KintaiInput";
import { MonthlyKintai } from "../MonthlyKintai";
import { PJReportPillarHeaderValues } from "./PJReportPillarHeaderValues";

export const PJReportPillarHeader = types
  .model("PJReportPillarHeader", {
    origin: PJReportPillarHeaderValues,
    input: PJReportPillarHeaderValues,
    computed: PJReportPillarHeaderValues,
  })
  .views(self => {
    function root() {
      return getParentOfType(self, MonthlyKintai);
    }

    return {
      get cellsCount() {
        return 3;
      },
      get labels(): Array<KintaiInputItemState<string>> {
        const disabled = root().isPJReportLocked;
        return range(0, this.cellsCount).map(idx => ({
          get value() {
            return self.input.labels[idx];
          },
          onChange: (value: string) => {
            self.input.setLabel(idx, value);
          },
          errors: [],
          warnings: [],
          infos: [],
          disabled,
          get hasNoChange() {
            return self.input.labels[idx] === self.origin.labels[idx];
          },
        }));
      },
      get pjCode(): KintaiInputItemState<string> {
        const disabled = root().isPJReportLocked;
        return {
          value: self.input.pjCode,
          onChange: self.input.setPjCode,
          disabled,
          errors: [],
          warnings: [],
          infos: [],
          get hasNoChange() {
            return disabled || self.input.pjCode === self.origin.pjCode;
          },
        };
      },
      get odCode(): KintaiInputItemState<string> {
        const disabled = root().isPJReportLocked;
        return {
          value: self.input.odCode,
          onChange: self.input.setOdCode,
          disabled,
          errors: [],
          warnings: [],
          infos: [],
          get hasNoChange() {
            return disabled || self.input.odCode === self.origin.odCode;
          },
        };
      },
      get pjName() {
        return self.computed.pjName;
      },
      get errors() {
        return self.computed.errors;
      },
      get warnings() {
        return self.computed.warnings;
      },
      get infos() {
        return self.computed.infos;
      },
    };
  })
  .views(self => {
    return {
      get hasNoChange() {
        return hasNoChangeReduce([
          self.pjCode.hasNoChange,
          self.odCode.hasNoChange,
          ...self.labels.map(it => it.hasNoChange),
        ]);
      },
    };
  })
  .actions(self => {
    return {
      reset() {
        const values = getSnapshot(self.origin);
        applySnapshot(self.input, values);
        applySnapshot(self.computed, values);
      },
    };
  });
export const isSamePillarHeader = (l: typeof PJReportPillarHeader.Type, r: typeof PJReportPillarHeader.Type) => {
  const labels = (p: typeof PJReportPillarHeader.Type) => p.labels.map(it => it.value);
  return l.pjCode.value === r.pjCode.value && l.odCode.value === r.odCode.value && isEqual(labels(l), labels(r));
};
