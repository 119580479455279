import { Button } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/admin/accounts.md";
import { observer } from "mobx-react";
import React from "react";

import { LoadingStatusType } from "../../../../models/common/LoadingStatusType";
import { FilterTextbox } from "../../../common/FilterTextbox";
import { TextField } from "../../../common/form/TextField";
import { LoadingRibbon } from "../../../common/loading/LoadingRibbon";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { ExcelIORibbon } from "../../../common/ribbon/ExcelIORibbon";
import { ExcludeHasNoChangeCheckbox } from "../../../common/ribbon/filter/ExcludeHasNoChangeCheckbox";
import { ExcludeOldRetiredMembersCheckbox } from "../../../common/ribbon/filter/ExcludeOldRetiredMembersCheckbox";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { SaveRibbon } from "../../../common/ribbon/SaveRibbon";

export interface AccountsPageRibbonVM {
  loadingStatus: LoadingStatusType;

  newUserIdText: string;
  newUserIdTextDisabled: boolean;
  onChangeNewUserIdText: (value: string) => void;
  onAddUser: () => void;

  filterText: string;
  onChangeFilterText: (value: string) => void;

  saveDisabled: boolean;
  onSave: () => void;
  resetDisabled: boolean;
  onReset: () => void;

  onExcelExport: () => void;
  onExcelImport: (f: File) => void;

  excludeOldRetireUsers: boolean;
  onChangeExcludeOldRetireUsers: (value: boolean) => void;

  excludeHasNoChange: boolean;
  onChangeExcludeHasNoChange: (value: boolean) => void;
}

@observer
export class AccountsPageRibbon extends React.Component<{ model: AccountsPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    if (loadingStatus !== "loaded") {
      return <LoadingRibbon />;
    }

    return (
      <>
        <GuideRibbon markdown={Help} />
        <PageRibbonSection title="新規社員追加">
          <PageRibbonLine>
            <TextField
              mode="form"
              maxLength={10}
              placeholder="新規追加する社員番号"
              onChange={model.onChangeNewUserIdText}
              value={model.newUserIdText}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <Button onClick={model.onAddUser} disabled={model.newUserIdTextDisabled} small={true} icon="add">
              新規社員追加
            </Button>
          </PageRibbonLine>
        </PageRibbonSection>
        <FilterRibbon title="テキストフィルタ">
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
        </FilterRibbon>

        <FilterRibbon title="条件フィルタ">
          <PageRibbonLine>
            <ExcludeHasNoChangeCheckbox
              checked={model.excludeHasNoChange}
              onChange={model.onChangeExcludeHasNoChange}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <ExcludeOldRetiredMembersCheckbox
              checked={model.excludeOldRetireUsers}
              onChange={model.onChangeExcludeOldRetireUsers}
            />
          </PageRibbonLine>
        </FilterRibbon>
        <ExcelIORibbon onExcelExport={model.onExcelExport} onExcelImport={model.onExcelImport} />
        <SaveRibbon
          onSave={model.onSave}
          onReset={model.onReset}
          saveDisabled={model.saveDisabled}
          resetDisabled={model.resetDisabled}
          useTimeoutConfirmReset={true}
        />
      </>
    );
  }
}
