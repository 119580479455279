import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalTimeSumKFProps = {
  model: MTotalTimeSumKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTimeSumKFVM {
  mTotalTimeSum: KintaiInputItemState<Minutes>;
}

export const MTotalTimeSumKF = observer((props: MTotalTimeSumKFProps) => {
  const { model } = props;
  const itemState = model.mTotalTimeSum;

  return <HHMMField {...props} label="mTotalTimeSumKF" {...itemState} onChange={undefined} />;
});
