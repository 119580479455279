import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { RenderedMedia } from "../../../common/export-table/ExportableTable";
import { HHMMField } from "../../../common/form/HHMMField";
import { NoResultsSpread } from "../../../common/NoResultsSpread";
import { WkTable, WkTd } from "../../../common/plaintable/WkTables";

export interface SaburokuPageTableVM {
  rows: SaburokuPageTableRowVM[];
}

export interface SaburokuPageTableRowVM {
  id: string;

  userId: string;
  userName: string;
  rankName: string;
  depName: string;

  thisMonth: {
    lawOver: Minutes;
    lawOverWarned: boolean;
    lawOverCalc: Minutes;
    lawOverCalcWarned: boolean;
    sundayOnCount: number;

    onNavigateToUserKintai: () => void;
  };

  lastMonth: {
    lawOver: Minutes;
    lawOverWarned: boolean;
    sundayOnCount: number;

    onNavigateToUserKintai: () => void;
  };

  monthBeforeLast: {
    lawOver: Minutes;
    lawOverWarned: boolean;

    onNavigateToUserKintai: () => void;
  };

  lawOverUntilLastMonth: Minutes;
  lawOverUntilLastMonthWarned: boolean;

  overFortyFiveUntilLastMonthCount: number;
  overFortyFiveUntilLastMonthCountWarned: boolean;
}

const LawOverCell = (props: { value: Minutes | undefined; warned: boolean; onNavigate: () => void }) => (
  <WkTd warned={props.warned}>
    <a href="javascript: undefined" onClick={props.onNavigate}>
      <HHMMField mode="print" value={props.value} />
    </a>
  </WkTd>
);

@observer
export class SaburokuPageTable extends React.Component<{
  renderedOn: RenderedMedia;
  model: SaburokuPageTableVM;
}> {
  public render() {
    const { model, renderedOn } = this.props;
    const { rows } = model;

    if (rows.length === 0) {
      return <NoResultsSpread />;
    }

    return (
      <WkTable>
        <thead>
          {renderedOn !== "csv" && (
            <>
              <tr>
                <th rowSpan={2}>#</th>

                <th rowSpan={2}>社員番号</th>
                <th rowSpan={2}>氏名</th>
                <th rowSpan={2}>今日時点のランク</th>
                <th rowSpan={2}>所属</th>

                <th colSpan={3}>当月</th>
                <th colSpan={2}>前月</th>
                <th colSpan={1}>前々月</th>

                <th colSpan={2}>前月までの累計</th>
              </tr>

              <tr>
                {/* 当月 */}
                <th>健康管理時間（予測）</th>
                <th>健康管理時間</th>
                <th>日曜出勤回数</th>

                {/* 前月 */}
                <th>健康管理時間</th>
                <th>日曜出勤回数</th>

                {/* 前々月 */}
                <th>健康管理時間</th>

                {/* 前月迄累計 */}
                <th>健康管理時間 (計)</th>
                <th>45時間/月超回数 (計)</th>
              </tr>
            </>
          )}
          {renderedOn === "csv" && (
            <tr>
              <th>#</th>
              <th>社員番号</th>
              <th>氏名</th>
              <th>今日時点のランク</th>
              <th>所属</th>

              {/* 当月 */}
              <th>当月 - 健康管理時間（予測）</th>
              <th>当月 - 健康管理時間</th>
              <th>当月 - 日曜出勤回数</th>

              {/* 前月 */}
              <th>前月 - 健康管理時間</th>
              <th>前月 - 日曜出勤回数</th>

              {/* 前々月 */}
              <th>前々月 - 健康管理時間</th>

              {/* 前月迄累計 */}
              <th>前月迄累計 - 健康管理時間 (計)</th>
              <th>前月迄累計 - 45時間/月超回数 (計)</th>
            </tr>
          )}
        </thead>
        <tbody>
          {model.rows.map((row, idx) => (
            <tr key={row.id}>
              <td>{idx + 1}</td>

              <td>{row.userId}</td>
              <td>{row.userName}</td>
              <td>{row.rankName}</td>
              <td>{row.depName}</td>

              {/* 今月 */}
              <LawOverCell
                warned={row.thisMonth.lawOverCalcWarned}
                value={row.thisMonth.lawOverCalc}
                onNavigate={row.thisMonth.onNavigateToUserKintai}
              />
              <LawOverCell
                warned={row.thisMonth.lawOverWarned}
                value={row.thisMonth.lawOver}
                onNavigate={row.thisMonth.onNavigateToUserKintai}
              />
              <td>{row.thisMonth.sundayOnCount}</td>

              {/* 前月 */}
              <LawOverCell
                warned={row.lastMonth.lawOverWarned}
                value={row.lastMonth.lawOver}
                onNavigate={row.lastMonth.onNavigateToUserKintai}
              />
              <td>{row.lastMonth.sundayOnCount}</td>

              {/* 前々月 */}
              <LawOverCell
                warned={row.monthBeforeLast.lawOverWarned}
                value={row.monthBeforeLast.lawOver}
                onNavigate={row.monthBeforeLast.onNavigateToUserKintai}
              />

              {/* 累計 */}
              <WkTd warned={row.lawOverUntilLastMonthWarned}>
                <HHMMField mode="print" value={row.lawOverUntilLastMonth} />
              </WkTd>
              <WkTd warned={row.overFortyFiveUntilLastMonthCountWarned}>{row.overFortyFiveUntilLastMonthCount}</WkTd>
            </tr>
          ))}
        </tbody>
      </WkTable>
    );
  }
}
