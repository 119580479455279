import { UserMonthlyDashboardData } from "../../../../../../schema/build/commonjs/main/typescript";
import { PrivateDDTableData, PrivateDDUserValueRow } from "../PrivateDDTableData";
import { dashboardLawOverConsts } from "./lawoverConsts";
import { sortByValue } from "./sortByValue";

export const calcPrvLawover = (value: UserMonthlyDashboardData): PrivateDDTableData<PrivateDDUserValueRow> => {
  const target = value.monthlyData.highestLawOvers;
  const warningCount = target.filter(it => it.value.minutes > 60 * 60).length;
  return {
    name: `健康管理（実績）${dashboardLawOverConsts.thresholdInHour}H超`,
    warningCount,
    warned: warningCount > 0,
    unit: "時間",
    rows: sortByValue(
      target.map(it => ({
        user: it.userAttributes,
        warned: it.value.minutes > dashboardLawOverConsts.thresholdInHour * 60,
        value: it.value.minutes / 60,
      })),
      "desc",
    ),
  };
};
