import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import Help from "@webkintai/help/content/manuals/overview/password_reset_app.md";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { decorateTitle } from "../../../utils/apptitle";
import { GuideButton } from "../../common/guide/GuideButton";

export interface PasswordChangeAppConsoleVM {
  showConsole: boolean;
  inputLocked: boolean;
  allowPushSubmit: boolean;
  userId: string;
  onChangeUserId: (value: string) => void;
  email: string;
  onChangeEmail: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}

const GuideButtonContainer = styled.div`
  float: right;
`;

@observer
export class PasswordChangeAppConsole extends React.Component<{ model: PasswordChangeAppConsoleVM }> {
  public render() {
    const { model } = this.props;

    return (
      <Dialog
        isOpen={model.showConsole}
        title="パスワードリセットの案内を申請する"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        onClose={model.onClose}
        autoFocus={true}
        isCloseButtonShown={true}>
        {model.showConsole && (
          <Helmet>
            <title>{decorateTitle("パスワードの変更の案内を申請する")}</title>
          </Helmet>
        )}

        <form className={Classes.DIALOG_BODY} method="get" action="javascript:undefined" onSubmit={model.onSubmit}>
          <GuideButtonContainer>
            <GuideButton markdown={Help} />
          </GuideButtonContainer>

          <p>パスワードリセットの案内を本システムからメールします。</p>
          <p>
            社員番号と、<strong>それに対応するメールアドレス</strong>
            を入力してください。（任意のメールアドレスは指定できません。）
          </p>

          <FormGroup label="社員番号" labelFor="login-form-logind">
            <input
              name="userId"
              id="login-form-logind"
              autoComplete="username"
              className={Classes.INPUT}
              disabled={model.inputLocked}
              placeholder="社員番号(半角数字)"
              type="text"
              onChange={this.onChangeUserId}
              value={model.userId}
            />
          </FormGroup>

          <FormGroup
            label={<span>メールアドレス</span>}
            labelFor="login-form-email"
            helperText="社員番号に対応するメールアドレスを入れてください。任意のメールアドレスは指定できません。社員番号に対応しないアドレスの場合はパスワード初期化の案内は飛びません。">
            <input
              name="email"
              id="login-form-email"
              disabled={model.inputLocked}
              className={Classes.INPUT}
              placeholder={"メールアドレス"}
              type="email"
              onChange={this.onChangeEmail}
              value={model.email}
            />
          </FormGroup>
        </form>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            type="submit"
            disabled={!model.allowPushSubmit || model.inputLocked}
            onClick={model.onSubmit}
            intent={Intent.DANGER}
            fill={true}
            large={true}
            text={"パスワード初期化の手続きを始める"}
          />
        </div>
      </Dialog>
    );
  }

  private onChangeUserId: React.ReactEventHandler<HTMLInputElement> = ev =>
    this.props.model.onChangeUserId(ev.currentTarget.value);

  private onChangeEmail: React.ReactEventHandler<HTMLInputElement> = ev =>
    this.props.model.onChangeEmail(ev.currentTarget.value);
}
