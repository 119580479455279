import { MeControllerApi, NotificationReadEntity } from "@webkintai/api";
import { inject } from "inversify";

import { ApiFactory } from "../ApiFactory";
import { NotificationsApi } from "./NotificationsApi";

export class NotificationsApiImpl extends NotificationsApi {
  constructor(@inject(ApiFactory) private readonly apiFactory: ApiFactory) {
    super();
  }

  public setRead(entries: NotificationReadEntity[]) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).readNotifications(entries),
    );
  }

  public getNotifications() {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).getNotifications(),
    );
  }

  public getNotificationDetails(id: number) {
    return this.apiFactory.guardFailures(() =>
      this.apiFactory.generateLoginAssumingApi(MeControllerApi).getNotification(id),
    );
  }
}
