import { Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

import { PrivateDDTableData, PrivateDDUserDatesRow } from "../../../../../models/statistics/prv/PrivateDDTableData";
import { WithLoading } from "../../../../../models/statistics/WithLoading";
import { paths } from "../../../../../routing/paths";
import { getNendo, getNendoMonths } from "../../../../../utils/calendar";
import { WkLink } from "../../../../common/link/WkLink";
import { PrvUserTable, PrvUserTableLoading, PrvUserTableNoData } from "./PrvUserTable";
import { PrvUserTableDataRow } from "./PrvUserTableDataRow";
import { PrvUserTableDepLink } from "./PrvUserTableDepLink";

export const PrvUserDatesTable: React.FC<{
  data: WithLoading<PrivateDDTableData<PrivateDDUserDatesRow>>;
  atMonth: Date;
  children?: never;
}> = ({ data, atMonth }) => {
  if (data.state === "loading") {
    return <PrvUserTableLoading />;
  }
  if (data.state === "no_data") {
    return <PrvUserTableNoData />;
  }

  return (
    <PrvUserTable>
      <thead>
        <tr>
          <th>#</th>
          <th>部門</th>
          <th>ランク</th>
          <th>社員番号</th>
          <th>氏名</th>
          <th>件数</th>
        </tr>
      </thead>
      <tbody>
        {data.rows.length === 0 && (
          <tr>
            <td colSpan={5}>データがありません</td>
          </tr>
        )}
        {data.rows.map((it, idx) => (
          <PrvUserTableDataRow key={it.user.userId} className={it.warned ? "Warned" : undefined}>
            <td>{idx + 1}</td>
            <td>
              <PrvUserTableDepLink atMonth={atMonth} user={it.user} />
            </td>
            <td>{it.user.rankName}</td>
            <td>
              <WkLink
                href={paths.kintai
                  .ofUser(it.user.userId)
                  .ofDate(atMonth)
                  .index()}>
                {it.user.userId}
              </WkLink>
            </td>
            <td>{it.user.userName}</td>
            <td>
              <Popover
                content={
                  <PopoverContent>
                    {getNendoMonths(getNendo(atMonth))
                      .map(month => ({
                        month,
                        dates: it.dates.filter(date => month.getMonth() === date.getMonth()),
                      }))
                      .filter(({ dates }) => dates.length > 0)
                      .map(({ month, dates }) => (
                        <PopoverMonthSection key={month.getTime()}>
                          <PopoverHeading>{moment(month).format("MM月")}</PopoverHeading>
                          <PopoverUl>
                            {dates.map(date => (
                              <li key={date.getTime()}>
                                <WkLink
                                  href={paths.kintai
                                    .ofUser(it.user.userId)
                                    .ofDate(date)
                                    .index()}>
                                  {moment(date).format("DD")}
                                </WkLink>
                              </li>
                            ))}
                          </PopoverUl>
                        </PopoverMonthSection>
                      ))}
                  </PopoverContent>
                }>
                <a>{it.dates.length}</a>
              </Popover>
            </td>
          </PrvUserTableDataRow>
        ))}
      </tbody>
    </PrvUserTable>
  );
};

const PopoverContent = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 15rem;
  overflow-y: auto;
`;

const PopoverMonthSection = styled.section`
  margin: 0.25rem;
  padding: 0.5rem;
  width: 10rem;
  background-color: #eee;
  border-radius: 0.25rem;
  text-align: center;
`;

const PopoverHeading = styled.h1`
  font-size: 1rem;
  margin: 0;
`;

const PopoverUl = styled.ul`
  margin: 0;
  margin-top: 0.25rem;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  & > li {
    display: block;
  }
`;
