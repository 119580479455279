import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalHolidaySumKFProps = {
  model: MTotalHolidaySumKFVM;
} & CellFormPrintAdaptable;

export interface MTotalHolidaySumKFVM {
  mTotalHolidaySum: KintaiInputItemState<Minutes>;
}

export const MTotalHolidaySumKF = observer((props: MTotalHolidaySumKFProps) => {
  const { model } = props;
  const itemState = model.mTotalHolidaySum;

  return <HHMMField {...props} label="mTotalHolidaySumKF" {...itemState} onChange={undefined} />;
});
