import { computed } from "mobx";

import { lazyInject } from "../../../../didecorators";
import { ByUserMonthView } from "../../../../models/kintai_sum/ByUserView";
import { paths } from "../../../../routing/paths";
import { AppRouter } from "../../../../services/AppRouter";
import { KintaiSumTableByUserMonthVM } from "./KintaiSumTableByUser";

export class KintaiSumTableByUserMonthVMImpl implements KintaiSumTableByUserMonthVM {
  @lazyInject(AppRouter)
  private appRouter: AppRouter;

  constructor(private readonly model: ByUserMonthView) {}

  @computed
  get month() {
    return this.model.month;
  }

  @computed
  get dayCount() {
    return this.model.dayCount;
  }

  @computed
  get lawBasis() {
    return this.model.lawBasis;
  }

  @computed
  get users() {
    return this.model.users.map(user => ({
      ...user,
      onNavigateToKintai: () => {
        this.appRouter.navigate(
          paths.kintai
            .ofUser(user.userId)
            .ofDate(this.model.month)
            .index(),
        );
      },
    }));
  }

  @computed
  get total() {
    return this.model.total;
  }

  @computed
  get average() {
    return this.model.average;
  }
}
