import { getNendo } from "../../../../utils/calendar";
import { compareMonth, dateOf } from "../../../../utils/date";

/**
 * 休業・休暇系日数の表示・非表示
 */
export class VacantDayItemsVisibility {
  constructor(private readonly month: Date) {}

  private get nendo() {
    return getNendo(this.month);
  }

  public get sickoutDaysOrNonattendanceDays() {
    return this.nendo <= 2017 ? "sickoutDays" : "nonattendanceDays";
  }

  public get leaveDays() {
    return 2018 <= this.nendo;
  }

  /**
   * @deprecated
   */
  public get holidaysColumnCount() {
    return (this.showAggregatedPaidHours ? 1 : 0) + (this.showAggregatedPaidDays ? 2 : 4 + (this.summerDays ? 1 : 0));
  }

  /**
   * 休暇系取得項目は有休あるいはその他休にすべて集約するか？
   */
  public get showAggregatedPaidDays() {
    return 2018 <= this.nendo;
  }

  /**
   * その他休のうち、時間休を表示するか
   * @deprecated
   */
  public get showAggregatedPaidHours() {
    return this.newDisplaySinceHourlyNF;
  }

  /**
   * 看護・介護時間休導入後の新しい表示方式
   */
  public get newDisplaySinceHourlyNF() {
    return compareMonth(this.month, hourlyNursingcareFamilycareEnabledFrom, (l, r) => l.getTime() >= r.getTime());
  }

  public get summerDays() {
    return 2016 <= this.nendo && this.nendo <= 2018;
  }

  public get nendoTotalRefreshDays() {
    return this.nendo <= 2015;
  }

  public get nendoTotalPositiveDays() {
    return this.nendo <= 2018;
  }

  public get nendoTotalHourlyLeave() {
    return 2019 <= this.nendo;
  }

  public get nendoTotalSummerDays() {
    return 2016 <= this.nendo && this.nendo <= 2018;
  }

  public get nendoTotalSpecialDays() {
    return this.nendo <= 2017;
  }

  public get nendoTotalOtherDays() {
    return 2018 <= this.nendo;
  }

  public get nendoTotalOtherHours() {
    return getNendo(hourlyNursingcareFamilycareEnabledFrom) <= this.nendo;
  }
}

const hourlyNursingcareFamilycareEnabledFrom = dateOf(2022, 4, 1);
