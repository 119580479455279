import { dateOf } from "./date";

export const applyBoundary = (value: number, min: number, max: number) => Math.min(max, Math.max(min, value));
export const inputIntegerHandler = (min: number, max: number, handler?: (days: number) => void) => (
  ev: React.FormEvent<HTMLInputElement>,
) => {
  if (!handler) {
    return;
  }
  const text = (ev.currentTarget.value || "").replace(/\D/g, "").slice(-`${max}`.length);
  handler(applyBoundary(Math.floor(+text) || 0, min, max));
};

export function inputStringHandler(handler: (value: string) => void): React.ChangeEventHandler<HTMLInputElement> {
  return ev => {
    handler(ev.currentTarget.value);
  };
}

const zenkakuDelta = "１".charCodeAt(0) - "1".charCodeAt(0);

export const zenkakuNumToHankaku = (src: string) =>
  src.replace(/[０１２３４５６７８９]/g, s => String.fromCharCode(s.charCodeAt(0) - zenkakuDelta));

export function recognizeAsDate(dateString: string, referenceYear = new Date().getFullYear()): Date | undefined {
  const normalized = zenkakuNumToHankaku(dateString.replace(/\s/g, ""));
  const result = normalized.match(/^(\d{4})?\D?(\d{1,2})\D?(\d{1,2})$/);
  if (result) {
    const year: number = +result[1] || referenceYear;
    const month: number = +result[2];
    const day: number = +result[3];

    return dateOf(year, month, day);
  }
  return undefined;
}

export const commandOrCtrlPushed = (ev: React.KeyboardEvent<unknown>) => ev.ctrlKey || ev.metaKey;
