import { Button, ButtonGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

const Container = styled.div`
  min-height: 10em;
  padding: 1ex;
`;

const PasswordField = styled.input`
  display: inline-block;
  font-family: monospace;
  background-color: #ccc;
  border: 0px solid #fff;
  border-radius: 5px;
  border-color: #999;
  padding: 1em;
`;

export interface PasswordInitDialogVM {
  userName: string;
  userId: string;
  mailAddr: string;
  suggestedPassword: string;
  fixedPasswordIsUsed: boolean;
  onRegeneratePassword: () => void;
  onInitializePassword: () => void;
}

@observer
export class PasswordInitDialog extends React.Component<{ model: PasswordInitDialogVM }> {
  private passwordInput = React.createRef<HTMLInputElement>();

  public render() {
    const { model } = this.props;

    return (
      <div>
        <Container>
          <h3>下記のユーザのパスワードを初期化します</h3>
          <pre>
            名前: {model.userName}
            {"\n"}
            社員番号: {model.userId}
            {"\n"}
            メール: {model.mailAddr}
          </pre>
          <p>初期化後のパスワードは下記になります。</p>
          <div>
            <PasswordField
              className="bp3-input"
              ref={this.passwordInput}
              onChange={this.voidChange}
              value={model.suggestedPassword}
            />
          </div>
          <p>
            ※初期化パスワードは仮のものです。本人が上記パスワードでログイン後、必ず別のパスワードを設定するように要求されます。
          </p>
          <p>
            <ButtonGroup>
              <Button icon="clipboard" text="コピーする" onClick={this.onCopyPassword} />
              <Button
                icon="refresh"
                text={model.fixedPasswordIsUsed ? "上記は固定パスワードです" : "別のパスワードにする"}
                disabled={model.fixedPasswordIsUsed}
                onClick={model.onRegeneratePassword}
              />
            </ButtonGroup>
          </p>
        </Container>

        <div className="bp3-dialog-footer">
          <div className="bp3-dialog-footer-actions">
            <Button onClick={model.onInitializePassword} intent={Intent.DANGER}>
              初期化する
            </Button>
          </div>
        </div>
      </div>
    );
  }
  private voidChange = () => {};

  private onCopyPassword = () => {
    const { current } = this.passwordInput;
    if (current) {
      current.select();
      document.execCommand("copy");
      current.blur();
    }
  };
}
