import Help from "@webkintai/help/content/manuals/dashboard/transday.md";
import { observer } from "mobx-react";
import React from "react";

import { TransDayPageValidityFilterType } from "../../../../models/trans_day/TransDayPageValidityFilter";
import { FilterTextbox } from "../../../common/FilterTextbox";
import { DateField } from "../../../common/form/DateField";
import { SelectField, SelectFieldOptionItem } from "../../../common/form/SelectField";
import { IncludeManagerCheckbox } from "../../../common/IncludeManagerCheckbox";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";

export interface TransDayPageRibbonVM {
  onReload: () => void;
  fromDate: Date;
  onChangeFromDate: (value: Date | undefined) => void;
  toDate: Date;
  onChangeToDate: (value: Date | undefined) => void;
  filterText: string;
  onChangeFilterText: (value: string) => void;
  filterForValidity: TransDayPageValidityFilterType;
  onChangeFilterForValidity: (value: TransDayPageValidityFilterType) => void;
  includeManager: boolean;
  onChangeIncludeManager: (value: boolean) => void;
}

const filterForValidityOptions: SelectFieldOptionItem[] = [
  { value: "ALL", label: "すべてのチェック結果" },
  { value: "OK", label: "チェックOKのみ" },
  { value: "NG", label: "チェックNGのみ" },
];

@observer
export class TransDayPageRibbon extends React.Component<{ model: TransDayPageRibbonVM }> {
  public render() {
    const { model } = this.props;
    return (
      <>
        <GuideRibbon markdown={Help} />

        <JumpRibbon>
          <PageRibbonLine>
            <DateField mode="form" value={model.fromDate} onChange={model.onChangeFromDate} />〜
          </PageRibbonLine>
          <PageRibbonLine>
            <DateField mode="form" value={model.toDate} onChange={model.onChangeToDate} />
          </PageRibbonLine>
        </JumpRibbon>
        <ReloadRibbon onReload={model.onReload} />
        <FilterRibbon>
          <PageRibbonLine>
            <SelectField
              mode="form"
              value={model.filterForValidity}
              options={filterForValidityOptions}
              onChange={this.onChangeFilterForValidity}
            />
            <IncludeManagerCheckbox
              includeManager={model.includeManager}
              onChangeIncludeManager={model.onChangeIncludeManager}
            />
          </PageRibbonLine>
          <PageRibbonLine>
            <FilterTextbox filterText={model.filterText} onChangeFilterText={model.onChangeFilterText} />
          </PageRibbonLine>
        </FilterRibbon>
      </>
    );
  }

  private onChangeFilterForValidity = (value: string | undefined) => {
    this.props.model.onChangeFilterForValidity((value || "ALL") as any);
  };
}
