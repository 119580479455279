import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../../models/statistics/usePrivateDDModel";
import { PrvUserDatesTable } from "../../../table/PrvUserDatesTable";
import { PrvUserTableWarningCounter } from "../../../table/PrvUserTableCounter";

export const PrvKintaiNotApprovedTableContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserDatesTable atMonth={model.date} data={model.nendo_kintaiAuthNotApproved} />;
});

export const PrvKintaiNotApprovedTableCounterContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return <PrvUserTableWarningCounter title="未承認の件数" data={model.nendo_kintaiAuthNotApproved} />;
});
