import styled from "@emotion/styled";
import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";
import { useCallback } from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type PJRBodyCellTimeKFProps = {
  model: PJRBodyCellTimeKFVM;
} & CellFormPrintAdaptable;

export interface PJRBodyCellTimeKFVM {
  PJRBodyCellLabel: KintaiInputItemState<string>;
  PJRBodyCellTime: KintaiInputItemState<Minutes | undefined>;
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CellLabel = styled.input`
  color: #666;
  font-size: 80%;
  border: none;
`;

@observer
export class PJRBodyCellTimeKF extends React.Component<PJRBodyCellTimeKFProps> {
  public render() {
    const { props } = this;
    const { model } = props;
    const itemState = model.PJRBodyCellTime;

    if (props.mode === "cell" || props.mode === "print") {
      return <HHMMField {...props} label="時間" {...itemState} placeholder={model.PJRBodyCellLabel.value} />;
    }

    return (
      <FormContainer>
        <CellLabel
          disabled={model.PJRBodyCellLabel.disabled}
          placeholder={"時間"}
          onChange={this.onChangeLabel}
          value={model.PJRBodyCellLabel.value}
        />
        <HHMMField {...props} {...itemState} />
      </FormContainer>
    );
  }

  private onChangeLabel = (ev: React.ChangeEvent<HTMLInputElement>) => {
    this.props.model.PJRBodyCellLabel.onChange(ev.currentTarget.value);
  };
}
