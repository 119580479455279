import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type WorkplaceSonotaKFProps = {
  model: WorkplaceSonotaKFVM;
} & CellFormPrintAdaptable;

export interface WorkplaceSonotaKFVM {
  workplaceSonota: KintaiInputItemState<boolean>;
}

export const WorkplaceSonotaKF = observer((props: WorkplaceSonotaKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.workplaceSonota);

  return <CheckboxField {...props} label="その他" {...itemState} />;
});
