import {
  DefaultResetPasswordSuggestionResult,
  PaidVacationEditEntity,
  PaidVacationsResult,
  UserAbsenceEntity,
  UserEditEntity,
  UsersResult,
} from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class UsersApi {
  public abstract getUsers(): Promise<UsersResult>;
  public abstract addUser(entry: UserEditEntity): Promise<unknown>;
  public abstract updateUsers(entries: UserEditEntity[]): Promise<unknown>;
  public abstract updateUserAbsences(userId: string, absences: UserAbsenceEntity[]): Promise<unknown>;
  public abstract getPaidVacations(nendo: number): Promise<PaidVacationsResult>;
  public abstract savePaidVacations(nendo: number, paidVacations: PaidVacationEditEntity[]): Promise<Response>;
  public abstract setInitPassword(userId: string, tempPassword: string | undefined): Promise<Response>;
  public abstract getDefaultResetPassword(): Promise<DefaultResetPasswordSuggestionResult>;
}
