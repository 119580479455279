import React, { useEffect, useState } from "react";

import { useAppStateModel } from "../../models/useAppStateModel";
import { AppFrame } from "./AppFrame";
import { AppFrameVMImpl } from "./AppFrameVMImpl";

export const App: React.FC<{ children?: never }> = () => {
  const appState = useAppStateModel();
  useEffect(() => {
    appState.init();
    return () => {
      appState.dispose();
    };
  }, []);

  return <AppFrame model={new AppFrameVMImpl(appState)} />;
};
