import { KintaiInputItemState } from "./KintaiInput";

const emptyList: string[] = [];
const noChange = () => {};

export function valueOnlyKintaiInputItemState<T>(value: T): KintaiInputItemState<T> {
  return {
    errors: emptyList,
    warnings: emptyList,
    infos: emptyList,
    hasNoChange: true,
    disabled: true,
    value,
    onChange: noChange,
  };
}
