import Chart from "chart.js";
import { flatten } from "lodash-es";
import React, { ComponentProps, useEffect, useRef, useState } from "react";

import { statRound } from "../statRound";
import { chartBgColor, chartBorderColor } from "./chartColor";

export const SimpleBarChart: React.FC<{
  large?: boolean;
  unit: string;
  data: Array<{
    label: string;
    value: number;
  }>;
  children?: never;
}> = ({ large, unit, data }) => {
  const ref = useRef<HTMLCanvasElement>(null);
  const [chart, setChart] = useState<Chart | undefined>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const ctx = ref.current.getContext("2d")!;
    const createdChart = new Chart(ctx, {
      type: "horizontalBar",
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: !large,
        responsive: large,
      },
    });
    setChart(createdChart);
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }
    chart.options.tooltips = {
      callbacks: {
        title(items) {
          if (items.length === 0 || !items[0].yLabel) {
            return "";
          }
          return flatten([items[0].yLabel]).join("");
        },
      },
    };
    chart.options.scales = {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            fontSize: 10,
            labelString: unit,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontSize: 10,
          },
        },
      ],
    };
    chart.data = {
      labels: data.map(it => (large ? it.label : flatten(it.label.match(/(.{0,8})/g)))),
      datasets: [
        {
          label: unit,
          data: data.map(it => statRound(it.value)),
          backgroundColor: data.map(it => chartBgColor(it.label)),
          borderColor: data.map(it => chartBorderColor(it.label)),
          borderWidth: 1,
        },
      ],
    };
    chart.update();
  }, [chart, data, unit]);

  return <canvas ref={ref} width={300} height={200} />;
};
