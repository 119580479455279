import { Button, IPopoverProps, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { mediaQuerySmall } from "../responsive/Responsive";
import { DeptSelector, DeptSelectorProps } from "./DeptSelector";

const DeptsButtonLabel = styled.span`
  ${mediaQuerySmall} {
    font-size: 8pt;
    line-height: 8pt;
    display: block;
    width: 6em;
    overflow: hidden;
    padding-bottom: 1px;
  }
`;

const PopoverFrame = styled.div`
  max-height: 25em;
  display: flex;
`;

export type DeptsSelectorButtonProps = {
  buttonProps?: JSX.LibraryManagedAttributes<typeof Button, Button["props"]>;
  popoverProps?: IPopoverProps;
} & DeptSelectorProps;
export const DeptsSelectorButton = (props: DeptsSelectorButtonProps) => {
  const { model } = props;
  const { status, activeDept } = model;
  const disabled = status !== "loaded";
  return (
    <Popover
      disabled={disabled}
      content={
        <PopoverFrame>
          <DeptSelector {...props} />
        </PopoverFrame>
      }
      {...{
        minimal: true,
        position: Position.BOTTOM,
        boundary: "viewport",
        ...props.popoverProps,
      }}>
      <Button
        disabled={disabled}
        icon="diagram-tree"
        rightIcon="caret-down"
        {...(props.buttonProps ? props.buttonProps : {})}>
        <DeptsButtonLabel>
          {status !== "loaded" && "ロード中"}
          {status === "loaded" && (activeDept ? activeDept.depName : "部門選択")}
        </DeptsButtonLabel>
      </Button>
    </Popover>
  );
};
