import { computed } from "mobx";
import { Instance } from "mobx-state-tree";

import { AppScreens } from "../../models/AppScreens";
import { AppState } from "../../models/AppState";
import { AdminPageVMImpl } from "../admin/AdminPageVMImpl";
import { DashboardPageVMImpl } from "../dashboard/DashboardPageVMImpl";
import { HelpPageVMImpl } from "../help/HelpPageVMImpl";
import { KintaiPageVMImpl } from "../kintai/KintaiPageVMImpl";
import { ProfilePageVMImpl } from "../profile/ProfilePageVMImpl";
import { AppFrameVM } from "./AppFrame";
import { WarningBannerVMImpl } from "./banner/WarningBannerVMImpl";
import { LoginConsoleVMImpl } from "./login/LoginConsoleVMImpl";
import { ApiServerFaultMsgVMImpl } from "./message/ApiServerFaultMsgVMImpl";
import { AppInitMsgVMImpl } from "./message/AppInitMsgVMImpl";
import { ApplicationConfirmVMImpl } from "./message/ApplicationConfirmVMImpl";
import { GlobalNavbbarVMImpl } from "./navbar/GlobalNavbbarVMImpl";
import { PasswordChangeConsoleVMImpl } from "./password/PasswordChangeConsoleVMImpl";
import { PasswordTokenResetConsoleVMImpl } from "./password/PasswordTokenResetConsoleVMImpl";

export class AppFrameVMImpl implements AppFrameVM {
  public appInitMsgVM = new AppInitMsgVMImpl();
  public warningBannerVM = new WarningBannerVMImpl();
  public apiServerFaultMsgVM = new ApiServerFaultMsgVMImpl();
  public loginConsoleVM = new LoginConsoleVMImpl();
  public applicationConfirmVM = new ApplicationConfirmVMImpl();
  public passwordChangeConsoleVM = new PasswordChangeConsoleVMImpl();
  public passwordTokenResetConsoleVM = new PasswordTokenResetConsoleVMImpl();
  public globalNavbarVM = new GlobalNavbbarVMImpl();
  public kintaiPageVM = new KintaiPageVMImpl();
  public dashboardPageVM = new DashboardPageVMImpl();
  public adminPageVM = new AdminPageVMImpl();
  public helpPageVM = new HelpPageVMImpl();
  public profilePageVM = new ProfilePageVMImpl();

  constructor(private readonly appState: Instance<typeof AppState>) {
    // DO NOTHING
  }

  @computed
  get envName() {
    return env.env_name;
  }

  @computed
  get showLoginConsole() {
    return this.appState.login.needsLogin;
  }

  @computed
  get mode() {
    return this.appState.mode;
  }

  @computed
  get state() {
    return this.appState.state;
  }

  @computed
  get isInApiServerFault() {
    return this.appState.isInApiServerFault;
  }

  get currentScreen(): typeof AppScreens.Type {
    return this.appState.screenMode;
  }

  get needsLogin() {
    return this.appState.login.needsLogin;
  }
}
