import { action, computed } from "mobx";

import { KintaiListEntry } from "../../../../models/kintai_list/KintaiListEntry";
import { KintaiListPageModel } from "../../../../models/kintai_list/KintaiListPageModel";
import { KintaiListCommonGridItems } from "./KintaiListCommonGridItems";

export class CommonKintaiListPageGridRowVMImpl implements KintaiListCommonGridItems {
  constructor(
    public readonly pageModel: typeof KintaiListPageModel.Type,
    public readonly monthlyKintai: typeof KintaiListEntry.Type,
  ) {}

  @computed
  private get attrs() {
    return this.monthlyKintai.attrs!;
  }

  @computed
  get userId() {
    return this.monthlyKintai.userId;
  }

  @computed
  get userName() {
    return this.monthlyKintai.userName || "";
  }

  @computed
  get rankName() {
    return this.monthlyKintai.rankName || "";
  }

  @action.bound
  public onOpenKintai() {
    this.pageModel.setActiveRow(this.userId);
  }

  @computed
  get errors() {
    return this.monthlyKintai.errors;
  }

  @computed
  get warnings() {
    return this.monthlyKintai.warnings;
  }

  @computed
  get infos() {
    return this.monthlyKintai.infos;
  }

  @computed
  get monthlyNotAppliedErrors() {
    return this.monthlyKintai.notAppliedErrors;
  }

  @computed
  get monthlyNotApprovedErrors() {
    return this.monthlyKintai.notApprovedErrors;
  }

  @computed
  get monthlyPrivilegedNotApprovedErrors() {
    return this.monthlyKintai.priviledgedNotApprovedErrors;
  }

  @computed
  get monthlyRejectedErrors() {
    return this.monthlyKintai.rejectedErrors;
  }

  @computed
  get monthlyApprovalOnYouErrors() {
    return this.monthlyKintai.requireApprovalOnYouErrors;
  }

  @computed
  get monthlyRequireApprovalOnYouErrors() {
    return this.monthlyKintai.requireApprovalOnYouErrors;
  }
  @computed
  get selfStamped() {
    return this.monthlyKintai.selfStamped;
  }
  @computed
  get reviewerStamped() {
    return this.monthlyKintai.reviewerStamped;
  }
  @computed
  get approverStamped() {
    return this.monthlyKintai.approverStamped;
  }
  @computed
  get clerkStamped() {
    return this.monthlyKintai.clerkStamped;
  }
  @computed
  get hrmStamped() {
    return this.monthlyKintai.hrmStamped;
  }

  @computed
  public get approverBA() {
    return {
      disabled: !this.monthlyKintai.approverBAStampable,
      checked: this.monthlyKintai.approverBAStamped,
      hasNoChange: this.monthlyKintai.approverBAHasNoChange,
      onChange: this.monthlyKintai.setApproverBAStamped,
    };
  }

  @computed
  public get clerkBA() {
    return {
      disabled: !this.monthlyKintai.clerkBAStampable,
      checked: this.monthlyKintai.clerkBAStamped,
      hasNoChange: this.monthlyKintai.clerkBAHasNoChange,
      onChange: this.monthlyKintai.setClerkBAStamped,
    };
  }

  @computed
  public get hrmBA() {
    return {
      disabled: !this.monthlyKintai.hrmBAStampable,
      checked: this.monthlyKintai.hrmBAStamped,
      hasNoChange: this.monthlyKintai.hrmBAHasNoChange,
      onChange: this.monthlyKintai.setHRMBAStamped,
    };
  }
}
