import { Days } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { DayCountField } from "../../../../../form/DayCountField";

export type MTotalPaidAllDaysKFProps = {
  model: MTotalPaidAllDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalPaidAllDaysKFVM {
  mTotalPaidAllDays: KintaiInputItemState<Days>;
}

/** 1日(終日)単位のみの有給休暇 */
export const MTotalPaidAllDaysKF = observer((props: MTotalPaidAllDaysKFProps) => {
  const { model } = props;
  const itemState = model.mTotalPaidAllDays;

  return <DayCountField {...props} label="" {...itemState} value={itemState.value.days} onChange={undefined} />;
});
