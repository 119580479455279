import { action, computed } from "mobx";

import { PjCloseNendoEditorMonth } from "../../../../models/pjclose/PjCloseNendoEditorMonth";
import { PjClosePageVMImpl } from "../PjClosePageVMImpl";
import { PjClosePageGridMonthVM } from "./PjClosePageGrid";

export class PjClosePageGridVMImpl implements PjClosePageGridMonthVM {
  constructor(
    private readonly pageModel: PjClosePageVMImpl,
    private readonly model: typeof PjCloseNendoEditorMonth.Type,
  ) {}

  @computed
  public get month() {
    return this.model.month;
  }
  @computed
  public get hasNoChange() {
    return this.model.hasNoChange;
  }
  @computed
  public get disabled() {
    return !this.pageModel.allowedToEdit;
  }

  @computed
  public get closed() {
    return this.model.closed;
  }

  @action.bound
  public onChangeClosed(value: boolean) {
    this.model.setClosed(value);
  }
}
