import { Tab, Tabs } from "@blueprintjs/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { usePrivateDDModel } from "../../../../../../models/statistics/usePrivateDDModel";
import { DashboardWidget } from "../../DashboardWidget";
import { DashboardWidgetLastUpdate } from "../../lastupdate/DashboardWidgetLastUpdate";
import {
  PrvKintaiNotAppliedTableContainer,
  PrvKintaiNotAppliedTableCounterContainer,
} from "./tables/PrvKintaiNotAppliedTableContainer";
import {
  PrvKintaiNotApprovedTableContainer,
  PrvKintaiNotApprovedTableCounterContainer,
} from "./tables/PrvKintaiNotApprovedTableContainer";
import { PrvPaidLeavesTableContainer, PrvPaidLeavesTableCounterContainer } from "./tables/PrvPaidLeavesTableContainer";

export const PrvNendoWidgetContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePrivateDDModel();

  return (
    <DashboardWidget
      mood={model.nendoDataWarned ? "bad" : "good"}
      title={"[年度内] あなたの部下について"}
      lastUpdate={<DashboardWidgetLastUpdate lastUpdate={model.nendoContentLastUpdate} />}>
      <Tabs
        id="TabsExample"
        onChange={model.setActiveNendoContent}
        selectedTabId={model.activeNendoContent}
        vertical={false}>
        <Tab
          id="notApplied"
          title={<PrvKintaiNotAppliedTableCounterContainer />}
          panel={<PrvKintaiNotAppliedTableContainer />}
        />
        <Tab
          id="notApproved"
          title={<PrvKintaiNotApprovedTableCounterContainer />}
          panel={<PrvKintaiNotApprovedTableContainer />}
        />
        <Tab id="paidLeaves" title={<PrvPaidLeavesTableCounterContainer />} panel={<PrvPaidLeavesTableContainer />} />
      </Tabs>
    </DashboardWidget>
  );
});
