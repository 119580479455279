import styled from "@emotion/styled";
import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { AppScreens } from "../../models/AppScreens";
import { AdminPage, AdminPageVM } from "../admin/AdminPage";
import { mediaQuerySmall, ResponsiveSpecification, useResponsive } from "../common/responsive/Responsive";
import { DashboardPage, DashboardPageVM } from "../dashboard/DashboardPage";
import { DashboardAppletContainer } from "../dashboard/statistics/applet/DashboardAppletContainer";
import { HelpPage, HelpPageVM } from "../help/HelpPage";
import { KintaiPage, KintaiPageVM } from "../kintai/KintaiPage";
import { ProfilePage, ProfilePageVM } from "../profile/ProfilePage";
import { WarningBanner, WarningBannerVM } from "./banner/WarningBanner";
import { LoginConsole, LoginConsoleVM } from "./login/LoginConsole";
import { ApiServerFaultMsg, ApiServerFaultMsgVM } from "./message/ApiServerFaultMsg";
import { AppInitMsg, AppInitMsgVM } from "./message/AppInitMsg";
import { ApplicationConfirm, ApplicationConfirmVM } from "./message/ApplicationConfirm";
import { GlobalNavbar, GlobalNavbbarVM } from "./navbar/GlobalNavbar";
import { LinkRouterProvider } from "./navigation/LinkRouterProvider";
import { PasswordChangeConsole, PasswordChangeConsoleVM } from "./password/PasswordChangeConsole";

export interface AppFrameVM {
  envName: string | undefined;
  mode: "none" | "kintai" | "token_reset";
  state: "init" | "started";
  isInApiServerFault: boolean;
  apiServerFaultMsgVM: ApiServerFaultMsgVM;
  currentScreen: typeof AppScreens.Type;
  applicationConfirmVM: ApplicationConfirmVM;
  passwordTokenResetConsoleVM: PasswordChangeConsoleVM;
  passwordChangeConsoleVM: PasswordChangeConsoleVM;
  showLoginConsole: boolean;
  appInitMsgVM: AppInitMsgVM;
  warningBannerVM: WarningBannerVM;
  loginConsoleVM: LoginConsoleVM;
  globalNavbarVM: GlobalNavbbarVM;
  kintaiPageVM: KintaiPageVM;
  dashboardPageVM: DashboardPageVM;
  adminPageVM: AdminPageVM;
  helpPageVM: HelpPageVM;
  profilePageVM: ProfilePageVM;
  needsLogin: boolean;
}

const AppFrameContainer = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.NeedsLogin,
  &.ApiRetry {
    filter: blur(3px);
  }

  ${mediaQuerySmall} {
    .bp3-button {
      padding-left: 5px;
      padding-right: 5px;
      & > .bp3-icon {
        margin-right: 3px;
      }
      & > .bp3-button-text {
        margin-right: 1px;
      }
    }
  }
`;

const PageContentContainer = styled.div`
  flex: 1 1 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const EnvNameContainer = styled.div`
  font-size: 5vw;
  font-weight: bold;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 10000;
  opacity: 0.1;
  pointer-events: none;
`;

type AppFrameProps = {
  model: AppFrameVM;
} & ResponsiveSpecification;

@observer
class OurAppFrame extends React.Component<AppFrameProps> {
  public render() {
    const { model, small } = this.props;

    const navbar = (model.mode === "none" || model.mode === "kintai") && (
      <GlobalNavbar state={model.state} model={model.globalNavbarVM} />
    );

    const floatingArea = (
      <>
        <ApplicationConfirm model={model.applicationConfirmVM} />
        {model.envName && <EnvNameContainer>{model.envName}</EnvNameContainer>}

        {model.mode === "kintai" && (
          <>
            {model.loginConsoleVM.showConsole && <LoginConsole model={model.loginConsoleVM} />}
            <PasswordChangeConsole model={model.passwordChangeConsoleVM} />
            <WarningBanner model={model.warningBannerVM} />
          </>
        )}
        {model.mode === "token_reset" && (
          <>
            <PasswordChangeConsole model={model.passwordTokenResetConsoleVM} />
          </>
        )}

        {model.isInApiServerFault && <ApiServerFaultMsg model={model.apiServerFaultMsgVM} />}

        <DashboardAppletContainer />
      </>
    );

    const contentArea = (
      <>
        {model.state === "init" && <AppInitMsg model={model.appInitMsgVM} />}

        {model.mode === "token_reset" && model.state === "started"}

        {model.mode === "kintai" && model.state === "started" && (
          <>
            <PageContentContainer>
              {model.currentScreen === "kintai" && <KintaiPage model={model.kintaiPageVM} />}
              {model.currentScreen === "dashboard" && <DashboardPage model={model.dashboardPageVM} />}
              {model.currentScreen === "admin" && <AdminPage model={model.adminPageVM} />}
              {model.currentScreen === "help" && <HelpPage model={model.helpPageVM} />}
              {model.currentScreen === "profile" && <ProfilePage model={model.profilePageVM} />}
            </PageContentContainer>
          </>
        )}
      </>
    );

    return (
      <LinkRouterProvider>
        <AppFrameContainer
          className={classNames(
            model.isInApiServerFault && "ApiRetry",
            model.mode === "kintai" && model.needsLogin && "NeedsLogin",
            model.state !== "started" && "ApplyCommonBackground",
          )}>
          {floatingArea}

          {!small && navbar}
          {contentArea}
          {small && navbar}
        </AppFrameContainer>
      </LinkRouterProvider>
    );
  }
}

export const AppFrame: React.FunctionComponent<{ model: AppFrameVM }> = props => (
  <OurAppFrame model={props.model} {...useResponsive()} />
);
