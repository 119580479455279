import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { TransDayPageModel, TransDayPageModelSymbol } from "../../../models/trans_day/TransDayPageModel";
import { TransDayPageValidityFilter } from "../../../models/trans_day/TransDayPageValidityFilter";
import { TransDayPageRibbonVM } from "./ribbon/TransDayPageRibbon";
import { TransDayPageTableVM } from "./table/TransDayPageTable";
import { TransDayPageTableRowVMImpl } from "./table/TransDayPageTableRowVMImpl";
import { TransDayPageVM } from "./TransDayPage";

export class TransDayPageVMImpl implements TransDayPageVM, TransDayPageRibbonVM, TransDayPageTableVM {
  @lazyInject(TransDayPageModelSymbol)
  private model: typeof TransDayPageModel.Type;

  public get ribbonModel() {
    return this;
  }
  public get tableModel() {
    return this;
  }

  @computed
  public get loadingStatus() {
    return this.model.searchResultLoadingState;
  }

  @computed
  public get fromDate() {
    return this.model.fromDate;
  }

  @action.bound
  public onChangeFromDate(value: Date | undefined) {
    this.model.setFromDate(value);
  }

  @computed
  public get toDate() {
    return this.model.toDate;
  }

  @action.bound
  public onChangeToDate(value: Date | undefined) {
    this.model.setToDate(value);
  }

  @computed
  public get filterText() {
    return this.model.filterText;
  }
  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  public get filterForValidity() {
    return this.model.filterForValidity;
  }
  @action.bound
  public onChangeFilterForValidity(value: typeof TransDayPageValidityFilter.Type) {
    this.model.setFilterForValidity(value);
  }

  @computed
  public get includeManager() {
    return this.model.includeManager;
  }
  @action.bound
  public onChangeIncludeManager(value: boolean) {
    this.model.setIncludeManager(value);
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @computed
  public get rows() {
    return this.model.filteredEntries.map(it => new TransDayPageTableRowVMImpl(this.model, it)).filter(it => it.shows);
  }
}
