import { observer } from "mobx-react";
import React, { useCallback } from "react";

import { useAppStateModel } from "../../../models/useAppStateModel";
import { WkLinkContext } from "../../common/link/WkLinkContext";

export const LinkRouterProvider: React.FC = observer(({ children }) => {
  const model = useAppStateModel();
  const route = useCallback(
    (path: string) => {
      model.navigate(path);
    },
    [model],
  );
  return <WkLinkContext.Provider value={{ route }}>{children}</WkLinkContext.Provider>;
});
