import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverSixtyWeekdayMidnightKFProps = {
  model: MTotalOverSixtyWeekdayMidnightKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverSixtyWeekdayMidnightKFVM {
  mTotalOverSixtyWeekdayMidnight: KintaiInputItemState<Minutes>;
}

export const MTotalOverSixtyWeekdayMidnightKF = observer((props: MTotalOverSixtyWeekdayMidnightKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverSixtyWeekdayMidnight;

  return <HHMMField {...props} label="mTotalOverSixtyWeekdayMidnightKF" {...itemState} onChange={undefined} />;
});
