import React from "react";

import { PrivateDDTableData, PrivateDDUserValueRow } from "../../../../../models/statistics/prv/PrivateDDTableData";
import { WithLoading } from "../../../../../models/statistics/WithLoading";
import { paths } from "../../../../../routing/paths";
import { WkLink } from "../../../../common/link/WkLink";
import { statRound } from "../statRound";
import { PrvUserTable, PrvUserTableLoading, PrvUserTableNoData } from "./PrvUserTable";
import { PrvUserTableDataRow } from "./PrvUserTableDataRow";
import { PrvUserTableDepLink } from "./PrvUserTableDepLink";

export const PrvUserValueTable: React.FC<{
  data: WithLoading<PrivateDDTableData<PrivateDDUserValueRow>>;
  atMonth: Date;
  children?: never;
}> = ({ data, atMonth }) => {
  if (data.state === "loading") {
    return <PrvUserTableLoading />;
  }
  if (data.state === "no_data") {
    return <PrvUserTableNoData />;
  }

  return (
    <PrvUserTable>
      <thead>
        <tr>
          <th>#</th>
          <th>部門</th>
          <th>ランク</th>
          <th>社員番号</th>
          <th>氏名</th>
          <th>値 ({data.unit})</th>
        </tr>
      </thead>
      <tbody>
        {data.rows.length === 0 && (
          <tr>
            <td colSpan={6}>データがありません</td>
          </tr>
        )}
        {data.rows.map((it, idx) => (
          <PrvUserTableDataRow key={it.user.userId} className={it.warned ? "Warned" : undefined}>
            <td>{idx + 1}</td>
            <td>
              <PrvUserTableDepLink atMonth={atMonth} user={it.user} />
            </td>
            <td>{it.user.rankName}</td>
            <td>
              <WkLink
                href={paths.kintai
                  .ofUser(it.user.userId)
                  .ofDate(atMonth)
                  .index()}>
                {it.user.userId}
              </WkLink>
            </td>
            <td>{it.user.userName}</td>
            <td>{statRound(it.value)}</td>
          </PrvUserTableDataRow>
        ))}
      </tbody>
    </PrvUserTable>
  );
};
