import { CompanyNendoDepData } from "../../../../../schema/build/commonjs/main/typescript";
import { getNendo, getNendoMonthIndex, getNendoMonths } from "../../../utils/calendar";
import { haveSameMonth } from "../../../utils/date";

/**
 * 年度頭〜指定月までの部門データを並べる
 */
export const enumerateMonthDeps = function*(dep: CompanyNendoDepData, atMonth: Date) {
  const monthDates = getNendoMonths(getNendo(atMonth)).filter(
    it => getNendoMonthIndex(it) <= getNendoMonthIndex(atMonth),
  );

  for (const monthDate of monthDates) {
    const monthDep = dep.months.find(it => haveSameMonth(new Date(it.date), monthDate));

    // データ無し / ユーザ 0人は除外する
    if (!monthDep) {
      continue;
    }
    if (monthDep.userCount === 0) {
      continue;
    }

    yield {
      monthDate,
      monthDep,
    };
  }
};
