import styled from "@emotion/styled";
import React from "react";

import { PrivateDDTableData } from "../../../../../models/statistics/prv/PrivateDDTableData";
import { WithLoading } from "../../../../../models/statistics/WithLoading";

export const PrvUserTableWarningCounter: React.FC<{
  title: React.ReactNode;
  data: WithLoading<PrivateDDTableData<any>>;
}> = ({ title, data }) => {
  if (data.state !== undefined) {
    return <span>{title}</span>;
  }

  return (
    <span style={{ opacity: data.warningCount === 0 ? 0.5 : undefined }}>
      {title}
      <CounterContainer>({data.warningCount})</CounterContainer>
    </span>
  );
};

const CounterContainer = styled.span`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;
