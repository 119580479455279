import { Button, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { KintaiBunrui } from "@webkintai/bunrui";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { ErrorOverlay } from "../../../form/ErrorOverlay";
import { LabelField } from "../../../form/LabelField";
import { KintaiBunrui1Color } from "../../../KintaiBunrui1Color";
import { MonthlyCalendar } from "../../../MonthlyCalendar";

export type DateKFProps = {
  model: DateKFVM;
} & CellFormPrintAdaptable;

export interface DateKFVM {
  errors: string[];
  warnings: string[];
  infos: string[];
  date: Date;
  type1: KintaiBunrui;
  onNavigateToDate: (date: Date) => void;
}

const DateHeading = styled.div``;

const DateInterior = styled.div`
  padding-left: 1ex;
  padding-right: 1ex;
  font-weight: bold;
  text-align: center;
`;

const CalendarContainer = styled.div`
  padding: 1ex;
`;

export const DateKF = observer((props: DateKFProps) => {
  const { model } = props;

  if (props.mode === "form") {
    return (
      <DateHeading>
        <Popover
          content={
            <CalendarContainer>
              <MonthlyCalendar month={model.date} onNavigateToDate={model.onNavigateToDate} renderOtherMonth={false} />
            </CalendarContainer>
          }
          boundary="viewport">
          <ErrorOverlay mode="form" errors={model.errors} warnings={model.warnings} infos={model.infos}>
            <Button small={true} minimal={true} fill={true}>
              <DateInterior>
                <KintaiBunrui1Color date={model.date} kintaiBunrui={model.type1}>
                  {moment(model.date).format("MM/DD")}
                  <br />
                  {moment(model.date).format("(dd)")}
                </KintaiBunrui1Color>
              </DateInterior>
            </Button>
          </ErrorOverlay>
        </Popover>
      </DateHeading>
    );
  }

  return (
    <LabelField onClick={emptyHandler} {...props} errors={model.errors} warnings={model.warnings} infos={model.infos}>
      <KintaiBunrui1Color date={model.date} kintaiBunrui={model.type1}>
        {moment(model.date).format("DD (dd)")}
      </KintaiBunrui1Color>
    </LabelField>
  );
});

/** 単純にLabelFieldを cursor: pointer として表示するためだけのハック */
const emptyHandler = () => {
  // DO NOTHING
};
