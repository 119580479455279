import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MidnightKFProps = {
  model: MidnightKFVM;
} & CellFormPrintAdaptable;

export interface MidnightKFVM {
  midnight: KintaiInputItemState<Minutes | undefined>;
}

export const MidnightKF = observer((props: MidnightKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.midnight);

  return <HHMMField {...props} label="深夜時間" {...itemState} />;
});
