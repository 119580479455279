import { observer } from "mobx-react";
import React from "react";

import { DashboardPageScreenModes } from "../../models/dashboard/DashboardPageScreenModes";
import { PageTile } from "../common/page/PageTile";
import { ApplicationsPage, ApplicationsPageVM } from "./applications/ApplicationsPage";
import { ExportPage, ExportPageVM } from "./export/ExportPage";
import { DashboardSubMenu, DashboardSubMenuVM } from "./header/DashboardSubMenu";
import { InterviewListPage, InterviewListPageVM } from "./interview_list/InterviewListPage";
import { KintaiListPage, KintaiListPageVM } from "./kintai_list/KintaiListPage";
import { KintaiSumPage, KintaiSumPageVM } from "./kintai_sum/KintaiSumPage";
import { SaburokuPage, SaburokuPageVM } from "./saburoku/SaburokuPage";
import { StatisticsPage } from "./statistics/StatisticsPage";
import { TransDayPage, TransDayPageVM } from "./trans_day/TransDayPage";

export interface DashboardPageVM {
  pageMode: typeof DashboardPageScreenModes.Type;
  dashboardSubMenuVM: DashboardSubMenuVM;
  kintaiListPageVM: KintaiListPageVM;
  kintaiSumPageVM: KintaiSumPageVM;
  transDayPageVM: TransDayPageVM;
  applicationsPageVM: ApplicationsPageVM;
  saburokuPageVM: SaburokuPageVM;
  interviewListPageVM: InterviewListPageVM;
  exportPageVM: ExportPageVM;
}

@observer
export class DashboardPage extends React.Component<{ model: DashboardPageVM }> {
  public render() {
    const { model } = this.props;
    const { pageMode } = model;
    return (
      <PageTile header={<DashboardSubMenu model={model.dashboardSubMenuVM} />}>
        {pageMode === "statistics" && <StatisticsPage />}
        {pageMode === "kintai_list" && <KintaiListPage model={model.kintaiListPageVM} />}
        {pageMode === "kintai_sum" && <KintaiSumPage model={model.kintaiSumPageVM} />}
        {pageMode === "trans_day" && <TransDayPage model={model.transDayPageVM} />}
        {pageMode === "applications" && <ApplicationsPage model={model.applicationsPageVM} />}
        {pageMode === "saburoku" && <SaburokuPage model={model.saburokuPageVM} />}
        {pageMode === "interview_list" && <InterviewListPage model={model.interviewListPageVM} />}
        {pageMode === "export" && <ExportPage model={model.exportPageVM} />}
      </PageTile>
    );
  }
}
