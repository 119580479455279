import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalMidnightKFProps = {
  model: MTotalMidnightKFVM;
} & CellFormPrintAdaptable;

export interface MTotalMidnightKFVM {
  mTotalMidnight: KintaiInputItemState<Minutes>;
}

export const MTotalMidnightKF = observer((props: MTotalMidnightKFProps) => {
  const { model } = props;
  const itemState = model.mTotalMidnight;

  return <HHMMField {...props} label="mTotalMidnightKF" {...itemState} onChange={undefined} />;
});
