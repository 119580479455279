import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalTakenOtherDaysKFProps = {
  model: MTotalTakenOtherDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTakenOtherDaysKFVM {
  mTotalTakenOtherDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalTakenOtherDaysKF = observer((props: MTotalTakenOtherDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalTakenOtherDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalTakenOtherDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
