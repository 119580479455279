import { observer } from "mobx-react";
import React from "react";

import { DailyApplicationForm } from "../../applications/dailyform/DailyApplicationForm";
import { HavingDailyApplicationVM } from "../../applications/dailyform/DailyApplicationVM";

@observer
export class SPortDailyKintaiFormApplications extends React.Component<{ model: HavingDailyApplicationVM }> {
  public render() {
    const { props } = this;
    const { model } = props;
    return <DailyApplicationForm model={model.dailyApp} />;
  }
}
