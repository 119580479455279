import { action, computed } from "mobx";

import { SPortNendoGivenTimeMonth } from "../../../../models/sport/SPortNendoGivenTimeMonth";
import { SPortAdminPageVMImpl } from "../SPortAdminPageVMImpl";
import { SPortAdminPageGridMonthVM } from "./SPortAdminPageGrid";

export class SPortAdminPageGridVMImpl implements SPortAdminPageGridMonthVM {
  constructor(
    private readonly pageModel: SPortAdminPageVMImpl,
    private readonly model: typeof SPortNendoGivenTimeMonth.Type,
  ) {}

  @computed
  public get month() {
    return this.model.month;
  }
  @computed
  public get hasNoChange() {
    return this.model.hasNoChange;
  }
  @computed
  public get disabled() {
    return !this.pageModel.allowedToEdit;
  }

  @computed
  public get givenDays() {
    return this.model.givenDays;
  }

  @action.bound
  public onChangeGivenDays(value: number | undefined) {
    this.model.setGivenDays(value);
  }

  @computed
  public get givenHours() {
    return this.model.givenHours;
  }

  @action.bound
  public onChangeGivenHours(value: number | undefined) {
    this.model.setGivenHours(value);
  }
}
