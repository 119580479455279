import { UserAbsenceEntity } from "@webkintai/api";
import { types } from "mobx-state-tree";

export const LeaveKind = types.enumeration("LeaveKind", ["休職", "育休", "出勤停止", "積立休暇", "介護休業"]);

export const LeaveKindValues = ["休職", "育休", "出勤停止", "積立休暇", "介護休業"];

export type LeaveKindType = typeof LeaveKind.Type;

export const stringToLeaveKindType = (code: string): LeaveKindType | undefined => {
  return (LeaveKindValues as LeaveKindType[]).find(it => it === code);
};

export const leaveKindToApiAbsenceType = (value: LeaveKindType): UserAbsenceEntity.TypeEnum => {
  switch (value) {
    case "休職":
      return UserAbsenceEntity.TypeEnum.休職;
    case "出勤停止":
      return UserAbsenceEntity.TypeEnum.出勤停止;
    case "積立休暇":
      return UserAbsenceEntity.TypeEnum.積立休暇;
    case "育休":
      return UserAbsenceEntity.TypeEnum.育休;
    case "介護休業":
      return UserAbsenceEntity.TypeEnum.介休;
    default:
      throw Error(`Not supported leave kind: ${value}`);
  }
};

export const apiAbsenceTypeToLeaveKind = (value: UserAbsenceEntity.TypeEnum): LeaveKindType => {
  switch (value) {
    case UserAbsenceEntity.TypeEnum.休職:
      return "休職";
    case UserAbsenceEntity.TypeEnum.出勤停止:
      return "出勤停止";
    case UserAbsenceEntity.TypeEnum.積立休暇:
      return "積立休暇";
    case UserAbsenceEntity.TypeEnum.育休:
      return "育休";
    case UserAbsenceEntity.TypeEnum.介休:
      return "介護休業";
    default:
      throw Error(`Not supported leave kind: ${value}`);
  }
};
