import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { ScrollablePane } from "../../common/ScrollablePane";
import { TransDayPageRibbon, TransDayPageRibbonVM } from "./ribbon/TransDayPageRibbon";
import { TransDayPageTable, TransDayPageTableVM } from "./table/TransDayPageTable";

export interface TransDayPageVM {
  loadingStatus: LoadingStatusType;
  ribbonModel: TransDayPageRibbonVM;
  tableModel: TransDayPageTableVM;
  onReloadOnFailure: () => void;
}

@observer
export class TransDayPage extends React.Component<{ model: TransDayPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon ribbon={<TransDayPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("振替日チェック")}</title>
        </Helmet>

        <ScrollablePane>
          {model.loadingStatus !== "loaded" && (
            <LoadingStateSpread loadingStatus={model.loadingStatus} onReloadOnFailure={model.onReloadOnFailure} />
          )}
          {model.loadingStatus === "loaded" && <TransDayPageTable model={model.tableModel} />}
        </ScrollablePane>
      </PageRibbon>
    );
  }
}
