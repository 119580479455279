import { CompanyNendoDashboardResult, PersonalMonthlyDashboardResult, PersonalNendoDashboardResult } from "@webkintai/api";
import { injectable } from "inversify";

@injectable()
export abstract class DashboardApi {
  public abstract getCompanyDashboardByNendo(nendo: number): Promise<CompanyNendoDashboardResult>;
  public abstract getPersonalDashboardByNendo(userId: string, nendo: number): Promise<PersonalNendoDashboardResult>;
  public abstract getPersonalDashboardByMonth(
    userId: string,
    year: number,
    month: number,
  ): Promise<PersonalMonthlyDashboardResult>;
}
