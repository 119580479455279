import { types } from "mobx-state-tree";

import { idNendoCalendar } from "../NendoCalendar";
import { NendoCalendarInput, NendoCalendarInputType } from "./NendoCalendarInput";

const model = types.optional(
  types
    .model("NendoCalendarsInput", {
      calendars: types.map(NendoCalendarInput),
    })
    .actions(self => ({
      prepare(nendo: number): NendoCalendarInputType {
        const id = idNendoCalendar(nendo);
        const existingCalendar = self.calendars.get(id);
        if (existingCalendar) {
          return existingCalendar;
        }

        const calendar = self.calendars.put(
          NendoCalendarInput.create({
            id,
            nendo,
          }),
        );
        return calendar;
      },
    })),
  {},
);

export type NendoCalendarsInputType = typeof NendoCalendarsInput.Type;

export const NendoCalendarsInputSymbol = "NendoCalendarsInput_Symbol";
export const NendoCalendarsInput: NendoCalendarsInputModelType = model;
type NendoCalendarsInputInferredType = typeof model;
export interface NendoCalendarsInputModelType extends NendoCalendarsInputInferredType {}
