import { A勤, B1勤, B2勤, C勤, KintaiBunrui, 休出, 公休, 出張, 平日, 明休 } from "@webkintai/bunrui";
import React from "react";

const knownColourMap = {
  [平日.code]: "black",
  [出張.code]: "#7d2399",
  [休出.code]: "#229973",
  [A勤.code]: "#994721",
  [B1勤.code]: "#939920",
  [B2勤.code]: "#1f7e99",
  [C勤.code]: "#1e2399",
  [明休.code]: "#ba3223",
};

export const KintaiBunrui1Color = (props: { date: Date; kintaiBunrui: KintaiBunrui; children: React.ReactNode }) => (
  <span
    style={{
      color: (() => {
        const { kintaiBunrui } = props;
        const fixedColor = knownColourMap[kintaiBunrui.code];
        if (fixedColor) {
          return fixedColor;
        }
        if (kintaiBunrui === 公休) {
          switch (props.date.getDay()) {
            case 6:
              return "blue";
            case 0:
              return "red";
            default:
              return "#ff6633";
          }
        }
        return "green";
      })(),
    }}>
    {props.children}
  </span>
);
