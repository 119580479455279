import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalTakenPaidDaysKFProps = {
  model: MTotalTakenPaidDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTakenPaidDaysKFVM {
  mTotalTakenPaidDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalTakenPaidDaysKF = observer((props: MTotalTakenPaidDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalTakenPaidDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalTakenPaidDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
