const INJECTION = Symbol.for("INJECTION");

export function lazyInject(resolution: any) {
  return (proto: any, key: string) => {
    function getter() {
      if (window.webkintai_container) {
        return window.webkintai_container.get(resolution);
      } else {
        return undefined;
      }
    }
    function setter(this: any, newVal: any) {
      Reflect.defineMetadata(INJECTION, newVal, this, key);
    }

    Object.defineProperty(proto, key, {
      configurable: true,
      enumerable: true,
      get: getter,
      set: setter,
    });
  };
}
