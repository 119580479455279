import { Button, Intent } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/profile/inbox.md";
import { observer } from "mobx-react";
import React from "react";

import { CheckboxField } from "../../../common/form/CheckboxField";
import { ShrinkRibbonGuideButton } from "../../../common/guide/ShrinkRibbonGuideButton";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { FilterRibbon } from "../../../common/ribbon/FilterRibbon";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";

export interface InboxPageRibbonVM {
  unreadCount: number;
  onlyUnread: boolean;
  onChangeOnlyUnread: (value: boolean) => void;
  messageOpened: boolean;
  messageRead: boolean;
  onToggleRead: () => void;
  markAllAsReadDisabled: boolean;
  onMarkAllAsRead: () => void;
}

@observer
export class InboxPageRibbon extends React.Component<{ model: InboxPageRibbonVM; shrink?: boolean }> {
  public render() {
    const { model, shrink } = this.props;

    const onlyUnreadCheckbox = (
      <CheckboxField
        mode="form"
        label={`未読のみ表示 (${model.unreadCount} 件)`}
        value={model.onlyUnread}
        onChange={model.onChangeOnlyUnread}
      />
    );

    const swapUnreadButton = (
      <Button disabled={!model.messageOpened} intent={Intent.NONE} small={true} onClick={model.onToggleRead}>
        {model.messageRead ? "未読" : "既読"}
        にする
      </Button>
    );

    const markAllAsReadButton = (
      <Button
        icon="small-tick"
        disabled={model.markAllAsReadDisabled}
        intent={Intent.PRIMARY}
        small={true}
        onClick={model.onMarkAllAsRead}>
        すべて既読にする
      </Button>
    );

    if (shrink) {
      return (
        <>
          {onlyUnreadCheckbox}
          <ShrinkRibbonGuideButton markdown={Help} />
        </>
      );
    }

    return (
      <>
        <GuideRibbon markdown={Help} />
        <FilterRibbon>{onlyUnreadCheckbox}</FilterRibbon>
        <PageRibbonSection title="操作">{swapUnreadButton}</PageRibbonSection>
        <PageRibbonSection title="一括操作">{markAllAsReadButton}</PageRibbonSection>
      </>
    );
  }
}
