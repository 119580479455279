import { HourBasedDays } from "@webkintai/core";
import { action, computed } from "mobx";

import { PHEntry } from "../../../../models/ph/PHEntry";
import { PHPerYearVacationVM } from "./PHPageGrid";

export class PHPerYearVacationVMImpl implements PHPerYearVacationVM {
  constructor(private readonly model: typeof PHEntry.Type | undefined) {}

  @computed
  get hours() {
    return this.model ? this.model.inputPhDayCount : undefined;
  }

  @computed
  public get disabled() {
    return this.model ? !this.model.allowedToEdit : true;
  }

  @computed
  public get hasNoChange() {
    return this.model ? this.model.hasNoChange : false;
  }

  @action.bound
  public onChangeHours(value: HourBasedDays | undefined) {
    if (this.model) {
      this.model.setInputPhDayCount(value);
    }
  }
}
