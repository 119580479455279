import { computed } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { GridSize } from "../../../common/table/GridSize";
import { GridTable, x } from "../../../common/table/GridTable";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { TableColumnDef } from "../../../common/table/TableColumnDef";
import { SPortAdminGivenDaysCell } from "./SPortAdminGivenDaysCell";
import { SPortAdminGivenHoursCell } from "./SPortAdminGivenHoursCell";

export interface SPortAdminPageGridVM {
  months: SPortAdminPageGridMonthVM[];
}

export interface SPortAdminPageGridMonthVM {
  month: Date;
  hasNoChange: boolean;
  disabled: boolean;
  givenDays: number | undefined;
  onChangeGivenDays: (value: number | undefined) => void;
  givenHours: number | undefined;
  onChangeGivenHours: (value: number | undefined) => void;
}

@observer
export class SPortAdminPageGrid extends React.Component<{ model: SPortAdminPageGridVM }> {
  public render() {
    const { model } = this.props;
    return (
      <GridTable
        hideCount={true}
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        activeCell={NULL_CELL_POSITION}
        renderFooter={false}
        rowCount={12}
      />
    );
  }

  @computed
  private get headerColumns() {
    const { model } = this.props;
    return [
      {
        id: "月",
        width: x("XX XX"),
        renderHeader: () => "月",
        renderBody: ({ rowIndex }) => {
          const row = model.months[rowIndex - 1];
          return (
            <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
              {moment(row.month).format("MM 月")}
            </LabelField>
          );
        },
      },
    ] as TableColumnDef[];
  }

  @computed
  private get bodyColumns() {
    const { model } = this.props;
    return [
      {
        id: "所定就業日数",
        width: GridSize.dayCountField,
        renderHeader: () => (
          <>
            所定
            <br />
            就業
            <br />
            日数
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = model.months[rowIndex - 1];
          return <SPortAdminGivenDaysCell model={row} />;
        },
      },
      {
        id: "所定就業時間",
        width: GridSize.hourCountField,
        renderHeader: () => (
          <>
            <br />
            <br />
            時間
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = model.months[rowIndex - 1];
          return <SPortAdminGivenHoursCell model={row} />;
        },
      },
    ] as TableColumnDef[];
  }
}
