import { inputEnablerBunruiListMap, KintaiBunrui } from "@webkintai/bunrui";
import { applySnapshot, getParentOfType, types } from "mobx-state-tree";

import { MonthlyKintai } from "../MonthlyKintai";
import { RegularDailyKintai } from "./RegularDailyKintai";

export function regularItemState<T>(
  name: string,
  setterName: string,
  comparator: (l: T, r: T) => boolean,
  trueName?: string,
) {
  return types.optional(regularRawItemState(name, setterName, comparator, trueName), {});
}

export function regularRawItemState<T>(
  name: string,
  setterName: string,
  comparator: (l: T, r: T) => boolean,
  trueName?: string,
) {
  return types
    .model(name, {
      errors: types.array(types.string),
      warnings: types.array(types.string),
      infos: types.array(types.string),
    })
    .views(self => {
      return {
        get root() {
          return getParentOfType(self, MonthlyKintai);
        },
        get input() {
          return getParentOfType(self, RegularDailyKintai).input;
        },
        get computed() {
          return getParentOfType(self, RegularDailyKintai).computed;
        },
        get origin() {
          return getParentOfType(self, RegularDailyKintai).origin;
        },
      };
    })
    .views(self => {
      const has = (bunruiList: KintaiBunrui[]) =>
        bunruiList.reduce((b, bunrui) => b || !!self.input.types.find(t => t === bunrui), false);
      return {
        get disabled() {
          if (self.root.isRegularKintaiLocked) {
            return true;
          }
          return !has(inputEnablerBunruiListMap[trueName || name] || []);
        },
      };
    })
    .views(self => {
      return {
        get value(): T {
          return (self.disabled ? self.computed : self.input)[name];
        },
        get hasNoChange() {
          return self.disabled || comparator(self.origin[name], self.input[name]);
        },
      };
    })
    .actions(self => {
      const errorsOf = (itemName: string) => {
        return self.computed.errors
          .filter(it => it.hasField(itemName))
          .filter(it => it.isError)
          .map(it => it.message);
      };
      const warningsOf = (itemName: string) => {
        return self.computed.errors
          .filter(it => it.hasField(itemName))
          .filter(it => it.isWarning)
          .map(it => it.message);
      };
      const infosOf = (itemName: string) => {
        return self.computed.errors
          .filter(it => it.hasField(itemName))
          .filter(it => it.isInfo)
          .map(it => it.message);
      };

      return {
        onChange(value: T) {
          self.input[setterName](value);
        },
        applyErrors() {
          const errors = errorsOf(trueName || name);
          const warnings = warningsOf(trueName || name);
          const infos = infosOf(trueName || name);
          applySnapshot(self.errors, errors);
          applySnapshot(self.warnings, warnings);
          applySnapshot(self.infos, infos);
        },
      };
    });
}

export const boolEq = (l: boolean, r: boolean) => l === r;
