import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalSpWeekdayMidnightKFProps = {
  model: MTotalSpWeekdayMidnightKFVM;
} & CellFormPrintAdaptable;

export interface MTotalSpWeekdayMidnightKFVM {
  mTotalSpWeekdayMidnight: KintaiInputItemState<Minutes>;
}

export const MTotalSpWeekdayMidnightKF = observer((props: MTotalSpWeekdayMidnightKFProps) => {
  const { model } = props;
  const itemState = model.mTotalSpWeekdayMidnight;

  return <HHMMField {...props} label="mTotalSpWeekdayMidnightKF" {...itemState} onChange={undefined} />;
});
