import { isEqual, sortBy } from "lodash-es";
import { applySnapshot, getParentOfType, getSnapshot, IModelType, types } from "mobx-state-tree";

import { TimeProvider } from "../../../services/TimeProvider";
import { getNendoAsDate } from "../../../utils/calendar";
import { getDI } from "../../common/getDI";
import { prefixedIdType } from "../../utils";
import { NendoInCharges } from "./NendoInCharges";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoInCharge_";

const model = types
  .model("NendoInCharge", {
    id: prefixedIdType(idPrefix),

    nendo: types.number,
    depCode: types.string,

    selectedUserIds: types.array(types.string),
    org_selectedUserIds: types.array(types.string),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);
    const parent = () => getParentOfType(self, NendoInCharges);
    return {
      get hasNoChange() {
        return isEqual(sortBy(self.selectedUserIds), sortBy(self.org_selectedUserIds));
      },
      userIdIsSelected(userId: string) {
        return self.selectedUserIds.indexOf(userId) >= 0;
      },
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
      get usersInSuperiorDept() {
        return parent().usersCoveringDept(self.depCode);
      },
      get usersInDept() {
        return parent()
          .usersInDept(self.depCode)
          .filter(it => it.eligibleForAssignee);
      },
      get selectedUserRows() {
        return parent().usersSelected(self.depCode);
      },
    };
  })
  .actions(self => {
    return {
      selectUserId(userId: string) {
        if (self.userIdIsSelected(userId)) {
          return;
        }
        self.selectedUserIds.push(userId);
      },
      removeUserId(userId: string) {
        self.selectedUserIds.remove(userId);
      },
      toggleUserId(userId: string) {
        if (self.userIdIsSelected(userId)) {
          this.removeUserId(userId);
          return;
        }
        this.selectUserId(userId);
      },
      reset() {
        applySnapshot(self.selectedUserIds, getSnapshot(self.org_selectedUserIds));
      },
    };
  })
  .views(self => {
    return {};
  });

export const idNendoInCharge = (nendo: number, depCode: string) => `${idPrefix}${nendo}_${depCode}`;

export type NendoInChargeType = typeof NendoInCharge.Type;
export const NendoInCharge: NendoInChargeModelType = model;
type NendoInChargeInferredType = typeof model;
export interface NendoInChargeModelType extends NendoInChargeInferredType {}
