import React from "react";

import { PageRibbonSection } from "../page/PageRibbonSection";

export interface ScreenModeRibbonProps {
  title?: string;
}

export const ScreenModeRibbon: React.FunctionComponent<ScreenModeRibbonProps> = props => {
  return (
    <PageRibbonSection title={"画面モード" + (props.title === undefined ? "" : ": " + props.title)}>
      {props.children}
    </PageRibbonSection>
  );
};
