import { observer } from "mobx-react";
import React from "react";

import { getNendo } from "../../../../../utils/calendar";
import { MTotalAllNightCountKF } from "../../monthly/fields/MTotalAllNightCountKF";
import { MTotalAllNightOffDaysKF } from "../../monthly/fields/MTotalAllNightOffDaysKF";
import { MTotalAllNightOffKF } from "../../monthly/fields/MTotalAllNightOffKF";
import { MTotalCompDaysKF } from "../../monthly/fields/MTotalCompDaysKF";
import { MTotalGivenDaysKF } from "../../monthly/fields/MTotalGivenDaysKF";
import { MTotalGivenHoursKF } from "../../monthly/fields/MTotalGivenHoursKF";
import { MTotalHolidayDaysKF } from "../../monthly/fields/MTotalHolidayDaysKF";
import { MTotalHolidaySumKF } from "../../monthly/fields/MTotalHolidaySumKF";
import { MTotalLawBasisKF } from "../../monthly/fields/MTotalLawBasisKF";
import { MTotalLawOverCalcKF } from "../../monthly/fields/MTotalLawOverCalcKF";
import { MTotalLawOverKF } from "../../monthly/fields/MTotalLawOverKF";
import { MTotalLawOverNineKF } from "../../monthly/fields/MTotalLawOverNineKF";
import { MTotalLawOverTodayKF } from "../../monthly/fields/MTotalLawOverTodayKF";
import { MTotalLawOverUntilLastMonthSumKF } from "../../monthly/fields/MTotalLawOverUntilLastMonthSumKF";
import { MTotalLeaveDaysKF } from "../../monthly/fields/MTotalLeaveDaysKF";
import { MTotalLiquidateSumKF } from "../../monthly/fields/MTotalLiquidateSumKF";
import { MTotalMidnightKF } from "../../monthly/fields/MTotalMidnightKF";
import { MTotalNonAttendanceDaysKF } from "../../monthly/fields/MTotalNonAttendanceDaysKF";
import { MTotalNormalDaysKF } from "../../monthly/fields/MTotalNormalDaysKF";
import { MTotalOffKF } from "../../monthly/fields/MTotalOffKF";
import { MTotalOverFortyFiveUntilLastMonthCountKF } from "../../monthly/fields/MTotalOverFortyFiveUntilLastMonthCount";
import { MTotalOverHolidayKF } from "../../monthly/fields/MTotalOverHolidayKF";
import { MTotalOverHolidayMidnightKF } from "../../monthly/fields/MTotalOverHolidayMidnightKF";
import { MTotalOverKF } from "../../monthly/fields/MTotalOverKF";
import { MTotalOverSixtyFlexHolidayKF } from "../../monthly/fields/MTotalOverSixtyFlexHolidayKF";
import { MTotalOverSixtyHolidayKF } from "../../monthly/fields/MTotalOverSixtyHolidayKF";
import { MTotalOverSixtyHolidayMidnightKF } from "../../monthly/fields/MTotalOverSixtyHolidayMidnightKF";
import { MTotalOverSixtyLiquidateKF } from "../../monthly/fields/MTotalOverSixtyLiquidateKF";
import { MTotalOverSixtyWeekdayKF } from "../../monthly/fields/MTotalOverSixtyWeekdayKF";
import { MTotalOverSixtyWeekdayMidnightKF } from "../../monthly/fields/MTotalOverSixtyWeekdayMidnightKF";
import { MTotalOverWeekdayKF } from "../../monthly/fields/MTotalOverWeekdayKF";
import { MTotalOverWeekdayMidnightKF } from "../../monthly/fields/MTotalOverWeekdayMidnightKF";
import { MTotalSickoutDaysKF } from "../../monthly/fields/MTotalSickoutDaysKF";
import { MTotalSpHolidayKF } from "../../monthly/fields/MTotalSpHolidayKF";
import { MTotalSpHolidayMidnightKF } from "../../monthly/fields/MTotalSpHolidayMidnightKF";
import { MTotalSpWeekdayKF } from "../../monthly/fields/MTotalSpWeekdayKF";
import { MTotalSpWeekdayMidnightKF } from "../../monthly/fields/MTotalSpWeekdayMidnightKF";
import { MTotalSundayOnCountKF } from "../../monthly/fields/MTotalSundayOnCountKF";
import { MTotalTransOffDaysKF } from "../../monthly/fields/MTotalTransOffDaysKF";
import { MTotalTransOnDaysKF } from "../../monthly/fields/MTotalTransOnDaysKF";
import { MTotalWorkplaceSonotaDaysKF } from "../../monthly/fields/workplace/MTotalWorkplaceSonotaDaysKF";
import { MTotalWorkplaceSyussyaDaysKF } from "../../monthly/fields/workplace/MTotalWorkplaceSyussyaDaysKF";
import { MTotalWorkplaceZaitakuDaysKF } from "../../monthly/fields/workplace/MTotalWorkplaceZaitakuDaysKF";
import { RegularDailyItemsVisibility } from "../../visibility/RegularDailyItemsVisibility";
import { RegularPrintedItemsVisibility } from "../../visibility/RegularPrintedItemsVisibility";
import { VacantDayItemsVisibility } from "../../visibility/VacantDayItemsVisibility";
import { getVacancyTotalComponents } from "../common/getVacancyTotalComponents";
import { PrintedVacationsTotals } from "../common/PrintedVacationsTotals";
import { pfRowCname } from "../KintaiPrintFrame";
import { RegularPrintedKintaiVM } from "./RegularPrintedKintai";

const Row: React.FunctionComponent<{}> = props => <div className={pfRowCname}>{props.children}</div>;

@observer
export class RegularPrintedTotalTables extends React.Component<{ model: RegularPrintedKintaiVM }> {
  public render() {
    const { regularMonthlyTotal: total, regularRows, rankPosition, kintaiType, printDetailMode } = this.props.model;
    const date = regularRows[0].date;
    const nendo = getNendo(date);

    const visibility = new RegularDailyItemsVisibility(date, kintaiType, rankPosition);
    const printedItemVisibility = new RegularPrintedItemsVisibility(date, kintaiType);
    const vacantVisibility = new VacantDayItemsVisibility(date);

    const { vacancyTotalHeader, vacancyTotalSubHeader, vacancyTotalBody } = getVacancyTotalComponents({ date, total });

    return (
      <div>
        {/* 月集計値 */}
        <Row>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>
                  所定
                  <br />
                  就業
                </th>
                <th rowSpan={2}>
                  所定
                  <br />
                  時間
                </th>
                <th colSpan={2}>振替</th>
                <th rowSpan={2}>
                  平常
                  <br />
                  出勤
                </th>
                <th rowSpan={2}>
                  休日
                  <br />
                  出勤
                </th>
                <th rowSpan={2}>
                  日曜出勤
                  <br />
                  回数
                </th>
                {printedItemVisibility.midnightCount && (
                  <th rowSpan={2}>
                    徹夜
                    <br />
                    回数
                  </th>
                )}
                <th
                  rowSpan={2}
                  title={
                    vacantVisibility.sickoutDaysOrNonattendanceDays === "sickoutDays"
                      ? "休業など含む欠勤日数"
                      : "欠勤のみの日数"
                  }>
                  欠勤
                  <br />
                  日数
                </th>
                {vacantVisibility.leaveDays && (
                  <th rowSpan={2}>
                    休業
                    <br />
                    日数
                  </th>
                )}
                <th rowSpan={2}>
                  代休
                  <br />
                  日数
                </th>
                {printedItemVisibility.allnightOffDays && (
                  <th rowSpan={2}>
                    徹休
                    <br />
                    日数
                  </th>
                )}

                {vacancyTotalHeader}

                <th rowSpan={2}>
                  遅刻早退
                  <br />
                  外出
                </th>
                <th rowSpan={2}>
                  休日
                  <br />
                  時間
                </th>
                {printedItemVisibility.midnight && (
                  <th rowSpan={2}>
                    深夜
                    <br />
                    時間
                  </th>
                )}
                {printedItemVisibility.allNightOff && (
                  <th rowSpan={2}>
                    徹休
                    <br />
                    時間
                  </th>
                )}
                {printedItemVisibility.sp4Items && <th colSpan={4}>特別勤務時間</th>}
                {printedItemVisibility.liquidate && (
                  <th rowSpan={2}>
                    清算
                    <br />
                    時間
                  </th>
                )}
                {printedItemVisibility.over4Items && <th colSpan={4}>残業／深夜時間</th>}
              </tr>
              <tr>
                <th>出勤</th>
                <th>休日</th>

                {vacancyTotalSubHeader}

                {/* 4項目 */}
                <th>普通</th>
                <th>平日深夜</th>
                <th>休日</th>
                <th>休日深夜</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* 所定就業 */}
                <td>{<MTotalGivenDaysKF mode="print" model={total} />}</td>
                {/* 所定時間 */}
                <td>{<MTotalGivenHoursKF mode="print" model={total} />}</td>
                {/* 振替:出勤 */}
                <td>{<MTotalTransOnDaysKF mode="print" model={total} />}</td>
                {/* 振替:休日 */}
                <td>{<MTotalTransOffDaysKF mode="print" model={total} />}</td>
                {/* 平常出勤 */}
                <td>{<MTotalNormalDaysKF mode="print" model={total} />}</td>
                {/* 休日出勤 */}
                <td>{<MTotalHolidayDaysKF mode="print" model={total} />}</td>
                {/* 日曜出勤 */}
                <td>{<MTotalSundayOnCountKF mode="print" model={total} />}</td>
                {/* 徹夜回数 */}
                {printedItemVisibility.midnightCount && <td>{<MTotalAllNightCountKF mode="print" model={total} />}</td>}
                {vacantVisibility.sickoutDaysOrNonattendanceDays === "sickoutDays" ? (
                  /* (休業含)欠勤 */
                  <td>{<MTotalSickoutDaysKF mode="print" model={total} />}</td>
                ) : (
                  /* (欠勤)欠勤 */
                  <td>{<MTotalNonAttendanceDaysKF mode="print" model={total} />}</td>
                )}
                {/* 休業 */}
                {vacantVisibility.leaveDays && <td>{<MTotalLeaveDaysKF mode="print" model={total} />}</td>}
                {/* 代休 */}
                <td>{<MTotalCompDaysKF mode="print" model={total} />}</td>
                {/* 徹休 */}
                {printedItemVisibility.allnightOffDays && (
                  <td>{<MTotalAllNightOffDaysKF mode="print" model={total} />}</td>
                )}

                {vacancyTotalBody}

                {/* 遅刻早退外出 */}
                <td>
                  <MTotalOffKF mode="print" model={total} />
                </td>
                {/* 休日時間 */}
                <td>
                  <MTotalHolidaySumKF mode="print" model={total} />
                </td>
                {/* 深夜時間 */}
                {printedItemVisibility.midnight && (
                  <td>
                    <MTotalMidnightKF mode="print" model={total} />
                  </td>
                )}
                {/* 徹休時間 */}
                {printedItemVisibility.allNightOff && (
                  <td>
                    <MTotalAllNightOffKF mode="print" model={total} />
                  </td>
                )}
                {/* 特別勤務 */}
                {printedItemVisibility.sp4Items && (
                  <>
                    <td>
                      <MTotalSpWeekdayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalSpWeekdayMidnightKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalSpHolidayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalSpHolidayMidnightKF mode="print" model={total} />
                    </td>
                  </>
                )}
                {/* 清算時間 */}
                {printedItemVisibility.liquidate && (
                  <td>
                    <MTotalLiquidateSumKF mode="print" model={total} />
                  </td>
                )}
                {/* 残業／深夜時間 */}
                {printedItemVisibility.over4Items && (
                  <>
                    <td>
                      <MTotalOverWeekdayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverWeekdayMidnightKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverHolidayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverHolidayMidnightKF mode="print" model={total} />
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
          {visibility.workplace && (
            <table>
              <thead>
                <tr>
                  <th colSpan={3}>勤務場所ごと日数</th>
                </tr>
                <tr>
                  <th>在宅</th>
                  <th>通常出社</th>
                  <th>その他</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <MTotalWorkplaceZaitakuDaysKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalWorkplaceSyussyaDaysKF mode="print" model={total} />
                  </td>
                  <td>
                    <MTotalWorkplaceSonotaDaysKF mode="print" model={total} />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </Row>
        <Row>
          <table>
            <thead>
              <tr>
                {printedItemVisibility.overSixtyFlexLiquidates && <th colSpan={2}>60時間超時間外</th>}
                {printedItemVisibility.overSixty4Items && <th colSpan={4}>60時間超時間外</th>}
                <th rowSpan={2}>
                  時間外
                  <br />
                  時間数
                </th>
              </tr>
              <tr>
                {printedItemVisibility.overSixtyFlexLiquidates && (
                  <>
                    <th>休日</th>
                    <th>清算時間</th>
                  </>
                )}
                {printedItemVisibility.overSixty4Items && (
                  <>
                    <th>平日</th>
                    <th>平日深夜</th>
                    <th>休日</th>
                    <th>休日深夜</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              <tr>
                {printedItemVisibility.overSixtyFlexLiquidates && (
                  <>
                    <td>
                      <MTotalOverSixtyFlexHolidayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverSixtyLiquidateKF mode="print" model={total} />
                    </td>
                  </>
                )}
                {printedItemVisibility.overSixty4Items && (
                  <>
                    <td>
                      <MTotalOverSixtyWeekdayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverSixtyWeekdayMidnightKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverSixtyHolidayKF mode="print" model={total} />
                    </td>
                    <td>
                      <MTotalOverSixtyHolidayMidnightKF mode="print" model={total} />
                    </td>
                  </>
                )}
                <td>
                  <MTotalOverKF mode="print" model={total} />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>法定基準時間</th>
                <th colSpan={5}>健康管理時間</th>
                <th>45時間超／月回数</th>
              </tr>
              <tr>
                <th>今月</th>
                <th>今日迄</th>
                <th>9日迄</th>
                <th>当月予測</th>
                <th>前月迄（累計）</th>
                <th>前月迄（累計）</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <MTotalLawBasisKF mode="print" model={total} />
                </td>

                {/* 健康管理 */}
                <td>
                  <MTotalLawOverKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalLawOverTodayKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalLawOverNineKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalLawOverCalcKF mode="print" model={total} />
                </td>
                <td>
                  <MTotalLawOverUntilLastMonthSumKF mode="print" model={total} />
                </td>

                {/* 45時間 */}
                <td>
                  <MTotalOverFortyFiveUntilLastMonthCountKF mode="print" model={total} />
                </td>
              </tr>
            </tbody>
          </table>

          <PrintedVacationsTotals model={total} nendo={nendo} vv={vacantVisibility} />
        </Row>
        {printDetailMode !== "brief" && (
          <>
            <Row>※「健康管理時間」は従来の「法定残業時間」からの名称変更であり、集計方法は従来と変わりません。</Row>
            <Row>※「所定休日」は従来の「公休」からの名称変更であり、法定休日と法定外休日の両方を含んでいます。</Row>
            <Row>
              ※この帳票を印刷捺印して提出する必要はありません。各担当者がオンラインで査閲・承認を行ってください。
            </Row>
          </>
        )}
      </div>
    );
  }
}
