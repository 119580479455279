import { observer } from "mobx-react";
import React from "react";

import { DashboardPageScreenModes } from "../../../models/dashboard/DashboardPageScreenModes";
import { SubMenuButton } from "../../common/page/SubMenuButton";

export interface DashboardSubMenuVM {
  pageMode: typeof DashboardPageScreenModes.Type;

  showStatistics: boolean;
  onNavigateToStatistics: () => void;

  showKintaiList: boolean;
  onNavigateToKintaiList: () => void;

  showKintaiSum: boolean;
  onNavigateToKintaiSum: () => void;

  showTransDay: boolean;
  onNavigateToTransDay: () => void;

  showInterviewList: boolean;
  onNavigateToInterviewList: () => void;

  showSaburoku: boolean;
  onNavigateToSaburoku: () => void;

  showExport: boolean;
  onNavigateToExport: () => void;
}

@observer
export class DashboardSubMenu extends React.Component<{ model: DashboardSubMenuVM }> {
  public render() {
    const { model } = this.props;
    const { pageMode } = model;

    return (
      <>
        {model.showStatistics && (
          <SubMenuButton onClick={model.onNavigateToStatistics} active={pageMode === "statistics"} icon="chart">
            ダッシュボード
          </SubMenuButton>
        )}
        {model.showKintaiList && (
          <SubMenuButton onClick={model.onNavigateToKintaiList} active={pageMode === "kintai_list"} icon="chart">
            勤怠一覧
          </SubMenuButton>
        )}
        {model.showKintaiList && (
          <SubMenuButton onClick={model.onNavigateToKintaiSum} active={pageMode === "kintai_sum"} icon="calculator">
            勤怠集計
          </SubMenuButton>
        )}
        {model.showTransDay && (
          <SubMenuButton onClick={model.onNavigateToTransDay} active={pageMode === "trans_day"} icon="chart">
            振替日
          </SubMenuButton>
        )}
        {model.showInterviewList && (
          <SubMenuButton onClick={model.onNavigateToInterviewList} active={pageMode === "interview_list"} icon="pulse">
            産業医
          </SubMenuButton>
        )}
        {model.showSaburoku && (
          <SubMenuButton onClick={model.onNavigateToSaburoku} active={pageMode === "saburoku"} icon="take-action">
            三六
          </SubMenuButton>
        )}
        {/* <SubMenuButton
          onClick={model.onNavigateToApplications}
          active={pageMode === "applications"}
          
          
          icon="following">
          申請
        </SubMenuButton> */}
        {model.showExport && (
          <SubMenuButton onClick={model.onNavigateToExport} active={pageMode === "export"} icon="th">
            エクスポート
          </SubMenuButton>
        )}
      </>
    );
  }
}
