import { ButtonGroup } from "@blueprintjs/core";
import React from "react";

import { PageRibbonLine } from "../page/PageRibbonLine";
import { PageRibbonSection } from "../page/PageRibbonSection";
import { ResetButton } from "./ResetButton";
import { SaveButton } from "./SaveButton";

export interface SaveRibbonProps {
  small?: boolean;
  hasNoChange?: boolean;
  disabled?: boolean;
  saveLabel?: string;
  cancelLabel?: string;
  onSave?: () => void;
  saveDisabled?: boolean;
  useTimeoutConfirmReset?: boolean;
  onReset?: () => void;
  resetDisabled?: boolean;
}

export const SaveRibbon = (props: SaveRibbonProps) => {
  const saveButton = <SaveButton {...props} disabled={props.disabled || props.saveDisabled} />;
  const resetButton = (
    <ResetButton
      {...props}
      disabled={props.disabled || props.resetDisabled}
      useTimeoutConfirm={props.useTimeoutConfirmReset}
    />
  );

  if (props.small) {
    return (
      <ButtonGroup>
        {resetButton}
        {saveButton}
      </ButtonGroup>
    );
  }

  const title = props.hasNoChange === undefined ? "保存" : props.hasNoChange ? "保存(変更無し)" : "*保存(未保存)";

  return (
    <PageRibbonSection title={title}>
      {props.onSave && <PageRibbonLine>{saveButton}</PageRibbonLine>}
      {props.onReset && <PageRibbonLine>{resetButton}</PageRibbonLine>}
    </PageRibbonSection>
  );
};
