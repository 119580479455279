import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";
import { useState } from "react";
import { useCallback } from "react";
import ReactMarkdown from "react-markdown/with-html";

import { WkDrawer } from "../drawer/WkDrawer";
import { mediaQuerySmall } from "../responsive/Responsive";

export interface GuideButtonProps {
  className?: string;
  children?: React.ReactNode;
  markdown?: string;
  buttonProps?: JSX.LibraryManagedAttributes<typeof Button, Button["props"]>;
}

const Container = styled.div`
  padding: 1ex 1em 1ex 1em;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  h1 {
    font-size: 18pt;
    border-bottom: 1px solid #ccc;
    text-align: center;
    margin: 0;
  }

  img {
    display: block;
    text-align: center;

    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    margin-bottom: 1em;

    max-width: 100%;
    max-height: 400px;

    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  }

  ${mediaQuerySmall} {
    td,
    th {
      padding: 0.4ex !important;
    }
  }
`;

const CloseArea = styled.div`
  border-bottom: 1px solid #ccc;
`;

export const GuideButton = (props: GuideButtonProps) => {
  const [opened, setOpened] = useState(false);
  const onOpen = useCallback(() => {
    setOpened(true);
  }, []);
  const onClose = useCallback(() => {
    setOpened(false);
  }, []);

  const content =
    props.markdown !== undefined ? (
      <ReactMarkdown linkTarget="_blank" source={props.markdown.replace(/---(.|\r|\n)+?---/, "")} escapeHtml={false} />
    ) : (
      props.children
    );

  const button = (
    <Button
      className={props.className}
      small={true}
      icon="help"
      onClick={onOpen}
      {...(props.buttonProps ? props.buttonProps : {})}
    />
  );

  return (
    <>
      {button}
      <WkDrawer isOpen={opened} onClose={onClose}>
        <Container className="bp3-running-text">{content}</Container>
      </WkDrawer>
    </>
  );
};
