import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLawOverKFProps = {
  model: MTotalLawOverKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLawOverKFVM {
  mTotalLawOver: KintaiInputItemState<Minutes>;
}

export const MTotalLawOverKF = observer((props: MTotalLawOverKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLawOver;

  return <HHMMField {...props} label="mTotalLawOverKF" {...itemState} onChange={undefined} />;
});
