import { action, computed } from "mobx";

import { AccountsPageModel } from "../../../../models/accounts/AccountsPageModel";
import { Role } from "../../../../models/roles/Role";
import { AcRoleHeaderVM } from "./AcRoleHeader";

export class AcRoleHeaderVMImpl implements AcRoleHeaderVM {
  constructor(private readonly model: typeof AccountsPageModel.Type, private readonly role: typeof Role.Type) {}

  @computed
  get roleCode() {
    return this.role.roleCode;
  }

  @computed
  get roleName() {
    return this.role.roleName;
  }

  @action.bound
  public onToggleRoleFilter() {
    this.model.toggleRoleFilter(this.roleCode);
  }

  @computed
  get filtered() {
    return this.model.isRoleFiltered(this.roleCode);
  }
}
