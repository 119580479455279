import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { SPortAdminPageGrid, SPortAdminPageGridVM } from "./grid/SPortAdminPageGrid";
import { SPortAdminPageRibbon, SPortAdminPageRibbonVM } from "./SPortAdminPageRibbon";

export interface SPortAdminPageVM {
  nendo: number;
  loadingStatus: LoadingStatusType;
  ribbonModel: SPortAdminPageRibbonVM;
  gridModel: SPortAdminPageGridVM;
}

@observer
export class SPortAdminPage extends React.Component<{ model: SPortAdminPageVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    return (
      <PageRibbon ribbon={<SPortAdminPageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("S-Port管理")}</title>
        </Helmet>

        {loadingStatus !== "loaded" && <GridTableLoading />}
        {loadingStatus === "loaded" && <SPortAdminPageGrid key={model.nendo} model={model.gridModel} />}
      </PageRibbon>
    );
  }
}
