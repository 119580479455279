import { observer } from "mobx-react";
import React from "react";

import { CheckboxField } from "../../../common/form/CheckboxField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RoleColumn } from "./AccountsPageGrid";

export interface AcRoleCellVM {
  roleValues: RoleValues[];
  rolesHasNoChange: boolean;
  onOpenPasswordInitDialog: () => void;
}

export interface RoleValues {
  roleCode: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

@observer
export class AcRoleCell extends React.Component<{ model: AcRoleCellVM; roleCol: RoleColumn }> {
  public render() {
    const { model, roleCol } = this.props;
    const val = model.roleValues.find(roleValue => roleValue.roleCode === roleCol.roleCode)!;

    return (
      <CheckboxField
        mode="cell"
        hasNoChange={model.rolesHasNoChange}
        cellPosition={NULL_CELL_POSITION}
        value={val.checked}
        onChange={val.onChange}
      />
    );
  }
}
