import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { TextField } from "../../../form/TextField";

export type AppPostTextKFProps = {
  model: AppPostTextKFVM;
} & CellFormPrintAdaptable;

export interface AppPostTextKFVM {
  appPostText: KintaiInputItemState<string>;
  appPostTextIsRequired: boolean;
}

/**
 * 勤怠申請テキスト
 */
export const AppPostTextKF = observer((props: AppPostTextKFProps) => {
  const { model } = props;
  const itemState = model.appPostText;

  return (
    <TextField
      multiline={true}
      {...props}
      label={`勤怠申請理由${model.appPostTextIsRequired ? "（必須）" : ""}`}
      {...itemState}
    />
  );
});
