import { injectable } from "inversify";

@injectable()
export abstract class LoginTokenStore {
  public abstract setRememberLogin(remember: boolean): void;

  public abstract save(token: string): void;

  public abstract load(): string | null;

  public abstract discard(): void;
}
