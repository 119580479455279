import { computed } from "mobx";

import { MonthlyKintai } from "../../../../../models/kintai/MonthlyKintai";
import { RegularMonthlyTotalVM } from "./RegularMonthlyTotalVM";

export class RegularMonthlyTotalVMImpl implements RegularMonthlyTotalVM {
  constructor(private readonly rootModel: typeof MonthlyKintai.Type) {}

  private get model() {
    return this.rootModel.regularKintai!.total;
  }

  private get vmodel() {
    return this.rootModel.vacationTotal!;
  }

  private get pjReport() {
    return this.rootModel.pjReport!;
  }

  @computed
  get mTotalLeaveDays() {
    return this.model.mTotalLeaveDays;
  }

  @computed
  get mTotalAbsenceDays() {
    return this.model.mTotalAbsenceDays;
  }
  @computed
  get mTotalActual() {
    return this.model.mTotalActual;
  }
  @computed
  get mTotalAllNightCount() {
    return this.model.mTotalAllNightCount;
  }
  @computed
  get mTotalAllNightOff() {
    return this.model.mTotalAllNightOff;
  }
  @computed
  get mTotalAllNightOffDays() {
    return this.model.mTotalAllNightOffDays;
  }
  @computed
  get mTotalCareDays() {
    return this.model.mTotalCareDays;
  }
  @computed
  get mTotalChildcareDays() {
    return this.model.mTotalChildcareDays;
  }
  @computed
  get mTotalCompDays() {
    return this.model.mTotalCompDays;
  }
  @computed
  get mTotalDiff() {
    return this.model.mTotalDiff;
  }
  @computed
  get mTotalHourlyLeave() {
    return this.model.mTotalHourlyLeave;
  }
  @computed
  get mTotalHourlyNursingcare() {
    return this.model.mTotalHourlyNursingcare;
  }
  @computed
  get mTotalHourlyFamilycare() {
    return this.model.mTotalHourlyFamilycare;
  }
  @computed
  get mTotalFamilycareDays() {
    return this.model.mTotalFamilycareDays;
  }
  @computed
  get mTotalFlex() {
    return this.model.mTotalFlex;
  }
  @computed
  get mTotalGivenDays() {
    return this.model.mTotalGivenDays;
  }
  @computed
  get mTotalGivenHours() {
    return this.model.mTotalGivenHours;
  }
  @computed
  get mTotalHoliday() {
    return this.model.mTotalHoliday;
  }
  @computed
  get mTotalHolidayDays() {
    return this.model.mTotalHolidayDays;
  }
  @computed
  get mTotalHolidaySum() {
    return this.model.mTotalHolidaySum;
  }
  @computed
  get mTotalInjuriesDays() {
    return this.model.mTotalInjuriesDays;
  }
  @computed
  get mTotalJudgmentDays() {
    return this.model.mTotalJudgmentDays;
  }
  @computed
  get mTotalLawBasis() {
    return this.model.mTotalLawBasis;
  }
  @computed
  get mTotalLawOver() {
    return this.model.mTotalLawOver;
  }
  @computed
  get mTotalOverFortyFiveUntilLastMonthCount() {
    return this.model.mTotalOverFortyFiveUntilLastMonthCount;
  }
  @computed
  get mTotalLawOverCalc() {
    return this.model.mTotalLawOverCalc;
  }
  @computed
  get mTotalLawOverNine() {
    return this.model.mTotalLawOverNine;
  }
  @computed
  get mTotalLawOverNendoSum() {
    return this.model.mTotalLawOverNendoSum;
  }
  @computed
  get mTotalLawOverToday() {
    return this.model.mTotalLawOverToday;
  }
  @computed
  get mTotalLawOverUntilLastMonthSum() {
    return this.model.mTotalLawOverUntilLastMonthSum;
  }
  @computed
  get mTotalLiquidate() {
    return this.model.mTotalLiquidate;
  }
  @computed
  get mTotalLiquidateSum() {
    return this.model.mTotalLiquidateSum;
  }
  @computed
  get mTotalMaternityDays() {
    return this.model.mTotalMaternityDays;
  }
  @computed
  get mTotalMenstruationDays() {
    return this.model.mTotalMenstruationDays;
  }
  @computed
  get mTotalMidnight() {
    return this.model.mTotalMidnight;
  }
  @computed
  get mTotalMidnightCount() {
    return this.model.mTotalMidnightCount;
  }
  @computed
  get mTotalNonAttendanceDays() {
    return this.model.mTotalNonAttendanceDays;
  }
  @computed
  get mTotalNormalDays() {
    return this.model.mTotalNormalDays;
  }
  @computed
  get mTotalNursingcareDays() {
    return this.model.mTotalNursingcareDays;
  }
  @computed
  get mTotalOff() {
    return this.model.mTotalOff;
  }
  @computed
  get mTotalOffCount() {
    return this.model.mTotalOffCount;
  }
  @computed
  get mTotalOver() {
    return this.model.mTotalOver;
  }
  @computed
  get mTotalOverHoliday() {
    return this.model.mTotalOverHoliday;
  }
  @computed
  get mTotalOverHolidayMidnight() {
    return this.model.mTotalOverHolidayMidnight;
  }
  @computed
  get mTotalOverSixtyFlexHoliday() {
    return this.model.mTotalOverSixtyFlexHoliday;
  }
  @computed
  get mTotalOverSixtyHoliday() {
    return this.model.mTotalOverSixtyHoliday;
  }
  @computed
  get mTotalOverSixtyHolidayMidnight() {
    return this.model.mTotalOverSixtyHolidayMidnight;
  }
  @computed
  get mTotalOverSixtyLiquidate() {
    return this.model.mTotalOverSixtyLiquidate;
  }
  @computed
  get mTotalOverSixtyWeekday() {
    return this.model.mTotalOverSixtyWeekday;
  }
  @computed
  get mTotalOverSixtyWeekdayMidnight() {
    return this.model.mTotalOverSixtyWeekdayMidnight;
  }
  @computed
  get mTotalOverSum() {
    return this.model.mTotalOverNendoSum;
  }
  @computed
  get mTotalOverWeekday() {
    return this.model.mTotalOverWeekday;
  }
  @computed
  get mTotalOverWeekdayMidnight() {
    return this.model.mTotalOverWeekdayMidnight;
  }
  @computed
  get mTotalPaidDays() {
    return this.model.mTotalPaidDays;
  }
  @computed
  get mTotalPlainPaidDays() {
    return this.model.mTotalPlainPaidDays;
  }
  @computed
  get mTotalOtherDays() {
    return this.model.mTotalOtherDays;
  }
  @computed
  get mTotalOtherDaysExcludingNuFa() {
    return this.model.mTotalOtherDaysExcludingNuFa;
  }
  @computed
  get mTotalOtherHours() {
    return this.model.mTotalOtherHours;
  }
  @computed
  get mTotalPaidAllDays() {
    return this.model.mTotalPaidAllDays;
  }
  @computed
  get mTotalPaidHalfDays() {
    return this.model.mTotalPaidHalfDays;
  }
  @computed
  get mTotalPositiveDays() {
    return this.model.mTotalPositiveDays;
  }
  @computed
  get mTotalRefreshDays() {
    return this.model.mTotalRefreshDays;
  }
  @computed
  get mTotalRest() {
    return this.model.mTotalRest;
  }
  @computed
  get mTotalSickoutDays() {
    return this.model.mTotalSickoutDays;
  }
  @computed
  get mTotalSpDays() {
    return this.model.mTotalSpDays;
  }
  @computed
  get mTotalSpHoliday() {
    return this.model.mTotalSpHoliday;
  }
  @computed
  get mTotalSpHolidayMidnight() {
    return this.model.mTotalSpHolidayMidnight;
  }
  @computed
  get mTotalSpWeekday() {
    return this.model.mTotalSpWeekday;
  }
  @computed
  get mTotalSpWeekdayMidnight() {
    return this.model.mTotalSpWeekdayMidnight;
  }
  @computed
  get mTotalSummerDays() {
    return this.model.mTotalSummerDays;
  }
  @computed
  get mTotalSundayOnCount() {
    return this.model.mTotalSundayOnCount;
  }
  @computed
  get mTotalSuspensionDays() {
    return this.model.mTotalSuspensionDays;
  }
  @computed
  get mTotalTransHolidays() {
    return this.model.mTotalTransHolidays;
  }
  @computed
  get mTotalTransOffDays() {
    return this.model.mTotalTransOffDays;
  }
  @computed
  get mTotalTransOnDays() {
    return this.model.mTotalTransOnDays;
  }
  @computed
  get mTotalWorkplaceZaitakuDays() {
    return this.model.mTotalWorkplaceZaitakuDays;
  }
  @computed
  get mTotalWorkplaceSyussyaDays() {
    return this.model.mTotalWorkplaceSyussyaDays;
  }
  @computed
  get mTotalWorkplaceSonotaDays() {
    return this.model.mTotalWorkplaceSonotaDays;
  }
  @computed
  get PJRFooterActualTime() {
    return this.pjReport.totalPillar.actual;
  }

  @computed
  get PJRFooterNotPopulatedTime() {
    return this.pjReport.totalPillar.notPopulated;
  }

  @computed
  get mTotalTakenRefreshDays() {
    return this.vmodel.mTotalTakenRefreshDays;
  }
  @computed
  get mTotalTakenPositiveDays() {
    return this.vmodel.mTotalTakenPositiveDays;
  }
  @computed
  get mTotalTakenPaidDays() {
    return this.vmodel.mTotalTakenPaidDays;
  }
  @computed
  get mTotalTakenPlainPaidDays() {
    return this.vmodel.mTotalTakenPlainPaidDays;
  }
  @computed
  get mTotalTakenHourlyLeave() {
    return this.vmodel.mTotalTakenHourlyLeave;
  }
  @computed
  get mTotalTakenNursingcareDays() {
    return this.vmodel.mTotalTakenNursingcareDays;
  }
  @computed
  get mTotalTakenHourlyNursingcare() {
    return this.vmodel.mTotalTakenNursingcareHourlyLeave;
  }
  @computed
  get mTotalTakenFamilycareDays() {
    return this.vmodel.mTotalTakenFamilycareDays;
  }
  @computed
  get mTotalTakenHourlyFamilycare() {
    return this.vmodel.mTotalTakenFamilycareHourlyLeave;
  }
  @computed
  get mTotalTakenSummerDays() {
    return this.vmodel.mTotalTakenSummerDays;
  }
  @computed
  get mTotalTakenSpDays() {
    return this.vmodel.mTotalTakenSpDays;
  }
  @computed
  get mTotalTakenOtherDays() {
    return this.vmodel.mTotalTakenOtherDays;
  }
  @computed
  get mTotalTakenOtherDaysExcludingNuFa() {
    return this.vmodel.mTotalTakenOtherDaysExcludingNuFa;
  }
  @computed
  get mTotalTakenOtherHours() {
    return this.vmodel.mTotalTakenOtherHours;
  }
  @computed
  get mTotalRestRefreshDays() {
    return this.vmodel.mTotalRestRefreshDays;
  }
  @computed
  get mTotalRestPositiveDays() {
    return this.vmodel.mTotalRestPositiveDays;
  }
  @computed
  get mTotalRestPaidDays() {
    return this.vmodel.mTotalRestPaidDays;
  }
  @computed
  get mTotalRestSummerDays() {
    return this.vmodel.mTotalRestSummerDays;
  }
}
