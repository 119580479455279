import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type OverWeekdayKFProps = {
  model: OverWeekdayKFVM;
} & CellFormPrintAdaptable;

export interface OverWeekdayKFVM {
  overWeekday: KintaiInputItemState<Minutes | undefined>;
}

export const OverWeekdayKF = observer((props: OverWeekdayKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.overWeekday);

  return <HHMMField {...props} label="平日 残業" {...itemState} />;
});
