import { getParentOfType, IModelType, types } from "mobx-state-tree";

import { dateEq } from "../../utils/date";
import { hasNoChangeReduce } from "../../utils/model";
import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { typesMinutes } from "../primitives/Minutes";
import { NendoInterviewList } from "./NendoInterviewList";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoFatigueCheckListEntry_";

const model = types
  .model("NendoFatigueCheckListEntry", {
    id: types.string,
    nendo: types.number,
    targetMonth: types.Date,
    userId: types.string,

    userName: types.string,
    depCode: types.string,
    depName: types.string,
    rankName: types.string,
    lawOver: typesMinutes,
    sundayOnCount: types.number,
    reservedDate: types.maybe(types.Date),

    acceptStatus: types.boolean,
    org_acceptStatus: types.boolean,
    submitDate: types.maybe(types.Date),
    org_submitDate: types.maybe(types.Date),
    note: types.string,
    org_note: types.string,
  })
  .views(self => {
    return {
      get searchedWords() {
        return [self.userId, self.userName, self.depCode, self.depName, self.rankName];
      },
    };
  })
  .views(self => {
    return {
      get hasNoChange() {
        return hasNoChangeReduce([
          self.acceptStatus === self.org_acceptStatus,
          dateEq(self.submitDate, self.org_submitDate),
          self.note === self.org_note,
        ]);
      },
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(self.searchedWords, searchWords);
      },
      get errors() {
        return [];
      },
    };
  })
  .actions(self => {
    const addNewInterview = () => {
      getParentOfType(self, NendoInterviewList).addNewInterview(self as any);
    };

    return {
      addNewInterview,
      setAcceptStatus(value: boolean) {
        self.acceptStatus = value;
      },
      setSubmitDate(value: Date | undefined) {
        self.submitDate = value;
      },
      setNote(value: string) {
        self.note = value;
      },
      reset() {
        self.acceptStatus = self.org_acceptStatus;
        self.submitDate = self.org_submitDate;
        self.note = self.org_note;
      },
    };
  });

export const idNendoNendoFatigueCheckListEntry = (nendo: number, targetMonth: Date, userId: string) =>
  `${idPrefix}${nendo}_${targetMonth.toISOString()}_${userId}`;

export const NendoFatigueCheckListEntrySymbol = "NendoFatigueCheckListEntry_Symbol";
export const NendoFatigueCheckListEntry: NendoFatigueCheckListEntryModelType = model;
type NendoFatigueCheckListEntryInferredType = typeof model;
export interface NendoFatigueCheckListEntryModelType extends NendoFatigueCheckListEntryInferredType {}
