import { WKSUserCommonAttributes } from "@webkintai/schema";
import React from "react";

import { paths } from "../../../../../routing/paths";
import { WkLink } from "../../../../common/link/WkLink";

export const PrvUserTableDepLink: React.FC<{
  atMonth: Date;
  user: WKSUserCommonAttributes;
  children?: never;
}> = ({ atMonth, user }) => {
  if (!user.depCode) {
    return <>{user.depName || ""}</>;
  }

  return (
    <WkLink href={paths.dashboard.kintai_list.dept.ofDate(atMonth).search(user.depCode)}>{user.depName || ""}</WkLink>
  );
};
