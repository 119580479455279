import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalSpHolidayKFProps = {
  model: MTotalSpHolidayKFVM;
} & CellFormPrintAdaptable;

export interface MTotalSpHolidayKFVM {
  mTotalSpHoliday: KintaiInputItemState<Minutes>;
}

export const MTotalSpHolidayKF = observer((props: MTotalSpHolidayKFProps) => {
  const { model } = props;
  const itemState = model.mTotalSpHoliday;

  return <HHMMField {...props} label="mTotalSpHolidayKF" {...itemState} onChange={undefined} />;
});
