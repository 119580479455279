import { GivenWorkTimeResult } from "@webkintai/api";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";
import moment from "moment";

import { SPortAdminApi } from "../../services/api/SPortAdminApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendoAsDate, getNendoMonths } from "../../utils/calendar";
import { hasNoChangeReduce } from "../../utils/model";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { prefixedIdType } from "../utils";
import { SPortNendoGivenTimeMonth } from "./SPortNendoGivenTimeMonth";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "SPNGT_";

const model = types
  .model("SPortNendoGivenTimes", {
    id: prefixedIdType(idPrefix),
    nendo: types.number,
    months: types.array(SPortNendoGivenTimeMonth),
    loadingStatus: types.optional(LoadingStatus, "loading"),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);
    return {
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
      get hasNoChange() {
        return hasNoChangeReduce(self.months.map(it => it.hasNoChange));
      },
    };
  })
  .actions(self => {
    const sportAdminApi = () => getDI(self, SPortAdminApi);
    const appNotifier = () => getDI(self, AppNotifier);

    const save = flow(function*() {
      self.loadingStatus = "loading";
      let progress = "";

      try {
        const api = sportAdminApi();
        for (const month of self.months) {
          progress = `${moment(month.month).format("YYYY年MM月")}`;
          yield api.saveGivenWorktime(month.month, {
            givenDays: month.givenDays
              ? {
                  days: month.givenDays,
                }
              : undefined,
            givenHours: month.givenHours
              ? {
                  minutes: month.givenHours * 60,
                }
              : undefined,
          });
        }
        appNotifier().info({ message: "S-Portの就業日数の設定を保存しました" });
      } catch (exception) {
        appNotifier().error({ message: `S-Portの就業日数の保存に失敗しました: 部分: ${progress}`, exception });
      } finally {
        self.loadingStatus = "loaded";
      }

      load();
    });

    const loadIfNeeded = () => {
      return self.loadingStatus === "loaded" ? new Promise(done => done()) : load();
    };

    const load = flow(function*() {
      self.loadingStatus = "loading";
      const api = sportAdminApi();

      const result = (yield Promise.all(
        getNendoMonths(self.nendo).map(nendoMonth => {
          return api.getGivenWorktime(nendoMonth).catch(reason => null);
        }),
      )) as GivenWorkTimeResult[];

      applySnapshot(
        self.months,
        getNendoMonths(self.nendo).map((month, idx) => {
          if (result[idx] && result[idx].givenWorkTime) {
            const givenWorkTime = result[idx].givenWorkTime!;

            const givenDays = givenWorkTime.givenDays ? givenWorkTime.givenDays.days : undefined;
            const givenHours = givenWorkTime.givenHours ? Math.floor(givenWorkTime.givenHours.minutes / 60) : undefined;
            return {
              month,
              givenDays,
              givenHours,
              org_givenDays: givenDays,
              org_givenHours: givenHours,
            };
          } else {
            return {
              month,
            };
          }
        }),
      );

      self.loadingStatus = "loaded";
    });

    return {
      load,
      loadIfNeeded,
      save,
      reset() {
        load();
      },
    };
  });
export const idSPortNendoGivenTimes = (nendo: number) => `${idPrefix}${nendo}`;

export type SPortNendoGivenTimesType = typeof SPortNendoGivenTimes.Type;

export const SPortNendoGivenTimesSymbol = "SPortNendoGivenTimes_Symbol";
export const SPortNendoGivenTimes: SPortNendoGivenTimesModelType = model;
type SPortNendoGivenTimesInferredType = typeof model;
export interface SPortNendoGivenTimesModelType extends SPortNendoGivenTimesInferredType {}
