import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { HHMMField } from "../../../form/HHMMField";

export interface PJRFooterNotPopulatedTimeKFProps {
  model: PJRFooterNotPopulatedTimeKFVM;
}

export interface PJRFooterNotPopulatedTimeKFVM {
  PJRFooterNotPopulatedTime: Minutes | undefined;
}

export const PJRFooterNotPopulatedTimeKF = observer((props: PJRFooterNotPopulatedTimeKFProps) => {
  const { model } = props;

  return <HHMMField mode="print" value={model.PJRFooterNotPopulatedTime} />;
});
