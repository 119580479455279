import { NotificationsResult } from "@webkintai/api";
import { keyBy } from "lodash-es";
import { applySnapshot, flow, IModelType, types } from "mobx-state-tree";

import { NotificationsApi } from "../../services/api/NotificationsApi";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getDI } from "../common/getDI";
import { Notification } from "./Notification";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("Notifications", {
      notifications: types.map(Notification),
    })
    .views(self => ({
      get unreadCount() {
        return [...self.notifications.values()].filter(notif => !notif.read).length;
      },
    }))
    .actions(self => {
      const notificationsApi = () => getDI(self, NotificationsApi);
      const appNotifier = () => getDI(self, AppNotifier);

      const load = flow(function*() {
        const result: NotificationsResult = yield notificationsApi().getNotifications();
        const data = Array.from(result.notifications, notif => ({
          id: `${notif.id}`,
          subject: notif.subject,
          read: notif.read,
          publishDate: new Date(notif.publishDate),
          // body は移し替える
          ...(() => {
            const existing = self.notifications.get(`${notif.id}`);
            return existing ? { body: existing.body, bodyLoadingState: existing.bodyLoadingState } : {};
          })(),
        }));
        applySnapshot(
          self.notifications,
          keyBy(data, ent => ent.id),
        );
      });

      const markAllAsRead = flow(function*() {
        yield notificationsApi().setRead(
          [...self.notifications.values()].map(notif => ({ id: +notif.id, read: true })),
        );
        yield load();
        appNotifier().info({ message: "全て既読にしました。" });
      });

      return {
        markAllAsRead,
        load,
      };
    }),
  {},
);

export type NotificationsType = typeof Notifications.Type;

export const NotificationsSymbol = "Notifications_Symbol";
export const Notifications: NotificationsModelType = model;
type NotificationsInferredType = typeof model;
export interface NotificationsModelType extends NotificationsInferredType {}
