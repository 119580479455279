import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type PJRBodyActualTimeKFProps = {
  model: PJRBodyActualTimeKFVM;
} & CellFormPrintAdaptable;

export interface PJRBodyActualTimeKFVM {
  PJRBodyActualTime: Minutes | undefined;
}

export const PJRBodyActualTimeKF = observer((props: PJRBodyActualTimeKFProps) => {
  const { model } = props;

  return <HHMMField label="就業時間" {...props} disabled={true} value={model.PJRBodyActualTime} />;
});
