import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";
import { CellPosition } from "react-virtualized/dist/es/CellMeasurer";

import { eqTableColumnDef, TableColumnDef } from "../TableColumnDef";
import { eqCellPosition } from "./cellsfunc";

interface Props {
  isActive: boolean;
  style: any;
  cellPosition: CellPosition;
  colDef: TableColumnDef;
  fixedColumnCount: number;
}

export const fixedEdgeRightBorderStyle = "border-right: 1px solid #333;";

const Cell = styled.div`
  font-size: 13px;
  font-family: "Courier New", Courier, monospace;
  background-color: #fcfcfc;

  border: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  &.FixedEdgeCellStyle {
    ${fixedEdgeRightBorderStyle}
  }

  line-height: 13px;
  text-align: center;
  font-weight: bold;
`;

export class HeaderCell extends React.Component<Props> {
  private containerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.containerRef = React.createRef();
  }

  public shouldComponentUpdate(nextProps: Props, nextState: {}) {
    if (
      !!this.props.isActive !== !!nextProps.isActive ||
      this.props.fixedColumnCount !== nextProps.fixedColumnCount ||
      !eqTableColumnDef(this.props.colDef, nextProps.colDef) ||
      !eqCellPosition(this.props.cellPosition, nextProps.cellPosition)
    ) {
      return true;
    }

    if (this.props.style.left !== nextProps.style.left || this.props.style.top !== nextProps.style.top) {
      const { current } = this.containerRef;
      if (current) {
        current.style.top = nextProps.style.top;
        current.style.left = nextProps.style.left;
      }
    }

    return false;
  }

  public render() {
    const { props, containerRef } = this;
    const { fixedColumnCount, style, cellPosition } = props;
    const { rowIndex, columnIndex } = props.cellPosition;
    const { id } = props.colDef;

    return (
      <Cell
        ref={containerRef}
        style={style}
        key={"header_" + id}
        tabIndex={-1}
        className={classNames(columnIndex === fixedColumnCount - 1 && "FixedEdgeCellStyle")}>
        {props.colDef.renderHeader(cellPosition)}
      </Cell>
    );
  }
}
