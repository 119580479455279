import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLawOverUntilLastMonthSumKFProps = {
  model: MTotalLawOverUntilLastMonthSumKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLawOverUntilLastMonthSumKFVM {
  mTotalLawOverUntilLastMonthSum: KintaiInputItemState<Minutes>;
}

export const MTotalLawOverUntilLastMonthSumKF = observer((props: MTotalLawOverUntilLastMonthSumKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLawOverUntilLastMonthSum;

  return <HHMMField {...props} label="mTotalLawOverUntilLastMonthSumKF" {...itemState} onChange={undefined} />;
});
