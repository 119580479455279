import { SaveTargetEntity } from "@webkintai/api";
import { Instance, types } from "mobx-state-tree";

export const model = types.optional(
  types
    .model("MonthlyKintaiAllowedSavedTarget", {
      projectReport: false,
      regularKintai: false,
      sportKintai: false,
    })
    .views(self => {
      return {
        get anyAllowed() {
          return self.projectReport || self.regularKintai || self.sportKintai;
        },
      };
    })
    .actions(self => {
      return {
        reflect(entity: SaveTargetEntity) {
          self.projectReport = entity.projectReport;
          self.regularKintai = entity.regularKintai;
          self.sportKintai = entity.sportKintai;
        },
      };
    }),
  {},
);

export const MonthlyKintaiAllowedSavedTarget: MonthlyKintaiAllowedSavedTargetModelType = model;
type MonthlyKintaiAllowedSavedTarget_InferredType = typeof model;
export interface MonthlyKintaiAllowedSavedTargetModelType extends MonthlyKintaiAllowedSavedTarget_InferredType {}
type MonthlyKintaiAllowedSavedTargetIIf = Instance<typeof MonthlyKintaiAllowedSavedTarget>;
export interface MonthlyKintaiAllowedSavedTargetInstance extends MonthlyKintaiAllowedSavedTargetIIf {}
