import { HourBasedDays } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../../form/CellFormAdaptable";
import { HourBasedDaysCountField } from "../../../../../form/HourBasedDaysCountField";

export type MTotalTakenNursingcareDaysKFProps = {
  model: MTotalTakenNursingcareDaysKFVM;
} & CellFormPrintAdaptable;

export interface MTotalTakenNursingcareDaysKFVM {
  mTotalTakenNursingcareDays: KintaiInputItemState<HourBasedDays>;
}

export const MTotalTakenNursingcareDaysKF = observer((props: MTotalTakenNursingcareDaysKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.mTotalTakenNursingcareDays);

  return (
    <HourBasedDaysCountField
      showAsDateCount={true}
      {...props}
      label="MTotalTakenNursingcareDays"
      {...itemState}
      onChange={undefined}
    />
  );
});
