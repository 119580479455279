import { action, computed } from "mobx";

import { lazyInject } from "../../../didecorators";
import { AccountPageModelSymbol, AccountsPageModel } from "../../../models/accounts/AccountsPageModel";
import { Roles, RolesSymbol } from "../../../models/roles/Roles";
import { AccountsPageVM } from "./AccountsPage";
import { AccountsPageGridVM } from "./grid/AccountsPageGrid";
import { AccountsPageGridRowVMImpl } from "./grid/AccountsPageGridRowVMImpl";
import { AcRoleHeaderVMImpl } from "./grid/AcRoleHeaderVMImpl";
import { PasswordInitDialogVMImpl } from "./password/PasswordInitDialogVMImpl";
import { AccountsPageRibbonVM } from "./ribbon/AccountsPageRibbon";

export class AccountsPageVMImpl implements AccountsPageVM, AccountsPageRibbonVM, AccountsPageGridVM {
  public passwordInitDialogModel = new PasswordInitDialogVMImpl();

  @lazyInject(AccountPageModelSymbol)
  private model: typeof AccountsPageModel.Type;

  @lazyInject(RolesSymbol)
  private roles: typeof Roles.Type;

  public get pageRibbonModel() {
    return this;
  }
  public get gridModel() {
    return this;
  }

  @computed
  public get accountRows() {
    return this.model.filteredRows.map(row => new AccountsPageGridRowVMImpl(this.model, row));
  }

  @computed
  public get roleColumns() {
    return Array.from(this.roles.roles.values(), role => new AcRoleHeaderVMImpl(this.model, role));
  }

  @computed
  public get loadingStatus() {
    return this.model.loadingStatus;
  }

  @computed
  public get newUserIdText() {
    return this.model.newUserIdText;
  }

  @action.bound
  public onChangeNewUserIdText(value: string) {
    this.model.setNewUserIdText(value);
  }

  @action.bound
  public onAddUser() {
    this.model.addUser();
  }

  @computed
  public get filterText() {
    return this.model.filterText;
  }

  @action.bound
  public onChangeFilterText(value: string) {
    this.model.setFilterText(value);
  }

  @computed
  public get excludeOldRetireUsers() {
    return this.model.excludeOldRetireUsers;
  }

  @action.bound
  public onChangeExcludeOldRetireUsers(value: boolean) {
    this.model.setExcludeOldRetireUsers(value);
  }

  @computed
  public get excludeHasNoChange() {
    return this.model.excludeHasNoChange;
  }

  @action.bound
  public onChangeExcludeHasNoChange(value: boolean) {
    this.model.setExcludeHasNoChange(value);
  }

  @computed
  public get newUserIdTextDisabled() {
    return this.model.newUserIdTextDisabled;
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @computed
  public get saveDisabled() {
    return this.model.saveDisabled;
  }

  @action.bound
  public onSave() {
    this.model.save();
  }

  @computed
  public get resetDisabled() {
    return this.model.resetDisabled;
  }

  @action.bound
  public onReset() {
    this.model.reset();
  }

  @action.bound
  public onExcelExport() {
    this.model.exportExcel();
  }

  @action.bound
  public onExcelImport(f: File) {
    this.model.importExcel(f);
  }

  @computed
  public get showsPasswordInitDialog() {
    return !!this.model.activePasswordChangeAccount;
  }

  @action.bound
  public onClosePasswordInitDialog() {
    this.model.setActivePasswordChangeAccount(undefined);
  }

  @computed
  public get showsAbsencesDialog() {
    return !!this.model.activePasswordChangeAccount;
  }

  @action.bound
  public onCloseAbsenceDialog() {
    this.model.setActiveAbsenceAccount(undefined);
  }
}
