import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type FlexKFProps = {
  model: FlexKFVM;
} & CellFormPrintAdaptable;

export interface FlexKFVM {
  flex: KintaiInputItemState<Minutes | undefined>;
}

export const FlexKF = observer((props: FlexKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.flex);

  return <HHMMField {...props} label="FLEX対象時間" {...itemState} />;
});
