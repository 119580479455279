import { Instance, types } from "mobx-state-tree";

export const model = types.optional(
  types.enumeration("PrvActiveMonthlyContentType", ["lawover", "lawoverCalc", "kintaiAlerts"]),
  "lawover",
);

export const PrvActiveMonthlyContentType: PrvActiveMonthlyContentType_ModelType = model;
type PrvActiveMonthlyContentType_InferredType = typeof model;
// tslint:disable-next-line:class-name
export interface PrvActiveMonthlyContentType_ModelType extends PrvActiveMonthlyContentType_InferredType {}
export type PrvActiveMonthlyContentTypeInstance = Instance<typeof PrvActiveMonthlyContentType>;
