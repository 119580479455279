import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalMissedKFProps = {
  model: MTotalMissedKFVM;
} & CellFormPrintAdaptable;

export interface MTotalMissedKFVM {
  mTotalMissed: KintaiInputItemState<Minutes>;
}

export const MTotalMissedKF = observer((props: MTotalMissedKFProps) => {
  const { model } = props;
  const itemState = model.mTotalMissed;

  return <HHMMField {...props} label="mTotalMissedKF" {...itemState} onChange={undefined} />;
});
