import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { ErrorsPopoverFrame } from "../../../../errors/ErrorsPopoverFrame";
import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { ErrorOverlay } from "../../../../form/ErrorOverlay";
import { ErrorCell, ErrorCountColor } from "./ErrorComponents";

export type MonthlyInfosKFProps = {
  model: MonthlyInfosKFVM;
} & CellFormAdaptable;

export interface MonthlyInfosKFVM {
  infos: string[];
}

export const MonthlyInfosKF = observer((props: MonthlyInfosKFProps) => {
  const { model } = props;

  if (props.mode === "cell") {
    return (
      <ErrorOverlay mode="cell" infos={model.infos} errors={[]} warnings={[]}>
        <ErrorCell>
          <ErrorCountColor length={model.infos.length}>{model.infos.length}</ErrorCountColor>
        </ErrorCell>
      </ErrorOverlay>
    );
  }

  return (
    <Tooltip
      disabled={model.infos.length === 0}
      boundary="viewport"
      content={
        <ErrorsPopoverFrame>
          {model.infos.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </ErrorsPopoverFrame>
      }>
      <span>
        情報: <ErrorCountColor length={model.infos.length}>{model.infos.length}件</ErrorCountColor>
      </span>
    </Tooltip>
  );
});
