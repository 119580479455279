import { Tooltip } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormAdaptable } from "../../../../form/CellFormAdaptable";
import { ErrorOverlay } from "../../../../form/ErrorOverlay";
import { ErrorCell, ErrorCountColor } from "../errors/ErrorComponents";

export type MonthlyApprovalOnYouErrorsKFProps = {
  model: MonthlyApprovalOnYouErrorsKFVM;
} & CellFormAdaptable;

export interface MonthlyApprovalOnYouErrorsKFVM {
  monthlyApprovalOnYouErrors: string[];
}

export const MonthlyApprovalOnYouErrorsKF = observer((props: MonthlyApprovalOnYouErrorsKFProps) => {
  const { model } = props;

  if (props.mode === "cell") {
    return (
      <ErrorOverlay mode="cell" errors={[]} warnings={model.monthlyApprovalOnYouErrors} infos={[]}>
        <ErrorCell>
          <ErrorCountColor length={model.monthlyApprovalOnYouErrors.length}>
            {model.monthlyApprovalOnYouErrors.length}
          </ErrorCountColor>
        </ErrorCell>
      </ErrorOverlay>
    );
  }

  return (
    <Tooltip
      disabled={model.monthlyApprovalOnYouErrors.length === 0}
      boundary="viewport"
      content={
        <div>
          {model.monthlyApprovalOnYouErrors.map((it, idx) => (
            <div key={idx}>{it}</div>
          ))}
        </div>
      }>
      <>
        あなた待ちの要承認:
        <ErrorCountColor length={model.monthlyApprovalOnYouErrors.length}>
          {model.monthlyApprovalOnYouErrors.length}件
        </ErrorCountColor>
      </>
    </Tooltip>
  );
});
