import { observer } from "mobx-react";
import React from "react";

import { AdminPageScreenModes } from "../../models/admin/AdminPageScreenModes";
import { PageTile } from "../common/page/PageTile";
import { AccountsPage, AccountsPageVM } from "./accounts/AccountsPage";
import { CalendarPage, CalendarPageVM } from "./calendar/CalendarPage";
import { DeptsPage, DeptsPageVM } from "./depts/DeptsPage";
import { AdminSubMenu, AdminSubMenuVM } from "./header/AdminSubMenu";
import { AdminIndexPage, AdminIndexPageVM } from "./index/AdminIndexPage";
import { PHPage, PHPageVM } from "./ph/PHPage";
import { PjClosePage, PjClosePageVM } from "./pjclose/PjClosePage";
import { RankPage, RankPageVM } from "./ranks/RankPage";
import { SPortAdminPage, SPortAdminPageVM } from "./sport/SPortAdminPage";

export interface AdminPageVM {
  pageMode: typeof AdminPageScreenModes.Type;
  adminSubMenuVM: AdminSubMenuVM;
  adminIndexPageVM: AdminIndexPageVM;
  accountsPageVM: AccountsPageVM;
  deptsPageVM: DeptsPageVM;
  calendarPageVM: CalendarPageVM;
  phPageVM: PHPageVM;
  rankPageVM: RankPageVM;
  pjClosePageVM: PjClosePageVM;
  sportAdminPageVM: SPortAdminPageVM;
}

@observer
export class AdminPage extends React.Component<{ model: AdminPageVM }> {
  public render() {
    const { model } = this.props;
    const { pageMode } = model;

    return (
      <PageTile header={<AdminSubMenu model={model.adminSubMenuVM} />}>
        {pageMode === "warnings" && <AdminIndexPage model={model.adminIndexPageVM} />}
        {pageMode === "accounts" && <AccountsPage model={model.accountsPageVM} />}
        {pageMode === "depts" && <DeptsPage model={model.deptsPageVM} />}
        {pageMode === "calendar" && <CalendarPage model={model.calendarPageVM} />}
        {pageMode === "ph" && <PHPage model={model.phPageVM} />}
        {pageMode === "ranks" && <RankPage model={model.rankPageVM} />}
        {pageMode === "pjclose" && <PjClosePage model={model.pjClosePageVM} />}
        {pageMode === "sport" && <SPortAdminPage model={model.sportAdminPageVM} />}
      </PageTile>
    );
  }
}
