import { KintaiBunrui } from "@webkintai/bunrui";
import { Minutes } from "@webkintai/core";
import { action, computed, observable } from "mobx";

import { lazyInject } from "../../../didecorators";
import { DiPanelMode } from "../../../models/kintai/DiPanelMode";
import { KintaiReportPageModel } from "../../../models/kintai/KintaiReportPageModel";
import { MainKintaiType } from "../../../models/kintai/MainKintaiType";
import { PrintDetailMode } from "../../../models/kintai/misc/printdetailmode/PrintDetailMode";
import { AppConfirm } from "../../../services/msg/AppConfirm";
import { MyPrivileges } from "../../../services/prv/MyPrivileges";
import { CellPosition } from "../table/CellPosition";
import { cellPositionsToMoveDelta } from "../table/GridUtils";
import { MoveDelta } from "../table/MoveDelta";
import { RegularDailyKintaiGridVM } from "./daily/regular/RegularDailyKintaiGrid";
import { RegularDailyVMImpl } from "./daily/regular/RegularDailyVMImpl";
import { SPortDailyKintaiGridVM } from "./daily/sport/SPortDailyKintaiGrid";
import { SPortDailyVMImpl } from "./daily/sport/SPortDailyVMImpl";
import { KintaiReportVM } from "./KintaiReport";
import { RegularMonthlyTotalVMImpl } from "./monthly/regular/RegularMonthlyTotalVMImpl";
import { SPortMonthlyTotalVMImpl } from "./monthly/sport/SPortMonthlyTotalVMImpl";
import { RegularPrintedKintaiVM } from "./print/regular/RegularPrintedKintai";
import { SPortPrintedKintaiVM } from "./print/sport/SPortPrintedKintai";
import { KintaiReportRibbonVM } from "./ribbon/KintaiReportRibbon";

export class KintaiReportVMImpl
  implements
    KintaiReportVM,
    KintaiReportRibbonVM,
    RegularDailyKintaiGridVM,
    RegularPrintedKintaiVM,
    SPortDailyKintaiGridVM,
    SPortPrintedKintaiVM {
  public get ribbonVM() {
    return this;
  }

  public get crucialRibbonVM() {
    return this;
  }

  @computed
  public get designatedMinutesDisabled() {
    return this.userKintai.isRegularKintaiLocked;
  }

  @computed
  public get mainKinmuTypeDisabled() {
    return this.userKintai.isRegularKintaiLocked;
  }

  @computed
  public get id() {
    return this.model.userKintai ? this.model.userKintai.id : "";
  }

  @computed
  public get loadingStatus() {
    return this.model.loadingStatus;
  }

  @computed
  public get loadingErrorMessage() {
    return this.model.loadingErrorMessage;
  }

  @computed
  public get diPanelMode() {
    return this.model.diPanelMode;
  }

  @computed
  private get userKintai() {
    return this.model.userKintai!;
  }

  @computed
  private get attrs() {
    return this.userKintai.attrs!;
  }

  @computed
  private get tsValues() {
    return this.userKintai.timestamps!;
  }

  @computed.struct
  get timestamps() {
    return {
      self: this.selfTsModel.timestamp,
      approver: this.approverTsModel.timestamp,
      reviewer: this.reviewerTsModel.timestamp,
      clerk: this.clerkTsModel.timestamp,
      hrm: this.hrmTsModel.timestamp,
    };
  }

  @computed
  public get userId() {
    return this.userKintai.userId;
  }

  @computed
  public get name() {
    return this.userKintai.userName || "";
  }

  @computed
  public get depName() {
    return this.attrs.depName || "";
  }

  @computed
  public get rankName() {
    return this.attrs.rankName!;
  }

  @computed
  public get rankPosition() {
    return this.attrs.rankPosition!;
  }

  @computed
  public get mainKinmuType() {
    return this.attrs.mainKintaiType!;
  }

  @computed
  public get availableKintaiTypes() {
    return this.userKintai.availableKintaiTypes;
  }

  @computed
  public get showsSetSPortTimeFixedTimes() {
    return this.kintaiType === "S-Port";
  }

  @computed
  public get setSPortTimeFixedTimesDisabled() {
    return this.userKintai.isSportKintaiLocked;
  }

  @computed
  public get designatedMinutes() {
    return this.attrs.designatedMinutes;
  }

  @computed
  public get showsPrinted() {
    return this.model.showsPrinted;
  }

  @computed
  public get printDetailMode() {
    return this.model.printDetailMode;
  }

  @computed
  public get showsProjectReport() {
    return this.model.selectedRegularKintaiTabId === "kintai_monthly_report";
  }

  @computed
  public get showsCkrpRecords() {
    return this.userKintai.hasAnyCkrpRecord;
  }

  @computed
  public get validateInProgress() {
    return this.model.validateInProgress;
  }

  @computed
  public get saveDisabled() {
    return this.model.saveDisabled;
  }

  @computed
  public get hasNoChange() {
    return this.userKintai.hasNoChange;
  }

  @computed
  public get reloadDisabled() {
    return this.model.reloadDisabled;
  }

  @computed
  public get lastLoadTime() {
    return this.userKintai.lastLoadTime;
  }

  @computed
  public get kintaiType() {
    return this.attrs.mainKintaiType!;
  }

  @computed
  public get projectInputStyle() {
    return this.userKintai.projectInputStyle;
  }

  public get regularMonthlyVM() {
    return this;
  }

  public get regularMonthlyPrintedKintaiVM() {
    return this;
  }

  @computed
  get month() {
    return this.userKintai.month;
  }

  @computed
  get errors() {
    if (this.mainKinmuType === "S-Port") {
      return this.sPortErrors;
    }
    return this.regularErrors;
  }

  @computed
  get warnings() {
    if (this.mainKinmuType === "S-Port") {
      return this.sPortWarnings;
    }
    return this.regularWarnings;
  }

  @computed
  get infos() {
    if (this.mainKinmuType === "S-Port") {
      return this.sPortInfos;
    }
    return this.regularInfos;
  }

  @computed
  get regularErrors() {
    return this.userKintai.regularKintai!.errors;
  }

  @computed
  get regularWarnings() {
    return this.userKintai.regularKintai!.warnings;
  }

  @computed
  get regularInfos() {
    return this.userKintai.regularKintai!.infos;
  }

  @computed
  get sPortErrors() {
    return this.userKintai.sPortKintai!.errors;
  }

  @computed
  get sPortWarnings() {
    return this.userKintai.sPortKintai!.warnings;
  }

  @computed
  get sPortInfos() {
    return this.userKintai.sPortKintai!.infos;
  }

  @computed
  public get regularMonthlyTotal() {
    return new RegularMonthlyTotalVMImpl(this.userKintai);
  }

  @computed
  public get regularRows() {
    return this.userKintai.regularKintai!.days.map((day, idx) => {
      return new RegularDailyVMImpl(this, this.model, this.userKintai.apps!.days[idx], this.userKintai.pjReport!, day);
    });
  }

  @computed
  public get regularToday() {
    return this.regularRows[this.model.date.getDate() - 1];
  }

  public get sPortMonthlyVM() {
    return this;
  }

  public get sPortMonthlyPrintedKintaiVM() {
    return this;
  }

  @computed
  public get sPortMonthlyTotal() {
    return new SPortMonthlyTotalVMImpl(this.userKintai);
  }

  @computed
  public get sPortRows() {
    return this.userKintai.sPortKintai!.days.map((day, idx) => {
      return new SPortDailyVMImpl(this.model, this.userKintai.apps!.days[idx], day);
    });
  }

  @computed
  public get sPortToday() {
    return this.sPortRows[this.model.date.getDate() - 1];
  }

  @computed.struct
  get approverModel() {
    return {
      userInfo: this.attrs.approver,
      candidates: this.userKintai.approvers,
      disabled: this.userKintai.reviewesSelectionIsLocked,
      onChoose: this.userKintai.setApprover,
      onCopyApproverFromLastMonth: this.onCopyApproverFromLastMonth,
    };
  }

  @computed.struct
  get reviewerModel() {
    return {
      userInfo: this.attrs.reviewer,
      candidates: this.userKintai.approvers,
      disabled: this.userKintai.reviewesSelectionIsLocked,
      onChoose: this.userKintai.setReviewer,
      onCopyApproverFromLastMonth: this.onCopyApproverFromLastMonth,
    };
  }

  @computed.struct
  get selfTsModel() {
    const ts = this.tsValues.self;
    return {
      timestamp: ts
        ? {
            at: ts.timestamp,
            userName: ts.user.userName,
          }
        : undefined,
      disabled: !this.userKintai.selfStampIsAllowed,
      onTimestamp: () => {
        this.userKintai.setTimestamp("self");
      },
      onRemoveTimestamp: () => {
        this.userKintai.clearTimestamp("self");
      },
    };
  }

  @computed.struct
  get approverTsModel() {
    const ts = this.tsValues.department;
    return {
      timestamp: ts
        ? {
            at: ts.timestamp,
            userName: ts.user.userName,
          }
        : undefined,
      disabled: !this.userKintai.approverStampIsAllowed,
      onTimestamp: () => {
        this.userKintai.setTimestamp("department");
      },
      onRemoveTimestamp: () => {
        this.userKintai.clearTimestamp("department");
      },
    };
  }
  @computed.struct
  get reviewerTsModel() {
    const ts = this.tsValues.review;
    return {
      timestamp: ts
        ? {
            at: ts.timestamp,
            userName: ts.user.userName,
          }
        : undefined,
      disabled: !this.userKintai.reviewerStampIsAllowed,
      onTimestamp: () => {
        this.userKintai.setTimestamp("review");
      },
      onRemoveTimestamp: () => {
        this.userKintai.clearTimestamp("review");
      },
    };
  }
  @computed.struct
  get clerkTsModel() {
    const ts = this.tsValues.clerk;
    return {
      timestamp: ts
        ? {
            at: ts.timestamp,
            userName: ts.user.userName,
          }
        : undefined,
      disabled: !this.userKintai.clerkStampIsAllowed,
      onTimestamp: () => {
        this.userKintai.setTimestamp("clerk");
      },
      onRemoveTimestamp: () => {
        this.userKintai.clearTimestamp("clerk");
      },
    };
  }
  @computed.struct
  get hrmTsModel() {
    const ts = this.tsValues.hrm;
    return {
      timestamp: ts
        ? {
            at: ts.timestamp,
            userName: ts.user.userName,
          }
        : undefined,
      disabled: !this.userKintai.hrmStampIsAllowed,
      onTimestamp: () => {
        this.userKintai.setTimestamp("hrm");
      },
      onRemoveTimestamp: () => {
        this.userKintai.clearTimestamp("hrm");
      },
    };
  }

  @computed.struct
  get pos(): CellPosition {
    return {
      rowIndex: this.model.date.getDate(),
      columnIndex: this.model.colPos,
    };
  }

  @computed
  public get showSetAllBunrui() {
    return !this.userKintai.isRegularKintaiLocked && this.myPrivileges.has("DISP_KINTAI_BULKSET_BUNRUI");
  }

  @computed
  public get setAllBunruiKintaiBunruiList() {
    return this.userKintai.availableRegularKintaiBunruiList.filter(it => it.canAppearAt(2));
  }

  @computed
  public get showsBulkApproval() {
    return this.userKintai.approveAppsAllowed;
  }

  @computed
  public get bulkApprovalDisabled() {
    return this.userKintai.bulkApprovalDisabled;
  }

  @computed
  public get bulkApprovalCount() {
    return this.userKintai.bulkApprovableRegularDays.length;
  }

  @computed
  public get showsForceRecalc() {
    return this.myPrivileges.has("DISP_KINTAI_REFRESH");
  }

  @computed
  public get showsForceAttributeRecalc() {
    return this.myPrivileges.has("DISP_KINTAI_MODIFY_ATTRS");
  }

  @computed
  public get showsDeleteRetiredKintai() {
    return this.myPrivileges.has("DISP_KINTAI_DELETE");
  }

  @observable
  public targetDate: Date;

  @lazyInject(MyPrivileges)
  private myPrivileges: MyPrivileges;

  @lazyInject(AppConfirm)
  private appConfirm: AppConfirm;

  constructor(private readonly model: typeof KintaiReportPageModel.Type) {}

  @action.bound
  public onChangeDiPanelMode(value: DiPanelMode) {
    this.model.setDiPanelMode(value);
  }

  @action.bound
  public onChangeMainKinmuType(type: typeof MainKintaiType.Type) {
    this.attrs.input.setMainKintaiType(type);
  }

  @action.bound
  public onSetSPortTimeFixedTimes() {
    this.userKintai.sPortKintai!.setFixedTimes();
  }

  @action.bound
  public onChangeDesignatedMinutes(value: Minutes | undefined) {
    this.attrs.input.setDesignatedMinutes(value);
  }

  @action.bound
  public onSwitchInputMode() {
    this.model.setShowsPrinted(false);
  }

  @action.bound
  public onSwitchPrintMode() {
    this.model.setShowsPrinted(true);
  }

  @action.bound
  public onSwitchPrintDetailMode(mode: PrintDetailMode) {
    this.model.setShowsPrinted(true);
    this.model.setPrintDetailMode(mode);
  }

  @action.bound
  public onSave() {
    this.model.save();
  }

  @action.bound
  public onReset() {
    this.appConfirm
      .confirm({ title: "勤怠表の取消確認", message: "入力した内容を消しますが、よろしいですか？" })
      .then(answer => {
        if (answer) {
          this.model.reset();
        }
      });
  }

  @action.bound
  public onReload() {
    this.model.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.model.reload();
  }

  @action.bound
  public onCopyApproverFromLastMonth() {
    this.userKintai.derivesAttrsFromLastMonth({
      copyApprovers: true,
    });
  }

  @action.bound
  public onCopyPillarsFromLastMonth() {
    this.userKintai.derivesAttrsFromLastMonth({
      copyPJReportPilalrs: true,
    });
  }

  @action.bound
  public onMoveCell(moveDelta: MoveDelta) {
    this.model.movePosition(moveDelta);
  }

  @action.bound
  public onCellFocused(pos: CellPosition) {
    this.onMoveCell(cellPositionsToMoveDelta(this.pos, pos));
  }

  @action.bound
  public onSetAllBunrui(value: KintaiBunrui) {
    this.userKintai.regularKintai!.setAllBunrui2(value);
  }

  @action.bound
  public onClearAllBunrui() {
    this.userKintai.regularKintai!.clearAllBunrui();
  }

  @action.bound
  public onBulkApproval() {
    this.userKintai.bulkApprove();
  }

  @action.bound
  public onForceAttributeRecalc() {
    this.userKintai.forceAttributeRecalc();
  }

  @action.bound
  public onForceRecalc() {
    this.userKintai.forceRecalc();
  }

  @action.bound
  public onDeleteRetiredKintai() {
    this.userKintai.deleteRetiredKintai();
  }

  @action.bound
  public onSetAllDaysDoorEnter() {
    this.userKintai.regularKintai!.setAllDaysDoorEnter();
  }
  @action.bound
  public onSetAllDaysDoorLeave() {
    this.userKintai.regularKintai!.setAllDaysDoorLeave();
  }
}
