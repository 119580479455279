import { getEnv, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { getDI } from "../common/getDI";
import { NotificationPageModelSymbol, NotificationPageModelType } from "../notify/NotificationPageModel";
import { ProfileInterviewPageModel, ProfileInterviewPageModelSymbol } from "./ProfileInterviewPageModel";
import { ProfilePageScreenModes } from "./ProfilePageScreenModes";
import { ProfileSettingsPageModel } from "./ProfileSettingsPageModel";

const model = types.optional(
  types
    .model("ProfilePage", {
      pageMode: types.optional(ProfilePageScreenModes, "inbox"),
    })
    .views(self => ({
      get url(): string {
        return paths.profile.index() + "/" + self.pageMode;
      },
    }))
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const notificationPageModel = (): NotificationPageModelType => getEnv(self).get(NotificationPageModelSymbol);
      const profileSettingsPageModel = (): typeof ProfileSettingsPageModel.Type =>
        getEnv(self).get(NotificationPageModelSymbol);
      const interviewPlanPageModel = (): typeof ProfileInterviewPageModel.Type =>
        getEnv(self).get(ProfileInterviewPageModelSymbol);

      return {
        route(pathFragment: string) {
          const { menuItem, others } = fromPaths.profile.index(pathFragment);

          if (ProfilePageScreenModes.is(menuItem)) {
            self.pageMode = menuItem;

            switch (menuItem) {
              case "settings":
                profileSettingsPageModel().route();
                break;
              case "inbox":
                notificationPageModel().route(others);
                break;
              case "interview":
                interviewPlanPageModel().route(others);
                break;
            }
          } else {
            appRouter().replaceWithoutEffects(self.url);
          }
        },
      };
    }),
  {},
);

export const ProfilePageModelSymbol = "Model_ProfilePage";
export const ProfilePageModel: ProfilePageModelModelType = model;
type ProfilePageModelInferredType = typeof model;
export interface ProfilePageModelModelType extends ProfilePageModelInferredType {}
