import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { decorateTitle } from "../../../utils/apptitle";
import { GridTableLoading } from "../../common/loading/GridTableLoading";
import { PageRibbon } from "../../common/page/PageRibbon";
import { PjClosePageGrid, PjClosePageGridVM } from "./grid/PjClosePageGrid";
import { PjClosePageRibbon, PjClosePageRibbonVM } from "./PjClosePageRibbon";

export interface PjClosePageVM {
  nendo: number;
  loadingStatus: LoadingStatusType;
  ribbonModel: PjClosePageRibbonVM;
  gridModel: PjClosePageGridVM;
}

@observer
export class PjClosePage extends React.Component<{ model: PjClosePageVM }> {
  public render() {
    const { model } = this.props;
    const { loadingStatus } = model;

    return (
      <PageRibbon ribbon={<PjClosePageRibbon model={model.ribbonModel} />}>
        <Helmet>
          <title>{decorateTitle("月報締め管理")}</title>
        </Helmet>

        {loadingStatus !== "loaded" && <GridTableLoading />}
        {loadingStatus === "loaded" && <PjClosePageGrid key={model.nendo} model={model.gridModel} />}
      </PageRibbon>
    );
  }
}
