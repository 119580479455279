export const checkIfFilterTextMatches = (values: string[], searchWords: string[]) => {
  if (searchWords.length === 0) {
    return true;
  }

  const normalizedValues = values.map(normalizeSearchWord);
  const normalizedSearchWords = searchWords.map(normalizeSearchWord);
  for (const s of normalizedSearchWords) {
    let anyMatched = false;
    for (const v of normalizedValues) {
      if (v.indexOf(s) >= 0) {
        anyMatched = true;
      }
    }
    if (!anyMatched) {
      return false;
    }
  }
  return true;
};

export const normalizeSearchWord = (value: string) => {
  return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).toLocaleLowerCase();
};

export const filterTextToSearchWords = (value: string) => value.split(/\s+/g);
