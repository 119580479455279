import { flow, getEnv, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendo } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { NendoCalendarInput } from "./input/NendoCalendarInput";
import { NendoCalendarsInputSymbol, NendoCalendarsInputType } from "./input/NendoCalendarsInput";
import { NendoCalendar } from "./NendoCalendar";
import { NendoCalendarsSymbol, NendoCalendarsType } from "./NendoCalendars";

const model = types.optional(
  types
    .model("CalendarPageModel", {
      nendo: types.optional(types.number, getNendo(new Date())),
      nendoCalendarInput: types.maybeNull(types.reference(NendoCalendarInput)),
      nendoCalendar: types.maybeNull(types.reference(NendoCalendar)),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      return {
        get url() {
          return paths.admin.calendar.ofNendo(self.nendo).index();
        },
        get loadingStatus() {
          return self.nendoCalendarInput ? self.nendoCalendar!.loadingStatus : "loading";
        },
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get allowedToEdit() {
          if (!self.nendoCalendarInput) {
            return false;
          }
          return self.nendoCalendarInput.allowedToEdit;
        },
      };
    })
    .actions(self => {
      const appRouter = (): AppRouter => getEnv(self).get(AppRouter);
      const nendoCalendars = (): NendoCalendarsType => getEnv(self).get(NendoCalendarsSymbol);
      const nendoCalendarsInput = (): NendoCalendarsInputType => getEnv(self).get(NendoCalendarsInputSymbol);

      return {
        setNendo: flow(function*(nendo: number) {
          self.nendo = nendo;
          self.nendoCalendar = nendoCalendars().prepare(nendo);
          self.nendoCalendarInput = nendoCalendarsInput().prepare(nendo);
          yield self.nendoCalendar.loadIfNeeded();
          if (self.nendoCalendarInput.hasNoChange) {
            self.nendoCalendarInput.setOriginalCalendar(self.nendoCalendar);
            self.nendoCalendarInput.copy();
          }
        }),
        route(pathFragment: string) {
          const { targetYear } = fromPaths.admin.calendar.ofYear.index(pathFragment);
          if (targetYear) {
            this.setNendo(targetYear);
          } else {
            this.setNendo(self.nendo);
          }

          appRouter().replaceWithoutEffects(self.url);
        },
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.admin.calendar.ofNendo(nendo).index());
        },
      };
    }),
  {},
);

export type CalendarPageModelType = typeof CalendarPageModel.Type;

export const CalendarPageModelSymbol = "CalendarPageModel_Symbol";
export const CalendarPageModel: CalendarPageModelModelType = model;
type CalendarPageModelInferredType = typeof model;
export interface CalendarPageModelModelType extends CalendarPageModelInferredType {}
