import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";
import { ReceptionStatus } from "../../../models/common/ReceptionStatus";
import { decorateTitle } from "../../../utils/apptitle";
import { DateField } from "../../common/form/DateField";
import { HHMMField } from "../../common/form/HHMMField";
import { LoadingStateSpread } from "../../common/loading/LoadingStateSpread";
import { NoResultsSpread } from "../../common/NoResultsSpread";
import { PageRibbon } from "../../common/page/PageRibbon";
import { WkTable } from "../../common/plaintable/WkTables";
import { ReadingsPane } from "../../common/ReadingsPane";
import { ProfileInterviewPageRibbon, ProfileInterviewPageRibbonVM } from "./ProfileInterviewPageRibbon";

export interface ProfileInterviewPageVM {
  loadingStatus: LoadingStatusType;
  ribbonModel: ProfileInterviewPageRibbonVM;
  months: ProfileInterviewRowVM[];
}

type rst = typeof ReceptionStatus.Type;

export interface ProfileInterviewRowVM {
  month: Date;
  lawOver: Minutes | undefined;
  sundayOnCount: number | undefined;

  fatigue?: {
    hasNoChange: boolean;
    submitDate: Date | undefined;
    onChangeSubmitDate: (value: Date | undefined) => void;
  };

  interview?: {
    hasNoChange: boolean;
    receptionStatus: rst;
    reservedDate: Date | undefined;
    onChangeReservedDate: (value: Date | undefined) => void;
  };
}

export interface ProfileInterviewFatigueRowVM {
  type: "fatigue";
  month: Date;
  notApplied: false;
  hasNoChange: boolean;
  lawOver: Minutes | undefined;
  sundayOnCount: number | undefined;
}

@observer
export class ProfileInterviewPage extends React.Component<{ model: ProfileInterviewPageVM }> {
  public render() {
    const { model } = this.props;
    return (
      <PageRibbon
        ribbon={<ProfileInterviewPageRibbon model={model.ribbonModel} small={false} />}
        shrinkRibbon={<ProfileInterviewPageRibbon model={model.ribbonModel} small={true} />}
        hideExpandButton={true}>
        <Helmet>
          <title>{decorateTitle("産業医面接予定一覧")}</title>
        </Helmet>
        <ReadingsPane>
          {model.loadingStatus !== "loaded" && <LoadingStateSpread />}
          {model.loadingStatus === "loaded" && (
            <>
              {model.months.length === 0 && (
                <>
                  <NoResultsSpread title="特に作業はありません" />
                </>
              )}
              {model.months.length > 0 && (
                <>
                  <h3>疲労蓄積度チェックシートの提出状況・産業医との面接予定を入力して保存してください</h3>
                  <WkTable>
                    <thead>
                      <tr>
                        <th rowSpan={2}>対象年月</th>
                        <th colSpan={2}>勤務状況（抜粋）</th>
                        <th>疲労蓄積度チェックシート</th>
                        <th colSpan={2}>産業医面接</th>
                      </tr>
                      <tr>
                        <th>健康管理時間</th>
                        <th>日曜出勤回数</th>
                        <th>提出した日</th>
                        <th>受診状況</th>
                        <th>面接予定日</th>
                      </tr>
                    </thead>
                    <tbody>
                      {model.months.map(m => (
                        <React.Fragment key={`${m.month.getTime()}`}>
                          <Row row={m} />
                        </React.Fragment>
                      ))}
                    </tbody>
                  </WkTable>
                  <p>（※一度回答した内容は削除できません。）</p>
                </>
              )}
            </>
          )}
        </ReadingsPane>
      </PageRibbon>
    );
  }
}

const Row: React.FC<{ row: ProfileInterviewRowVM }> = observer(props => {
  const { row } = props;
  return (
    <tr key={row.month.getTime()}>
      <td>{moment(row.month).format("YYYY年 MM月")}</td>
      <td>
        <HHMMField mode="print" value={row.lawOver} />
      </td>
      <td>{row.sundayOnCount}</td>
      <FatigueCells cells={row.fatigue} />
      <InterviewCells cells={row.interview} />
    </tr>
  );
});

const FatigueCells: React.FC<{ cells: ProfileInterviewRowVM["fatigue"] }> = observer(({ cells }) => (
  <>
    {cells === undefined && (
      <>
        <td>-</td>
      </>
    )}
    {cells && (
      <>
        <td style={{ minWidth: "11ex" }}>
          <DateField
            mode="form"
            hasNoChange={cells.hasNoChange}
            value={cells.submitDate}
            onChange={cells.onChangeSubmitDate}
          />
        </td>
      </>
    )}
  </>
));

const InterviewCells: React.FC<{ cells: ProfileInterviewRowVM["interview"] }> = observer(({ cells }) => (
  <>
    {cells === undefined && (
      <>
        <td>-</td>
        <td>-</td>
      </>
    )}
    {cells && (
      <>
        <td>{cells.receptionStatus}</td>
        <td style={{ minWidth: "11ex" }}>
          <DateField
            mode="form"
            hasNoChange={cells.hasNoChange}
            value={cells.reservedDate}
            onChange={cells.onChangeReservedDate}
          />
        </td>
      </>
    )}
  </>
));
