import { PersonalMonthlyDashboardResult, PersonalNendoDashboardResult } from "@webkintai/api";
import { UserMonthlyDashboardData, UserNendoDashboardData } from "@webkintai/schema";
import { reverse, sortBy } from "lodash-es";
import { flow, Instance, toGenerator, types } from "mobx-state-tree";

import { DashboardApi } from "../../../services/api/DashboardApi";
import { fromApiDate } from "../../../utils/api";
import { sleep } from "../../../utils/async";
import { getNendo } from "../../../utils/calendar";
import { getDI } from "../../common/getDI";
import { LoadingStatus } from "../../common/LoadingStatus";
import { getProfile } from "../../profile/getProfile";
import { WithLoading } from "../WithLoading";
import { calcPrvKintaiAuthNotApplied } from "./calc/calcPrvKintaiAuthNotApplied";
import { calcPrvKintaiAuthNotApproved } from "./calc/calcPrvKintaiAuthNotApproved";
import { calcPrvKintaiWarnings } from "./calc/calcPrvKintaiWarnings";
import { calcPrvLawover } from "./calc/calcPrvLawover";
import { calcPrvLawoverCalc } from "./calc/calcPrvLawoverCalc";
import { calcPrvPaidLeaves } from "./calc/calcPrvPaidLeaves";
import { PrvActiveMonthlyContentType, PrvActiveMonthlyContentTypeInstance } from "./PrvActiveMonthlyContentType";
import { PrvActiveNendoContentType, PrvActiveNendoContentTypeInstance } from "./PrvActiveNendoContentType";

export const model = types.optional(
  types
    .model("PrivateDDModel", {
      loadingStatus: types.optional(LoadingStatus, "loading"),

      dismissApplet: false,
      dismissAppletByRoute: false,
      dismissAppletByTimeout: false,

      date: types.optional(types.Date, new Date()),

      nendoContent: types.maybe(types.frozen<UserNendoDashboardData>()),
      nendoContentLastUpdate: types.maybe(types.Date),
      activeNendoContent: PrvActiveNendoContentType,

      monthlyContent: types.maybe(types.frozen<UserMonthlyDashboardData>()),
      monthlyContentLastUpdate: types.maybe(types.Date),
      activeMonthlyContent: PrvActiveMonthlyContentType,
    })
    .views(self => {
      const withMonthlyContent = <T>(calc: (data: UserMonthlyDashboardData) => T): WithLoading<T> => {
        if (self.loadingStatus !== "loaded") {
          return {
            state: "loading",
          };
        }
        if (self.monthlyContent === undefined) {
          return {
            state: "no_data",
          };
        }

        return calc(self.monthlyContent);
      };

      const withNendoContent = <T>(calc: (data: UserNendoDashboardData) => T): WithLoading<T> => {
        if (self.loadingStatus !== "loaded") {
          return {
            state: "loading",
          };
        }
        if (self.nendoContent === undefined) {
          return {
            state: "no_data",
          };
        }

        return calc(self.nendoContent);
      };

      return {
        get monthly_lawover() {
          return withMonthlyContent(calcPrvLawover);
        },
        get monthly_lawoverCalc() {
          return withMonthlyContent(calcPrvLawoverCalc);
        },
        get monthly_kintaiWarnings() {
          return withMonthlyContent(calcPrvKintaiWarnings);
        },
        get nendo_kintaiAuthNotApplied() {
          return withNendoContent(calcPrvKintaiAuthNotApplied);
        },
        get nendo_kintaiAuthNotApproved() {
          return withNendoContent(calcPrvKintaiAuthNotApproved);
        },
        get nendo_paidLeaves() {
          return withNendoContent(calcPrvPaidLeaves);
        },
        get latestLastUpdate(): Date | undefined {
          return reverse(
            sortBy([self.nendoContentLastUpdate, self.monthlyContentLastUpdate], (it: Date | undefined) =>
              it ? it.getTime() : 0,
            ),
          )[0];
        },
      };
    })
    .views(self => {
      const anyWarned = (ary: Array<WithLoading<{ warned: boolean }>>) => {
        return ary.some(it => (it.state !== undefined ? false : it.warned));
      };

      return {
        get monthlyDataWarned() {
          return anyWarned([self.monthly_lawover, self.monthly_lawoverCalc, self.monthly_kintaiWarnings]);
        },
        get nendoDataWarned() {
          return anyWarned([self.nendo_kintaiAuthNotApplied, self.nendo_kintaiAuthNotApproved, self.nendo_paidLeaves]);
        },
      };
    })
    .views(self => {
      return {
        get showApplet() {
          if (self.loadingStatus !== "loaded") {
            return false;
          }
          if (self.dismissAppletByRoute || self.dismissApplet || self.dismissAppletByTimeout) {
            return false;
          }
          return self.monthlyDataWarned || self.nendoDataWarned;
        },
      };
    })
    .actions(self => {
      const dashboardApi = () => getDI(self, DashboardApi);

      const route = (value?: Date) => {
        self.dismissAppletByRoute = true;

        if (value === undefined) {
          loadIfNeeded();
          return;
        }

        self.date = value;
        load();
      };

      const loadIfNeeded = flow(function*() {
        if (self.loadingStatus === "loaded") {
          return;
        }

        yield load();
      });

      const load = flow(function*() {
        self.loadingStatus = "loading";

        const { userId } = getProfile();

        try {
          const nendoResult: PersonalNendoDashboardResult = yield* toGenerator(
            dashboardApi().getPersonalDashboardByNendo(userId, getNendo(self.date)),
          );
          self.nendoContentLastUpdate = fromApiDate(nendoResult.lastUpdate);
          self.nendoContent = JSON.parse(nendoResult.contentJson);
        } catch (e) {
          console.error(`Error (or not found) while loading private NENDO dashboard`, e);
          self.nendoContent = undefined;
        }

        try {
          const monthlyResult: PersonalMonthlyDashboardResult = yield* toGenerator(
            dashboardApi().getPersonalDashboardByMonth(userId, self.date.getFullYear(), self.date.getMonth() + 1),
          );
          self.monthlyContentLastUpdate = fromApiDate(monthlyResult.lastUpdate);
          self.monthlyContent = JSON.parse(monthlyResult.contentJson);
        } catch (e) {
          console.error(`Error (or not found) while loading private MONTHLY dashboard`, e);
          self.monthlyContent = undefined;
        }

        self.loadingStatus = "loaded";
        triggerDismissByTimeout();
      });

      const triggerDismissByTimeout = flow(function*() {
        yield sleep(5000);
        self.dismissAppletByTimeout = true;
      });

      return {
        loadIfNeeded,
        load,
        route,
        setActiveNendoContent(value: PrvActiveNendoContentTypeInstance) {
          self.activeNendoContent = value;
        },
        setActiveMonthlyContent(value: PrvActiveMonthlyContentTypeInstance) {
          self.activeMonthlyContent = value;
        },
        setDismissApplet(value: boolean) {
          self.dismissApplet = value;
        },
      };
    }),
  {},
);

export const PrivateDDModel: PrivateDDModelModelType = model;
type PrivateDDModel_InferredType = typeof model;
export interface PrivateDDModelModelType extends PrivateDDModel_InferredType {}
type PrivateDDModelIIf = Instance<typeof PrivateDDModel>;
export interface PrivateDDModelInstance extends PrivateDDModelIIf {}
