import styled from "@emotion/styled";
import classNames from "classnames";
import React, { useCallback, useRef, useState } from "react";

import { FocusMarkerClassName } from "../../table/GridTable";
import { CellAdaptable } from "../CellFormAdaptable";
import { ErrorOverlay } from "../ErrorOverlay";
import { cellStyle } from "../FormCommon";
import { LabelFieldCoreProps } from "../LabelField";

export const CellLabelField: React.FC<LabelFieldCoreProps & CellAdaptable> = ({
  onClick,
  className,
  errors,
  warnings,
  infos,
  alignment,
  hasNoChange,
  style,
  children,
}) => {
  const onClickHandler = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  return (
    <ErrorOverlay mode="cell" errors={errors} warnings={warnings} infos={infos}>
      <Cell
        tabIndex={0}
        className={classNames(FocusMarkerClassName, className)}
        style={{
          textAlign: alignment || "center",
          cursor: onClick ? "pointer" : undefined,
          backgroundColor: hasNoChange === false ? "yellow" : undefined,
          ...(style || {}),
        }}
        onKeyPress={onClick}
        onClick={onClickHandler}>
        {children}
      </Cell>
    </ErrorOverlay>
  );
};

const Cell = styled.span`
  ${cellStyle}
  color: black;
  display: block;
  text-align: center;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
`;
