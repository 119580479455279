import React from "react";

import { GridHeaderLabel } from "../../../table/GridHeaderLabel";

export const HeaderLabelRest = () => <GridHeaderLabel label="休憩" />;

export const HeaderLabelUnused = () => <GridHeaderLabel label="休憩未取得" />;

export const HeaderLabelWorkplace = () => <GridHeaderLabel label="場所" />;

export const HeaderLabelHourlyLeave = () => <GridHeaderLabel label="時間休" />;
