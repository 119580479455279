import { Button, Classes, Dialog, FormGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";
import { Helmet } from "react-helmet";
import { passwordPlaceholder, passwordPolicyText } from "../../../utils/password";

import { inputStringHandler } from "../../../utils/react";

export type PasswordChangeMode = "password_reset" | "token_reset";

export interface PasswordChangeConsoleVM {
  mode: PasswordChangeMode;

  showConsole: boolean;
  forced: boolean;
  userId: string;
  currentPassword: string;
  newPassword: string;
  newPasswordForConfirm: string;
  errors: string[];
  allowSubmit: boolean;

  onChangeCurrentPassword: (value: string) => void;
  onChangeNewPassword: (value: string) => void;
  onChangeNewPasswordForConfirm: (value: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}

const PasswordErrors = styled.ul``;
const PasswordError = styled.li`
  color: red;
`;

@observer
export class PasswordChangeConsole extends React.Component<{ model: PasswordChangeConsoleVM }> {
  private onChangeCurrentPassword = inputStringHandler(this.props.model.onChangeCurrentPassword);
  private onChangeNewPassword = inputStringHandler(this.props.model.onChangeNewPassword);
  private onChangeNewPasswordForConfirm = inputStringHandler(this.props.model.onChangeNewPasswordForConfirm);

  public render() {
    const { model } = this.props;
    const { mode } = model;
    const title = model.forced ? "パスワード変更が必要です" : "パスワード変更";

    return (
      <Dialog
        isOpen={model.showConsole}
        canEscapeKeyClose={!model.forced}
        canOutsideClickClose={!model.forced}
        isCloseButtonShown={!model.forced}
        onClose={model.onClose}
        title={title}>
        {model.showConsole && (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}

        <form className={Classes.DIALOG_BODY} method="get" action="javascript: undefined" onSubmit={this.onSubmitForm}>
          {mode === "password_reset" && (
            <FormGroup label="ユーザID" labelFor="login-form-logind">
              <input
                autoComplete="off"
                id="login-form-logind"
                className={Classes.INPUT}
                placeholder="半角英数字のみ"
                type="text"
                readOnly={true}
                value={model.userId}
              />
            </FormGroup>
          )}

          {mode === "password_reset" && (
            <FormGroup
              label="旧パスワード"
              labelFor="login-form-password"
              helperText="今のパスワードを入力してください。">
              <input
                autoComplete="off"
                id="login-form-password"
                className={Classes.INPUT}
                placeholder={passwordPlaceholder}
                type="password"
                onChange={this.onChangeCurrentPassword}
                value={model.currentPassword}
              />
            </FormGroup>
          )}

          <FormGroup
            label="新しいパスワード"
            labelFor="login-form-password"
            helperText="新しいパスワードを入力してください。">
            <input
              id="login-form-password"
              className={Classes.INPUT}
              placeholder={passwordPlaceholder}
              type="password"
              onChange={this.onChangeNewPassword}
              value={model.newPassword}
            />
          </FormGroup>
          <FormGroup
            label="新しいパスワード（確認用）"
            labelFor="login-form-password"
            helperText="確認用に再度、新しいパスワードを入力してください。">
            <input
              id="login-form-password"
              className={Classes.INPUT}
              placeholder={passwordPlaceholder}
              type="password"
              onChange={this.onChangeNewPasswordForConfirm}
              value={model.newPasswordForConfirm}
            />
          </FormGroup>
          <p>パスワードポリシー: {passwordPolicyText}</p>
        </form>
        <div>
          {model.errors.length > 0 && (
            <PasswordErrors>
              {model.errors.map(error => (
                <PasswordError key={error}>{error}</PasswordError>
              ))}
            </PasswordErrors>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <Button
            type="submit"
            disabled={!model.allowSubmit}
            onClick={this.onSubmitForm}
            intent={Intent.PRIMARY}
            fill={true}
            large={true}
            text={"パスワードを変更する"}
          />
        </div>
      </Dialog>
    );
  }

  private onSubmitForm = () => {
    const { model } = this.props;

    if (model.allowSubmit) {
      model.onSubmit();
    }
  };
}
