import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type WorkplaceZaitakuGT4HKFProps = {
  model: WorkplaceZaitakuGT4HKFVM;
} & CellFormPrintAdaptable;

export interface WorkplaceZaitakuGT4HKFVM {
  workplaceZaitakuGT4H: KintaiInputItemState<boolean>;
}

export const WorkplaceZaitakuGT4HKF = observer((props: WorkplaceZaitakuGT4HKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.workplaceZaitakuGT4H);

  return <CheckboxField {...props} label="在宅4時間超" {...itemState} />;
});
