import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { LabelField } from "../../../form/LabelField";

export type MTotalOffCountKFProps = {
  model: MTotalOffCountKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOffCountKFVM {
  mTotalOffCount: KintaiInputItemState<number>;
}

export const MTotalOffCountKF = observer((props: MTotalOffCountKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOffCount;

  return (
    <LabelField {...props} {...itemState}>
      {itemState.value}
    </LabelField>
  );
});
