import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";
import { RegularDailyItemsVisibility } from "../../visibility/RegularDailyItemsVisibility";
import { DailyFormTransDayContainer } from "../DailyFormTransDayContainer";
import { DailyKintaiFormLine2, DailyKintaiFormLine4, EntireDailyKintaiFormLineChild } from "../DailyKintaiFormPane";
import { ActualKF } from "../fields/ActualKF";
import { AllNightOffKF } from "../fields/AllNightOffKF";
import { CkrpEnterKF } from "../fields/CkrpEnterKF";
import { CkrpLeaveKF } from "../fields/CkrpLeaveKF";
import { DiffKF } from "../fields/DiffKF";
import { DoorEnterKF } from "../fields/DoorEnterKF";
import { DoorLeaveKF } from "../fields/DoorLeaveKF";
import { FlexKF } from "../fields/FlexKF";
import { GrowingOverKF } from "../fields/GrowingOverKF";
import { HolidayKF } from "../fields/HolidayKF";
import { HourlyFamilycareKF } from "../fields/hourlyleave/HourlyFamilycare";
import { HourlyLeaveKF } from "../fields/hourlyleave/HourlyLeaveKF";
import { HourlyNursingcareKF } from "../fields/hourlyleave/HourlyNursingcare";
import { MidnightKF } from "../fields/MidnightKF";
import { OffKF } from "../fields/OffKF";
import { OverHolidayKF } from "../fields/OverHolidayKF";
import { OverHolidayMidnightKF } from "../fields/OverHolidayMidnightKF";
import { OverWeekdayKF } from "../fields/OverWeekdayKF";
import { OverWeekdayMidnightKF } from "../fields/OverWeekdayMidnightKF";
import { RemarksKF } from "../fields/RemarksKF";
import { RestKF } from "../fields/RestKF";
import { SpHolidayKF } from "../fields/SpHolidayKF";
import { SpHolidayMidnightKF } from "../fields/SpHolidayMidnightKF";
import { SpWeekdayKF } from "../fields/SpWeekdayKF";
import { SpWeekdayMidnightKF } from "../fields/SpWeekdayMidnightKF";
import { TransDayKF } from "../fields/TransDayKF";
import { UnusedRestNightKF } from "../fields/unused-rest/UnusedRestNightKF";
import { UnusedRestSunsetKF } from "../fields/unused-rest/UnusedRestSunsetKF";
import { RegularDailyVM } from "./RegularDailyVM";

@observer
export class RegularDailyKintaiFormBody extends React.Component<{ model: RegularDailyVM }> {
  public render() {
    const { props } = this;
    const { model } = props;

    const visibility = new RegularDailyItemsVisibility(model.date, model.kintaiType, model.rankPosition);

    return (
      <>
        <DailyKintaiFormLine4>
          <DoorEnterKF mode="form" model={model} />
          <DoorLeaveKF mode="form" model={model} />
          <CkrpEnterKF mode="form" model={model} />
          <CkrpLeaveKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        {visibility.transDay && (
          <DailyKintaiFormLine4>
            <DailyFormTransDayContainer>
              <TransDayKF mode="form" model={model} />
            </DailyFormTransDayContainer>
          </DailyKintaiFormLine4>
        )}
        <DailyKintaiFormLine4>
          <HourlyLeaveKF mode="form" model={model} />
          <HourlyNursingcareKF mode="form" model={model} />
          <HourlyFamilycareKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          <DiffKF mode="form" model={model} />
          <RestKF mode="form" model={model} />
          <ActualKF mode="form" model={model} />
        </DailyKintaiFormLine4>
        <DailyKintaiFormLine4>
          {visibility.flex && <FlexKF mode="form" model={model} />}
          {visibility.holiday && <HolidayKF mode="form" model={model} />}
          {visibility.midnight && <MidnightKF mode="form" model={model} />}
          {visibility.allNightOff && <AllNightOffKF mode="form" model={model} />}
        </DailyKintaiFormLine4>
        {visibility.spWeekdayHolidayItems && (
          <DailyKintaiFormLine4>
            <SpWeekdayKF mode="form" model={model} />
            <SpWeekdayMidnightKF mode="form" model={model} />
            <SpHolidayKF mode="form" model={model} />
            <SpHolidayMidnightKF mode="form" model={model} />
          </DailyKintaiFormLine4>
        )}
        <DailyKintaiFormLine4>
          {visibility.growingSum && <GrowingOverKF mode="form" model={model} />}
          {visibility.off && <OffKF mode="form" model={model} />}
        </DailyKintaiFormLine4>
        {visibility.overWeekdayHolidayItems && (
          <DailyKintaiFormLine4>
            {visibility.overWeekday && <OverWeekdayKF mode="form" model={model} />}
            {visibility.overWeekdayMidnight && <OverWeekdayMidnightKF mode="form" model={model} />}
            {visibility.overHoliday && <OverHolidayKF mode="form" model={model} />}
            {visibility.overHolidayMidnight && <OverHolidayMidnightKF mode="form" model={model} />}
          </DailyKintaiFormLine4>
        )}
        <DailyKintaiFormLine4>
          <EntireDailyKintaiFormLineChild>
            <RemarksKF mode="form" model={model} />
          </EntireDailyKintaiFormLineChild>
        </DailyKintaiFormLine4>
        {visibility.unusedRestTimes && (
          <Section>
            <Heading>未取得の休憩があれば指定してください</Heading>
            <DailyKintaiFormLine2>
              <span>
                <UnusedRestSunsetKF mode="form" model={model} />
              </span>
              <span>
                <UnusedRestNightKF mode="form" model={model} />
              </span>
            </DailyKintaiFormLine2>
          </Section>
        )}
      </>
    );
  }
}

const Section = styled.section`
  padding: 1ex 1ex 0 1ex;
  border-top: 1px solid #ccc;
`;

const Heading = styled.h1`
  font-size: 10pt;
  margin: 0;
  padding: 0;
`;
