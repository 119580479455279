import { getEnv, types } from "mobx-state-tree";

import { PasswordChangeFormModel, PasswordChangeFormModelSymbol } from "../login/PasswordChangeFormModel";
import { Profile, ProfileSymbol, ProfileType } from "./Profile";

const model = types.optional(
  types
    .model("ProfileSettingsPageModel", {})
    .views(self => {
      const myProfile = (): ProfileType => getEnv(self).get(ProfileSymbol);

      return {
        get userId() {
          return myProfile().userId;
        },

        get userName() {
          return myProfile().userName;
        },

        get mailAddress() {
          return myProfile().me!.mailAddress || "";
        },

        get rankName() {
          const rank = myProfile().rank;
          return rank ? rank.rankName : "";
        },

        get depCode() {
          return myProfile().me!.depCode || "";
        },

        get depName() {
          const dept = myProfile().dept;
          return dept ? dept.name : "";
        },

        get roleNames() {
          return myProfile().roleNames;
        },
      };
    })
    .actions(self => {
      const profile = (): typeof Profile.Type => getEnv(self).get(ProfileSymbol);
      const passwordConsole = (): typeof PasswordChangeFormModel.Type =>
        getEnv(self).get(PasswordChangeFormModelSymbol);

      return {
        route() {},
        openPasswordDialog() {
          passwordConsole().initialize(true, profile().userId);
        },
      };
    }),
  {},
);

export const ProfileSettingsPageModelSymbol = "Model_ProfileSettingsPage";
export const ProfileSettingsPageModel: ProfileSettingsPageModelModelType = model;
type ProfileSettingsPageModelInferredType = typeof model;
export interface ProfileSettingsPageModelModelType extends ProfileSettingsPageModelInferredType {}
