import { IModelType, types } from "mobx-state-tree";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(types.model("ApplicationsPageModel", {}), {});

export type ApplicationsPageModelType = typeof ApplicationsPageModel.Type;

export const ApplicationsPageModelSymbol = "ApplicationsPageModel_Symbol";
export const ApplicationsPageModel: ApplicationsPageModelModelType = model;
type ApplicationsPageModelInferredType = typeof model;
export interface ApplicationsPageModelModelType extends ApplicationsPageModelInferredType {}
