import { IModelType, types } from "mobx-state-tree";

import { dateEq } from "../../utils/date";
import { hasNoChangeReduce } from "../../utils/model";
import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { ReceptionStatus } from "../common/ReceptionStatus";
import { typesMinutes } from "../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

export const idPrefix = "NendoInterviewListEntry_";

const model = types
  .model("NendoInterviewListEntry", {
    id: types.string,
    nendo: types.number,
    targetMonth: types.Date,
    userId: types.string,

    userName: types.string,
    depCode: types.string,
    depName: types.string,
    rankName: types.string,
    lawOver: typesMinutes,
    sundayOnCount: types.number,

    isNew: false,

    receptionStatus: ReceptionStatus,
    org_receptionStatus: ReceptionStatus,
    reservedDate: types.maybe(types.Date),
    org_reservedDate: types.maybe(types.Date),
    actualDate: types.maybe(types.Date),
    org_actualDate: types.maybe(types.Date),
    note: types.string,
    org_note: types.string,
  })
  .views(self => {
    return {
      get searchedWords() {
        return [self.userId, self.userName, self.depCode, self.depName, self.rankName];
      },
    };
  })
  .views(self => {
    return {
      get hasNoChange() {
        if (self.isNew) {
          return false;
        }

        return hasNoChangeReduce([
          self.receptionStatus === self.org_receptionStatus,
          dateEq(self.reservedDate, self.org_reservedDate),
          dateEq(self.actualDate, self.org_actualDate),
          self.note === self.org_note,
        ]);
      },
      get reservedDateDisabled() {
        return self.receptionStatus !== "予約済";
      },
      get actualDateDisabled() {
        return self.receptionStatus !== "受診済";
      },
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches(self.searchedWords, searchWords);
      },
      get errors() {
        return [...this.reservedDateErrors, ...this.actualDateErrors];
      },
      get reservedDateErrors() {
        if (self.receptionStatus === "予約済" && self.reservedDate === undefined) {
          return ["予約済の場合は面接予定日を指定してください。"];
        }
        return [];
      },
      get actualDateErrors() {
        if (self.receptionStatus === "受診済" && self.actualDate === undefined) {
          return ["受診済の場合は面接日を指定してください。"];
        }
        return [];
      },
    };
  })
  .actions(self => {
    return {
      setReceptionStatus(value: typeof ReceptionStatus.Type) {
        self.receptionStatus = value;
        if (self.actualDateDisabled) {
          self.actualDate = undefined;
        }
      },
      setActualDate(value: Date | undefined) {
        self.actualDate = value;
      },
      setReservedDate(value: Date | undefined) {
        self.reservedDate = value;
      },
      setNote(value: string) {
        self.note = value;
      },
      reset() {
        self.receptionStatus = self.org_receptionStatus;
        self.actualDate = self.org_actualDate;
        self.note = self.org_note;
      },
    };
  });

export const idNendoNendoInterviewListEntry = (nendo: number, targetMonth: Date, userId: string) =>
  `${idPrefix}${nendo}_${targetMonth.toISOString()}_${userId}`;

export const NendoInterviewListEntrySymbol = "NendoInterviewListEntry_Symbol";
export const NendoInterviewListEntry: NendoInterviewListEntryModelType = model;
type NendoInterviewListEntryInferredType = typeof model;
export interface NendoInterviewListEntryModelType extends NendoInterviewListEntryInferredType {}
