import { HourBasedDays } from "@webkintai/core";
import { injectable } from "inversify";
import { flatMap } from "lodash-es";

import { ExcelColDef, generateColumns } from "../ExcelColDef";
import { excelLoadSheet } from "../excelLoadSheet";
import { excelWriteBook } from "../excelWriteBook";
import { PHExcelEntries, PHImportResult, PHImportResultRow, PHInputExcel } from "./PHInputExcel";

@injectable()
export class PHInputExcelImpl extends PHInputExcel {
  public async importExcel(file: File): Promise<PHImportResult> {
    const sheetLoadResult = await excelLoadSheet(file, sheetName);

    if (sheetLoadResult.status === "failure") {
      return sheetLoadResult;
    }

    const { sheet } = sheetLoadResult;

    const rows: PHImportResultRow[] = [];

    const nendoAndColPos: Array<{
      nendo: number;
      col: string;
    }> = [];

    sheet.eachRow(row => {
      if (row.number === 1) {
        row.eachCell(cell => {
          const label = cell.text;
          const mat = label.match(/有休休暇時間_(\d+)/);
          if (mat) {
            nendoAndColPos.push({
              nendo: +mat[1],
              col: cell.col,
            });
          }
        });
      }
      if (row.number > 1) {
        const userId = fields[0].deserializer(row.getCell(1).text);

        if (userId) {
          rows.push({
            userId,
            vacHoursPerNendo: nendoAndColPos.map(pos => ({
              nendo: pos.nendo,
              hours: nendoCsvField.deserializer(row.getCell(pos.col).text),
            })),
          });
        }
      }
    });

    return {
      status: "success",
      rows,
    };
  }
  public exportExcel(value: PHExcelEntries, targetNendos: number[]) {
    return excelWriteBook(async wb => {
      const sheet = wb.addWorksheet(sheetName);
      sheet.columns = generateColumns([
        ...fields,
        ...flatMap(
          targetNendos.map(nendo => [
            {
              ...nendoCsvField,
              label: nendoCsvField.label + nendo,
            },
            {
              ...nendoCsvFieldAsDayCount,
              label: nendoCsvFieldAsDayCount.label + nendo,
            },
          ]),
        ),
      ]);
      value.forEach(user => {
        sheet.addRow([
          fields[0].serializer(user.userId),
          fields[1].serializer(user.userName),
          fields[2].serializer(user.deptName),
          fields[3].serializer(user.rankName),
          ...flatMap(
            targetNendos.map(nendo => {
              const nendoEntry = user.getNendoEntry(nendo);
              return [
                nendoCsvField.serializer(nendoEntry ? nendoEntry.inputPhDayCount : undefined),
                nendoCsvFieldAsDayCount.serializer(nendoEntry ? nendoEntry.inputPhDayCount : undefined),
              ];
            }),
          ),
        ]);
      });
    });
  }
}

const recognizeHourText = (text: string) => (text.match(/^\d+$/) ? +text : undefined);

const sheetName = "有給休暇";

const fields: Array<ExcelColDef<any>> = [
  {
    label: "社員番号",
    type: "key",
    serializer: (value: string) => value,
    deserializer: (value: string) => value,
  },
  {
    label: "氏名",
    type: "readOnly",
    serializer: (value: string) => value,
    deserializer: (value: string) => value,
  },
  {
    label: "所属（名称）",
    type: "readOnly",
    serializer: (value: string) => value,
    deserializer: (value: string) => value,
  },
  {
    label: "ランク（名称）",
    type: "readOnly",
    serializer: (value: string) => value,
    deserializer: (value: string) => value,
  },
];

const nendoCsvField: ExcelColDef<HourBasedDays | undefined> = {
  label: "有休休暇時間_",
  type: "input",
  serializer: (value: HourBasedDays | undefined) => `${value === undefined ? "" : value.hours}`,
  deserializer: (value: string) => (value === "" ? undefined : HourBasedDays.of(recognizeHourText(value))),
};

const nendoCsvFieldAsDayCount: ExcelColDef<any> = {
  label: "有休休暇日数_",
  type: "readOnly",
  serializer: (value: number | undefined) => `${value === undefined ? "" : HourBasedDays.of(value)!.dayCount}`,
  deserializer: (value: string) => (value === "" ? undefined : value),
};
