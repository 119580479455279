import { Button, Classes, InputGroup, Intent } from "@blueprintjs/core";
import styled from "@emotion/styled";
import Help from "@webkintai/help/content/manuals/kintai/kintai_reviewer.md";
import React from "react";

import { checkIfFilterTextMatches, filterTextToSearchWords } from "../../../../utils/searchwords";
import { GuideButton } from "../../guide/GuideButton";
import { InlineTooltip } from "../../tooltip/TextTooltip";
import { ApproverUserEntry } from "./ApproverUserEntry";

export interface AssignApproverButtonProps {
  /** 注意: 退職者が選択されている場合、usersにいないユーザがここで指定されることがある */
  currentUser?: ApproverUserEntry;
  users: ApproverUserEntry[];
  onChooseApprover: (userId: string | undefined) => void;
  onCopyApproverFromLastMonth: () => void;
}

export const ApproverSelectWindow = (props: AssignApproverButtonProps) => {
  const { currentUser, users } = props;
  const [filterText, setFilterText] = React.useState("");
  const onChangeFilterText = React.useCallback(
    (ev: React.FormEvent<HTMLInputElement>) => {
      setFilterText(ev.currentTarget.value);
    },
    [setFilterText],
  );
  const filteredUsers = users.filter(u =>
    checkIfFilterTextMatches([u.userName, u.userId], filterTextToSearchWords(filterText)),
  );
  const onClickUserRow = (userId: string) => () => {
    props.onChooseApprover(userId);
  };
  const onRemoveCurrentUser = () => {
    if (currentUser) {
      props.onChooseApprover(undefined);
    }
  };

  return (
    <Window>
      <Header>
        <p>
          勤務表の
          <InlineTooltip
            content={
              <>
                <p>査閲者・承認者に指名された人は日々の有休取得などの承認や勤怠の承認操作を行う必要があります。</p>
                <p>※紙媒体による承認は原則廃止となりました。</p>
              </>
            }>
            査閲者・承認者
          </InlineTooltip>
          を必ず、管理職から一人選択する必要があります。
        </p>
        <p>
          （査閲者は自分の上司、承認者は自分の部門長を選択してください。職位が高く査閲者がいない場合は承認者のみ指名してください。）
        </p>
        <div>
          <InputGroup value={filterText} onChange={onChangeFilterText} placeholder="絞り込む" leftIcon="filter" />
        </div>
      </Header>
      <Body>
        <Table className="bp3-html-table bp3-html-table-striped">
          <thead>
            <tr>
              <th style={{ width: "7em" }} />
              <th>社員番号</th>
              <th>ユーザ名</th>
            </tr>
          </thead>
          <tbody>
            {currentUser && (
              <tr>
                <td>
                  <Button
                    className={Classes.POPOVER_DISMISS}
                    text="解除"
                    icon="delete"
                    intent={Intent.DANGER}
                    onClick={onRemoveCurrentUser}
                  />
                </td>
                <td>{currentUser.userId}</td>
                <td>{currentUser.userName}</td>
              </tr>
            )}
            {filteredUsers
              .filter(user => !currentUser || user.userId !== currentUser.userId)
              .map(user => (
                <tr key={user.userId}>
                  <td>
                    <Button className={Classes.POPOVER_DISMISS} text="選択" onClick={onClickUserRow(user.userId)} />
                  </td>
                  <td>{user.userId}</td>
                  <td>{user.userName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Body>
      <Footer>
        <Button icon="duplicate" text="査閲承認者を前月からコピー" onClick={props.onCopyApproverFromLastMonth} />
        <GuideButton markdown={Help} buttonProps={{ small: false, text: "ヘルプ" }} />
      </Footer>
    </Window>
  );
};

const Window = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 1ex;
`;

const Body = styled.div`
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const Footer = styled.div`
  padding: 0.5ex;
`;

const Table = styled.table`
  width: 100%;
`;
