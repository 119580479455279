import { encode } from "iconv-lite";

export function* htmlToStringList(elements: HTMLElement[]) {
  // Elementをfor-ofトラバースする際、 Array.from でラップしておかないと、Edgeで落ちることがある
  // https://its.ssc-dom.net/issues/583
  for (const elem of Array.from(elements)) {
    if (elem.tagName === "TABLE") {
      for (const tr of Array.from(elem.querySelectorAll("tr"))) {
        yield Array.from(tr.querySelectorAll("td, th"), it => it.textContent || "");
      }
    } else {
      yield [elem.textContent || ""];
    }
  }
}

export function stringListToCsv(stringList: string[][], lineSeparator = "\r\n") {
  return stringList.map(row => row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(",")).join(lineSeparator);
}

export function htmlToCsv(elements: HTMLElement[], lineSeparator = "\r\n") {
  return stringListToCsv([...htmlToStringList(elements)], lineSeparator);
}

export function htmlToCsvBytes(elements: HTMLElement[]) {
  return encode(stringListToCsv([...htmlToStringList(elements)]), "Shift_JIS");
}
