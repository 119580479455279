import { observer } from "mobx-react";
import React from "react";
import { HasNoChangeField } from "../../../common/form/HasNoChangeLabel";

import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RankRowVM } from "./RankPageGrid";

@observer
export class RKChangedCell extends React.Component<{ model: RankRowVM }> {
  public render() {
    const row = this.props.model;
    return <HasNoChangeField mode="cell" hasNoChange={row.hasNoChange} cellPosition={NULL_CELL_POSITION} />;
  }
}
