import React from "react";

import { CellPosition } from "./CellPosition";

export interface TableColumnDef {
  id: string;
  width: number;
  headerColSpan?: number;
  renderHeader: HeaderRenderer;
  renderBody: BodyRenderer;
  renderFooter?: FooterRenderer;
}

export const eqTableColumnDef = (l: TableColumnDef, r: TableColumnDef) => {
  return (
    l === r ||
    (l.id === r.id &&
      l.width === r.width &&
      l.headerColSpan === r.headerColSpan &&
      l.renderHeader === r.renderBody &&
      l.renderBody === r.renderBody &&
      l.renderFooter === r.renderFooter)
  );
};

export type RowIdGenerator = (rowIndex: number) => string;
export type HeaderRenderer = (cellPosition: CellPosition) => React.ReactNode;
export type BodyRenderer = (cellPosition: CellPosition, isActive?: boolean) => React.ReactNode;
export type FooterRenderer = (cellPosition: CellPosition, isActive?: boolean) => React.ReactNode | null;
