import { observer } from "mobx-react";
import React from "react";

import { HasNoChangeField } from "../../../common/form/HasNoChangeLabel";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcChangedCellVM {
  hasNoChange: boolean;
  errors: string[];
}

@observer
export class AcChangedCell extends React.Component<{ model: AcChangedCellVM }> {
  public render() {
    const { model } = this.props;
    return (
      <HasNoChangeField
        mode="cell"
        cellPosition={NULL_CELL_POSITION}
        hasNoChange={model.hasNoChange}
        errors={model.errors}
      />
    );
  }
}
