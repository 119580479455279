import { getSnapshot, Instance } from "mobx-state-tree";

import { createContainer } from "../inversify";
import { AppState, AppStateModelType } from "./AppState";
import { createGlobalStore } from "./createGlobalStore";

export const { getStore: getAppStateModel, useStore: useAppStateModel } = createGlobalStore<
  Instance<AppStateModelType>
>("app", prev => {
  let appState: any;
  const container = createContainer(() => appState);
  appState = prev ? AppState.create(getSnapshot(prev), container) : AppState.create(undefined, container);

  return appState;
});
