import { Button, Drawer } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

import { useResponsive } from "../responsive/Responsive";

export const WkDrawer: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose, children }) => {
  const { middle, small } = useResponsive();

  return (
    <Drawer isOpen={isOpen} onClose={onClose} lazy={true} size={small ? "100%" : middle ? "80%" : undefined}>
      {small && (
        <CloseArea>
          <Button minimal={true} small={true} fill={true} onClick={onClose} text="閉じる" icon="cross" />
        </CloseArea>
      )}
      {children}
    </Drawer>
  );
};

const CloseArea = styled.div`
  border-bottom: 1px solid #ccc;
`;
