import { IModelType, types } from "mobx-state-tree";

import { checkIfFilterTextMatches } from "../../utils/searchwords";
import { TransDayResultEachDayEntry } from "./TransDayResultEachDayEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("TransDayResultEntry", {
    userId: types.string,
    userName: types.string,
    deptCode: types.string,
    deptName: types.string,
    isManager: types.boolean,
    entries: types.array(TransDayResultEachDayEntry),
  })
  .views(self => {
    return {
      filterMatched(searchWords: string[]) {
        return checkIfFilterTextMatches([self.userId, self.userName, self.deptCode, self.deptName], searchWords);
      },
    };
  });

export type TransDayResultEntryType = typeof TransDayResultEntry.Type;
export const TransDayResultEntry: TransDayResultEntryModelType = model;
type TransDayResultEntryInferredType = typeof model;
export interface TransDayResultEntryModelType extends TransDayResultEntryInferredType {}
