import { inject } from "inversify";

import { AppConfirmQueue, AppConfirmQueueSymbol } from "../../models/applications/AppConfirmQueue";
import { AppConfirm } from "./AppConfirm";

export class AppConfirmImpl extends AppConfirm {
  constructor(@inject(AppConfirmQueueSymbol) private readonly appConfirmQueue: typeof AppConfirmQueue.Type) {
    super();
  }
  public confirm(params: {
    title: string;
    message: string;
    yesButtonLabel?: string;
    noButtonLabel?: string;
  }): Promise<boolean> {
    return new Promise(done => {
      this.appConfirmQueue.queue({
        title: params.title,
        message: params.message,
        resolver: done,
        yesButtonLabel: params.yesButtonLabel,
        noButtonLabel: params.noButtonLabel,
      });
    });
  }
}
