import { Button, Intent } from "@blueprintjs/core";
import Help from "@webkintai/help/content/manuals/profile/settings.md";
import { observer } from "mobx-react";
import React from "react";

import { ShrinkRibbonGuideButton } from "../../../common/guide/ShrinkRibbonGuideButton";
import { PageRibbonSection } from "../../../common/page/PageRibbonSection";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";

export interface SettingsPageRibbonVM {
  onOpeningPasswordChangeDialog: () => void;
}

@observer
export class SettingsPageRibbon extends React.Component<{ model: SettingsPageRibbonVM; small?: boolean }> {
  public render() {
    const { model, small } = this.props;
    const passwordChangeButton = (
      <Button icon="key" intent={Intent.WARNING} onClick={model.onOpeningPasswordChangeDialog} small={true}>
        パスワードの変更を行う
      </Button>
    );

    if (small) {
      return (
        <>
          {passwordChangeButton}
          <ShrinkRibbonGuideButton markdown={Help} />
        </>
      );
    }

    return (
      <>
        <GuideRibbon markdown={Help} />
        <PageRibbonSection title="パスワードの変更">{passwordChangeButton}</PageRibbonSection>
      </>
    );
  }
}
