import { Tooltip } from "@blueprintjs/core";
import styled from "@emotion/styled";
import React from "react";

const TooltipText = styled.span`
  text-decoration: underline dotted;
`;

export const InlineTooltip: React.FunctionComponent<{
  content: React.ReactNode;
}> = props => (
  <Tooltip boundary="window" content={<div style={{ maxWidth: 400 }}>{props.content}</div>}>
    <TooltipText>{props.children}</TooltipText>
  </Tooltip>
);
