import { LoginResult } from "@webkintai/api";
import { flow, getEnv, types } from "mobx-state-tree";

import { paths } from "../../routing/paths";
import { LoginApi } from "../../services/api/LoginApi";
import { AppRouter } from "../../services/AppRouter";
import { LoginTokenStore } from "../../services/LoginTokenStore";
import { AppConfirm } from "../../services/msg/AppConfirm";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { getDI } from "../common/getDI";
import { LoginStatus } from "./LoginStatus";
import { PasswordChangeFormModelSymbol, PasswordChangeFormModelType } from "./PasswordChangeFormModel";
import { fromPaths } from "../../routing/fromPaths";

const model = types.optional(
  types
    .model("Login", {
      state: LoginStatus,
      loginToken: types.maybeNull(types.string),
    })
    .volatile(self => ({
      loginNeededPromises: [] as Array<() => void>,
    }))
    .views(self => ({
      get needsLogin() {
        return self.state !== "loggedIn";
      },
    }))
    .actions(self => {
      const passwordChangeForm = (): PasswordChangeFormModelType => getEnv(self).get(PasswordChangeFormModelSymbol);

      const loginApi = (): LoginApi => getDI(self, LoginApi);
      const loginTokenStore = (): LoginTokenStore => getDI(self, LoginTokenStore);
      const appNotifier = (): AppNotifier => getDI(self, AppNotifier);
      const appRouter = (): AppRouter => getDI(self, AppRouter);
      const appConfirm = (): AppConfirm => getDI(self, AppConfirm);

      return {
        login: flow(function*(userId: string, password: string, rememberLogin?: boolean) {
          console.log("Login", userId);
          try {
            const loginResult: LoginResult = yield loginApi().doLogin(userId, password);

            if (loginResult.status === "required_to_change_password") {
              appNotifier().info({ message: "パスワードの変更が必要です" });
              passwordChangeForm().initialize(false, userId, password);
              return;
            }

            if (!loginResult.jwtToken) {
              // ありえない
              return;
            }

            self.loginToken = loginResult.jwtToken;

            self.state = "loggedIn";
            appNotifier().info({ message: "ログインしました" });

            if (rememberLogin !== undefined) {
              loginTokenStore().setRememberLogin(rememberLogin);
            }
            loginTokenStore().save(loginResult.jwtToken);

            self.loginNeededPromises.forEach(p => p());
            self.loginNeededPromises = [];
          } catch (exception) {
            appNotifier().error({ message: "ログインに失敗しました", exception });
          }
        }),

        logout: flow(function*() {
          if (
            !(yield appConfirm().confirm({
              title: "ログアウトの確認",
              message: "ログアウトしますが、よろしいですか？",
            }))
          ) {
            return;
          }

          try {
            yield loginApi().doLogout();
          } catch (e) {
            console.error("Failure on logout (but ignored)", e);
          }

          loginTokenStore().discard();
          appNotifier().info({ message: "ログアウトしました" });
          appRouter().replaceWithoutEffects(paths.index());
          window.webkintaiReset();
        }),

        ensureLogin: flow(function*() {
          self.loginToken = loginTokenStore().load();
          if (self.loginToken) {
            self.state = "loggedIn";
            return Promise.resolve();
          }

          try {
            const oidcLoginResult: LoginResult = yield loginApi().getOidcLoginToken();
            const { jwtToken } = oidcLoginResult;
            if (!jwtToken) {
              throw new Error("空白のログイントークン");
            }
            self.loginToken = jwtToken;
            self.state = "loggedIn";
            return Promise.resolve();
          } catch (exception) {
            console.log("OIDCログイントークン無し", exception);
          }

          if (fromPaths.global(location.pathname).menuItem === "loginError") {
            const { message } = fromPaths.loginError.index(location.search);
            if (message) {
              appNotifier().error({ message: `ログインエラー: ${message}` });
            }
          }

          self.state = "notLoggedIn";

          return new Promise<void>(done => {
            self.loginNeededPromises.push(done);
          });
        }),

        recoverFromLoginExpired() {
          self.state = "expired";
          return new Promise<void>(done => {
            if (self.loginNeededPromises.length === 0) {
              appNotifier().error({ message: "ログインがタイムアウトしました" });
              loginTokenStore().discard();
            }
            self.loginNeededPromises.push(done);
          });
        },
        askChangePassword() {
          self.state = "passwordChangeRequired";
        },
      };
    }),
  {
    state: "notLoggedIn",
    loginToken: null,
  },
);

export const LoginSymbol = "Login";

export type LoginType = typeof Login.Type;

export interface LoginEnv {
  apiClient: LoginApi;
}
export const Login: LoginModelType = model;
type LoginInferredType = typeof model;
export interface LoginModelType extends LoginInferredType {}
