import { injectable } from "inversify";

@injectable()
export abstract class TimeProvider {
  public abstract get currentTime(): Date;

  public abstract get currentNendo(): number;

  public abstract get referenceTargetNendoList(): number[];

  public abstract allowEditMasterData(at: Date, dataType?: DataType): boolean;
}

export type DataType = "MasterData" | "InterviewList";
