import { reverse, sortBy } from "lodash-es";

export const sortByDatesCount = <
  T extends {
    dates: unknown[];
  }
>(
  ary: T[],
  orderBy: "asc" | "desc",
) => {
  const result = sortBy(ary, it => it.dates.length);
  return orderBy === "asc" ? result : reverse(result);
};
