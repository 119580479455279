import Help from "@webkintai/help/content/manuals/admin/deptspic.md";
import { observer } from "mobx-react";
import React from "react";

import { getNendoAsDate } from "../../../../utils/calendar";
import { KintaiCalendarSelectorButton } from "../../../common/kintai-calendar-selector/KintaiCalendarSelectorButton";
import { PageRibbonLine } from "../../../common/page/PageRibbonLine";
import { GuideRibbon } from "../../../common/ribbon/GuideRibbon";
import { JumpRibbon } from "../../../common/ribbon/JumpRibbon";
import { ReloadRibbon } from "../../../common/ribbon/ReloadRibbon";
import { SaveRibbon } from "../../../common/ribbon/SaveRibbon";

export interface DeptsPageRibbonVM {
  nendo: number;
  nendoList: number[];
  onChangeNendo: (date: Date) => void;
  showsSaveRibbon: boolean;
  reloadDisabled: boolean;
  onReload: () => void;
  saveDisabled: boolean;
  onReset: () => void;
  onSave: () => void;
}

@observer
export class DeptsPageRibbon extends React.Component<{ model: DeptsPageRibbonVM }> {
  public render() {
    const { props } = this;
    const { model } = props;
    return (
      <>
        <GuideRibbon markdown={Help} />
        <JumpRibbon>
          <PageRibbonLine>
            <KintaiCalendarSelectorButton
              buttonProps={{ small: true }}
              unit="year"
              nendoList={model.nendoList}
              currentDate={getNendoAsDate(model.nendo)}
              onChangeDate={model.onChangeNendo}
            />
          </PageRibbonLine>
        </JumpRibbon>
        <ReloadRibbon disabled={model.reloadDisabled} onReload={model.onReload} />
        {model.showsSaveRibbon && (
          <SaveRibbon
            disabled={model.saveDisabled}
            onReset={model.onReset}
            onSave={model.onSave}
            useTimeoutConfirmReset={true}
          />
        )}
      </>
    );
  }
}
