import { Button, Intent } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

export const ResetButton: React.FunctionComponent<{
  disabled?: boolean;
  cancelLabel?: string;
  cancelConfirmingLabel?: string;
  onReset?: () => void;
  useTimeoutConfirm?: boolean;
}> = props => {
  const [confirmingMSec, setConfirmingMSec] = useState(0);

  useEffect(() => {
    if (confirmingMSec <= 0) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setConfirmingMSec(confirmingMSec - 1000);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [confirmingMSec]);

  return (
    <Button
      disabled={props.disabled}
      onClick={() => {
        if (!props.onReset) {
          return;
        }
        if (!props.useTimeoutConfirm) {
          props.onReset();
          return;
        }

        if (confirmingMSec <= 0) {
          setConfirmingMSec(5000);
          return;
        }

        props.onReset();
        setConfirmingMSec(0);
      }}
      small={true}
      intent={Intent.DANGER}
      icon="delete">
      {confirmingMSec > 0 && (
        <>
          {props.cancelConfirmingLabel || "取消しますか？"} ({confirmingMSec / 1000})
        </>
      )}
      {confirmingMSec <= 0 && <>{props.cancelLabel || "取消"}</>}
    </Button>
  );
};
