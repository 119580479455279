import { KintaiLawOverReportsResult, LawOverDetailEntity } from "@webkintai/api";
import { sortBy } from "lodash-es";
import { applySnapshot, flow, getEnv, IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { SaburokuApi } from "../../services/api/SaburokuApi";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { fromApiDate } from "../../utils/api";
import { getNendo } from "../../utils/calendar";
import { dateOf } from "../../utils/date";
import { filterTextToSearchWords } from "../../utils/searchwords";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DeptsSymbol, DeptsType } from "../depts/Depts";
import { NendoDeptsType } from "../depts/NendoDepts";
import { NendoRanksType } from "../ranks/NendoRanks";
import { RanksSymbol, RanksType } from "../ranks/Ranks";
import { idSaburokuEntry, SaburokuEntry } from "./SaburokuEntry";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("SaburokuPageModel", {
      /** 最終検索条件 */
      lastSearchCondition: types.optional(types.string, ""),

      /** 対象年月 */
      targetMonth: types.optional(types.Date, new Date()),

      /** フィルタテキスト */
      filterText: types.optional(types.string, ""),
      /** 管理職を含めるか */
      includeManager: types.optional(types.boolean, true),

      /** 検索結果 */
      searchResult: types.array(SaburokuEntry),
      searchResultLoadingState: types.optional(LoadingStatus, "init"),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get searchCondition() {
          return `${self.targetMonth.toISOString()}`;
        },
        get filteredRows() {
          const searchWords = filterTextToSearchWords(self.filterText);
          return sortBy(
            self.searchResult
              .filter(it => it.filterMatched(searchWords))
              .filter(it => self.includeManager || !it.isManager),
            it => it.depCode,
          );
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const appNotifier = () => getDI(self, AppNotifier);
      const saburokuApi = () => getDI(self, SaburokuApi);
      const depts = () => getEnv(self).get(DeptsSymbol) as DeptsType;
      const ranks = () => getEnv(self).get(RanksSymbol) as RanksType;
      const triggerLoad = flow(function*(force = false) {
        if (!force && self.lastSearchCondition === self.searchCondition) {
          return;
        }
        self.lastSearchCondition = self.searchCondition;
        try {
          self.searchResultLoadingState = "loading";

          const nendo = getNendo(self.targetMonth);
          const nendoDepts: NendoDeptsType = yield depts().prepareAndLoadMostRecent(nendo);
          const nendoRanks: NendoRanksType = yield ranks().prepareAndLoadMostRecent(nendo);
          const searchResult: KintaiLawOverReportsResult = yield saburokuApi().getLawOverReports(self.targetMonth);

          applySnapshot(
            self.searchResult,
            searchResult.lawOverReports.map(it => {
              const user = it.user;
              const dept = user.depCode ? nendoDepts.getDept(user.depCode) : undefined;
              const rank = user.rankCode ? nendoRanks.getRank(user.rankCode) : undefined;
              const targetMonth = fromApiDate(it.month);

              const lawOverDetailToModel = (det: LawOverDetailEntity) => ({
                lawOver: det.lawOver,
                lawOverCalc: det.lawOverCalc,
                sundayOnCount: det.sundayOnCount,
              });

              return {
                id: idSaburokuEntry(user.userId, targetMonth),

                rankCode: user.rankCode || "",
                rankName: rank ? rank.rankName : "",
                isManager: rank ? rank.isManager : false,

                depCode: user.depCode || "",
                depName: dept ? dept.name : "",

                userId: user.userId,
                userName: user.userName || "",
                targetMonth,

                thisMonth: lawOverDetailToModel(it.lawOverDetail),
                lastMonth: lawOverDetailToModel(it.lastMonth),
                monthBeforeLast: lawOverDetailToModel(it.twoMonthsAgo),

                lawOverUntilLastMonth: it.lawOverUntilLastMonthSum,
                overFortyFiveCountUntilLastMonth: it.overFortyFiveUntilLastMonthCount,
              };
            }),
          );
          self.searchResultLoadingState = "loaded";
        } catch (exception) {
          self.searchResultLoadingState = "failed";
          appNotifier().error({ message: "三六協定協議対象者一覧のロードに失敗しました", exception });
        }
      });
      const reload = () => {
        triggerLoad(true);
      };

      return {
        route(pathFragment: string) {
          const { targetYear, targetMonth } = fromPaths.dashboard.saburoku.ofDate.index(pathFragment);

          if (targetYear && targetMonth) {
            self.targetMonth = dateOf(targetYear, targetMonth);
          }

          triggerLoad();
        },
        navigateToMonth(value: Date) {
          appRouter().navigate(paths.dashboard.saburoku.ofDate(value).index());
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
        setIncludeManager(value: boolean) {
          self.includeManager = value;
        },
        reload,
      };
    }),
  {},
);

export type SaburokuPageModelType = typeof SaburokuPageModel.Type;

export const SaburokuPageModelSymbol = "SaburokuPageModel_Symbol";
export const SaburokuPageModel: SaburokuPageModelModelType = model;
type SaburokuPageModelInferredType = typeof model;
export interface SaburokuPageModelModelType extends SaburokuPageModelInferredType {}
