import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type OverRestMidnightKFProps = {
  model: OverRestMidnightKFVM;
} & CellFormPrintAdaptable;

export interface OverRestMidnightKFVM {
  overRestMidnight: KintaiInputItemState<Minutes | undefined>;
}

export const OverRestMidnightKF = observer((props: OverRestMidnightKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.overRestMidnight);

  return <HHMMField {...props} label="残業休憩 深夜" {...itemState} />;
});
