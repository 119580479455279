import React from "react";

import { CheckboxField } from "./form/CheckboxField";

export const IncludeManagerCheckbox: React.FC<{
  includeManager: boolean;
  onChangeIncludeManager: (value: boolean) => void;
}> = props => (
  <CheckboxField
    mode="form"
    label="（現）管理職を含める"
    value={props.includeManager}
    onChange={props.onChangeIncludeManager}
  />
);
