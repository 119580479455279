import { flow, getEnv, IModelType, types } from "mobx-state-tree";
import moment from "moment";

import { MoveDelta } from "../../components/common/table/MoveDelta";
import { dateOf, haveSameMonth } from "../../utils/date";
import { DiPanelMode } from "./DiPanelMode";
import { KintaiUsersSymbol, KintaiUsersType } from "./KintaiUsers";
import { PrintDetailMode } from "./misc/printdetailmode/PrintDetailMode";
import { PrintDetailModeModel } from "./misc/printdetailmode/PrintDetailModeModel";
import { MonthlyKintai } from "./MonthlyKintai";
import { RegularDailyFormTabId, RegularDailyFormTabIdType } from "./regular/RegularDailyFormTabId";
import { SPortDailyFormTabId, SPortDailyFormTabIdType } from "./sport/SPortDailyFormTabId";
import { typeDiPanelMode } from "./typeDiPanelMode";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("KintaiReportPageModel", {
      userId: types.maybe(types.string),
      // Y 座標
      date: types.Date,
      // X 座標
      colPos: types.optional(types.number, 1),
      diPanelMode: types.optional(typeDiPanelMode, "header"),
      showsPrinted: types.optional(types.boolean, false),
      printDetailMode: types.optional(PrintDetailModeModel, "detailed"),
      userKintai: types.maybe(types.reference(MonthlyKintai)),
      selectedRegularKintaiTabId: types.optional(RegularDailyFormTabId, "kintai_details"),
      selectedSPortKintaiTabId: types.optional(SPortDailyFormTabId, "kintai_details"),
    })
    .views(self => {
      return {
        get loadingStatus() {
          if (!self.userKintai) {
            return "loading";
          }
          return self.userKintai.loadingStatus;
        },
        get loadingErrorMessage() {
          if (!self.userKintai) {
            return "";
          }
          return self.userKintai.loadingErrorMessage;
        },
        get saveDisabled() {
          if (!self.userKintai) {
            return true;
          }
          return self.userKintai.isSaveLocked;
        },
        get reloadDisabled() {
          if (!self.userKintai) {
            return true;
          }

          // 変更がある場合はリロード不可（リセットしてね）
          return self.userKintai.isReloadLocked;
        },
        get validateInProgress() {
          if (!self.userKintai) {
            return false;
          }

          return self.userKintai.validatingStatus !== "loaded";
        },
      };
    })
    .actions(self => {
      const kintaiUsers = (): KintaiUsersType => getEnv(self).get(KintaiUsersSymbol);

      const load = flow(function*() {
        self.userKintai = kintaiUsers().open(self.userId!, self.date!);
        yield self.userKintai.loadIfNeeded();
      });

      const loadIfNeeded = () => load();

      const reset = () => {
        if (self.userKintai) {
          self.userKintai.reset();
        }
      };

      const save = () => {
        if (self.userKintai) {
          self.userKintai.save();
        }
      };

      const reload = () => {
        if (self.userKintai) {
          self.userKintai.load();
        }
      };

      return {
        load,
        loadIfNeeded,
        save,
        reset,
        reload,
        setDate(value: Date) {
          self.date = value;
        },
        setUserId(value: string) {
          self.userId = value;
        },
        setDiPanelMode(value: DiPanelMode) {
          self.diPanelMode = value;
        },
        setShowsPrinted(value: boolean) {
          self.showsPrinted = value;
        },
        setPrintDetailMode(value: PrintDetailMode) {
          self.printDetailMode = value;
        },
        setSelectedRegularKintaiTabId(value: RegularDailyFormTabIdType) {
          self.selectedRegularKintaiTabId = value;
        },
        setSelectedSPortKintaiTabId(value: SPortDailyFormTabIdType) {
          self.selectedSPortKintaiTabId = value;
        },
        setToAppDailyForm() {
          self.selectedRegularKintaiTabId = "kintai_application";
          self.selectedSPortKintaiTabId = "kintai_application";
        },
        movePosition(delta: MoveDelta) {
          if (delta.y !== 0) {
            const moveToDate = moment(self.date)
              .add(delta.y, "days")
              .toDate();
            if (haveSameMonth(self.date, moveToDate)) {
              this.setDate(moveToDate);
            }
          }
          if (delta.x !== 0) {
            self.colPos = Math.max(1, self.colPos + delta.x);
          }
        },
      };
    }),
  {
    date: dateOf(),
  },
);

export type KintaiReportPageModelType = typeof KintaiReportPageModel.Type;
export const KintaiReportPageModel: KintaiReportPageModelModelType = model;
type KintaiReportPageModelInferredType = typeof model;
export interface KintaiReportPageModelModelType extends KintaiReportPageModelInferredType {}
