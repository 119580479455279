import { CellPosition } from "../table/CellPosition";
import { MoveDelta } from "../table/MoveDelta";
import { PrintAdaptable } from "./PrintAdaptable";

export type CellFormPrintAdaptable = CellFormAdaptable | PrintAdaptable;

export type CellFormAdaptable = FormAdaptable | CellAdaptable;

export interface ErrorDisplayable {
  errors?: string[];
  warnings?: string[];
  infos?: string[];
}

export type FormAdaptable = {
  mode: "form";
} & ErrorDisplayable;

export type CellAdaptable = InteractiveCellComponentProps & {
  mode: "cell";
} & ErrorDisplayable;

export interface CellComponentProps {
  cellPosition: CellPosition;
}

export interface InteractiveCellComponentProps extends CellComponentProps {
  onCellFocused?: CellPositionHandler;
  isActive?: boolean;
}

export type CellPositionHandler = (cellPosition: CellPosition) => void;

export const getCellMove: (ev: React.KeyboardEvent<unknown>) => MoveDelta | null = ev => {
  const key = ev.which || ev.keyCode;
  switch (key) {
    case 9:
      return { x: ev.shiftKey ? -1 : 1, y: 0 };
    case 37:
      return { x: -1, y: 0 };
    case 38:
      return { x: 0, y: -1 };
    case 39:
      return { x: 1, y: 0 };
    case 40:
      return { x: 0, y: 1 };
  }
  return null;
};
