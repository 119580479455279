import { action, computed } from "mobx";

import { KintaiApplicationDay } from "../../../../../models/kintai/apps/KintaiApplicationDay";
import { KintaiReportPageModel } from "../../../../../models/kintai/KintaiReportPageModel";
import { PJReport } from "../../../../../models/kintai/pjreport/PJReport";
import { RegularDailyFormTabId } from "../../../../../models/kintai/regular/RegularDailyFormTabId";
import { RegularDailyKintai } from "../../../../../models/kintai/regular/RegularDailyKintai";
import { DailyApplicationVMImpl } from "../../applications/dailyform/DailyApplicationVMImpl";
import { KintaiReportVMImpl } from "../../KintaiReportVMImpl";
import { DailyPillarVMImpl } from "./DailyPillarVMImpl";
import { RegularDailyVM } from "./RegularDailyVM";

export class RegularDailyVMImpl implements RegularDailyVM {
  @computed
  public get isLocked() {
    return this.reportModel.isLocked;
  }

  @computed
  public get date() {
    return this.model.date;
  }

  @computed
  public get errors() {
    return [...this.model.errors, ...this.PJBodyErrors.map(it => `【月報】${it}`)];
  }

  @computed
  public get warnings() {
    return [...this.model.warnings, ...this.PJBodyWarnings.map(it => `【月報】${it}`)];
  }

  @computed
  public get infos() {
    return [...this.model.infos, ...this.PJBodyWarnings.map(it => `【月報】${it}`)];
  }

  @computed
  public get type1() {
    return this.model.type1.kintaiBunrui;
  }

  @computed.struct
  public get input() {
    return this.model.input;
  }

  @computed.struct
  public get arrival() {
    return this.model.arrival;
  }

  @computed.struct
  public get doorEnterTime() {
    return this.model.doorEnter.value;
  }

  @computed.struct
  public get doorLeaveTime() {
    return this.model.doorLeave.value;
  }

  @computed.struct
  public get ckrpEnterTime() {
    return this.model.ckrpEnter.value;
  }

  @computed.struct
  public get ckrpLeaveTime() {
    return this.model.ckrpLeave.value;
  }

  @computed.struct
  public get leave() {
    return this.model.leave;
  }

  @computed.struct
  public get types() {
    return [this.model.type1, this.model.type2, this.model.type3, this.model.type4];
  }

  @computed.struct
  public get transDay() {
    return this.model.transDay;
  }

  @computed.struct
  public get hourlyLeave() {
    return this.model.hourlyLeave;
  }

  @computed.struct
  public get hourlyNursingcare() {
    return this.model.hourlyNursingcare;
  }

  @computed.struct
  public get hourlyFamilycare() {
    return this.model.hourlyFamilycare;
  }

  @computed.struct
  public get rest() {
    return this.model.rest;
  }

  @computed.struct
  public get actual() {
    return this.model.actual;
  }

  @computed.struct
  public get flex() {
    return this.model.flex;
  }

  @computed.struct
  public get holiday() {
    return this.model.holiday;
  }

  @computed.struct
  public get midnight() {
    return this.model.midnight;
  }

  @computed.struct
  public get allNightOff() {
    return this.model.allNightOff;
  }

  @computed.struct
  public get spWeekday() {
    return this.model.spWeekday;
  }

  @computed.struct
  public get spWeekdayMidnight() {
    return this.model.spWeekdayMidnight;
  }

  @computed.struct
  public get spHoliday() {
    return this.model.spHoliday;
  }

  @computed.struct
  public get spHolidayMidnight() {
    return this.model.spHolidayMidnight;
  }

  @computed.struct
  public get diff() {
    return this.model.diff;
  }

  @computed.struct
  public get off() {
    return this.model.off;
  }

  @computed.struct
  public get offCount() {
    return this.model.offCount;
  }

  @computed.struct
  public get over() {
    return this.model.over;
  }

  @computed.struct
  public get overHoliday() {
    return this.model.overHoliday;
  }

  @computed.struct
  public get overHolidayMidnight() {
    return this.model.overHolidayMidnight;
  }

  @computed.struct
  public get growingOver() {
    return this.model.growingOver;
  }

  @computed.struct
  public get overWeekday() {
    return this.model.overWeekday;
  }

  @computed.struct
  public get overWeekdayMidnight() {
    return this.model.overWeekdayMidnight;
  }

  @computed.struct
  public get remarks() {
    return this.model.remarks;
  }

  @computed
  public get regularDailyFormTabId() {
    return this.pageModel.selectedRegularKintaiTabId;
  }

  @computed
  public get projectInputStyle() {
    return this.reportModel.projectInputStyle;
  }

  @computed
  public get jobNoDefs() {
    return this.reportModel.availableJobNos.map(it => ({
      jobNo: it.jobNo,
      jobName: it.jobName,
    }));
  }

  @computed
  public get dailyPillars() {
    return this.reportModel.livingPillars.map((pillar, idx) => {
      return new DailyPillarVMImpl(idx, pillar, pillar.getAt(this.date)!);
    });
  }

  @computed
  private get todaysTotalPillar() {
    return this.reportModel.totalPillar.getAt(this.date);
  }

  @computed
  public get PJBodyErrors(): string[] {
    return this.todaysTotalPillar ? this.todaysTotalPillar.errors : [];
  }

  @computed
  public get PJBodyWarnings(): string[] {
    return this.todaysTotalPillar ? this.todaysTotalPillar.warnings : [];
  }

  @computed.struct
  get PJRBodyActualTime() {
    return this.todaysTotalPillar ? this.todaysTotalPillar.actual : undefined;
  }

  @computed.struct
  get PJRBodyNotPopulatedTime() {
    return this.todaysTotalPillar ? this.todaysTotalPillar.notPopulated : undefined;
  }

  @computed
  get rankPosition() {
    return this.parent.regularMonthlyVM.rankPosition;
  }

  @computed
  get kintaiType() {
    return this.parent.regularMonthlyVM.kintaiType;
  }

  @computed
  get unusedRestSunset() {
    return this.model.unusedRestSunset;
  }

  @computed
  get unusedRestNight() {
    return this.model.unusedRestNight;
  }

  @computed
  get workplaceZaitakuGT4H() {
    return this.model.workplaceZaitakuGt4H;
  }

  @computed
  get workplaceZaitakuLE4H() {
    return this.model.workplaceZaitakuLe4H;
  }

  @computed
  get workplaceSyussya() {
    return this.model.workplaceSyussya;
  }

  @computed
  get workplaceSonota() {
    return this.model.workplaceSonota;
  }

  public dailyApp: DailyApplicationVMImpl;

  constructor(
    private readonly parent: KintaiReportVMImpl,
    private readonly pageModel: typeof KintaiReportPageModel.Type,
    private readonly appModel: typeof KintaiApplicationDay.Type,
    private readonly reportModel: typeof PJReport.Type,
    private readonly model: typeof RegularDailyKintai.Type,
  ) {
    this.dailyApp = new DailyApplicationVMImpl(this.pageModel, this.appModel);
  }

  @action.bound
  public onNavigateToDate(value: Date) {
    this.pageModel.setDate(value);
  }

  @action.bound
  public onChangeRegularDailyFormTabId(value: typeof RegularDailyFormTabId.Type) {
    this.pageModel.setSelectedRegularKintaiTabId(value);
  }

  @action.bound
  public onAddPillar() {
    this.reportModel.addEmptyPillar();
  }

  @action.bound
  public onCopyPillarsFromLastMonth() {
    this.parent.onCopyPillarsFromLastMonth();
  }

  @action.bound
  public onSwipe(direction: number) {
    this.pageModel.movePosition({ x: 0, y: direction });
  }

  @action.bound
  public onApplyDoorEnter() {
    this.model.applyDoorEnter();
  }

  @action.bound
  public onApplyDoorLeave() {
    this.model.applyDoorLeave();
  }

  @action.bound
  public onApplyCkrpEnter() {
    this.model.applyCkrpEnter();
  }

  @action.bound
  public onApplyCkrpLeave() {
    this.model.applyCkrpLeave();
  }
}
