import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type DiffKFProps = {
  model: DiffKFVM;
} & CellFormPrintAdaptable;

export interface DiffKFVM {
  diff: KintaiInputItemState<Minutes | undefined>;
}

export const DiffKF = observer((props: DiffKFProps) => {
  const { model } = props;

  const itemState = extractAsProps(model.diff);
  return <HHMMField {...props} label="差引時間" {...itemState} />;
});
