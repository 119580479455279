import { Button, IPopoverProps, Popover, Position } from "@blueprintjs/core";
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";

import { mediaQuerySmall } from "../responsive/Responsive";
import { KintaiCalendarSelector, KintaiCalendarSelectorProps } from "./KintaiCalendarSelector";

export type KintaiCalendarSelectorButtonProps = {
  buttonProps?: JSX.LibraryManagedAttributes<typeof Button, Button["props"]>;
  popoverProps?: IPopoverProps;
} & KintaiCalendarSelectorProps;

const Year = styled.span`
  ${mediaQuerySmall} {
    font-size: 8pt;
    line-height: 8pt;
    display: block;
  }
`;
const Month = Year;
const Day = styled.span``;

export const KintaiCalendarSelectorButton = (props: KintaiCalendarSelectorButtonProps) => (
  <Popover
    content={<KintaiCalendarSelector {...props} />}
    {...{
      minimal: true,
      position: Position.BOTTOM,
      boundary: "window",
      ...props.popoverProps,
    }}>
    <Button
      {...{
        icon: "timeline-events",
        rightIcon: "caret-down",
        ...props.buttonProps,
      }}>
      <Year>{moment(props.currentDate).format("YYYY年")}</Year>
      {props.unit !== "year" && (
        <Month>
          {moment(props.currentDate).format("MM月")}
          {props.unit === "day" && <Day>{moment(props.currentDate).format("DD日")}</Day>}
        </Month>
      )}
    </Button>
  </Popover>
);
