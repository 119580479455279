import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverRestMidnightKFProps = {
  model: MTotalOverRestMidnightKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverRestMidnightKFVM {
  mTotalOverRestMidnight: KintaiInputItemState<Minutes>;
}

export const MTotalOverRestMidnightKF = observer((props: MTotalOverRestMidnightKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverRestMidnight;

  return <HHMMField {...props} label="mTotalOverRestMidnightKF" {...itemState} onChange={undefined} />;
});
