import { ProgressBar } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";

import { LoadingStatusType } from "../../../models/common/LoadingStatusType";

export interface OpenedMessageVM {
  messageLoadingStatus: LoadingStatusType;
  message: string;
  subject: string;
  timestamp: Date;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Headers = styled.div`
  width: 100%;
  background-color: #eee;
  padding-left: 0.5ex;
  padding-right: 0.5ex;
`;

const Subject = styled.h1`
  font-size: 12pt;
  padding: 0;
  margin: 0;
`;

const Timestamp = styled.p`
  font-size: 10pt;
  padding: 0;
  margin: 0;
  color: #666;
`;

const MessageBody = styled.pre`
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0.2ex;
  white-space: pre-wrap;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

@observer
export class OpenedMessage extends React.Component<{ model: OpenedMessageVM }> {
  public render() {
    const { model } = this.props;

    if (model.messageLoadingStatus !== "loaded") {
      return <ProgressBar />;
    }

    return (
      <Container>
        <Headers>
          <Subject>{model.subject}</Subject>
          <Timestamp>{moment(model.timestamp).format("YYYY/MM/DD (ddd) HH:mm")}</Timestamp>
        </Headers>
        <MessageBody>{model.message}</MessageBody>
      </Container>
    );
  }
}
