import {
  ApproversResult,
  JobNoListResult,
  KintaiExportsResult,
  KintaiReportEditEntity,
  KintaiReportResult,
  KintaiReportsResult,
  MonthlyReportApproversEditEntity,
  MonthlyReportAttributesEditEntity,
  UsersResult,
} from "@webkintai/api";
import { injectable } from "inversify";

export type KintaiApiTimestampTypes = "self" | "clerk" | "review" | "department" | "hrm";

@injectable()
export abstract class KintaiApi {
  public abstract getKintai(userId: string, year: number, month: number): Promise<KintaiReportResult>;

  public abstract getKintaiReportsByUserIds(
    year: number,
    month: number,
    userIds: string[],
  ): Promise<KintaiReportsResult>;

  public abstract validateKintai(
    userId: string,
    year: number,
    month: number,
    kintaiReportEntity: KintaiReportEditEntity,
  ): Promise<KintaiReportResult>;

  public abstract saveKintai(
    userId: string,
    year: number,
    month: number,
    kintaiReportEntity: KintaiReportEditEntity,
  ): Promise<KintaiReportResult>;

  public abstract removeKintai(userId: string, year: number, month: number): Promise<unknown>;

  public abstract searchNoEntryUsers(yearMonth: Date, depCode: string): Promise<UsersResult>;

  public abstract searchReviewerSpecified(yearMonth: Date, userId: string): Promise<UsersResult>;

  public abstract searchPrivilegedKintaiBunruiSpecified(yearMonth: Date): Promise<UsersResult>;

  public abstract getApprovers(userId: string, year: number, month: number): Promise<ApproversResult>;

  public abstract updateKintaiAttributes(
    userId: string,
    year: number,
    month: number,
    payload: MonthlyReportAttributesEditEntity,
  ): Promise<unknown>;

  public abstract updateApprovers(
    userId: string,
    month: Date,
    payload: MonthlyReportApproversEditEntity,
  ): Promise<unknown>;

  public abstract recalcKintai(userId: string, year: number, month: number): Promise<unknown>;

  public abstract refreshKintaiAttributes(userId: string, year: number, month: number): Promise<unknown>;

  public abstract setTimestamp(
    userId: string,
    year: number,
    month: number,
    type: KintaiApiTimestampTypes,
  ): Promise<unknown>;

  public abstract clearTimestamp(
    userId: string,
    year: number,
    month: number,
    type: KintaiApiTimestampTypes,
  ): Promise<unknown>;

  public abstract exportKintai(yearMonth: Date): Promise<KintaiExportsResult>;

  public abstract getJobNos(userId: string, year: number, month: number): Promise<JobNoListResult>;
}

export const kintaiApiTimestampTypesToText = (type: KintaiApiTimestampTypes) => {
  switch (type) {
    case "review":
      return "査閲";
    case "department":
      return "承認";
    case "hrm":
      return "総務人事";
    case "clerk":
      return "事務";
  }
  return "";
};
