import { HourBasedDays } from "@webkintai/core";
import { IModelType, types } from "mobx-state-tree";

import { typesDays } from "../../primitives/Days";
import { typesHourBasedDays } from "../../primitives/HourBasedDays";
import { typesMinutes } from "../../primitives/Minutes";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types
  .model("RegularMonthlyTotalValues", {
    absenceDays: typesDays,
    actual: typesMinutes,
    allNightCount: types.number,
    allNightOff: typesMinutes,
    allNightOffDays: typesDays,
    careDays: typesDays,
    childcareDays: typesDays,
    compDays: typesDays,
    diff: typesMinutes,
    hourlyLeave: typesHourBasedDays,
    hourlyNursingcare: typesHourBasedDays,
    hourlyFamilycare: typesHourBasedDays,
    familycareDays: typesHourBasedDays,
    flex: typesMinutes,
    givenDays: typesDays,
    givenHours: typesMinutes,
    holiday: typesMinutes,
    holidayDays: typesDays,
    holidaySum: typesMinutes,
    injuriesDays: typesDays,
    judgmentDays: typesDays,
    lawBasis: typesMinutes,
    lawOver: typesMinutes,
    lawOverCalc: typesMinutes,
    lawOverNine: typesMinutes,
    lawOverNendoSum: typesMinutes,
    lawOverToday: typesMinutes,
    lawOverUntilLastMonthSum: typesMinutes,
    liquidate: typesMinutes,
    liquidateSum: typesMinutes,
    maternityDays: typesDays,
    menstruationDays: typesDays,
    midnight: typesMinutes,
    midnightCount: types.number,
    nonAttendanceDays: typesDays,
    normalDays: typesHourBasedDays,
    nursingcareDays: typesHourBasedDays,
    off: typesMinutes,
    offCount: types.number,
    over: typesMinutes,
    overFortyFiveUntilLastMonthCount: types.number,
    overHoliday: typesMinutes,
    overHolidayMidnight: typesMinutes,
    overSixtyFlexHoliday: typesMinutes,
    overSixtyHoliday: typesMinutes,
    overSixtyHolidayMidnight: typesMinutes,
    overSixtyLiquidate: typesMinutes,
    overSixtyWeekday: typesMinutes,
    overSixtyWeekdayMidnight: typesMinutes,
    overNendoSum: typesMinutes,
    overWeekday: typesMinutes,
    overWeekdayMidnight: typesMinutes,
    paidAllDays: typesDays,
    paidHalfDays: typesHourBasedDays,
    plainPaidAllDays: typesDays,
    plainPaidHalfDays: typesHourBasedDays,
    positiveDays: typesDays,
    refreshDays: typesDays,
    rest: typesMinutes,
    sickoutDays: typesDays,
    spDays: typesHourBasedDays,
    spHoliday: typesMinutes,
    spHolidayMidnight: typesMinutes,
    spWeekday: typesMinutes,
    spWeekdayMidnight: typesMinutes,
    summerDays: typesDays,
    sundayOnCount: types.number,
    suspensionDays: typesDays,
    transHolidays: typesDays,
    transOffDays: typesDays,
    transOnDays: typesDays,
    workplaceZaitakuDays: typesHourBasedDays,
    workplaceSyussyaDays: typesHourBasedDays,
    workplaceSonotaDays: typesHourBasedDays,
  })
  .views(self => {
    return {
      /** 休業日数 */
      get leaveDays() {
        return self.absenceDays
          .add(self.suspensionDays)
          .add(self.childcareDays)
          .add(self.careDays)
          .add(self.maternityDays);
      },
      /** 有休の集計値 */
      get paidDays() {
        return self.paidHalfDays.addDays(self.paidAllDays);
      },
      get plainPaidDays() {
        return self.plainPaidHalfDays.addDays(self.plainPaidAllDays);
      },
      /** その他休暇の集計値 */
      get otherDays() {
        return new HourBasedDays(0)
          .add(self.spDays)
          .addDays(self.menstruationDays)
          .add(self.nursingcareDays)
          .add(self.familycareDays)
          .addDays(self.judgmentDays)
          .addDays(self.injuriesDays);
      },
      /** その他休暇(介護・看護除く)の集計値 */
      get otherDaysExcludingNuFa() {
        return new HourBasedDays(0)
          .add(self.spDays)
          .addDays(self.menstruationDays)
          .addDays(self.judgmentDays)
          .addDays(self.injuriesDays);
      },
      /** その他休暇(時間休)の集計値 */
      get otherHours() {
        return new HourBasedDays(0).add(self.hourlyNursingcare).add(self.hourlyFamilycare);
      },
    };
  });

export type RegularMonthlyTotalValuesType = typeof RegularMonthlyTotalValues.Type;
export const RegularMonthlyTotalValues: RegularMonthlyTotalValuesModelType = model;
type RegularMonthlyTotalValuesInferredType = typeof model;
export interface RegularMonthlyTotalValuesModelType extends RegularMonthlyTotalValuesInferredType {}
