import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { compareMonth, dateOf } from "../../../../../utils/date";

export interface PrintedTimestampsVM {
  timestamps: {
    self?: KintaiPrintTimestamp;
    approver?: KintaiPrintTimestamp;
    reviewer?: KintaiPrintTimestamp;
    clerk?: KintaiPrintTimestamp;
    hrm?: KintaiPrintTimestamp;
  };
}

export interface KintaiPrintTimestamp {
  userName: string;
  at: Date;
}

const StampTableClass = "StampTableClass";
const StampElemClass = "StampElemClass";

const StampElem = (props: { model?: KintaiPrintTimestamp }) => {
  const { model } = props;
  if (!model) {
    return <div className={StampElemClass} />;
  }

  return (
    <div className={StampElemClass}>
      <div className="CircleContainer">
        <div className="Circle">&nbsp;</div>
      </div>
      <div className="UserName">{model.userName}</div>
      <div className="At">
        {moment(model.at).format("YYYY年")}
        <br />
        {moment(model.at).format("MM月DD日")}
        <br />
        {moment(model.at).format("HH:mm")}
      </div>
    </div>
  );
};

@observer
export class PrintedTimestamps extends React.Component<{ date: Date; model: PrintedTimestampsVM }> {
  public render() {
    const { date } = this.props;
    const { timestamps } = this.props.model;

    return (
      <div>
        <style>{`
          .${StampElemClass} {
            box-sizing: border-box;
            height: 80px;
            width: 80px;
            min-height: 5em;
            text-align: center;
          }
          .${StampElemClass} .At {
            margin-top: 1ex;
            font-size: 80%;
            color: #666;
          }
          .${StampElemClass} .CircleContainer {
            position: relative;
            width: 0;
            height: 0;
          }
          .${StampElemClass} .Circle {
            box-sizing: border-box;
            position: absolute;
            top: 10px;
            left: 10px;
            width: 60px;
            height: 60px;
            border: 4px solid rgba(255, 50, 0, 0.3);
            border-radius: 50%;
          }

          .${StampTableClass} thead th {
            padding-left: 0;
            padding-right: 0;
          }
`}</style>
        {/* ハンコ */}
        <table className={StampTableClass}>
          <thead>
            <tr>
              {/* 部門の名前が入らないので左右のパディングを切り落としている */}
              <th colSpan={1}>本人</th>
              <th colSpan={1}>{useNewDeptLabel(date) ? "総務・人事部" : "総務人事部"}</th>
              <th colSpan={1}>{useNewDeptLabel(date) ? "業務管理部" : "事業支援部"}</th>
              <th colSpan={2}>主管部門</th>
            </tr>
            <tr>
              <th>提出</th>
              <th>承認</th>
              <th>事務確認</th>
              <th>承認</th>
              <th>査閲</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <StampElem model={timestamps.self} />
              </td>
              <td>
                <StampElem model={timestamps.hrm} />
              </td>
              <td>
                <StampElem model={timestamps.clerk} />
              </td>
              <td>
                <StampElem model={timestamps.approver} />
              </td>
              <td>
                <StampElem model={timestamps.reviewer} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const useNewDeptLabel = (date: Date) => compareMonth(date, dateOf(2023, 4, 1), (l, r) => l.getTime() >= r.getTime());
