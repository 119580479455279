import { flow, types } from "mobx-state-tree";

import { idNendoDepts, NendoDepts } from "./NendoDepts";

const model = types.optional(
  types
    .model("Depts", {
      nendoDepts: types.map(NendoDepts),
    })
    .actions(self => {
      return {
        prepare(nendo: number) {
          const id = idNendoDepts(nendo);
          const existing = self.nendoDepts.get(id);
          if (existing) {
            return existing;
          }
          const newOne = NendoDepts.create({
            id,
            nendo,
          });
          self.nendoDepts.put(newOne);
          return newOne;
        },
      };
    })
    .actions(self => {
      return {
        prepareAndLoadMostRecent: flow(function*(nendo: number) {
          try {
            const candidate = self.prepare(nendo);
            yield candidate.loadIfNeeded();
            return candidate;
          } catch (e) {
            console.error(`Failed to load depts at ${nendo}.`);
          }

          const lastYearCandidate = self.prepare(nendo - 1);
          yield lastYearCandidate.loadIfNeeded();
          return lastYearCandidate;
        }),
      };
    }),
  {},
);

export const DeptsSymbol = "Depts";

export type DeptsType = typeof Depts.Type;
export const Depts: DeptsModelType = model;
type DeptsInferredType = typeof model;
export interface DeptsModelType extends DeptsInferredType {}
