import { flow, getEnv, IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendo } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { copyBetweenNendoRanksInputs, NendoRanksInput } from "./input/NendoRanksInput";
import { RanksInputSymbol, RanksInputType } from "./input/RanksInput";
import { NendoRanks } from "./NendoRanks";
import { RanksSymbol, RanksType } from "./Ranks";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("RankPageModel", {
      inSaving: types.optional(types.boolean, false),
      loadingStatus: types.optional(LoadingStatus, "init"),
      nendo: types.optional(types.number, getNendo(new Date())),
      nendoRanksInput: types.maybeNull(types.reference(NendoRanksInput)),
      nendoRanks: types.maybeNull(types.reference(NendoRanks)),

      newRankCodeText: types.optional(types.string, ""),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);
      return {
        get url() {
          return paths.admin.ranks.ofNendo(self.nendo).index();
        },
        get hasNoChange() {
          return self.nendoRanksInput ? self.nendoRanksInput.hasNoChange : true;
        },
        get allowedToEdit() {
          if (!self.nendoRanksInput) {
            return false;
          }
          return self.nendoRanksInput.allowedToEdit;
        },
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
      };
    })
    .views(self => {
      return {
        get initializeWithPrevYearDisabled() {
          return self.loadingStatus !== "loaded" || self.inSaving;
        },
        get reloadDisabled() {
          return self.loadingStatus === "loading" || self.inSaving || !self.hasNoChange;
        },
        get saveDisabled() {
          return self.loadingStatus !== "loaded" || self.inSaving || self.hasNoChange;
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const appNotifier = () => getDI(self, AppNotifier);
      const ranksInput = (): RanksInputType => getEnv(self).get(RanksInputSymbol);
      const ranks = (): RanksType => getEnv(self).get(RanksSymbol);

      const loadNendo = flow(function*(nendo: number, force = false) {
        const nendoRanks = ranks().prepare(nendo);
        const nendoRanksInput = ranksInput().prepare(nendo);

        if (force) {
          yield nendoRanks.load();
        } else {
          yield nendoRanks.loadIfNeeded();
        }

        if (force || nendoRanksInput.hasNoChange) {
          nendoRanksInput.setOrigin(nendoRanks);
          nendoRanksInput.copy();
        }
      });

      const initWithPrevNendo = flow(function*() {
        const { nendo } = self;
        const prevNendo = nendo - 1;
        if (!self.nendoRanksInput) {
          return;
        }

        try {
          const prevNendoRanks = ranksInput().prepare(prevNendo);
          yield loadNendo(prevNendo, false);
          copyBetweenNendoRanksInputs(prevNendoRanks, self.nendoRanksInput);
        } catch (exception) {
          appNotifier().error({ message: "ランク情報取得中にエラーが発生しました。", exception });
        }
      });

      const triggerLoad = flow(function*(force = false) {
        const { nendo } = self;
        try {
          self.loadingStatus = "loading";

          self.nendoRanks = ranks().prepare(nendo);
          self.nendoRanksInput = ranksInput().prepare(nendo);
          yield loadNendo(nendo, force);

          self.loadingStatus = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "ランク情報取得中にエラーが発生しました。", exception });
          self.loadingStatus = "failed";
        }
      });

      const save = flow(function*() {
        try {
          self.inSaving = true;
          yield self.nendoRanksInput!.save();
          appNotifier().info({ message: "ランク情報を保存しました。" });
          triggerLoad(true);
        } catch (exception) {
          appNotifier().error({ message: "ランク情報保存中にエラーが発生しました。", exception });
        } finally {
          self.inSaving = false;
        }
      });

      const addNewRank = () => {
        if (!self.nendoRanksInput) {
          return;
        }

        self.nendoRanksInput.addNewRank(self.newRankCodeText);
      };

      return {
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.admin.ranks.ofNendo(nendo).index());
        },

        route(pathFragment: string) {
          const { targetYear } = fromPaths.admin.ranks.ofYear.index(pathFragment);

          if (targetYear) {
            self.nendo = targetYear;
          }

          triggerLoad();
        },
        initWithPrevNendo,

        setNewRankCodeText(value: string) {
          self.newRankCodeText = value;
        },
        addNewRank,

        save,

        reload() {
          triggerLoad(true);
        },

        reset() {
          self.nendoRanksInput!.copy();
        },
      };
    }),
  {},
);

export type RankPageModelType = typeof RankPageModel.Type;

export const RankPageModelSymbol = "RankPageModel_Symbol";
export const RankPageModel: RankPageModelModelType = model;
type RankPageModelInferredType = typeof model;
export interface RankPageModelModelType extends RankPageModelInferredType {}
