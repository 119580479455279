import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type SpHolidayMidnightKFProps = {
  model: SpHolidayMidnightKFVM;
} & CellFormPrintAdaptable;

export interface SpHolidayMidnightKFVM {
  spHolidayMidnight: KintaiInputItemState<Minutes | undefined>;
}

export const SpHolidayMidnightKF = observer((props: SpHolidayMidnightKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.spHolidayMidnight);

  return <HHMMField {...props} label="特勤 休日深夜" {...itemState} />;
});
