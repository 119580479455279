import { observer } from "mobx-react";
import React from "react";

import { RankPositionType } from "../../../../models/common/RankPosition";
import { WorkStyleType } from "../../../../models/common/WorkStyle";
import { GridSize } from "../../../common/table/GridSize";
import { GridTable } from "../../../common/table/GridTable";
import { TableColumnDef } from "../../../common/table/TableColumnDef";
import { RKChangedCell } from "./RKChangedCell";
import { RKPositionCell } from "./RKPositionCell";
import { RKRankNameCell } from "./RKRankNameCell";
import { RKRoleTypeSelectCell } from "./RKRoleTypeSelectCell";
import { RKWorkingTypeCell } from "./RKWorkingTypeCell";

export interface RankPageGridVM {
  rankRows: RankRowVM[];
}

export interface RankRowVM {
  rankCode: string;
  rankName: string;
  onChangeRankName: (value: string) => void;
  disabled: boolean;
  workingType?: WorkStyleType;
  onChangeWorkStyleCode: (value: WorkStyleType | undefined) => void;
  jobKindCode?: RankPositionType;
  onChangeJobKindCode: (value: RankPositionType | undefined) => void;
  roleCode: string;
  onChangeRoleCode: (value: string | undefined) => void;
  hasNoChange: boolean;
}

@observer
export class RankPageGrid extends React.Component<{ model: RankPageGridVM }> {
  public render() {
    return (
      <GridTable
        headerColumns={this.headerColumns}
        bodyColumns={this.bodyColumns}
        rowCount={this.props.model.rankRows.length}
      />
    );
  }

  private get headerColumns(): TableColumnDef[] {
    const { model } = this.props;
    return [
      {
        id: "変更",
        width: GridSize.changesHereField,
        renderHeader: () => "変更",
        renderBody: ({ rowIndex }) => <RKChangedCell model={model.rankRows[rowIndex - 1]} />,
      },
      {
        id: "ランクコード",
        width: GridSize.rankCodeField,
        renderHeader: () => "コード",
        renderBody: ({ rowIndex }) => model.rankRows[rowIndex - 1].rankCode,
      },
    ];
  }

  private get bodyColumns(): TableColumnDef[] {
    const { model } = this.props;
    const rows = model.rankRows;

    return [
      {
        id: "ランク名称",
        width: GridSize.rankNameField,
        renderHeader: () => "ランク名称",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <RKRankNameCell model={row} />;
        },
      },
      {
        id: "勤務形態",
        width: GridSize.workingTypeField,
        renderHeader: () => "勤務形態",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <RKWorkingTypeCell model={row} />;
        },
      },
      {
        id: "一般・管理職",
        width: GridSize.positionTypeField,
        renderHeader: () => "一般・管理職",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <RKPositionCell model={row} />;
        },
      },
      {
        id: "ロール",
        width: GridSize.roleNameField,
        renderHeader: () => "ロール",
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <RKRoleTypeSelectCell model={row} />;
        },
      },
    ];
  }
}
