import { ApiHourBasedDays, HourBasedDays } from "@webkintai/core";
import { types } from "mobx-state-tree";

export const typesHourBasedDays = types.custom<number | ApiHourBasedDays, HourBasedDays>({
  name: "HourBasedDays",

  fromSnapshot(value: number | ApiHourBasedDays) {
    return new HourBasedDays(typeof value === "number" ? value : value && value.hours);
  },
  toSnapshot(value: HourBasedDays) {
    if (value === undefined) {
      return undefined as any;
    }

    return value.hours;
  },
  isTargetType(value: number | HourBasedDays): boolean {
    if (value === undefined) {
      return true;
    }

    return value instanceof HourBasedDays;
  },
  getValidationMessage(value: number): string {
    return "";
  },
});
