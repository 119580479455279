import { observer } from "mobx-react";
import React from "react";

import { manualLinks } from "../../../utils/manuals";
import { SubMenuButton } from "../../common/page/SubMenuButton";

export interface HelpSubMenuVM {}

@observer
export class HelpSubMenu extends React.Component<{ model: HelpSubMenuVM }> {
  public render() {
    return (
      <>
        <SubMenuButton icon="box" onClick={this.openManual}>
          利用者マニュアル
        </SubMenuButton>
      </>
    );
  }

  private openManual = () => {
    window.open(manualLinks.general);
  };
  private openManualForTesters = () => {
    window.open(manualLinks.testers);
  };
}
