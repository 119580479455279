import { Button, Classes, Divider, Hotkey, Hotkeys } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { PageHeaderLeft } from "../../../common/page/PageHeaderLeft";
import { PageHeaderRight } from "../../../common/page/PageHeaderRight";
import { PageTile } from "../../../common/page/PageTile";
import { InlineTooltip } from "../../../common/tooltip/TextTooltip";

export interface KintaiPageFrameVM {
  gridFrameSize: number;
  onChangeGridFrameSize: (value: number) => void;
  onCloseEditMode: () => void;
  onNavigateToKintaiPage: () => void;
  onMoveActiveRowPosition: (direction: number) => void;
}

@observer
export class KintaiPageFrame extends React.Component<{ model: KintaiPageFrameVM }> {
  public render() {
    const { model } = this.props;

    return (
      <PageTile
        header={
          <>
            <PageHeaderLeft>
              <Button onClick={model.onCloseEditMode} minimal={true} className={Classes.SMALL} icon="small-cross">
                閉じる
              </Button>
              <Divider tagName="span" />
              <Button onClick={this.prev} minimal={true} className={Classes.SMALL} icon="chevron-left">
                前
              </Button>
              <Button onClick={this.next} minimal={true} className={Classes.SMALL} rightIcon="chevron-right">
                次
              </Button>
            </PageHeaderLeft>
            <PageHeaderRight>
              <Button onClick={model.onNavigateToKintaiPage} minimal={true} className={Classes.SMALL} icon="link">
                <InlineTooltip content={"勤怠一覧から離れ、専用の画面で閲覧します。"}>
                  個別の勤怠画面で見る
                </InlineTooltip>
              </Button>
            </PageHeaderRight>
          </>
        }>
        {this.props.children}
      </PageTile>
    );
  }

  public renderHotkeys() {
    return (
      <Hotkeys>
        <Hotkey global={true} combo="Shift+j" label="前の勤務表にジャンプ" onKeyDown={this.prev} />
        <Hotkey global={true} combo="Shift+k" label="次の勤務表にジャンプ" onKeyDown={this.next} />
      </Hotkeys>
    );
  }

  private prev = () => {
    this.props.model.onMoveActiveRowPosition(-1);
  };

  private next = () => {
    this.props.model.onMoveActiveRowPosition(1);
  };
}
