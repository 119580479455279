import { observer } from "mobx-react";
import React from "react";

import { extractAsProps } from "../../../../../../models/kintai/extractAsProps";
import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../../form/CellFormAdaptable";
import { CheckboxField } from "../../../../form/CheckboxField";

export type UnusedRestNightKFProps = {
  model: UnusedRestNightKFVM;
} & CellFormPrintAdaptable;

export interface UnusedRestNightKFVM {
  unusedRestNight: KintaiInputItemState<boolean>;
}

/** 休憩未取得 */
export const UnusedRestNightKF = observer((props: UnusedRestNightKFProps) => {
  const { model } = props;
  const itemState = extractAsProps(model.unusedRestNight);

  return <CheckboxField {...props} label="21:00-21:30 は未取得です" {...itemState} />;
});
