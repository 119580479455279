import { HourBasedDays, hourBasedDaysEq } from "@webkintai/core";
import { IModelType, types } from "mobx-state-tree";

import { TimeProvider } from "../../services/TimeProvider";
import { getNendoAsDate } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { typesHourBasedDays } from "../primitives/HourBasedDays";
import { prefixedIdType } from "../utils";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;
export const idPrefix = "PHEntry_";

const model = types
  .model("PHEntry", {
    id: prefixedIdType(idPrefix),
    userId: types.string,
    nendo: types.number,

    phDayCount: types.maybe(typesHourBasedDays),
    inputPhDayCount: types.maybe(typesHourBasedDays),
  })
  .views(self => {
    const timeProvider = () => getDI(self, TimeProvider);

    return {
      get allowedToEdit() {
        return timeProvider().allowEditMasterData(getNendoAsDate(self.nendo));
      },
      get hasNoChange() {
        return hourBasedDaysEq(self.phDayCount, self.inputPhDayCount);
      },
    };
  })
  .actions(self => {
    return {
      setInputPhDayCount(value: HourBasedDays | undefined) {
        self.inputPhDayCount = value;
      },
      reset() {
        self.inputPhDayCount = self.phDayCount;
      },
    };
  });

export const idPHEntry = (userId: string, nendo: number) => `${idPrefix}${userId}_${nendo}`;

export type PHEntryType = typeof PHEntry.Type;

export const PHEntrySymbol = "PHEntry_Symbol";
export const PHEntry: PHEntryModelType = model;
type PHEntryInferredType = typeof model;
export interface PHEntryModelType extends PHEntryInferredType {}
