import { Button } from "@blueprintjs/core";
import { observer } from "mobx-react";
import React from "react";

import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";

export type AppPostButtonKFProps = {
  model: AppPostButtonKFVM;
} & CellFormPrintAdaptable;

export interface AppPostButtonKFVM {
  onAppPost: () => void;
  postDisabled: boolean;
}

/**
 * 勤怠申請ボタン
 */
export const AppPostButtonKF = observer((props: AppPostButtonKFProps) => {
  const { model } = props;

  return <Button disabled={model.postDisabled} onClick={model.onAppPost} text="申請する" />;
});
