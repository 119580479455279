import { types } from "mobx-state-tree";

import { idNendoCalendar, NendoCalendar, NendoCalendarType } from "./NendoCalendar";

const model = types.optional(
  types
    .model("NendoCalendars", {
      calendars: types.map(NendoCalendar),
    })
    .actions(self => ({
      prepare(nendo: number): NendoCalendarType {
        const id = idNendoCalendar(nendo);
        const existingCalendar = self.calendars.get(id);
        if (existingCalendar) {
          return existingCalendar;
        }

        const calendar = self.calendars.put(
          NendoCalendar.create({
            id,
            nendo,
          }),
        );
        return calendar;
      },
    })),
  {},
);

export type NendoCalendarsType = typeof NendoCalendars.Type;

export const NendoCalendarsSymbol = "NendoCalendars_Symbol";
export const NendoCalendars: NendoCalendarsModelType = model;
type NendoCalendarsInferredType = typeof model;
export interface NendoCalendarsModelType extends NendoCalendarsInferredType {}
