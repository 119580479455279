import { action, computed, observable } from "mobx";

import { lazyInject } from "../../../didecorators";
import { KintaiListDisplayedRowsType } from "../../../models/kintai_list/KintaiListDisplayedRowsType";
import { KintaiListPageModel, KintaiListPageModelSymbol } from "../../../models/kintai_list/KintaiListPageModel";
import { MyPrivileges } from "../../../services/prv/MyPrivileges";
import { getNendo } from "../../../utils/calendar";
import { DeptSelectorVMImpl } from "../../common/dept-selector/DeptSelectorVMImpl";
import { KintaiReportVMImpl } from "../../common/kintai-table/KintaiReportVMImpl";
import { KintaiListPageVM } from "./KintaiListPage";
import { NoRegKintaiListPageGridVM } from "./noregistration/NoRegKintaiListPageGrid";
import { NoRegKintaiListPageGridRowVMImpl } from "./noregistration/NoRegKintaiListPageGridRowVMImpl";
import { RegularKintaiListPageGridVM } from "./regular/RegularKintaiListPageGrid";
import { RegularKintaiListPageGridRowVMImpl } from "./regular/RegularKintaiListPageGridRowVMImpl";
import { KintaiListPageRibbonVM } from "./ribbon/KintaiListPageRibbon";
import { KintaiListPageSearchType } from "./ribbon/KintaiListPageSearchType";
import { SPortKintaiListPageGridVM } from "./sport/SPortKintaiListPageGrid";
import { SPortKintaiListPageGridRowVMImpl } from "./sport/SPortKintaiListPageGridRowVMImpl";

export class KintaiListPageVMImpl
  implements
    KintaiListPageVM,
    KintaiListPageRibbonVM,
    RegularKintaiListPageGridVM,
    SPortKintaiListPageGridVM,
    NoRegKintaiListPageGridVM {
  @lazyInject(KintaiListPageModelSymbol)
  private pageModel: typeof KintaiListPageModel.Type;

  @lazyInject(MyPrivileges)
  private myPrivileges: MyPrivileges;

  @observable
  private deptSelectorFilterText: string = "";

  public get ribbonModel() {
    return this;
  }
  public get regularGridModel() {
    return this;
  }
  public get sportGridModel() {
    return this;
  }
  public get noRegGridModel() {
    return this;
  }

  @computed
  public get searchResultLoadingState() {
    return this.pageModel.searchResultLoadingState;
  }

  @computed
  public get searchMode() {
    return this.pageModel.mode;
  }

  @computed
  public get targetMonth() {
    return this.pageModel.targetMonth;
  }

  @computed
  public get targetDepCode() {
    return this.pageModel.targetDepCode;
  }

  @computed
  public get deptsLoadingState() {
    return this.pageModel.deptsLoadingState;
  }

  @computed
  public get deptSelectorVM() {
    const nendoDepts = this.pageModel.nendoDepts!;
    return new DeptSelectorVMImpl(
      this.pageModel.targetDept,
      nendoDepts,
      this.deptSelectorFilterText,
      selectedDept => {
        this.pageModel.navigateToDepCode(selectedDept.depCode);
      },
      this.setDeptSelectorFilterText,
    );
  }

  @computed
  public get searchButtonEnabled() {
    return this.pageModel.deptsLoadingState === "loaded";
  }

  @computed
  get activeKintaiReport() {
    if (!this.pageModel.showActiveRow) {
      return undefined;
    }
    return new KintaiReportVMImpl(this.pageModel.activeRow);
  }

  @computed
  get gridFrameSize() {
    return this.pageModel.gridFrameSize;
  }

  @computed
  public get regularRows() {
    if (this.selectedType === "Non-Flex") {
      return this.pageModel.filteredNonFlexRows.map(row => new RegularKintaiListPageGridRowVMImpl(this.pageModel, row));
    }
    return this.pageModel.filteredFlexRows.map(row => new RegularKintaiListPageGridRowVMImpl(this.pageModel, row));
  }

  @computed
  public get month() {
    return this.pageModel.targetMonth;
  }

  @computed
  public get nendo() {
    return getNendo(this.pageModel.targetMonth);
  }

  @computed
  public get kintaiType() {
    if (this.selectedType === "NoReg") {
      return "Flex";
    }
    return this.selectedType;
  }

  @computed
  public get sPortRows() {
    return this.pageModel.filteredSPortRows.map(row => new SPortKintaiListPageGridRowVMImpl(this.pageModel, row));
  }

  @computed
  public get noRegRows() {
    return this.pageModel.filteredNoRegistrationRows.map(
      row => new NoRegKintaiListPageGridRowVMImpl(this.pageModel, row),
    );
  }

  @computed
  get selectedType() {
    return this.pageModel.displayedRowsType;
  }

  @computed
  public get nonFlexRows() {
    return this.pageModel.filteredNonFlexRows.map(row => new RegularKintaiListPageGridRowVMImpl(this.pageModel, row));
  }

  @computed
  get flexCount() {
    return this.pageModel.flexRows.length;
  }

  @computed
  get nonFlexCount() {
    return this.pageModel.nonFlexRows.length;
  }

  @computed
  get sPortCount() {
    return this.pageModel.sPortRows.length;
  }

  @computed
  get noRegCount() {
    return this.pageModel.noRegistrationRows.length;
  }

  @computed
  get showCloseDeptSection() {
    return this.pageModel.showCloseDept;
  }

  @computed
  get closedMonth() {
    return this.pageModel.closedMonth;
  }

  @computed
  get showCloseDept() {
    return this.pageModel.deptKintaiCloseAvailableOp === "close";
  }

  @computed
  get deptIsClosed() {
    return this.pageModel.isDeptKintaiClosed;
  }

  @computed
  get showReopenDept() {
    return this.pageModel.deptKintaiCloseAvailableOp === "reopen";
  }

  @computed
  get filterText() {
    return this.pageModel.filterText;
  }

  @computed
  get filterApprovalOnYou() {
    return this.pageModel.filterApprovalOnYou;
  }

  @computed
  get filterReviewerAssignedToMe() {
    return this.pageModel.filterReviewerAssignedToMe;
  }

  @computed
  get filterApproverAssignedToMe() {
    return this.pageModel.filterApproverAssignedToMe;
  }

  @computed
  get filterPrivilegedApproval() {
    return this.pageModel.filterPrivilegedApproval;
  }

  @computed
  get bulkAuthMode() {
    return this.pageModel.bulkAuthMode;
  }

  @computed
  get showsBulkAuth() {
    return this.pageModel.bulkAuthMode;
  }

  @computed
  get bulkAuthChangeCount() {
    return this.pageModel.bulkAuthChangeCount;
  }

  @computed
  get saveBulkAuthDisabled() {
    return this.pageModel.saveBulkAuthDisabled;
  }

  @action.bound
  public onSaveBulkAuth() {
    this.pageModel.saveBulkAuth();
  }

  @computed
  get resetBulkAuthDisabled() {
    return this.pageModel.resetBulkAuthDisabled;
  }

  @action.bound
  public onResetBulkAuth() {
    this.pageModel.resetBulkAuth();
  }

  @action.bound
  public onCloseDept() {
    this.pageModel.closeDept();
  }

  @action.bound
  public onReopenDept() {
    this.pageModel.reopenDept();
  }

  @action.bound
  public onChangeSearchMode(value: KintaiListPageSearchType) {
    this.pageModel.navigateToMode(value);
  }

  @action.bound
  public onChangeMonth(month: Date) {
    this.pageModel.navigateToMonth(month);
  }

  @computed
  public get nendoList() {
    return this.pageModel.nendoList;
  }

  @action.bound
  public onCloseEditMode() {
    this.pageModel.setActiveRow(undefined);
  }

  @action.bound
  public onNavigateToKintaiPage() {
    this.pageModel.navigateToActiveRowKintai();
  }

  @action.bound
  public onChangeGridFrameSize(value: number) {
    this.pageModel.setGridFrameSize(value);
  }

  @action.bound
  public onMoveActiveRowPosition(direction: number) {
    this.pageModel.moveActiveRow(direction);
  }

  @action.bound
  public onChooseType(value: typeof KintaiListDisplayedRowsType.Type) {
    this.pageModel.setDisplayedRowsType(value);
  }

  @action.bound
  public onChangeFilterText(value: string) {
    this.pageModel.setFilterText(value);
  }

  @action.bound
  public onChangeFilterApprovalOnYou(value: boolean) {
    this.pageModel.setrFilterApprovalOnYou(value);
  }

  @action.bound
  public onChangeFilterReviewerAssignedToMe(value: boolean) {
    this.pageModel.setFilterReviewerAssignedToMe(value);
  }

  @action.bound
  public onChangeFilterApproverAssignedToMe(value: boolean) {
    this.pageModel.setFilterApproverAssignedToMe(value);
  }

  @action.bound
  public onChangeFilterPrivilegedApproval(value: boolean) {
    this.pageModel.setFilterPrivilegedApproval(value);
  }

  @action.bound
  public onReload() {
    this.pageModel.reload();
  }

  @action.bound
  public onReloadOnFailure() {
    this.pageModel.reload();
  }

  @action.bound
  public onChangeBulkAuthMode(value: boolean) {
    this.pageModel.setBulkAuthMode(value);
  }

  @computed
  public get lastLoadTime() {
    return this.pageModel.lastLoadTime;
  }

  @action.bound
  private setDeptSelectorFilterText(value: string) {
    this.deptSelectorFilterText = value;
  }
}
