import { IModelType, types } from "mobx-state-tree";

import { idNendoRanksInput, NendoRanksInput } from "./NendoRanksInput";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("RanksInput", {
      nendoRanks: types.map(NendoRanksInput),
    })
    .actions(self => {
      return {
        prepare(nendo: number) {
          const id = idNendoRanksInput(nendo);
          const existing = self.nendoRanks.get(id);
          if (existing) {
            return existing;
          }

          const created = NendoRanksInput.create({
            id,
            nendo,
          });
          self.nendoRanks.put(created);

          return created;
        },
      };
    }),
  {},
);

export type RanksInputType = typeof RanksInput.Type;

export const RanksInputSymbol = "RanksInput_Symbol";
export const RanksInput: RanksInputModelType = model;
type RanksInputInferredType = typeof model;
export interface RanksInputModelType extends RanksInputInferredType {}
