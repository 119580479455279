import { UserMonthlyDashboardData } from "../../../../../../schema/build/commonjs/main/typescript";
import { PrivateDDTableData, PrivateDDUserDatesRow } from "../PrivateDDTableData";
import { sortByDatesCount } from "./sortByDatesCount";

export const calcPrvKintaiWarnings = (value: UserMonthlyDashboardData): PrivateDDTableData<PrivateDDUserDatesRow> => {
  const target = value.monthlyData.kintaiWarnings;
  return {
    name: "勤怠警告有",
    warned: false,
    warningCount: target.filter(it => it.dates.length > 0).length,
    unit: "件",
    rows: sortByDatesCount(
      target.map(it => ({
        user: it.userAttributes,
        warned: true,
        dates: it.dates.map(date => new Date(date)),
      })),
      "desc",
    ),
  };
};
