import { computed } from "mobx";

import { TransDayPageModel } from "../../../../models/trans_day/TransDayPageModel";
import { TransDayResultEntry } from "../../../../models/trans_day/TransDayResultEntry";
import { TransDayPageTableRowVM } from "./TransDayPageTable";
import { TransDayRowDetailsVMImpl } from "./TransDayRowDetailsVMImpl";

export class TransDayPageTableRowVMImpl implements TransDayPageTableRowVM {
  constructor(
    private readonly pageModel: typeof TransDayPageModel.Type,
    private readonly model: typeof TransDayResultEntry.Type,
  ) {}

  @computed
  public get userId() {
    return this.model.userId;
  }

  @computed
  public get userName() {
    return this.model.userName;
  }

  @computed
  public get deptCode() {
    return this.model.deptCode;
  }

  @computed
  public get deptName() {
    return this.model.deptName;
  }

  @computed
  public get isManager() {
    return this.model.isManager;
  }

  @computed
  public get details() {
    return this.model.entries.map(it => new TransDayRowDetailsVMImpl(this, this.pageModel, it)).filter(it => it.shows);
  }

  @computed
  public get shows() {
    return this.details.length > 0;
  }
}
