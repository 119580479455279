import styled from "@emotion/styled";
import { Minutes } from "@webkintai/core";
import classNames from "classnames";
import React from "react";
import { useRef } from "react";
import { useCallback } from "react";

import { minAsHourText } from "../../../utils/hours";
import { CellFormPrintAdaptable } from "./CellFormAdaptable";
import { ErrorOverlayProps } from "./ErrorOverlay";
import { CellHHMMField } from "./hhmm/CellHHMMField";
import { FormHHMMField } from "./hhmm/FormHHMMField";

export type HHMMFieldCoreProps = {
  label?: string;
  disableZeroGray?: boolean;
  printHourAsDecimal?: boolean;
  placeholder?: string;
  /** 0:00 からの 相対分 */
  value: Minutes | undefined;
  hasNoChange?: boolean;
  disabled?: boolean;
  disabledButShowsErrors?: boolean;
  allowTimeInput?: boolean;
  onChange?: (value: Minutes | undefined) => void;
} & ErrorOverlayProps;
export type HHMMFieldProps = HHMMFieldCoreProps & CellFormPrintAdaptable;

export const HHMMField: React.FunctionComponent<HHMMFieldProps> = props => {
  const showsZeroAsGray = props.disableZeroGray !== true && props.value && props.value.minutes === 0;
  if (props.mode === "print") {
    return (
      <Print className={classNames(props.value === undefined || (showsZeroAsGray && "Zero"))}>
        {hhmmHourAsDisplayText(props.value, false, props.printHourAsDecimal === true)}
      </Print>
    );
  }

  if (props.mode === "cell") {
    return <CellHHMMField {...props} />;
  }

  return <FormHHMMField {...props} />;
};

export const hhmmHourAsDisplayText = (d: Minutes | undefined, hh = true, decimal = false) => {
  return minAsHourText(d ? d.minutes : undefined, hh, decimal);
};

const Print = styled.span`
  &.Zero {
    color: rgba(0, 0, 0, 0.3);
  }
`;
