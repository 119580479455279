import styled from "@emotion/styled";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../../models/kintai/kintaiinput/KintaiInput";
import { FormLabel } from "../../../../form/FormLabel";

export type WorkplaceSectionProps = {
  model: { workplaceZaitakuGT4H: KintaiInputItemState<boolean> };
  lines: React.ReactNode[];
};

export const WorkplaceFormLabelKF = observer(({ model, lines }: WorkplaceSectionProps) => {
  const { errors, warnings, infos } = model.workplaceZaitakuGT4H;

  return (
    <Container>
      <FormLabel
        {...{ errors, warnings, infos }}
        label={
          <>
            勤務場所（退勤時間まで入れた場合に入力可＆<strong>入力必須</strong>）
          </>
        }>
        {lines.map((line, idx) => (
          <WorkplaceSectionLine key={idx}>{line}</WorkplaceSectionLine>
        ))}
      </FormLabel>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-items: stretch;
  padding: 1ex;
`;

const WorkplaceSectionLine = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
  }
`;
