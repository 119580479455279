import { types, Instance } from "mobx-state-tree";

export const model = types
  .model("LoginOidcSite", {
    id: types.string,
    name: types.string,
    url: types.string,
  })
  .views(self => {
    return {};
  })
  .actions(self => {
    return {};
  });

export const LoginOidcSite: LoginOidcSiteModelType = model;
type LoginOidcSite_InferredType = typeof model;
export interface LoginOidcSiteModelType extends LoginOidcSite_InferredType {}
type LoginOidcSiteIIf = Instance<typeof LoginOidcSite>;
export interface LoginOidcSiteInstance extends LoginOidcSiteIIf {}
