import { observer } from "mobx-react";
import React from "react";

import { LabelField } from "../../../common/form/LabelField";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";

export interface AcUserIdCellVM {
  userId: string;
  onNavigateToKintai: () => void;
}

@observer
export class AcUserIdCell extends React.Component<{ model: AcUserIdCellVM }> {
  public render() {
    const { model } = this.props;
    return (
      <LabelField mode="cell" cellPosition={NULL_CELL_POSITION} onClick={model.onNavigateToKintai}>
        {model.userId}
      </LabelField>
    );
  }
}
