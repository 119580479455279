import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalLiquidateSumKFProps = {
  model: MTotalLiquidateSumKFVM;
} & CellFormPrintAdaptable;

export interface MTotalLiquidateSumKFVM {
  mTotalLiquidateSum: KintaiInputItemState<Minutes>;
}

export const MTotalLiquidateSumKF = observer((props: MTotalLiquidateSumKFProps) => {
  const { model } = props;
  const itemState = model.mTotalLiquidateSum;

  return <HHMMField {...props} label="mTotalLiquidateSumKF" {...itemState} onChange={undefined} />;
});
