import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverSixtyHolidayMidnightKFProps = {
  model: MTotalOverSixtyHolidayMidnightKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverSixtyHolidayMidnightKFVM {
  mTotalOverSixtyHolidayMidnight: KintaiInputItemState<Minutes>;
}

export const MTotalOverSixtyHolidayMidnightKF = observer((props: MTotalOverSixtyHolidayMidnightKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverSixtyHolidayMidnight;

  return <HHMMField {...props} label="mTotalOverSixtyHolidayMidnightKF" {...itemState} onChange={undefined} />;
});
