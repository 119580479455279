import React from "react";

import { ButtonField } from "../../../common/form/ButtonField";
import { LabelField } from "../../../common/form/LabelField";
import {
  MonthlyApprovalOnYouErrorsKF,
} from "../../../common/kintai-table/monthly/fields/approvals/MonthlyApprovalOnYouErrorsKF";
import { MonthlyNotAppliedErrorsKF } from "../../../common/kintai-table/monthly/fields/approvals/MonthlyNotAppliedErrorsKF";
import {
  MonthlyNotApprovedErrorsKF,
} from "../../../common/kintai-table/monthly/fields/approvals/MonthlyNotApprovedErrorsKF";
import {
  MonthlyPrivilegedNotApprovedErrorsKF,
} from "../../../common/kintai-table/monthly/fields/approvals/MonthlyPrivilegedNotApprovedErrorsKF copy";
import { MonthlyRejectedErrorsKF } from "../../../common/kintai-table/monthly/fields/approvals/MonthlyRejectedErrorsKF";
import { MonthlyErrorsKF } from "../../../common/kintai-table/monthly/fields/errors/MonthlyErrors";
import { MonthlyInfosKF } from "../../../common/kintai-table/monthly/fields/errors/MonthlyInfos";
import { MonthlyWarningsKF } from "../../../common/kintai-table/monthly/fields/errors/MonthlyWarnings";
import { MonthlyApproverStamp } from "../../../common/kintai-table/monthly/fields/stamps/MonthlyApproverStamp";
import { MonthlyClerkStamp } from "../../../common/kintai-table/monthly/fields/stamps/MonthlyClerkStamp";
import { MonthlyHrmStamp } from "../../../common/kintai-table/monthly/fields/stamps/MonthlyHrmStamp";
import { MonthlyReviewerStamp } from "../../../common/kintai-table/monthly/fields/stamps/MonthlyReviewerStamp";
import { MonthlySelfStamp } from "../../../common/kintai-table/monthly/fields/stamps/MonthlySelfStamp";
import { GridSize } from "../../../common/table/GridSize";
import { x } from "../../../common/table/GridTable";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { TableColumnDef } from "../../../common/table/TableColumnDef";
import { KintaiBulkAuthCheckbox } from "./KintaiBulkAuthCheckbox";
import { KintaiListCommonGridItems } from "./KintaiListCommonGridItems";
import {
  HeaderLabelBulkAuth,
  HeaderLabelKintaiSinsei,
  HeaderLabelStamp,
  HeaderLabelWarningCount,
} from "./KintaiListHeaderLabels";
import { KLUserNameCell } from "./KLUserNameCell";

const approvalWidth = 4.5 * x("XX");
const stampWidth = 2.5 * x("XX");

export const kintaiListPageGridItemsHeader = (rows: KintaiListCommonGridItems[], bulkAuthMode: boolean) => {
  const result: TableColumnDef[] = [
    {
      id: "No",
      width: GridSize.rowNumberField,
      renderHeader: () => <>#</>,
      renderBody: ({ rowIndex }) => rowIndex,
    },
    {
      id: "社員番号",
      width: GridSize.userIdField,
      renderHeader: () => <>社員番号</>,
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return (
          <ButtonField mode="cell" cellPosition={NULL_CELL_POSITION} onClick={row.onOpenKintai}>
            {row.userId}
          </ButtonField>
        );
      },
    },
    {
      id: "氏名",
      width: GridSize.userNameField,
      renderHeader: () => <>氏名</>,
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return <KLUserNameCell model={row} />;
      },
    },
  ];

  if (bulkAuthMode) {
    const width = 3.3 * x("XX");

    result.push(
      {
        id: "一括承認(承認)",
        width,
        renderHeader: () => (
          <>
            <HeaderLabelBulkAuth />
            承認
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <KintaiBulkAuthCheckbox mode="cell" cellPosition={NULL_CELL_POSITION} model={row} type="approverBA" />;
        },
      },
      {
        id: "一括承認(事務)",
        width,
        renderHeader: () => (
          <>
            <HeaderLabelBulkAuth />
            事務
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <KintaiBulkAuthCheckbox mode="cell" cellPosition={NULL_CELL_POSITION} model={row} type="clerkBA" />;
        },
      },
      {
        id: "一括承認(総務人事)",
        width,
        renderHeader: () => (
          <>
            <HeaderLabelBulkAuth />
            総務
            <br />
            人事
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <KintaiBulkAuthCheckbox mode="cell" cellPosition={NULL_CELL_POSITION} model={row} type="hrmBA" />;
        },
      },
    );
  }

  return result;
};

export const kintaiListPageGridItemsBody = (rows: KintaiListCommonGridItems[], noReg: boolean = false) => {
  const result: TableColumnDef[] = [
    {
      id: "編集",
      width: 3.3 * x("XX"),
      renderHeader: () => <>編集</>,
      renderBody: ({ rowIndex }) => {
        const row = rows[rowIndex - 1];
        return (
          <ButtonField mode="cell" cellPosition={NULL_CELL_POSITION} onClick={row.onOpenKintai}>
            編集
          </ButtonField>
        );
      },
    },
  ];
  if (noReg) {
    return result;
  }

  result.push(
    ...([
      {
        id: "ランク",
        width: GridSize.rankNameField,
        renderHeader: () => <>ランク</>,
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return (
            <LabelField mode="cell" cellPosition={NULL_CELL_POSITION}>
              {row.rankName}
            </LabelField>
          );
        },
      },
      {
        id: "エラー",
        width: GridSize.warningCountField,
        renderHeader: () => (
          <>
            <HeaderLabelWarningCount />
            エラー
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "警告",
        width: GridSize.warningCountField,
        renderHeader: () => (
          <>
            <HeaderLabelWarningCount />
            警告
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyWarningsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "情報",
        width: GridSize.warningCountField,
        renderHeader: () => (
          <>
            <HeaderLabelWarningCount />
            情報
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyInfosKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "未申請",
        width: approvalWidth,
        renderHeader: () => (
          <>
            <HeaderLabelKintaiSinsei />
            未申請
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyNotAppliedErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "未承認",
        width: approvalWidth,
        renderHeader: () => (
          <>
            <HeaderLabelKintaiSinsei />
            未承認
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyNotApprovedErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "あなたの承認待ち",
        width: approvalWidth,
        renderHeader: () => (
          <>
            <HeaderLabelKintaiSinsei />
            あなたの
            <br />
            承認待ち
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyApprovalOnYouErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "総務人事承認待ち",
        width: approvalWidth,
        renderHeader: () => (
          <>
            <HeaderLabelKintaiSinsei />
            総務人事
            <br />
            承認待ち
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyPrivilegedNotApprovedErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "却下",
        width: approvalWidth,
        renderHeader: () => (
          <>
            <HeaderLabelKintaiSinsei />
            却下
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyRejectedErrorsKF mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "印:本人",
        width: stampWidth,
        renderHeader: () => (
          <>
            <HeaderLabelStamp />
            本人
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlySelfStamp mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "印:査閲",
        width: stampWidth,
        renderHeader: () => (
          <>
            <HeaderLabelStamp />
            査閲
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyReviewerStamp mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "印:承認",
        width: stampWidth,
        renderHeader: () => (
          <>
            <HeaderLabelStamp />
            承認
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyApproverStamp mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
      {
        id: "印:事務",
        width: stampWidth,
        renderHeader: () => (
          <>
            <HeaderLabelStamp />
            事務
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyClerkStamp mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },

      {
        id: "印:総務",
        width: stampWidth,
        renderHeader: () => (
          <>
            <HeaderLabelStamp />
            総務
            <br />
            人事
          </>
        ),
        renderBody: ({ rowIndex }) => {
          const row = rows[rowIndex - 1];
          return <MonthlyHrmStamp mode="cell" cellPosition={NULL_CELL_POSITION} model={row} />;
        },
      },
    ] as TableColumnDef[]),
  );
  return result;
};
