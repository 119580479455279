import { flow, IModelType, types } from "mobx-state-tree";

import { fromPaths } from "../../routing/fromPaths";
import { paths } from "../../routing/paths";
import { AppRouter } from "../../services/AppRouter";
import { AppNotifier } from "../../services/msg/AppNotifier";
import { TimeProvider } from "../../services/TimeProvider";
import { getNendo } from "../../utils/calendar";
import { getDI } from "../common/getDI";
import { LoadingStatus } from "../common/LoadingStatus";
import { DiPanelMode } from "../kintai/DiPanelMode";
import { typeDiPanelMode as typesDiPanelMode } from "../kintai/typeDiPanelMode";
import { idNendoInCharges, NendoInCharges } from "./incharge/NendoInCharges";

// cf. https://github.com/Microsoft/TypeScript/issues/5938
export type __IModelType = IModelType<any, any>;

const model = types.optional(
  types
    .model("DeptsPageModel", {
      panelMode: types.optional(typesDiPanelMode, "header"),
      nendo: types.optional(types.number, getNendo(new Date())),

      loadingStatus: types.optional(LoadingStatus, "init"),
      nendoInCharges: types.map(NendoInCharges),
      targetNendoInCharges: types.maybe(types.reference(NendoInCharges)),

      filterText: types.optional(types.string, ""),
    })
    .views(self => {
      const timeProvider = () => getDI(self, TimeProvider);

      return {
        get nendoList() {
          return timeProvider().referenceTargetNendoList;
        },
        get allowedToEdit() {
          if (!self.targetNendoInCharges) {
            return false;
          }

          return self.targetNendoInCharges.allowedToEdit;
        },
        get reloadDisabled() {
          return (
            self.loadingStatus !== "loaded" ||
            self.targetNendoInCharges === undefined ||
            !self.targetNendoInCharges.hasNoChange ||
            self.targetNendoInCharges.inSaving
          );
        },

        get saveDisabled() {
          return (
            self.loadingStatus !== "loaded" ||
            self.targetNendoInCharges === undefined ||
            self.targetNendoInCharges.hasNoChange ||
            self.targetNendoInCharges.inSaving
          );
        },
      };
    })
    .actions(self => {
      const appRouter = () => getDI(self, AppRouter);
      const appNotifier = () => getDI(self, AppNotifier);

      const prepare = () => {
        const nendo = self.nendo;
        const id = idNendoInCharges(self.nendo);
        return (
          self.nendoInCharges.get(id) ||
          (() => {
            const created = NendoInCharges.create({
              id,
              nendo,
            });
            self.nendoInCharges.put(created);
            return created;
          })()
        );
      };

      const triggerLoad = flow(function*(force = false) {
        try {
          self.loadingStatus = "loading";
          const nendoInCharges = prepare();
          self.targetNendoInCharges = nendoInCharges;
          if (force) {
            yield nendoInCharges.load();
          } else {
            yield nendoInCharges.loadIfNeeded();
          }
          self.loadingStatus = "loaded";
        } catch (exception) {
          appNotifier().error({ message: "部門勤怠責任者情報のロード処理に失敗しました", exception });
          self.loadingStatus = "failed";
        }
      });

      const reload = () => triggerLoad(true);

      return {
        setPanelMode(value: DiPanelMode) {
          self.panelMode = value;
        },
        route(pathFragment: string) {
          const { targetYear } = fromPaths.admin.depts.ofYear.index(pathFragment);

          if (targetYear) {
            self.nendo = targetYear;
          }
          triggerLoad();
        },
        reload,
        navigateToNendo(nendo: number) {
          appRouter().navigate(paths.admin.depts.ofNendo(nendo).index());
        },
        setFilterText(value: string) {
          self.filterText = value;
        },
      };
    }),
  {},
);

export type DeptsPageModelType = typeof DeptsPageModel.Type;

export const DeptsPageModelSymbol = "DeptsPageModel_Symbol";
export const DeptsPageModel: DeptsPageModelModelType = model;
type DeptsPageModelInferredType = typeof model;
export interface DeptsPageModelModelType extends DeptsPageModelInferredType {}
