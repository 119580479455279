import { Button } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import { saveAs } from "file-saver";
import React from "react";
import { FunctionComponent } from "react";

import { TableAttachedOptions } from "../../TableAttachedOptions";

export const printFrameCname = "KintaiPrintFrame";
export const pfRowCname = `${printFrameCname}_Row`;
export const pfPageBreak = `${printFrameCname}_Break`;

const tableExteriorBorderColor = "#363";
const tableInteriorBorderColor = "#ddd";

const Container = styled.div``;

const commonCss = `
.${printFrameCname} {
  width: 1600px;
  font-family: monospace;
  font-size: 12px;
  background-color: #ffffff;
  color: #000;
}

.${pfPageBreak} {
  page-break-before: always;
}

.${printFrameCname} table {
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid ${tableExteriorBorderColor};
  padding: 1px;
  empty-cells: show;
  margin-bottom: 1ex;
}

.${printFrameCname} table + table {
  margin-left: 1ex;
}

.${printFrameCname} .FullWidth {
  width: 100%;
}

.${printFrameCname} tr.even {
  background-color: #f6f6f6;
}

.${printFrameCname} td.col_group_start {
  border-left: 1px solid #AAA;
}

.${printFrameCname} td.col_group_end {
  border-right: 1px solid #AAA;
}

.${printFrameCname} th {
  height: 19px;
  border: 1px solid ${tableExteriorBorderColor};
  background-color: #dfd;
  padding: 1px 3px;
  text-align: center;
}

.${printFrameCname} td {
  height: 19px;
  border-left: 1px solid ${tableExteriorBorderColor};
  border-bottom: 1px solid ${tableInteriorBorderColor};
  text-align: right;
}

.${printFrameCname} tbody tr:last-child td,
.${printFrameCname} tfoot tr:last-child td {
  border-bottom: 1px solid ${tableExteriorBorderColor};
}

.${printFrameCname} td + td {
  border-left: 1px solid ${tableInteriorBorderColor};
}

.${printFrameCname} .left {
  text-align: left;
}

.${printFrameCname} .right {
  text-align: right;
}

.${printFrameCname} .center {
  text-align: center;
}

.${printFrameCname} .PJReport td,
.${printFrameCname} .PJReport th {
  width: 65px;
}

.${pfRowCname} {
  display: flex;
  flex-direction: row;
}

`;

const printCss = `
@page {
  margin: 8mm;
  size: A4 landscape;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  visibility: hidden;
}

.${printFrameCname} {
  font-size: 10px;
}

.${printFrameCname} .HiddenWhenPrinting {
  display: none;
}

#root .${printFrameCname} {
  position: absolute;
  visibility: visible;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  display: block;
}
`;

const screenCss = `
.${printFrameCname} {
  overflow: auto;
  border-right: 1px dashed #ccc;
}

.${printFrameCname} .${pfPageBreak} {
  border-top: 1px dashed #ccc;
}
`;

const WindowOpenerContainer = styled.div<{}>`
  position: fixed;
  width: 400px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

export const KintaiPrintFrame: FunctionComponent<{ title: string }> = props => {
  const printFrame = React.useRef<HTMLDivElement>(null);
  const fullHtml = (html: string) =>
    `<!doctype html>\n<html><head><meta charset="utf-8"><title>${props.title}</title></head><body>${html}</body></html>`;
  const openWindow = React.useCallback(() => {
    const newWindow = window.open();
    if (newWindow && printFrame.current) {
      newWindow.document.write(fullHtml(printFrame.current.outerHTML));
    }
  }, [printFrame]);
  const download = React.useCallback(() => {
    if (printFrame.current) {
      saveAs(
        new Blob([fullHtml(printFrame.current.outerHTML)], {
          type: "text/html; charset=utf-8",
        }),
        `Web勤怠印刷_${props.title}.html`,
      );
    }
  }, [printFrame]);

  return (
    <Container>
      <HiddenWhenPrinting>
        <TableAttachedOptions>
          <Button onClick={openWindow} icon="print">
            印刷
          </Button>
          <Button onClick={download} icon="download">
            ダウンロード
          </Button>
        </TableAttachedOptions>
      </HiddenWhenPrinting>
      <div className={printFrameCname} ref={printFrame}>
        <style>{commonCss}</style>
        <style media="screen">{screenCss}</style>
        <style media="print">{printCss}</style>

        {props.children}
      </div>
    </Container>
  );
};

export const HiddenWhenPrinting: React.FunctionComponent<{}> = props => (
  <div className="HiddenWhenPrinting">{props.children}</div>
);

export const KintaiPrintPage: React.FunctionComponent<{ noBreak?: boolean }> = props => (
  <div className={classNames(props.noBreak !== true && pfPageBreak)}>{props.children}</div>
);
