import styled from "@emotion/styled";
import React from "react";

import { DiPanelMode } from "../../../models/kintai/DiPanelMode";
import { mediaQuerySmall, useResponsive } from "../responsive/Responsive";

const Component = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: row;

  overflow: hidden;

  ${mediaQuerySmall} {
    flex-direction: column;
  }
`;

const LeftHeader = styled.div`
  flex: 0 0 320px;
  overflow: hidden;

  display: flex;

  border-right: 1px solid #ccc;
`;

const RightBody = styled.div`
  flex: 1 auto;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

const SmallScreen = styled.div`
  flex: 1 auto;

  display: flex;
  overflow: hidden;
`;

const Switcher = styled.div`
  flex: 0 0 auto;

  font-size: 9pt;
  display: flex;
  flex-direction: row;
`;

const SwitcherTab = (props: { title: string; active: boolean; onClick: () => void }) => {
  return (
    <SwitcherTabA active={props.active} href="javascript: undefined" onClick={props.onClick}>
      {props.title}
    </SwitcherTabA>
  );
};
const SwitcherTabA = styled.a<{ active: boolean }>`
  flex: 1 100%;
  padding: 0.5ex 0 0.5ex 0;

  text-align: center;
  text-decoration: none;
  background-color: #eee;
  color: #666;

  &:hover {
    color: #666;
    text-decoration: none;
  }

  ${({ active }) =>
    active
      ? `
  background-color: #fff;
  font-weight: bold;
  color: #000;
  `
      : `
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.05) inset;
  `};
`;

export interface DiPanelProps {
  header: React.ReactNode;
  headerTitle: string;
  body: React.ReactNode;
  bodyTitle: string;
  mode: DiPanelMode;
  onChangeMode: (mode: DiPanelMode) => void;
}

export const DiPanel: React.FunctionComponent<DiPanelProps> = props => {
  const { header, headerTitle, body, bodyTitle, mode } = props;

  const { small } = useResponsive();
  const toHeader = React.useCallback(() => props.onChangeMode("header"), [props.onChangeMode]);
  const toBody = React.useCallback(() => props.onChangeMode("body"), [props.onChangeMode]);

  if (small) {
    return (
      <Component>
        <SmallScreen>
          {mode === "header" && header}
          {mode === "body" && body}
        </SmallScreen>
        <Switcher>
          <SwitcherTab title={headerTitle} active={mode === "header"} onClick={toHeader} />
          <SwitcherTab title={bodyTitle} active={mode === "body"} onClick={toBody} />
        </Switcher>
      </Component>
    );
  }

  return (
    <Component>
      <LeftHeader>{header}</LeftHeader>
      <RightBody>{body}</RightBody>
    </Component>
  );
};
