import { injectable } from "inversify";

import { LeaveKindType } from "../../../models/accounts/LeaveKind";

@injectable()
export abstract class AccountsExcel {
  public abstract importExcel(file: File): Promise<AccountsExcelImportResult>;
  public abstract exportExcel(values: AccountsExcelExportEntity): Promise<Buffer>;
}

export type AccountsExcelImportResult =
  | {
      status: "success";
      rows: AccountsExcelExportEntry[];
    }
  | {
      status: "failure";
      failureReason: string;
    };

export interface AccountsExcelExportEntity {
  entries: AccountsExcelExportEntry[];
  roleCodes: string[];
}

export interface AccountsExcelAbsentType {
  type: LeaveKindType | undefined;
  from: Date | undefined;
  to: Date | undefined;
  remarks: string;
}

export interface AccountsExcelExportEntry {
  userId: string;
  userName: string;
  depCode: string | undefined;
  depName: string | undefined;
  rankCode: string | undefined;
  rankName: string | undefined;
  mailAddress: string;
  absences: AccountsExcelAbsentType[];
  leaveDate: Date | undefined;
  roles: string[];
}
