import { Minutes } from "@webkintai/core";
import { observer } from "mobx-react";
import React from "react";

import { KintaiInputItemState } from "../../../../../models/kintai/kintaiinput/KintaiInput";
import { CellFormPrintAdaptable } from "../../../form/CellFormAdaptable";
import { HHMMField } from "../../../form/HHMMField";

export type MTotalOverRestDayKFProps = {
  model: MTotalOverRestDayKFVM;
} & CellFormPrintAdaptable;

export interface MTotalOverRestDayKFVM {
  mTotalOverRestDay: KintaiInputItemState<Minutes>;
}

export const MTotalOverRestDayKF = observer((props: MTotalOverRestDayKFProps) => {
  const { model } = props;
  const itemState = model.mTotalOverRestDay;

  return <HHMMField {...props} label="mTotalOverRestDayKF" {...itemState} onChange={undefined} />;
});
