import { observer } from "mobx-react";
import React from "react";

import { ManagerTypeSelect } from "../../../common/form/ManagerTypeSelect";
import { NULL_CELL_POSITION } from "../../../common/table/NullCellPosition";
import { RankRowVM } from "./RankPageGrid";

@observer
export class RKPositionCell extends React.Component<{ model: RankRowVM }> {
  public render() {
    const row = this.props.model;
    return (
      <ManagerTypeSelect
        mode="cell"
        disabled={row.disabled}
        value={row.jobKindCode}
        onChange={row.onChangeJobKindCode}
        cellPosition={NULL_CELL_POSITION}
      />
    );
  }
}
