import { action, computed } from "mobx";

import { RankPositionType } from "../../../../models/common/RankPosition";
import { WorkStyleType } from "../../../../models/common/WorkStyle";
import { NendoRankInput } from "../../../../models/ranks/input/NendoRankInput";
import { RankRowVM } from "./RankPageGrid";

export class RankRowVMImpl implements RankRowVM {
  constructor(private readonly rank: typeof NendoRankInput.Type) {}

  @computed
  public get rankCode() {
    return this.rank.rankCode;
  }
  @computed
  public get rankName() {
    return this.rank.rankName;
  }

  @action.bound
  public onChangeRankName(value: string) {
    this.rank.setRankName(value);
  }

  @computed
  public get disabled() {
    return !this.rank.allowedToEdit;
  }
  @computed
  public get workingType() {
    return this.rank.workingType;
  }

  @action.bound
  public onChangeWorkStyleCode(value: WorkStyleType | undefined) {
    this.rank.setWorkingType(value);
  }

  @computed
  public get jobKindCode() {
    return this.rank.rankPosition;
  }
  @action.bound
  public onChangeJobKindCode(value: RankPositionType | undefined) {
    this.rank.setRankPosition(value);
  }

  @computed
  public get roleCode() {
    return this.rank.roleCode;
  }

  @action.bound
  public onChangeRoleCode(value: string | undefined) {
    this.rank.setRoleCode(value);
  }

  @computed
  public get hasNoChange() {
    return this.rank.hasNoChange;
  }
}
