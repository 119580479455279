import { observer } from "mobx-react-lite";
import React from "react";

import { usePublicDDModel } from "../../../../../../models/statistics/usePublicDDModel";
import { PubBarChartWidget } from "../../chart/PubBarChartWidget";

export const PubAvgLawoverAscWidgetContainer: React.FC<{ children?: never }> = observer(() => {
  const model = usePublicDDModel();
  const data = model.avg_lawover_asc;
  return (
    <PubBarChartWidget
      mood="good"
      title="[年度内] 健康管理時間 (平均) - 下位"
      data={data}
      lastUpdate={model.contentLastUpdate}
    />
  );
});
